import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useEPeticeEditContext } from '@gov-nx/module/page';
import {
	Button,
	FormInput,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

export const PetitionMemberForm = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();

	const { code, petitionMemberAddressLookup } = useEPeticeEditContext();

	const tsn = getLocalizeCurried(code);
	const isProcessing = petitionMemberAddressLookup.isLoading;

	return (
		<FormProvider {...petitionMemberAddressLookup.formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={petitionMemberAddressLookup.formDefinition}
				onSubmit={petitionMemberAddressLookup.onSubmit}>
				<FormWidthElementLayout type="l">
					<LayoutGap gap={4}>
						<FormInput
							label={{ children: tsn('formular.pole.jmeno') }}
							field={{
								name: 'jmeno',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.jmeno'),
							}}
						/>

						<FormInput
							label={{ children: tsn('formular.pole.prijmeni') }}
							field={{
								name: 'prijmeni',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.prijmeni'),
							}}
						/>

						<div className="text-m text-neutral-black">
							{tsn('formular.nadpis.vyhledat-adresu')}
						</div>

						<FormInput
							label={{ children: tsn('formular.pole.nazev-obce') }}
							field={{
								name: 'nazevObce',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.nazev-obce'),
							}}
						/>

						<FormInput
							label={{ children: tsn('formular.pole.nazev-ulice') }}
							field={{
								name: 'nazevUlice',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.nazev-ulice'),
							}}
						/>

						<div>
							<FormInput
								label={{ children: tsn('formular.pole.cislo') }}
								field={{
									name: 'cislo',
									disabled: isProcessing,
									placeholder: tsn('formular.placeholder.cislo'),
								}}
							/>

							<Button
								variant={'primary'}
								type={'solid'}
								size="s"
								className="mt-2"
								disabled={petitionMemberAddressLookup.isLoading}
								loading={petitionMemberAddressLookup.isLoading}
								onClick={petitionMemberAddressLookup.onLookup}
								wcagLabel={tsn('akce.wcag.hledat')}>
								{tsn('formular.tlacitko.hledat')}
							</Button>
						</div>

						{!!petitionMemberAddressLookup.options.length && (
							<FormSelect
								field={{
									name: '_address',
									disabled: isProcessing,
									options: petitionMemberAddressLookup.options,
								}}
								label={{
									children: tsn('formular.pole.clen-adresa'),
								}}
							/>
						)}
					</LayoutGap>
				</FormWidthElementLayout>

				<Button
					className="mt-8"
					nativeType={'submit'}
					wcagLabel={tsn('akce.wcag.pridat-clena')}
					disabled={petitionMemberAddressLookup.disableSubmitButton}
					variant={'primary'}
					type="solid">
					{tsn('formular.tlacitko.pridat-clena')}
				</Button>
			</PoFormWeb>
		</FormProvider>
	);
};
