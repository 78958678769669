import { PeticePrilohaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, usePetitionAttachmentContext } from '@gov-nx/module/page';
import { Attachment } from '@gov-nx/ui/web';

export interface EPetitionAttachmentsItemProps {
	code: PageCode;
	attachment: PeticePrilohaDto;
	petitionId: number;
}

export const EPetitionAttachmentItem = ({
	code,
	attachment,
	petitionId,
}: EPetitionAttachmentsItemProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	const { downloadAttachment } = usePetitionAttachmentContext();

	return (
		<Attachment
			size={attachment.velikost}
			name={attachment.nazev}
			downloadLabel={ts('prilohy.akce.stahnout')}
			onDownload={(onsuccess) => {
				downloadAttachment(
					{
						petitionId: petitionId,
						attachmentId: attachment.id as number,
					},
					onsuccess
				);
			}}
		/>
	);
};
