import {
	CommonSettingKey,
	UserSetting,
	UserSettingFavorites,
	UserSettingLanguage,
} from '@gov-nx/api/portal-obcana';

export const isLanguageSetting = (
	setting: UserSetting
): setting is UserSettingLanguage => setting.key === CommonSettingKey.Language;

export const isFavoritesSetting = (
	setting: UserSetting
): setting is UserSettingFavorites =>
	setting.key === CommonSettingKey.Favorites;
