import classnames from 'classnames';
import React from 'react';

export interface HeaderLayoutProps {
	children: React.ReactNode;
	filterParams?: React.ReactNode;
	className?: string;
}

export const HeaderLayout = ({
	children,
	filterParams,
	className,
}: HeaderLayoutProps) => {
	return (
		<header
			className={classnames(
				'py-4 px-4 -mx-4 -mt-4 rounded-t border-b border-secondary-400 bg-neutral-white',
				'[ md:pl-8 md:pr-10 md:-ml-8 md:-mr-10 md:-mt-8 ]'
			)}>
			<div
				className={classnames(
					`flex justify-between items-center gap-4`,
					className
				)}>
				{children}
			</div>
			{filterParams}
		</header>
	);
};
