import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import { OsobyKomunikacniUdajeDto } from '../generated';
import { RequestData } from '../types/queryTypes';

export const robCommunicationQuery =
	async (): Promise<OsobyKomunikacniUdajeDto> => {
		govApiLog('/api/v1/fyzickeosoby/komunikace');
		const { data } = await axiosInstance.get('/api/v1/fyzickeosoby/komunikace');
		return data;
	};

export const verificationFinishQuery = <
	Params,
	Body extends {
		kod: string;
		hodnota: string;
		typ: 'EMAIL' | 'TELEFON';
		zdroj?: 'ROB' | 'ROS';
		ico?: number;
	}
>({
	body,
}: RequestData<Params, Body>): Promise<void> => {
	govApiLog('/api/v1/komunikacniudaje/dokoncit-overeni');
	return axiosInstance.post('/api/v1/komunikacniudaje/dokoncit-overeni', body);
};

export const verificationQuery = async <
	Params,
	Body extends {
		hodnota: string;
		typ: 'EMAIL' | 'TELEFON';
		zdroj?: 'ROB' | 'ROS';
		ico?: number;
	}
>({
	body,
}: RequestData<Params, Body>): Promise<void> => {
	govApiLog('/api/v1/komunikacniudaje/overit');
	return axiosInstance.post('/api/v1/komunikacniudaje/overit', body);
};
