import {
	saveDataBoxAttachmentToDocumentsQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';

export interface SaveAttachmentToDocumentsProps {
	onError: (error: Error) => void;
	onSuccess: () => void;
}

export const useSaveAttachmentToDocuments = ({
	onSuccess,
	onError,
}: SaveAttachmentToDocumentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { toastMessage } = useMessageEvents();
	const saveAttachmentToDocuments = usePoMutation({
		mutationFn: saveDataBoxAttachmentToDocumentsQuery,
		onError: (error) => {
			onError &&
				onError(
					new GovError(
						`Error: An unexpected error occurred while saving file to documents. ${error}`
					)
				);
		},
		onSuccess: async () => {
			onSuccess && onSuccess();
			toastMessage({
				options: {
					variant: 'success',
					icon: {
						name: 'save',
						type: 'basic',
					},
				},
				content: tn('akce.priloha-ulozena'),
			});
		},
	});
	return saveAttachmentToDocuments;
};
