import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PoFormWeb } from '@gov-nx/component/web';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	MySubmissionMessage as MySubmissionMessageInterface,
	useMojePodaniDetailContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormSelect,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';

const Skeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap>
			<div className={'flex flex-col mt-6 [ md:flex-row ]'}>
				<div className={'w-2/5'}>
					<GovSkeleton
						height={'22px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						className={'[ md:ml-3 ]'}
					/>
				</div>
			</div>
		</LayoutGap>
	);
};

interface Props {
	message: MySubmissionMessageInterface;
}

export const ConnectToAnotherSubmissionModal = ({ message }: Props) => {
	const { code, connectToAnotherSubmission } =
		useMojePodaniDetailContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const {
		form,
		onSubmit,
		submissionOptions,
		isOpen,
		isLoading,
		isSubmitting,
		onClose,
	} = connectToAnotherSubmission;

	return (
		<Modal
			label={ts('formular.pripojit-k-jinemu-podani.titulek')}
			open={isOpen}
			onGov-close={onClose}>
			<div>
				{isLoading ? (
					<Skeleton />
				) : (
					<PoFormWeb
						formDefinition={form}
						onSubmit={onSubmit}>
						<LayoutGap gap={4}>
							<FormSelect
								field={{
									name: 'submission',
									options: submissionOptions,
								}}
								label={{
									children: ts('formular.pripojit-k-jinemu-podani.podani'),
								}}
							/>

							<ButtonGroupLayout>
								<Button
									loading={isSubmitting}
									nativeType={'submit'}
									wcagLabel={ts('akce.pripojit-k-jinemu-podani-ulozit')}
									disabled={isSubmitting}
									variant={'primary'}
									type={'solid'}>
									{ts('akce.pripojit-k-jinemu-podani-ulozit')}
								</Button>
							</ButtonGroupLayout>
						</LayoutGap>
					</PoFormWeb>
				)}
			</div>
		</Modal>
	);
};
