import { PageCode } from '../definitions/codes';
import { PageListMapper } from './PageMap';

export default {
	children: {
		dashboard: {
			locales: {
				cs: {
					slug: '',
				},
			},
			code: PageCode['dashboard'],
			children: {},
		},
		prihlaseni: {
			locales: {
				cs: {
					slug: 'prihlaseni',
				},
			},
			code: PageCode['prihlaseni'],
			children: {},
		},
		odhlaseni: {
			locales: {
				cs: {
					slug: 'odhlaseni',
				},
			},
			code: PageCode['odhlaseni'],
			children: {},
		},
		sluzba: {
			locales: {
				cs: {
					slug: 'sluzba',
				},
			},
			code: PageCode['sluzba'],
			children: {},
		},
		nastaveni: {
			locales: {
				cs: {
					slug: 'nastaveni',
				},
			},
			code: PageCode['nastaveni'],
			children: {
				'datove-schranky': {
					locales: {
						cs: {
							slug: 'datove-schranky',
						},
					},
					code: PageCode['datove-schranky-pripojeni'],
					children: {},
				},
			},
		},
		epetice: {
			locales: {
				cs: {
					slug: 'e-petice',
				},
			},
			code: PageCode['epetice'],
			children: {
				zalozeni: {
					locales: {
						cs: {
							slug: 'zalozeni',
						},
					},
					code: PageCode['epetice-nova'],
					children: {},
				},
				detail: {
					locales: {
						cs: {
							slug: ':petitionId',
						},
					},
					code: PageCode['epetice-detail'],
					children: {
						editace: {
							locales: {
								cs: {
									slug: 'editace',
								},
							},
							code: PageCode['epetice-detail-editace'],
							children: {},
						},
					},
				},
			},
		},
		'moje-podani': {
			locales: {
				cs: {
					slug: 'moje-podani',
				},
			},
			code: PageCode['moje-podani'],
			children: {
				detail: {
					locales: {
						cs: {
							slug: ':submissionId',
						},
					},
					code: PageCode['moje-podani-detail'],
					children: {},
				},
			},
		},
		profil: {
			locales: {
				cs: {
					slug: 'profil',
				},
			},
			code: null,
			children: {
				doklady: {
					locales: {
						cs: {
							slug: 'doklady',
						},
					},
					code: PageCode['doklady'],
					children: {
						'obcansky-prukaz': {
							locales: {
								cs: {
									slug: 'obcansky-prukaz',
								},
							},
							code: PageCode['obcansky-prukaz'],
							children: {},
						},
						'cestovni-pas': {
							locales: {
								cs: {
									slug: 'cestovni-pas',
								},
							},
							code: PageCode['cestovni-pas'],
							children: {},
						},
						'ridicsky-prukaz': {
							locales: {
								cs: {
									slug: 'ridicsky-prukaz',
								},
							},
							code: PageCode['ridicsky-prukaz'],
							children: {},
						},
						'vlastni-doklad': {
							locales: {
								cs: {
									slug: 'vlastni-doklad/:documentId?',
								},
							},
							code: PageCode['vlastni-doklad'],
							children: {},
						},
					},
				},
				edoklady: {
					locales: {
						cs: {
							slug: 'edoklady',
						},
					},
					code: PageCode['profil-edoklady'],
					children: {},
				},
				rob: {
					locales: {
						cs: {
							slug: 'registr-obyvatel',
						},
					},
					code: PageCode['profil-ROB'],
					children: {},
				},
				ros: {
					locales: {
						cs: {
							slug: 'registr-osob',
						},
					},
					code: PageCode['profil-ROS'],
					children: {},
				},
				certifikaty: {
					locales: {
						cs: {
							slug: 'certifikaty',
						},
					},
					code: PageCode['profil-certifikaty'],
					children: {},
				},
			},
		},
		'registr-ZP': {
			locales: {
				cs: {
					slug: 'registr-zivnostenskeho-podnikani',
				},
			},
			code: PageCode['registr-ZP'],
			children: {},
		},
		'muj-katastr-nemovitosti': {
			locales: {
				cs: {
					slug: 'muj-katastr-nemovitosti',
				},
			},
			code: PageCode['muj-katastr-nemovitosti'],
			children: {},
		},
		'registr-SV': {
			locales: {
				cs: {
					slug: 'registr-silnicnich-vozidel',
				},
			},
			code: PageCode['registr-SV'],
			children: {
				detail: {
					locales: {
						cs: {
							slug: ':vehicleId',
						},
					},
					code: PageCode['registr-SV-detail'],
					children: {},
				},
			},
		},
		podpora: {
			locales: {
				cs: {
					slug: 'podpora',
				},
			},
			code: PageCode['podpora'],
			children: {},
		},
		upozorneni: {
			locales: {
				cs: {
					slug: 'upozorneni',
				},
			},
			code: PageCode['upozorneni'],
			children: {},
		},
		'uzivatelska-prirucka': {
			locales: {
				cs: {
					slug: 'uzivatelska-prirucka',
				},
			},
			code: PageCode['uzivatelska-prirucka'],
			children: {},
		},
		drozd: {
			locales: {
				cs: {
					slug: 'drozd',
				},
			},
			code: PageCode['drozd'],
			children: {},
		},
		'datove-schranky': {
			locales: {
				cs: {
					slug: 'datove-schranky',
				},
			},
			code: PageCode['datove-schranky'],
			children: {
				schranka: {
					locales: {
						cs: {
							slug: 'schranka/:dataBoxId/:folderType',
						},
					},
					code: PageCode['datove-schranky-seznam'],
					children: {
						'nova-zprava': {
							locales: {
								cs: {
									slug: 'nova-zprava',
								},
							},
							code: PageCode['datove-schranky-nova'],
							children: {},
						},
						zprava: {
							locales: {
								cs: {
									slug: 'zprava/:messageId',
								},
							},
							code: PageCode['datove-schranky-zprava'],
							children: {
								odpoved: {
									locales: {
										cs: {
											slug: 'odpoved',
										},
									},
									code: PageCode['datove-schranky-odpoved'],
									children: {},
								},
							},
						},
					},
				},
			},
		},
		'registr-ridicu': {
			locales: {
				cs: {
					slug: 'registr-ridicu',
				},
			},
			code: PageCode['registr-ridicu'],
			children: {},
		},
		'zdravotnicka-dokumentace': {
			locales: {
				cs: {
					slug: 'zdravotnicka-dokumentace',
				},
			},
			code: PageCode['zdravotnicka-dokumentace'],
			children: {},
		},
		'moje-soubory': {
			locales: {
				cs: {
					slug: 'moje-soubory',
				},
			},
			code: PageCode['moje-soubory'],
			children: {},
		},
		'sprava-souhlasu': {
			locales: {
				cs: {
					slug: 'sprava-souhlasu',
				},
			},
			code: PageCode['sprava-souhlasu'],
			children: {
				detail: {
					locales: {
						cs: {
							slug: ':consentId',
						},
					},
					code: PageCode['sprava-souhlasu-detail'],
					children: {},
				},
			},
		},
		'kde-o-mne-stat-vede-udaje': {
			locales: {
				cs: {
					slug: 'kde-o-mne-stat-vede-udaje',
				},
			},
			code: PageCode['kde-o-mne-stat-vede-udaje'],
			children: {},
		},
	},
	locales: {
		cs: {
			slug: '',
		},
	},
	code: null,
} as PageListMapper;
