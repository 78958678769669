import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { isString } from '@gov-nx/core/types';

interface DateShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	isInvalidMessage?: string;
}

export const useDateShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getDatePickerShape: (props?: DateShape): yup.DateSchema => {
			const schema = yup
				.date()
				.notRequired()
				.transform((value, orig) => {
					if (orig === '') return undefined;

					if (isString(orig)) {
						return parse(orig, 'd.M.yyyy', new Date());
					}

					return value;
				})
				.typeError(
					props?.isInvalidMessage ??
						t('datum.validace.datum-neni-ve-spravnem-tvaru')
				);

			if (props?.isRequired) {
				return schema.required(
					props?.isRequiredMessage ?? t('datum.validace.zadejte-datum')
				);
			}

			return schema;
		},
	};
};
