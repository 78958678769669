import { pubSubActionBridge } from '@gov-nx/core/app';
import {
	NavigationActions,
	NavigateToPageAction,
	NavigateToServiceAction,
} from './navigation.types';

export enum NavigationActionTypes {
	toPage = 'NAVIGATION_TO_PAGE',
	toService = 'NAVIGATION_TO_SERVICE',
}

export const toPageSubscription = (
	payload: NavigateToPageAction['payload']
): NavigateToPageAction => ({ type: NavigationActionTypes.toPage, payload });

export const toServiceSubscription = (
	payload: NavigateToServiceAction['payload']
): NavigateToServiceAction => ({
	type: NavigationActionTypes.toService,
	payload,
});

export const navigationEvents = pubSubActionBridge<NavigationActions>();
