import {
	DatovkaApiType,
	DokumentyDokumentSeznamDto,
	DokumentyStrankovaniDto,
	KosPolozkaKosSeznamDto,
	RecycleBinType,
} from '@gov-nx/api/portal-obcana';
import {
	useDocumentDelete,
	useDocumentRestore,
	useEmptyRecycleBin,
} from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { IUseBoolean } from '@gov-nx/utils/common';
import { PageContextCore } from '../../types/page.types';
import { useMyFilesDownload } from './hooks/useMyFilesDownload';
import { useMyFilesFilters } from './hooks/useMyFilesFilters';
import { useMyFilesModals } from './hooks/useMyFilesModals';
import { useMyFilesSelection } from './hooks/useMyFilesSelection';
import { useMyFilesStorage } from './hooks/useMyFilesStorage';

export enum MyFilesSource {
	UZIVATELEM_NAHRANY_DOKUMENT = 'UZIVATELEM_NAHRANY_DOKUMENT',
	PRILOHA_DATOVE_ZPRAVY = 'PRILOHA_DATOVE_ZPRAVY',
	VSTUP_NEBO_VYSTUP_PODANI = 'VSTUP_NEBO_VYSTUP_PODANI',
}

export enum MyFilesOrder {
	DATUM_VYTVORENI = 'datumVytvoreni',
	DATUM_VYTVORENI_DESC = '-datumVytvoreni',
	NAZEV = 'nazev',
	NAZEV_DESC = '-nazev',
	NAZEV_SOUBORU = 'nazevSouboru',
	NAZEV_SOUBORU_DESC = '-nazevSouboru',
	KONEC_PLATNOSTI = 'konecPlatnosti',
	KONEC_PLATNOSTI_DESC = '-konecPlatnosti',
}

export enum RecycleBinOrder {
	DATUM_SMAZANI = 'datumSmazani',
	DATUM_SMAZANI_DESC = '-datumSmazani',
	NAZEV = 'nazev',
	NAZEV_DESC = '-nazev',
	NAZEV_SOUBORU = 'nazevSouboru',
	NAZEV_SOUBORU_DESC = '-nazevSouboru',
	VELIKOST = 'velikost',
	VELIKOST_DESC = '-velikost',
	DNU_DO_ODSTRANENI = 'dnuDoOdstraneni',
	DNU_DO_ODSTRANENI_DESC = '-dnuDoOdstraneni',
}

export interface MyFilesFilterType extends MyFilesFormData {
	source?: MyFilesSource;

	selectedBadgeIndex: number;
	razeni: MyFilesOrder | RecycleBinOrder;
	start: number;
	pageSize: number;
}

export interface MyFilesFormData {
	text?: string;
	selection: { [documentId: string]: boolean };
	datumVytvoreniOd?: Date;
	datumVytvoreniDo?: Date;
}

export interface MyFilesDataRequest extends DokumentyStrankovaniDto {
	text?: string;
	zdroje?: MyFilesSource;
	smazano?: 'false';
	datumVytvoreniOd?: string;
	datumVytvoreniDo?: string;
	jeSoubor?: boolean;

	typ?: RecycleBinType.DOKUMENT;
}

export interface MyFilesContextType extends PageContextCore {
	firstDataBox?: DatovkaApiType;
	documents?: DokumentyDokumentSeznamDto;
	documentsBinned?: KosPolozkaKosSeznamDto;
	isDataFetching: boolean;

	badges: MyFilesBadge[];
	isSelectedRecycleBin: boolean;
	isSelectedWithoutFile: boolean;
	onSubmit: () => void;
	formDefinition: FormDefinition<MyFilesFormData>;

	deleteConfirm: IUseBoolean;
	deletePermanentConfirm: IUseBoolean;

	myFilesFilters: ReturnType<typeof useMyFilesFilters>;
	myFilesSelection: ReturnType<typeof useMyFilesSelection>;
	documentRestore: ReturnType<typeof useDocumentRestore>;
	documentDelete: ReturnType<typeof useDocumentDelete>;
	emptyRecycleBin: ReturnType<typeof useEmptyRecycleBin>;
	myFilesDownload: ReturnType<typeof useMyFilesDownload>;
	myFilesModals: ReturnType<typeof useMyFilesModals>;

	storage: ReturnType<typeof useMyFilesStorage>;
}

export interface MyFilesBadge {
	nameCode: string;
	icon: string;
	source?: MyFilesSource;
	isFile?: boolean;
}
