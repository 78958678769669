import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { cmsSignpostQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { propEq } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useAppLanguage } from '../Localization/useAppLanguage';

export const useSignposts = () => {
	const { language } = useAppLanguage();
	const { application: app, environment: env } = useConfigurationStore();

	const queryClient = useQueryClient();
	const queryKey = useMemo(() => ['signpost', language], [language]);

	const query = usePoQuery({
		queryKey,
		enabled: false,
		refetchOnWindowFocus: false,
		queryFn:
			app && env
				? () =>
						cmsSignpostQuery({
							app,
							env,
							locale: language,
						})
				: undefined,
	});
	const isEDocumentsEnabled = query.data?.find(
		propEq('pageCode', PageCode['profil-edoklady'])
	);

	const refetchSignposts = useCallback(async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey });

		await query.refetch();
	}, [query, queryClient, queryKey]);

	return {
		refetchSignposts,
		isLoaded: query.isFetched,
		isEDocumentsEnabled: !!isEDocumentsEnabled,
		signposts: query.data,
	};
};
