import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CmsExternalService, CmsPage, CmsService } from '@gov-nx/api/common';
import { Nullable, hasOwnProperty } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { CmsState } from './store.types';

const initialState = {
	services: {},
	pages: {},
	externalServices: {},
};

export const useCmsStore = create<CmsState>()(
	persist(
		(set, get) => ({
			...initialState,

			saveCmsService: (code: ServiceCode, data: CmsService) => {
				const prevServices = get().services;
				set({
					services: { ...prevServices, [code]: { ...data } },
				});
			},
			saveCmsExternalService: (id: number, data: CmsExternalService) => {
				const prevExternalServices = get().externalServices;
				set({
					externalServices: { ...prevExternalServices, [id]: { ...data } },
				});
			},
			saveCmsPage: (code: PageCode, data: CmsPage) => {
				const prevPages = get().pages;
				set({
					pages: { ...prevPages, [code]: { ...data } },
				});
			},
			resetCms: () => set({ ...initialState }),

			getCmsService: (code: ServiceCode) => {
				const services = get().services;
				return code in services ? services[code] : null;
			},
			getCmsPage: (code: Nullable<PageCode>) => {
				const pages = get().pages;
				return code && code in pages ? pages[code] : null;
			},
			getCmsExternalService: (id: Nullable<number>) => {
				const externalServices = get().externalServices;
				return id && hasOwnProperty(externalServices, id)
					? externalServices[id]
					: null;
			},
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.cms,
		}
	)
);
