import { AgendyAisAgendaAisDto } from '@gov-nx/api/portal-obcana';
import { KdeOMneStatVedeUdajeData } from './context.types';

export const prepareAgendasResponse = (
	data: AgendyAisAgendaAisDto[]
): KdeOMneStatVedeUdajeData => {
	const dataObj = data.reduce(
		(
			acc: Record<string, NonNullable<KdeOMneStatVedeUdajeData>[number]>,
			{ kodAgendy, kodAis, nazevAgendy, nazevAis, nazevSpravce }
		) => {
			if (!kodAgendy) return acc;

			if (acc[kodAgendy]) {
				acc[kodAgendy].children.push({ kodAis, nazevAis, nazevSpravce });
			} else {
				acc[kodAgendy] = {
					kodAgendy,
					nazevAgendy,
					children: [{ kodAis, nazevAis, nazevSpravce }],
				};
			}
			return acc;
		},
		{}
	);

	return Object.values(dataObj);
};
