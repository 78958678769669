import React, { createContext, useContext, useEffect } from 'react';
import {
	mySubmissionsQuery,
	mySubmissionsSearchQuery,
	PodaniPodaniInstanceDto,
	PodaniPodaniInstanceSeznamDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	FilterParam,
	FilterSortDirection,
	has,
	Nullable,
	Time,
} from '@gov-nx/core/types';
import { toDateReadable, useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { removeIdFromSubmissionName } from '../../epetice/seznam/utils';
import { getTimePeriod } from '../../upozorneni/utils';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	MySubmissionListOrder,
	MySubmissionsListContext,
	MySubmissionsListControl,
	MySubmissionsListFilter,
} from './context.types';
import { prepareStatus } from './utils';

const PageMojePodaniSeznamContext =
	createContext<Nullable<MySubmissionsListContext>>(null);

interface SettingsMPSContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

const defaultFilter: MySubmissionsListFilter = {
	pocet: 10,
	razeni: MySubmissionListOrder.datumOdeslani,
	razeniSmer: FilterSortDirection.DESC,
	smazano: false,
	start: 0,
	datumVyrizeni: undefined,
	vyrizeno: undefined,
	lhutaVyrizeni: undefined,
};

export function MojePodaniSeznamContextProvider({
	children,
	code,
}: SettingsMPSContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(code);
	const { setControls, controls } = useProcessControl<MySubmissionsListControl>(
		{
			displayFilter: false,
		}
	);

	const { filter, setPartialFilter } = useFilter<MySubmissionsListFilter>({
		key: code,
		initialState: defaultFilter,
	});

	useEffect(() => {
		filter.datumVyrizeni &&
			formDefinition.formMethods.setValue(
				'datumVyrizeni',
				filter.datumVyrizeni
			);
		filter.lhutaVyrizeni &&
			formDefinition.formMethods.setValue(
				'lhutaVyrizeni',
				filter.lhutaVyrizeni
			);
		filter.vyrizeno &&
			formDefinition.formMethods.setValue('vyrizeno', filter.vyrizeno);
		filter.razeni &&
			formDefinition.formMethods.setValue('razeni', filter.razeni);
		filter.razeniSmer &&
			formDefinition.formMethods.setValue('razeniSmer', filter.razeniSmer);
		filter.hledat &&
			formDefinition.formMethods.setValue('hledat', filter.hledat);
	}, []);

	const formDefinition = FormInstance({
		code,
		defaultValues: defaultFilter,
	});

	const query = usePoQuery<PodaniPodaniInstanceSeznamDto>({
		queryKey: ['my-submissions-list', filter],
		queryFn: async () => {
			const { params } = prepareSubmitData(filter);

			if (filter.hledat) {
				return mySubmissionsSearchQuery(filter.hledat, params);
			}

			return mySubmissionsQuery(params);
		},
		staleTime: Time['10 seconds'],
	});

	const submissions: PodaniPodaniInstanceDto[] = query.data?.seznam ?? [];
	const numberOfSubmissions: number = query.data?.pocet ?? 0;

	const filterParams: FilterParam[] = [
		{
			label: tn('formular.select.datum-vyrizeni'),
			value: filter.datumVyrizeni
				? toDateReadable(filter.datumVyrizeni)
				: undefined,
			isActive: !!filter.datumVyrizeni,
			onRemove: () => {
				setPartialFilter({ datumVyrizeni: undefined, start: 0 });
				formDefinition.formMethods.resetField('datumVyrizeni');
			},
		},
		{
			label: tn('filtr.pole.vyrizeno'),
			value: filter.vyrizeno ? toDateReadable(filter.vyrizeno) : undefined,
			isActive: !!filter.vyrizeno,
			onRemove: () => {
				setPartialFilter({ vyrizeno: undefined, start: 0 });
				formDefinition.formMethods.resetField('vyrizeno');
			},
		},
		{
			label: tn('formular.select.lhuta-vyrizeni'),
			value: filter.lhutaVyrizeni
				? toDateReadable(filter.lhutaVyrizeni)
				: undefined,
			isActive: !!filter.lhutaVyrizeni,
			onRemove: () => {
				setPartialFilter({ lhutaVyrizeni: undefined, start: 0 });
				formDefinition.formMethods.resetField('lhutaVyrizeni');
			},
		},
	];

	const resetFilter = async () => {
		setPartialFilter(defaultFilter);
		setControls({ displayFilter: false });
		formDefinition.formReset();
	};
	const goToPage = async (page: number) => {
		setPartialFilter({ start: (page - 1) * filter.pocet });
	};

	const currentPage = filter.start / filter.pocet + 1;

	const onSubmit = formDefinition.formMethods.handleSubmit(async () => {
		setPartialFilter({ ...formDefinition.formMethods.getValues(), start: 0 });
		setControls({ displayFilter: false });
	});

	return (
		<PageMojePodaniSeznamContext.Provider
			value={{
				filterParams,
				submissions: submissions.filter(has('id')).map((submission) => {
					return {
						id: submission.id,
						status: prepareStatus(submission),
						name: submission.nazev
							? removeIdFromSubmissionName(submission.nazev) ?? ''
							: '',
						period: submission.datumOdeslani
							? getTimePeriod(submission.datumOdeslani)
							: undefined,
					};
				}),
				controls,
				query,
				numberOfSubmissions,
				setControls,
				formDefinition,
				resetFilter,
				filter,
				setPartialFilter,
				currentPage,
				onSubmit,
				goToPage,
			}}>
			{children}
		</PageMojePodaniSeznamContext.Provider>
	);
}

export const useMojePodaniSeznamContextInstance =
	(): MySubmissionsListContext =>
		useContext(PageMojePodaniSeznamContext) as MySubmissionsListContext;
