import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { OsobyPodnikatelDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { DataTable } from '@gov-nx/ui/web';

interface TableTypes {
	data: OsobyPodnikatelDto[];
	code: PageCode;
}

export const ProfileROSDataView = ({ data, code }: TableTypes) => {
	const filteredUdaje = data?.filter((data) => data.typ === 'PFO');
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (!Array.isArray(filteredUdaje) || filteredUdaje.length === 0) {
		return (
			<GovMessage variant={'primary'}>{ts('oznameni.zadne-udaje')}</GovMessage>
		);
	}

	return (
		<DataTable responsive>
			<tbody>
				{filteredUdaje.map((data) => (
					<React.Fragment key={data.datovaSchrankaId}>
						<tr>
							<td>{ts('zaznam.ico')}</td>
							<td>
								<strong>{data.ico}</strong>
							</td>
						</tr>
						<tr>
							<td>{ts('zaznam.pravni-forma')}</td>
							<td>
								<strong>{`${data.pravniForma?.nazev}`}</strong>
							</td>
						</tr>
						<tr>
							<td>{ts('zaznam.datova-schranka')}</td>
							<td>
								<strong>{data.datovaSchrankaId?.split(' ')[0]}</strong>
							</td>
						</tr>
					</React.Fragment>
				))}
			</tbody>
		</DataTable>
	);
};
