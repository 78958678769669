import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { createContext, useContext } from 'react';
import {
	documentDeleteQuery,
	documentsListQuery,
	DokladyDokladDto,
	DokladyDokladSeznamDto,
	DokumentyDokumentDto,
	driversLicenceToDocument,
	driversRegistryQuery,
	driversRegistryResponseTransform,
	filterErrorStatus,
	identityDocumentQuery,
	ResponseError,
	usePoMutation,
	usePoQueries,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import {
	useTranslationWithNamespace,
	useConfiguration,
} from '@gov-nx/core/service';
import { propEq } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import {
	DokladyType,
	IdentityDocumentControls,
	IdentityDocumentsContext,
} from './context.types';
import { ownDocumentKeys } from './queryKeys';
import { useDocumentPreview } from './useDocumentPreview';

const DokladyContext = createContext<IdentityDocumentsContext | null>(null);

interface DokladyContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function DokladyContextProvider({
	children,
	code,
}: DokladyContextProviderProps) {
	const { toastMessageSuccess } = useMessageEvents();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const queryClient = useQueryClient();
	const { zadostNovyRidicskyPrukazUrl } = useConfiguration();

	const { setControls, controls } = useProcessControl<IdentityDocumentControls>(
		{
			initialLoading: true,
			displayComplaintModal: undefined,
		}
	);

	const [documentsQuery, ownDocumentsQuery] = usePoQueries<
		[
			UseQueryResult<
				AxiosResponse<DokladyDokladSeznamDto>,
				AxiosError<ResponseError>
			>,
			UseQueryResult<DokumentyDokumentDto[], AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['doklady'],
				queryFn: identityDocumentQuery,
				refetchOnWindowFocus: false,
				onError: () => setControls({ initialLoading: false }),
			},
			{
				queryKey: ownDocumentKeys().all,
				queryFn: async () =>
					documentsListQuery({
						kategorie: '#DOKLAD#',
						smazano: false,
						razeni: '-datumVytvoreni',
						start: 0,
						pocet: 10,
					}),
				refetchOnWindowFocus: false,
				onError: () => setControls({ initialLoading: false }),
			},
		],
		onAllSuccess: () => setControls({ initialLoading: false }),
	});

	const driversLicenseQuery = usePoQuery(
		{
			queryKey: [PageCode['registr-ridicu']],
			queryFn: async () => {
				const response = await driversRegistryQuery();
				return driversRegistryResponseTransform(response);
			},
			refetchOnWindowFocus: false,
		},
		{ errorIgnoreFilter: filterErrorStatus(400) }
	);

	const ownDocumentDeleteMutation = usePoMutation({
		mutationFn: documentDeleteQuery,
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.smazano'));
			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({ queryKey: ['documents-binned'] });
			await queryClient.invalidateQueries({ queryKey: ownDocumentKeys().all });
			setControls({ processLoading: false });
		},
		onError: (processError) => {
			setControls({ processLoading: false, processError });
		},
	});
	const documentPreviewQuery = useDocumentPreview();

	const driversLicense = driversLicenseQuery.data?.license
		? driversLicenceToDocument(driversLicenseQuery.data?.license)
		: undefined;

	const hasLicense = driversLicenseQuery.data?.hasLicense ?? false;

	const canChangeLicence =
		driversLicenseQuery.data?.license?.canChange ?? false;

	const documents = documentsQuery.data?.data.seznam ?? [];

	const getDocumentByType = (type: DokladyType): DokladyDokladDto[] => {
		if (type === DokladyType.RP && driversLicense) {
			return [driversLicense];
		}
		return documents.filter(propEq('druhDokladu', type));
	};

	return (
		<DokladyContext.Provider
			value={{
				controls,
				setControls,

				documents,
				isDocumentsLoading: documentsQuery.isLoading,
				documentsError: documentsQuery.error
					? new GovError(documentsQuery.error.message)
					: undefined,

				driversLicense,
				canChangeLicence,
				zadostNovyRidicskyPrukazUrl,
				hasLicense,
				isDriversLicenseLoading: driversLicenseQuery.isLoading,
				driversLicenseError: driversLicenseQuery.error
					? new GovError(driversLicenseQuery.error.message)
					: undefined,

				ownDocuments: ownDocumentsQuery.data ?? [],
				ownDocumentsError: ownDocumentsQuery.error
					? new GovError(ownDocumentsQuery.error.message)
					: undefined,

				getDocumentByType,
				onOwnDocumentDelete: ownDocumentDeleteMutation.mutate,
				documentPreviewQuery,
			}}>
			{children}
		</DokladyContext.Provider>
	);
}

export const useDokladyContext = (): IdentityDocumentsContext =>
	useContext(DokladyContext) as IdentityDocumentsContext;
