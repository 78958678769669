import { useQueryClient } from '@tanstack/react-query';
import {
	documentDeleteQuery,
	usePoMutation,
	DocumentDeleteParams,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';

export interface DocumentDeleteProps {
	onDeleteSuccess?: (params: DocumentDeleteParams) => void;
	onError?: (error: Error, params: DocumentDeleteParams) => void;
}

export const useDocumentDelete = ({
	onDeleteSuccess,
	onError,
}: DocumentDeleteProps) => {
	const queryClient = useQueryClient();

	const deleteMutation = usePoMutation({
		mutationFn: documentDeleteQuery,
		onError: (error, params) => {
			onError &&
				onError(
					new GovError(
						`Error: An unexpected error occurred while document deleting. ${error}`
					),
					params
				);
		},
		onSuccess: async (_, params) => {
			onDeleteSuccess && onDeleteSuccess(params);

			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({
				queryKey: ['documents-binned'],
			});
			await queryClient.invalidateQueries({ queryKey: ['uloziste-vyuziti'] });
		},
	});

	const deleteDocument = async ({
		id,
		permanent,
	}: DocumentDeleteParams): Promise<void> => {
		return deleteMutation.mutate({ id, permanent });
	};

	const deleteDocuments = async (
		documentIds: number[],
		permanent: boolean
	): Promise<void[]> => {
		return Promise.all(
			documentIds.map((id) => deleteDocument({ id, permanent }))
		);
	};

	return {
		deleteDocument,
		deleteDocuments,
		isDeleting: deleteMutation.isLoading,
	};
};
