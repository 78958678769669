import {
	GovChip,
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DATABOX_FOLDER_LABELS,
	DataBoxFolderType,
	formatIdentification,
	useDataBoxMessageDetailContext,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, CopyButton, DataTable, ToggleButton } from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';
import { DataBoxAttachmentList } from './DataBoxAttachmentList';
import { DataBoxMessageDataBoxDetail } from './DataBoxMessageDataBoxDetail';

interface DataBoxMessageOverviewProps {
	message: DatoveSchrankyDatovaZpravaDto;
}

export const DataBoxMessageOverview = ({
	message,
}: DataBoxMessageOverviewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { dataBoxId, folderType, recipientDataBox, senderDataBox } =
		useDataBoxMessageDetailContext();

	const { automaticArchiveEnabled } = useDataBoxesContext();

	return (
		<>
			<Button
				href={routeResolver(PageCode['datove-schranky-seznam'], {
					dataBoxId,
					folderType,
				})}
				variant="primary"
				type="base"
				className="[ ds:hidden ]"
				wcagLabel={tn(DATABOX_FOLDER_LABELS[folderType])}>
				<GovIcon
					name="chevron-left"
					slot="left-icon"
				/>
				{tn(DATABOX_FOLDER_LABELS[folderType])}
			</Button>
			<header
				className={
					'flex flex-col gap-2 h-auto pt-4 pb-6 px-4 bg-neutral-white [ md:px-6 ]'
				}>
				<div
					className={
						'flex flex-col [ md:flex-row md:justify-between md:gap-4 ]'
					}>
					<h1 className={'mb-1 text-m font-bold [ md:mb-0 md:text-xl ]'}>
						{message.vec}
					</h1>
					<time
						className={
							'text-s text-secondary-700 whitespace-nowrap [ md:mt-1.5 ]'
						}>
						{message.datumACasDodani &&
							toDateTimeReadable(message.datumACasDodani)}
					</time>
				</div>
				<dl className={'flex flex-col gap-1'}>
					<div className={'flex gap-2'}>
						<dt className={'text-secondary-700'}>{tn('zpravy.detail.od')}</dt>
						<dd className={'text-primary-600'}>
							{senderDataBox ? (
								<DataBoxMessageDataBoxDetail
									dataBox={senderDataBox}
									label={`${message.odesilatelNazev} (${message.odesilatelId})`}
								/>
							) : (
								<span className="font-normal text-primary-600">{`${message.odesilatelNazev} (${message.odesilatelId})`}</span>
							)}
						</dd>
					</div>
					<div className={'flex gap-2'}>
						<dt className={'text-secondary-700'}>{tn('zpravy.detail.komu')}</dt>
						<dd className={'text-primary-600'}>
							{recipientDataBox ? (
								<DataBoxMessageDataBoxDetail
									dataBox={recipientDataBox}
									label={`${message.prijemceNazev} (${message.prijemceId})`}
								/>
							) : (
								<span className="font-normal text-primary-600">{`${message.prijemceNazev} (${message.prijemceId})`}</span>
							)}
						</dd>
					</div>
				</dl>

				{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(
					folderType
				) ? (
					message.datumArchivace ? (
						<GovChip
							variant="success"
							size="xs"
							inverse
							className={'mt-1'}>
							<GovIcon
								name="archived-outline"
								slot="left-icon"
							/>
							{tn('zpravy.detail.archivovana')}
						</GovChip>
					) : automaticArchiveEnabled ? (
						<GovChip
							variant="secondary"
							size="xs"
							inverse
							className={'mt-1'}>
							<GovIcon
								name="archive-outline"
								slot="left-icon"
							/>
							{tn('zpravy.detail.bude-archivovana-automaticky')}
						</GovChip>
					) : null
				) : null}

				{[DataBoxFolderType.Archived, DataBoxFolderType.Trash].includes(
					folderType
				) ? (
					dataBoxId === message.prijemceId ? (
						<GovChip
							variant="secondary"
							size="xs"
							inverse
							className={'mt-1'}>
							<GovIcon
								name="envelope"
								slot="left-icon"
							/>
							{tn('slozky.dorucene')}
						</GovChip>
					) : (
						<GovChip
							variant="secondary"
							size="xs"
							inverse
							className={'mt-1'}>
							<GovIcon
								name="send"
								slot="left-icon"
							/>
							{tn('slozky.odeslane')}
						</GovChip>
					)
				) : null}
			</header>
			<div className={'bg-neutral-white [ md:h-full md:overflow-y-auto ]'}>
				<section className={'px-4 mb-4 [ md:px-6 ]'}>
					<ToggleButton>
						<div className={'ds-more-info-table flex flex-col gap-6'}>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{tn('zpravy.detail.id-zpravy')}</td>
										<td>
											<strong>{message.datovaZpravaId}</strong>
											{message.datovaZpravaId && (
												<CopyButton
													label={tn('schranky.zkopirovat-id-schranky')}
													value={message.datovaZpravaId}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.odesilatel')}</td>
										<td>
											{senderDataBox ? (
												<DataBoxMessageDataBoxDetail
													dataBox={senderDataBox}
													label={`${message.odesilatelNazev} (${message.odesilatelId})`}
													position={'right'}
												/>
											) : (
												<span className="font-normal text-primary-600">{`${message.odesilatelNazev} (${message.odesilatelId})`}</span>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.adresat')}</td>
										<td>
											{recipientDataBox ? (
												<DataBoxMessageDataBoxDetail
													dataBox={recipientDataBox}
													label={`${message.prijemceNazev} (${message.prijemceId})`}
													position={'right'}
												/>
											) : (
												<span className="font-normal text-primary-600">{`${message.odesilatelNazev} (${message.odesilatelId})`}</span>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.identifikace')}</td>
										<td>
											<strong>
												{message.odesilatelJmenoUzivatele
													? formatIdentification(
															message.odesilatelJmenoUzivatele
													  )
													: null}
											</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{tn('zpravy.detail.datum-dodani')}</td>
										<td>
											<strong>
												{message.datumACasDodani &&
													toDateTimeReadable(message.datumACasDodani)}
											</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={
													'inline-block relative w-4 h-4 ml-4 top-0.5'
												}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>{tn('zpravy.detail.kdy-zprava-dodana')}</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.datum-doruceni')}</td>
										<td>
											<strong>
												{message.datumACasDoruceni &&
													toDateTimeReadable(message.datumACasDoruceni)}
											</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={
													'inline-block relative w-4 h-4 ml-4 top-0.5'
												}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>{tn('zpravy.detail.kdy-zprava-prevzata')}</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{tn('zpravy.detail.do-vlastnich-rukou')}</td>
										<td>
											<strong>
												{message.doVlastnichRukou === true
													? tn('zpravy.detail.do-vlastnich-rukou-ano')
													: message.doVlastnichRukou === false
													? tn('zpravy.detail.do-vlastnich-rukou-ne')
													: '-'}
											</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={
													'inline-block relative w-4 h-4 ml-4 top-0.5'
												}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>{tn('zpravy.detail.obdoba-modry-pruh')}</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.k-rukam')}</td>
										<td>
											<strong>{message.kRukam ?? '-'}</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{tn('zpravy.detail.nase-cislo-jednaci')}</td>
										<td>
											<strong>{message.naseCisloJednaci ?? '-'}</strong>
											{message.naseCisloJednaci && (
												<CopyButton
													label={tn(
														'zpravy.detail.zkopirovat-nase-cislo-jednaci'
													)}
													value={message.naseCisloJednaci}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.nase-spisova-znacka')}</td>
										<td>
											<strong>{message.naseSpisovaZnacka ?? '-'}</strong>
											{message.naseSpisovaZnacka && (
												<CopyButton
													label={tn(
														'zpravy.detail.zkopirovat-nase-spisova-znacka'
													)}
													value={message.naseSpisovaZnacka}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.vase-cislo-jednaci')}</td>
										<td>
											<strong>{message.vaseCisloJednaci ?? '-'}</strong>
											{message.vaseCisloJednaci && (
												<CopyButton
													label={tn(
														'zpravy.detail.zkopirovat-vase-cislo-jednaci'
													)}
													value={message.vaseCisloJednaci}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>{tn('zpravy.detail.vase-spisova-znacka')}</td>
										<td>
											<strong>{message.vaseSpisovaZnacka ?? '-'}</strong>
											{message.vaseSpisovaZnacka && (
												<CopyButton
													label={tn(
														'zpravy.detail.zkopirovat-vase-spisova-znacka'
													)}
													value={message.vaseSpisovaZnacka}></CopyButton>
											)}
										</td>
									</tr>
								</tbody>
							</DataTable>
						</div>
					</ToggleButton>
				</section>

				<DataBoxAttachmentList message={message} />
			</div>
		</>
	);
};
