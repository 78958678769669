import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { DataBoxSearchState } from './store.types';

const isInHistory = (array: string[], string: string) =>
	array.some((str) => str.toLowerCase() === string.toLowerCase());

export const useDataBoxSearchStore = create<DataBoxSearchState>()(
	persist(
		(set, get) => ({
			searchHistory: [],

			setDataBoxSearchHistory: (databoxSearch: string) =>
				set((state: DataBoxSearchState) => ({
					searchHistory: isInHistory(state.searchHistory, databoxSearch)
						? state.searchHistory
						: [databoxSearch, ...state.searchHistory],
				})),
			deleteDataboxSearchHistory: (databoxSearch: string) =>
				set((state: DataBoxSearchState) => ({
					searchHistory: state.searchHistory.filter(
						(item) => item !== databoxSearch
					),
				})),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.dataBoxSearch,
		}
	)
);
