import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ProfileEDocumentsSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<div className={'p-4'}>
			<div className={'flex gap-4 mb-4'}>
				<GovSkeleton
					width={'120px'}
					height={'40px'}
					wcagLabel={
						tg('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'85px'}
					height={'40px'}
					wcagLabel={
						tg('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'130px'}
					height={'40px'}
					wcagLabel={
						tg('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'110px'}
					height={'40px'}
					wcagLabel={
						tg('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'90px'}
					height={'40px'}
					wcagLabel={
						tg('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'flex justify-between items-center'}>
				<GovSkeleton
					width={'130px'}
					height={'26px'}
					variant={'secondary'}
					wcagLabel={tg('loading.wcag.processing')}
				/>
				<GovSkeleton
					width={'73px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={tg('loading.wcag.processing')}
				/>
			</div>
			<div className={'p-4'}>
				<div className={'hidden [ sm:flex sm:flex-row sm:items-center ]'}>
					<div className={'w-2/5 mb-2'}>
						<GovSkeleton
							width={'70px'}
							height={'21px'}
							variant={'secondary'}
							wcagLabel={tg('loading.wcag.processing')}
						/>
					</div>
					<div className={'mb-2'}>
						<GovSkeleton
							width={'200px'}
							height={'21px'}
							variant={'secondary'}
							wcagLabel={tg('loading.wcag.processing')}
						/>
					</div>
				</div>
				{[0, 1].map((i) => {
					return (
						<div key={i}>
							<div
								className={
									'flex flex-col pb-2 [ sm:flex-row sm:items-center ]'
								}>
								<div className={'w-full [ sm:hidden ]'}>
									<GovSkeleton
										width={'45px'}
										height={'17px'}
										variant={'secondary'}
										wcagLabel={tg('loading.wcag.processing')}
									/>
								</div>
								<div className={'w-2/5 mb-2'}>
									<GovSkeleton
										width={generateRandomNumber(40, 60) + '%'}
										height={'21px'}
										variant={'secondary'}
										wcagLabel={tg('loading.wcag.processing')}
									/>
								</div>

								<div className={'w-full [ sm:hidden ]'}>
									<GovSkeleton
										width={'130px'}
										height={'17px'}
										variant={'secondary'}
										wcagLabel={tg('loading.wcag.processing')}
									/>
								</div>
								<div className={'w-[120px] mb-2 [ sm:w-[130px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'21px'}
										variant={'secondary'}
										wcagLabel={tg('loading.wcag.processing')}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
