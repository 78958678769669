import { Configuration } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';

export const useConfiguration = (): Configuration => {
	const { configuration } = useConfigurationStore();

	if (!configuration) {
		throw new GovError('[GOV] configuration not loaded yet');
	}

	return configuration;
};
