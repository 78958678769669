import { differenceInDays, isPast } from 'date-fns';
import { Nullable, Optional, isString } from '@gov-nx/core/types';
import { today } from '../Date/utils';

export const getTextColor = (
	input?: Nullable<Date> | string,
	daysLimit = 90
): { classname: Optional<string>; nativeColor: Optional<string> } => {
	if (!input) return { classname: undefined, nativeColor: undefined };
	const date = isString(input) ? today(input) : input;
	const now = today();

	if (isPast(date) || differenceInDays(date, now) < daysLimit)
		return { classname: 'gov-color--warning-500', nativeColor: '#C78300' };

	return { classname: undefined, nativeColor: undefined };
};
