// @TODO: replace this with iports from @gov-nx/core/helpers
import { ValueOf } from '@gov-nx/core/types';

type Pipe = {
	<T>(source: T): T;
	<T, A>(source: T, a: (value: T) => A): A;
	<T, A, B>(source: T, a: (value: T) => A, b: (value: A) => B): B;
	<T, A, B, C>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C
	): C;
	<T, A, B, C, D>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D
	): D;
	<T, A, B, C, D, E>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E
	): E;
	<T, A, B, C, D, E, F>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F
	): F;
	<T, A, B, C, D, E, F, G>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F,
		g: (value: F) => G
	): G;
	<T, A, B, C, D, E, F, G, H>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F,
		g: (value: F) => G,
		h: (value: G) => H
	): H;
	<T, A, B, C, D, E, F, G, H, I>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F,
		g: (value: F) => G,
		h: (value: G) => H,
		i: (value: H) => I
	): I;
	<T, A, B, C, D, E, F, G, H, I, J>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F,
		g: (value: F) => G,
		h: (value: G) => H,
		i: (value: H) => I,
		j: (value: I) => J
	): J;
	<T, A, B, C, D, E, F, G, H, I, J, K>(
		source: T,
		a: (value: T) => A,
		b: (value: A) => B,
		c: (value: B) => C,
		d: (value: C) => D,
		e: (value: D) => E,
		f: (value: E) => F,
		g: (value: F) => G,
		h: (value: G) => H,
		i: (value: H) => I,
		j: (value: I) => J,
		k: (value: J) => K
	): K;
};

export const pipe: Pipe = (source: unknown, ...fns: any[]) =>
	fns.reduce((accumulator, element) => element(accumulator), source);

export const hasOwnProperty = <X extends object, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, ValueOf<X>> => {
	return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const getProperty = <T, Key extends keyof T>(
	obj: T,
	property: Key
): undefined | T[Key] => {
	return isObject(obj) && hasOwnProperty(obj, property)
		? obj[property]
		: undefined;
};

export const compare =
	<T extends object>(properties: Array<keyof T>) =>
	(a: T, b: T): number => {
		const [property, ...rest] = properties;
		if (!property) {
			return 0;
		}
		if (a[property] < b[property]) {
			return -1;
		}
		if (a[property] > b[property]) {
			return 1;
		}
		return rest.length > 0 ? compare(rest)(a, b) : 0;
	};

export const isObject = <T extends object>(
	input: T | unknown
): input is object => typeof input === 'object';
export const isString = (input: unknown): input is string =>
	typeof input === 'string';
export const isNumber = (input: unknown): input is number =>
	typeof input === 'number';

export const map =
	<I, O>(fn: (input: I) => O) =>
	(arr: I[]) =>
		arr.map(fn);

export const reduceAddSpread = <I, O>(fn: (t: I) => O[], array: I[]) => {
	return array.reduce((all, current) => {
		return [...all, ...fn(current)];
	}, [] as O[]);
};

export const reduceAddSpreadCurried =
	(fn: Parameters<typeof reduceAddSpread>[0]) =>
	(array: Parameters<typeof reduceAddSpread>[1]) =>
		reduceAddSpread(fn, array);

export const reduceAdd = <I, O>(fn: (t: I) => O, array: I[]) => {
	return array.reduce((all, current) => {
		return [...all, fn(current)];
	}, [] as O[]);
};
export const reduceAddCurried =
	(fn: Parameters<typeof reduceAdd>[0]) =>
	(array: Parameters<typeof reduceAdd>[1]) =>
		reduceAdd(fn, array);
