export const isFulfilled = <T>(
	result: PromiseSettledResult<T>
): result is PromiseFulfilledResult<T> => {
	return result.status === 'fulfilled';
};

export const isRejected = <T>(
	result: PromiseSettledResult<T>
): result is PromiseRejectedResult => {
	return result.status === 'rejected';
};
