import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DownloadEPetitionSubscription,
	useDownloadEvents,
	useMessageEvents,
} from '@gov-nx/core/events';
import { usePetitionDownload } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DownloadCallback } from '../Download/types';

export const DownloadEPetitionListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();

	const [callbacks, setCallbacks] = useState<{
		[key: number]: Nullable<DownloadCallback<number>['callback']>;
	}>({});

	const { mutate } = usePetitionDownload({
		onSuccess: (data) => {
			download({
				blob: data.blob,
				id: data.petitionId.toString(),
				antivirus: data.antivirus,
				fileName: data.fileName,
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.petitionId);
					}
					if (error) {
						errorMessage();
						onDownload(data.petitionId, error);
					}
				},
			});
		},
		onError: () => {
			errorMessage();
		},
	});

	const onEPetitionDownload = useCallback(
		(
			_: DownloadEPetitionSubscription['type'],
			{ petitionId, callback }: DownloadEPetitionSubscription['payload']
		) => {
			if (callback) {
				setCallbacks((state) => ({
					...state,
					[petitionId]: callback,
				}));
			}

			setTimeout(() => {
				mutate(petitionId);
			}, 0);
		},
		[mutate]
	);

	useDownloadEvents({
		onEPetitionDownload,
	});

	const onDownload = useCallback(
		(petitionId: number, error?: Error) => {
			const callbackFunction = callbacks[petitionId];
			if (callbackFunction) {
				callbackFunction(petitionId, !error, error ?? null);
				setCallbacks((state) => ({ ...state, [petitionId]: null }));
			}
		},
		[callbacks]
	);

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-uspech', {
				namespace: LocalizeNameSpaceTypes.Service,
				fileName,
			}),
			options: {
				variant: 'success',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-chyba', {
				namespace: LocalizeNameSpaceTypes.Service,
			}),
			options: {
				variant: 'error',
				icon: {
					name: 'exclamation-triangle-fill',
					type: 'basic',
				},
			},
		});
	};

	return null;
};
