import { useCallback, useState } from 'react';
import { useCmsExternalServiceQuery } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { useCmsStore } from '@gov-nx/store/portal-obcana';
import { delay } from '@gov-nx/utils/common';

export interface CmsExternalServiceLoaderProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useCmsExternalServiceLoader = (
	props?: CmsExternalServiceLoaderProps
) => {
	const { saveCmsExternalService, getCmsExternalService } = useCmsStore();
	const [id, setId] = useState<Nullable<number>>(null);
	const externalService = getCmsExternalService(id);

	const query = useCmsExternalServiceQuery({
		id,
		onError: (message: string) => {
			props?.onError && props?.onError(new GovError(message));
		},
		onSuccess: (data) => saveCmsExternalService(id as number, data),
	});

	const loadExternalService = useCallback(
		async (id: number): Promise<void> => {
			setId(id);
			await delay(200);
			await query.refetch();
		},
		[query]
	);

	return { loadExternalService, externalService };
};
