import * as yup from 'yup';
import { CiselnikyVolbyDto } from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { getValues } from '@gov-nx/core/types';
import { IElectionControl, ServiceCode } from '@gov-nx/module/service';
import {
	FormDataStep3,
	ZpusobPrevzeti,
	ZpusobPrevzetiUrad,
	ZpusobPrevzetiPosta,
} from './context.types';
import { IFormStep3 } from './form.types';
import { useVoterRegistrationCardStrings } from './step3StringsHook';

interface FormStep3Props {
	code: ServiceCode;
	embassyOfficesOptions: { value: number; label: string }[];
	checkMutationData?: IElectionControl;
	election?: CiselnikyVolbyDto;
}

export const FormStep3 = ({
	code,
	embassyOfficesOptions,
	checkMutationData,
	election,
}: FormStep3Props): IFormStep3 => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep3>>({
			zpusobPrevzeti: yup
				.string()
				.oneOf(getValues(ZpusobPrevzeti))
				.required(tsn('povinne-pole')),
			zpusobPrevzetiPosta: yup.string().oneOf(getValues(ZpusobPrevzetiPosta)),
			zpusobPrevzetiUrad: yup.string().oneOf(getValues(ZpusobPrevzetiUrad)),
			ulice: yup.string().when('zpusobPrevzetiPosta', {
				is: ZpusobPrevzetiPosta.JINA_NEZ_TRVALA_ADRESA,
				then: yup.string().required(tsn('povinne-pole')),
				otherwise: yup.string().optional(),
			}),
			obec: yup.string().when('zpusobPrevzetiPosta', {
				is: ZpusobPrevzetiPosta.JINA_NEZ_TRVALA_ADRESA,
				then: yup.string().required(tsn('povinne-pole')),
				otherwise: yup.string().optional(),
			}),
			cislo: yup.string().when('zpusobPrevzetiPosta', {
				is: ZpusobPrevzetiPosta.JINA_NEZ_TRVALA_ADRESA,
				then: yup.string().required(tsn('povinne-pole')),
				otherwise: yup.string().optional(),
			}),
			stat: yup.string().when('zpusobPrevzetiPosta', {
				is: ZpusobPrevzetiPosta.JINA_NEZ_TRVALA_ADRESA,
				then: yup.string().required(tsn('povinne-pole')),
				otherwise: yup.string().optional(),
			}),
			volitNaOkrskuVZahraniciId: yup.string().when('zpusobPrevzetiUrad', {
				is: ZpusobPrevzetiUrad.URAD_ZAHRANICNI,
				then: yup.string().required(tsn('povinne-pole')),
				otherwise: yup.string().optional(),
			}),
		})
		.required();

	const formMethods = usePoForm<FormDataStep3>({
		formSchema,
		defaultValues: {
			zpusobPrevzeti: undefined,
			zpusobPrevzetiPosta: ZpusobPrevzetiPosta.TRVALA_ADRESA,
			zpusobPrevzetiUrad: ZpusobPrevzetiUrad.DLE_TRVALEHO_BYDLISTE,
			obec: undefined,
			ulice: undefined,
			cislo: undefined,
			stat: undefined,
		},
	});

	const strings = useVoterRegistrationCardStrings(checkMutationData, election);

	return {
		fields: {
			volitNaOkrskuVZahranici: {
				label: { children: tsn('formular.pole.misto-zastupitelskehu-uradu') },
				field: {
					name: 'volitNaOkrskuVZahraniciId',
					options: [
						{
							value: '',
							label: tsn('formular.pole.misto-zastupitelskehu-uradu-zvolte'),
							isDisabled: true,
						},
						...embassyOfficesOptions,
					],
				},
			},
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
		strings,
	};
};
