import * as yup from 'yup';
import {
	FormSchemaShape,
	getFormDefinition,
	useLocale,
	usePoForm,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { RadioBooleanOption } from '@gov-nx/ui/types';
import { FormDataStep2a } from './context.types';
import { IFormStep2a } from './form.types';

interface FormStep2Props {
	code: ServiceCode;
	isProcessing: boolean;
	embassyOfficesOptions: { value: number; label: string }[];
}

export const FormStep2a = ({
	code,
	embassyOfficesOptions,
	isProcessing,
}: FormStep2Props): IFormStep2a => {
	const { t } = useLocale(code);

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep2a>>({
			livesAbroad: yup.string().oneOf(Object.values(RadioBooleanOption)),
			onSpecialList: yup.string().oneOf(Object.values(RadioBooleanOption)),

			embassyId: yup.string(),
		})
		.required();

	const formMethods = usePoForm<FormDataStep2a>({
		formSchema,
		defaultValues: {
			livesAbroad: RadioBooleanOption.no,
			onSpecialList: RadioBooleanOption.no,
			embassyId: '',
		},
	});

	return {
		fields: {
			embassy: {
				label: { children: t('formular.pole.misto-zastupitelskehu-uradu') },
				field: {
					name: 'embassyId',
					disabled: isProcessing,
					isDisabled: isProcessing,
					options: [
						{
							value: '',
							label: t('formular.pole.misto-zastupitelskehu-uradu-zvolte'),
							isDisabled: true,
						},
						...embassyOfficesOptions,
					],
				},
			},
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
};
