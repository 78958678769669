import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { generateRandomNumber } from '@gov-nx/utils/common';
import { axiosCmsInstance } from '../axios/instance';
import { prepareConfiguration } from './configuration';
import { Configuration, ConfigurationResponse } from './configuration.types';

export const useConfigurationQuery = (props?: {
	onError?: (errorMessage: string) => void;
	onSuccess?: (configuration: Configuration) => void;
}) => {
	return useQuery({
		onError: props?.onError,
		onSuccess: props?.onSuccess,
		queryFn: async () => {
			const cacheKey = generateRandomNumber(10000, 100000);
			const response: AxiosResponse<ConfigurationResponse> =
				await axiosCmsInstance.get('/configurations.json?c=' + cacheKey);
			const { data, meta } = response.data;
			const updatedConfiguration = prepareConfiguration(data);
			updatedConfiguration.cmsKeyCache = meta.version;
			return updatedConfiguration;
		},
		queryKey: ['settings'],
		retry: 1,
	});
};
