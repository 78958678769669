import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfilCertificatesContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	DataTable,
	ErrorStatusBanner,
	FormFileUpload,
	InfoBanner,
	LayoutGap,
	Prompt,
	SortArrows,
} from '@gov-nx/ui/web';
import { getFormattedPublisher, toDateReadable } from '@gov-nx/utils/common';
import { PoFormWeb } from '../../Form/PoForm';
import { ProfilCertificatesSkeleton } from './ProfilCertificatesSkeleton';

export interface CertificatesFormProps {
	navigation: ReactNode;
	code: PageCode;
}

export const ProfileCertificatesView = ({
	navigation,
	code,
}: CertificatesFormProps) => {
	const {
		certificates,
		controls,
		setControls,
		deleteCertificate,
		hasPermission,
		formDefinition,
		disableAddButton,
		isSubmitting,
		onSubmit,
		sortCertificates,
		fileUploadOptions,
	} = useProfilCertificatesContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return (
			<ContentLayout customClasses={'!pt-4'}>
				{navigation}
				<ProfilCertificatesSkeleton />
			</ContentLayout>
		);
	}

	if (controls.initialDataError) {
		return (
			<ContentLayout customClasses={'!pt-4'}>
				{navigation}
				<ErrorStatusBanner code={code} />
			</ContentLayout>
		);
	}

	return (
		<ContentLayout customClasses={'!pt-4'}>
			{navigation}
			<LayoutGap gap={4}>
				{Array.isArray(certificates) && certificates.length === 0 ? (
					<InfoBanner
						icon={{ name: 'empty-file', type: 'colored' }}
						type="secondary"
						className={'!px-0 !py-4'}>
						<p className={'text-2xl [ md:text-3xl ]'}>
							{ts('stav.chyba.slozka-je-prazdna')}
						</p>
						<p>{ts('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
					</InfoBanner>
				) : (
					<DataTable responsive>
						<thead>
							<tr>
								<th
									className={
										'!pl-2 !pr-0 !py-0 text-left font-normal text-primary-600'
									}>
									{ts('zaznam.vydavatel')}
									<SortArrows
										descWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.vydavatel'
										)} - ${ts('razeni.sestupne')}`}
										descOnClick={() => sortCertificates('vydavatel', 'desc')}
										ascWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.vydavatel'
										)} - ${ts('razeni.vzestupne')}`}
										ascOnClick={() => sortCertificates('vydavatel', 'asc')}
									/>
								</th>
								<th
									className={
										'!pl-4 !pr-0 !py-0 text-left font-normal text-primary-600'
									}>
									{ts('zaznam.seriove-cislo')}
									<SortArrows
										descWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.seriove-cislo'
										)} - ${ts('razeni.sestupne')}`}
										descOnClick={() => sortCertificates('serioveCislo', 'desc')}
										ascWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.seriove-cislo'
										)} - ${ts('razeni.vzestupne')}`}
										ascOnClick={() => sortCertificates('serioveCislo', 'asc')}
									/>
								</th>
								<th
									className={
										'!pl-4 !pr-0 !py-0 text-left font-normal text-primary-600'
									}>
									{ts('zaznam.platnost')}
									<SortArrows
										descWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.platnost'
										)} - ${ts('razeni.sestupne')}`}
										descOnClick={() => sortCertificates('platnostDo', 'desc')}
										ascWcagLabel={`${ts('razeni.title')}: ${ts(
											'zaznam.platnost'
										)} - ${ts('razeni.vzestupne')}`}
										ascOnClick={() => sortCertificates('platnostDo', 'asc')}
									/>
								</th>
								<th className={'!pl-4 !pr-0 !py-0 w-14'}></th>
							</tr>
						</thead>
						<tbody>
							{certificates?.map((cert) => (
								<tr key={cert.serioveCislo}>
									<td className={'!px-0 [ sm:!pl-2 ]'}>
										<div className={'sm:hidden'}>{ts('zaznam.vydavatel')}:</div>
										<div
											className={'mb-1 font-bold [ sm:mb-0 sm:font-normal ]'}>
											{getFormattedPublisher(cert.vydavatel)}
										</div>
									</td>
									<td className={'!px-0 [ sm:!pl-4 ]'}>
										<div className={'sm:hidden'}>
											{ts('zaznam.seriove-cislo')}:
										</div>
										<div
											className={'mb-1 font-bold [ sm:mb-0 sm:font-normal ]'}>
											{cert.serioveCislo}
										</div>
									</td>
									<td className={'!px-0 [ sm:!pl-4 ]'}>
										<div className={'sm:hidden'}>{ts('zaznam.platnost')}:</div>
										<div
											className={'mb-1 font-bold [ sm:mb-0 sm:font-normal ]'}>
											{cert.platnostDo ? toDateReadable(cert.platnostDo) : ''}
										</div>
									</td>
									<td className={'!px-0 [ sm:!pl-4 ]'}>
										<Button
											onClick={() => {
												setControls({
													displayRemoveModal: true,
													selectedCertificate: cert,
												});
											}}
											variant="error"
											size="s"
											type="base"
											disabled={!hasPermission}
											wcagLabel={ts('akce.odstranit-certifikat')}>
											<GovIcon
												name={'x-lg'}
												type={'basic'}></GovIcon>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</DataTable>
				)}
				<ButtonGroupLayout>
					<Button
						onClick={() => {
							setControls({ displayCertificateAddingModal: true });
						}}
						nativeType="submit"
						variant="primary"
						disabled={!hasPermission}
						size="l"
						type="solid"
						wcagLabel={ts('akce.pridat-certifikat')}>
						{ts('akce.pridat-certifikat')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
			<Prompt
				label={ts('certifikaty.odstranit-certifikat')}
				open={controls.displayRemoveModal}
				onGov-close={() => setControls({ displayRemoveModal: false })}>
				<p
					dangerouslySetInnerHTML={{
						__html: ts('certifikaty.chcete-odstranit'),
					}}
				/>
				<div
					slot={'actions'}
					className={'flex justify-end gap-4'}>
					{controls.displayRemoveModal ? (
						<>
							<Button
								onClick={() => setControls({ displayRemoveModal: false })}
								variant={'primary'}
								type={'base'}
								wcagLabel={ts('akce.zrusit')}>
								{ts('akce.zrusit')}
							</Button>
							<Button
								onClick={() => {
									setControls({
										initialLoading: true,
										displayRemoveModal: false,
									});
									deleteCertificate(controls.selectedCertificate);
								}}
								variant={'error'}
								type={'solid'}
								wcagLabel={ts('akce.odstranit')}>
								{ts('akce.odstranit')}
							</Button>
						</>
					) : null}
				</div>
			</Prompt>
			<GovModal
				label={ts('certifikaty.pridat-certifikat')}
				open={controls.displayCertificateAddingModal}
				onGov-close={() => {
					setControls({ displayCertificateAddingModal: false });
					formDefinition.formReset();
				}}>
				<div>
					{controls.displayCertificateAddingModal ? (
						<FormProvider {...formDefinition.formMethods}>
							<PoFormWeb
								formDefinition={formDefinition}
								onSubmit={onSubmit}>
								<FormFileUpload
									field={{ name: 'nazevSouboru', expanded: true }}
									options={fileUploadOptions}
								/>
								<Button
									disabled={disableAddButton}
									loading={isSubmitting}
									nativeType={'submit'}
									variant={'primary'}
									size={'l'}
									type={'solid'}
									className={'mt-4'}
									wcagLabel={ts('akce.pridat-certifikat')}>
									{ts('akce.pridat-certifikat')}
								</Button>
							</PoFormWeb>
						</FormProvider>
					) : null}
				</div>
			</GovModal>
		</ContentLayout>
	);
};
