import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { RegistrSVFormData } from './context.types';

export function FormDefinitionInstance(): FormDefinition<RegistrSVFormData> {
	const formSchema = yup
		.object<FormSchemaShape<RegistrSVFormData>>({
			filterVehicleDetails: yup.object({
				vehicle: yup.boolean(),
				motor: yup.boolean(),
				dimensions: yup.boolean(),
				weight: yup.boolean(),
				chassis: yup.boolean(),
				noise: yup.boolean(),
				others: yup.boolean(),
			}),
			filterDocuments: yup.object({
				prehledRz: yup.boolean(),
				prehledTp: yup.boolean(),
				prehledOrv: yup.boolean(),
				vehicleStatus: yup.boolean(),
			}),
		})
		.required();

	const formMethods = usePoForm<RegistrSVFormData>({
		formSchema,
		defaultValues: {
			filterVehicleDetails: {
				vehicle: true,
				motor: false,
				dimensions: false,
				weight: false,
				chassis: false,
				noise: false,
				others: false,
			},
			filterDocuments: {
				prehledRz: true,
				prehledTp: false,
				prehledOrv: false,
				vehicleStatus: false,
			},
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}
