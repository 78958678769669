import {
	axiosInstance,
	CiselnikyCiselnikPolozkaDto,
	CiselnikyVolbySeznamDto,
} from '@gov-nx/api/portal-obcana';

export const electionsQuery = async (): Promise<CiselnikyVolbySeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/volby');
	// hodi se pro lokalni mockovani
	// return {
	// 	seznam: [
	// 		{
	// 			druh: 'SENAT',
	// 			volbyId: 14494,
	// 			kola: [
	// 				{
	// 					kolo: 1,
	// 					terminOd: '2022-11-23T00:00:00Z',
	// 					terminDo: '2022-11-24T00:00:00Z',
	// 					lzePodatZadost: true,
	// 					zadostPodana: false,
	// 				},
	// 				{
	// 					kolo: 2,
	// 					terminOd: '2022-11-30T00:00:00Z',
	// 					terminDo: '2022-12-01T00:00:00Z',
	// 					lzePodatZadost: true,
	// 					zadostPodana: false,
	// 				},
	// 			],
	// 		},
	// 	],
	// 	pocet: 1,
	// };
	return data;
};

export interface CiselnikyCiselnikPolozkaDtoEmbassyOffice
	extends CiselnikyCiselnikPolozkaDto {
	parametry: {
		adresaText?: string;
	};
}

export const embassyOfficeListQuery = async (): Promise<{
	seznam?: CiselnikyCiselnikPolozkaDtoEmbassyOffice[];
}> => {
	const { data } = await axiosInstance.get(
		'/api/v1/ciselniky/VOLBY_ZAHRANICNI_VO/polozky'
	);
	return data;
};
