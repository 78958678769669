import { GovIcon, GovTooltip } from '@gov-design-system-ce/react';
import { isVoterRegistrationSubmission } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { pipe, addWhen } from '@gov-nx/core/types';
import {
	useMojePodaniDatovaZpravaInstance,
	useMojePodaniDetailContextInstance,
} from '@gov-nx/module/page';
import { ContentLayout, DataTable, ToggleButton } from '@gov-nx/ui/web';
import { ConnectToAnotherSubmission } from './ConnectToAnotherSubmission';
import { MySubmissionAttachments } from './MySubmissionAttachments';
import { MySubmissionPreview } from './MySubmissionPreview';
import { MySubmissionsMessageSkeleton } from './MySubmissionsDetailSkeleton';
import { VoterRegistrationMessage } from './VoterRegistrationMessage';
import { CopyOrEmpty, Row } from './components';

export const MySubmissionMessage = () => {
	const { submission, code, powerOfAttorney } =
		useMojePodaniDetailContextInstance();
	const {
		toggleAccordeon,
		query,
		dataMessageDataboxId,
		dataMessage,
		display,
		message,
		datumACasDodani,
	} = useMojePodaniDatovaZpravaInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<li>
			<ContentLayout>
				<header>
					<button
						type={'button'}
						onClick={() => toggleAccordeon()}
						aria-haspopup={true}
						aria-expanded={display.value}
						aria-label={pipe(
							[ts('akce.wcag.zobrazit-komunikaci')],
							addWhen(!!datumACasDodani, datumACasDodani),
							(chunks) => chunks.join(' ')
						)}
						className={
							'flex justify-between items-center w-full py-4 text-left text-xl text-primary-600 rounded anim-bg-hover custom-focus [ md:px-2 ]'
						}>
						<h3>{datumACasDodani}</h3>
						<GovIcon
							name={display.value ? 'chevron-up' : 'chevron-down'}
							className={'w-6 h-6'}
						/>
					</button>
				</header>
				{query.isFetching ? <MySubmissionsMessageSkeleton /> : null}
				{dataMessage && display.value ? (
					<div>
						<MySubmissionPreview
							code={code}
							dataMessage={dataMessage}
							submission={submission}
						/>
						<ToggleButton id="my-submission-detail-show-table">
							<DataTable
								responsive
								customClasses={'mt-3 mb-4'}>
								<tbody>
									<Row label={ts('data.id-datove-zpravy')}>
										<CopyOrEmpty
											value={dataMessage.datovaZpravaId}
											label={ts('akce.zkopirovat-id-datovky')}
										/>
									</Row>
									<tr>
										<td>{ts('data.do-vlastnich-rukou')}</td>
										<td className={'flex-row items-center flex-1'}>
											<strong>
												{dataMessage.doVlastnichRukou
													? ts('data.do-vlastnich-rukou-ano')
													: ts('data.do-vlastnich-rukou-ne')}
											</strong>
											<GovTooltip
												position="right"
												size="s"
												message={ts('zprava.info')}
												className={'text-left border-b-0'}>
												<GovIcon
													name={'info'}
													type={'basic'}
													className={'ml-2 align-middle w-4'}
												/>
											</GovTooltip>
										</td>
									</tr>
									<Row label={ts('data.k-rukam')}>
										{dataMessage.kRukam ?? '-'}
									</Row>
									<Row label={ts('data.nase-cislo-jednaci')}>
										<CopyOrEmpty
											value={dataMessage.naseCisloJednaci}
											label={ts('akce.zkopirovat-nase-cislo-jednaci')}
										/>
									</Row>
									<Row label={ts('data.nase-spisova-znacka')}>
										<CopyOrEmpty
											value={dataMessage.naseSpisovaZnacka}
											label={ts('akce.zkopirovat-nasi-spisovou-znacku')}
										/>
									</Row>
									<Row label={ts('data.vase-cislo-jednaci')}>
										<CopyOrEmpty
											value={dataMessage.vaseCisloJednaci}
											label={ts('akce.zkopirovat-vase-cislo-jednaci')}
										/>
									</Row>
									<Row label={ts('data.vase-spisova-znacka')}>
										<CopyOrEmpty
											value={dataMessage.vaseSpisovaZnacka}
											label={ts('akce.zkopirovat-vasi-spisovou-znacku')}
										/>
									</Row>
								</tbody>
							</DataTable>
							<footer>
								<ConnectToAnotherSubmission message={message} />
							</footer>
						</ToggleButton>
						{dataMessage.datovaZpravaId && dataMessageDataboxId ? (
							<div className={'py-4'}>
								<MySubmissionAttachments
									code={code}
									messageId={dataMessage.datovaZpravaId}
									dataBoxId={dataMessageDataboxId}
									attachments={dataMessage.prilohy ?? []}
								/>
							</div>
						) : null}

						{submission && isVoterRegistrationSubmission(submission) ? (
							<VoterRegistrationMessage
								submission={submission}
								onDownload={(onSuccess) => powerOfAttorney.download(onSuccess)}
							/>
						) : null}
					</div>
				) : null}
			</ContentLayout>
		</li>
	);
};
