import { Nullable } from '@gov-nx/core/types';

export function replaceVariablesInUrl(
	inputString: string,
	valueObject: Nullable<Record<string, any>>
): string {
	const queryParams: string[] = [];

	if (typeof valueObject === 'object') {
		for (const key in valueObject) {
			if (Object.prototype.hasOwnProperty.call(valueObject, key)) {
				const value = valueObject[key];
				const colonKey = `:${key}`;

				if (inputString.includes(colonKey)) {
					inputString = inputString.replace(colonKey, value);
				} else {
					queryParams.push(`${key}=${value}`);
				}
			}
		}
	}

	if (queryParams.length > 0) {
		const queryString = queryParams.join('&');
		inputString += inputString.includes('?')
			? `&${queryString}`
			: `?${queryString}`;
	}

	return inputString;
}
