import { useLocale } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { ConfirmationPrompt } from '@gov-nx/ui/web';

export const ConfirmPrompts = () => {
	const {
		myFilesSelection,
		documentDelete,
		deleteConfirm,
		deletePermanentConfirm,
		code,
		emptyRecycleBin,
	} = useMojeSouborySeznamContext();
	const { t } = useLocale(code);

	return (
		<>
			<ConfirmationPrompt
				isOpen={deletePermanentConfirm.value}
				strings={{
					title: t('soubor.akce.smazat.permanentne.potvrzeni.titulek'),
					buttons: {
						cancel: {
							label: t('soubor.akce.smazat.permanentne.potvrzeni.nemazat'),
							wcagLabel: t('soubor.akce.smazat.permanentne.nemazat'),
						},
						confirm: {
							label: t('soubor.akce.smazat.permanentne.potvrzeni.smazat'),
							wcagLabel: t('soubor.akce.smazat.permanentne.potvrzeni.smazat'),
						},
					},
				}}
				onCancel={deletePermanentConfirm.setFalse}
				onConfirm={() =>
					documentDelete.deleteDocuments(myFilesSelection.selectedIds, true)
				}
				isDisabled={documentDelete.isDeleting}
				isLoading={documentDelete.isDeleting}
				children={t('soubor.akce.smazat.permanentne.potvrzeni.otazka')}
			/>
			<ConfirmationPrompt
				isOpen={deleteConfirm.value}
				strings={{
					title: t('soubor.akce.smazat.potvrzeni.titulek'),
					buttons: {
						cancel: {
							label: t('soubor.akce.smazat.potvrzeni.nemazat'),
							wcagLabel: t('soubor.akce.smazat.potvrzeni.nemazat'),
						},
						confirm: {
							label: t('soubor.akce.smazat.potvrzeni.smazat'),
							wcagLabel: t('soubor.akce.smazat.potvrzeni.smazat'),
						},
					},
				}}
				onCancel={deleteConfirm.setFalse}
				onConfirm={() =>
					documentDelete.deleteDocuments(myFilesSelection.selectedIds, false)
				}
				isDisabled={documentDelete.isDeleting}
				isLoading={documentDelete.isDeleting}
				children={t('soubor.akce.smazat.potvrzeni.otazka')}
			/>
			<ConfirmationPrompt
				isOpen={emptyRecycleBin.emptyBinModal.value}
				strings={{
					title: t('akce.vysypat-kos-nadpis'),
					buttons: {
						cancel: {
							label: t('akce.vysypat-kos-zrusit'),
							wcagLabel: t('akce.vysypat-kos-zrusit'),
						},
						confirm: {
							label: t('akce.vysypat-kos-potvrzeni'),
							wcagLabel: t('akce.vysypat-kos-potvrzeni'),
						},
					},
				}}
				onCancel={emptyRecycleBin.emptyBinModal.setFalse}
				onConfirm={emptyRecycleBin.emptyDocumentRecycleBin}
				isDisabled={emptyRecycleBin.isEmptying}
				isLoading={emptyRecycleBin.isEmptying}
				children={t('akce.vysypat-kos-popis')}
			/>
		</>
	);
};
