import { useEffect } from 'react';
import { CmsExternalServiceModalSubscription } from './cms.types';
import {
	cmsEvents,
	cmsExternalServiceModalSubscription,
	CmsSubscriptionTypes,
} from './cmsEvents';

interface CmsEventsProps {
	onCmsExternalServiceModal?: (
		action: CmsExternalServiceModalSubscription['type'],
		payload: CmsExternalServiceModalSubscription['payload']
	) => void;
}

export const useCmsEvents = ({
	onCmsExternalServiceModal,
}: CmsEventsProps = {}) => {
	useEffect(() => {
		const cmsExternalServiceModalSub =
			onCmsExternalServiceModal &&
			cmsEvents.subscribe(
				CmsSubscriptionTypes.externalServiceModal,
				onCmsExternalServiceModal
			);

		return () => {
			cmsExternalServiceModalSub?.unSubscribe();
		};
	}, []);

	const cmsExternalServiceModal = (
		payload: CmsExternalServiceModalSubscription['payload']
	) => {
		cmsEvents.publish(cmsExternalServiceModalSubscription(payload));
	};

	return { cmsExternalServiceModal };
};
