import {
	GovButton,
	GovControlGroup,
	GovDropdown,
	GovIcon,
} from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout } from '@gov-nx/ui/web';

export const ActionsBinned = () => {
	const { code, documentRestore, deletePermanentConfirm, myFilesSelection } =
		useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<>
			<ButtonGroupLayout customClasses={'hidden [ md:flex ]'}>
				<Button
					wcagLabel={tsn('akce.obnovit')}
					variant="primary"
					type="solid"
					size="l"
					loading={documentRestore.isRestoring}
					onClick={() =>
						documentRestore.restoreDocuments(myFilesSelection.selectedIds)
					}>
					<GovIcon
						slot="left-icon"
						name="trash-restore"></GovIcon>
					{tsn('akce.obnovit', { count: myFilesSelection.selectedIds.length })}
				</Button>
				<Button
					wcagLabel={tsn('akce.trvale-odstranit')}
					variant="error"
					type="outlined"
					size="l"
					onClick={deletePermanentConfirm.setTrue}>
					<GovIcon
						slot="left-icon"
						name="x-lg"></GovIcon>
					{tsn('akce.trvale-odstranit', {
						count: myFilesSelection.selectedIds.length,
					})}
				</Button>
			</ButtonGroupLayout>

			<GovControlGroup
				variant="primary"
				className={'table-cell w-auto [ md:hidden ]'}>
				<GovButton
					variant="primary"
					type="base"
					size="s">
					<GovIcon
						slot="left-icon"
						name="trash-restore"
					/>
					{tsn('akce.obnovit', { count: myFilesSelection.selectedIds.length })}
				</GovButton>
				<GovDropdown position="right">
					<GovButton
						variant="primary"
						type="base"
						size="s">
						<GovIcon
							name="chevron-down"
							slot="left-icon"
						/>
					</GovButton>
					<ul slot="list">
						<li>
							<Button
								wcagLabel={tsn('akce.obnovit')}
								variant="secondary"
								type="base"
								size="m"
								onClick={() =>
									documentRestore.restoreDocuments(myFilesSelection.selectedIds)
								}>
								<GovIcon
									slot="right-icon"
									name="trash-restore"></GovIcon>
								{tsn('akce.obnovit', {
									count: myFilesSelection.selectedIds.length,
								})}
							</Button>
						</li>
						<li>
							<Button
								wcagLabel={tsn('akce.trvale-odstranit')}
								variant="secondary"
								type="base"
								size="m"
								onClick={deletePermanentConfirm.setTrue}>
								<GovIcon
									slot="right-icon"
									name="x-lg"></GovIcon>
								{tsn('akce.trvale-odstranit', {
									count: myFilesSelection.selectedIds.length,
								})}
							</Button>
						</li>
					</ul>
				</GovDropdown>
			</GovControlGroup>
		</>
	);
};
