import {
	PeticePeticeDto,
	ePetitionExtendQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { EPetitionStatus, isMonthBeforeClosureDate } from '@gov-nx/module/page';

export interface PetitionExtendProps {
	petition: Nullable<PeticePeticeDto>;
	onExtendSuccess: () => void;
	onExtendError: () => void;
}

export const usePetitionExtend = ({
	petition,
	onExtendSuccess,
	onExtendError,
}: PetitionExtendProps) => {
	const extendQuery = usePoMutation({
		mutationKey: ['e-petition-extend'],
		mutationFn: (petitionId: number) => {
			return ePetitionExtendQuery(petitionId);
		},
		onSuccess: () => onExtendSuccess && onExtendSuccess(),
		onError: () => onExtendError && onExtendError(),
	});

	const isPublic = petition?.stav === EPetitionStatus.zverejnena;
	const isFounder = petition?.mnouZalozene;
	const isUnderExtendLimit =
		!petition?.pocetProdlouzeni || petition.pocetProdlouzeni < 6;

	const monthBeforeClosure = petition?.datumUkonceni
		? isMonthBeforeClosureDate(petition.datumUkonceni)
		: false;

	const canBeExtended =
		isPublic && isFounder && isUnderExtendLimit && monthBeforeClosure;

	return { canBeExtended, extendQuery };
};
