import { useAddressSearch } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfilROSReklamaceContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	FormInput,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';

export interface AddressFormProps {
	code: PageCode;
	isProcessing: boolean;
}

export const AddressSearchForm = ({ code, isProcessing }: AddressFormProps) => {
	const { address, mainQuery, formDefinition, setControls, controls } =
		useProfilROSReklamaceContextInstance();
	const { disableSearchButton } = useAddressSearch({
		formMethods: formDefinition.formMethods,
	});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<FormWidthElementLayout type={'l'}>
			<LayoutGap gap={4}>
				<FormInput
					field={{
						name: 'nazevObce',
						disabled: isProcessing,
						placeholder: ts('formular.placeholders.nazev-obce'),
					}}
					label={{ children: ts('formular.pole.nazev-obce') }}
					control={{ size: 'm' }}
				/>
				<FormInput
					field={{
						name: 'nazevUlice',
						disabled: isProcessing,
						placeholder: ts('formular.placeholders.nazev-ulice'),
					}}
					label={{ children: ts('formular.pole.nazev-ulice') }}
					control={{ size: 'm' }}
				/>
				<FormInput
					field={{
						name: 'cislo',
						disabled: isProcessing,
						placeholder: ts('formular.placeholders.cislo-evidencni-popisne'),
					}}
					label={{ children: ts('formular.pole.cislo-evidencni-popisne') }}
					control={{ size: 'm' }}
				/>
				<Button
					onClick={() => {
						mainQuery.refetch();
						setControls({ displaySearchAddressLoading: true });
					}}
					disabled={disableSearchButton || isProcessing}
					loading={controls.displaySearchAddressLoading}
					nativeType={'button'}
					variant={'primary'}
					size={'m'}
					type={'outlined'}>
					{ts('akce.vyhledat')}
				</Button>
				{mainQuery.isFetched && (
					<FormSelect
						field={{
							name: 'newAddress',
							disabled: isProcessing,
							options: [
								{
									value: '',
									label: ts('formular.pole.neuvedeno'),
									isDisabled: true,
								},
								...address.map((address) => {
									return {
										value: address.kod || '',
										label: address.adresaText || '',
									};
								}),
							],
						}}
						label={{
							children: ts('formular.pole.nova-adresa'),
						}}
					/>
				)}
			</LayoutGap>
		</FormWidthElementLayout>
	);
};
