import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { useApplicantLoader, useProcessControl } from '@gov-nx/core/hooks';
import {
	getRequiredDataBoxes,
	LocalizeNameSpaceTypes,
} from '@gov-nx/core/service';
import { Nullable, is, compose } from '@gov-nx/core/types';
import { usePersonStore } from '@gov-nx/store/portal-obcana';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextControls, ServiceContextTypes } from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { t: tf } = useTranslation([LocalizeNameSpaceTypes.Form]);
	const { toastMessage } = useMessageEvents();
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const { downloadBlob } = useDownloadEvents();
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		initialLoading: true,
		downloadLoading: false,
		downloadError: null,
	});

	useApplicantLoader({
		onError: (initialError) => {
			setControls({ initialLoading: false, initialError });
		},
		onLoaded: () => {
			if (controls.initialLoading) {
				setControls({ initialLoading: false });
			}
		},
	});

	const { communication: person } = usePersonStore();
	const formDefinition = FormInstance({ code, person });

	const submitMutation = usePoMutation({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			formDefinition.formReset();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		const values = formDefinition.formMethods.getValues();
		if (!is(values.odesilatelDSId)) {
			formDefinition.formMethods.setError('odesilatelDSId', {
				type: 'custom',
				message: tf('data-box.validations.vyberte-datovou-schranku', {
					namespace: LocalizeNameSpaceTypes.Form,
				}),
			});
		} else {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(formDefinition.formMethods.getValues());
		}
	}, [formDefinition.formMethods, tf, setControls, submitMutation]);

	const handleDownload = useCallback(async () => {
		setControls({ downloadError: null, downloadLoading: true });
		return downloadBlob({
			data: prepareSubmitData(formDefinition.formMethods.getValues()),
			onSuccess: () => {
				setControls({ downloadLoading: false });
				formDefinition.formReset();
			},
			onError: (error) => {
				setControls({ downloadError: error, downloadLoading: false });
				formDefinition.formReset();
			},
		});
	}, [downloadBlob, formDefinition, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);
	const onDownload = formDefinition.formMethods.handleSubmit(handleDownload);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				controls,
				setControls,
				onDownload,
				requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const PodaniZadostiInformaceContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
