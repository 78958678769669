import {
	GovControlGroup,
	GovDropdown,
	GovIcon,
} from '@gov-design-system-ce/react';
import { useState } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

export const AttachmentsDropdown = () => {
	const { openDocumentsModal, openUploadModal } =
		useDataBoxMessageFormContext();
	const [isOpened, setIsOpened] = useState(false);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const onAttachmentsClick = () => {
		setIsOpened(false);
		openUploadModal();
	};

	const onDocumentsClick = () => {
		setIsOpened(false);
		openDocumentsModal();
	};

	const onDropdownStateChange = (event: CustomEvent) => {
		if (event.detail.open !== undefined) {
			setIsOpened(event.detail.open);
		}
	};

	return (
		<GovControlGroup className="ds-attachments-dropdown [ md:w-max ]">
			<Button
				variant={'primary'}
				size="s"
				type={'base'}
				onClick={onAttachmentsClick}
				wcagLabel={tn('nova-zprava.nahrat-prilohu-ze-zarizeni')}>
				<GovIcon
					name={'attachment'}
					type={'basic'}
				/>
				<span>{tn('nova-zprava.nahrat-prilohu-ze-zarizeni')}</span>
			</Button>
			<GovDropdown
				open={isOpened}
				onGov-change={onDropdownStateChange}
				position="right">
				<Button
					variant={'primary'}
					size="s"
					type={'base'}
					className={'!w-auto'}>
					<GovIcon
						name={'chevron-down'}
						type={'basic'}
					/>
				</Button>
				<ul slot={'list'}>
					<li>
						<Button
							variant={'secondary'}
							type={'base'}
							onClick={onAttachmentsClick}
							wcagLabel={tn('nova-zprava.nahrat-prilohu-ze-zarizeni')}
							className={'!w-full'}>
							{tn('nova-zprava.nahrat-prilohu-ze-zarizeni')}
							<GovIcon
								name={'upload'}
								type={'basic'}
							/>
						</Button>
					</li>
					<li>
						<Button
							variant={'secondary'}
							type={'base'}
							onClick={onDocumentsClick}
							wcagLabel={tn('nova-zprava.vybrat-z-mych-souboru')}
							className={'!w-full'}>
							{tn('nova-zprava.vybrat-z-mych-souboru')}
							<GovIcon
								name={'file-earmark-text'}
								type={'basic'}
							/>
						</Button>
					</li>
				</ul>
			</GovDropdown>
		</GovControlGroup>
	);
};
