import React, { useEffect } from 'react';
import { is } from '@gov-nx/core/types';
import { ApplicantDetailsFormMethods } from '@gov-nx/ui/types';
import { useApplicantSelector } from './useApplicantSelector';

export const useApplicantFormHelper = (
	formMethods: ApplicantDetailsFormMethods
) => {
	const {
		dataBoxes,
		dataBoxPFO,
		dataBoxesPO,
		dataBoxFO,
		individualPerson,
		hasDisconnectedDS,
	} = useApplicantSelector();
	const selectedApplicantType = formMethods.watch('typOsoby');

	React.useEffect(() => {
		const subscription = formMethods.watch((value, { name }) => {
			if (name === 'typOsoby') {
				if (value.typOsoby === 'FO') {
					formMethods.setValue('ico', undefined);
					formMethods.setValue('_businessAddress', undefined);
				}
				if (value.typOsoby === 'PFO') {
					formMethods.setValue('ico', dataBoxPFO?.ico);
					formMethods.setValue(
						'_businessAddress',
						dataBoxPFO?.sidloSpolecnosti?.adresaText
					);
				}
				if (value.typOsoby === 'PO') {
					formMethods.setValue('ico', dataBoxesPO[0]?.ico);
					formMethods.setValue(
						'_businessAddress',
						dataBoxesPO[0]?.sidloSpolecnosti?.adresaText
					);
				}
			}

			if (name === '_businessPersonDataBox') {
				const dataBox = dataBoxesPO.find(
					(f) => f.datovaSchrankaId === value._businessPersonDataBox
				);
				formMethods.setValue('ico', dataBox?.ico);
				formMethods.setValue(
					'_businessAddress',
					dataBox?.sidloSpolecnosti?.adresaText
				);
			}
		});
		return () => subscription.unsubscribe();
	}, [formMethods.watch, formMethods, dataBoxesPO, dataBoxPFO]);

	const personType = formMethods.getValues('typOsoby');
	useEffect(() => {
		if (!personType) {
			if (dataBoxFO && dataBoxFO.datovaSchrankaId) {
				formMethods.setValue('typOsoby', 'FO');
			} else if (dataBoxPFO && dataBoxPFO.datovaSchrankaId) {
				formMethods.setValue('typOsoby', 'PFO');
				formMethods.setValue(
					'_businessPersonDataBox',
					dataBoxPFO?.datovaSchrankaId
				);
				formMethods.setValue('ico', dataBoxPFO?.ico);
				formMethods.setValue(
					'_businessAddress',
					dataBoxPFO?.sidloSpolecnosti?.adresaText
				);
			} else if (dataBoxesPO.length > 0) {
				const withDataboxId = dataBoxesPO.find((d) => d.datovaSchrankaId);
				if (withDataboxId) {
					formMethods.setValue('typOsoby', 'PO');
					formMethods.setValue(
						'_businessPersonDataBox',
						withDataboxId.datovaSchrankaId
					);
					formMethods.setValue('ico', withDataboxId.ico);
					formMethods.setValue(
						'_businessAddress',
						withDataboxId.sidloSpolecnosti?.adresaText
					);
				}
			}
		}
	}, [personType]);

	return {
		dataBoxes,
		dataBoxesPO,
		dataBoxPFO,
		dataBoxFO,
		individualPerson,
		selectedApplicantType,
		values: formMethods.getValues(),
		hasDisconnectedDS,
		selectPOOptions: (dataBoxesPO ?? [])
			.map((person) =>
				person.datovaSchrankaId && person.nazevSpolecnosti
					? {
							value: person.datovaSchrankaId ?? '',
							label: person.nazevSpolecnosti ?? '',
					  }
					: undefined
			)
			.filter(is),
	};
};
