import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';

export const UserManualSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={8}>
			<div
				className={
					'flex flex-col gap-4 w-full p-8 border border-secondary-400 rounded bg-neutral-white [ md:flex-row ]'
				}>
				<GovSkeleton
					height={'48px'}
					width={'48px'}
					className={'py-2'}
					shape={'circle'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<div className={'w-full'}>
					<GovSkeleton
						height={'28px'}
						width={'230px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'block mb-3'}
					/>
					<GovSkeleton
						height={'21px'}
						width={'98%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'100%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'95%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'20%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					height={'32px'}
					width={'80px'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div
				className={
					'flex flex-col gap-4 w-full p-8 border border-secondary-400 rounded bg-neutral-white [ md:flex-row ]'
				}>
				<GovSkeleton
					height={'48px'}
					width={'48px'}
					className={'py-2'}
					shape={'circle'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<div className={'w-full'}>
					<GovSkeleton
						height={'28px'}
						width={'70%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'block mb-3'}
					/>
					<GovSkeleton
						height={'21px'}
						width={'100%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'90%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'98%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'21px'}
						width={'70%'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					height={'32px'}
					width={'80px'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
		</LayoutGap>
	);
};
