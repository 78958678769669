import { Nullable } from '@gov-nx/core/types';
import { DokladyType } from '@gov-nx/module/page';
import {
	convertToArray,
	createUuid4,
	isDefined,
	xValToValidDate,
} from '@gov-nx/utils/common';
import { DokladyDokladDto } from '../generated';
import {
	DriversLicenseData,
	DriversRegistryData,
	DriversRegistryDetailResponse,
	DriversRegistryDocumentsResponse,
	DriversRegistryResponse,
} from '../types/driversRegistry';

export interface DriversRegistryResponseTransformOptions {
	complete?: boolean;
	includeLicense?: boolean;
}

export const driversRegistryResponseTransform = (
	response: DriversRegistryResponse,
	options: DriversRegistryResponseTransformOptions = { complete: true }
): Nullable<DriversRegistryData> | Partial<DriversRegistryData> => {
	if (!response?.Data) return null;

	const documents = response.Data.find(
		(item): item is DriversRegistryDocumentsResponse => 'Doklady' in item
	);
	const base = response.Data.find(
		(item): item is DriversRegistryDetailResponse => 'r' in item
	)?.r?.crrOdpoved?.kontextData;
	if (!base) return null;

	const transformed: Partial<DriversRegistryData> = {};

	if (options.complete || options.includeLicense) {
		const license = {
			id: base.ridicskyPrukaz?.cisloDokladu?.xVal ?? '',
			validFrom: xValToValidDate(base.ridicskyPrukaz?.platnostOd?.xVal),
			validTo: xValToValidDate(base.ridicskyPrukaz?.platnostDo?.xVal),
			canChange: documents?.VymenaRP?.Povolena ?? false,
		};

		transformed.license = license;
		transformed.hasLicense = !!license.id;
	}

	if (options.complete) {
		const qualification = {
			id: base.profesniZpusobilost?.cisloDokladu?.xVal ?? '',
			validFrom: xValToValidDate(base.profesniZpusobilost?.platnostOd?.xVal),
			validTo: xValToValidDate(base.profesniZpusobilost?.platnostDo?.xVal),
			groups: (base.profesniZpusobilost?.proSkupiny?.xVal ?? '').split(' '),
		};

		transformed.qualification = qualification;
		transformed.hasQualification = !!qualification.id;
	}

	if (options.complete) {
		const competencies = convertToArray(
			base.ridicskeOpravneniSeznam?.ridicskeOpravneni
		).map((competency) => ({
			id: createUuid4('competency'),
			group: competency.skupina?.xVal ?? '',
			validFrom: xValToValidDate(competency.platnostOd?.xVal),
		}));

		transformed.competencies = competencies;
		transformed.hasCompetencies = competencies.length > 0;
	}

	if (options.complete) {
		const offences = convertToArray(base.prestupekSeznam?.prestupek).map(
			(offence) => ({
				id: createUuid4('offence'),
				points: Number(offence.body?.xVal),
				decisionDate: xValToValidDate(offence.datumPmRozhodnuti?.xVal),
				decisionBureau: offence.rozhodlKdo?.xVal ?? '',
				offenceDate: xValToValidDate(offence.datumSpachani?.xVal),
				offence: offence.spachanePrestupky?.xVal ?? '',
				violation: offence.porusenaUstanoveni?.xVal ?? '',
				sanctions: [offence.sankce1?.xVal, offence.sankce2?.xVal].filter(
					isDefined
				),
			})
		);

		transformed.offences = offences;
		transformed.hasOffences = offences.length > 0;
	}

	if (options.complete) {
		const prohibitions = convertToArray(base.zrmvSeznam?.zrmv).map(
			(prohibition) => ({
				id: createUuid4('prohibition'),
				validFrom: xValToValidDate(prohibition.datumOd?.xVal),
				validTo: xValToValidDate(prohibition.datumDo?.xVal),
				group: prohibition.proSkupiny?.xVal ?? '',
				length: prohibition.delka?.xVal ?? '',
			})
		);

		transformed.prohibitions = prohibitions;
		transformed.hasProhibitions = prohibitions.length > 0;
	}

	if (options.complete) {
		const revocations = convertToArray(base.blokaceRoSeznam?.blokaceRo).map(
			(revocation) => ({
				id: createUuid4('revocation'),
				validFrom: xValToValidDate(revocation.datumOd?.xVal),
				validTo: xValToValidDate(revocation.datumDo?.xVal),
				group: revocation.proSkupiny?.xVal ?? '',
				reason: revocation.duvod?.xVal ?? '',
			})
		);

		transformed.revocations = revocations;
		transformed.hasRevocations = revocations.length > 0;
	}

	if (options.complete) {
		const currentPoints = Number(base.bodoveHodnoceni?.aktualniStav?.xVal);
		const points: DriversRegistryData['points'] = {
			current: currentPoints,
			max: 12,
			lastChange: xValToValidDate(base.bodoveHodnoceni?.posledniZmena?.xVal),
			state: currentPoints < 5 ? 'good' : currentPoints < 8 ? 'warning' : 'bad',
			history: convertToArray(
				base.bodoveHodnoceni?.bodyHistorieSeznam?.zaznamBodu
			).map((record) => ({
				date: xValToValidDate(record.datum?.xVal),
				reason: record.duvod?.xVal ?? '',
				points: Number(record.pocet?.xVal),
			})),
		};

		transformed.points = points;
	}

	return transformed;
};

export const driversLicenceToDocument = (
	driversLicense: DriversLicenseData
): DokladyDokladDto => {
	return {
		druhDokladu: DokladyType.RP,
		cisloDokladu: driversLicense.id,
		platnostOd: driversLicense.validFrom?.toISOString(),
		platnostDo: driversLicense.validTo?.toISOString(),
	};
};
