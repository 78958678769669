import { useTranslation } from 'react-i18next';
import { getI18n } from './i18n';

export const useTranslationWithNamespace = () => {
	const { t } = useTranslation();
	const getLocalize = (
		namespace: string,
		key: string,
		values?: Record<string, string | number>
	): string => {
		const isLoaded = getI18n().hasLoadedNamespace(namespace);
		if (!isLoaded) return key;

		return t(key, { ns: namespace, ...values });
	};

	const getLocalizeCurried =
		(namespace: string) =>
		(key: string, values?: Record<string, string | number>): string =>
			getLocalize(namespace, key, values);

	return { getLocalize, ts: getLocalize, getLocalizeCurried, t };
};
