import * as yup from 'yup';
import { OsobyEditorDto, OsobyPodnikatelDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	LocalizeNameSpaceTypes,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { toDate, toStringDate, usePhoneShape } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	FormData,
	ProfileRosComplaintProcessControl,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	data: OsobyPodnikatelDto;
	controls: ProfileRosComplaintProcessControl;
}

export function FormInstance({
	code,
	data,
	controls,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize, getLocalizeCurried } = useTranslationWithNamespace();
	const { getPhoneShape } = usePhoneShape();
	const tsn = getLocalizeCurried(code);
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			nazevSpolecnosti: yup.string().optional(),
			adresaSidla: yup.string().optional(),
			popisReklamace: yup
				.string()
				.required(
					getLocalize(code, 'formular.validace.popisReklamace.povinny')
				),
			nazevObce: yup.string().when([], {
				is: () => controls.displaySearchAddress,
				then: yup
					.string()
					.required(getLocalize(code, 'formular.validace.povinne')),
				otherwise: yup.string().optional(),
			}),
			nazevUlice: yup.string().optional(),
			cislo: yup.string().when([], {
				is: () => controls.displaySearchAddress,
				then: yup
					.string()
					.required(getLocalize(code, 'formular.validace.povinne'))
					.min(0)
					.max(4, getLocalize(code, 'formular.validace.max-9999'))
					.matches(
						/^[0-9]+$/,
						getLocalize(code, 'formular.validace.pouze-cislice')
					),
				otherwise: yup.string().optional(),
			}),
			newPhone: getPhoneShape(),
			newEmail: yup
				.string()
				.optional()
				.email(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.email-neni-ve-spravnem-tvaru'
					)
				),
			kontaktTelefon: yup.string().when(['newPhone', 'newEmail'], {
				is: (newPhone?: string, newEmail?: string) => !newPhone && !newEmail,
				then: yup.string().required(tsn('formular.validace.povinne')),
			}),
			kontaktEmail: yup
				.string()
				.optional()
				.when('kontaktTelefon', {
					is: (kontaktTelefon?: string) => !kontaktTelefon,
					then: (schema) => schema.required(tsn('formular.validace.povinne')),
				}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			nazevSpolecnosti: data.nazevSpolecnosti,
			adresaSidla: data.sidloSpolecnosti?.adresaText,
			datumOd: data.datumVzniku ? toDate(data.datumVzniku) : undefined,
			datumDo: data.datumZaniku ? toDate(data.datumZaniku) : undefined,
			pozastaveniPreruseni: data.pozastaveniPreruseni,
			pravniForma: data.pravniForma?.nazev,
			nazevObce: undefined,
			cislo: undefined,
			nazevUlice: undefined,
			newPhone: undefined,
			newEmail: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(newAddress?: string, editors?: OsobyEditorDto[]) =>
	(data: FormData): DataRequest => {
		const seznam = [];
		if (data.nazevSpolecnosti) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'ObchodniNazev',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.nazevSpolecnosti,
			});
		}
		if (data.datumOd) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'DatumVznikuOpravneni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: toStringDate(data.datumOd),
			});
		}
		if (data.datumDo) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'DatumZanikuOpravneni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: toStringDate(data.datumDo),
			});
		}
		if (data.pravniForma) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'PravniForma',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.pravniForma,
			});
		}
		if (data.pozastaveniPreruseni !== undefined) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'PozastaveniPreruseni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.pozastaveniPreruseni,
			});
		}
		if (data.adresaSidla) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'AdresaSidla',
				zpusobNaplneni: newAddress,
			});
		}
		const kontaktValue = [
			data.newPhone,
			data.newEmail,
			data._telefon ? data._telefon : undefined,
			data._email ? data._email : undefined,
		]
			.filter((value) => !!value)
			.join(', ');

		return {
			params: {},
			body: {
				agenda: null,
				ico: data.ico,
				kontakt: kontaktValue,
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
