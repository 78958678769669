import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	MySubmissionMessage as MySubmissionMessageInterface,
	useMojePodaniDetailContextInstance,
} from '@gov-nx/module/page';
import { Button, PopConfirm } from '@gov-nx/ui/web';
import { ConnectToAnotherSubmissionModal } from './ConnectToAnotherSubmissionModal';

interface Props {
	message: MySubmissionMessageInterface;
}

export const ConnectToAnotherSubmission = ({ message }: Props) => {
	const { code, connectToAnotherSubmission, disconnectFromSubmission } =
		useMojePodaniDetailContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { form, onSelect, isDisconnected } = connectToAnotherSubmission;

	return (
		<FormProvider {...form.formMethods}>
			<div>
				{isDisconnected ? (
					<div>
						<ConnectToAnotherSubmissionModal message={message} />
						<Button
							onClick={() => onSelect(message.dataMessage)}
							variant={'primary'}
							type={'link'}
							wcagLabel={ts('akce.pripojit-k-jinemu-podani')}>
							{ts('akce.pripojit-k-jinemu-podani')}
						</Button>
					</div>
				) : (
					<PopConfirm
						onConfirm={() => disconnectFromSubmission(message.dataMessage)}
						strings={{
							title: ts('odpojit-od-podani.titulek'),
							body: ts('odpojit-od-podani.text'),
							buttons: {
								cancel: {
									label: ts('akce.odpojit-od-podani-zrusit'),
									wcagLabel: ts('akce.odpojit-od-podani-zrusit'),
								},
								confirm: {
									label: ts('akce.odpojit-od-podani-potvrdit'),
									wcagLabel: ts('akce.odpojit-od-podani-potvrdit'),
								},
							},
						}}>
						{({ open }) => {
							return (
								<Button
									onClick={open}
									variant={'primary'}
									type={'link'}
									wcagLabel={ts('akce.odpojit-od-podani')}>
									{ts('akce.odpojit-od-podani')}
								</Button>
							);
						}}
					</PopConfirm>
				)}
			</div>
		</FormProvider>
	);
};
