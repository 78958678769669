import { AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';

export const deleteCertificatesQuery = async <Params>(
	serialNumber: string,
	{ params }: { params: Params }
): Promise<AxiosResponse<void>> => {
	return await axiosInstance.delete(
		`/api/v1/fyzickeosoby/certifikaty/${serialNumber}`,
		{ params }
	);
};
