import { create } from 'zustand';
import { Country, Language } from '@gov-nx/api/common';
import { ListsState } from './store.types';

const initialState = {
	countries: [],
	languages: [],
};

export const useListsStore = create<ListsState>()((set, get) => ({
	...initialState,

	saveCountryList: (countries: Country[]) => set({ countries }),
	saveLanguageList: (languages: Language[]) => set({ languages }),

	getEuropeCountries: () => get().countries?.filter(({ eu }) => eu),
}));
