import { GovIcon, GovModal, GovPagination } from '@gov-design-system-ce/react';
import { useScroll } from '@gov-nx/web';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useUpozorneniContextInstance } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { ModalView } from './ModalView';
import { NotificationsSkeleton } from './NotificationsSkeleton';

export interface NotificationsFormProps {
	code: PageCode;
}

export const NotificationsView = ({ code }: NotificationsFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { scrollToTop } = useScroll();
	const {
		data,
		numberOfNotifications,
		goToPage,
		currentPage,
		filter,
		controls,
		link,
		reloadNotifications,
		categorizedData,
		selectedNotification,
	} = useUpozorneniContextInstance();

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (controls.initialLoading) {
		return <NotificationsSkeleton />;
	}

	if (!data || data.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'cactus', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl [ md:text-3xl ]'}>
					{ts('stav.chyba.zatim-tu-nic-nemame')}
				</p>
				<p>{ts('stav.chyba.nemate-zadne-upozorneni')}</p>
			</InfoBanner>
		);
	}

	return (
		<ContentLayout>
			<ul className={'gov-list--plain'}>
				{categorizedData.map((category) => {
					return (
						<li key={category.key}>
							<h2 className={'my-2 text-xs text-center'}>{category.label}</h2>
							<ul className={'gov-list--plain'}>
								{category.notifications.map((item) => {
									const isUnread = !item.datumACasPrecteni;
									return (
										<li
											key={item.id.toString()}
											className={
												'-mx-5 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ]'
											}>
											<Link
												to={'#'}
												onClick={() => {
													selectedNotification.select(item.id);
													isUnread && reloadNotifications();
												}}
												className={
													'anim-bg-hover relative block py-6 pr-4 rounded no-underline'
												}>
												<div className={cx('flex gap-2', !isUnread && 'pl-6')}>
													{isUnread && (
														<span
															className={
																'relative flex grow-0 shrink-0 w-4 h-4 top-1 rounded-full bg-warning-400'
															}
															title="Nepřečteno"></span>
													)}
													<h3
														className={cx(
															'pr-6 text-m text-primary-600 [ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]',
															isUnread && 'font-bold'
														)}>
														{item.nazev}
													</h3>
												</div>
												<time
													className={cx(
														'relative top-auto right-auto inline-block mt-2 pl-6 text-secondary-700 [ md:absolute md:top-6 md:right-12 md:mt-0 ]',
														isUnread && 'font-bold'
													)}>
													{item.datumACasVytvoreni
														? toDateReadable(item.datumACasVytvoreni)
														: undefined}
												</time>
												<span className={'w-3'}>
													<GovIcon
														name="chevron-right"
														className={
															'absolute top-6 right-4 w-3 h-6 text-primary-600'
														}></GovIcon>
												</span>
											</Link>
										</li>
									);
								})}
							</ul>
						</li>
					);
				})}
			</ul>
			<GovModal
				open={!!selectedNotification.id}
				label={selectedNotification.modalLabel}
				onGov-close={selectedNotification.unselect}>
				<div>
					{selectedNotification.id ? (
						<ModalView
							selectedNotification={selectedNotification}
							code={code}
							children={
								link?.url && link?.urlText ? (
									<Button
										enforceLink={true}
										target={'_blank'}
										type="solid"
										variant="primary"
										href={link.url}>
										{link.urlText}
										<GovIcon name="box-arrow-up-right" />
									</Button>
								) : null
							}
						/>
					) : null}
				</div>
			</GovModal>
			{numberOfNotifications > filter.pocet ? (
				<footer className={'mt-6'}>
					<GovPagination
						onGov-page={(event) => {
							const page = event.detail.pagination.currentPage;
							scrollToTop();
							goToPage(page);
						}}
						current={currentPage}
						page-size={filter.pocet}
						total={numberOfNotifications}></GovPagination>
				</footer>
			) : null}
		</ContentLayout>
	);
};
