import {
	DokumentyDokumentSeznamDto,
	KosPolozkaKosSeznamDto,
} from '@gov-nx/api/portal-obcana';
import { getEntries, is } from '@gov-nx/core/types';
import { MyFilesContextType } from '../context.types';

interface MyFilesDownloadHookProps {
	formMethods: MyFilesContextType['formDefinition']['formMethods'];
	isSelectedRecycleBin: boolean;
	documents?: DokumentyDokumentSeznamDto;
	documentsBinned?: KosPolozkaKosSeznamDto;
}

const first = <A, B>(array: [A, B]): A => array[0];
const last = <A, B>(array: [A, B]): B => array[1];

const set = <T extends { [key: string]: boolean }>(
	id: number | undefined,
	value: boolean,
	obj: T
): T => {
	if (!id) return obj;
	return { ...obj, [id]: value };
};

export const useMyFilesSelection = ({
	documents,
	isSelectedRecycleBin,
	documentsBinned,
	formMethods,
}: MyFilesDownloadHookProps) => {
	const setValue = (isChecked: boolean): { [key: string]: boolean } => {
		if (isSelectedRecycleBin) {
			return (documentsBinned?.seznam ?? [])?.reduce(
				(all, document) => set(document.id, isChecked, all),
				{} as { [key: string]: boolean }
			);
		}
		return (documents?.seznam ?? []).reduce(
			(all, document) => set(document.dokumentId, isChecked, all),
			{} as { [key: string]: boolean }
		);
	};
	const selectedIds = getEntries(formMethods.watch('selection'))
		.filter(last)
		.map(first)
		.map(Number);

	const isItemWithoutFileInSelectedIds = (): boolean => {
		const hasMatchingUndefinedSize = selectedIds.find((id) => {
			const matchingObject = documents?.seznam?.find(
				(obj) => obj.dokumentId === id
			);

			return !!(matchingObject && !is(matchingObject.velikost));
		});
		return !!hasMatchingUndefinedSize;
	};

	const isItemWithNoFileInSelection = isItemWithoutFileInSelectedIds();

	return {
		selectedIds,
		isItemWithNoFileInSelection,
		resetSelectedIds: () => formMethods.setValue(`selection`, setValue(false)),
		onSelectAll: () => formMethods.setValue('selection', setValue(true)),
	};
};
