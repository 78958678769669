import { GovFormSearch, GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxSearchFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, FormInput } from '@gov-nx/ui/web';

export const DataBoxSearch = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { form, onSubmit } = useDataBoxSearchFormContext();

	return (
		<form
			onSubmit={form.handleSubmit(onSubmit)}
			className={'w-full'}>
			<div className={'relative'}>
				<label
					id="vyhledat-datove-zpravy"
					className={'hidden'}>
					{tn('vyhledavani.wcag.label')}
				</label>
				<GovFormSearch>
					<FormInput
						field={{
							name: 'query',
							placeholder: tn('vyhledavani.placeholder.co-hledate'),
							wcagLabelledBy: 'vyhledat-datove-zpravy',
							size: 's',
						}}
						label={{}}
						slot="input"
					/>
					<Button
						slot="button"
						variant="primary"
						type="solid"
						nativeType="submit"
						wcagLabel={tn('vyhledavani.hledat')}
						size="s">
						<GovIcon
							slot="left-icon"
							name="search"
						/>
					</Button>
				</GovFormSearch>
			</div>
		</form>
	);
};
