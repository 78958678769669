import { useEffect } from 'react';
import { OsobyAdresaDto } from '@gov-nx/api/portal-obcana';
import { usePersonStore } from '@gov-nx/store/portal-obcana';
import { IUseBoolean, useBoolean } from '@gov-nx/utils/common';

export interface PersonCommunicationData {
	emailVerified?: string;
	unverifiedEmail?: string;

	phoneVerified?: string;
	unverifiedPhone?: string;

	address?: OsobyAdresaDto;

	control: {
		isEditingEmail: IUseBoolean;
		isEditingPhone: IUseBoolean;
		isEditingAddress: IUseBoolean;
	};
}

export const usePersonCommunicationData = (): PersonCommunicationData => {
	const {
		poCommunicationEmail,
		poCommunicationVerifyEmail,
		poCommunicationPhone,
		poCommunicationVerifyPhone,
		poCommunicationAddress,
	} = usePersonStore();
	const emailVerified = poCommunicationEmail();
	const unverifiedEmail = poCommunicationVerifyEmail();

	const phoneVerified = poCommunicationPhone();
	const unverifiedPhone = poCommunicationVerifyPhone();

	const address = poCommunicationAddress();

	const isEditingEmail = useBoolean();
	const isEditingPhone = useBoolean();
	const isEditingAddress = useBoolean();

	useEffect(() => {
		if (unverifiedPhone) {
			isEditingPhone.setTrue();
		}
		if (unverifiedEmail) {
			isEditingEmail.setTrue();
		}
		if (!address) {
			isEditingAddress.setTrue();
		}
	}, [unverifiedPhone, unverifiedEmail, address]);

	return {
		emailVerified,
		phoneVerified,
		unverifiedEmail,
		unverifiedPhone,
		address,
		control: {
			isEditingEmail,
			isEditingPhone,
			isEditingAddress,
		},
	};
};
