import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { ConnectToAnotherSubmissionForm } from '@gov-nx/module/page';

export function FormInstance(): FormDefinition<ConnectToAnotherSubmissionForm> {
	const formSchema = yup
		.object({
			submission: yup.string(),
		})
		.required();

	const formMethods = usePoForm<ConnectToAnotherSubmissionForm>({
		formSchema,
		defaultValues: {
			submission: undefined,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}
