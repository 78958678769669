import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadRequestQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import {
	DownloadBlobSubscription,
	useDownloadEvents,
	useMessageEvents,
} from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import {
	createUuid4,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export const DownloadBlobListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const { toastMessage } = useMessageEvents();
	const [requestQueue, updateRequestQueue] = useState<
		DownloadBlobSubscription['payload'][]
	>([]);
	const [processingRequest, setProcessingRequest] =
		useState<Optional<DownloadBlobSubscription['payload']>>(undefined);

	const { download } = useDownloadEvents({
		onBlobDownload: (_, props) => {
			updateRequestQueue((queue) => [...queue, props]);
		},
	});

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-uspech', {
				namespace: LocalizeNameSpaceTypes.Service,
				fileName,
			}),
			options: {
				variant: 'success',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-chyba', {
				namespace: LocalizeNameSpaceTypes.Service,
			}),
			options: {
				variant: 'error',
				icon: {
					name: 'exclamation-triangle-fill',
					type: 'basic',
				},
			},
		});
	};

	const mutation = usePoMutation<
		AxiosResponse<Blob>,
		DownloadBlobSubscription['payload']['data']
	>({
		mutationFn: downloadRequestQuery,
		onError: (error) => {
			errorMessage();
			processingRequest?.onError(error);
			setProcessingRequest(undefined);
		},
		onSuccess: async (response) => {
			try {
				const disposition = getDispositionResponseHeader(response);
				const antivirus = getAntiVirusWarningHeader(response);
				const fileName = getFileNameFromDispositionHeader(disposition);

				return download({
					blob: response.data,
					id: createUuid4('blob'),
					antivirus,
					fileName,
					callback: (id, success, error) => {
						if (success) {
							successMessage(fileName);
							processingRequest?.onSuccess();
						}
						if (error) {
							errorMessage();
							processingRequest?.onError(error as GovError);
						}
					},
				});
			} catch (error) {
				errorMessage();
				processingRequest?.onError(error as GovError);
			} finally {
				setProcessingRequest(undefined);
			}
		},
	});

	useEffect(() => {
		if (requestQueue.length > 0) {
			const [request, ...rest] = requestQueue;
			updateRequestQueue(rest);
			setProcessingRequest(request);
		}
	}, [requestQueue]);

	useEffect(() => {
		if (processingRequest) {
			mutation.mutate(processingRequest.data);
		}
	}, [processingRequest]);

	return null;
};
