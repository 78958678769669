import { createContext, useContext, useState } from 'react';

interface UiBlockingGroupContextProviderProps {
	children: React.ReactNode;
}

interface UiBlockingGroupContextValue {
	isBlockedEditation: boolean;
	blockEditation: () => void;
	unblockEditation: () => void;
}

export const UiBlockingGroupContext =
	createContext<UiBlockingGroupContextValue | null>(null);

export const UiBlockingGroupContextProvider = ({
	children,
}: UiBlockingGroupContextProviderProps) => {
	const [isBlockedEditation, setIsBlockedEditation] = useState(false);
	const parentBlocking = useContext(UiBlockingGroupContext);

	const blockEditation = () => {
		setIsBlockedEditation(true);
		parentBlocking?.blockEditation();
	};

	const unblockEditation = () => {
		setIsBlockedEditation(false);
		parentBlocking?.unblockEditation();
	};

	return (
		<UiBlockingGroupContext.Provider
			value={{ isBlockedEditation, blockEditation, unblockEditation }}>
			{children}
		</UiBlockingGroupContext.Provider>
	);
};
