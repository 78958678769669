import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';

export const DataBoxMessageListMainSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<div className="flex flex-col">
			<div className={'py-5 px-4 border-b border-secondary-300'}>
				<div className={'flex gap-4'}>
					<div className="w-full">
						<GovSkeleton
							width={'100%'}
							height={'24px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<GovSkeleton
						width={'40px'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'100%'}
					height={'21px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'py-5 px-4 border-b border-secondary-300'}>
				<div className={'flex gap-4'}>
					<div className="w-full">
						<GovSkeleton
							width={'100%'}
							height={'24px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<GovSkeleton
						width={'40px'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'100%'}
					height={'21px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'py-5 px-4 border-b border-secondary-300'}>
				<div className={'flex gap-4'}>
					<div className="w-full">
						<GovSkeleton
							width={'100%'}
							height={'24px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<GovSkeleton
						width={'40px'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'100%'}
					height={'21px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'py-5 px-4 border-b border-secondary-300'}>
				<div className={'flex gap-4'}>
					<div className="w-full">
						<GovSkeleton
							width={'100%'}
							height={'24px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<GovSkeleton
						width={'40px'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'100%'}
					height={'21px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'py-5 px-4'}>
				<div className={'flex gap-4'}>
					<div className="w-full">
						<GovSkeleton
							width={'100%'}
							height={'24px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<GovSkeleton
						width={'40px'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'100%'}
					height={'21px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
		</div>
	);
};
