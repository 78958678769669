import { useState } from 'react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';

export enum DataBoxConnectDialogVariant {
	Closed = 'closed',
	Connect = 'connect',
	Create = 'create',
}

export const useDataBoxConnectDialog = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Service);
	const [requiredTypes, setRequiredTypes] = useState<
		DatovkaApiType['typSchranky'][]
	>([]);
	const [modalVariant, setModalVariant] = useState<DataBoxConnectDialogVariant>(
		DataBoxConnectDialogVariant.Closed
	);
	const [mainKey, setMainKey] = useState<'pripojeni' | 'zalozeni'>('pripojeni');

	useDataBoxEvents({
		onConnect: (_, { requiredTypes }) => {
			setMainKey('pripojeni');
			setRequiredTypes([...requiredTypes]);
			setModalVariant(DataBoxConnectDialogVariant.Connect);
		},
		onCreate: () => {
			setMainKey('zalozeni');
			setModalVariant(DataBoxConnectDialogVariant.Create);
		},
	});

	const closeModal = () => {
		// reset to default state
		setMainKey('pripojeni');
		setRequiredTypes([]);
		// ...and close modal
		setModalVariant(DataBoxConnectDialogVariant.Closed);
	};

	return {
		requiredTypes,
		mainKey,
		ts,
		setRequiredTypes,
		modalVariant,
		closeModal,
	};
};
