import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useState } from 'react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxSupportModal } from './DataBoxSupportModal';

export const DataBoxesNavigation = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

	const { connect } = useDataBoxEvents();
	const { listToConnectTypes } = useDataBoxAccess({ requiredTypes: [] });

	return (
		<div
			className={
				'flex flex-col gap-2 justify-center items-center mt-auto [ xl:grow-0 xl:items-start ]'
			}>
			<DataBoxSupportModal
				isOpen={isSupportModalOpen}
				onClose={() => setIsSupportModalOpen(false)}
			/>
			<nav
				aria-label={tn('wcag.spodni-navigace')}
				className={'w-full'}>
				<ul className={'gov-list--plain flex flex-col gap-2 w-full'}>
					{listToConnectTypes.length ? (
						<li>
							<Button
								onClick={() => connect({ requiredTypes: listToConnectTypes })}
								variant="primary"
								type="base"
								className={'hidden ds-bottom-nav-item [ xl:block ]'}
								wcagLabel={tn('schranky.pripojit-dalsi-datovku')}>
								<GovIcon
									name="plus-circle"
									slot="left-icon"
								/>
								{tn('schranky.pripojit-dalsi-datovku')}
							</Button>
						</li>
					) : null}
					<li>
						<Button
							href={routeResolver(PageCode['nastaveni'])}
							variant="primary"
							type="base"
							className={'hidden ds-bottom-nav-item [ xl:block ]'}
							wcagLabel={tn('schranky.nastaveni-datovky')}>
							<GovIcon
								name="gear"
								slot="left-icon"
							/>
							{tn('schranky.nastaveni-datovky')}
						</Button>
						<Button
							href={routeResolver(PageCode['nastaveni'])}
							variant="primary"
							type="base"
							className={'ds-bottom-nav-item [ xl:hidden ]'}
							wcagLabel={tn('schranky.nastaveni-datovky')}>
							<GovIcon
								name="gear-fill"
								slot="left-icon"
							/>
						</Button>
					</li>
					<li>
						<Button
							onClick={() => setIsSupportModalOpen(true)}
							variant="primary"
							type="base"
							className={'hidden ds-bottom-nav-item [ xl:block ]'}
							wcagLabel={tn('schranky.napoveda')}>
							<GovIcon
								name="question-circle"
								slot="left-icon"
							/>
							{tn('schranky.napoveda')}
						</Button>
						<Button
							onClick={() => setIsSupportModalOpen(true)}
							variant="primary"
							type="base"
							className={'ds-bottom-nav-item [ xl:hidden ]'}
							wcagLabel={tn('schranky.napoveda')}>
							<GovIcon
								name="question-circle-fill"
								slot="left-icon"
							/>
						</Button>
					</li>
				</ul>
			</nav>
		</div>
	);
};
