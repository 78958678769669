import { GovError } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { axiosInstance } from '../axios';
import { AgendyAisAisDto } from '../generated';

export const aisQuery = async (
	code: Nullable<string>
): Promise<AgendyAisAisDto> => {
	if (!code) throw new GovError('Code is required parameter');

	const { data } = await axiosInstance.get(`/api/v1/ais/${code}`);
	return data;
};
