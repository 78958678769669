import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useState,
} from 'react';
import { cmsServiceQuery } from '@gov-nx/api/common';
import { usePoQuery } from '@gov-nx/api/portal-obcana';
import { govLog, useAppContext } from '@gov-nx/core/app';
import { useAppLanguage } from '@gov-nx/core/hooks';
import {
	getI18n,
	i18nLoadNamespaces,
	LocalizeNameSpaceTypes,
	useCmsService,
	useConfiguration,
	useCountryLoader,
	useLanguageLoader,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { useCmsStore } from '@gov-nx/store/portal-obcana';

export interface CmsServiceProviderCoreProps {
	children: React.ReactNode;
	code: ServiceCode;
	onError?: () => void;
	onNotFound?: () => void;
	skeleton: React.ReactNode;
}

export const CmsServiceProviderCore = forwardRef(
	({ children, code, skeleton = null }: CmsServiceProviderCoreProps, ref) => {
		const { saveCmsService } = useCmsStore();
		const [translationLoaded, setTranslationLoaded] = useState(
			getI18n().hasLoadedNamespace(code)
		);
		const { cmsKeyCache } = useConfiguration();
		const { language } = useAppLanguage();
		const { setCurrentCode } = useAppContext();
		const service = useCmsService({ code });
		const { loadCountries } = useCountryLoader();
		const { loadLanguages } = useLanguageLoader();

		const serviceQuery = usePoQuery({
			queryKey: ['cms-service', code, cmsKeyCache, language],
			queryFn: () => cmsServiceQuery({ cmsKeyCache, code, locale: language }),
			onSuccess: (data) => saveCmsService(code, data),
			retry: 0,
			cacheTime: 0,
			refetchOnWindowFocus: false,
		});

		const init = useCallback(async () => {
			await Promise.all([
				i18nLoadNamespaces([
					code,
					LocalizeNameSpaceTypes.Form,
					LocalizeNameSpaceTypes.Service,
				]),
				serviceQuery.refetch(),
				loadCountries(),
				loadLanguages(),
			]);
			setTranslationLoaded(getI18n().hasLoadedNamespace(code));
		}, [code, serviceQuery, loadCountries, loadLanguages]);

		useImperativeHandle(ref, () => ({
			init() {
				setCurrentCode(null, code);
				init().finally(() => {
					govLog('Page loaded [' + code + ']');
				});
			},
		}));

		if (service && translationLoaded) {
			return <>{children}</>;
		}

		return <>{skeleton}</>;
	}
);
