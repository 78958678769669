import { School } from '@gov-nx/api/portal-obcana';
import { useSchoolSearchAutocomplete } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { TypeOfSchool } from '@gov-nx/core/types';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

export interface SchoolSearchAutocompleteProps
	extends WebFormAutocompleteProps {
	type: TypeOfSchool;
}

export const SchoolSearchAutocomplete = (
	props: SchoolSearchAutocompleteProps
) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { isFetching, schools, setText } = useSchoolSearchAutocomplete({
		type: props.type,
	});

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				processing: isFetching,
				disabled: props.field.disabled,
				messageEmpty:
					props.field.messageEmpty ?? tn('naseptavac.pole.zadny-vysledek'),
				messageLoading:
					props.field.messageLoading ?? tn('naseptavac.pole.nacitam'),
				options: schools,
				templateResolver: (item: School) => {
					return `${item.nazev} (${item.datovaSchrankaId})`;
				},
				nameKey: 'datovaSchrankaId',
			}}
		/>
	);
};
