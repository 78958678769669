import { GovDropdown, GovIcon } from '@gov-design-system-ce/react';
import { useBoolean } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';
import { DocumentDownloadItem } from '../DocumentDownload/DocumentDownloadItem';

interface AttachmentProps {
	name?: string;
	size?: number;
	onDownload: (onSuccess: () => void) => void;
	downloadLabel: string;
}

export const Attachment = ({
	size,
	name,
	downloadLabel,
	onDownload,
}: AttachmentProps) => {
	const isLoading = useBoolean();
	const isDropdownOpen = useBoolean();

	return (
		<GovDropdown
			open={isDropdownOpen.value}
			onGov-change={(event) => {
				if (event.detail.open) {
					isDropdownOpen.setTrue();
				} else {
					isDropdownOpen.setFalse();
				}
			}}
			position="right">
			<DocumentDownloadItem
				name={name}
				size={size}
			/>

			<ul
				slot="list"
				className={'!p-0'}>
				<li>
					<Button
						onClick={() => {
							isLoading.setTrue();
							onDownload(() => {
								isLoading.setFalse();
								isDropdownOpen.setFalse();
							});
						}}
						variant="secondary"
						type="base"
						loading={isLoading.value}
						disabled={isLoading.value}
						wcagLabel={downloadLabel}>
						{downloadLabel}
						<GovIcon
							name="download"
							type="basic"
						/>
					</Button>
				</li>
			</ul>
		</GovDropdown>
	);
};
