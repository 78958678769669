import React from 'react';
import { useLocale } from '@gov-nx/core/service';
import { useZdravDokumentaceContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
	LayoutGap,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { HealthDocumentationSkeleton } from './HealthDocumentationSkeleton';

export const HealthDocumentationView = () => {
	const { code, controls, documents, handleDownload, handleSaveToFiles } =
		useZdravDokumentaceContext();
	const { t } = useLocale(code);
	const isProcessing = controls.processLoading || controls.downloadLoading;

	if (controls.initialLoading) {
		return <HealthDocumentationSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (Array.isArray(documents) && documents.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl [ md:text-3xl ]'}>
					{t('stav.chyba.zadna-dokumentace')}
				</p>
				<p>{t('stav.chyba.zadne-zaznamy')}</p>
			</InfoBanner>
		);
	}

	return (
		<ContentLayout>
			<LayoutGap gap={4}>
				<Tiles>
					{documents?.map((document) => {
						return (
							<Tile
								key={document.ehealthDocumentId}
								name={document.sourceName}>
								<p className={'mb-4'}>
									{t('pole.udaje.identifikacni-cislo')}{' '}
									<strong>{document.sourceIco}</strong>,{' '}
									{t('pole.udaje.platnost-od')}{' '}
									<strong>{document.effectiveTime}</strong>
								</p>
								<ButtonGroupLayout>
									<Button
										onClick={() => handleDownload(document)}
										disabled={isProcessing}
										loading={
											document.sourceIdentifier === controls.downloadDocumentId
										}
										nativeType="button"
										variant="primary"
										size="m"
										type="solid"
										wcagLabel={t('akce.wcag.stahnout-dokumentaci')}>
										{t('akce.stahnout-dokumentaci')}
									</Button>
									<Button
										onClick={() => handleSaveToFiles(document)}
										disabled={isProcessing}
										loading={
											document.sourceIdentifier === controls.submitDocumentId
										}
										nativeType="button"
										variant="primary"
										size="m"
										type="outlined"
										wcagLabel={t('akce.wcag.ulozit-do-souboru')}>
										{t('akce.ulozit-do-souboru')}
									</Button>
								</ButtonGroupLayout>
							</Tile>
						);
					})}
				</Tiles>
			</LayoutGap>
		</ContentLayout>
	);
};
