import { GovFormFileItem } from '@gov-design-system-ce/components/dist/types/components/gov-form/file/gov-form-file.types';
import { hasOwnProperty } from '@gov-nx/core/types';

export enum FileState {
	error = 'error',
	uploading = 'uploading',
	validating = 'validating',
	success = 'success',
}

export interface FileUploadNative {
	uploadType: 'native';
	id: string;
	uri: string;
	name: string;
	type: string;
	size: number;
}

export type FileUploadWeb = GovFormFileItem;

export const isFileUploadItemNative = (
	file: FileUploadItem
): file is FileUploadItemNative => {
	return hasOwnProperty(file, 'uploadType') && file.uploadType === 'native';
};

export const isFileUploadItemWeb = (
	file: FileUploadItem
): file is FileUploadItemWeb => !isFileUploadItemNative(file);

export const isFileUploadNative = (
	file: FileUpload
): file is FileUploadNative => {
	return hasOwnProperty(file, 'uploadType') && file.uploadType === 'native';
};

export type FileUpload = FileUploadNative | FileUploadWeb;

type FileUploadItemCommon = {
	name: string;
	size: number;
	type: string;
	state: FileState;
	message?: string;
	fileId?: string;
	index: number;
	showLargeFileLoader?: boolean;
};

export type FileUploadItemWeb = FileUploadItemCommon & FileUploadWeb;
export type FileUploadItemNative = FileUploadItemCommon & FileUploadNative;

export type FileUploadItem = FileUploadItemWeb | FileUploadItemNative;

export type InitialUploadItemState = Pick<
	FileUploadItem,
	'state' | 'message' | 'showLargeFileLoader'
>;

export type FileUploadProps = {
	initialFiles?: FileUploadItem[];
	extensions: string[];
	maxFileSize: number;
	maxSumFileSize: number;
	maxAttachments: number;
	multiple: boolean;
	onFilesChanged?: (
		files: FileUploadItem[],
		isAllFilesUploaded: boolean
	) => void;
	onFileRemoved?: (fileId: FileUploadItem) => void;
	onAllFilesUploaded?: (files: FileUploadItem[]) => Promise<FileUploadItem[]>;
};

export interface NativeUploadFileOptions extends FileUploadProps {
	type?: string | string[];
}

export const DefaultUploadParameters: FileUploadProps = {
	extensions: [
		'.pdf',
		'.xml',
		'.fo',
		'.zfo',
		'.htm',
		'.html',
		'.odp',
		'.odt',
		'.ods',
		'.txt',
		'.rtf',
		'.doc',
		'.docx',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
		'.jpeg',
		'.jpg',
		'.jp2',
		'.jfif',
		'.png',
		'.tif',
		'.tiff',
		'.gif',
		'.mpg',
		'.mpeg',
		'.mpeg1',
		'.mpeg2',
		'.mp1',
		'.mp2',
		'.mp3',
		'.wav',
		'.isdoc',
		'.isdocx',
		'.edi',
		'.dwg',
		'.shp',
		'.dbf',
		'.shx',
		'.prj',
		'.qix',
		'.sbn',
		'.sbx',
		'.dgn',
		'.gml',
		'.gfs',
		'.xsd',
		'.cer',
		'.crt',
		'.csv',
		'.der',
		'.p7b',
		'.p7c',
		'.p7f',
		'.p7m',
		'.p7s',
		'.tsr',
		'.tst',
	],
	maxFileSize: 20 * 1024 * 1024,
	maxSumFileSize: 20 * 1024 * 1024,
	maxAttachments: 1,
	multiple: false,
};
