import { pipe } from '@gov-nx/core/types';
import { PhoneWithCountryCodeShape } from '@gov-nx/ui/types';

const isNumeric = (input: string) => /^\d+$/.test(input);
export const digitsOnly = (input: string) => input.replace(/\D/g, '');
export const removeWhiteSpaces = (input: string) => input.replace(/\s/g, '');
export const removeFirstChar = (char: string) => (input: string) =>
	input.indexOf(char) === 0 ? input.substring(1) : input;

const isCzechPrefix = (input: string): boolean =>
	/^(\+420|00420)[\d\s]+$/.test(input);

export const withoutPrefix = (input: string) =>
	input.replace(/^(\+|00)420/, '');

export const isCzechFormat = (input: string): boolean =>
	isCzechPrefix(input) || /^(6|7)[\d\s]+$/.test(input);

export const isWrongLength = (input: string): boolean => {
	const numbersOnly = pipe(input, withoutPrefix, digitsOnly);
	if (numbersOnly.length !== 9) {
		return true;
	}
	return false;
};

export const cleanPhoneNumber = (input: string): string => {
	if (isCzechFormat(input)) {
		const numbersOnly = pipe(input, withoutPrefix, digitsOnly);
		if (numbersOnly.length === 9) {
			return numbersOnly;
		}
	}
	return pipe(input, removeWhiteSpaces, removeFirstChar('+'));
};

export function phoneValidator(input?: unknown): boolean {
	if (typeof input !== 'string' || input.trim() === '') {
		return true;
	}
	if (isCzechFormat(input) && isWrongLength(input)) {
		return false;
	}

	return pipe(input, cleanPhoneNumber, isNumeric);
}

export function phoneStartsWithPlus(input?: unknown): boolean {
	if (typeof input === 'string') {
		return input?.trim().startsWith('+');
	}

	return false;
}

export function isValidCzechMobileNumber(input?: unknown): boolean {
	if (typeof input === 'string' && isCzechPrefix(input)) {
		const numbersOnly = pipe(input, withoutPrefix, digitsOnly);
		const firstNumber = numbersOnly[0];
		return firstNumber === '6' || firstNumber === '7';
	}

	return true;
}

/**
 * Returns true when phone number has valid shape
 *
 * @param {string | undefined} countryCode
 * @param {string | undefined} number
 * @return {boolean}
 */
export const phoneShapeValidator = ({
	countryCode,
	number,
}: Partial<PhoneWithCountryCodeShape>): boolean => {
	if (countryCode === '420' && number) {
		return digitsOnly(number).length === 9;
	}

	return true;
};
