import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useLocale } from '@gov-nx/core/service';
import {
	PageCode,
	useNastaveniKontaktnichUdajuContextInstance,
} from '@gov-nx/module/page';
import { Button, ErrorStatusBanner } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../../Markdown/PoMarkdownRenderWeb';
import { ContactEmailView } from '../ContactEmailView';
import { ContactPhoneView } from '../ContactPhoneView';
import { ContactDetailSkeleton } from '../Skeletons';

interface UseContactProps {
	message: string;
	isLoading: boolean;
	onSubmit: () => void;
}

const UseContact = ({ onSubmit, message, isLoading }: UseContactProps) => {
	const { t } = useLocale(PageCode['nastaveni']);

	return (
		<GovMessage variant={'primary'}>
			<GovIcon
				slot={'icon'}
				type={'basic'}
				name="lightbulb-fill"
			/>
			<PoMarkdownRenderWeb>{message}</PoMarkdownRenderWeb>
			<Button
				variant={'primary'}
				type={'link'}
				size={'l'}
				disabled={isLoading}
				loading={isLoading}
				onClick={onSubmit}>
				{t('kontakt.info.registr-obyvatel-tlacitko')}
			</Button>
		</GovMessage>
	);
};

interface ROBContactViewProps {
	code: PageCode;
}

export const ROBContactView = ({ code }: ROBContactViewProps) => {
	const {
		initialState,
		phone,
		email,
		nuEmail,
		nuPhone,
		historicalEmail,
		historicalPhone,
	} = useNastaveniKontaktnichUdajuContextInstance();
	const { t } = useLocale(PageCode['nastaveni']);

	if (initialState.isLoading) {
		return (
			<>
				<ContactDetailSkeleton />
				<ContactDetailSkeleton />
			</>
		);
	}

	if (initialState.error) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<>
			<ContactEmailView
				code={code}
				{...email}
			/>

			{nuEmail ? (
				<UseContact
					message={t('kontakt.info.pouzit-po-email', {
						email: nuEmail.email,
					})}
					isLoading={nuEmail.isLoading}
					onSubmit={nuEmail.useIt}
				/>
			) : null}

			{!email.valueVerified &&
			!email.isNeedForVerification &&
			!nuEmail &&
			historicalEmail ? (
				<UseContact
					message={t('kontakt.info.pouzit-po-email', {
						email: historicalEmail.email,
					})}
					isLoading={historicalEmail.isLoading}
					onSubmit={historicalEmail.useIt}
				/>
			) : null}

			<br />
			<hr />
			<ContactPhoneView
				code={code}
				{...phone}
			/>

			{nuPhone ? (
				<UseContact
					message={t('kontakt.info.pouzit-po-telefon', {
						telefon: nuPhone.phone,
					})}
					isLoading={nuPhone.isLoading}
					onSubmit={nuPhone.useIt}
				/>
			) : null}

			{!phone.valueVerified &&
			!phone.isNeedForVerification &&
			!nuPhone &&
			historicalPhone ? (
				<UseContact
					message={t('kontakt.info.pouzit-po-telefon', {
						telefon: historicalPhone.phone,
					})}
					isLoading={historicalPhone.isLoading}
					onSubmit={historicalPhone.useIt}
				/>
			) : null}
		</>
	);
};
