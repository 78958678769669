import { GovIcon } from '@gov-design-system-ce/react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

interface DataboxManagementButtonsProps {
	connectedDataboxes: string[];
	handleShowMoreLess: () => void;
}

export const DataboxManagementButtons = ({
	connectedDataboxes,
	handleShowMoreLess,
}: DataboxManagementButtonsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		possibleToConnect,
		possibleToCreate,
		listToConnectTypes,
		connectedIds,
	} = useDataBoxAccess();
	const { zalozeniDatoveSchrankyUrl } = useConfiguration();
	const { connect } = useDataBoxEvents();

	const showCreateButton = possibleToCreate && zalozeniDatoveSchrankyUrl;
	const showDisplayMoreButton = connectedIds.length > 2;

	if (!showDisplayMoreButton && !possibleToConnect && !showCreateButton) {
		return null;
	}

	return (
		<div className="flex gap-4 flex-col pt-10 border-t border-secondary-300 [ md:flex-row ]">
			{showDisplayMoreButton && (
				<Button
					onClick={() => handleShowMoreLess()}
					variant="primary"
					type="solid"
					wcagLabel={tds('schranky.ukazat-vice-datovek')}>
					<GovIcon
						name={
							connectedDataboxes.length === 2
								? 'caret-down-fill'
								: 'caret-up-fill'
						}
						slot="left-icon"
					/>
					{connectedDataboxes.length === 2
						? tds('schranky.ukazat-vice-datovek')
						: tds('schranky.ukazat-mene-datovek')}
				</Button>
			)}
			{possibleToConnect && (
				<Button
					onClick={() => connect({ requiredTypes: listToConnectTypes })}
					variant="primary"
					type="outlined"
					wcagLabel={tds('schranky.pripojit-dalsi-datovku')}>
					<GovIcon
						name="plus-circle"
						slot="left-icon"
					/>
					{tds('schranky.pripojit-dalsi-datovku')}
				</Button>
			)}
			{showCreateButton && (
				<Button
					href={zalozeniDatoveSchrankyUrl}
					target="_blank"
					enforceLink={true}
					variant="primary"
					type="outlined"
					wcagLabel={tds('nastaveni.zalozit-datovou-schranku-fo')}>
					<GovIcon
						name="plus-circle"
						slot="left-icon"
					/>
					{tds('nastaveni.zalozit-datovou-schranku-fo')}
				</Button>
			)}
		</div>
	);
};
