import { useQueryClient } from '@tanstack/react-query';
import {
	OsobyKomunikacniUdajeDto,
	rosCommunicationQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { is, Optional, propEq } from '@gov-nx/core/types';

export const useROSCommunicationQuery = () => {
	const queryClient = useQueryClient();
	const queryKey = ['credits-ros'];

	const query = usePoQuery({
		queryKey,
		queryFn: rosCommunicationQuery,
		retry: 1,
		refetchOnWindowFocus: false,
	});

	const getRosContact = (ico: number): Optional<OsobyKomunikacniUdajeDto> =>
		query.data?.seznam?.filter(is).find(propEq('ico', ico))?.komunikacniUdaje ??
		undefined;

	return {
		...query,
		getRosContact,
		invalidate: async () => queryClient.invalidateQueries({ queryKey }),
	};
};
