import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useRegistrRidicuContext } from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner, InfoBanner } from '@gov-nx/ui/web';
import { DriversRegistryCompetencyView } from './DriversRegistryCompetencyView';
import { DriversRegistryNavigation } from './DriversRegistryNavigation';
import { DriversRegistryOffencesView } from './DriversRegistryOffencesView';
import { DriversRegistryProhibitionsView } from './DriversRegistryProhibitionsView';
import { DriversRegistrySkeleton } from './DriversRegistrySkeleton';

type DriversRegistryViewProps = {
	code: PageCode;
};

export const DriversRegistryView = ({ code }: DriversRegistryViewProps) => {
	const { data, selectedPage, controls } = useRegistrRidicuContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <DriversRegistrySkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (!data?.hasLicense) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl [ md:text-3xl ]'}>
					{ts('stav.chyba.zadny-ridicak') as string}
				</p>
				<p>{ts('stav.chyba.zadny-ridicak-popis') as string}</p>
			</InfoBanner>
		);
	}

	return (
		<ContentLayout customClasses={'!pt-4'}>
			<DriversRegistryNavigation code={code} />
			{selectedPage === 'competency' && (
				<DriversRegistryCompetencyView code={code} />
			)}
			{selectedPage === 'offences' && (
				<DriversRegistryOffencesView code={code} />
			)}
			{selectedPage === 'prohibitions' && (
				<DriversRegistryProhibitionsView code={code} />
			)}
		</ContentLayout>
	);
};

DriversRegistryView.displayName = 'DriversRegistryView';
