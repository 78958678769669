import { useState } from 'react';

export interface IUseBoolean {
	value: boolean;
	isFalse: boolean;
	isTrue: boolean;
	setValue: (value: boolean) => void;
	setFalse: () => void;
	setTrue: () => void;
	toggle: () => void;
}

export const useBoolean = (initial?: boolean): IUseBoolean => {
	const [value, setValue] = useState<boolean>(!!initial);

	return {
		value,
		isFalse: !value,
		isTrue: value,
		setValue,
		setFalse: () => setValue(false),
		setTrue: () => setValue(true),
		toggle: () => setValue(!value),
	};
};
