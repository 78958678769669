import { GovPagination } from '@gov-design-system-ce/react';
import { routeResolver, useScroll } from '@gov-nx/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	PageCode,
	useMojePodaniSeznamContextInstance,
} from '@gov-nx/module/page';
import { ContentLayout, InfoBanner, Tile } from '@gov-nx/ui/web';
import { MySubmissionsListFilter } from './MySubmissionsListFilter';
import { MySubmissionsListSkeleton } from './MySubmissionsListSkeleton';

export interface MySubmissionsViewProps {
	code: PageCode;
}

export const MySubmissionsList = ({ code }: MySubmissionsViewProps) => {
	const { scrollIntoView } = useScroll();
	const { t } = useTranslation([code]);
	const {
		submissions,
		filter,
		currentPage,
		goToPage,
		numberOfSubmissions,
		query,
	} = useMojePodaniSeznamContextInstance();
	return (
		<ContentLayout customClasses={'mt-6'}>
			<MySubmissionsListFilter code={code} />
			{query.isFetching ? (
				<MySubmissionsListSkeleton />
			) : (
				<section>
					{numberOfSubmissions ? (
						<>
							<ul className={'gov-list--plain mt-2 [ md:mt-8 ]'}>
								{submissions.map((submission) => {
									return (
										<Tile
											key={submission.id}
											icon={{ name: 'chevron-right', type: 'basic' }}
											to={routeResolver(PageCode['moje-podani-detail'], {
												submissionId: submission.id,
											})}
											name={submission.name}
											date={t('status.' + submission.status.message, {
												namespace: code,
												count: submission.status.date as number,
												date: submission.status.date,
											})}>
											<p className={'text-secondary-700'}></p>
										</Tile>
									);
								})}
							</ul>
							{numberOfSubmissions > filter.pocet ? (
								<footer className={'mt-6'}>
									<GovPagination
										onGov-page={(event) => {
											scrollIntoView();
											goToPage(event.detail.pagination.currentPage);
										}}
										current={currentPage}
										page-size={filter.pocet}
										total={numberOfSubmissions}
										wcag-label={t('strankovani')}></GovPagination>
								</footer>
							) : null}
						</>
					) : (
						<InfoBanner
							icon={{ name: 'cactus', type: 'colored' }}
							type="secondary"
							className={'!px-0 !py-4'}>
							<p className={'text-2xl [ md:text-3xl ]'}>
								{t('stav.chyba.zatim-jste-nic-nepodali') as string}
							</p>
							<p>{t('stav.chyba.zrejme-jste-podani-nevytvorili') as string}</p>
						</InfoBanner>
					)}
				</section>
			)}
		</ContentLayout>
	);
};
