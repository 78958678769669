import { GovIcon } from '@gov-design-system-ce/react';
import { SideBoxHeader } from '@gov-nx/component/web';
import { useNotificationMessageLoader } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { ContentLayout, NotificationBadge } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const DashboardUnreadNotificationsWidgetView = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Global);

	const { unreadNotificationsCount, unreadNotifications } =
		useNotificationMessageLoader();

	if (!unreadNotifications.length) {
		return null;
	}

	return (
		<ContentLayout customClasses={'!p-0 !mx-0'}>
			<SideBoxHeader link={PageCode['upozorneni']}>
				<GovIcon
					name="bell-fill"
					type="basic"
					slot="icon"
					className={'w-6 h-6 !text-neutral-white'}></GovIcon>
				{ts('upozorneni.neprectena')}
				{unreadNotificationsCount > 0 && (
					<NotificationBadge customClasses={'text-primary-700'}>
						{unreadNotificationsCount}
					</NotificationBadge>
				)}
				<GovIcon
					name="chevron-right"
					type="basic"
					slot="icon"
					className={'!ml-auto w-3 !text-neutral-white'}></GovIcon>
			</SideBoxHeader>
			<ul className={'gov-list--plain'}>
				{unreadNotifications.slice(0, 3).map((notification) => {
					return (
						<li
							className={
								'border-b border-secondary-500 [ last-of-type:border-b-0 ]'
							}
							key={notification?.nazev}>
							<a
								href={PageCode['upozorneni']}
								className={
									'flex justify-between p-4 text-primary-600 [ hover-bg-primary-200 ]'
								}>
								<div className={'text-lg font-bold'}>{notification.nazev}</div>
								<div className={'flex flex-col items-end justify-center'}>
									<span className={'text-xs !text-secondary-700'}>
										{notification.datumACasVytvoreni
											? toDateReadable(notification.datumACasVytvoreni)
											: undefined}
									</span>
									<GovIcon
										name="chevron-right"
										type="basic"
										slot="icon"
										className={'w-3 !my-auto text-primary-600'}></GovIcon>
								</div>
							</a>
						</li>
					);
				})}
			</ul>
		</ContentLayout>
	);
};
