import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { is } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useAddressLookup } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormInput,
	FormRadio,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

interface ContactAddressFormProps {
	code: PageCode;
	addressLookup: ReturnType<typeof useAddressLookup>;
}

export const ContactAddressForm = ({
	code,
	addressLookup,
}: ContactAddressFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();

	const tsn = getLocalizeCurried(code);
	const isProcessing = addressLookup.isLoading;

	return (
		<FormProvider {...addressLookup.formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={addressLookup.formDefinition}
				onSubmit={addressLookup.onSubmit}>
				<FormWidthElementLayout type="l">
					{addressLookup.error && <div>{addressLookup.error.message}</div>}

					<LayoutGap gap={4}>
						<FormInput
							label={{ children: tsn('formular.pole.nazev-obce') }}
							field={{
								name: 'nazevObce',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.nazev-obce'),
							}}
						/>

						<FormInput
							label={{ children: tsn('formular.pole.nazev-ulice') }}
							field={{
								name: 'nazevUlice',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.nazev-ulice'),
							}}
						/>

						<FormInput
							label={{ children: tsn('formular.pole.cislo') }}
							field={{
								name: 'cislo',
								disabled: isProcessing,
								placeholder: tsn('formular.placeholder.cislo'),
							}}
						/>

						{addressLookup.results?.length && (
							<FormRadio
								field={{
									name: '_address',
									disabled: isProcessing,
									options: addressLookup.results
										.map((option) => {
											return option.kod
												? {
														value: option.kod,
														label: { children: option.adresaText ?? '' },
												  }
												: undefined;
										})
										.filter(is),
								}}
								label={{
									children: tsn('formular.pole.clen-adresa'),
								}}
							/>
						)}
					</LayoutGap>
				</FormWidthElementLayout>

				<ButtonGroupLayout customClasses={'mt-8'}>
					<Button
						variant={'primary'}
						type={'solid'}
						onClick={addressLookup.onLookup}
						wcagLabel={tsn('akce.wcag.hledat')}>
						{tsn('formular.tlacitko.hledat')}
					</Button>

					<Button
						loading={isProcessing}
						nativeType={'submit'}
						wcagLabel={tsn('formular.tlacitko.adresa-ulozit')}
						disabled={
							isProcessing ||
							!addressLookup.formDefinition.formMethods.watch('_address')
						}
						variant={'primary'}
						type={'outlined'}>
						{tsn('formular.tlacitko.adresa-ulozit')}
					</Button>
				</ButtonGroupLayout>
			</PoFormWeb>
		</FormProvider>
	);
};
