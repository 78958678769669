import { LocalizationLanguage } from '@gov-nx/core/app';
import { getKeys, Nullable } from '@gov-nx/core/types';
import { getPageUrlByCode, PageCode } from '@gov-nx/module/page';
import { replaceVariablesInUrl } from '@gov-nx/utils/common';
import { ServiceCode } from './codes';
import ServiceList from './services';

export interface ServiceListMapper {
	code: ServiceCode;
	locales: {
		[LocalizationLanguage.Czech]: {
			slug: string;
		};
		[LocalizationLanguage.English]?: {
			slug: string;
		};
	};
}

export const getServiceUrlByCode = (
	code: ServiceCode,
	params: Nullable<Record<string, unknown>> = null,
	locale: LocalizationLanguage = LocalizationLanguage.Czech
): Nullable<string> => {
	const slug = getServiceRouteSlug(code, locale);
	if (slug) {
		const serviceSlug = getPageUrlByCode(PageCode['sluzba'], params, locale);
		if (serviceSlug) {
			const link = [serviceSlug, slug].join('/');

			return replaceVariablesInUrl(link, params);
		}
	}
	return null;
};

export const getServiceByCode = (
	code: ServiceCode
): Nullable<ServiceListMapper> => {
	const service = ServiceList.find((service) => service.code === code) || null;
	if (service) return service;
	return null;
};

export const getServiceRouteSlug = (
	code: ServiceCode,
	locale: LocalizationLanguage = LocalizationLanguage.Czech
): Nullable<string> => {
	const service = getServiceByCode(code);
	if (service && service.locales[locale]) {
		return service.locales[locale]?.slug ?? null;
	}
	return null;
};

export const getServiceCodeBySlug = (slug: string): Nullable<ServiceCode> => {
	const service = ServiceList.find((service) => {
		const slugs = getKeys(service.locales)
			.map((key) => service.locales[key]?.slug === slug)
			.filter((item) => item);
		return slugs.length ? true : false;
	});
	if (service) {
		return service.code;
	}
	return null;
};
