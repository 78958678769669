import {
	DatoveSchrankyDatovaSchrankaDto,
	DatoveSchrankyOdpovedSeznamDto,
} from '../generated/datove-schranky.types';

// Note: Values are arbitrary, used for sorting by type
export enum DatoveSchrankyTypSchranky {
	// Fyzicke osoby:
	FO = 100,

	// Podnikajici fyzicke osoby:
	PFO = 200,
	PFO_ADVOK,
	PFO_AUDITOR,
	PFO_DANPOR,
	PFO_INSSPR,
	PFO_REQ,
	PFO_TLUMOCNIK,
	PFO_ZNALEC,
	PFO_ARCH,
	PFO_AIAT,
	PFO_AZI,

	// Pravnicke osoby:
	PO = 300,
	PO_REQ,
	PO_ZAK,

	// Organy verejne moci:
	OVM = 400,
	OVM_EXEK,
	OVM_FO,
	OVM_NOTAR,
	OVM_PFO,
	OVM_PO,
	OVM_REQ,
}

export interface DatovkaApiType
	extends Omit<DatoveSchrankyDatovaSchrankaDto, 'typSchranky'> {
	typSchranky?: keyof typeof DatoveSchrankyTypSchranky;
}

export type DatovkyApiSeznam = DatoveSchrankyOdpovedSeznamDto & {
	seznam?: DatovkaApiType[];
};
