import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';

export const useDataBoxes = (
	requiredDataBoxes: DatovkaApiType['typSchranky'][]
) => {
	const { getConnectedDataboxesListByTypes } = useDataBoxStore();
	const dataBoxes = getConnectedDataboxesListByTypes(requiredDataBoxes);
	const isDataBoxConnected = dataBoxes.length > 0;

	return { isDataBoxConnected, requiredDataBoxes };
};
