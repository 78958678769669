import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { PoFormWeb, PoMarkdownRenderWeb } from '@gov-nx/component/web';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useLocale } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import { PageCode, useDataboxAdditionalSettings } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, FormSwitch, Modal } from '@gov-nx/ui/web';

export const DataboxConnectedSettingsModal = () => {
	const { t: tsn } = useLocale(PageCode['datove-schranky']);
	const [databoxId, setDataboxId] = useState<Optional<string>>();

	useDataBoxEvents({
		onShowDataBoxSettingModal: (_, payload) => setDataboxId(payload.databoxId),
	});

	const {
		formDefinitions,
		onSubmit,
		isLoading,
		isDisabled,
		isInitialLoading,
		emailVerified,
		isArchivingAvailable,
		showLargeMessageArchive,
	} = useDataboxAdditionalSettings({
		dataBoxId: databoxId,
		onSuccess: () => setDataboxId(undefined),
	});

	if (isInitialLoading) {
		return null;
	}

	return (
		<Modal
			label={tsn('datove-schranky-pripojeni-modal.nadpis')}
			open={!!databoxId}
			onGov-close={() => setDataboxId(undefined)}>
			<FormProvider {...formDefinitions.formMethods}>
				<PoFormWeb
					onSubmit={onSubmit}
					formDefinition={formDefinitions}>
					<PoMarkdownRenderWeb>
						{tsn('datove-schranky-pripojeni-modal.popis')}
					</PoMarkdownRenderWeb>
					{isArchivingAvailable ? (
						<>
							<div className={'ml-4 mb-8'}>
								<FormSwitch
									field={{
										name: `shouldArchiveAutomatically`,
										disabled: isDisabled,
										label: {
											children: tsn('automaticka-archivace.label'),
											identifier: `label-${databoxId}-shouldArchiveAutomatically`,
										},
										wcagLabelledBy: `label-${databoxId}-shouldArchiveAutomatically`,
										wcagDescribedBy: `automaticka-archivace-plny-popis_${databoxId}`,
									}}
								/>
								<p className="text-xs md:text-s text-secondary-700">
									{tsn('automaticka-archivace.plny-popis')}
								</p>
							</div>

							{showLargeMessageArchive ? (
								<div className={'ml-4 mb-8'}>
									<FormSwitch
										field={{
											name: `shouldArchiveLargeMessagesAutomatically`,
											disabled: isDisabled,
											label: {
												children: tsn(
													'automaticka-archivace-velke-zpravy.label'
												),
												identifier: `label-${databoxId}-shouldArchiveLargeMessagesAutomatically`,
											},
											wcagLabelledBy: `label-${databoxId}-shouldArchiveLargeMessagesAutomatically`,
											wcagDescribedBy: `automaticka-archivace-plny-popis_${databoxId}`,
										}}
									/>
									<p className="text-xs md:text-s text-secondary-700">
										{tsn('automaticka-archivace-velke-zpravy.plny-popis')}
									</p>
								</div>
							) : null}
						</>
					) : (
						<GovMessage
							variant={'warning'}
							className={'ml-4 mb-8'}>
							<GovIcon
								type={'basic'}
								name={'info'}
								slot={'icon'}></GovIcon>
							<p>{tsn('automaticka-archivace.nedostupna-PO')}</p>
						</GovMessage>
					)}

					<div className={'ml-4 mb-8'}>
						<FormSwitch
							field={{
								name: `emailEnabled`,
								disabled: isDisabled || !emailVerified,
								label: {
									children: tsn('datove-schranky-pripojeni-modal.email'),
								},
								wcagLabelledBy: `popisek-nastaveni-upozorneni-notifikace_${databoxId}`,
							}}
							group={{ gap: 's' }}
						/>
						<p className="text-xs md:text-s text-secondary-700">
							{tsn('datove-schranky-pripojeni-modal.email-popis')}
						</p>
						{!emailVerified && (
							<GovMessage variant={'warning'}>
								<GovIcon
									type={'basic'}
									name={'info'}
									slot={'icon'}
								/>
								{tsn('upozorneni.zprava.zadny-email')}
							</GovMessage>
						)}
					</div>

					<ButtonGroupLayout>
						<Button
							wcagLabel={tsn(
								'datove-schranky-pripojeni-modal.tlacitko.pokracovat'
							)}
							disabled={isDisabled || isLoading}
							loading={isLoading}
							nativeType={'submit'}
							variant={'primary'}
							size={'l'}
							type={'solid'}>
							{tsn('datove-schranky-pripojeni-modal.tlacitko.pokracovat')}
						</Button>
					</ButtonGroupLayout>
				</PoFormWeb>
			</FormProvider>
		</Modal>
	);
};
