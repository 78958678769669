import { UpozorneniContextProvider } from '@gov-nx/module/page';
import { DashboardUnreadNotificationsWidgetView } from './DashboardUnreadNotificationsWidgetView';

export const DashboardUnreadNotificationsWidget = () => {
	return (
		<UpozorneniContextProvider>
			<DashboardUnreadNotificationsWidgetView />
		</UpozorneniContextProvider>
	);
};
