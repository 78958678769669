import { GovIcon } from '@gov-design-system-ce/react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxAccess, useDataBoxesContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxesItem } from './DataBoxesItem';

interface DataBoxesListProps {
	list: DatovkaApiType[];
}

export const DataBoxesList = ({ list }: DataBoxesListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { dataBoxId } = useDataBoxesContext();
	const { connect } = useDataBoxEvents();
	const { listToConnectTypes } = useDataBoxAccess({ requiredTypes: [] });

	return (
		<nav aria-label={tn('schranky.seznam-datovek')}>
			<ul className={'gov-list--plain flex flex-col gap-6'}>
				{list.map((dataBox) => {
					return (
						<li
							key={dataBox.datovaSchrankaId}
							className={'static'}>
							{dataBox.datovaSchrankaId && dataBoxId ? (
								<DataBoxesItem
									dataBox={dataBox}
									toggleable={list.length > 1}
									defaultlyOpen={dataBox.datovaSchrankaId === dataBoxId}
								/>
							) : (
								dataBox.datovaSchrankaId
							)}
						</li>
					);
				})}
				{listToConnectTypes.length ? (
					<li className={'p-1 [ xl:hidden ]'}>
						<button
							onClick={() => connect({ requiredTypes: listToConnectTypes })}
							aria-label={tn('schranky.pripojit-dalsi-datovku')}>
							<div
								className={
									'flex flex-col justify-center items-center w-10 h-10 bg-neutral-white rounded-full text-secondary-800'
								}>
								<GovIcon
									name="datovka"
									className={'w-5'}
								/>
								<span
									className={
										'absolute top-0 right-0 w-4 h-4 flex justify-center items-center rounded-full text-xs font-medium text-neutral-white bg-primary-600'
									}>
									+
								</span>
							</div>
						</button>
					</li>
				) : null}
			</ul>
		</nav>
	);
};
