import {
	downloadDocumentQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { OnDocumentSaveParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents } from '@gov-nx/core/events';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
	useMap,
} from '@gov-nx/utils/common';

export interface DocumentDownloadProps {
	onError?: (id: number, error: Error) => void;
	onSuccess?: (data: OnDocumentSaveParams) => void;
}

export const useDocumentDownload = (props?: DocumentDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: downloadDocumentQuery,
		onError: (error, variables, context) => {
			props?.onError && props.onError(variables, error as GovError);
		},
		onSuccess: async (response, id) => {
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(id);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props?.onSuccess &&
						props.onSuccess({ antivirus, fileName, blob, id });
				} catch (e) {
					props?.onError && props.onError(id, e as Error);
				}
			}
		},
	});
	return mutation;
};

export const useDocumentsDownload = () => {
	const { downloadDocument } = useDownloadEvents();
	const isDownloading = useMap<number, boolean>();

	const prepare = (documentId: number) => {
		return {
			documentId,
			onDownload: () => {
				isDownloading.set(documentId, true);
				return downloadDocument({
					documentId,
					callback: () => {
						isDownloading.set(documentId, false);
					},
				});
			},
			isDownloading: isDownloading.get(documentId) ?? false,
		};
	};

	return { prepare };
};
