import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { FilterSortDirection } from '@gov-nx/core/types';
import {
	PageCode,
	useMojePodaniSeznamContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	FilterParameterList,
	FormDatePicker,
	FormSelect,
	FormWidthElementLayout,
	HeaderLayout,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

export interface MySubmissionsListFilterProps {
	code: PageCode;
}

export const MySubmissionsListFilter = ({
	code,
}: MySubmissionsListFilterProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const {
		controls,
		resetFilter,
		filterParams,
		setControls,
		filter,
		formDefinition,
		onSubmit,
	} = useMojePodaniSeznamContextInstance();

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<>
					<HeaderLayout className={'!items-start'}>
						<FilterParameterList
							className={'pt-0'}
							items={filterParams}
							onReset={() => resetFilter()}
						/>
						<Button
							onClick={() => setControls({ displayFilter: true })}
							variant="primary"
							type="outlined"
							className={'ml-auto'}
							wcagLabel={ts('akce.wcag.zobrazit-filtr')}>
							<GovIcon
								name={'funnel-fill'}
								type={'basic'}></GovIcon>
						</Button>
					</HeaderLayout>
					<GovModal
						open={controls.displayFilter}
						label={ts('filtr.nazev')}
						onGov-close={() => setControls({ displayFilter: false })}
						wcagCloseLabel={ts('akce.wcag.zavrit-filtr')}>
						<div className={'flex flex-col gap-10 [ md:flex-row ]'}>
							<FormWidthElementLayout className={'flex flex-col gap-4'}>
								<FormDatePicker
									field={{
										name: 'datumVyrizeni',
									}}
									label={{
										children: ts('filtr.pole.datum-vyrizeni'),
									}}
									group={{ gap: 's' }}
								/>
								<FormDatePicker
									field={{
										name: 'lhutaVyrizeni',
									}}
									label={{
										children: ts('filtr.pole.lhuta-vyrizeni'),
									}}
									group={{ gap: 's' }}
								/>
								<FormDatePicker
									field={{
										name: 'vyrizeno',
									}}
									label={{
										children: ts('filtr.pole.vyrizeno'),
									}}
									group={{ gap: 's' }}
								/>
							</FormWidthElementLayout>
							<FormWidthElementLayout className={'flex flex-col gap-4'}>
								<FormSelect
									field={{
										name: 'razeni',
										options: [
											{
												value: 'datumOdeslani',
												label: ts('formular.select.datum-odeslani'),
											},
											{
												value: 'datumVyrizeni',
												label: ts('formular.select.datum-vyrizeni'),
											},
											{
												value: 'lhutaVyrizeni',
												label: ts('formular.select.lhuta-vyrizeni'),
											},
										],
									}}
									label={{
										children: ts('filtr.pole.razeni'),
									}}
									group={{ gap: 's' }}
								/>
								<FormSelect
									field={{
										name: 'razeniSmer',
										options: [
											{
												value: FilterSortDirection.DESC,
												label: ts('formular.select.sestupne'),
											},
											{
												value: FilterSortDirection.ASC,
												label: ts('formular.select.vzestupne'),
											},
										],
									}}
									label={{
										children: ts('filtr.pole.razeni-smer'),
									}}
									group={{ gap: 's' }}
								/>
							</FormWidthElementLayout>
						</div>
						<div
							slot="footer"
							className={'flex gap-4'}>
							<Button
								variant={'primary'}
								type={'solid'}
								nativeType={'submit'}
								wcagLabel={ts('akce.wcag.filtrovat')}>
								{ts('akce.filtrovat')}
							</Button>
							{filter.datumVyrizeni ||
							filter.lhutaVyrizeni ||
							filter.vyrizeno ? (
								<Button
									onClick={() => resetFilter()}
									variant="error"
									type="outlined"
									wcagLabel={ts('akce.wcag.zrusit-filtr')}>
									{ts('akce.zrusit-filtr')}
								</Button>
							) : null}
						</div>
					</GovModal>
				</>
			</PoFormWeb>
		</FormProvider>
	);
};
