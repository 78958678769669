import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { getRequiredDataBoxes } from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefenitions';
import { ServiceContextTypes } from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const { controls, setControls } = useProcessControl();
	const formDefinition = FormInstance({ code });

	const submitMutation = usePoMutation({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('form.messages.odeslano', { namespace: code }),
			});
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				controls,
				setControls,
				requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisSeznamuDodavateluContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
