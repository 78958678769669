import { useEffect } from 'react';
import {
	downloadBlobSubscription,
	downloadDocumentSubscription,
	downloadEvents,
	downloadFileSubscription,
	downloadSubscription,
	downloadDataBoxAttachmentSubscription,
	downloadDataBoxMessageSubscription,
	DownloadSubscriptionTypes,
	downloadEPetitionAttachmentSubscription,
	downloadEPetitionSubscription,
} from './downloadEvents';
import {
	DownloadBlobSubscription,
	DownloadDocumentSubscription,
	DownloadFileSubscription,
	DownloadSubscription,
	DownloadDataBoxAttachmentSubscription,
	DownloadDataBoxMessageSubscription,
	DownloadEPetitionAttachmentSubscription,
	DownloadEPetitionSubscription,
} from './types';

interface DownloadEventsProps {
	onDownload?: (
		action: DownloadSubscription['type'],
		payload: DownloadSubscription['payload']
	) => void;
	onDocumentDownload?: (
		action: DownloadDocumentSubscription['type'],
		payload: DownloadDocumentSubscription['payload']
	) => void;
	onFileDownload?: (
		action: DownloadFileSubscription['type'],
		payload: DownloadFileSubscription['payload']
	) => void;
	onBlobDownload?: (
		action: DownloadBlobSubscription['type'],
		payload: DownloadBlobSubscription['payload']
	) => void;
	onDataBoxAttachmentDownload?: (
		action: DownloadDataBoxAttachmentSubscription['type'],
		payload: DownloadDataBoxAttachmentSubscription['payload']
	) => void;
	onEPetitionAttachmentDownload?: (
		action: DownloadEPetitionAttachmentSubscription['type'],
		payload: DownloadEPetitionAttachmentSubscription['payload']
	) => void;
	onEPetitionDownload?: (
		action: DownloadEPetitionSubscription['type'],
		payload: DownloadEPetitionSubscription['payload']
	) => void;
	onDataBoxMessageDownload?: (
		action: DownloadDataBoxMessageSubscription['type'],
		payload: DownloadDataBoxMessageSubscription['payload']
	) => void;
}

export const useDownloadEvents = ({
	onDownload,
	onDocumentDownload,
	onFileDownload,
	onBlobDownload,
	onDataBoxAttachmentDownload,
	onEPetitionAttachmentDownload,
	onEPetitionDownload,
	onDataBoxMessageDownload,
}: DownloadEventsProps = {}) => {
	useEffect(() => {
		const download =
			onDownload &&
			downloadEvents.subscribe(DownloadSubscriptionTypes.download, onDownload);
		const downloadDocument =
			onDocumentDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadDocument,
				onDocumentDownload
			);
		const downloadFile =
			onFileDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadFile,
				onFileDownload
			);
		const downloadBlob =
			onBlobDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadBlob,
				onBlobDownload
			);
		const downloadDataBoxAttachment =
			onDataBoxAttachmentDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadDataBoxAttachment,
				onDataBoxAttachmentDownload
			);
		const downloadEPetitionAttachment =
			onEPetitionAttachmentDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadEPetitionAttachment,
				onEPetitionAttachmentDownload
			);
		const downloadEPetition =
			onEPetitionDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadEPetition,
				onEPetitionDownload
			);
		const downloadDataBoxMessage =
			onDataBoxMessageDownload &&
			downloadEvents.subscribe(
				DownloadSubscriptionTypes.downloadDataBoxMessage,
				onDataBoxMessageDownload
			);

		return () => {
			download?.unSubscribe();
			downloadDocument?.unSubscribe();
			downloadFile?.unSubscribe();
			downloadBlob?.unSubscribe();
			downloadDataBoxAttachment?.unSubscribe();
			downloadEPetitionAttachment?.unSubscribe();
			downloadEPetition?.unSubscribe();
			downloadDataBoxMessage?.unSubscribe();
		};
	}, []);

	const download = (payload: DownloadSubscription['payload']) => {
		downloadEvents.publish(downloadSubscription(payload));
	};

	const downloadFile = (payload: DownloadFileSubscription['payload']) => {
		downloadEvents.publish(downloadFileSubscription(payload));
	};

	const downloadDocument = (
		payload: DownloadDocumentSubscription['payload']
	) => {
		downloadEvents.publish(downloadDocumentSubscription(payload));
	};

	const downloadBlob = (payload: DownloadBlobSubscription['payload']) => {
		downloadEvents.publish(downloadBlobSubscription(payload));
	};

	const downloadDataBoxAttachment = (
		payload: DownloadDataBoxAttachmentSubscription['payload']
	) => {
		downloadEvents.publish(downloadDataBoxAttachmentSubscription(payload));
	};

	const downloadEPetitionAttachment = (
		payload: DownloadEPetitionAttachmentSubscription['payload']
	) => {
		downloadEvents.publish(downloadEPetitionAttachmentSubscription(payload));
	};

	const downloadEPetition = (
		payload: DownloadEPetitionSubscription['payload']
	) => {
		downloadEvents.publish(downloadEPetitionSubscription(payload));
	};

	const downloadDataBoxMessage = (
		payload: DownloadDataBoxMessageSubscription['payload']
	) => {
		downloadEvents.publish(downloadDataBoxMessageSubscription(payload));
	};

	return {
		download,
		downloadFile,
		downloadDocument,
		downloadBlob,
		downloadDataBoxAttachment,
		downloadEPetitionAttachment,
		downloadEPetition,
		downloadDataBoxMessage,
	};
};
