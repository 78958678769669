import React, { FormEventHandler } from 'react';
import { FormDefinition, PoForm } from '@gov-nx/core/service';

interface PropsWeb<T extends object> {
	onSubmit?: FormEventHandler<HTMLFormElement>;
	formDefinition: FormDefinition<T>;
	children: React.ReactNode;
}

export const PoFormWeb = <T extends object>({
	formDefinition,
	children,
	onSubmit,
}: PropsWeb<T>) => {
	return (
		<PoForm formDefinition={formDefinition}>
			<form
				autoComplete={'off'}
				noValidate={true}
				onSubmit={onSubmit}>
				{children}
			</form>
		</PoForm>
	);
};
