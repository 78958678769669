import React from 'react';
import { useAgendaSearchAutocomplete } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

export const AgendaSearchAutocomplete = (props: WebFormAutocompleteProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { isFetching, agendas, setText } = useAgendaSearchAutocomplete('nazev');

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				rightIcon: {
					name: isFetching ? 'loader' : 'search',
					type: 'basic',
				},
				messageEmpty:
					props.field.messageEmpty ?? tn('naseptavac.pole.zadny-vysledek'),
				messageLoading:
					props.field.messageLoading ?? tn('naseptavac.pole.nacitam'),
				options: agendas,
				templateResolver: (item) => `${item.kod} ${item.nazev}`,
				nameKey: 'kod',
			}}
		/>
	);
};
