import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
	DatovkyApiSeznam,
	fetchDataBoxesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useAppContext } from '@gov-nx/core/app';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { sortDataboxesBeforeStoringToState } from '@gov-nx/utils/common';

const queryKey = ['data-box-list'];

export const useDataBoxLoader = () => {
	const { setAppError } = useAppContext();
	const { setDataBoxList } = useDataBoxStore();

	const onSuccess = (data: DatovkyApiSeznam) => {
		if (!data || !data.seznam || !Array.isArray(data.seznam)) {
			setAppError(new Error('DataBoxLoader: Invalid data received from API'));
			return;
		}

		setDataBoxList(
			data.seznam.length ? sortDataboxesBeforeStoringToState(data.seznam) : []
		);
	};

	const queryClient = useQueryClient();
	const query = usePoQuery({
		queryKey,
		queryFn: fetchDataBoxesQuery,
		onSuccess,
		onError: setAppError,
		enabled: false,
	});

	const initDataBoxes = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetchDataBoxes = useCallback(async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey });

		await query.refetch();
	}, [query, queryClient]);

	return {
		isFetched: query.isFetched,
		initDataBoxes,
		refetchDataBoxes,
		data: query.data,
	};
};
