import React from 'react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxSearchAutocomplete } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

interface DataBoxSearchAutocompleteProps extends WebFormAutocompleteProps {
	dataBoxType?: DatovkaApiType['typSchranky'];
}

export const DataBoxSearchAutocomplete = (
	props: DataBoxSearchAutocompleteProps
) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { isFetching, dataBoxes, setText } = useDataBoxSearchAutocomplete(
		'GENERAL',
		props.dataBoxType
	);

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				options: dataBoxes,
				processing: isFetching,
				messageEmpty:
					props.field.messageEmpty ?? tn('naseptavac.pole.zadny-vysledek'),
				messageLoading:
					props.field.messageLoading ?? tn('naseptavac.pole.nacitam'),
				rightIcon: {
					name: 'search',
					type: 'basic',
				},
				templateResolver: (item) => {
					return dataBoxLabel(item);
				},
				nameKey: 'datovaSchrankaId',
			}}
		/>
	);
};
