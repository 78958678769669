import { useQueryClient } from '@tanstack/react-query';
import {
	DocumentRestoreParams,
	documentRestoreQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';

export interface DocumentRestoreProps {
	onError?: (error: Error, params: DocumentRestoreParams) => void;
	onRestoreSuccess?: (params: DocumentRestoreParams) => void;
}

export const useDocumentRestore = ({
	onRestoreSuccess,
	onError,
}: DocumentRestoreProps) => {
	const queryClient = useQueryClient();

	const restoreMutation = usePoMutation({
		mutationFn: documentRestoreQuery,
		onError: (error, params) => {
			onError &&
				onError(
					new GovError(
						`Error: An unexpected error occurred while item restoring. ${error}`
					),
					params
				);
		},
		onSuccess: async (_, params) => {
			onRestoreSuccess && onRestoreSuccess(params);

			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({
				queryKey: ['documents-binned'],
			});
		},
	});

	const restoreDocument = async ({
		id,
	}: DocumentRestoreParams): Promise<void> => {
		return restoreMutation.mutate({ id });
	};

	const restoreDocuments = async (documentIds: number[]): Promise<void[]> => {
		return Promise.all(documentIds.map((id) => restoreDocument({ id })));
	};

	return {
		restoreDocument,
		restoreDocuments,
		isRestoring: restoreMutation.isLoading,
	};
};
