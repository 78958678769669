import * as yup from 'yup';

interface AddressSearchShape {
	controls: boolean;
	nazevObce: {
		requiredMessage: string;
	};
	cislo: {
		requiredMessage: string;
		max9999Message: string;
		onlyNumbersMessage: string;
	};
}

export const useAddressSearchShape = () => {
	return {
		getAddressSearchDefaultValues: () => ({
			nazevObce: undefined,
			nazevUlice: undefined,
			cislo: undefined,
		}),
		getAddressSearchShape: ({
			controls,
			nazevObce,
			cislo,
		}: AddressSearchShape) => {
			return {
				nazevObce: yup.string().when([], {
					is: () => controls,
					then: yup.string().required(nazevObce.requiredMessage),
					otherwise: yup.string().optional(),
				}),
				nazevUlice: yup.string().optional(),
				cislo: yup.string().when([], {
					is: () => controls,
					then: yup
						.string()
						.required(cislo.requiredMessage)
						.min(0)
						.max(4, cislo.max9999Message)
						.matches(/^[0-9]+$/, cislo.onlyNumbersMessage),

					otherwise: yup.string().optional(),
				}),
			};
		},
	};
};
