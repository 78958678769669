import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import { Button } from '../Button/Button';

export interface BadgeNavProps {
	children: React.ReactNode;
	className?: string;
	moreBadges?: Optional<boolean>;
	wcagLabel?: string;
	customClasses?: string;
}

export const BadgeNav = ({
	children,
	className,
	moreBadges = undefined,
	wcagLabel,
	customClasses,
}: BadgeNavProps) => {
	const [showAll, setShowAll] = useState<boolean>(false);
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<nav
			aria-label={wcagLabel}
			className={cx(
				'relative flex flex-row items-start justify-start gap-2',
				moreBadges === undefined
					? ''
					: '[ after:absolute after:top-0 after:right-[54px] after:w-4 after:h-10 after:bg-gradient-to-l after:from-neutral-white ]',
				showAll ? '[ after:content-none ]' : undefined
			)}>
			<ul
				className={cx(
					'gov-list--plain flex pb-4 gap-4 whitespace-nowrap overflow-x-auto',
					moreBadges === undefined
						? '[ xl:overflow-x-visible xl:flex-wrap ]'
						: 'pr-4',
					customClasses,
					className && className,
					showAll ? 'flex-wrap' : undefined
				)}>
				{children}
			</ul>
			{moreBadges ? (
				<Button
					onClick={() => setShowAll(!showAll)}
					variant={'primary'}
					type={'base'}
					iconLeft={showAll ? 'basic/chevron-up' : 'basic/chevron-down'}
					wcagLabel={t('akce.dalsi')}></Button>
			) : null}
		</nav>
	);
};
