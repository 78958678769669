import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../constants';
import { AppState } from './store.types';

const initialState = {
	lastActivityTime: 0,
	pushNotificationWindowShown: false,
	dismissedAlertIds: [],
};

export const useAppStore = create<AppState>()(
	persist(
		(set) => ({
			...initialState,

			saveLastActivityTime: (lastActivityTime: number) =>
				set({ lastActivityTime }),

			setPushNotificationWindowShown: (pushNotificationWindowShown: boolean) =>
				set({ pushNotificationWindowShown }),

			setDismissedAlertIds: (dismissedAlertIds: number[]) =>
				set({ dismissedAlertIds }),

			appReset: () => set(initialState),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.app,
		}
	)
);
