import { useScroll } from '@gov-nx/web';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parseIcon } from '@gov-nx/component/common';
import { CookbookListCoreProps } from '@gov-nx/ui/types';
import { CookbookContainer } from './CookbookContainer';
import { CookbookItem } from './CookbookItem';

export const CookbookList = ({ label, items }: CookbookListCoreProps) => {
	const location = useLocation();
	const { scrollToElement } = useScroll();

	useEffect(() => {
		if (location.hash) {
			const elementId = location.hash.replace('#', '');
			const element = document.getElementById(elementId);
			scrollToElement(element);
		}
	}, [location.hash]);

	return (
		<CookbookContainer label={label}>
			{items.map(({ id, text, icon }) => {
				return (
					<CookbookItem
						key={id}
						icon={icon?.icon ? parseIcon(icon.icon) : null}
						copy={text}
					/>
				);
			})}
		</CookbookContainer>
	);
};
