import {
	GovButton,
	GovControlGroup,
	GovDropdown,
	GovIcon,
} from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout } from '@gov-nx/ui/web';

export const Actions = () => {
	const {
		code,
		deleteConfirm,
		deletePermanentConfirm,
		myFilesDownload,
		myFilesSelection,
	} = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<>
			<ButtonGroupLayout customClasses={'hidden [ md:flex ]'}>
				<Button
					wcagLabel={tsn('akce.stahnout')}
					variant="primary"
					type="solid"
					size="l"
					loading={myFilesDownload.isAnyDocumentDownloading}
					disabled={myFilesSelection.isItemWithNoFileInSelection}
					onClick={() =>
						myFilesDownload.downloadSelectedDocuments(
							myFilesSelection.selectedIds
						)
					}>
					<GovIcon
						slot="left-icon"
						name="download"></GovIcon>
					<span className={'whitespace-nowrap'}>
						{tsn('akce.stahnout', {
							count: myFilesSelection.selectedIds.length,
						})}
					</span>
				</Button>
				<GovControlGroup variant="error">
					<Button
						wcagLabel={tsn('akce.smazat')}
						variant="error"
						type="outlined"
						size="l"
						onClick={deleteConfirm.setTrue}>
						<GovIcon
							slot="left-icon"
							name="trash"></GovIcon>
						{tsn('akce.smazat', { count: myFilesSelection.selectedIds.length })}
					</Button>
					<GovDropdown position="right">
						<GovButton
							variant="error"
							type="outlined"
							size="l">
							<GovIcon
								name="chevron-down"
								slot="left-icon"
							/>
						</GovButton>
						<ul slot="list">
							<li>
								<Button
									wcagLabel={tsn('akce.trvale-odstranit')}
									variant="error"
									type="outlined"
									size="l"
									onClick={deletePermanentConfirm.setTrue}>
									<GovIcon
										slot="right-icon"
										name="x-lg"></GovIcon>
									{tsn('akce.trvale-odstranit', {
										count: myFilesSelection.selectedIds.length,
									})}
								</Button>
							</li>
						</ul>
					</GovDropdown>
				</GovControlGroup>
			</ButtonGroupLayout>
			<GovControlGroup className={'table-cell w-auto [ md:hidden ]'}>
				<GovButton
					variant="primary"
					type="base"
					size="s"
					disabled={myFilesSelection.isItemWithNoFileInSelection}>
					<GovIcon
						slot="left-icon"
						name="download"
					/>
					{tsn('akce.stahnout', { count: myFilesSelection.selectedIds.length })}
				</GovButton>
				<GovDropdown position="right">
					<GovButton
						variant="primary"
						type="base"
						size="s">
						<GovIcon
							name="chevron-down"
							slot="left-icon"
						/>
					</GovButton>
					<ul slot="list">
						<li>
							<Button
								wcagLabel={tsn('akce.stahnout')}
								variant="secondary"
								type="base"
								size="m"
								disabled={myFilesSelection.isItemWithNoFileInSelection}
								onClick={() =>
									myFilesDownload.downloadSelectedDocuments(
										myFilesSelection.selectedIds
									)
								}>
								<GovIcon
									slot="right-icon"
									name="download"></GovIcon>
								{tsn('akce.stahnout', {
									count: myFilesSelection.selectedIds.length,
								})}
							</Button>
						</li>
						<li>
							<Button
								wcagLabel={tsn('akce.smazat')}
								variant="secondary"
								type="base"
								size="m"
								onClick={deleteConfirm.setTrue}>
								<GovIcon
									slot="right-icon"
									name="trash"></GovIcon>
								{tsn('akce.smazat', {
									count: myFilesSelection.selectedIds.length,
								})}
							</Button>
						</li>
						<li>
							<Button
								wcagLabel={tsn('akce.trvale-odstranit')}
								variant="secondary"
								type="base"
								size="m"
								onClick={deletePermanentConfirm.setTrue}>
								<GovIcon
									slot="right-icon"
									name="x-lg"></GovIcon>
								{tsn('akce.trvale-odstranit', {
									count: myFilesSelection.selectedIds.length,
								})}
							</Button>
						</li>
					</ul>
				</GovDropdown>
			</GovControlGroup>
		</>
	);
};

export const ActionsNoFile = () => {
	const { code, deletePermanentConfirm, myFilesSelection } =
		useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	return (
		<>
			<ButtonGroupLayout customClasses={'hidden [ md:flex ]'}>
				<Button
					wcagLabel={tsn('akce.trvale-odstranit')}
					variant="error"
					type="outlined"
					size="l"
					onClick={deletePermanentConfirm.setTrue}>
					<GovIcon
						slot="right-icon"
						name="x-lg"></GovIcon>
					{tsn('akce.trvale-odstranit', {
						count: myFilesSelection.selectedIds.length,
					})}
				</Button>
			</ButtonGroupLayout>
			<GovControlGroup className={'table-cell w-auto [ md:hidden ]'}>
				<Button
					wcagLabel={tsn('akce.trvale-odstranit')}
					variant="primary"
					type="base"
					size="s"
					onClick={deletePermanentConfirm.setTrue}>
					<GovIcon
						slot="left-icon"
						name="x-lg"></GovIcon>
					{tsn('akce.trvale-odstranit', {
						count: myFilesSelection.selectedIds.length,
					})}
				</Button>
			</GovControlGroup>
		</>
	);
};
