import { useScroll } from '@gov-nx/web';
import React, { useEffect, useRef } from 'react';
import {
	CmsPageProviderCore,
	CmsPageProviderCoreProps,
} from '@gov-nx/component/common';
import { CmsPageSkeleton } from './CmsPageSkeleton';

export const CmsPageProvider = (props: CmsPageProviderCoreProps) => {
	const ref = useRef<{ init: () => void }>();
	const { scrollIntoView } = useScroll();
	useEffect(() => {
		if (ref.current) {
			ref.current.init();
			scrollIntoView({ behavior: 'instant' });
		}
	}, []);
	return (
		<CmsPageProviderCore
			ref={ref}
			{...props}
			skeleton={<CmsPageSkeleton>{props.skeleton}</CmsPageSkeleton>}>
			{props.children}
		</CmsPageProviderCore>
	);
};
