import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useNavigate } from 'react-router-dom';
import { useContactDetailsTabsFilter } from '@gov-nx/core/hooks';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

export const SettingsLink = () => {
	const navigate = useNavigate();
	const { setActiveTab } = useContactDetailsTabsFilter();

	return (
		<Button
			onClick={() => {
				setActiveTab('PO');
				navigate(routeResolver(PageCode['nastaveni']));
			}}
			variant={'primary'}
			size={'s'}
			type={'base'}>
			<GovIcon
				name="pencil-square"
				slot="right-icon"
			/>
		</Button>
	);
};
