import {
	GovButton,
	GovControlGroup,
	GovDropdown,
	GovIcon,
} from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';

interface EPetitionCloseSubmitButtonsProps {
	code: PageCode;
}

export const EPetitionCloseSubmitButtons = ({
	code,
}: EPetitionCloseSubmitButtonsProps) => {
	const { t } = useTranslation(code);
	const {
		controls,
		isDropdownOpen,
		handleEPetitionSubmitClose,
		handleEPetitionClose,
		setControls,
		onCloseModal,
	} = useEPeticeDetailContext();

	return (
		<div className="flex flex-start items-center gap-4">
			<GovControlGroup className="w-auto">
				<Button
					onClick={() => {
						setControls({ isCloseAndSubmitModal: true });
					}}
					variant={'primary'}
					type={'solid'}
					size="m"
					wcagLabel={t('akce.uzavrit-podat')}>
					{t('akce.uzavrit-podat')}
				</Button>
				<GovDropdown
					open={isDropdownOpen.value}
					onGov-change={(event) => {
						if (event.detail.open) {
							isDropdownOpen.setTrue();
						} else {
							isDropdownOpen.setFalse();
						}
					}}
					position="right">
					<GovButton
						disabled={controls.isSubmitAndCloseProcessing}
						variant="primary"
						type="solid"
						size="m"
						wcagLabel={'akce.wcag.rozbalit'}>
						<GovIcon
							name="chevron-down"
							slot="left-icon"
						/>
					</GovButton>
					<ul slot="list">
						<li>
							<Button
								onClick={() => {
									setControls({ isCloseModal: true });
								}}
								variant={'secondary'}
								type={'base'}
								size="m"
								wcagLabel={t('akce.uzavrit')}>
								{t('akce.uzavrit')}
							</Button>
						</li>
					</ul>
				</GovDropdown>
			</GovControlGroup>
			<Prompt
				open={controls.isCloseAndSubmitModal}
				label={t('modal.pole.uzavrit-podat')}
				onGov-close={() => setControls({ isCloseAndSubmitModal: false })}>
				<PoMarkdownRenderWeb>
					{t('modal.pole.chcete-uzavrit-podat')}
				</PoMarkdownRenderWeb>
				<div
					slot={'actions'}
					className={'flex justify-end gap-4'}>
					<Button
						onClick={() => handleEPetitionSubmitClose()}
						loading={controls.isSubmitAndCloseProcessing}
						disabled={controls.isSubmitAndCloseProcessing}
						variant="primary"
						type="solid"
						nativeType="button"
						size="s"
						wcagLabel={t('tlacitko.akce.uzavrit-podat')}>
						{t('tlacitko.akce.uzavrit-podat')}
					</Button>
					<Button
						onClick={() => setControls({ isCloseAndSubmitModal: false })}
						variant="error"
						nativeType="button"
						size="s"
						type="outlined"
						wcagLabel={t('tlacitko.akce.neuzavirat')}>
						{t('tlacitko.akce.neuzavirat')}
					</Button>
				</div>
			</Prompt>
			<Prompt
				open={controls.isCloseModal}
				label={t('modal.pole.pouze-uzavrit')}
				onGov-close={() => onCloseModal()}>
				<PoMarkdownRenderWeb>
					{t('modal.pole.chcete-pouze-uzavrit')}
				</PoMarkdownRenderWeb>
				<div
					slot={'actions'}
					className={'flex justify-end gap-4'}>
					<Button
						onClick={() => handleEPetitionClose()}
						loading={controls.isCloseProcessing}
						disabled={controls.isCloseProcessing}
						variant="primary"
						type="solid"
						nativeType="button"
						size="s"
						wcagLabel={t('tlacitko.akce.pouze-uzavrit')}>
						{t('tlacitko.akce.pouze-uzavrit')}
					</Button>
					<Button
						onClick={() => onCloseModal()}
						variant="error"
						nativeType="button"
						size="s"
						type="outlined"
						wcagLabel={t('tlacitko.akce.neuzavirat')}>
						{t('tlacitko.akce.neuzavirat')}
					</Button>
				</div>
			</Prompt>
		</div>
	);
};
