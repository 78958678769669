import {
	GovDropdown,
	GovFormControl,
	GovIcon,
} from '@gov-design-system-ce/react';
import cx from 'classnames';
import { FormErrorMessage } from 'libs/ui/web/src/Form/FormMessage';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxLabel,
	getDataBoxIcon,
	getDataBoxTypeLabel,
	getDataBoxTypeShortcut,
	useDataBoxLabel,
	useDataBoxMessageFormContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, FormLabel } from '@gov-nx/ui/web';

type DropdownItemProps = {
	dataBox: DatovkaApiType;
	selected?: boolean;
};

const DropdownItem = ({ dataBox, selected }: DropdownItemProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	return (
		<span className={'whitespace-nowrap'}>
			<GovIcon
				name={getDataBoxIcon(dataBox.typSchranky)}
				className={'!w-4 !h-4 grow-0 shrink-0'}
			/>{' '}
			<span className={'w-auto'}>
				<strong
					className={cx(
						!selected && 'font-normal',
						'inline-flex overflow-hidden md:max-w-screen-sm xxl:max-w-[211px]',
						dataBox.typSchranky?.match(/^PFO/)
							? 'max-w-[158px] sm:max-w-[278px] ds:max-w-[148px] lg:max-w-[230px] xl:max-w-[146px]'
							: 'max-w-[239px] sm:max-w-[359px] ds:max-w-[229px] lg:max-w-[311px] xl:max-w-[227px]'
					)}>
					<span className={'whitespace-nowrap overflow-hidden text-ellipsis'}>
						{dataBoxLabel(dataBox, true)}
					</span>
				</strong>
				<span
					className={cx('text-secondary-700', selected && '!text-primary-600')}>
					{' '}
					–{' '}
					<span className={'xxl:hidden'}>
						{getDataBoxTypeShortcut(dataBox.typSchranky, tn)}
					</span>
					<span className={'hidden xxl:inline'}>
						{dataBox.typSchranky
							? getDataBoxTypeLabel(dataBox.typSchranky, tn)
							: null}
					</span>
				</span>
			</span>
		</span>
	);
};

export const SenderDropdown = () => {
	const { sender } = useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { availableDataBoxes, isDropDownOpen, databox, onChange, error } =
		sender;
	const { label } = useDataBoxLabel();

	if (availableDataBoxes.length === 0) return null;

	return (
		<GovFormControl
			invalid={!!error}
			className={'ds-new-message'}>
			<FormLabel
				required
				onGov-click={isDropDownOpen.setTrue}>
				{`${tn('nova-zprava.od')}:`}
			</FormLabel>
			{availableDataBoxes.length > 1 ? (
				<div className={'relative w-full'}>
					<GovDropdown
						open={isDropDownOpen.value}
						className={'ds-new-message-dropdown p-0.5'}>
						<Button
							variant={'secondary'}
							type={'outlined'}
							wcagLabel={tn('nova-zprava.od.rozbalit')}>
							<DropdownItem dataBox={databox} />
							<GovIcon
								name={'chevron-down'}
								type={'basic'}
								className={'!text-primary-600'}
							/>
						</Button>
						<ul
							slot={'list'}
							className={'!p-0'}>
							{availableDataBoxes.map((dataBox) => {
								const isSelected =
									dataBox.datovaSchrankaId === databox.datovaSchrankaId;

								return (
									<li
										key={dataBox.datovaSchrankaId}
										className={cx(
											'!whitespace-normal border-secondary-500',
											isSelected &&
												"relative before:absolute before:top-2 before:left-0 before:bottom-2 before:bg-primary-600 before:w-0.5 before:!content-[''] before:h-auto before:rounded-none"
										)}>
										<Button
											variant={isSelected ? 'primary' : 'secondary'}
											type={'base'}
											onClick={() =>
												onChange(dataBox.datovaSchrankaId as string)
											}
											wcagLabel={
												dataBoxLabel(dataBox, true) +
												' – ' +
												(dataBox.typSchranky
													? label(dataBox.typSchranky)
													: null)
											}>
											<DropdownItem
												dataBox={dataBox}
												selected={isSelected}
											/>
										</Button>
									</li>
								);
							})}
						</ul>
					</GovDropdown>
				</div>
			) : (
				<div className="ds-sender-text ml-3">
					<DropdownItem dataBox={databox} />
				</div>
			)}
			{error && (
				<FormErrorMessage
					slot="bottom"
					error={error}
				/>
			)}
		</GovFormControl>
	);
};
