import { MyFilesBadge, MyFilesSource } from '../context.types';

const badges: MyFilesBadge[] = [
	{
		nameCode: 'filtr.zdroj.vse',
		icon: 'collection',
	},
	{
		nameCode: `filtr.zdroj.${MyFilesSource.VSTUP_NEBO_VYSTUP_PODANI}`,
		icon: 'file-earmark-arrow-left',
		source: MyFilesSource.VSTUP_NEBO_VYSTUP_PODANI,
	},
	{
		nameCode: `filtr.zdroj.${MyFilesSource.PRILOHA_DATOVE_ZPRAVY}`,
		icon: 'datovka',
		source: MyFilesSource.PRILOHA_DATOVE_ZPRAVY,
	},
	{
		nameCode: `filtr.zdroj.${MyFilesSource.UZIVATELEM_NAHRANY_DOKUMENT}`,
		icon: 'upload',
		source: MyFilesSource.UZIVATELEM_NAHRANY_DOKUMENT,
	},
	{
		nameCode: 'filtr.zdroj.kos',
		icon: 'trash',
	},
	{
		nameCode: 'filtr.zdroj.bez-souboru',
		icon: 'card-text',
		isFile: false,
	},
];

export const useMyFilesBadges = (selectedBadgeIndex: number) => {
	return {
		badges,
		isFile: badges[selectedBadgeIndex] && badges[selectedBadgeIndex].isFile,
		source: badges[selectedBadgeIndex] && badges[selectedBadgeIndex].source,
		isSelectedRecycleBin: selectedBadgeIndex === badges.length - 2,
		isSelectedWithoutFile: selectedBadgeIndex === badges.length - 1,
	};
};
