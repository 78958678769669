import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';

export enum DataBoxFolderType {
	Received = 'dorucene',
	Sent = 'odeslane',
	Archived = 'archiv',
	Trash = 'kos',
}

export enum DataBoxTimeDivisionType {
	Today = 'today',
	ThisWeek = 'this-week',
	LastWeek = 'last-week',
	ThisMonth = 'this-month',
	LastMonth = 'last-month',
	ThisYear = 'this-year',
	LastYear = 'last-year',
	Older = 'older',
}

export type DataBoxTimeDividedMessageList = {
	label: DataBoxTimeDivisionType;
	messages: DatoveSchrankyDatovaZpravaDto[];
}[];

export enum DataBoxConfirmModalType {
	removeMessages = 'remove-messages',
	removeMessagesPermanently = 'remove-messages-permanently',
	emptyTrash = 'empty-trash',
	removeMessage = 'remove-message',
}
