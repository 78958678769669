import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	sendLoggedSupportQuery,
	sendNotLoggedSupportQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	WizardFormStep,
	usePoSupportDataLoad,
	useWizard,
} from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import {
	FormPersonInstance,
	FormProblemInstance,
	prepareSubmitData,
} from './FormDefinitions';
import { FormData, FormPersonData, SupportContextTypes } from './context.types';
import { useZpusobPrihlaseniOptions } from './options';

const PodporaContext = createContext<Nullable<SupportContextTypes>>(null);

interface PodporaContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function PodporaContextProvider({
	children,
	code,
}: PodporaContextProviderProps) {
	const navigate = useNavigate();
	const { t } = useLocale(code);
	const { toastMessageSuccess } = useMessageEvents();
	const isSubmitted = useBoolean();
	const { isLoggedIn } = useAuthStore();
	const isLogged = isLoggedIn();

	const { individualPerson, communication, ...supportData } =
		usePoSupportDataLoad({
			isEnabled: isLogged,
		});

	const submitMutation = usePoMutation({
		mutationFn: async (formData: FormData) => {
			const prepared = prepareSubmitData(formData);
			if (!isLogged) {
				return sendNotLoggedSupportQuery(prepared);
			}
			return sendLoggedSupportQuery(prepared);
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.odeslana'));
			wizard.resetForms();
			isSubmitted.setTrue();
			navigate(-1);
		},
	});

	const wizard = useWizard<FormData>({
		steps: [
			FormPersonInstance({ code, communication, individualPerson }),
			FormProblemInstance({ code, isLogged }),
			{},
		],
		onSubmit: async (values) => submitMutation.mutate(values),
	});

	const personForm = wizard.step(
		0
	) as unknown as WizardFormStep<FormPersonData>;

	useEffect(() => {
		if (individualPerson && communication) {
			if (individualPerson.jmeno) {
				personForm.formDefinition.formMethods.setValue(
					'jmeno',
					individualPerson.jmeno
				);
			}
			if (individualPerson.prijmeni) {
				personForm.formDefinition.formMethods.setValue(
					'prijmeni',
					individualPerson.prijmeni
				);
			}
		}
	}, [individualPerson, communication, wizard.resetForms]);

	const { options } = useZpusobPrihlaseniOptions();

	return (
		<PodporaContext.Provider
			value={{
				code,
				controls: {
					initialLoading: isLogged ? supportData.isLoading : false,
					initialError: supportData.error,

					processLoading: submitMutation.isLoading,
					processError: submitMutation.error,
				},
				individualPerson,
				isLogged,
				wizard,
				isSubmitted: isSubmitted.isTrue,

				zpusobPrihlaseniOptions: options,
			}}>
			{children}
		</PodporaContext.Provider>
	);
}

export const usePodporaContext = (): SupportContextTypes =>
	useContext(PodporaContext) as SupportContextTypes;
