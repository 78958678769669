import { GovCard, GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, LayoutGap } from '@gov-nx/ui/web';
import { MarkdownRender } from '../../Markdown/MarkdownRender';

export interface UserManualViewProps {
	code: PageCode;
}

export const UserManualView = ({ code }: UserManualViewProps) => {
	const { handbookDesktopUrl, handbookMobileUrl } = useConfiguration();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<LayoutGap gap={8}>
			<GovCard promotion={true}>
				<GovIcon
					slot="icon"
					type="complex"
					name="portal"
				/>
				<div>
					<h2 className={'mb-2 text-2xl'}>
						{ts('uzivatelska-prirucka.pocitac.nadpis')}
					</h2>
					<p>{ts('uzivatelska-prirucka.pocitac.popis')}</p>
				</div>
				{handbookDesktopUrl ? (
					<Button
						enforceLink={true}
						target={'_blank'}
						href={handbookDesktopUrl}
						variant="primary"
						type="outlined"
						size="s"
						slot="btn"
						wcagLabel={ts('uzivatelska-prirucka.tlacitko.wcag.pocitac')}>
						{ts('uzivatelska-prirucka.tlacitko.text')}
					</Button>
				) : null}
			</GovCard>

			<GovCard promotion={true}>
				<GovIcon
					slot="icon"
					type="complex"
					name="mobile-phone"
				/>
				<div>
					<h2 className={'mb-2 text-2xl'}>
						{ts('uzivatelska-prirucka.mobil.nadpis')}
					</h2>
					<MarkdownRender content={ts('uzivatelska-prirucka.mobil.popis')} />
				</div>
				{handbookMobileUrl ? (
					<Button
						enforceLink={true}
						target={'_blank'}
						href={handbookMobileUrl}
						variant="primary"
						type="outlined"
						size="s"
						slot="btn"
						wcagLabel={ts('uzivatelska-prirucka.tlacitko.wcag.mobil')}>
						{ts('uzivatelska-prirucka.tlacitko.text')}
					</Button>
				) : null}
			</GovCard>
		</LayoutGap>
	);
};
