import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	cmsSignpostGroupsQuery,
	cmsSignpostQuery,
	FavoriteCode,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useAppLanguage, useProcessControl } from '@gov-nx/core/hooks';
import { Nullable, Optional } from '@gov-nx/core/types';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { debounce, useFilter } from '@gov-nx/utils/common';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { SignpostContext, SignpostListFilter } from './context.types';

const RozcestikContext = createContext<Nullable<SignpostContext>>(null);

export function RozcestnikContextProvider({ children }: PropsWithChildren) {
	const { poUserFavorites } = useUserStore();
	const favorites = poUserFavorites();
	const { application: app, environment: env } = useConfigurationStore();
	const { language } = useAppLanguage();
	const { setControls, controls } = useProcessControl({
		processLoading: true,
	});

	const [search, setSearch] = useState<Optional<string>>(undefined);
	const { filter, setPartialFilter } = useFilter<SignpostListFilter>({
		key: 'rozcestnik',
		initialState: {
			language: language,
			serviceGroups: null,
			favourites: false,
			page: 1,
			perPage: 10,
		},
	});

	const { data } = usePoQuery({
		queryKey: ['signpost', filter, search],
		queryFn:
			app && env
				? () => {
						const params = prepareSubmitData(filter, search, app, env);
						return cmsSignpostQuery(params);
				  }
				: undefined,
		onSettled: () => setControls({ processLoading: false }),
	});

	const signpostsData = data ?? [];

	const signposts = (
		search?.length
			? signpostsData
			: signpostsData.sort((a, b) => a.order - b.order)
	)
		.filter((signpost) => {
			if (filter.favourites) {
				return (
					favorites.includes(signpost.pageCode as FavoriteCode) ||
					favorites.includes(signpost.serviceCode as FavoriteCode) ||
					favorites.includes(signpost.externalServiceCode as FavoriteCode)
				);
			}
			return true;
		})
		.slice((filter.page - 1) * filter.perPage, filter.page * filter.perPage);

	const numberOfSignposts = data?.length ?? 0;

	const signpostGroupQuery = usePoQuery({
		queryKey: ['signpost-group', language],
		queryFn: () => cmsSignpostGroupsQuery({ locale: language }),
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const groups = (signpostGroupQuery.data ?? []).sort((a, b) => {
		return a.order - b.order;
	});

	const resetFilter = () => {
		setPartialFilter({ serviceGroups: null, favourites: false });
		setSearch(undefined);
		formDefinition.formReset();
	};

	const goToPage = (page: number) => {
		setPartialFilter({ page: page });
	};

	const formDefinition = FormInstance({ search });
	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		//setControls({ processLoading: true });
		//setPartialFilter(formDefinition.formMethods.getValues());
	});

	const setFilterActiveGroup = (groupId: number) => {
		setPartialFilter({ serviceGroups: groupId, favourites: false, page: 1 });
		setSearch(undefined);
		formDefinition.formReset();
	};
	const setFilterFavourites = () => {
		setPartialFilter({ serviceGroups: null, favourites: true, page: 1 });
		setSearch(undefined);
		formDefinition.formReset();
	};
	const setFilterAll = () => {
		setPartialFilter({ serviceGroups: null, favourites: false, page: 1 });
		setSearch(undefined);
		formDefinition.formReset();
	};

	useEffect(() => {
		if (language !== filter.language) {
			setPartialFilter({ serviceGroups: null, language });
		}
	}, [language]);

	const searchValue = formDefinition.formMethods.watch('search');

	useEffect(() => {
		if (searchValue && searchValue.length > 2) {
			setPartialFilter({ serviceGroups: null, favourites: false });
			debounce(() => setSearch(searchValue), 200)();
			goToPage(1);
		} else {
			setSearch(undefined);
		}
	}, [searchValue]);

	useEffect(() => {
		if (filter.favourites && signposts.length === 0) {
			goToPage(1);
		}
	}, [signposts.length, filter.favourites]);

	return (
		<RozcestikContext.Provider
			value={{
				setFilterActiveGroup,
				setFilterFavourites,
				setFilterAll,
				groups,
				setPartialFilter,
				numberOfSignposts: filter.favourites
					? favorites.length
					: numberOfSignposts,
				filter,
				controls,
				data: signposts,
				formDefinition,
				onSubmit,
				setControls,
				resetFilter,
				goToPage,
				favorites,
			}}>
			{children}
		</RozcestikContext.Provider>
	);
}

export const useRozcestnikContext = (): SignpostContext =>
	useContext(RozcestikContext) as SignpostContext;
