import PubSub from 'pubsub-js';

export const pubSubActionBridge = <
	Actions extends { type: string; payload?: unknown }
>() => {
	return {
		clear: <T extends Actions['type']>(actionType: T) =>
			PubSub.clearAllSubscriptions(actionType),
		publish: (payload: Actions) =>
			PubSub.publish(payload.type, payload.payload),
		subscribe: <
			T extends Actions['type'],
			A extends Extract<Actions, { type: T }>
		>(
			actionType: T,
			callback: (action: A['type'], payload: A['payload']) => void
		) => {
			const subscription = PubSub.subscribe(
				actionType,
				callback as (message: string, data?: T) => void
			);
			return {
				unSubscribe: () => PubSub.unsubscribe(subscription),
			};
		},
	};
};
