import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const EPetitionDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<>
			<ContentLayout>
				<LayoutGap>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'26px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 100) + '%'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<div className={'flex justify-between'}>
						<GovSkeleton
							width={'140px'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'mb-0 pb-0'}
						/>
						<GovSkeleton
							width={'22px'}
							height={'22px'}
							variant={'secondary'}
							shape={'circle'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'mb-0 pb-0'}
						/>
					</div>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<div
					className={
						'flex flex-col [ md:flex-row md:justify-between md:items-center ]'
					}>
					<GovSkeleton
						width={'300px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={'85px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
				</div>
			</ContentLayout>
		</>
	);
};
