import { GovMessage, GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	ProfilROSReklamaceContextProvider,
	useProfilROSContextInstance,
} from '@gov-nx/module/page';
import { Button, DataTable } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { ProfileROSComplaintView } from './ProfileROSComplaintView';
import { SettingsLink } from './SettingsLink';

interface TableTypes {
	code: PageCode;
}

export const ProfileROSBusinessPersonView = ({ code }: TableTypes) => {
	const { mergedData, controls, setControls } = useProfilROSContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (!Array.isArray(mergedData) || mergedData.length === 0) {
		return (
			<GovMessage variant={'primary'}>{ts('oznameni.zadne-udaje')}</GovMessage>
		);
	}

	return (
		<>
			{mergedData.map((data) => (
				<React.Fragment key={data.ico}>
					<h3 className={'text-xl'}>{data.nazevSpolecnosti}</h3>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{ts('zaznam.ico')}</td>
								<td>
									<strong>{data?.ico ?? '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.pravni-forma')}</td>
								<td>
									<strong>{`${data?.pravniForma?.nazev}` ?? '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.sidlo')}</td>
								<td>
									<strong>{data?.sidloSpolecnosti?.adresaText ?? '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.datum-opravneni')}</td>
								<td>
									<strong>
										{data?.datumVzniku
											? toDateReadable(data?.datumVzniku)
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.datum-zaniku')}</td>
								<td>
									<strong>
										{data.datumZaniku
											? ts('zaznam.datum-zaniku-ano')
											: ts('zaznam.datum-zaniku-ne')}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.pozastaveni-preruseni')}</td>
								<td>
									<strong>
										{data?.pozastaveniPreruseni
											? ts('zaznam.pozastaveni-preruseni-ano')
											: ts('zaznam.pozastaveni-preruseni-ne')}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.datova-schranka')}</td>
								<td>
									<strong>{data?.datovaSchrankaId?.split(' ')[0]}</strong>
								</td>
							</tr>
						</tbody>
					</DataTable>

					<Button
						onClick={() => setControls({ complaintModal: data })}
						nativeType="button"
						variant="primary"
						size="l"
						type="outlined"
						wcagLabel={ts('akce.podnet-k-oprave-udaju')}>
						{ts('akce.podnet-k-oprave-udaju')}
					</Button>

					<h3 className={'text-xl'}>{ts('zaznam.kontaktni-udaje')}</h3>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{ts('zaznam.email')}</td>
								<td>
									<strong>
										{data.komunikacniUdaje?.email ?? ts('zaznam.neuvedeno')}
									</strong>
									<SettingsLink />
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.telefon')}</td>
								<td>
									<strong>
										{data.komunikacniUdaje?.telefonniCislo ??
											ts('zaznam.neuvedeno')}
									</strong>
									<SettingsLink />
								</td>
							</tr>
						</tbody>
					</DataTable>
				</React.Fragment>
			))}
			<GovModal
				open={!!controls.complaintModal}
				label={ts('formular.podnet-k-oprave-udaju')}
				onGov-close={() => setControls({ complaintModal: null })}
				wcagCloseLabel={ts('akce.wcag.zavrit-formular')}>
				<div>
					{controls.complaintModal ? (
						<ProfilROSReklamaceContextProvider
							code={code}
							data={controls.complaintModal}
							onComplaintSubmit={() => setControls({ complaintModal: null })}>
							<ProfileROSComplaintView code={code} />
						</ProfilROSReklamaceContextProvider>
					) : null}
				</div>
			</GovModal>
		</>
	);
};
