import { routeResolver } from '@gov-nx/web';
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ePetitionSetupQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import {
	useApplicantLoader,
	useProcessControl,
	useWizard,
} from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { PageContextControls } from '../../types/page.types';
import { FormInstanceStep1 } from '../FormDefinitions';
import { EPeticeFormDataStep1 } from '../wizzard.types';
import { EPetitionNewContext } from './context.types';

const EPeticeNovaContext = createContext<Nullable<EPetitionNewContext>>(null);

interface EPeticeContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function EPeticeNovaContextProvider({
	children,
	code,
}: EPeticeContextProviderProps) {
	const { controls, setControls } = useProcessControl<PageContextControls>();
	const loginInfo = useIdentity();

	const navigate = useNavigate();
	const setupQuery = usePoMutation({
		mutationFn: ePetitionSetupQuery,
	});

	const formStep1 = FormInstanceStep1({ code });
	const wizard = useWizard<EPeticeFormDataStep1>({
		steps: [formStep1, {}, {}, {}],
		onSubmit: async (_, onSuccess) => undefined,
		onStepSubmitted: async () =>
			new Promise((resolve) => {
				const values = formStep1.formMethods.getValues();
				return setupQuery.mutate(values, {
					onSuccess: (petitionId) => {
						navigate(
							routeResolver(PageCode['epetice-detail-editace'], { petitionId })
						);
						return resolve({ canContinue: true });
					},
				});
			}),
	});

	const applicant = useApplicantLoader({
		onError: (initialError) => {
			setControls({ initialLoading: false, initialError });
		},
		onLoaded: ({ individualPerson, businessPerson }) => {
			if (businessPerson)
				if (individualPerson && businessPerson.seznam?.length) {
					formStep1.formMethods.setValue('zakladatelTyp', 'FO');
				} else if (individualPerson) {
					formStep1.formMethods.setValue('zakladatelTyp', 'FO');
				} else {
					formStep1.formMethods.setValue('zakladatelTyp', 'PO');
				}

			setControls({ initialLoading: false });
		},
	});

	return (
		<EPeticeNovaContext.Provider
			value={{
				code,
				isDisabled: setupQuery.isLoading,
				isLoading: setupQuery.isLoading,
				formDefinition: formStep1,
				applicant,
				setControls,
				controls,
				wizard,
				loginInfo,
			}}>
			{children}
		</EPeticeNovaContext.Provider>
	);
}

export const useEPeticeNovaContext = (): EPetitionNewContext =>
	useContext(EPeticeNovaContext) as EPetitionNewContext;
