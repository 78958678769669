import { AxiosError } from 'axios';
import { GovError } from '@gov-nx/core/app';
import { hasOwnProperty, Nullable, Optional } from '@gov-nx/core/types';
import {
	POError,
	POResponseError,
	ResponseError,
	ResultsLimitExceededPOError,
} from './errors.types';

export const isPoResponseError = (
	error: AxiosError<ResponseError>
): error is AxiosError<POResponseError> =>
	(error.response?.data && hasOwnProperty(error.response.data, 'chyby')) ??
	false;

export const getPoErrors = (
	error: AxiosError<POResponseError>
): Optional<POError[]> => error.response?.data?.chyby;

export const isResponseStatus =
	(status: number) =>
	(error: AxiosError<ResponseError>): boolean =>
		error.response?.status === status;

const errorIgnoreFilter =
	(
		filterFunction: (error: AxiosError<ResponseError>) => boolean,
		onIgnoredError?: (error: AxiosError<ResponseError>) => void
	) =>
	(error: AxiosError<ResponseError>): boolean => {
		const isValid = filterFunction(error);
		if (onIgnoredError && isValid) {
			onIgnoredError(error);
		}
		return isValid;
	};

export const filterErrorStatus = (
	status: number,
	onIgnoredError?: (error: AxiosError<ResponseError>) => void
) => errorIgnoreFilter(isResponseStatus(status), onIgnoredError);

export const shouldIgnoreError =
	(condition: (error: AxiosError<ResponseError>) => boolean) =>
	(error: AxiosError<ResponseError>): boolean =>
		condition(error);

export const isUnauthorizedResponse = (
	error: AxiosError<ResponseError>
): boolean => {
	return error.response ? [401, 403].includes(error.response.status) : false;
};

const isExceededError = (
	error: POError
): error is ResultsLimitExceededPOError =>
	error.extKod === 'PREKROCEN_LIMIT_VYSLEDKU';
export const isResultsLimitExceededError = (
	error: AxiosError<ResponseError>
): Optional<ResultsLimitExceededPOError> => {
	return isPoResponseError(error)
		? getPoErrors(error)?.find(isExceededError)
		: undefined;
};

export const getError = <T>(
	error: AxiosError<ResponseError, T> | null,
	options?: { ignoreStatus?: number }
): Nullable<GovError> => {
	if (!error) return null;

	if (options?.ignoreStatus && isResponseStatus(options.ignoreStatus)(error)) {
		return null;
	}

	return error ? new GovError(error.message) : null;
};
