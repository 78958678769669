import { ScrollToTopHelper, UrlErrorListener } from '@gov-nx/web';
import {
	ReactPlugin,
	withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { QueryClientProvider } from '@tanstack/react-query';
import { createBrowserHistory } from 'history';
import { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { setupCmsAxiosInterceptors } from '@gov-nx/api/common';
import { setupPoAxiosInterceptors } from '@gov-nx/api/portal-obcana';
import { AuthProvider } from '@gov-nx/auth/web';
import { ErrorBoundary, ErrorPage } from '@gov-nx/component/web';
import {
	AppContextProvider,
	getQueryClient,
	InitProvider,
	SyncProvider,
	useAppContext,
} from '@gov-nx/core/app';
import { preparePageStructure } from '@gov-nx/module/page';
import { appLoaderControl } from './helpers/loader';
import { MatomoInstanceProvider } from './providers/MatomoInstanceProvider';

preparePageStructure();

const AppRoutes = lazy(() =>
	import('./routes/AppRoutes').then(({ AppRoutes }) => ({ default: AppRoutes }))
);
const AppListeners = lazy(() =>
	import('./components/AppListeners').then(({ AppListeners }) => ({
		default: AppListeners,
	}))
);

const isLocalhost = window.location.hostname.includes('localhost');
const environment = 'prod';
const finalEnvironment = environment.includes('NX')
	? isLocalhost
		? 'lcl'
		: 'prod'
	: environment;
const application = 'po';

const queryClient = getQueryClient();

const instrumentationKey = '54cf3e26-c29a-4377-bd06-0da531596f68';
const finalInstrumentationKey = instrumentationKey.includes('NX');

const reactPlugin = new ReactPlugin();
if (!finalInstrumentationKey) {
	const browserHistory = createBrowserHistory();
	const appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: instrumentationKey,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: browserHistory },
			},
		},
	});
	appInsights.loadAppInsights();
}

export const App = () => {
	const { appError } = useAppContext();

	if (appError && isLocalhost) {
		console.error(
			'APP BREAKING ERROR! \nThis would block the user from doing anything if we were not on localhost!\n',
			appError
		);
	} else if (appError) {
		return (
			<Suspense>
				<ErrorPage message={appError.message} />
			</Suspense>
		);
	}

	return (
		<ErrorBoundary>
			<Suspense>
				<InitProvider
					application={application}
					environment={finalEnvironment}>
					<BrowserRouter>
						<MatomoInstanceProvider>
							<AuthProvider>
								<SyncProvider>
									<Suspense>
										<AppRoutes />
									</Suspense>
								</SyncProvider>
							</AuthProvider>
							<ScrollToTopHelper />
							<AppListeners />
						</MatomoInstanceProvider>
					</BrowserRouter>
					<UrlErrorListener />
				</InitProvider>
			</Suspense>
		</ErrorBoundary>
	);
};

export const AppWrapper = () => {
	return (
		<AppContextProvider loaderControl={appLoaderControl}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</AppContextProvider>
	);
};

setupPoAxiosInterceptors();
setupCmsAxiosInterceptors();

export default withAITracking(reactPlugin, AppWrapper);
