import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { EPetitionCloseSubmitButtons } from './EPetitionCloseSubmitButtons';
import { EPetitionClosedStatusBanner } from './EPetitionClosedStatusBanner';
import { EPetitionSubmitedStatusBanner } from './EPetitionSubmitedStatusBanner';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionCloseAndSubmit = ({ code }: EPetitionDetailViewProps) => {
	const { ePetitionSubmitClose } = useEPeticeDetailContext();

	if (ePetitionSubmitClose.canSubmitOrClose) {
		return <EPetitionCloseSubmitButtons code={code} />;
	}

	if (ePetitionSubmitClose.isClosed && ePetitionSubmitClose.isFounder) {
		return <EPetitionClosedStatusBanner code={code} />;
	}

	if (ePetitionSubmitClose.isSubmited && ePetitionSubmitClose.isFounder) {
		return <EPetitionSubmitedStatusBanner code={code} />;
	}
};
