import { GovIcon } from '@gov-design-system-ce/react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useKatastrNemovitostiContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	FormInput,
	FormWidthElementLayout,
	HeaderLayout,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../Form/PoForm';

export interface CadastreOfRealEstateFilterProps {
	code: PageCode;
}

export const CadastreOfRealEstateFilter = ({
	code,
}: CadastreOfRealEstateFilterProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { formDefinition, onSubmit } = useKatastrNemovitostiContextInstance();

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<HeaderLayout>
					<FormWidthElementLayout
						type="l"
						className={'relative'}>
						<FormInput
							field={{
								name: 'text',
								placeholder: ts('filtr.placeholder.vyhledat-nemovitost'),
								wcagLabelledBy: 'vyhledat-nemovitost',
							}}
							label={{
								children: '',
							}}
							group={{ gap: 's' }}
						/>
						<label
							id="vyhledat-nemovitost"
							className={'hidden'}>
							{ts('filtr.pole.wcag.vyhledat-nemovitost')}
						</label>
						<div className={'absolute top-1 right-1'}>
							<Button
								nativeType={'submit'}
								variant="primary"
								type="solid"
								size="s"
								wcagLabel={ts('akce.wcag.vyhledat')}>
								<GovIcon
									name={'search'}
									type={'basic'}
									slot="left-icon"></GovIcon>
							</Button>
						</div>
					</FormWidthElementLayout>
				</HeaderLayout>
			</PoFormWeb>
		</FormProvider>
	);
};
