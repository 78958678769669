import React, { createContext, useContext, useState } from 'react';
import { Nullable } from '@gov-nx/core/types';
import { useBoolean } from '@gov-nx/utils/common';
import { useAttachmentOperations } from '../hooks/useAttachmentOperations';
import { DataBoxFolderType } from './DataBoxes.types';
import {
	DataBoxMessageAttachmentContextType,
	DataBoxMessageAttachmentLoadingItemsType,
} from './MessageAttachmentContext.types';

export const DataBoxMessageAttachmentContext =
	createContext<Nullable<DataBoxMessageAttachmentContextType>>(null);

interface DataBoxMessageAttachmentProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	children: React.ReactNode;
}

export function DataBoxMessageAttachmentProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
}: DataBoxMessageAttachmentProviderProps) {
	const [loadingItems, setLoadingItems] = useState<
		Partial<Record<DataBoxMessageAttachmentLoadingItemsType, boolean>>
	>({});

	const updateLoadingItems = (
		loadingItem: DataBoxMessageAttachmentLoadingItemsType,
		add: boolean
	) => {
		if (add) {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: true,
			}));
		} else {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: false,
			}));
		}
	};

	const isDropdownOpen = useBoolean(false);

	const attachmentOperations = useAttachmentOperations({
		dataBoxId,
		folderType,
		messageId,
		updateLoadingItems,
		closeDropdown: () => isDropdownOpen.setFalse(),
	});

	return (
		<DataBoxMessageAttachmentContext.Provider
			value={{
				isDropdownOpen,
				attachmentOperations,
				loadingItems,
			}}>
			{children}
		</DataBoxMessageAttachmentContext.Provider>
	);
}

export const useDataBoxMessageAttachmentContext =
	(): DataBoxMessageAttachmentContextType =>
		useContext(
			DataBoxMessageAttachmentContext
		) as DataBoxMessageAttachmentContextType;
