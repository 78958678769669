import React, { createContext, useContext } from 'react';
import {
	fetchDataBoxesReceivedMessagesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { isFulfilled, Nullable, Time } from '@gov-nx/core/types';
import {
	useDataBoxUnreadMessages,
	useDataboxDateUtils,
} from '@gov-nx/module/data-box';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { isUnreadMessage } from '@gov-nx/utils/common';
import { DashboardContextType } from './context.types';

const maxUnreadMessages = 3;

const DashboardContext = createContext<Nullable<DashboardContextType>>(null);

interface DashboardContextProviderProps {
	children: React.ReactNode;
}

export function DashboardContextProvider({
	children,
}: DashboardContextProviderProps) {
	const { getConnectedDataBoxesList } = useDataBoxStore();
	const connectedDataBoxes = getConnectedDataBoxesList();

	const showUnreadMessagesWidget = connectedDataBoxes.length > 0;
	const showDataBoxBanner = connectedDataBoxes.length === 0;

	const {
		unreadMessagesCount,
		hasUnreadMessages,
		isLoading: isUnreadMessagesLoading,
		unreadMessagesDataboxIds,
	} = useDataBoxUnreadMessages();

	const databoxUnreadMessagesQuery = usePoQuery({
		queryKey: ['data-box-unread-messages-list', unreadMessagesDataboxIds],
		queryFn: async () =>
			fetchDataBoxesReceivedMessagesQuery({
				dataBoxesIds: unreadMessagesDataboxIds,
			}),
		retry: 0,
		refetchOnWindowFocus: false,
		refetchInterval: Time['30 seconds'],
	});

	const unreadMessages =
		databoxUnreadMessagesQuery.data
			?.filter(isFulfilled)
			?.map((response) => response.value)
			?.map((message) => message.data.seznam)
			?.flat()
			?.filter((message) => message && isUnreadMessage(message))
			.sort((a, b) =>
				(a?.datumACasDodani as string) < (b?.datumACasDodani as string)
					? 1
					: (a?.datumACasDodani as string) > (b?.datumACasDodani as string)
					? -1
					: 0
			)
			.slice(0, maxUnreadMessages)
			.map((message) => {
				return {
					...message,
					typSchranky: connectedDataBoxes.find(
						(dataBox) => dataBox.datovaSchrankaId === message?.prijemceId
					)?.typSchranky,
				};
			}) ?? [];

	const dataBoxDateUtils = useDataboxDateUtils();

	return (
		<DashboardContext.Provider
			value={{
				unreadMessages,
				showUnreadMessagesWidget,
				showDataBoxBanner,
				unreadMessagesCount,
				hasUnreadMessages,
				dataBoxDateUtils,
				isLoading:
					databoxUnreadMessagesQuery.isLoading || isUnreadMessagesLoading,
			}}>
			{children}
		</DashboardContext.Provider>
	);
}

export const useDashboardContextInstance = (): DashboardContextType =>
	useContext(DashboardContext) as DashboardContextType;
