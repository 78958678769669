import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxParams,
	useDataBoxShape,
	useIcNumberShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();
	const { getIcNumberShape } = useIcNumberShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			ico: getIcNumberShape({
				isRequired: true,
			}),
			druhVypisu: yup
				.string()
				.oneOf(['UPLNY', 'PLATNE'])
				.required(tsn('form.validations.zadejte-druh-vypisu')),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			ico: undefined,
			druhVypisu: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData) {
	return {
		params: dataBoxParams(formData),
		body: {
			typVypisu: 'VEREJNY_REJSTRIK',
			data: {
				ico: formData.ico,
				druhVypisu: formData.druhVypisu,
			},
		},
	};
}
