import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useRegistrSVSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormCheckboxList,
	FormDatePicker,
	FormRadio,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';

export const FilterModal = () => {
	const {
		code,
		controls,
		isFilterWindowOpen,
		closeFilterModal,
		onReset,
		hasPFODataBox,
		typOsoby,
		dataBoxesPFO,
	} = useRegistrSVSeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();

	const tsn = getLocalizeCurried(code);

	return (
		<Modal
			label={tsn('formular.akce.filtrovat')}
			open={isFilterWindowOpen}
			onGov-close={closeFilterModal}>
			<div>
				<LayoutGap>
					<FormCheckboxList
						label={{
							children: tsn('formular.label.status'),
							legend: true,
						}}
						control={{ fieldset: true }}
						group={{ gap: 's', orientation: 'horizontal' }}
						fields={[
							{
								name: 'filterStatus.provozovane',
								label: {
									children: tsn('formular.pole.filter-status-provozovane'),
								},
							},
							{
								name: 'filterStatus.zanik',
								label: { children: tsn('formular.pole.filter-status-zanik') },
							},
							{
								name: 'filterStatus.vse',
								label: { children: tsn('formular.pole.filter-status-vse') },
							},
						]}
					/>

					<fieldset>
						<legend>{tsn('formular.label.obdobi')}</legend>

						<LayoutGap className="mb-4 [ md:flex-row ]">
							<FormWidthElementLayout>
								<FormDatePicker
									field={{
										name: 'datumOd',
									}}
									label={{ children: tsn('formular.label.obdobi-od') }}
								/>
							</FormWidthElementLayout>
							<FormWidthElementLayout>
								<FormDatePicker
									field={{
										name: 'datumDo',
									}}
									label={{ children: tsn('formular.label.obdobi-do') }}
								/>
							</FormWidthElementLayout>
						</LayoutGap>
					</fieldset>

					<FormCheckboxList
						label={{
							children: tsn('formular.label.vztah-k-vozidlu'),
							legend: true,
						}}
						control={{ fieldset: true }}
						group={{ gap: 's', orientation: 'horizontal' }}
						fields={[
							{
								name: 'filterVztah.vlastnik',
								label: { children: tsn('formular.pole.filtr-vztah-vlastnik') },
							},
							{
								name: 'filterVztah.provozovatel',
								label: {
									children: tsn('formular.pole.filtr-vztah-provozovatel'),
								},
							},
							{
								name: 'filterVztah.vse',
								label: { children: tsn('formular.pole.filtr-vztah-vse') },
							},
						]}
					/>

					{hasPFODataBox && (
						<FormRadio
							field={{
								name: 'typOsoby',
								options: [
									{
										value: 'FO',
										label: {
											children: tsn('formular.pole.typ-osoby-fo'),
										},
									},
									{
										value: 'PFO',
										label: {
											children: tsn('formular.pole.typ-osoby-pfo'),
										},
										children:
											typOsoby === 'PFO' && dataBoxesPFO.length > 1 ? (
												<div>
													<FormSelect
														field={{
															name: 'ico',
															options: dataBoxesPFO.map((box) => {
																return {
																	value: `${box.ico}`,
																	label: `${box.ico}`,
																};
															}),
														}}
														label={{ children: tsn('formular.pole.ico') }}
													/>
												</div>
											) : undefined,
									},
								],
							}}
							label={{ children: tsn('formular.label.typOsoby') }}
						/>
					)}

					<ButtonGroupLayout>
						<Button
							loading={controls.processLoading}
							wcagLabel={tsn('formular.akce.filtrovat')}
							name="submit"
							nativeType="submit"
							variant="primary"
							size="l"
							type="solid">
							{tsn('formular.akce.filtrovat')}
						</Button>

						<Button
							onClick={onReset}
							variant="error"
							type="outlined"
							wcagLabel={tsn('formular.akce.zrusit')}
							size="l">
							{tsn('formular.akce.zrusit-filtr')}
						</Button>
					</ButtonGroupLayout>
				</LayoutGap>
			</div>
		</Modal>
	);
};
