import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useNavigate } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DATABOX_FOLDER_LABELS,
	DataBoxConfirmModalType,
	DataBoxFolderType,
	isDataBoxTypePO,
	useDataBoxMessageDetailContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageConfirmModal } from './DataBoxMessageConfirmModal';
import { DataBoxMessageDetailPageSkeleton } from './DataBoxMessageDetailPageSkeleton';
import { DataBoxMessageOverview } from './DataBoxMessageOverview';

export const DataBoxMessageDetailPage = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		dataBox,
		dataBoxId,
		folderType,
		messageId,
		isLoading,
		loadingItems,
		message,
		messageOperations: {
			handleArchiveMessage,
			handleRestoreMessage,
			handleDownloadMessage,
			isArchivingEnabled,
		},
		setConfirmModal,
		disableNewMessageButton,
	} = useDataBoxMessageDetailContext();

	const navigate = useNavigate();

	const redirect = () => {
		navigate(
			routeResolver(PageCode['datove-schranky-seznam'], {
				dataBoxId,
				folderType,
			})
		);
	};

	if (isLoading) {
		return <DataBoxMessageDetailPageSkeleton />;
	}

	if (!message) {
		return (
			<section className="flex flex-col h-full bg-neutral-white p-4 [ md:grow md:rounded-2xl ]">
				<Button
					href={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId,
						folderType,
					})}
					variant="primary"
					type="base"
					className="[ ds:hidden ]"
					wcagLabel={tn(DATABOX_FOLDER_LABELS[folderType])}>
					<GovIcon
						name="chevron-left"
						slot="left-icon"
					/>
					{tn(DATABOX_FOLDER_LABELS[folderType])}
				</Button>

				<p>{tn('zpravy.zpravu-nepodarilo-nacist')}</p>
			</section>
		);
	}

	return (
		<section className="flex flex-col justify-between h-full mt-auto bg-neutral-white [ md:grow md:rounded-2xl md:overflow-hidden ]">
			<div className={'flex flex-col justify-between w-full h-full'}>
				<DataBoxMessageOverview message={message} />
				<footer
					className={
						'ds-message-footer flex justify-between items-center gap-4 mt-auto p-4 border-t border-secondary-300 bg-neutral-white [ md:p-6 ] [ xl:rounded-b-2xl ]'
					}>
					{!isDataBoxTypePO(dataBox) &&
					[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(
						folderType
					) ? (
						message.datumArchivace ? (
							<GovTooltip
								position="top"
								size="s"
								icon
								className={'!hidden [ xl:!block ]'}>
								<Button
									variant="primary"
									type="outlined"
									size="s"
									disabled
									wcagLabel={tn('zpravy.zkopirovat-do-archivu')}>
									<GovIcon
										slot="left-icon"
										name="archive-outline"
									/>
									<span className={'!hidden [ xl:!inline-block ]'}>
										{tn('zpravy.zkopirovat-do-archivu')}
									</span>
								</Button>
								<GovTooltipContent>
									<p>{tn('zpravy.zprava-jiz-zkopirovana')}</p>
								</GovTooltipContent>
							</GovTooltip>
						) : (
							<Button
								variant="primary"
								type="outlined"
								size="s"
								loading={loadingItems.archive}
								onClick={handleArchiveMessage}
								disabled={!!loadingItems.archive}
								wcagLabel={tn('zpravy.zkopirovat-do-archivu')}>
								<span className="inline-flex items-center gap-x-3">
									{!loadingItems.archive && (
										<GovIcon
											slot="left-icon"
											name="archive-outline"
										/>
									)}
									<span className={'!hidden [ xl:!inline-block ]'}>
										{tn('zpravy.zkopirovat-do-archivu')}
									</span>
								</span>
							</Button>
						)
					) : null}
					{folderType === DataBoxFolderType.Archived &&
						message.datumArchivace && (
							<Button
								variant="error"
								type="outlined"
								size="s"
								disabled={isArchivingEnabled}
								onClick={() =>
									setConfirmModal(DataBoxConfirmModalType.removeMessage)
								}
								wcagLabel={tn('zpravy.smazat-kopii')}>
								<span className="inline-flex items-center gap-x-3">
									<GovIcon
										slot="left-icon"
										name="trash"
									/>
									<span className={'!hidden [ xl:!inline-block ]'}>
										{tn('zpravy.smazat-kopii')}
									</span>
								</span>
							</Button>
						)}
					{folderType !== DataBoxFolderType.Trash && (
						<Button
							variant="primary"
							type="outlined"
							size="s"
							loading={loadingItems.download}
							onClick={() => handleDownloadMessage(message)}
							disabled={!!loadingItems.download}
							wcagLabel={tn('zpravy.stahnout-zpravu')}>
							<span className="inline-flex items-center gap-x-3">
								{!loadingItems.download && (
									<GovIcon
										slot="left-icon"
										name="download"
									/>
								)}
								<span className={'!hidden [ xl:!inline-block ]'}>
									{tn('zpravy.stahnout-zpravu')}
								</span>
							</span>
						</Button>
					)}
					{folderType === DataBoxFolderType.Trash && (
						<>
							<Button
								variant="error"
								type="outlined"
								size="s"
								onClick={() =>
									setConfirmModal(
										DataBoxConfirmModalType.removeMessagesPermanently
									)
								}
								wcagLabel={tn('zpravy.trvale-smazat')}>
								<span className="inline-flex items-center gap-x-3">
									<GovIcon
										slot="left-icon"
										name="trash"
									/>
									<span className={'!hidden [ xl:!inline-block ]'}>
										{tn('zpravy.trvale-smazat')}
									</span>
								</span>
							</Button>
							<Button
								variant="primary"
								type="outlined"
								size="s"
								loading={loadingItems.restore}
								className={'mr-auto'}
								onClick={() => handleRestoreMessage(redirect)}
								disabled={!!loadingItems.restore}
								wcagLabel={tn('zpravy.obnovit')}>
								<span className="inline-flex items-center gap-x-3">
									{!loadingItems.restore && (
										<GovIcon
											slot="left-icon"
											name="arrow-counterclockwise"
										/>
									)}
									<span className={'!hidden [ xl:!inline-block ]'}>
										{tn('zpravy.obnovit')}
									</span>
								</span>
							</Button>
						</>
					)}
					{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(
						folderType
					) && (
						<GovTooltip
							position="top"
							size="s"
							icon
							className={'!hidden h-6 [ xl:!block ]'}>
							<GovIcon
								name="info-circle"
								className={'w-6 h-6 grow-0 shrink-0'}
							/>
							<GovTooltipContent>
								<p>{tn('zpravy.zpravy-nemuzete-smazat')}</p>
							</GovTooltipContent>
						</GovTooltip>
					)}
					{folderType !== DataBoxFolderType.Trash ? (
						message.prijemceId === dataBoxId ? (
							<Button
								variant="primary"
								type="base"
								size="s"
								href={routeResolver(PageCode['datove-schranky-odpoved'], {
									dataBoxId,
									folderType,
									messageId,
								})}
								className={'ml-auto'}
								disabled={disableNewMessageButton}
								wcagLabel={tn('zpravy.detail.odpovedet')}>
								<GovIcon
									slot="left-icon"
									name="reply"
								/>
								{tn('zpravy.detail.odpovedet')}
							</Button>
						) : (
							<Button
								variant="primary"
								type="base"
								size="s"
								href={routeResolver(PageCode['datove-schranky-nova'], {
									dataBoxId,
									folderType,
									prijemceId: message.prijemceId,
								})}
								className={'ml-auto'}
								disabled={disableNewMessageButton}
								wcagLabel={tn('zpravy.detail.odeslat-dalsi')}>
								<GovIcon
									slot="left-icon"
									name="reply"
								/>
								{tn('zpravy.detail.odeslat-dalsi')}
							</Button>
						)
					) : null}
				</footer>
			</div>
			<DataBoxMessageConfirmModal />
		</section>
	);
};
