import React, { createContext, ReactNode, useContext } from 'react';
import {
	fetchDataPreviewsQuery,
	isResponseStatus,
	usePoQueries,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useProcessControl } from '@gov-nx/core/hooks';
import { is } from '@gov-nx/core/types';
import { useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import { FormInstance } from './FormDefinitions';
import {
	CadastreOfRealEstateContext,
	DataDetail,
	CadastreOfRealEstatePreviewsDetailsParams,
	CadastreOfRealEstateDataSource,
	CadastreOfRealEstatePreviewsParams,
	DataResponse,
	CadastreOfEstateFilter,
} from './context.types';
import { formatItemData, getDetailsFormatted } from './utils';

const KatastrNemovitostiContext =
	createContext<CadastreOfRealEstateContext | null>(null);

interface CadastreOfRealEstateContextProviderProps {
	children: ReactNode;
	code: PageCode;
}

export function KatastrNemovitostiContextProvider({
	children,
	code,
}: CadastreOfRealEstateContextProviderProps) {
	const { setControls, controls } = useProcessControl({ initialLoading: true });
	const { filter, setPartialFilter } = useFilter<CadastreOfEstateFilter>({
		key: code,
		initialState: {
			text: undefined,
		},
	});

	const formDefinition = FormInstance({ code, defaultValues: filter });

	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		setPartialFilter(formDefinition.formMethods.getValues());
	});

	const realEstateQuery = usePoQuery({
		queryKey: [
			CadastreOfRealEstateDataSource.KATASTR_NEMOVITOSTI_PRAVO,
			filter,
		],
		queryFn: async () => {
			const response = await fetchDataPreviewsQuery<
				CadastreOfRealEstatePreviewsParams,
				DataResponse
			>({
				zdrojUdaju: CadastreOfRealEstateDataSource.KATASTR_NEMOVITOSTI_PRAVO,
				text: filter.text,
				format: 'json',
			});

			const realEstates = formatItemData(response.data);
			if (realEstates.length === 0) {
				setControls({ initialLoading: false });
			}

			return realEstates;
		},
		onError: (error: Error) =>
			setControls({
				initialLoading: false,
				initialError: new GovError(error.message),
			}),
	});

	const queries = usePoQueries({
		queries: (realEstateQuery.data ?? []).map((lv) => ({
			queryKey: [
				CadastreOfRealEstateDataSource.KATASTR_NEMOVITOSTI_ODKAZ,
				lv.lv,
			],
			onError: (error: Error) =>
				setControls({
					initialLoading: false,
					initialError: new GovError(error.message),
				}),
			queryFn: async () => {
				if (lv.telId) {
					const response = await fetchDataPreviewsQuery<
						CadastreOfRealEstatePreviewsDetailsParams,
						DataDetail
					>({
						id: lv.telId,
						typ: 'lv',
						zdrojUdaju:
							CadastreOfRealEstateDataSource.KATASTR_NEMOVITOSTI_ODKAZ,
					});

					return getDetailsFormatted(lv, response.data);
				}
			},
		})),
		configs: [
			{
				errorIgnoreFilter: isResponseStatus(404),
			},
		],
		onAllSuccess: () => {
			if (realEstateQuery.data && realEstateQuery.data.length > 0) {
				setControls({ initialLoading: false });
			}
		},
	});

	const realEstates = queries.map((q) => q.data).filter(is);

	return (
		<KatastrNemovitostiContext.Provider
			value={{
				controls,
				formDefinition,
				onSubmit,
				realEstates,
				queryLoading: realEstateQuery.isLoading,
				noRealEstates:
					!realEstateQuery.isLoading && realEstates && realEstates.length === 0,
			}}>
			{children}
		</KatastrNemovitostiContext.Provider>
	);
}

export const useKatastrNemovitostiContextInstance =
	(): CadastreOfRealEstateContext =>
		useContext(KatastrNemovitostiContext) as CadastreOfRealEstateContext;
