import cx from 'classnames';
import React from 'react';

export interface LayoutGapProps {
	children: React.ReactNode;
	gap?: number;
	className?: string;
}

export const LayoutGap = ({ children, gap = 6, className }: LayoutGapProps) => {
	return (
		<div
			className={cx(
				'flex flex-col',
				gap && `gap-${gap}`,
				className && className
			)}>
			{children}
		</div>
	);
};
