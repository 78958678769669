import { useEffect, useState } from 'react';
import { CiselnikyVolbyDto } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { IElectionControl, ServiceCode } from '@gov-nx/module/service';

interface Props {
	election?: CiselnikyVolbyDto;
	onCheck: () => Promise<IElectionControl>;
}

export interface InitialCheckResult {
	isLoading: boolean;
	initialErrors: IElectionControl['errors'];
}

export const useInitialCheck = ({
	election,
	onCheck,
}: Props): InitialCheckResult => {
	const { t } = useLocale(ServiceCode['volicsky-prukaz']);

	const [initialCheck, setInitialCheck] = useState<InitialCheckResult>({
		isLoading: true,
		initialErrors: [],
	});

	const check = async () => {
		const canBeSubmitted = !!election?.kola?.find(
			(round) => round.lzePodatZadost
		);

		if (!canBeSubmitted) {
			return setInitialCheck({
				isLoading: false,
				initialErrors: [
					{ code: 'nelze', message: t('volby.error.nelze-podat.text') },
				],
			});
		}

		const transformed = await onCheck();

		setInitialCheck({
			isLoading: false,
			initialErrors: transformed.errors.filter(({ code }) =>
				['NesplnenaPodminkaVek'].includes(code)
			),
		});
	};

	useEffect(() => {
		if (!election) return;
		check();
	}, [election]);

	return initialCheck;
};
