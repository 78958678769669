import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import {
	LocalizeNameSpaceTypes,
	useLocale,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Space } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';

export const Storage = () => {
	const { code, storage, firstDataBox } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { t } = useLocale(LocalizeNameSpaceTypes.Page);

	if (storage.isLoading) {
		return null;
	}

	if (storage.error) {
		return (
			<p>{tsn('stav.chyba.behem-nacitani-stavu-uloziste-doslo-k-chybe')}</p>
		);
	}

	if (storage.data.hasEnoughFreeSpace) return null;

	return (
		<div className={'mb-4'}>
			<p className={'mb-4 text-secondary-700'}>
				<strong>{t('uloziste.uloziste-portalu-obcana')}</strong>
			</p>
			<div className={'w-full h-4 mb-2 rounded bg-secondary-500'}>
				<div
					className={'h-4 rounded-l bg-error-400'}
					style={{
						width: `${
							storage.data.isStorageFull ? 100 : storage.data.usedInPercentInt
						}%`,
					}}></div>
			</div>
			<div
				className={
					'flex justify-between w-full mb-4 text-xs text-secondary-700'
				}>
				{storage.data.isStorageFull ? (
					<>
						<span title={storage.data.fullStorageInPercentFormatted}>
							<PoMarkdownRenderWeb>
								{t('uloziste.vyuzito-z', {
									used: storage.data?.usedFormatted ?? '',
									maximum: storage.data?.maximumFormatted ?? '',
								})}
							</PoMarkdownRenderWeb>
						</span>
						<span title={storage.data.maximumFormatted}>
							{t('uloziste.zaplneno')}
							<Space />
							<strong>{storage.data.fullStorageInPercentFormatted}</strong>
						</span>
					</>
				) : (
					<>
						<span title={storage.data?.usedInPercentFormatted}>
							<PoMarkdownRenderWeb>
								{t('uloziste.vyuzito-z', {
									used: storage.data?.usedFormatted,
									maximum: storage.data?.maximumFormatted,
								})}
							</PoMarkdownRenderWeb>
						</span>
						<span title={storage.data?.freeFormatted}>
							{t('uloziste.zbyva')}
							<Space />
							<strong>{storage.data?.freeInPercentFormatted}</strong>
						</span>
					</>
				)}
			</div>
			<GovMessage variant={'error'}>
				<PoMarkdownRenderWeb>
					{t(
						'uloziste.uloziste-procistite-smazanim-nekterych-archivovanych-zprav-nebo-dokumentu',
						{
							documentUrl: pageRouteResolver('moje-soubory'),
							archiveUrl: firstDataBox?.datovaSchrankaId
								? pageRouteResolver('datove-schranky-seznam', {
										dataBoxId: firstDataBox.datovaSchrankaId,
										folderType: DataBoxFolderType.Archived,
								  })
								: pageRouteResolver('datove-schranky'),
						}
					)}
				</PoMarkdownRenderWeb>
				<GovIcon
					type="basic"
					name="exclamation-triangle-fill"
					slot="icon"></GovIcon>
			</GovMessage>
		</div>
	);
};
