import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLastDocument } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { toDateReadable } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';
import { ToggleButton } from '../Button/ToggleButton';

export interface LastDocumentProps {
	code: ServiceCode;
	isLoading: boolean;
	lastDocument: ReturnType<typeof useLastDocument>;
	children: ReactNode;
}

export const LastDocument = ({
	code,
	lastDocument,
	isLoading,
	children,
}: LastDocumentProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { t } = useTranslation(code);

	if (!lastDocument.data) {
		return null;
	}

	return (
		<GovMessage variant={'secondary'}>
			<GovIcon
				type={'basic'}
				name={'lightbulb-fill'}
				slot={'icon'}></GovIcon>
			<div
				className={
					'flex-col flex justify-between items-start gap-4 [ md:flex-row ]'
				}>
				<div className={'flex-col flex gap-2 flex-1'}>
					<p>
						{t('formular.zprava.posledni-zadost-o-vypis', {
							namespace: code,
							date: lastDocument.data?.datumOdeslani
								? toDateReadable(lastDocument.data.datumOdeslani)
								: undefined,
						})}
					</p>
					<ToggleButton
						showLabel={tsn('akce.zobrazit-detail-zadosti')}
						hideLabel={tsn('akce.skryt-detail-zadosti')}>
						<div className="bg-neutral-white">{children}</div>
					</ToggleButton>
				</div>

				{lastDocument.lastDocument && (
					<Button
						loading={lastDocument.lastDocument.isDownloading}
						wcagLabel={tsn('akce.wcag.stahnout-vypis')}
						disabled={isLoading || lastDocument.lastDocument.isDownloading}
						variant={'primary'}
						size={'m'}
						onClick={lastDocument.lastDocument.onDownload}
						type={'solid'}>
						{tsn('akce.stahnout-vypis')}
					</Button>
				)}
			</div>
		</GovMessage>
	);
};
