import { create } from 'zustand';
import { Configuration } from '@gov-nx/api/common';
import { currentPlatform, CurrentPlatformType } from '@gov-nx/utils/common';
import { ConfigurationState } from './store.types';

const initialState = {
	cmsUrlPath: 'https://obcan.portal.gov.cz/cms',
};

export const useConfigurationStore = create<ConfigurationState>()(
	(set, get) => ({
		...initialState,

		initMeta: (application: string, environment: string) =>
			set({
				...initialState,
				application: String(application).toString(),
				environment: String(environment).toString(),
			}),
		saveConfiguration: (configuration: Configuration) => set({ configuration }),

		poServerUrl: () => {
			const configuration = get().configuration;
			// return 'https://poc.asseco-ce.com'
			// return 'http://localhost:3000'
			if (configuration?.poServerUrl) {
				const { poServerUrl } = configuration;
				if (poServerUrl && poServerUrl.indexOf('|') !== -1) {
					const urls = poServerUrl.split('|');
					if (currentPlatform === CurrentPlatformType.IOS) {
						return urls[0] as string;
					} else if (currentPlatform === CurrentPlatformType.ANDROID) {
						return urls[1] as string;
					}
				}
				return poServerUrl as string;
			}
			return null;
		},
		getBaseCmsUrlPath: () => {
			const cmsUrlPath = get().cmsUrlPath;
			const finalCmsUrlPath = String(cmsUrlPath).includes('https')
				? cmsUrlPath
				: 'https://obcan.portal.gov.cz/cms';

			return finalCmsUrlPath;
		},
		getCmsUrlPath: () => {
			const cmsUrlPath = get().cmsUrlPath;
			const finalCmsUrlPath = String(cmsUrlPath).includes('https')
				? cmsUrlPath
				: 'https://obcan.portal.gov.cz/cms';

			return `${finalCmsUrlPath}/${get().application}/${get().environment}`;
		},
	})
);
