import { routeResolver } from '@gov-nx/web';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePetitionToggleText } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { pipe } from '@gov-nx/core/types';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	ToggleButton,
} from '@gov-nx/ui/web';
import { stripHTMLTags } from '@gov-nx/utils/common';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { EPetitionAttachments } from './EPetitionAttachments';
import { EPetitionDetailSkeleton } from './EPetitionDetailSkeleton';
import { EPetitionExtend } from './EPetitionExtend';
import { EPetitionInfo } from './EPetitionInfo';
import { EPetitionShareMessage } from './EPetitionShareMessage';
import { EPetitionSign } from './EPetitionSign';
import { EPetitionCloseAndSubmit } from './ePetitionCloseAndSubmit';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionDetailView = ({ code }: EPetitionDetailViewProps) => {
	const {
		petition,
		individualPerson,
		ePetitionSign,
		ePetitionExtend,
		controls,
		isEditable,
	} = useEPeticeDetailContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const navigate = useNavigate();

	const maxSymbol = 400;
	const { truncateText, toggleFullText } = usePetitionToggleText(maxSymbol);

	const getContent = () => {
		if (controls.initialLoading) {
			return <EPetitionDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		return (
			<>
				<EPetitionCloseAndSubmit code={code} />
				{ePetitionExtend.canBeExtended ? <EPetitionExtend code={code} /> : null}

				<ContentLayout customClasses={'flex flex-col gap-8'}>
					{petition?.perex ? (
						<h2 className={'text-xl text-secondary-800'}>
							{stripHTMLTags(petition.perex)}
						</h2>
					) : null}
					{petition?.text ? (
						<div className="flex flex-col gap-0">
							<div className={'format-basic-html-tags'}>
								{pipe(petition.text, stripHTMLTags, truncateText)}
							</div>
							{petition.text.length > maxSymbol && (
								<div>
									<ToggleButton
										showLabel={ts('akce.zobrazit-vice')}
										hideLabel={ts('akce.zobrazit-mene')}
										onClick={toggleFullText}
									/>
								</div>
							)}
						</div>
					) : null}
					<section>
						<EPetitionAttachments code={code} />
					</section>
					<section>
						<EPetitionInfo code={code} />
					</section>
					{isEditable ? (
						<Button
							variant={'primary'}
							type={'solid'}
							onClick={() =>
								navigate(
									routeResolver(PageCode['epetice-detail-editace'], {
										petitionId: petition?.id,
									})
								)
							}
							wcagLabel={ts('akce.wcag.editovat')}>
							{ts('akce.editovat')}
						</Button>
					) : null}
				</ContentLayout>
				{individualPerson && ePetitionSign.isPublic ? (
					<EPetitionSign code={code} />
				) : null}

				{petition?.datumZverejneni ? (
					<EPetitionShareMessage
						petition={petition}
						code={code}
					/>
				) : null}
			</>
		);
	};

	return (
		<CmsPageRender
			code={code}
			override={{ name: petition?.nazev }}
			showDescription={false}>
			{getContent()}
		</CmsPageRender>
	);
};
