import { GovButton, GovErrorCode, GovIcon } from '@gov-design-system-ce/react';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import {
// 	PERSIST_ZUSTAND_STORE_KEY,
// 	StoreKeys,
// } from '@gov-nx/store/portal-obcana';
import { useEffect } from 'react';
import { useEnvironment } from '@gov-nx/core/hooks';
import { LayoutGap } from '@gov-nx/ui/web';

interface ErrorPageProps {
	message: string;
}

export const ErrorPage = ({ message }: ErrorPageProps) => {
	const env = useEnvironment();

	useEffect(() => {
		if (!env.isLocalhost) {
			// Todo: Remove storage for web
			// (Object.keys(StoreKeys) as Array<keyof typeof StoreKeys>).map(
			// 	async (key) => {
			// 		await AsyncStorage.removeItem(
			// 			PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys[key]
			// 		);
			// 	}
			// );
		}
	}, [env]);

	return (
		<main className={'flex items-center content-center'}>
			<div className={'gov-layout'}>
				<section className={'gov-layout__section gov-layout__section-stretch'}>
					<div
						className={
							'gov-layout-column max-w-[712px] mx-auto flex min-h-screen items-center'
						}>
						<GovErrorCode>
							<LayoutGap className={'text-center items-center'}>
								<GovIcon
									className={'w-36 h-36 text-primary-600'}
									name="card-500"
									type="complex"></GovIcon>
								<div>
									<h1 className="gov-text--5xl">Nastala chyba</h1>
									<p className="gov-text--l pb-10">
										Na opravě usilovně pracujeme, vraťte se, prosím, za chvíli.
									</p>
									<hr />
									<p className="gov-text--5xl mt-8">
										<span className={'text-secondary-700'}>
											I think it's time
										</span>
									</p>
									<p className="gov-text--l text-secondary-700">
										We're working hard on the repair, please come back in a
										moment.
									</p>
									<p className="hidden">{message}</p>

									<GovButton
										className={'mt-10'}
										onGov-click={() => window.location.reload()}
										variant={'primary'}
										size={'l'}
										type={'outlined'}>
										Aktualizovat stránku / Refresh page
									</GovButton>
								</div>
							</LayoutGap>
						</GovErrorCode>
					</div>
				</section>
			</div>
		</main>
	);
};
