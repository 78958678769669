import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { isString } from '@gov-nx/core/types';
import { ToastMessageSubscription } from './message.types';
import {
	MessageSubscriptionTypes,
	messageEvents,
	toastMessageSubscription,
} from './messageEvents';

interface MessageEventsProps {
	onToastMessage?: (
		action: ToastMessageSubscription['type'],
		payload: ToastMessageSubscription['payload']
	) => void;
}

export const useMessageEvents = ({
	onToastMessage,
}: MessageEventsProps = {}) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	useEffect(() => {
		const toastMessageSub =
			onToastMessage &&
			messageEvents.subscribe(
				MessageSubscriptionTypes.toastMessage,
				onToastMessage
			);

		return () => {
			toastMessageSub?.unSubscribe();
		};
	}, [onToastMessage]);

	const toastMessage = (payload: ToastMessageSubscription['payload']) => {
		messageEvents.publish(toastMessageSubscription(payload));
	};

	return {
		toastMessage,
		toastMessageSuccess: (content: string) =>
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content,
			}),
		toastMessageError: (content?: string | null) => {
			return toastMessage({
				options: {
					variant: 'error',
					type: 'solid',
					icon: {
						name: 'exclamation-triangle-fill',
						type: 'basic',
					},
				},
				content: isString(content) ? content : t('notification.error.default'),
			});
		},
	};
};
