import React from 'react';
import { useIcSearchAutocomplete } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

export const IcNumberAutocomplete = (props: WebFormAutocompleteProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { isFetching, result, setText } = useIcSearchAutocomplete();

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			onSelect={() => setText(undefined)}
			field={{
				...props.field,
				options: result ? [result] : [],
				processing: isFetching,
				messageEmpty:
					props.field.messageEmpty ?? tn('naseptavac.pole.zadny-vysledek'),
				messageLoading:
					props.field.messageLoading ?? tn('naseptavac.pole.nacitam'),
				rightIcon: {
					name: 'search',
					type: 'basic',
				},
				templateResolver: (item) => {
					return item?.ico;
				},
				nameKey: 'ico',
			}}
		/>
	);
};
