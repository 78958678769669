import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const DriversRegistrySkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout>
			<div className={'flex gap-4 pb-3 overflow-hidden'}>
				<GovSkeleton
					width={'100px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'100px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<GovSkeleton
					width={'78px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<LayoutGap
				gap={2}
				className={'py-6 px-4 border-b border-secondary-300'}>
				<GovSkeleton
					width={'150px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<div className={'[ md:w-[360px] ]'}>
					<GovSkeleton
						width={'100%'}
						height={'21px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</LayoutGap>
			<div className={'mt-6 mb-4'}>
				<GovSkeleton
					width={'170px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<DataTable responsive>
				<tbody>
					{[0, 1, 2, 3, 4, 5].map((i) => {
						return (
							<tr key={i}>
								<td className={'md:!w-1/2'}>
									<GovSkeleton
										height={'19px'}
										width={generateRandomNumber(20, 30) + '%'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</td>
								<td>
									<GovSkeleton
										height={'19px'}
										width={'100px'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
										className={'flex flex-grow '}
									/>
								</td>
							</tr>
						);
					})}
				</tbody>
			</DataTable>
			<div className={'mt-12 mb-4'}>
				<GovSkeleton
					width={'170px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<DataTable responsive>
				<tbody>
					<tr>
						<td className={'md:!w-1/2'}>
							<GovSkeleton
								height={'19px'}
								width={generateRandomNumber(20, 30) + '%'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</td>
						<td>
							<GovSkeleton
								height={'19px'}
								width={'100px'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={'flex flex-grow '}
							/>
						</td>
					</tr>
				</tbody>
			</DataTable>
		</ContentLayout>
	);
};
