import { LocalizationLanguage } from '@gov-nx/core/app';
import { useListsStore } from '@gov-nx/store/portal-obcana';
import { getI18n } from '../translations/i18n';

interface LanguageHookProps {
	filterOut?: string[];
}

export const useLanguage = (props?: LanguageHookProps) => {
	const i18n = getI18n();
	const { languages } = useListsStore();
	const selectedLocale =
		i18n.language === LocalizationLanguage.Czech
			? LocalizationLanguage.Czech
			: LocalizationLanguage.English;

	const languagesForSelect =
		languages
			?.sort((a, b) =>
				a.name[selectedLocale]
					.toLocaleLowerCase()
					.localeCompare(b.name[selectedLocale].toLocaleLowerCase())
			)
			.map((lang) => {
				return {
					value: lang.iso2,
					label:
						selectedLocale === LocalizationLanguage.Czech
							? lang.name.cs
							: lang.name.en,
				};
			}) ?? [];

	const filterCondition = props?.filterOut && props.filterOut;
	const filteredLanguagesToSelect = languagesForSelect.filter(
		(lang) => !filterCondition?.includes(lang.value)
	);

	return { languages, languagesForSelect, filteredLanguagesToSelect };
};
