import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useDokumentyReklamaceContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormDatePicker,
	FormInput,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../Form/PoForm';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';

export interface ComplaintProps {
	code: PageCode;
}

export const DocumentComplaintView = ({ code }: ComplaintProps) => {
	const { controls, formDefinition, onSubmit } =
		useDokumentyReklamaceContextInstance();
	const isProcessing = controls.processLoading;

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<LayoutGap gap={8}>
					<PoMarkdownRenderWeb>{ts('formular.popis')}</PoMarkdownRenderWeb>
					<FormWidthElementLayout type={'l'}>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'cisloDokladu',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.cislo-dokladu'),
								}}
								label={{ children: ts('formular.pole.cislo-dokladu') }}
								control={{ size: 'm' }}
								group={{ gap: 's' }}
							/>
							<FormDatePicker
								field={{
									name: 'platnostDo',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.platnost-do'),
								}}
								label={{
									children: ts('formular.pole.platnost-do'),
								}}
								group={{ gap: 's' }}
							/>
							<FormInput
								field={{
									name: 'popisReklamace',
									multiline: true,
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.popis-reklamace'),
								}}
								label={{ children: ts('formular.pole.popis-reklamace') }}
								control={{ size: 'm' }}
								group={{ gap: 's' }}
							/>
							<ButtonGroupLayout>
								<Button
									loading={controls.processLoading}
									disabled={isProcessing}
									nativeType="submit"
									variant="primary"
									size="m"
									type="solid"
									wcagLabel={ts('akce.odeslat-reklamaci')}>
									{ts('akce.odeslat-reklamaci')}
								</Button>
							</ButtonGroupLayout>
						</LayoutGap>
					</FormWidthElementLayout>
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
