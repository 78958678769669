import { GovIcon } from '@gov-design-system-ce/react';
import { useConfiguration, useLocale } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';
import { formatPrice } from '@gov-nx/utils/common';

export const LowCreditPrompt = () => {
	const { t: tn } = useLocale(PageCode['datove-schranky']);

	const { lowCreditPrompt, messagePrice } = useDataBoxMessageFormContext();
	const { datovaSchrankaDobitiKredituUrl } = useConfiguration();

	return (
		<Prompt
			label={tn('nova-zprava.je-potreba-dobit-kredit')}
			open={lowCreditPrompt.isOpen}
			onGov-close={lowCreditPrompt.onPromptClose}>
			<p>
				{tn('nova-zprava.je-potreba-dobit-kredit-vysvetleni', {
					price: formatPrice(messagePrice),
				})}
			</p>

			<Button
				variant={'primary'}
				nativeType={'button'}
				type={'base'}
				onClick={lowCreditPrompt.onPromptClose}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.zrusit')}>
				{tn('nova-zprava.nepridavat-adresata')}
			</Button>

			<Button
				href={datovaSchrankaDobitiKredituUrl.replace(
					'{{DS_ID}}',
					lowCreditPrompt.senderId as string
				)}
				enforceLink={true}
				target={'_blank'}
				variant={'primary'}
				type={'solid'}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.prejit')}>
				{tn('nova-zprava.prejit-na-dobiti')}
				<GovIcon
					slot="right-icon"
					name="box-arrow-up-right"
				/>
			</Button>
		</Prompt>
	);
};
