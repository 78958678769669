import {
	DatoveSchrankyDatovaZpravaDto,
	archiveAllDataBoxesMessagesQuery,
	archiveDataBoxesMessagesQuery,
	deleteDataBoxesMessagesQuery,
	restoreDataBoxesMessagesQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useMessageEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { isFulfilled, isRejected, Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import {
	DataBoxConfirmModalType,
	DataBoxFolderType,
} from '../providers/DataBoxes.types';
import { DataBoxMessageListLoadingItemsType } from '../providers/MessageListContext.types';

interface MassOperationsProps {
	messageList: DatoveSchrankyDatovaZpravaDto[];
	selectedMessageList: number[];
	dataBoxId: string;
	folderType: DataBoxFolderType;
	updateLoadingItems: (
		loadingItem: DataBoxMessageListLoadingItemsType,
		add: boolean
	) => void;
	setConfirmModal: (confirmModal: Nullable<DataBoxConfirmModalType>) => void;
	cancelMassOperations: () => void;
}

export const useMassOperations = ({
	messageList,
	selectedMessageList,
	dataBoxId,
	folderType,
	updateLoadingItems,
	setConfirmModal,
	cancelMassOperations,
}: MassOperationsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { toastMessage } = useMessageEvents();
	const {
		messagesArchive,
		messagesUnselectAll,
		messageListUpdate,
		storageRefetch,
	} = useDataBoxEvents();

	const archiveMessages = usePoMutation({
		mutationFn: archiveDataBoxesMessagesQuery,
		onSuccess: (data, variables) => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
			messagesUnselectAll();
			cancelMassOperations();
			storageRefetch();

			messagesArchive({
				messageIds: variables.messagesIds.filter(
					(_, index) => data[index].status === 'fulfilled'
				),
			});

			const successCount = data.filter(isFulfilled).length;
			const errorResponses = data.filter(isRejected);

			if (errorResponses.length) {
				toastMessage({
					options: {
						variant: 'error',
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
					content: tn('akce.chyba-archivace', { count: errorResponses.length }),
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'archive-outline',
							type: 'basic',
						},
					},
					content: tn('akce.zkopirovany-archiv', { count: successCount }),
				});
			}
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
		},
	});

	const handleArchiveMessages = async () => {
		updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, true);

		const messages = messageList.filter(
			(message) =>
				selectedMessageList.includes(message.datovaZpravaId as number) &&
				!message.datumArchivace
		);

		if (!messages.length) {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
			messagesUnselectAll();
			cancelMassOperations();

			toastMessage({
				options: {
					variant: 'success',
					icon: {
						name: 'archive-outline',
						type: 'basic',
					},
				},
				content: tn('akce.zkopirovany-archiv', {
					count: selectedMessageList.length,
				}),
			});
		} else {
			archiveMessages.mutate({
				dataBoxId,
				messagesIds: messages.map(
					(message) => message.datovaZpravaId as number
				),
			});
		}
	};

	const archiveAllMessages = usePoMutation({
		mutationFn: archiveAllDataBoxesMessagesQuery,
		onSuccess: () => {
			messagesArchive({
				messageIds: messageList.map(
					(message) => message.datovaZpravaId as number
				),
			});
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
			storageRefetch();

			toastMessage({
				content: tn('akce.vsechny-zpravy-archivovany'),
				options: {
					variant: 'success',
					icon: {
						name: 'archive-outline',
						type: 'basic',
					},
				},
			});
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
		},
	});

	const handleArchiveAllMessages = () => {
		updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, true);
		archiveAllMessages.mutate({ dataBoxId, folderType });
	};

	const deleteMessages = usePoMutation({
		mutationFn: deleteDataBoxesMessagesQuery,
		onSuccess: (_data, { permanently, messagesIds }) => {
			updateLoadingItems(
				permanently
					? DataBoxMessageListLoadingItemsType.RemovePermanently
					: DataBoxMessageListLoadingItemsType.Remove,
				false
			);
			messageListUpdate();
			setConfirmModal(null);
			messagesUnselectAll();
			cancelMassOperations();
			storageRefetch();

			const successCount = messagesIds.length;

			toastMessage({
				options: {
					variant: 'success',
					icon: {
						name: 'trash',
						type: 'basic',
					},
				},
				content: permanently
					? tn('akce.trvale-smazany-kopie', { count: successCount })
					: tn('akce.smazany-kopie', { count: successCount }),
			});
		},
		onError: async (_, { permanently }) => {
			updateLoadingItems(
				permanently
					? DataBoxMessageListLoadingItemsType.RemovePermanently
					: DataBoxMessageListLoadingItemsType.Remove,
				false
			);
		},
	});

	const handleDeleteMessages = async (permanently = false) => {
		updateLoadingItems(
			permanently
				? DataBoxMessageListLoadingItemsType.RemovePermanently
				: DataBoxMessageListLoadingItemsType.Remove,
			true
		);
		deleteMessages.mutate({
			messagesIds: selectedMessageList,
			permanently,
		});
	};

	const handleEmptyTrash = async () => {
		updateLoadingItems(
			DataBoxMessageListLoadingItemsType.RemovePermanently,
			true
		);
		deleteMessages.mutate({
			messagesIds: messageList.map(
				(message) => message.datovaZpravaId as number
			),
			permanently: true,
		});
	};

	const restoreMessages = usePoMutation({
		mutationFn: restoreDataBoxesMessagesQuery,
		onSuccess: (data) => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, false);
			messageListUpdate();
			messagesUnselectAll();
			cancelMassOperations();

			const successCount = data.filter(isFulfilled).length;
			const errorResponses = data.filter(isRejected);

			if (errorResponses.length) {
				toastMessage({
					options: {
						variant: 'error',
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
					content: tn('akce.chyba-obnoveni', { count: errorResponses.length }),
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'trash-restore',
							type: 'basic',
						},
					},
					content: tn('akce.obnoveny', { count: successCount }),
				});
			}
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, false);
		},
	});

	const handleRestoreMessages = async () => {
		updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, true);
		restoreMessages.mutate({ messagesIds: selectedMessageList });
	};

	return {
		handleArchiveMessages,
		handleArchiveAllMessages,
		handleDeleteMessages,
		handleRestoreMessages,
		handleEmptyTrash,
	};
};
