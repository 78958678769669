import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { UiBlockingGroupContext } from '@gov-nx/component/common';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { DalsiNastaveniDatovkyContext, PageCode } from '@gov-nx/module/page';
import { Button, FormSwitch } from '@gov-nx/ui/web';
import { DataboxAdditionalSettingsSkeleton } from './DataboxAdditionalSettingsSkeleton';
import { DataboxDisconnectModal } from './DataboxDisconnectModal';

export const DataboxAdditionalSettings = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	const context = useContext(DalsiNastaveniDatovkyContext);
	const uiBlocking = useContext(UiBlockingGroupContext);

	if (!context) {
		return null;
	}

	const {
		databoxId,
		databox,
		controls,
		formDefinitions,
		isAutomaticArchiveEnabled,
		openDisconnectConfirmation,
	} = context;

	if (controls.initialLoading) {
		return <DataboxAdditionalSettingsSkeleton />;
	}

	return (
		<>
			{databox?.typSchranky === 'PO' ? (
				<GovMessage
					variant={'warning'}
					className={'ml-4 mb-8'}>
					<GovIcon
						type={'basic'}
						name={'info'}
						slot={'icon'}></GovIcon>
					<p>{tds('automaticka-archivace.nedostupna-PO')}</p>
				</GovMessage>
			) : (
				<FormProvider {...formDefinitions.formMethods}>
					<div className={'ml-4 mb-8'}>
						<FormSwitch
							field={{
								name: `${databoxId}.shouldArchiveAutomatically`,
								disabled:
									controls.initialLoading ||
									controls.processLoading ||
									uiBlocking?.isBlockedEditation,
								label: {
									children: tds('automaticka-archivace.label'),
									identifier: `label-${databoxId}-shouldArchiveAutomatically`,
								},
								wcagLabelledBy: `label-${databoxId}-shouldArchiveAutomatically`,
								wcagDescribedBy: `automaticka-archivace-plny-popis_${databoxId}`,
							}}
						/>
						<p
							className="text-xs md:text-s text-secondary-700"
							id={`automaticka-archivace-plny-popis_${databoxId}`}>
							{tds('automaticka-archivace.plny-popis')}
						</p>
					</div>
					{isAutomaticArchiveEnabled ? (
						<div className={'ml-4 mb-8'}>
							<FormSwitch
								field={{
									name: `${databoxId}.shouldArchiveLargeMessagesAutomatically`,
									disabled:
										controls.initialLoading ||
										controls.processLoading ||
										uiBlocking?.isBlockedEditation,
									label: {
										children: tds('automaticka-archivace-velke-zpravy.label'),
										identifier: `label-${databoxId}-shouldArchiveLargeMessagesAutomatically`,
									},
									wcagLabelledBy: `label-${databoxId}-shouldArchiveLargeMessagesAutomatically`,
									wcagDescribedBy: `automaticka-archivace-plny-popis_${databoxId}`,
								}}
							/>
							<p
								className="text-xs md:text-s text-secondary-700"
								id={`automaticka-archivace-plny-popis_${databoxId}`}>
								{tds('automaticka-archivace-velke-zpravy.plny-popis')}
							</p>
						</div>
					) : null}
				</FormProvider>
			)}

			<Button
				variant="error"
				type="outlined"
				disabled={controls.initialLoading || controls.processLoading}
				onClick={openDisconnectConfirmation}
				wcagLabel={tds('odpojeni.odpojit-datovku-z-portalu')}
				className={'ml-4'}>
				{tds('odpojeni.odpojit-datovku-z-portalu')}
			</Button>

			<DataboxDisconnectModal />
		</>
	);
};
