import { useCallback } from 'react';
import { connectDataBoxQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

export const useDataBoxConnect = () => {
	const mutation = usePoMutation({
		mutationKey: ['data-box-connect'],
		mutationFn: connectDataBoxQuery,
	});

	const connect = useCallback(
		async (sessionId: string): Promise<Nullable<string>> => {
			const dataBoxToAdd = { isDsRedirect: 'sessionId=' + sessionId };
			const body = { klic: JSON.stringify(dataBoxToAdd) };
			const response = await mutation.mutateAsync({
				dataBoxId: 'sessionId',
				body,
			});
			return response.data?.datovaSchrankaId;
		},
		[mutation]
	);

	return { connect };
};
