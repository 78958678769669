import { useQuery } from '@tanstack/react-query';
import { useAppLanguageSelector } from '@gov-nx/core/hooks';
import { useConfiguration } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { axiosCmsInstance } from '../axios/instance';
import { CmsExternalService, CmsExternalServiceResponse } from './types';

export const useCmsExternalServiceQuery = (props: {
	id: Nullable<number>;
	onError?: (errorMessage: string) => void;
	onSuccess?: (data: CmsExternalService) => void;
}) => {
	const { cmsKeyCache } = useConfiguration();
	const { language } = useAppLanguageSelector();

	return useQuery({
		onError: props?.onError,
		onSuccess: props?.onSuccess,
		queryFn: async () => {
			const response = await axiosCmsInstance.get<CmsExternalServiceResponse>(
				`/external-services/${props.id}-${language}.json?v=${cmsKeyCache}`
			);
			return response.data.data;
		},
		enabled: !!props.id,
		retry: 0,
		refetchOnWindowFocus: false,
		queryKey: ['cms-external-service', props.id],
	});
};
