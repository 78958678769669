import {
	OsobyKomunikacniUdajeRosSeznamDto,
	OsobyPodnikatelDto,
} from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

export const mergeDataByIco = (
	businessPersonData: OsobyPodnikatelDto[],
	businessPersonCommunication: Nullable<OsobyKomunikacniUdajeRosSeznamDto>
) => {
	const icoMap = new Map(
		businessPersonCommunication?.seznam?.map((item) => [item.ico, item])
	);
	return businessPersonData
		.filter((data) => data.typ === 'PO' || 'PFO')
		.map((item) => ({ ...item, ...icoMap.get(item.ico) }));
};
