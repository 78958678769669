import {
	downloadDataBoxAttachmentQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { OnAttachmentSaveParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface AttachmentDownloadProps {
	onError: (attachmentHash: string, e: Error) => void;
	onSuccess: (data: OnAttachmentSaveParams) => void;
}

export const useAttachmentDownload = (props: AttachmentDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: downloadDataBoxAttachmentQuery,
		onError: (error, attachmentData) => {
			props.onError &&
				props.onError(
					attachmentData.attachment.hashPrilohy as string,
					error as GovError
				);
		},
		onSuccess: async (response, attachmentData) => {
			if (response.status === 202) {
				await delay(2000);
				await mutation.mutate(attachmentData);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props.onSuccess({
						antivirus,
						fileName,
						blob,
						id: attachmentData.attachment.hashPrilohy as string,
					});
				} catch (e) {
					props.onError(
						attachmentData.attachment.hashPrilohy as string,
						e as Error
					);
				}
			}
		},
	});
	return mutation;
};
