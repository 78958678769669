import { routeResolver, RouteType } from '@gov-nx/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CmsNavigation } from '@gov-nx/api/common';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { is } from '@gov-nx/core/types';
import { TileLinkList } from '@gov-nx/ui/web';

export interface CsmNavigationSignpostProps {
	navigations: CmsNavigation[];
	localizeNameSpace: LocalizeNameSpaceTypes;
}

export const CsmNavigationSignpost = ({
	navigations,
	localizeNameSpace,
}: CsmNavigationSignpostProps) => {
	const { t } = useTranslation([localizeNameSpace]);
	return (
		<section>
			<h2 className={'mb-4 text-4xl'}>
				{t('mozna-hledate', { namespace: localizeNameSpace })}
			</h2>
			<TileLinkList
				items={navigations
					.map((nav) => {
						let link;
						if (nav.service) link = nav.service;
						else if (nav.page) link = nav.page;
						if (link) {
							const route = routeResolver(
								link.code,
								null,
								nav.kind === 'service' ? RouteType.SERVICE : RouteType.PAGE
							);
							if (route.indexOf('#') === -1) {
								return {
									label: link.name,
									link: routeResolver(
										link.code,
										null,
										nav.kind === 'service' ? RouteType.SERVICE : RouteType.PAGE
									),
									icon: link.icon?.icon,
								};
							}
						}
						return null;
					})
					.filter(is)}
			/>
		</section>
	);
};
