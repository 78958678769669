import { GovIcon } from '@gov-design-system-ce/react';
import { Trans, useTranslation } from 'react-i18next';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DATABOX_FOLDER_LABELS,
	dataBoxLabel,
	getDataBoxTypeLabel,
	useDataBoxMessageListContext,
	useDataBoxSearchFormContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';
import { DataBoxMessageList } from './DataBoxMessageList';
import { DataBoxMessageListMainSkeleton } from './DataBoxMessageListMainSkeleton';

export const DataBoxMessageListMain = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);

	const {
		controls,
		messageList,
		isLoadMorePossible,
		loadMore,
		messagesFilter: { query, hasSearch, folderType },
		previousSuggestions,
		deleteSuggestion,
		nextDataBoxForSearch,
	} = useDataBoxMessageListContext();

	const { showRelevantSuggestions, setSearchInputValue } =
		useDataBoxSearchFormContext();

	const content = () => {
		if (hasSearch) {
			if (typeof query === 'string' && query.length < 3) {
				return (
					<p className="px-4 py-4 text-s font-bold text-secondary-700">
						{tn('vyhledavani.vyhledavany-vyraz')}
					</p>
				);
			} else if (!query) {
				if (previousSuggestions.length > 0) {
					return (
						<div>
							<p className="px-4 py-2 text-s font-bold text-secondary-700 bg-secondary-200">
								{tn('vyhledavani.nedavno-vyhledavane')}
							</p>
							{showRelevantSuggestions(previousSuggestions).map(
								(suggestion, index) => (
									<div
										key={suggestion}
										className={'flex gap-4 items-center justify-between px-4'}>
										<GovIcon
											name="clock-history"
											type="basic"
											className={'w-4 h-4 grow-0 shrink-0'}
										/>
										<div
											onClick={() => setSearchInputValue(suggestion)}
											className={
												'w-[274px] mr-auto overflow-hidden whitespace-nowrap text-ellipsis'
											}>
											{suggestion}
										</div>
										<Button
											variant="secondary"
											type="base"
											size="m"
											onClick={() => deleteSuggestion(suggestion)}>
											<GovIcon
												name="x"
												type="basic"
												slot="right-icon"
											/>
										</Button>
									</div>
								)
							)}
						</div>
					);
				} else {
					return (
						<>
							<div className="flex flex-col gap-2 px-4 py-6 [ ds:hidden ]">
								<p className="flex gap-2 items-center font-bold">
									<GovIcon
										name="lightbulb-fill"
										className={'inline-flex grow-0 shrink-0 w-4'}
									/>
									{tn('info-stranka.vyhledavani.podle')}
								</p>
								<ul className="pl-6 text-secondary-700">
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.predmet')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.odesilatel')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.schranka')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.zprava')}
									</li>
								</ul>
								<p>
									<Trans
										t={t}
										i18nKey="info-stranka.vyhledavani-slozka"
										components={{ strong: <strong /> }}
									/>
								</p>
							</div>
							<p className="hidden px-4 py-4 text-s text-secondary-700 [ ds:block ]">
								{tn('vyhledavani.vychozi-hlaska')}
							</p>
						</>
					);
				}
			} else if (!messageList?.length && !controls.processLoading) {
				return (
					<div className="px-4 py-8">
						<img
							src="\assets\images\empty.svg"
							width="91"
							height="56"
							alt=""
						/>
						<p className="mt-6 mb-10">
							<strong>
								{tn('vyhledavani.vyhledani-nebylo-uspesne', {
									vyraz: query,
								})}
							</strong>
						</p>
						<p>
							<strong>{tn('vyhledavani.tipy')}</strong>
						</p>
						<ul className="pl-6 text-secondary-700">
							<li className="mb-0 before:!bg-secondary-700">
								{tn('info-stranka.vyhledavani.podle')}
								<ul className="pl-6 font-bold">
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.predmet')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.odesilatel')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.schranka')}
									</li>
									<li className="!mb-0 before:!bg-secondary-700">
										{tn('info-stranka.vyhledavani.zprava')}
									</li>
								</ul>
							</li>
							<li className="!mb-0 before:!bg-secondary-700">
								<PoMarkdownRenderWeb>
									{tn('vyhledavani.ujistete-se')}
								</PoMarkdownRenderWeb>
							</li>
							{nextDataBoxForSearch && (
								<li className="!mb-0 before:!bg-secondary-700">
									<PoMarkdownRenderWeb>
										{t('vyhledavani.zkuste-vyhledat-jinde', {
											vyraz: query,
											slozka: tn(DATABOX_FOLDER_LABELS[folderType]),
											schrankaId: nextDataBoxForSearch.datovaSchrankaId,
											'nazev-schranky': dataBoxLabel(
												nextDataBoxForSearch,
												true
											),
											'typ-schranky': getDataBoxTypeLabel(
												nextDataBoxForSearch.typSchranky,
												tn
											),
										})}
									</PoMarkdownRenderWeb>
								</li>
							)}
							<li className="!mb-0 before:!bg-secondary-700">
								{tn('vyhledavani.zkontrolujte-preklepy')}
							</li>
						</ul>
					</div>
				);
			}
		}
		return (
			<>
				<DataBoxMessageList messageList={messageList} />

				{controls.processLoading && <DataBoxMessageListMainSkeleton />}

				{isLoadMorePossible && !controls.processLoading && (
					<div className={'py-4 border-t border-secondary-300 text-center'}>
						<Button
							variant="primary"
							type="outlined"
							size="s"
							onClick={loadMore}
							wcagLabel={tn('zpravy.vypis.nacist-dalsi')}>
							{tn('zpravy.vypis.nacist-dalsi')}
						</Button>
					</div>
				)}
			</>
		);
	};

	return <section className={'flex-1 overflow-y-auto'}>{content()}</section>;
};
