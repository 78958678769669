import * as yup from 'yup';
import { OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import {
	toDateReadable,
	nameFormatting,
	useAddressSearchShape,
} from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	FormData,
	ProfileRobComplaintProcessControl,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	data: Nullable<OsobyFyzickaOsobaDto>;
	controls: ProfileRobComplaintProcessControl;
}

export function FormInstance({
	code,
	data,
	controls,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const { getAddressSearchDefaultValues, getAddressSearchShape } =
		useAddressSearchShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			jmeno: yup.string().optional(),
			prijmeni: yup.string().optional(),
			datumNarozeni: yup.string().optional(),
			mistoNarozeni: yup.string().optional(),
			stat: yup.string().optional(),
			adresaPobytu: yup.string().optional(),
			nazevObceDorucovaciAdresa: yup.string().when([], {
				is: () => controls.displaySearchDeliveryAddress,
				then: yup
					.string()
					.required(tsn('formular.validace.popisReklamace.povinny')),
				otherwise: yup.string().optional(),
			}),
			nazevUliceDorucovaciAdresa: yup.string().optional(),
			cisloDorucovaciAdresa: yup.string().when([], {
				is: () => controls.displaySearchDeliveryAddress,
				then: yup
					.string()
					.required(tsn('formular.validace.popisReklamace.povinny'))
					.min(0)
					.max(4, tsn('formular.validace.max-9999'))
					.matches(/^[0-9]+$/, tsn('formular.validace.pouze-cislice')),
				otherwise: yup.string().optional(),
			}),
			popisReklamace: yup
				.string()
				.required(tsn('formular.validace.popisReklamace.povinny')),

			...getAddressSearchShape({
				controls: controls.displaySearchAddress,
				nazevObce: {
					requiredMessage: tsn('formular.validace.popisReklamace.povinny'),
				},
				cislo: {
					requiredMessage: tsn('formular.validace.popisReklamace.povinny'),
					max9999Message: tsn('formular.validace.max-9999'),
					onlyNumbersMessage: tsn('formular.validace.pouze-cislice'),
				},
			}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			jmeno: nameFormatting(data?.jmeno),
			prijmeni: nameFormatting(data?.prijmeni),
			datumNarozeni:
				data && data.datumNarozeni
					? toDateReadable(data.datumNarozeni) || ''
					: '',
			stat: undefined,
			popisReklamace: undefined,
			mistoNarozeni: data?.mistoNarozeni,
			adresaPobytu: data?.adresaPobytu?.adresaText,
			adresaDorucovaci:
				data?.adresaDorucovaci?.adresaText ??
				tsn('formular.adresa.nelze-reklamovat'),
			...getAddressSearchDefaultValues(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(
		newAddress?: string,
		newDeliveryAddress?: string,
		newPlaceOfBirth?: string
	) =>
	(data: FormData): DataRequest => {
		const seznam = [];
		if (data.jmeno) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Jmeno',
				zpusobNaplneni: data.jmeno,
			});
		}
		if (data.prijmeni) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Prijmeni',
				zpusobNaplneni: data.prijmeni,
			});
		}
		if (data.stat) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'MistoNarozeniSvetStat',
				zpusobNaplneni: data.stat,
			});
		}
		if (data.stat) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'MistoNarozeniSvetMisto',
				zpusobNaplneni: newPlaceOfBirth,
			});
		}
		if (data.adresaPobytu) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'AdresaPobytu',
				zpusobNaplneni: newAddress,
			});
		}
		if (data.adresaDorucovaci) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'DorucovaciAdresa',
				zpusobNaplneni: newDeliveryAddress,
			});
		}
		return {
			params: {},
			body: {
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
