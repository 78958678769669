import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	DokladyDokladDto,
	DokladyDokladSeznamDto,
	OsobySouhlasDto,
	OsobySouhlasSeznamDto,
	consentPreviewsQuery,
	identityDocumentQuery,
	sendSubmissionQuery,
	usePoMutation,
	usePoQueries,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable, is, compose } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { ConsentRequest } from '../detail/context.types';
import { prepareSubmitData } from '../detail/utils';
import {
	ConsentManagementContextTypes,
	ConsentManagementControls,
} from './context.types';

const SpravaSouhlasuSeznamContext =
	createContext<Nullable<ConsentManagementContextTypes>>(null);

interface SpravaSouhlasuContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function ConsentManagementSeznamContextProvider({
	children,
	code,
}: SpravaSouhlasuContextProviderProps) {
	const { controls, setControls } =
		useProcessControl<ConsentManagementControls>({
			initialLoading: true,
			consentLoadingId: null,
		});
	const { toastMessage } = useMessageEvents();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const [consentData, setConsentData] = useState<OsobySouhlasDto[]>([]);
	const [idDocuments, setIdDocuments] = useState<DokladyDokladDto[]>([]);

	const consentQuerySetup = {
		queryKey: ['souhlasy'],
		queryFn: consentPreviewsQuery,
	};

	const consentQuery = usePoQuery({
		...consentQuerySetup,
		onSuccess: (data) => setConsentData(data.seznam || []),
	});

	const mainQueries = usePoQueries<
		[
			UseQueryResult<OsobySouhlasSeznamDto, AxiosError<AxiosResponse>>,
			UseQueryResult<DokladyDokladSeznamDto, AxiosError<AxiosResponse>>
		]
	>({
		queries: [
			{
				...consentQuerySetup,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
			},
			{
				queryKey: ['doklady'],
				queryFn: identityDocumentQuery,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
				refetchOnWindowFocus: false,
				retry: 0,
			},
		],
	});

	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);

	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const consents = queriesResponses[0]
				? (queriesResponses[0] as OsobySouhlasSeznamDto)
				: null;
			const identityDocuments = queriesResponses[1]
				? (queriesResponses[1] as AxiosResponse<DokladyDokladSeznamDto>)
				: null;

			setConsentData(consents?.seznam || []);
			setIdDocuments(identityDocuments?.data.seznam || []);
			setControls({ initialLoading: false });
		}
	}, [isQueriesFetched]);

	const reloadConsents = useCallback(async () => {
		await consentQuery.refetch();
		setControls({ processLoading: false, consentLoadingId: null });
		toastMessage({
			options: {
				variant: 'success',
				type: 'solid',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
			content: tsn('formular.zprava.souhlas-odvolan'),
		});
	}, [consentQuery, setControls, toastMessage, tsn]);

	const submitMutation = usePoMutation<
		AxiosResponse<number, void>,
		ConsentRequest
	>({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			toastMessage({
				options: {
					variant: 'error',
					type: 'solid',
					icon: {
						name: 'exclamation-triangle-fill',
						type: 'basic',
					},
				},
				content: tsn('formular.zprava.chyba'),
			});
			setControls({
				processError: error,
				processLoading: false,
				consentLoadingId: null,
			});
		},
		onSuccess: async () => {
			reloadConsents();
		},
	});

	const handleSubmit = useCallback(
		async (consent: OsobySouhlasDto) => {
			setControls({
				processError: null,
				processLoading: true,
				consentLoadingId: consent.id,
			});
			submitMutation.mutate({
				kodPodani: 'ZR10_ODVOLANI',
				souhlasId: consent.id,
				nazevOsoby: consent.nazevOsoby,
				datovaSchrankaId: consent.datovaSchrankaId,
				typCisloID: idDocuments[0].cisloDokladu,
			});
		},
		[idDocuments, setControls, submitMutation]
	);

	return (
		<SpravaSouhlasuSeznamContext.Provider
			value={{
				controls,
				consentData,
				handleSubmit,
			}}>
			{children}
		</SpravaSouhlasuSeznamContext.Provider>
	);
}

export const useSpravaSouhlasuSeznamContext =
	(): ConsentManagementContextTypes =>
		useContext(SpravaSouhlasuSeznamContext) as ConsentManagementContextTypes;
