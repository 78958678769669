import { useState } from 'react';
import {
	notificationsDetailQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';
import { NotificationDto } from '@gov-nx/module/page';
import { extractLink, extractPoLinkAttributes } from '@gov-nx/utils/common';
import { useAppLanguage } from '../Localization/useAppLanguage';

export const useNotificationDetail = () => {
	const [selectedId, setSelectedId] = useState<bigint>();
	const { language } = useAppLanguage();

	const detailQuery = usePoQuery({
		queryKey: ['upozorneni', selectedId?.toString()],
		queryFn: async () => selectedId && notificationsDetailQuery(selectedId),
		enabled: !!selectedId,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const detail = detailQuery.data as Optional<NotificationDto>;

	const modalLabel = detail?.popisKratky ?? detail?.popisDlouhy ?? '';
	const poLinks = extractPoLinkAttributes(detail?.popisDlouhy, language);
	const { links } = extractLink(detail?.popisDlouhy);

	return {
		isFetching: detailQuery.isFetching,
		detail,
		select: setSelectedId,
		unselect: () => setSelectedId(undefined),
		id: selectedId,
		modalLabel,
		links,
		poLinks,
	};
};
