import { useEffect } from 'react';
import {
	authEvents,
	authLogoutSubscription,
	AuthSubscriptionTypes,
	systemPinMissingSubscription,
	userInactiveSubscription,
} from './authEvents';
import {
	AuthLogoutSubscription,
	SystemPinMissingSubscription,
} from './authEvents.types';

interface AuthEventsProps {
	onUserInactive?: () => void;
	onSystemPinMissing?: (action: SystemPinMissingSubscription['type']) => void;
	onUserLogout?: (
		action: AuthLogoutSubscription['type'],
		payload?: AuthLogoutSubscription['payload']
	) => void;
}

export const useAuthEvents = ({
	onUserInactive,
	onSystemPinMissing,
	onUserLogout,
}: AuthEventsProps = {}) => {
	useEffect(() => {
		const userInactiveSub =
			onUserInactive &&
			authEvents.subscribe(AuthSubscriptionTypes.userInactive, onUserInactive);
		const logoutSub =
			onUserLogout &&
			authEvents.subscribe(AuthSubscriptionTypes.logout, onUserLogout);
		const systemPinMissingSub =
			onSystemPinMissing &&
			authEvents.subscribe(
				AuthSubscriptionTypes.systemPinMissing,
				onSystemPinMissing
			);

		return () => {
			userInactiveSub?.unSubscribe();
			logoutSub?.unSubscribe();
			systemPinMissingSub?.unSubscribe();
		};
	}, [onUserLogout, onUserInactive, onSystemPinMissing]);

	const userInactive = () => {
		authEvents.publish(userInactiveSubscription());
	};

	const systemPinMissing = () => {
		authEvents.publish(systemPinMissingSubscription());
	};

	const userLogout = (payload?: AuthLogoutSubscription['payload']) => {
		authEvents.publish(authLogoutSubscription(payload));
	};

	return { userInactive, userLogout, systemPinMissing };
};
