import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScroll } from './useScroll';

export function ScrollToTopHelper() {
	const { scrollToTop } = useScroll();
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(scrollToTop, 100);
	}, [pathname]);

	return null;
}
