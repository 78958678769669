import { routeResolver } from '@gov-nx/web';
import { useParams } from 'react-router-dom';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxItemNavigationItem } from './DataBoxItemNavigationItem';

interface DataBoxItemNavigationProps {
	dataBox: DatovkaApiType;
	unreadMessagesCount: Nullable<number>;
}

export const DataBoxItemNavigation = ({
	dataBox,
	unreadMessagesCount,
}: DataBoxItemNavigationProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { dataBoxId, folderType } = useParams();

	return (
		<nav aria-label={tn('slozky.slozky')}>
			<ul className={'flex flex-col gap-2 gov-list--plain px-2'}>
				{!dataBox.odpojeno && (
					<>
						<DataBoxItemNavigationItem
							active={
								dataBoxId === dataBox.datovaSchrankaId &&
								folderType === DataBoxFolderType.Received
							}
							label={tn('slozky.dorucene')}
							url={routeResolver(PageCode['datove-schranky-seznam'], {
								dataBoxId: dataBox.datovaSchrankaId,
								folderType: DataBoxFolderType.Received,
							})}
							unreadMessagesCount={unreadMessagesCount}
							icons={{ default: 'envelope-fill', active: 'envelope' }}
						/>

						<DataBoxItemNavigationItem
							active={
								dataBoxId === dataBox.datovaSchrankaId &&
								folderType === DataBoxFolderType.Sent
							}
							label={tn('slozky.odeslane')}
							url={routeResolver(PageCode['datove-schranky-seznam'], {
								dataBoxId: dataBox.datovaSchrankaId,
								folderType: DataBoxFolderType.Sent,
							})}
							icons={{ default: 'send-fill', active: 'send' }}
						/>
					</>
				)}

				<DataBoxItemNavigationItem
					active={
						dataBoxId === dataBox.datovaSchrankaId &&
						folderType === DataBoxFolderType.Archived
					}
					label={tn('slozky.archiv')}
					url={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: dataBox.datovaSchrankaId,
						folderType: DataBoxFolderType.Archived,
					})}
					icons={{ default: 'archive-fill', active: 'archive-outline' }}
				/>

				<DataBoxItemNavigationItem
					active={
						dataBoxId === dataBox.datovaSchrankaId &&
						folderType === DataBoxFolderType.Trash
					}
					label={tn('slozky.kos')}
					url={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: dataBox.datovaSchrankaId,
						folderType: DataBoxFolderType.Trash,
					})}
					icons={{ default: 'trash-fill', active: 'trash' }}
				/>
			</ul>
		</nav>
	);
};
