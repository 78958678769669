import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, ContentLayout, LayoutGap } from '@gov-nx/ui/web';

interface EPetitionSubmitedStatusBannerProps {
	code: PageCode;
}

export const EPetitionSubmitedStatusBanner = ({
	code,
}: EPetitionSubmitedStatusBannerProps) => {
	const { t } = useTranslation(code);
	const { petition, controls, ePetitionSubmitClose, handleEPetitionDownload } =
		useEPeticeDetailContext();

	return (
		<ContentLayout>
			<LayoutGap gap={6}>
				<h2 className={'text-xl'}>{t('pole.zpusob.predani-vyberte')}</h2>
				<div className="flex flex-col gap-4 mt-2 [ md:flex-row ]">
					<GovIcon
						name="datovka"
						className={'w-10 h-10 grow-0 shrink-0 text-warning-400'}
					/>
					<div className="flex flex-col">
						<h3 className={'mb-1 text-l font-medium'}>
							{t('pole.zpusob-podani-datovka')}
						</h3>
						<p className={'text-secondary-700'}>
							{t('pole.podani-adresat')}:{' '}
							<strong>{petition?.adresat?.nazev}</strong> (
							{petition?.adresat?.datovaSchrankaId})
						</p>
						<p className={'text-secondary-700'}>{t('pole.datovka-popis')}</p>
					</div>
					<Button
						disabled={ePetitionSubmitClose.isSubmited}
						variant="primary"
						type="solid"
						size="s"
						className={'[ md:ml-auto md:mt-2 ]'}
						wcagLabel={t('akce.odeslano')}>
						<GovIcon
							name="send"
							slot="left-icon"
						/>
						{t('akce.odeslano')}
					</Button>
				</div>
				<div className="flex flex-col gap-4 [ md:flex-row ]">
					<GovIcon
						name="journal-text"
						className={'w-10 h-10 grow-0 shrink-0 text-primary-600 [ md:mt-2 ]'}
					/>
					<div className="flex flex-col">
						<h3 className={'mb-1 text-l font-medium'}>
							{t('pole.zpusob-podani-osobne')}
						</h3>
						<p className={'text-secondary-700'}>{t('pole.osobne-popis')}</p>
					</div>
					<Button
						onClick={() => handleEPetitionDownload()}
						loading={controls.isDownloadProcessing ?? false}
						disabled={controls.isDownloadProcessing ?? false}
						variant="primary"
						type="solid"
						size="s"
						className={'[ md:ml-auto md:mt-2 ]'}
						wcagLabel={t('akce.stahnout')}>
						<GovIcon
							name="download"
							slot="left-icon"
						/>
						{t('akce.stahnout')}
					</Button>
				</div>
			</LayoutGap>
		</ContentLayout>
	);
};
