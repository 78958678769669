import {
	GovChip,
	GovEmpty,
	GovIcon,
	GovPagination,
} from '@gov-design-system-ce/react';
import { routeResolver, RouteType, useScroll } from '@gov-nx/web';
import React from 'react';
import { Link } from 'react-router-dom';
import { CmsSignpost } from '@gov-nx/api/common';
import { parseIcon } from '@gov-nx/component/common';
import { useCmsEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { isNumber } from '@gov-nx/core/types';
import { PageCode, useRozcestnikContext } from '@gov-nx/module/page';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { BadgeNav, ContentLayout, HeaderLayout } from '@gov-nx/ui/web';
import { FavoriteToggle } from '../../Favorite/FavoriteToggle';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';
import { SignpostSearch } from './SignpostSearch';

export interface SignpostViewProps {
	code: PageCode;
}

export const SignpostView = ({ code }: SignpostViewProps) => {
	const { scrollToTop } = useScroll();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(PageCode['rozcestnik']);
	const { getBaseCmsUrlPath } = useConfigurationStore();
	const urlCmsPath = getBaseCmsUrlPath();
	const {
		data,
		groups,
		filter,
		numberOfSignposts,
		favorites,
		goToPage,
		setFilterFavourites,
		setFilterAll,
		setFilterActiveGroup,
	} = useRozcestnikContext();
	const { cmsExternalServiceModal } = useCmsEvents();
	const linkResolver = (signpost: CmsSignpost) => {
		if (signpost.pageCode) {
			return routeResolver(signpost.pageCode, null, RouteType.PAGE);
		} else if (signpost.serviceCode) {
			return routeResolver(signpost.serviceCode, null, RouteType.SERVICE);
		}
		return '#';
	};

	const actionResolver = (signpost: CmsSignpost, children: React.ReactNode) => {
		if (signpost.pageCode || signpost.serviceCode) {
			return (
				<Link
					to={linkResolver(signpost)}
					className={
						'flex gap-2 justify-between items-start w-full no-underline'
					}>
					{children}
				</Link>
			);
		} else if (isNumber(signpost.externalServiceId)) {
			return (
				<button
					onClick={() =>
						cmsExternalServiceModal({
							id: signpost.externalServiceId as number,
						})
					}
					className={
						'flex gap-2 justify-between items-start w-full no-underline'
					}>
					{children}
				</button>
			);
		}
		return null;
	};
	return (
		<ContentLayout customClasses={'px-0 mx-0'}>
			<HeaderLayout className={'!block'}>
				<SignpostSearch code={code} />
			</HeaderLayout>
			<div className={'my-4'}>
				<BadgeNav
					moreBadges={true}
					wcagLabel={tsn('filtr.kategorie')}
					customClasses={'pt-1 pl-1'}>
					<li>
						<GovChip
							variant={'primary'}
							onGov-click={() => setFilterAll()}
							tag={'button'}
							size="m"
							inverse={
								!(filter.favourites === false && filter.serviceGroups === null)
							}
							wcagLabel={tsn('filtr.wcag.vse')}>
							<span>{tsn('filtr.vse')}</span>
						</GovChip>
					</li>
					<li>
						<GovChip
							variant={'primary'}
							onGov-click={() => setFilterFavourites()}
							tag={'button'}
							size="m"
							identifier={'favorites-toggle'}
							iconLeft={favorites.length ? 'basic/star-fill' : 'basic/star'}
							inverse={!filter.favourites}
							wcagLabel={tsn('filtr.wcag.oblibene')}>
							<span>{tsn('filtr.oblibene')}</span>
						</GovChip>
					</li>
					{groups.map((group) => {
						return (
							<li key={group.label}>
								<GovChip
									variant={'primary'}
									onGov-click={() => setFilterActiveGroup(group.id)}
									tag={'button'}
									size="m"
									inverse={filter.serviceGroups !== group.id}
									wcagLabel={group.label}>
									{group.label}
								</GovChip>
							</li>
						);
					})}
				</BadgeNav>
			</div>
			<section>
				{data.length ? (
					<ul className={'gov-list--plain -ml-8 -mr-8 px-4'}>
						{data.map((signpost) => {
							const code =
								signpost.pageCode ||
								signpost.serviceCode ||
								signpost.externalServiceCode ||
								null;
							const parsedIcon = signpost.icon?.icon
								? parseIcon(signpost.icon.icon)
								: null;
							if (!signpost.name) return null;
							return (
								<li key={signpost.id}>
									<div
										className={
											'p-4 transition-colors duration-200 ease-in relative [ hover:bg-primary-200 ][ md:p-6 ]'
										}>
										{actionResolver(
											signpost,
											<>
												<div
													className={
														'hidden [ md:block md:grow-0 md:shrink-0 md:basis-10 ]'
													}>
													{signpost.icon?.file ? (
														<img
															src={urlCmsPath + signpost.icon?.file}
															alt=""
															className={'[ md:w-10 ]'}
														/>
													) : (
														<GovIcon
															name={parsedIcon?.name}
															type={parsedIcon?.type}
															slot="icon"
															className={
																'!text-primary-600 [ md:w-10 ]'
															}></GovIcon>
													)}
												</div>
												<div
													className={
														'grow gap-2 flex flex-col justify-start text-left pr-4 [ md:ml-4 ]'
													}>
													<h3
														className={
															'text-l text-primary-600 [ md:text-xl ]'
														}>
														{signpost.name}
													</h3>
													{signpost.description ? (
														<div className={'text-secondary-700'}>
															<PoMarkdownRenderWeb
																className={'signpost-description'}>
																{signpost.description}
															</PoMarkdownRenderWeb>
														</div>
													) : null}
												</div>
												<div
													className={
														'grow-0 shrink-0 basis-5 mt-0.5 text-center'
													}>
													<GovIcon
														name="chevron-right"
														type="basic"
														slot="icon"
														className={'w-3 text-primary-600'}></GovIcon>
												</div>
											</>
										)}
										{code ? (
											<div
												className={
													'absolute top-14 right-1.5 z-30 [ md:top-16 md:right-3.5 ]'
												}>
												<FavoriteToggle
													size={'s'}
													code={code}
													name={signpost.name}
												/>
											</div>
										) : null}
									</div>
								</li>
							);
						})}
					</ul>
				) : (
					<GovEmpty className={'text-center'}>
						<p className={'gov-text--2xl'}>{tsn('chyba.nic-jsme-nenasli')}</p>
						<p className={'gov-text--m gov-color--secondary-700'}>
							{tsn('chyba.nepodarilo-se-najit-zadnou-sluzbu')}
						</p>
						<GovIcon
							type="complex"
							name="cactus"
							slot="icon"></GovIcon>
					</GovEmpty>
				)}
			</section>
			{numberOfSignposts > filter.perPage ? (
				<footer className={'mt-6'}>
					<GovPagination
						pageSize={filter.perPage}
						current={filter.page}
						onGov-page={(event) => {
							scrollToTop();
							goToPage(event.detail.pagination.currentPage);
						}}
						wcag-label={tsn('wcag.strankovani')}
						total={numberOfSignposts}></GovPagination>
				</footer>
			) : null}
		</ContentLayout>
	);
};
