import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export interface CustomElementTriggerAliasProps {
	enforceLink?: boolean;
}

export const useCustomElementTriggerAlias = (
	props?: CustomElementTriggerAliasProps
) => {
	const ref = useRef<any>();
	const navigate = useNavigate();
	useEffect(() => {
		if (props?.enforceLink) {
			return;
		}
		const customElement: {
			getTriggerRef: () => HTMLLinkElement | HTMLButtonElement;
		} = ref.current as any;
		if (customElement) {
			setTimeout(async () => {
				const link = await (customElement?.getTriggerRef
					? customElement?.getTriggerRef()
					: undefined);
				if (link && link.nodeName === 'A') {
					link.addEventListener('click', (e) => {
						e.preventDefault();
						e.stopPropagation();
						const target = link as HTMLLinkElement;
						const url = new URL(target.href);
						navigate(url.pathname);
					});
				}
			}, 100);
		}
	}, [ref.current]);

	return { ref };
};
