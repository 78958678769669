import * as yup from 'yup';
import {
	addressLookupQuery,
	PeticeAdresaDto,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '../definitions/codes';

export interface AddressLookupProps {
	code: PageCode;
	onLookUpSuccess?: (data: PeticeAdresaDto[]) => void;
	onSubmit?: (data: {
		formData: AddressLookupFormData;
		address?: PeticeAdresaDto;
	}) => void;
}

export interface AddressLookupFormData {
	_address: string;
	nazevObce: string;
	nazevUlice: string;
	cislo: string;
}

export const useAddressLookup = ({
	code,
	onLookUpSuccess,
	onSubmit,
}: AddressLookupProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<AddressLookupFormData>>({
			cislo: yup.string().required(tsn('formular.validace.cislo.povinny')),
			nazevObce: yup
				.string()
				.required(tsn('formular.validace.nazev-obce.povinny')),
			nazevUlice: yup.string(),
			_address: yup.string(),
		})
		.required();

	const formMethods = usePoForm<AddressLookupFormData>({
		formSchema,
		defaultValues: {
			_address: undefined,
			cislo: undefined,
			nazevObce: undefined,
			nazevUlice: undefined,
		},
	});

	const lookupMutation = usePoMutation({
		mutationFn: (
			params: Pick<AddressLookupFormData, 'nazevObce' | 'nazevUlice' | 'cislo'>
		) => addressLookupQuery(params),
		onSuccess: (data) => onLookUpSuccess && onLookUpSuccess(data),
	});

	const formDefinition = getFormDefinition({ formMethods, formSchema });

	return {
		lookupMutation,
		onLookup: () =>
			lookupMutation.mutate(formDefinition.formMethods.getValues()),
		onSubmit: formMethods.handleSubmit((formData) => {
			const address = lookupMutation.data?.find(
				(address) => address.kod === formData._address
			);

			formDefinition.formReset();
			lookupMutation.reset();

			return onSubmit && onSubmit({ formData, address });
		}),
		results: lookupMutation.data,
		error: lookupMutation.error,
		isLoading: lookupMutation.isLoading,
		formDefinition,
	};
};
