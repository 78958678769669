import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { InfoBanner } from '@gov-nx/ui/web';
import { TableHead } from './TableHead';
import { TableRowNoFile } from './TableRowNoFile';

export const TableNoFile = () => {
	const { documents, code } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<table className={'w-full table-fixed'}>
			{documents?.seznam && documents.seznam.length > 0 ? (
				<TableHead />
			) : (
				<InfoBanner
					icon={{ name: 'cactus', type: 'colored' }}
					type="secondary">
					<p className={'text-2xl [ md:text-3xl ]'}>
						{tsn('stav.chyba.zatim-tu-nic-nemame')}
					</p>
					<p>{tsn('stav.chyba.nenasli-jsme-zadne-soubory')}</p>
				</InfoBanner>
			)}
			<tbody>
				{documents?.seznam?.map((document) => (
					<TableRowNoFile
						key={document.dokumentId}
						document={document}
					/>
				))}
			</tbody>
		</table>
	);
};
