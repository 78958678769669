import * as yup from 'yup';
import { useContactDetailsFormDefinition } from '@gov-nx/core/hooks';
import {
	FormSchemaShape,
	getFormDefinition,
	useLocale,
	usePoForm,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { FormDataStep2b } from './context.types';
import { IFormStep2b } from './form.types';

interface FormStep2Props {
	code: ServiceCode;
	isProcessing: boolean;
}

export const FormStep2b = ({
	code,
	isProcessing,
}: FormStep2Props): IFormStep2b => {
	const { t } = useLocale(code);
	const { shape, defaultValues, fields } = useContactDetailsFormDefinition();

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep2b>>(shape)
		.required();

	const formMethods = usePoForm<FormDataStep2b>({
		formSchema,
		defaultValues,
	});

	return {
		fields: fields(isProcessing, {
			email: {
				label: t('formular.pole.kontakt-email'),
				placeholder: t('formular.placeholder.email'),
			},
			phone: {
				label: t('formular.pole.kontakt-telefon'),
				placeholder: t('formular.placeholder.telefon'),
			},
		}),
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
};
