// internal settings PageCodes to be used only for native navigation via useAppNavigation/useNavigationEvents

export enum PageCode {
	'dashboard' = 'dashboard',
	'prihlaseni' = 'prihlaseni',
	'odhlaseni' = 'odhlaseni',
	'sluzba' = 'sluzba',
	'nastaveni' = 'nastaveni',
	'nastaveni-kontakt' = 'nastaveni-kontakt',
	'nastaveni-datovky' = 'nastaveni-datovky',
	'nastaveni-upozorneni' = 'nastaveni-upozorneni',
	'registr-ZP' = 'registr-ZP',
	'cestovni-pas' = 'cestovni-pas',
	'obcansky-prukaz' = 'obcansky-prukaz',
	'ridicsky-prukaz' = 'ridicsky-prukaz',
	'vlastni-doklad' = 'vlastni-doklad',
	'doklady' = 'doklady',
	'profil-ROB' = 'profil-ROB',
	'profil-ROS' = 'profil-ROS',
	'profil-certifikaty' = 'profil-certifikaty',
	'profil-edoklady' = 'profil-edoklady',
	'drozd' = 'drozd',
	'drozd-registrations' = 'drozd-registrations',
	'drozd-registration-detail' = 'drozd-registration-detail',
	'drozd-countries' = 'drozd-countries',
	'drozd-insurance-companies' = 'drozd-insurance-companies',
	'drozd-add-registration' = 'drozd-add-registration',
	'moje-soubory' = 'moje-soubory',
	'podani' = 'podani',
	'epetice' = 'epetice',
	'epetice-detail' = 'epetice-detail',
	'epetice-detail-editace' = 'epetice-detail-editace',
	'epetice-nova' = 'epetice-nova',
	'moje-podani' = 'moje-podani',
	'moje-podani-detail' = 'moje-podani-detail',
	'zdravotnicka-dokumentace' = 'zdravotnicka-dokumentace',
	'datove-schranky' = 'datove-schranky',
	'datove-schranky-seznam' = 'datove-schranky-seznam',
	'datove-schranky-zprava' = 'datove-schranky-zprava',
	'datove-schranky-odpoved' = 'datove-schranky-odpoved',
	'datove-schranky-nova' = 'datove-schranky-nova',
	'datove-schranky-pripojeni' = 'datove-schranky-pripojeni',
	'podpora' = 'podpora',
	'muj-katastr-nemovitosti' = 'muj-katastr-nemovitosti',
	'registr-SV' = 'registr-silnicnich-vozidel',
	'registr-SV-detail' = 'registr-silnicnich-vozidel-detail',
	'registr-ridicu' = 'registr-ridicu',
	'upozorneni' = 'upozorneni',
	'kde-o-mne-stat-vede-udaje' = 'kde-o-mne-stat-vede-udaje',
	'rozcestnik' = 'rozcestnik',
	'sprava-souhlasu' = 'sprava-souhlasu',
	'sprava-souhlasu-detail' = 'sprava-souhlasu-detail',
	'uzivatelska-prirucka' = 'uzivatelska-prirucka',
}
