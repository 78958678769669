import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	checkFileQuery,
	uploadFileQuery,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { propEq, Time } from '@gov-nx/core/types';
import { MAX_NORMAL_FILE_SIZE } from '@gov-nx/module/data-box';
import { isAllUploaded } from '@gov-nx/utils/common';
import {
	FileState,
	FileUpload,
	FileUploadItem,
	FileUploadProps,
} from './fileUpload.types';
import { useFileUtils } from './fileUploadUtils';

export const hasFileId = (
	file: FileUploadItem
): file is FileUploadItem & Required<Pick<FileUploadItem, 'fileId'>> =>
	file.fileId !== undefined;

export const hasNoSuccessFile = (files: FileUploadItem[]) =>
	!!files.find((file) => file.state !== FileState.success);

export const useFileUpload = (props: FileUploadProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const [files, setFiles] = useState<FileUploadItem[]>(
		props.initialFiles ?? []
	);

	const { validate, toFileUploadItem } = useFileUtils(props);

	useEffect(() => {
		if (files.length && props.onFilesChanged) {
			props.onFilesChanged(files, isAllUploaded(files));
		}
	}, [files]);

	usePoQueries({
		queries: files.filter(hasFileId).map((file) => ({
			queryKey: ['file-check', file.id],
			refetchInterval: (file.state === FileState.validating
				? Time['2 seconds']
				: false) as number | false,
			enabled: file.state === FileState.validating,
			queryFn: async () => {
				const response = await checkFileQuery(file.fileId);
				if (response.stavKontroly === 'OK') {
					const updated = markFileOK(file);
					await finalFileUpdate(updated);
				} else if (response.stavKontroly === 'INFIKOVANY') {
					const updated = markFileHasVirus(file);
					await finalFileUpdate(updated);
				}
				return response;
			},
			onError: () =>
				finalFileUpdate({
					...file,
					state: FileState.error,
					showLargeFileLoader: false,
					message: t('nahrani-souboru.status.chyba-antivirus'),
				}),
		})),
	});

	usePoQueries({
		queries: files.map((file) => ({
			queryKey: ['file-upload', file.id],
			enabled: file.state === FileState.uploading,
			queryFn: async () => {
				const response = await uploadFileQuery(file);
				const updated = markFileUploaded(response, file);
				updateState(updated);

				return response;
			},
			onError: () =>
				finalFileUpdate({
					...file,
					state: FileState.error,
					showLargeFileLoader: false,
					message: t('nahrani-souboru.status.chyba-nahravani'),
				}),
		})),
	});

	const updateState = (updated: FileUploadItem) => {
		setFiles((files) =>
			files.map((file) => (file.id === updated.id ? updated : file))
		);
	};

	const finalFileUpdate = async (file: FileUploadItem) => {
		const updated = files.map((item) => (item.id === file.id ? file : item));
		if (props.onAllFilesUploaded && isAllUploaded(updated)) {
			const uploaded = await props.onAllFilesUploaded(updated);
			setFiles(uploaded);
		} else {
			updateState(file);
		}
	};

	const markFileOK = (file: FileUploadItem): FileUploadItem => {
		return {
			...file,
			state: FileState.success,
			message: t('nahrani-souboru.status.provereno-anitvirovou-ochranou'),
			showLargeFileLoader: false,
		};
	};

	const markFileHasVirus = (file: FileUploadItem): FileUploadItem => {
		return {
			...file,
			state: FileState.error,
			message: t('nahrani-souboru.status.soubor-infikovan'),
			showLargeFileLoader: false,
		};
	};

	const markFileUploaded = (
		response: AxiosResponse<string>,
		file: FileUploadItem
	): FileUploadItem => {
		const isLargeFile = file.size > MAX_NORMAL_FILE_SIZE;

		if (response.status === 200 && response.data) {
			return {
				...file,
				state: FileState.validating,
				message: isLargeFile
					? t('nahrani-souboru.status.probiha-kontrola-velkeho-souboru')
					: t('nahrani-souboru.status.probiha-kontrola'),
				showLargeFileLoader: isLargeFile,
				fileId: response.data,
			};
		}
		return {
			...file,
			state: FileState.error,
			showLargeFileLoader: false,
			message: t('nahrani-souboru.status.chyba-nahravani'),
		};
	};

	const addFiles = (newFiles: FileUpload[]) => {
		const newUploads = newFiles
			.filter((govFile) => !files.find((file) => file.id === govFile.id))
			.map(toFileUploadItem(files.length));

		setFiles([...files, ...newUploads].map(validate));
	};

	const removeFile = (id: string) => {
		setFiles((fileUploads) =>
			fileUploads.filter((file) => file.id !== id).map(validate)
		);

		if (props.onFileRemoved) {
			const existing = files.find(propEq('id', id));

			existing && props.onFileRemoved(existing);
		}
	};

	return {
		files,
		addFiles,
		removeFile,
		setFiles,
	};
};
