import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';

export const SelectAll = () => {
	const { code, myFilesSelection } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<th
			className={
				'w-24 min-w-[96px] pl-1 text-right font-normal text-primary-600 [ lg:w-24 ]'
			}>
			<Link
				to=""
				onClick={() => myFilesSelection.onSelectAll()}
				className={'text-primary-600 underline whitespace-nowrap'}>
				{tsn('vyber.vybrat-vse')}
			</Link>
			{myFilesSelection.selectedIds.length > 0 && (
				<div className={'leading-4'}>
					<Link
						to=""
						onClick={() => myFilesSelection.resetSelectedIds()}
						className={'text-primary-600 underline'}>
						{tsn('vyber.zrusit-vyber')}
					</Link>
				</div>
			)}
		</th>
	);
};
