import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
	DatovkaNastaveniArchivaceValue,
	fetchSettingsQuery,
	saveSettingsQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';

interface ArchivingSettingsVariables {
	value: Nullable<string>;
}
export const useArchivingSettingsQuery = (dataBoxId?: string) => {
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const queryClient = useQueryClient();

	const key = `AA_DS_${dataBoxId}`;
	const queryKey = ['setting-archiving', key];

	const query = usePoQuery({
		queryKey,
		enabled: !!dataBoxId,
		queryFn: () => fetchSettingsQuery<DatovkaNastaveniArchivaceValue>(key),
		refetchOnWindowFocus: false,
	});

	const mutation = usePoMutation({
		mutationFn: (variables: ArchivingSettingsVariables) =>
			saveSettingsQuery({
				klic: key,
				hodnota: variables.value,
			}),
		onSuccess: (response, variables) => {
			queryClient.setQueryData(queryKey, {
				klic: key,
				hodnota: variables.value,
			});
			toastMessageSuccess(t('ulozeno'));
		},
		onError: (error) => {
			toastMessageError(t('chyba-ulozeni'));
		},
	});

	return {
		isLoading: query.isLoading,
		data: query.data,

		mutateAsync: mutation.mutateAsync,
		mutate: mutation.mutate,
		isMutating: mutation.isLoading,
	};
};

interface NotificationSettingsVariables {
	value: Nullable<string>;
}
export const useNotificationSettingsQuery = (dataBoxId?: string) => {
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const queryClient = useQueryClient();

	const key = `N_DS_${dataBoxId}`;
	const queryKey = ['setting-notifications', key];

	const query = usePoQuery({
		queryKey,
		enabled: !!dataBoxId,
		queryFn: () => {
			return fetchSettingsQuery<string>(key);
		},
		refetchOnWindowFocus: false,
	});

	const mutation = usePoMutation({
		mutationFn: (variables: NotificationSettingsVariables) =>
			saveSettingsQuery({
				klic: key,
				hodnota: variables.value,
			}),
		onSuccess: (response, variables) => {
			queryClient.setQueryData(queryKey, {
				klic: key,
				hodnota: variables.value,
			});
			toastMessageSuccess(t('ulozeno'));
		},
		onError: (error) => {
			toastMessageError(t('chyba-ulozeni'));
		},
	});

	return {
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		data: query.data,
		mutateAsync: mutation.mutateAsync,
		mutate: mutation.mutate,
		isMutating: mutation.isLoading,
	};
};
