import { GovPagination } from '@gov-design-system-ce/react';
import { useScroll } from '@gov-nx/web';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, FormCheckbox, Modal, Space } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const DocumentsModal = () => {
	const {
		documents,
		pageDocuments,
		pager,
		selectedDocuments,
		showDocumentsModal,
		goToPage,
		onDocumentsConfirm,
		onDocumentsModalClose,
		addDocument,
		removeDocument,
	} = useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { scrollToTop } = useScroll();

	return (
		<Modal
			label={tn('nova-zprava.dokumenty.vybrat-z-mych-souboru')}
			open={showDocumentsModal}
			onGov-close={onDocumentsModalClose}>
			<ul className={'gov-list--plain flex flex-col gap-6'}>
				{pageDocuments?.map((document) => (
					<li
						key={document.dokumentId}
						className={'flex flex-col gap-1 [ md:flex-row md:gap-4 ]'}>
						<FormCheckbox
							field={{
								name: 'document' + document.dokumentId,
								checked: selectedDocuments.includes(
									document.dokumentId as number
								),
								label: {
									children: (
										<span>
											<strong className={'break-all'}>{document.nazev}</strong>
											<Space />
											<time
												className={'text-s whitespace-nowrap ml-9 [ md:ml-0 ]'}>
												{document.datumVytvoreni
													? toDateReadable(document.datumVytvoreni)
													: null}
											</time>
										</span>
									),
								},
								size: 's',
								// @TODO: Checkbox changes should be handled in context with formMethods.watch('document')
								onChange: (event) =>
									event.target.checked
										? addDocument(document.dokumentId as number)
										: removeDocument(document.dokumentId as number, true),
							}}
						/>
					</li>
				))}
			</ul>
			<div className="flex justify-center">
				{documents.length > pager.perPage ? (
					<div className={'mt-6'}>
						<GovPagination
							pageSize={pager.perPage}
							current={pager.page}
							onGov-page={(event) => {
								scrollToTop();
								goToPage(event.detail.pagination.currentPage);
							}}
							wcag-label={tn('wcag.strankovani')}
							total={documents.length}></GovPagination>
					</div>
				) : null}
			</div>
			<div slot="footer">
				<Button
					nativeType="button"
					variant="primary"
					type="solid"
					disabled={selectedDocuments.length === 0}
					onClick={onDocumentsConfirm}
					wcagLabel={tn('nova-zprava.dokumenty.prilozit-vybrane')}>
					{tn('nova-zprava.dokumenty.prilozit-vybrane')}
				</Button>
			</div>
		</Modal>
	);
};
