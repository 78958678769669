import { QueryClient } from '@tanstack/react-query';
import { Time } from '@gov-nx/core/types';

export const getQueryClient = () => {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: Time['5 minutes'],
			},
		},
	});
};
