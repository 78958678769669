import * as yup from 'yup';
import { SignpostRequestParams } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import { SignpostFilterForm, SignpostListFilter } from './context.types';

interface Props {
	search: Optional<string>;
}

export function FormInstance({
	search,
}: Props): FormDefinition<SignpostFilterForm> {
	const formSchema = yup
		.object({
			search: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<SignpostFilterForm>({
		formSchema,
		defaultValues: {
			search: search ?? undefined,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	filter: SignpostListFilter,
	search: Optional<string>,
	app: string,
	env: string
): SignpostRequestParams => {
	return {
		search: search && search.length > 2 ? search : undefined,
		serviceGroups: filter.serviceGroups ? filter.serviceGroups : undefined,
		app,
		env,
		locale: filter.language,
	};
};
