import cx from 'classnames';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { TableHeadBinned } from './TableHeadBinned';
import { TableRowBinned } from './TableRowBinned';

export const TableBinned = () => {
	const { documentsBinned } = useMojeSouborySeznamContext();

	return (
		<table
			className={cx(`w-full table-fixed sort-arrows`, `bin-table-responsive`)}>
			{documentsBinned?.seznam && documentsBinned?.seznam.length > 0 && (
				<TableHeadBinned />
			)}
			<tbody>
				{documentsBinned?.seznam?.map((document, index) => (
					<TableRowBinned
						key={document.id}
						document={document}
						index={index}
					/>
				))}
			</tbody>
		</table>
	);
};
