import { useQueryClient } from '@tanstack/react-query';
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	connectToAnotherSubmissionQuery,
	disconnectFromSubmissionQuery,
	isVoterRegistrationSubmission,
	MySubmission,
	mySubmissionDetailQuery,
	mySubmissionsQuery,
	MySubmissionVoterCardAplication,
	PodaniDatovaZpravaIdDto,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useDataBoxMessagesLoader,
	useDocumentsDownload,
	useDownloadBlob,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useLocale, useTranslationWithNamespace } from '@gov-nx/core/service';
import { compose, equals, has, is, Nullable, prop } from '@gov-nx/core/types';
import { pickupDates } from '@gov-nx/module/service';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { removeIdFromSubmissionName } from '../../epetice/seznam/utils';
import { FormInstance } from './FormDefinitions';
import {
	ConnectToAnotherSubmissionForm,
	DataMessage,
	MySubmissionDetailContext,
	MySubmissionMessage,
} from './context.types';

const MojePodaniDetailContext =
	createContext<Nullable<MySubmissionDetailContext>>(null);

interface MojePodaniDetailContextProviderProps {
	children: ReactNode;
	code: PageCode;
	submissionId: number;
}

export function MojePodaniDetailContextProvider({
	code,
	children,
	submissionId,
}: MojePodaniDetailContextProviderProps) {
	const { t } = useLocale(code);
	const { setControls, controls } = useProcessControl();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessageSuccess } = useMessageEvents();
	const queryClient = useQueryClient();
	const isDisconnected = useBoolean();
	const { handleDownloadBlob } = useDownloadBlob();
	const [messageReadyForConnection, setMessageReadyForConnection] =
		useState<DataMessage>();

	const connectToAnotherSubmissionForm = FormInstance();

	const disconnectMutation = usePoMutation({
		mutationFn: async (body: DataMessage) =>
			disconnectFromSubmissionQuery({
				submissionId,
				body,
			}),
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.odpojeno'));
			isDisconnected.setTrue();
			await queryClient.invalidateQueries({
				queryKey: ['my-submissions-list'],
			});
		},
	});

	const connectMutation = usePoMutation({
		mutationFn: async (
			body: Parameters<typeof connectToAnotherSubmissionQuery>[0]
		) => connectToAnotherSubmissionQuery(body),
		onSuccess: async (_, variables) => {
			toastMessageSuccess(tsn('formular.zprava.pripojeno'));
			setMessageReadyForConnection(undefined);
			isDisconnected.setFalse();
			await queryClient.invalidateQueries({
				queryKey: ['my-submission-detail', submissionId],
			});
			await queryClient.invalidateQueries({
				queryKey: [
					'my-submission-detail',
					parseInt(variables.submissionId, 10),
				],
			});
			await queryClient.invalidateQueries({
				queryKey: ['my-submissions-list'],
			});
		},
	});

	const submissionsQuery = usePoQuery({
		queryKey: ['my-submissions-list'],
		queryFn: mySubmissionsQuery,
		enabled: !!messageReadyForConnection,
	});

	useEffect(() => {
		if (submissionsQuery.data) {
			const [first] = submissionsQuery.data.seznam ?? [];
			if (first.id) {
				connectToAnotherSubmissionForm.formMethods.setValue(
					'submission',
					`${first.id}`
				);
			}
		}
	}, [submissionsQuery.data]);

	const submissionQuery = usePoQuery<MySubmission>({
		queryKey: ['my-submission-detail', submissionId],
		queryFn: () => mySubmissionDetailQuery(submissionId),
		onError: async (err) => {
			setControls({
				initialError: new GovError(err.message),
			});
		},
	});

	const isDataMessage = (
		message: PodaniDatovaZpravaIdDto
	): message is DataMessage =>
		!!(message.datovaSchrankaId && message.datovaSchrankaId);

	const dataMessages: DataMessage[] =
		submissionQuery.data &&
		Array.isArray(submissionQuery.data.datoveZpravy) &&
		submissionQuery.data.datoveZpravy.length
			? submissionQuery.data.datoveZpravy.filter(isDataMessage)
			: [];

	const messagesQuery = useDataBoxMessagesLoader({
		messageIds: dataMessages.map(prop('datovaZpravaId')).filter(is),
		onError: () => setControls({ initialLoading: false }),
	});

	const messages: MySubmissionMessage[] = dataMessages
		.map((dataMessage) => {
			const message = (messagesQuery.data ?? []).find(
				compose(equals(dataMessage.datovaZpravaId), prop('datovaZpravaId'))
			);
			if (!message) return null;

			return {
				message: message ?? null,
				isDeleted: !message,
				dataMessage,
			};
		})
		.filter(is);

	const isInitialLoading =
		submissionQuery.isLoading ||
		(dataMessages.length > 0 && messagesQuery.isLoading);

	const handleSubmit = useCallback(
		(formData: ConnectToAnotherSubmissionForm) => {
			if (messageReadyForConnection) {
				connectMutation.mutate({
					submissionId: formData.submission,
					body: messageReadyForConnection,
				});
			}
		},
		[messageReadyForConnection, connectMutation]
	);
	const { prepare } = useDocumentsDownload();
	const submission = submissionQuery.data;

	return (
		<MojePodaniDetailContext.Provider
			value={{
				code,
				controls: {
					...controls,
					initialLoading: isInitialLoading,
				},
				submission,
				messages,
				disconnectFromSubmission: disconnectMutation.mutate,
				connectToAnotherSubmission: {
					form: connectToAnotherSubmissionForm,
					submissionOptions: (submissionsQuery.data?.seznam ?? [])
						.filter(has('id'))
						.map((submission) => ({
							label: submission.nazev,
							value: submission.id,
						})),
					onSubmit:
						connectToAnotherSubmissionForm.formMethods.handleSubmit(
							handleSubmit
						),
					isOpen: !!messageReadyForConnection,
					isLoading: submissionsQuery.isLoading,
					isSubmitting: connectMutation.isLoading,
					isDisconnected: isDisconnected.value,
					onSelect: setMessageReadyForConnection,
					onClose: () => setMessageReadyForConnection(undefined),
				},
				powerOfAttorney: handleDownloadBlob(() => {
					const params =
						submissionQuery.data &&
						isVoterRegistrationSubmission(submissionQuery.data)
							? submissionQuery.data.parametry
							: undefined;

					return {
						body: {
							kodPodani: 'ZADOST_VOLEBNI_PRUKAZ_PLNA_MOC',
							kodVoleb: params?.kod,
							kola: params ? getElectionRounds(params) : undefined,
							zpusobPrevzeti: params?.zpusobPrevzeti,
						},
					};
				}),
				documents:
					submissionQuery.data?.dokumenty
						?.map(prop('dokumentId'))
						.filter(is)
						.map(prepare) ?? [],
				strings: {
					submission: {
						name: submission?.nazev
							? removeIdFromSubmissionName(submission.nazev)
							: undefined,
					},
					voterRegistration:
						submission && isVoterRegistrationSubmission(submission)
							? {
									infoMessage: t('volicsky-prukaz.info-o-volicim-prukazu', {
										termin: pickupDates(submission),
										adresaUradu: submission.parametry?.vyzvednutiNaUradu
											? submission.parametry?.vyzvednutiNaUradu
											: '&nbsp;',
									}),
							  }
							: undefined,
				},
			}}>
			{children}
		</MojePodaniDetailContext.Provider>
	);
}

export const useMojePodaniDetailContextInstance =
	(): MySubmissionDetailContext =>
		useContext(MojePodaniDetailContext) as MySubmissionDetailContext;

const getElectionRounds = (
	params: MySubmissionVoterCardAplication['parametry']
) => {
	if (params.kolo1zadost === 'true' && params.kolo2zadost === 'true') {
		return 'ALL';
	}
	if (params.kolo2zadost === 'true') {
		return 'II';
	}
	if (params.kolo1zadost === 'true') {
		return 'I';
	}
	return;
};
