import AsyncStorage from '@react-native-async-storage/async-storage';
import { createJSONStorage } from 'zustand/middleware';

export const PERSIST_STORE_KEY = 'portal-obcana:root';
export const PERSIST_ZUSTAND_STORE_KEY = 'portal-obcana-zustand';
export const PERSIST_ZUSTAND_STORE_VERSION = 1;

export enum StoreKeys {
	app = 'app',
	auth = 'auth',
	dataBoxSearch = 'dataBoxSearch',
	user = 'user',
	cms = 'cms',
	filters = 'filters',
}

export const storeGlobalConfig = {
	name: PERSIST_ZUSTAND_STORE_KEY,
	storage: createJSONStorage(() => AsyncStorage),
	version: PERSIST_ZUSTAND_STORE_VERSION,
	// onRehydrateStorage: () => (state: any, error: any) => {
	// 	console.log('rehydruju1: ', state);
	// },

	// Note: Potential migrations
	// migrate: (persistedState, version) => {
	// 	if (version === 0) {
	// 		return {
	// 			...persistedState,
	// 			// Zde můžete přidat logiku pro migraci stavu, pokud je potřeba
	// 		};
	// 	}
	// 	return persistedState;
	// },
};
