import { getEntries, isString } from '@gov-nx/core/types';
import { Configuration, ConfigurationRaw } from './configuration.types';

const toBool = <T>(value?: T): boolean => {
	const string = isString(value);
	return string ? value?.toLocaleLowerCase() === 'true' : false;
};

const toNumber = <T>(value?: T): number => {
	const string = isString(value);
	if (!string) return 0;
	try {
		return value ? parseInt(value, 10) : 0;
	} catch {
		return 0;
	}
};

export const prepareConfiguration = (
	configuration: ConfigurationRaw
): Configuration => {
	const configurationPrepared = getEntries(configuration).reduce(
		(all, current) => {
			const key = current?.[0];
			const value = current?.[1];

			if (!key) return all;

			if (value === 'null') {
				return {
					...all,
					[key]: null,
				};
			}
			switch (key) {
				case 'useDummyDataForEdocuments':
					return { ...all, [key]: toBool(value) };
				case 'datovaZpravaCena':
					return { ...all, [key]: toNumber(value) };
				case 'velkokapacitniZpravaCena':
					return { ...all, [key]: toNumber(value) };
				case 'zobrazitNastaveniPushNotifikaci':
					return {
						...all,
						[key]: toBool(value),
					};
				default:
					return { ...all, [key]: value };
			}
		},
		{} as Configuration
	);

	return configurationPrepared;
};
