import { differenceInCalendarDays, isBefore } from 'date-fns';
import {
	PeticePeticeDto,
	ePetitionCloseAndSubmitQuery,
	ePetitionCloseQuery,
	ePetitionSubmitQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { Nullable } from '@gov-nx/core/types';
import { EPetitionStatus } from '@gov-nx/module/page';
import { today } from '@gov-nx/utils/common';

export interface PetitionCloseSubmitProps {
	petition: Nullable<PeticePeticeDto>;
	onCloseSuccess: () => void;
	onCloseError: () => void;
	onSubmitAndClosedSuccess: () => void;
	onSubmitAndClosedError: () => void;
	onSubmitSuccess: () => void;
	onSubmitError: () => void;
}

export const usePetitionCloseSubmit = ({
	petition,
	onCloseSuccess,
	onCloseError,
	onSubmitAndClosedSuccess,
	onSubmitAndClosedError,
	onSubmitSuccess,
	onSubmitError,
}: PetitionCloseSubmitProps) => {
	const { isDataBoxLoginType } = useIdentity();

	const closeQuery = usePoMutation({
		mutationKey: ['e-petition-close', petition?.id],
		mutationFn: ePetitionCloseQuery,
		onSuccess: () => onCloseSuccess && onCloseSuccess(),
		onError: () => onCloseError && onCloseError(),
	});

	const submitAndCloseQuery = usePoMutation({
		mutationKey: ['e-petition-close-submit', petition?.id],
		mutationFn: ePetitionCloseAndSubmitQuery,
		onSuccess: () => onSubmitAndClosedSuccess && onSubmitAndClosedSuccess(),
		onError: () => onSubmitAndClosedError && onSubmitAndClosedError(),
	});

	const submitQuery = usePoMutation({
		mutationKey: ['e-petition-submit', petition?.id],
		mutationFn: ePetitionSubmitQuery,
		onSuccess: () => onSubmitSuccess && onSubmitSuccess(),
		onError: () => onSubmitError && onSubmitError(),
	});

	const date = new Date();
	const submitDate = petition?.datumPodaniDo;

	const isBeforeSubmitDate = (): boolean => {
		if (submitDate && isBefore(date, today(submitDate))) return true;
		return false;
	};

	const dayLeftUntilDeadline = (): Nullable<number> => {
		if (isBeforeSubmitDate()) {
			return Math.abs(differenceInCalendarDays(date, today(submitDate)));
		}
		return null;
	};

	const isPublic = petition?.stav === EPetitionStatus.zverejnena;
	const isClosed = petition?.stav === EPetitionStatus.uzavrena;
	const isSubmited = petition?.stav === EPetitionStatus.podana;
	const isClosedOrSubmitted =
		petition?.stav === EPetitionStatus.uzavrena || EPetitionStatus.podana;

	const isBeforeSubmit = isBeforeSubmitDate();
	const daysLeft = dayLeftUntilDeadline();

	const isFounder = petition?.mnouZalozene;
	const canSubmitOrClose = isFounder && isPublic && !isDataBoxLoginType;

	return {
		closeQuery,
		submitAndCloseQuery,
		submitQuery,
		canSubmitOrClose,
		isClosed,
		isSubmited,
		isFounder,
		isClosedOrSubmitted,
		isBeforeSubmit,
		daysLeft,
	};
};
