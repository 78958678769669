import { useState } from 'react';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { isUnreadMessage } from '@gov-nx/utils/common';
import { DataBoxFolderType } from '../providers/DataBoxes.types';
import { DataBoxListStatesType } from '../providers/MessageListContext.types';

interface MessagesSelectProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	listState: DataBoxListStatesType;
	messageList: DatoveSchrankyDatovaZpravaDto[];
}

export function useMessagesSelect({
	dataBoxId,
	folderType,
	listState,
	messageList,
}: MessagesSelectProps) {
	const [selectedMessageList, setSelectedMessageList] = useState<number[]>([]);
	const [isAllSelected, setIsAllSelected] = useState(false);

	const showCheckboxes = ![
		DataBoxListStatesType.List,
		DataBoxListStatesType.Search,
	].includes(listState);

	const updateSelectedMessageList = (id: number) => {
		if (selectedMessageList.includes(id)) {
			setSelectedMessageList([
				...selectedMessageList.filter((item) => item !== id),
			]);
		} else {
			setSelectedMessageList([...selectedMessageList, id]);
		}
	};

	const updateAllMessagesSelection = (check: boolean) => {
		if (check) {
			setSelectedMessageList(
				messageList
					.filter((message) => {
						if (folderType !== DataBoxFolderType.Received) return true;
						return !(
							message.prijemceId === dataBoxId && isUnreadMessage(message)
						);
					})
					.map((message) => message.datovaZpravaId as number)
			);
			setIsAllSelected(true);
		} else {
			setSelectedMessageList([]);
			setIsAllSelected(false);
		}
	};

	return {
		showCheckboxes,
		selectedMessageList,
		setSelectedMessageList,
		updateSelectedMessageList,
		updateAllMessagesSelection,
		isAllSelected,
		setIsAllSelected,
	};
}
