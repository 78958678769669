import { CiselnikyCiselnikPolozkaDto } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

export function getSortedLegalFormOptions(
	legalForm: Nullable<CiselnikyCiselnikPolozkaDto[]>
) {
	const sortedOptions = legalForm
		? legalForm
				.filter(
					(data) =>
						typeof data.kod !== 'undefined' &&
						typeof data.nazevCs !== 'undefined'
				)
				.sort((a, b) => (a.nazevCs || '').localeCompare(b.nazevCs || ''))
				.map((data) => ({
					value: (data.kod || '').toString(),
					label: data.nazevCs || '',
				}))
		: [];

	return sortedOptions;
}
