import { useQueryClient } from '@tanstack/react-query';
import {
	usePoMutation,
	verificationFinishQuery,
	verificationQuery,
} from '@gov-nx/api/portal-obcana';
import {
	CommunicationType,
	ContactSource,
	EmailFormData,
	PhoneFormData,
	prepareDeleteFormSubmitData,
	UpdateContactHook,
	useCountryCodes,
} from '@gov-nx/core/hooks';
import { pipe, whenDefined } from '@gov-nx/core/types';
import {
	getNotificationSettingKeys,
	notificationSettingQueryKey,
} from '@gov-nx/module/page';
import { useBoolean } from '@gov-nx/utils/common';
import {
	prepareUpdateEmailFormSubmitData,
	prepareUpdatePhoneFormSubmitData,
	prepareVerifyEmailFormSubmitData,
	prepareVerifyPhoneFormSubmitData,
} from './formDefinitions';

interface UpdateContactProps {
	unverifiedEmail?: string;
	unverifiedPhone?: string;
	source: ContactSource;
}

export const useUpdateContact = ({
	unverifiedEmail,
	unverifiedPhone,
	source,
}: UpdateContactProps): {
	email: UpdateContactHook<EmailFormData>;
	phone: UpdateContactHook<PhoneFormData>;
} => {
	const { stringToPhoneShape } = useCountryCodes();

	const updateEmailMutation = usePoMutation<void, EmailFormData>({
		mutationFn: async (formData) => {
			const prepared = prepareUpdateEmailFormSubmitData(source, formData);
			return verificationQuery(prepared);
		},
	});

	const resendEmailVerificationMutation = usePoMutation<void, void>({
		mutationFn: async () => {
			const prepared = prepareUpdateEmailFormSubmitData(source, {
				email: unverifiedEmail ?? '',
			});
			return verificationQuery(prepared);
		},
	});
	const verifyEmailMutation = usePoMutation<void, EmailFormData>({
		mutationFn: async (formData) => {
			const prepared = prepareVerifyEmailFormSubmitData(source, formData);
			return verificationFinishQuery(prepared);
		},
	});

	const deleteEmailMutation = usePoMutation<void, void>({
		mutationFn: async () => {
			const prepared = prepareDeleteFormSubmitData(
				source,
				CommunicationType.EMAIL
			);
			return verificationQuery(prepared);
		},
	});

	const updatePhoneMutation = usePoMutation<void, PhoneFormData>({
		mutationFn: (formData) => {
			const prepared = prepareUpdatePhoneFormSubmitData(source, formData);
			return verificationQuery(prepared);
		},
	});

	const resendPhoneVerificationMutation = usePoMutation<void, void>({
		mutationFn: async () => {
			return pipe(
				unverifiedPhone ? unverifiedPhone : undefined,
				whenDefined(stringToPhoneShape),
				whenDefined((shape) =>
					prepareUpdatePhoneFormSubmitData(source, { telefon: shape })
				),
				whenDefined(verificationQuery)
			);
		},
	});

	const verifyPhoneMutation = usePoMutation<void, PhoneFormData>({
		mutationFn: async (formData) => {
			const prepared = prepareVerifyPhoneFormSubmitData(source, formData);
			return verificationFinishQuery(prepared);
		},
	});

	const deletePhoneMutation = usePoMutation<void, void>({
		mutationFn: async () => {
			const prepared = prepareDeleteFormSubmitData(
				source,
				CommunicationType.TELEFON
			);
			return verificationQuery(prepared);
		},
	});

	return {
		email: {
			update: updateEmailMutation.mutate,
			isUpdating: updateEmailMutation.isLoading,

			verify: verifyEmailMutation.mutate,
			isVerifying: verifyEmailMutation.isLoading,

			delete: deleteEmailMutation.mutate,
			isDeleting: deleteEmailMutation.isLoading,

			resendVerification: resendEmailVerificationMutation.mutate,
			isVerificationResending: resendEmailVerificationMutation.isLoading,
		},
		phone: {
			update: updatePhoneMutation.mutate,
			isUpdating: updatePhoneMutation.isLoading,

			verify: verifyPhoneMutation.mutate,
			isVerifying: verifyPhoneMutation.isLoading,

			delete: deletePhoneMutation.mutate,
			isDeleting: deletePhoneMutation.isLoading,

			resendVerification: resendPhoneVerificationMutation.mutate,
			isVerificationResending: resendPhoneVerificationMutation.isLoading,
		},
	};
};

interface ContactDetailsProps {
	unverifiedValue?: string;
}

export const useContactDetails = ({ unverifiedValue }: ContactDetailsProps) => {
	const queryClient = useQueryClient();

	const isDeleteConfirmationOpen = useBoolean();
	const isChanging = useBoolean();

	const isNeedForVerification =
		(!isChanging.value && !!unverifiedValue) ?? false;

	const cleanNotificationSettingsCache = async (): Promise<void> => {
		const keys = getNotificationSettingKeys()
			.map(notificationSettingQueryKey)
			.map((queryKey) => queryClient.invalidateQueries({ queryKey }));

		await Promise.all(keys);
	};

	return {
		isDeleteConfirmationOpen,
		isChanging,
		isNeedForVerification,
		cleanNotificationSettingsCache,
	};
};
