import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	CiselnikyCiselnikPolozkaSeznamDto,
	OsobyEditorSeznamDto,
	OsobyPodnikatelDto,
	ResponseError,
	editorsOvmQuery,
	legalFormQuery,
	sendComplaintQueryRos,
	usePoMutation,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useAddressSearch, useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ProfileROSComplaintContext,
	ProfileRosComplaintProcessControl,
} from './context.types';
import { getSortedLegalFormOptions } from './utils';

const ProfilROSReklamaceContext =
	createContext<Nullable<ProfileROSComplaintContext>>(null);

interface ProfilROSComplaintContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	data: OsobyPodnikatelDto;
	onComplaintSubmit?: () => void;
}

export function ProfilROSReklamaceContextProvider({
	children,
	code,
	data,
	onComplaintSubmit,
}: ProfilROSComplaintContextProviderProps) {
	const { setControls, controls } =
		useProcessControl<ProfileRosComplaintProcessControl>({
			initialLoading: true,
			displaySearchAddressLoading: false,
			displaySearchAddress: false,
			initialLegalFormError: null,
			initialEditorsError: null,
		});
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { t } = useTranslation([code]);

	const [rosLegalFormResult, rosEditorsResult] = usePoQueries<
		[
			UseQueryResult<
				CiselnikyCiselnikPolozkaSeznamDto,
				AxiosError<ResponseError>
			>,
			UseQueryResult<OsobyEditorSeznamDto, AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['profile-ros-legal-form'],
				queryFn: legalFormQuery,
				onError: async (error: Error) => {
					setControls({ initialLegalFormError: new GovError(error.message) });
				},
			},
			{
				queryKey: ['profile-ros-editors', data.ico],
				queryFn: () => editorsOvmQuery(data.ico),
				onError: async (error: Error) => {
					setControls({ initialEditorsError: new GovError(error.message) });
				},
			},
		],
		onAllSuccess: () => {
			setControls({ initialLoading: false });
		},
	});

	const legalForm = rosLegalFormResult.data?.seznam ?? [];
	const editors = rosEditorsResult.data?.seznam ?? [];

	const formDefinition = FormInstance({ code, data, controls });

	const [initialFormValues, setInitialFormValues] = useState<FormData | null>(
		null
	);

	useEffect(() => {
		formDefinition.formMethods.setValue('ico', data.ico);
		setInitialFormValues(formDefinition.formMethods.getValues());
	}, [data.ico, formDefinition.formMethods]);

	const { address, mainQuery } = useAddressSearch({
		formMethods: formDefinition.formMethods,
		onSuccess: () => setControls({ displaySearchAddressLoading: false }),
		onError: () => setControls({ displaySearchAddressLoading: false }),
	});

	const newAddress = formDefinition.formMethods.watch('newAddress');

	useEffect(() => {
		const selectedOption = address.find((option) => option.kod === newAddress);
		if (selectedOption && address.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaSidla',
				selectedOption.adresaText || ''
			);
		}
	}, [newAddress, address, formDefinition.formMethods]);

	const submitMutation = usePoMutation({
		mutationFn: async (formData: FormData) => {
			const prepared = prepareSubmitData(newAddress, editors)(formData);
			return sendComplaintQueryRos(prepared);
		},
		onError: (error) => {
			toastMessageError(t('formular.zprava.neodeslano'));
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.odeslano'));
			setControls({ processLoading: false });
			formDefinition.formMethods.reset();
			onComplaintSubmit && onComplaintSubmit();
		},
	});

	const resetAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObce');
		formDefinition.formMethods.resetField('nazevUlice');
		formDefinition.formMethods.resetField('cislo');
	};

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		const changedValues: FormData = {};

		const companyName = formDefinition.formMethods.watch('nazevSpolecnosti');
		const initialCompanyName = initialFormValues?.nazevSpolecnosti;
		if (companyName !== initialCompanyName) {
			changedValues.nazevSpolecnosti = companyName;
		}

		const startDate = formDefinition.formMethods.watch('datumOd');
		const initialStartDate = initialFormValues?.datumOd;
		if (startDate !== initialStartDate) {
			changedValues.datumOd = startDate;
		}

		const endDate = formDefinition.formMethods.watch('datumDo');
		const initialEndDate = initialFormValues?.datumDo;
		if (endDate !== initialEndDate) {
			changedValues.datumDo = endDate;
		}

		const legalForm = formDefinition.formMethods.watch('pravniForma');
		const initialLegalForm = initialFormValues?.pravniForma;
		if (legalForm !== initialLegalForm) {
			changedValues.pravniForma = legalForm;
		}

		const suspensionInterruption = formDefinition.formMethods.watch(
			'pozastaveniPreruseni'
		);
		const initialSuspensionInterruption =
			initialFormValues?.pozastaveniPreruseni;
		if (suspensionInterruption !== initialSuspensionInterruption) {
			changedValues.pozastaveniPreruseni = suspensionInterruption;
		}

		const registeredAddress = formDefinition.formMethods.watch('adresaSidla');
		const initialRegisteredAddress = initialFormValues?.adresaSidla;
		if (registeredAddress !== initialRegisteredAddress) {
			changedValues.adresaSidla = registeredAddress;
		}

		const newPhone = formDefinition.formMethods.watch('newPhone');
		const newEmail = formDefinition.formMethods.watch('newEmail');

		if (newPhone || newEmail) {
			changedValues.newPhone = newPhone;
			changedValues.newEmail = newEmail;
		}

		const currentEmail = formDefinition.formMethods.watch('_email');
		const currentTelefon = formDefinition.formMethods.watch('_telefon');

		if (currentEmail !== 'another') {
			changedValues._email = currentEmail;
		}
		if (currentTelefon !== 'another') {
			changedValues._telefon = currentTelefon;
		}

		changedValues.popisReklamace =
			formDefinition.formMethods.watch('popisReklamace');

		if (Object.keys(changedValues).length > 0) {
			submitMutation.mutate({
				...changedValues,
				ico: initialFormValues?.ico,
			});
		} else {
			setControls({ processLoading: false });
		}
	}, [
		submitMutation,
		formDefinition.formMethods,
		setControls,
		initialFormValues,
	]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const sortedOptions = getSortedLegalFormOptions(legalForm);
	const selectedLegalForm = formDefinition.formMethods.getValues('pravniForma');

	const filteredOptions = sortedOptions.filter(
		(option) => option.label !== selectedLegalForm
	);

	return (
		<ProfilROSReklamaceContext.Provider
			value={{
				controls,
				setControls,
				formDefinition,
				data,
				legalForm,
				onSubmit,
				address,
				selectedLegalForm,
				filteredOptions,
				mainQuery,
				resetAddressSearchFields,
				disableSubmitButton: submitMutation.isSuccess,
			}}>
			{children}
		</ProfilROSReklamaceContext.Provider>
	);
}

export const useProfilROSReklamaceContextInstance =
	(): ProfileROSComplaintContext =>
		useContext(ProfilROSReklamaceContext) as ProfileROSComplaintContext;
