import { AxiosResponse } from 'axios';
import {
	filter,
	find,
	first,
	hasOwnProperty,
	is,
	ObjectOrArray,
	ObjectOrArrayType,
	Optional,
	pipe,
	prop,
	propOptional,
} from '@gov-nx/core/types';
import { today } from '@gov-nx/utils/common';
import { sortDescending } from './seznam/filterUtils';
import {
	KontextyResponse,
	KontextyResponseData,
	KontextyResponseDetailData,
	KontextyResponseIcoData,
	RegistrVozidelResponse,
	StatusName,
	Subject,
	Vehicle,
	VehicleDocuments,
} from './vehicle.types';

const isInFuture = (date: Date) => date > new Date();
const isNowInRange = (from?: string, to?: string): boolean => {
	if (!from) return false;

	const now = today();
	const fromDate = today(from);

	if (to) {
		const toDate = today(to);
		return fromDate < now && toDate > now;
	}

	return fromDate < now;
};

const toSubject = (
	type: Subject['subjektTyp']['xVal']
): Optional<'VLASTNÍK' | 'PROVOZOVATEL'> => {
	switch (type) {
		case '1':
			return 'VLASTNÍK';
		case '2':
			return 'PROVOZOVATEL';
		default:
			return undefined;
	}
};

const getVehicleId = (vehicle?: Vehicle): Optional<string> =>
	vehicle?.vozidloUdaje.udajeZakladni?.vozidloID?.xVal;
const getVehicleName = (vehicle: Vehicle): string => {
	const data = vehicle.vozidloUdaje.udajeZakladni;
	return [
		data?.vozidloTovarniZnacka?.xVal,
		data?.vozidloObchodniOznaceni?.xVal !== '.'
			? data?.vozidloObchodniOznaceni?.xVal
			: undefined,
	]
		.filter(is)
		.join(' ');
};

const toDate = (dateString?: string): Optional<Date> =>
	pipe(
		dateString,
		(dateString) => (dateString ? Date.parse(dateString) : undefined),
		(timestamp) => (timestamp ? new Date(timestamp) : undefined)
	);

export const getVztahOd = (vehicle: Vehicle): Optional<Date> => {
	return pipe(
		vehicle.subjekty.subjekt,
		ObjectOrArray.first,
		(subject) => subject.subjektOd?.xVal,
		toDate
	);
};

const getVehicleLatestStatusName = (vehicle: Vehicle) =>
	pipe(
		vehicle.vozidloStav,
		ObjectOrArray.last,
		prop('status'),
		ObjectOrArray.last,
		(status) => status?.statusNazev?.xVal
	);
export const getVehicleGetters = (vehicle: Vehicle) => {
	return {
		id: getVehicleId(vehicle),
		name: () => getVehicleName(vehicle),
		rz: () =>
			pipe(
				vehicle?.doklady,
				ObjectOrArray.first,
				propOptional('rz'),
				ObjectOrArray.first,
				(rz) => rz?.cisloRz?.xVal
			),
		statusName: () => getVehicleLatestStatusName(vehicle),
		latestStatus: pipe(vehicle.vozidloStav.status, ObjectOrArray.last),
		vin: vehicle?.vozidloUdaje.udajeZakladni?.vozidloVin?.xVal,
		isActive: () =>
			pipe(vehicle.subjekty.subjekt, ObjectOrArray.array, (subjects) => {
				const statusName = getVehicleLatestStatusName(vehicle);
				return statusName
					? subjects.some((subjekt) =>
							isNowInRange(subjekt.subjektOd?.xVal, subjekt.subjektDo?.xVal)
					  ) && ![StatusName.vyvoz, StatusName.zanik].includes(statusName)
					: false;
			}),
		isCurrentSubject: (): boolean =>
			pipe(
				vehicle.subjekty.subjekt,
				ObjectOrArray.array,
				(subjects) =>
					subjects.map((subject) => pipe(subject.subjektDo?.xVal, toDate)),
				(dates) => dates.filter(is),
				(dates) => dates.sort(sortDescending),
				first,
				(subjectTo) => {
					if (!subjectTo) {
						return true;
					}

					return isInFuture(subjectTo);
				}
			),
		relations: () => {
			return pipe(
				vehicle.subjekty.subjekt,
				ObjectOrArray.array,
				(subjects) => subjects.filter((subject) => subject.subjektTyp?.xVal),
				(subjects) =>
					subjects.map((subject) => pipe(subject.subjektTyp?.xVal, toSubject)),
				(relations) => relations.filter(is)
			);
		},
		documents: <T extends keyof VehicleDocuments>(
			type: T
		): Optional<VehicleDocuments[T]> =>
			pipe(vehicle.doklady, ObjectOrArray.first, propOptional(type)),
		documentsHistory: <T extends keyof VehicleDocuments>(
			type: T
		): Optional<VehicleDocuments[T]> =>
			pipe(vehicle.dokladyHistorie, ObjectOrArray.first, propOptional(type)),
	};
};

const isContextResponse = (
	data: RegistrVozidelResponse['Data'][number]
): data is KontextyResponse => hasOwnProperty(data, 'r');

const isContextResponseData = (
	r: KontextyResponse['r']
): r is KontextyResponseData => hasOwnProperty(r, 'paisRsvCtiAifoResponse');

const isContextResponseIcoData = (
	r: KontextyResponse['r']
): r is KontextyResponseIcoData => hasOwnProperty(r, 'paisRsvCtiIcoResponse');

const isContextResponseDetailData = (
	r: KontextyResponse['r']
): r is KontextyResponseDetailData =>
	hasOwnProperty(r, 'paisRsvCtiVozidloIdResponse');

export const getTotalVehicleCount = (
	response?: AxiosResponse<RegistrVozidelResponse>
): Optional<number> => {
	return pipe(response?.data.Data, find(isContextResponse), (response) => {
		if (response && isContextResponseData(response.r)) {
			const vehicleCount =
				response.r.paisRsvCtiAifoResponse.pocetNalezenychVozidel?.xVal;
			return vehicleCount ? parseInt(vehicleCount, 10) : undefined;
		}

		return undefined;
	});
};

export const getData = (
	response?: AxiosResponse<RegistrVozidelResponse>
): ObjectOrArrayType<Optional<Vehicle>> => {
	return pipe(response?.data.Data, find(isContextResponse), (response) => {
		if (response && isContextResponseData(response.r)) {
			return response.r.paisRsvCtiAifoResponse.paisRsvCtiVozidloResponse;
		}
		if (response && isContextResponseIcoData(response.r))
			return response.r.paisRsvCtiIcoResponse.paisRsvCtiVozidloResponse;
		return undefined;
	});
};

export const getVehicleList = (
	response?: AxiosResponse<RegistrVozidelResponse>
): Vehicle[] => {
	return pipe(response, getData, ObjectOrArray.array, filter(is));
};

export const getVehicleDetailData = (
	response?: AxiosResponse<RegistrVozidelResponse>
): Optional<Vehicle> => {
	return pipe(response?.data.Data, find(isContextResponse), (response) => {
		if (response && isContextResponseDetailData(response.r)) {
			return response?.r.paisRsvCtiVozidloIdResponse.paisRsvCtiVozidloResponse;
		}
		return undefined;
	});
};
