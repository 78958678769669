import { useQueryClient } from '@tanstack/react-query';
import {
	ePetitionAttachmentsQuery,
	getError,
	isResponseStatus,
	PeticePrilohaDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { FileState, FileUploadItem } from '@gov-nx/core/hooks';

const toFile = (
	attachment: PeticePrilohaDto,
	index: number
): FileUploadItem => {
	return {
		id: attachment.id ? `${attachment.id}` : '',
		size: attachment.velikost ?? 0,
		type: attachment.typ ?? '',
		name: attachment.nazev ?? '',
		state: FileState.success,
		index,
		file: {
			name: attachment.nazev ?? '',
			size: attachment.velikost ?? 0,
			type: attachment.typ ?? '',
		} as File,
		acceptValid: true,
		sizeValid: true,
	};
};

export const usePetitionAttachmentsQuery = (petitionId: number) => {
	const queryClient = useQueryClient();
	const queryKey = ['epetice-attachments', petitionId];

	const petitionAttachments = usePoQuery(
		{
			queryKey,
			queryFn: () => ePetitionAttachmentsQuery(petitionId),
			refetchOnWindowFocus: false,
			retry: 0,
		},
		{
			errorIgnoreFilter: isResponseStatus(400),
		}
	);

	const attachments = petitionAttachments.data?.seznam ?? [];

	return {
		refetch: async (): Promise<FileUploadItem[]> => {
			const result = await petitionAttachments.refetch();
			return result.data?.seznam?.map(toFile) ?? [];
		},
		attachments,
		files: attachments.map(toFile),
		error: getError(petitionAttachments.error, { ignoreStatus: 400 }),
		invalidate: async () => queryClient.invalidateQueries({ queryKey }),
	};
};
