import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useRegistrRidicuContext } from '@gov-nx/module/page';
import { DataTable, InfoBanner } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export interface DriversRegistryProhibitionsViewProps {
	code: PageCode;
}

export const DriversRegistryProhibitionsView = ({
	code,
}: DriversRegistryProhibitionsViewProps) => {
	const { data } = useRegistrRidicuContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<section className={'flex flex-col gap-6'}>
			<section className={'py-2'}>
				<h2 className={'mb-4 text-xl'}>
					{ts('zakaz-rizeni-motorovych-vozidel')}
				</h2>
				{data?.hasProhibitions ? (
					data?.prohibitions?.map((prohibition) => (
						<DataTable
							responsive
							key={prohibition.id}>
							<tbody>
								<tr>
									<td>{ts('data.skupiny')}</td>
									<td>
										<strong>{prohibition.group}</strong>
									</td>
								</tr>
								<tr>
									<td>{ts('data.datum-zakazu')}</td>
									<td>
										<strong>
											{prohibition.validFrom
												? toDateReadable(prohibition.validFrom)
												: ts('data.neurceno')}{' '}
											-{' '}
											{prohibition.validTo
												? toDateReadable(prohibition.validTo)
												: ts('data.neurceno')}
										</strong>
									</td>
								</tr>
								<tr>
									<td>{ts('data.delka')}</td>
									<td>
										<strong>{prohibition.length}</strong>
									</td>
								</tr>
							</tbody>
						</DataTable>
					))
				) : (
					<InfoBanner
						icon={{ name: 'empty-file', type: 'colored' }}
						type="secondary"
						className={'!px-0 !py-4'}>
						<p className={'text-2xl [ md:text-3xl ]'}>
							{ts('stav.chyba.zadny-zakaz-rizeni')}
						</p>
						<p>{ts('stav.chyba.zadny-zakaz-rizeni-popis')}</p>
					</InfoBanner>
				)}
			</section>

			<section className={'py-2'}>
				<h2 className={'mb-4 text-xl'}>
					{ts('data.duvody-pozbytych-opravneni')}
				</h2>
				{data?.hasRevocations ? (
					data?.revocations?.map((revocation) => (
						<DataTable
							responsive
							key={revocation.id}>
							<tbody>
								<tr>
									<td>{ts('data.skupiny')}</td>
									<td>
										<strong>{revocation.group}</strong>
									</td>
								</tr>
								<tr>
									<td>{ts('data.datum-pozbyti')}</td>
									<td>
										<strong>
											{revocation.validFrom
												? toDateReadable(revocation.validFrom)
												: ts('data.neurceno')}{' '}
											-{' '}
											{revocation.validTo
												? toDateReadable(revocation.validTo)
												: ts('data.neurceno')}
										</strong>
									</td>
								</tr>
								<tr>
									<td>{ts('data.duvod')}</td>
									<td>
										<strong>{revocation.reason}</strong>
									</td>
								</tr>
							</tbody>
						</DataTable>
					))
				) : (
					<InfoBanner
						icon={{ name: 'empty-file', type: 'colored' }}
						type="secondary"
						className={'!px-0 !py-4'}>
						<p className={'text-2xl [ md:text-3xl ]'}>
							{ts('stav.chyba.zadne-duvody-pozbyti')}
						</p>
						<p>{ts('stav.chyba.zadne-duvody-pozbyti-popis')}</p>
					</InfoBanner>
				)}
			</section>
		</section>
	);
};
