import { POError } from '@gov-nx/api/portal-obcana';
import { Optional, hasProperty } from '@gov-nx/core/types';

export const getIgnoredError = (poErrors: Optional<POError[]>) => {
	const ignoredErrorsByExtKod = [
		'ISZR_EGON_DUPLICITA_CRT',
		'B_CRT_VALIDATION_ERROR',
		'B_CRT_VALIDATION_ERROR_NOT_AFTER',
		'B_CRT_VALIDATION_ERROR_NOT_LONG',
		'B_CRT_VALIDATION_ERROR_EU',
	];
	return poErrors
		?.filter(hasProperty('extKod'))
		.find((error) => ignoredErrorsByExtKod.includes(error.extKod));
};
