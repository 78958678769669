import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { createContext, useCallback, useContext } from 'react';
import {
	ePetitionDetailQuery,
	ePetitionMembersQuery,
	filterErrorStatus,
	PeticeClenPeticnihoVyboruSeznamDto,
	PeticePeticeDto,
	ResponseError,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	usePetitionCloseSubmit,
	usePetitionExtend,
	usePetitionSign,
	usePoIndividualDataLoad,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { toDateReadable, useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { usePetitionAttachmentsQuery } from '../hooks/usePetitionAttachmentsQuery';
import {
	EPetitionDetailContext,
	EPetitionDetailProcessControl,
} from './context.types';
import { isPetitionEditable } from './utils';

const EPeticeDetailContext =
	createContext<Nullable<EPetitionDetailContext>>(null);

interface EPeticeDetailContextProviderProps {
	petitionId: number;
	children: React.ReactNode;
	code: PageCode;
}

export function EPeticeDetailContextProvider({
	children,
	code,
	petitionId,
}: EPeticeDetailContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { toastMessageSuccess } = useMessageEvents();
	const { setControls, controls } =
		useProcessControl<EPetitionDetailProcessControl>({
			initialLoading: true,
			isRevokeModal: false,
			isSignModal: false,
			isSignProcessing: false,
			isRevokeProcessing: false,
			isCloseAndSubmitModal: false,
			isCloseModal: false,
			isExtendModal: false,
			isExtendProcessing: false,
			isCloseProcessing: false,
			isSubmitAndCloseProcessing: false,
			isSubmitProcessing: false,
		});
	const isDropdownOpen = useBoolean(false);

	const { downloadEPetition } = useDownloadEvents();
	const loginInfo = useIdentity();

	const { getConnectedDataBoxesList } = useDataBoxStore();
	const dataBoxes = getConnectedDataBoxesList();
	const isDataBoxConnected = dataBoxes.length > 0;

	const { individualPerson } = usePoIndividualDataLoad({
		onError: (initialError) => setControls({ initialError }),
	});

	const petitionAttachmentsQuery = usePetitionAttachmentsQuery(petitionId);

	const [petitionQuery, petitionMembersQuery] = usePoQueries<
		[
			UseQueryResult<PeticePeticeDto, AxiosError<ResponseError>>,
			UseQueryResult<
				PeticeClenPeticnihoVyboruSeznamDto,
				AxiosError<ResponseError>
			>
		]
	>({
		queries: [
			{
				queryKey: ['epetice-detail', petitionId],
				queryFn: () => ePetitionDetailQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
			},
			{
				queryKey: ['epetice-members', petitionId],
				queryFn: () => ePetitionMembersQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
			},
		],
		configs: [
			{
				errorIgnoreFilter: filterErrorStatus(400, (initialError) =>
					setControls({ initialError })
				),
			},
			{
				errorIgnoreFilter: filterErrorStatus(400, (initialError) =>
					setControls({ initialError })
				),
			},
		],
		onAllSuccess: () => {
			setControls({ initialLoading: false });
		},
	});

	const petition = petitionQuery.data;
	const members = petitionMembersQuery.data?.seznam ?? [];
	const attachments = petitionAttachmentsQuery.attachments;

	const refresh = useCallback(async () => {
		await petitionQuery.refetch();
	}, [petitionQuery]);

	const ePetitionSign = usePetitionSign({
		petition: petition ? petition : null,
		onSignSuccess: async () => {
			await refresh();
			toastMessageSuccess(ts('zprava.petice-podepsana'));
			setControls({ isSignModal: false, isSignProcessing: false });
		},
		onSignError: () => {
			setControls({ isSignModal: false, isSignProcessing: false });
		},
		onRevokeSuccess: async () => {
			await refresh();
			toastMessageSuccess(ts('zprava.petice-odvolana'));
			setControls({ isRevokeModal: false, isRevokeProcessing: false });
		},
		onRevokeError: () => {
			setControls({ isRevokeModal: false, isRevokeProcessing: false });
		},
	});

	const ePetitionExtend = usePetitionExtend({
		petition: petition ? petition : null,
		onExtendSuccess: async () => {
			const { data } = await petitionQuery.refetch();

			data?.datumUkonceni &&
				toastMessageSuccess(
					ts('zprava.petice-prodlouzena', {
						prodlouzeniDo: toDateReadable(data.datumUkonceni),
					})
				);
			setControls({ isExtendProcessing: false, isExtendModal: false });
		},
		onExtendError: () =>
			setControls({ isExtendProcessing: false, isExtendModal: false }),
	});

	const ePetitionSubmitClose = usePetitionCloseSubmit({
		petition: petition ? petition : null,
		onCloseSuccess: async () => {
			await refresh();
			setControls({ isCloseProcessing: false, isCloseModal: false });
			isDropdownOpen.setFalse();
			toastMessageSuccess(ts('zprava.petice-uzavrena'));
		},
		onCloseError: () => {
			setControls({ isCloseProcessing: false, isCloseModal: false });
			isDropdownOpen.setFalse();
		},
		onSubmitAndClosedSuccess: async () => {
			await refresh();
			setControls({
				isSubmitAndCloseProcessing: false,
				isCloseAndSubmitModal: false,
			});
			toastMessageSuccess(ts('zprava.petice-uzavrena-podana'));
		},
		onSubmitAndClosedError: () =>
			setControls({
				isSubmitAndCloseProcessing: false,
				isCloseAndSubmitModal: false,
			}),
		onSubmitSuccess: async () => {
			await refresh();
			setControls({
				isSubmitProcessing: false,
			});
			toastMessageSuccess(ts('zprava.petice-podana'));
		},
		onSubmitError: () => setControls({ isSubmitProcessing: false }),
	});

	const handleEPetitionDownload = () => {
		setControls({ isDownloadProcessing: true });
		return downloadEPetition({
			petitionId,
			callback: () => {
				setControls({ isDownloadProcessing: false });
			},
		});
	};

	const handleEPetitionSubmit = () => {
		setControls({ isSubmitProcessing: true });
		ePetitionSubmitClose.submitQuery.mutate(petitionId);
	};

	const handleEPetitionSubmitClose = () => {
		setControls({ isSubmitAndCloseProcessing: true });
		ePetitionSubmitClose.submitAndCloseQuery.mutate(petitionId);
	};

	const handleEPetitionClose = () => {
		setControls({ isCloseProcessing: true });
		ePetitionSubmitClose.closeQuery.mutate(petitionId);
	};

	const onCloseModal = () => {
		setControls({ isCloseModal: false });
		isDropdownOpen.setFalse();
	};

	return (
		<EPeticeDetailContext.Provider
			value={{
				petition,
				isEditable: petition ? isPetitionEditable(petition) : false,
				members,
				attachments,
				individualPerson,
				isDataBoxConnected,
				controls: {
					...controls,
					initialError: controls.initialError ?? petitionAttachmentsQuery.error,
				},
				isDropdownOpen,
				ePetitionSign,
				ePetitionExtend,
				ePetitionSubmitClose,
				loginInfo,
				handleEPetitionDownload,
				handleEPetitionSubmit,
				handleEPetitionSubmitClose,
				handleEPetitionClose,
				onCloseModal,
				setControls,
			}}>
			{children}
		</EPeticeDetailContext.Provider>
	);
}

export const useEPeticeDetailContext = (): EPetitionDetailContext =>
	useContext(EPeticeDetailContext) as EPetitionDetailContext;
