import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { isZipCodeValid } from './Validator/zip-code';

export const useZipCodeShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getZipCodeShape: (): yup.StringSchema => {
			return yup
				.string()
				.test('shape', t('psc.validace.spatny-tvar'), isZipCodeValid);
		},
	};
};
