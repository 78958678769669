import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { PersonCommunicationData } from '@gov-nx/core/hooks';
import {
	CheckboxProps,
	PhoneWithCountryCodeShape,
	WebFormInputProps,
} from '@gov-nx/ui/types';
import {
	Button,
	FormCheckbox,
	FormWidthElementLayout,
	PhoneNumberInput,
} from '@gov-nx/ui/web';
import { phoneShapeToString } from '@gov-nx/utils/common';

interface UseCommunicationDataPhoneProps {
	personCommunicationData: PersonCommunicationData;
	fields: {
		_usePhone: CheckboxProps;
		phone: WebFormInputProps;
	};
	wcagLabels: {
		edit: string;
		cancel: string;
	};
	phone?: PhoneWithCountryCodeShape;
	onReset: () => void;
}

export const UseCommunicationDataPhone = ({
	personCommunicationData,
	fields,
	onReset,
	phone,
	wcagLabels,
}: UseCommunicationDataPhoneProps) => {
	return (
		<div
			className={
				'flex flex-col gap-1 flex-wrap [ md:flex-row md:items-center ]'
			}>
			<FormCheckbox field={fields._usePhone} />
			{personCommunicationData.control.isEditingPhone.value ? (
				<div className={'flex flex-row items-center gap-2 [ md:ml-10 ]'}>
					<FormWidthElementLayout type={'xl'}>
						<PhoneNumberInput
							field={fields.phone}
							label={{}}
							countryCodeLabel={{}}
						/>
					</FormWidthElementLayout>
					{personCommunicationData.phoneVerified && (
						<Button
							variant={'primary'}
							type={'base'}
							size={'m'}
							onClick={() => {
								personCommunicationData.control.isEditingPhone.setFalse();
								onReset();
							}}
							wcagLabel={wcagLabels.cancel}>
							<GovIcon
								name="x-lg"
								slot="right-icon"
							/>
						</Button>
					)}
				</div>
			) : (
				<div className={'inline-flex items-center gap-2 mb-1'}>
					<strong>{phone ? phoneShapeToString(phone) : null}</strong>
					<Button
						onClick={() =>
							personCommunicationData.control.isEditingPhone.setTrue()
						}
						wcagLabel={wcagLabels.edit}
						variant={'primary'}
						size={'s'}
						type={'base'}>
						<GovIcon
							name="pencil-square"
							slot="right-icon"
						/>
					</Button>
				</div>
			)}
		</div>
	);
};
