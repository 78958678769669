import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfilROBReklamaceContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormInput,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../Form/PoForm';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';
import { AddressSearchForm } from './AddressSearchForm';
import { BirthPlaceChangeForm } from './BirthplaceChangeForm';
import { DeliveryAddressSearchForm } from './DeliveryAddressSearchForm';

export interface ComplaintProps {
	code: PageCode;
}

export const ProfileROBComplaintView = ({ code }: ComplaintProps) => {
	const {
		data,
		controls,
		setControls,
		formDefinition,
		onSubmit,
		resetAddressSearchFields,
		resetDeliveryAddressSearchFields,
		resetBirthPlaceSearchFields,
	} = useProfilROBReklamaceContextInstance();

	const isProcessing = controls.processLoading;

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<LayoutGap gap={6}>
					<PoMarkdownRenderWeb>{ts('formular.popis')}</PoMarkdownRenderWeb>
					<FormWidthElementLayout
						type={'l'}
						className={'flex flex-col gap-6'}>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'jmeno',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.jmeno'),
								}}
								label={{ children: ts('formular.pole.jmeno') }}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'prijmeni',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.prijmeni'),
								}}
								label={{ children: ts('formular.pole.prijmeni') }}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'datumNarozeni',
									disabled: true,
									placeholder: ts('formular.placeholders.datum-narozeni'),
								}}
								label={{ children: ts('formular.pole.datum-narozeni') }}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'mistoNarozeni',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.misto-narozeni'),
								}}
								label={{ children: ts('formular.pole.misto-narozeni') }}
								control={{ size: 'm' }}
							/>
							{!controls.displayBirthPlace && (
								<Button
									onClick={() => setControls({ displayBirthPlace: true })}
									disabled={isProcessing}
									variant="primary"
									size="m"
									type="outlined">
									{ts('akce.zmenit-misto')}
								</Button>
							)}

							{controls.displayBirthPlace && (
								<>
									<Button
										onClick={() => {
											setControls({ displayBirthPlace: false });
											resetBirthPlaceSearchFields();
										}}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{ts('akce.zrusit-zmeny')}
									</Button>
									<BirthPlaceChangeForm
										code={code}
										isProcessing={isProcessing}
									/>
								</>
							)}
							<FormInput
								field={{
									name: 'adresaPobytu',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.adresa-pobytu'),
								}}
								label={{ children: ts('formular.pole.adresa-pobytu') }}
								control={{ size: 'm' }}
							/>

							{!controls.displaySearchAddress && (
								<Button
									onClick={() => setControls({ displaySearchAddress: true })}
									disabled={isProcessing}
									variant="primary"
									size="m"
									type="outlined">
									{ts('akce.zmenit-adresu')}
								</Button>
							)}
							{controls.displaySearchAddress && (
								<>
									<Button
										onClick={() => {
											setControls({ displaySearchAddress: false });
											resetAddressSearchFields();
										}}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{ts('akce.zrusit-zmeny')}
									</Button>
									<AddressSearchForm
										code={code}
										isProcessing={isProcessing}
									/>
								</>
							)}
							<FormInput
								field={{
									name: 'adresaDorucovaci',
									disabled: isProcessing || !data?.adresaDorucovaci?.adresaText,
									placeholder: ts('formular.placeholders.dorucovaci-adresa'),
								}}
								label={{ children: ts('formular.pole.dorucovaci-adresa') }}
								control={{ size: 'm' }}
							/>
							{!controls.displaySearchDeliveryAddress &&
								data?.adresaDorucovaci?.adresaText && (
									<Button
										onClick={() =>
											setControls({ displaySearchDeliveryAddress: true })
										}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{ts('akce.zmenit-adresu')}
									</Button>
								)}
							{controls.displaySearchDeliveryAddress && (
								<>
									<Button
										onClick={() => {
											setControls({ displaySearchDeliveryAddress: false });
											resetDeliveryAddressSearchFields();
										}}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{ts('akce.zrusit-zmeny')}
									</Button>
									<DeliveryAddressSearchForm
										code={code}
										isProcessing={isProcessing}
									/>
								</>
							)}
							<FormInput
								field={{
									name: 'popisReklamace',
									multiline: true,
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.popis-reklamace'),
								}}
								label={{ children: ts('formular.pole.popis-reklamace') }}
								control={{ size: 'm' }}
							/>
						</LayoutGap>
						<ButtonGroupLayout>
							<Button
								loading={isProcessing}
								disabled={isProcessing}
								nativeType="submit"
								variant="primary"
								size="m"
								type="solid">
								{ts('akce.odeslat-reklamaci')}
							</Button>
						</ButtonGroupLayout>
					</FormWidthElementLayout>
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
