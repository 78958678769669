import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { PersonCommunicationData } from '@gov-nx/core/hooks';
import { CheckboxProps, WebFormInputProps } from '@gov-nx/ui/types';
import {
	Button,
	FormCheckbox,
	FormInput,
	FormWidthElementLayout,
} from '@gov-nx/ui/web';

interface UseCommunicationDataEmailProps {
	fields: {
		_useEmail: CheckboxProps;
		email: WebFormInputProps;
	};
	wcagLabels: {
		edit: string;
		cancel: string;
	};
	personCommunicationData: PersonCommunicationData;
	onReset: () => void;
	email?: string;
}

export const UseCommunicationDataEmail = ({
	personCommunicationData,
	email,
	wcagLabels,
	fields,
	onReset,
}: UseCommunicationDataEmailProps) => {
	return (
		<div
			className={
				'flex flex-col gap-1 flex-wrap [ md:flex-row md:items-center ]'
			}>
			<FormCheckbox field={fields._useEmail} />
			{personCommunicationData.control.isEditingEmail.value ? (
				<div
					className={'flex flex-row items-center gap-2 [ md:w-1/2 md:ml-10 ]'}>
					<FormWidthElementLayout type={'l'}>
						<FormInput
							field={fields.email}
							label={{}}
						/>
					</FormWidthElementLayout>
					{personCommunicationData.emailVerified && (
						<Button
							variant={'primary'}
							type={'base'}
							size={'m'}
							onClick={() => {
								personCommunicationData.control.isEditingEmail.setFalse();
								onReset();
							}}
							wcagLabel={wcagLabels.cancel}>
							<GovIcon
								name="x-lg"
								slot="right-icon"
							/>
						</Button>
					)}
				</div>
			) : (
				<div className={'inline-flex items-center gap-2 mb-1'}>
					<strong>{email}</strong>
					<Button
						onClick={personCommunicationData.control.isEditingEmail.setTrue}
						wcagLabel={wcagLabels.edit}
						variant={'primary'}
						size={'s'}
						type={'base'}>
						<GovIcon
							name="pencil-square"
							slot="right-icon"
						/>
					</Button>
				</div>
			)}
		</div>
	);
};
