import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import { FormDefinition } from '@gov-nx/core/service';
import { IUseBoolean } from '@gov-nx/utils/common';

export interface ContactDataSection<FormData extends FieldValues> {
	isReloading: boolean;

	formDefinition: FormDefinition<FormData>;
	valueVerified?: string;
	isDeleteConfirmationOpen: IUseBoolean;
	isEditing: IUseBoolean;
	isNeedForVerification: boolean;

	onResendVerification?: () => void;
	isVerificationResending: boolean;

	onChange: () => void;
	isChanging: IUseBoolean;

	onDelete: () => void;
	isDeleting: boolean;

	onSubmit: () => void;
	onPersonalContactsUse: (value: string) => void;
	isUpdating: boolean;
	isFormOpen: boolean;
}

export interface UpdateContactHook<FormData> {
	update: UseMutateFunction<void, AxiosError, FormData>;
	isUpdating: boolean;

	verify: UseMutateFunction<void, AxiosError, FormData>;
	isVerifying: boolean;

	delete: UseMutateFunction<void, AxiosError>;
	isDeleting: boolean;

	resendVerification: UseMutateFunction<void, AxiosError>;
	isVerificationResending: boolean;
}

type ROSSource = { source: 'ROS'; ico: number };
type ROBSource = { source: 'ROB' };
export type ContactSource = ROSSource | ROBSource;

export const isROSSource = (
	source: ROSSource | ROBSource
): source is ROSSource => source.source === 'ROS';
