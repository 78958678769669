import {
	GovAttachments,
	GovAttachmentsItem,
} from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { FileState, FileUploadItem } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { WebMessagesProps } from '@gov-nx/ui/types';
import { formatBytes } from '@gov-nx/utils/common';
import { Space } from '../../Space/Space';
import { FormMessage } from '../FormMessage';

const variant = (fileState: FileState) => {
	if (fileState === FileState.success || fileState === FileState.error) {
		return fileState;
	}
	return 'secondary';
};

const messages = (file: FileUploadItem): WebMessagesProps['messages'] => {
	if (file.message) {
		return {
			variant: variant(file.state),
			children: file.message,
		};
	}
	return [];
};

export interface FormUploadAttachmentsProps {
	files: FileUploadItem[];
	onRemove: (fileId: string) => void;
}

export const FormUploadAttachments = ({
	files,
	onRemove,
}: FormUploadAttachmentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);

	if (files.length === 0) {
		return null;
	}

	return (
		<div>
			<h2 className={'mt-4'}>{ts('nahrani-souboru.prilohy')}</h2>
			<GovAttachments>
				<div>
					{files
						.sort((a, b) => a.index - b.index)
						.map((file) => {
							return (
								<GovAttachmentsItem
									key={file.id}
									wcagRemoveLabel={t('nahrani-souboru.wcag.odstranit-soubor', {
										namespace: LocalizeNameSpaceTypes.Form,
										fileName: file.name,
									})}
									onGov-remove={() => onRemove(file.id)}>
									{file.name}
									<Space />
									<span slot={'info'}>({formatBytes(file.size)})</span>
									<div slot={'message'}>
										<FormMessage
											messages={messages(file)}
											loading={file.showLargeFileLoader}
										/>
									</div>
								</GovAttachmentsItem>
							);
						})}
				</div>
			</GovAttachments>
		</div>
	);
};
