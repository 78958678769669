import { ServiceCode } from './definitions/codes';
import { NeverejnyVypisROSContextInstance } from './neverejny-vypis-z-ROS/Context';
import { ServiceContextProvider as NeverejnyVypisZROSProvider } from './neverejny-vypis-z-ROS/Context';
import { useNeverejnyVypisZZRContext } from './neverejny-vypis-z-ZR/Context';
import { ServiceContextProvider as NeverejnyVypisZZRProvider } from './neverejny-vypis-z-ZR/Context';
import { PodaniZadostiInformaceContextInstance } from './podani-zadosti-o-informace/Context';
import { ServiceContextProvider as PodaniZadostiOInformaceProvider } from './podani-zadosti-o-informace/Context';
import { PotvrzeniStudiuContextInstance } from './potvrzeni-o-studiu/Context';
import { ServiceContextProvider as PotvrzeniOStudiuProvider } from './potvrzeni-o-studiu/Context';
import { TrestniOznameniContextInstance } from './trestni-oznameni/Context';
import { ServiceContextProvider as TrestniOznameniProvider } from './trestni-oznameni/Context';
import { VerejnyVypisROBContextInstance } from './verejny-vypis-z-ROS/Context';
import { ServiceContextProvider as VerejnyVypisZROSProvider } from './verejny-vypis-z-ROS/Context';
import { VolicskyPrukazDetailContextInstance } from './volicsky-prukaz/detail/Context';
import { VolicskyPrukazDetailContextProvider as VolicskyPrukazDetailProvider } from './volicsky-prukaz/detail/Context';
import { VolicskyPrukazSeznamContextInstance } from './volicsky-prukaz/seznam/Context';
import { ServiceContextProvider as VolicskyPrukazSeznamProvider } from './volicsky-prukaz/seznam/Context';
import { VypisBodovehoHodnoceniRidiceContextInstance } from './vypis-bodoveho-hodnoceni-ridice/Context';
import { ServiceContextProvider as VypisBodovehoHodnoceniRidiceProvider } from './vypis-bodoveho-hodnoceni-ridice/Context';
import { VypisUdajuZROBContextInstance } from './vypis-udaju-z-ROB/Context';
import { ServiceContextProvider as VypisUdajuZROBProvider } from './vypis-udaju-z-ROB/Context';
import { VypisVyuzitiUdajuZROBContextInstance } from './vypis-vyuziti-udaju-z-ROB/Context';
import { ServiceContextProvider as VypisVyuzitiUdajuZROBProvider } from './vypis-vyuziti-udaju-z-ROB/Context';
import { VypisVyuzitiUdajuZROSContextInstance } from './vypis-vyuziti-udaju-z-ROS/Context';
import { ServiceContextProvider as VypisVyuzitiUdajuZROSProvider } from './vypis-vyuziti-udaju-z-ROS/Context';
import { VypisInsolvencnihoRejstrikuContextInstance } from './vypis-z-IR/Context';
import { ServiceContextProvider as VypisZIRProvider } from './vypis-z-IR/Context';
import { VypisRejstrikuTrestuFOContextInstance } from './vypis-z-rejstriku-trestu-FO/Context';
import { ServiceContextProvider as VypisZRejstrikuTrestuFOProvider } from './vypis-z-rejstriku-trestu-FO/Context';
import { VypisRejstrikuTrestuPOContextInstance } from './vypis-z-rejstriku-trestu-PO/Context';
import { ServiceContextProvider as VypisZRejstrikuTrestuPOProvider } from './vypis-z-rejstriku-trestu-PO/Context';
import { VypisZVerejnehoRejstrikuContextInstance } from './vypis-z-verejneho-rejstriku/Context';
import { ServiceContextProvider as VypisZVerejnehoRejstrikuProvider } from './vypis-z-verejneho-rejstriku/Context';
import { VypisZivnostenskehoRejstrikuContextInstance } from './vypis-z-zivnostenskeho-rejstriku/Context';
import { ServiceContextProvider as VypisZZivnostenskehoRejstrikuProvider } from './vypis-z-zivnostenskeho-rejstriku/Context';
import { VypisSeznamuDodavateluContextInstance } from './vypis-ze-seznamu-KD/Context';
import { ServiceContextProvider as VypisZeSeznamuKDProvider } from './vypis-ze-seznamu-KD/Context';
import { ZadostOOsvedceniDIGUkonuContextInstance } from './zadost-o-osvedceni-DIG-ukonu/Context';
import { ServiceContextProvider as ZadostOOsvedceniDIGUkonuProvider } from './zadost-o-osvedceni-DIG-ukonu/Context';
import { ZadostOPoskytnutiUdajuContextInstance } from './zadost-o-poskytovani-udaju/Context';
import { ServiceContextProvider as ZadostOPoskytovaniUdajuProvider } from './zadost-o-poskytovani-udaju/Context';

export {
	ZadostOPoskytnutiUdajuReklamaceContextProvider,
	ZadostOPoskytnutiUdajuReklamaceContextInstance,
} from './zadost-o-poskytovani-udaju/reklamace/Context';

export type { NahledyUdajuResponse } from './vypis-bodoveho-hodnoceni-ridice/service.types';
export type {
	FormDataStep2,
	ScopeHelperField,
} from './zadost-o-poskytovani-udaju/service.types';
export type {
	TrestniOznameniFormDataStep1,
	TrestniOznameniFormDataStep2,
	TrestniOznameniFormDataStep3,
	TrestniOznameniFormDataStep4,
	TrestniOznameniFormDataStep5,
	TrestniOznameniFormDataStep6,
	TrestniOznameniFormData,
} from './trestni-oznameni/service.types';
export {
	TrestniOznameniGender,
	TrestniOznameniGroupTarget,
} from './trestni-oznameni/service.types';
export * from './definitions/ServiceMap';
export * from './definitions/services';
export * from './definitions/codes';
export * from './potvrzeni-o-studiu/Lists';
export * from './volicsky-prukaz/detail/utils';

const ServiceProviderList = {
	[ServiceCode['vypis-z-rejstriku-trestu-FO']]: VypisZRejstrikuTrestuFOProvider,
	[ServiceCode['vypis-z-rejstriku-trestu-PO']]: VypisZRejstrikuTrestuPOProvider,
	[ServiceCode['verejny-vypis-z-ROS']]: VerejnyVypisZROSProvider,
	[ServiceCode['neverejny-vypis-z-ROS']]: NeverejnyVypisZROSProvider,
	[ServiceCode['vypis-z-zivnostenskeho-rejstriku']]:
		VypisZZivnostenskehoRejstrikuProvider,
	[ServiceCode['neverejny-vypis-z-ZR']]: NeverejnyVypisZZRProvider,
	[ServiceCode['vypis-vyuziti-udaju-z-ROB']]: VypisVyuzitiUdajuZROBProvider,
	[ServiceCode['vypis-vyuziti-udaju-z-ROS']]: VypisVyuzitiUdajuZROSProvider,
	[ServiceCode['vypis-ze-seznamu-KD']]: VypisZeSeznamuKDProvider,
	[ServiceCode['vypis-z-IR']]: VypisZIRProvider,
	[ServiceCode['zadost-o-osvedceni-DIG-ukonu']]:
		ZadostOOsvedceniDIGUkonuProvider,
	[ServiceCode['vypis-bodoveho-hodnoceni-ridice']]:
		VypisBodovehoHodnoceniRidiceProvider,
	[ServiceCode['vypis-z-verejneho-rejstriku']]:
		VypisZVerejnehoRejstrikuProvider,
	[ServiceCode['podani-zadosti-o-informace']]: PodaniZadostiOInformaceProvider,
	[ServiceCode['potvrzeni-o-studiu']]: PotvrzeniOStudiuProvider,
	[ServiceCode['vypis-udaju-z-ROB']]: VypisUdajuZROBProvider,
	[ServiceCode['zadost-o-poskytovani-udaju']]: ZadostOPoskytovaniUdajuProvider,
	[ServiceCode['trestni-oznameni']]: TrestniOznameniProvider,
	[ServiceCode['volicsky-prukaz']]: VolicskyPrukazSeznamProvider,
};

export const VolicskyPrukazDetailContextProvider = VolicskyPrukazDetailProvider;

export const getServiceContextProvider = (code: ServiceCode) =>
	ServiceProviderList[code];

export const getVypisRejstrikuTrestuFOContext =
	VypisRejstrikuTrestuFOContextInstance;
export const getVypisRejstrikuTrestuPOContext =
	VypisRejstrikuTrestuPOContextInstance;
export const getVerejnyVypisROBContext = VerejnyVypisROBContextInstance;
export const getNeverejnyVypisROSContext = NeverejnyVypisROSContextInstance;
export const getVypisZivnostenskehoRejstrikuContext =
	VypisZivnostenskehoRejstrikuContextInstance;
export const getNeverejnyVypisZZRContext = useNeverejnyVypisZZRContext;
export const getVypisVyuzitiUdajuZROBContextInstance =
	VypisVyuzitiUdajuZROBContextInstance;
export const getVypisVyuzitiUdajuZROSContextInstance =
	VypisVyuzitiUdajuZROSContextInstance;
export const getVypisSeznamuDodavateluContextInstance =
	VypisSeznamuDodavateluContextInstance;
export const getVypisInsolvencnihoRejstrikuContextInstance =
	VypisInsolvencnihoRejstrikuContextInstance;
export const getZadostOOsvedceniDigitalnihoUkonuContextInstance =
	ZadostOOsvedceniDIGUkonuContextInstance;
export const getVypisBodovehoHodnoceniRidiceContext =
	VypisBodovehoHodnoceniRidiceContextInstance;
export const getVypisZVerejnehoRejstrikuContextInstance =
	VypisZVerejnehoRejstrikuContextInstance;
export const getPodaniZadostiInformaceContext =
	PodaniZadostiInformaceContextInstance;
export const getPotvrzeniStudiuContext = PotvrzeniStudiuContextInstance;
export const getVypisUdajuZROBContextInstance = VypisUdajuZROBContextInstance;
export const getZadostOPoskytnutiUdajuContextInstance =
	ZadostOPoskytnutiUdajuContextInstance;
export const getTrestniOznameniContextInstance = TrestniOznameniContextInstance;

export const getVolicskyPrukazSeznamContextInstance =
	VolicskyPrukazSeznamContextInstance;
export const getVolicskyPrukazDetailContextInstance =
	VolicskyPrukazDetailContextInstance;

export * from './types/serviceTypeHelpers';
export * from './volicsky-prukaz/detail/context.types';
