import { useEffect } from 'react';
import * as yup from 'yup';
import { PersonCommunicationData } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	LocalizeNameSpaceTypes,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	equals,
	getEntries,
	getKeys,
	getValues,
	is,
	Optional,
	pairs,
	prop,
} from '@gov-nx/core/types';
import { RadioBooleanOption } from '@gov-nx/ui/types';
import {
	dataBoxParams,
	getAttachmentsIds,
	today,
	toStringDate,
	useDataBoxShape,
	useDateShape,
	usePhoneShape,
	useZipCodeShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import {
	DataRequest,
	TrestniOznameniFormData,
	TrestniOznameniFormDataStep1,
	TrestniOznameniFormDataStep2,
	TrestniOznameniFormDataStep3,
	TrestniOznameniFormDataStep4,
	TrestniOznameniFormDataStep5,
	TrestniOznameniFormDataStep6,
	TrestniOznameniGender,
	TrestniOznameniGroupTarget,
} from './service.types';

const noneIsTrue = (...fields: Optional<boolean>[]) =>
	!fields.some(equals(true));

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstanceStep1({
	code,
}: FormInstanceProps): FormDefinition<TrestniOznameniFormDataStep1> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const groupTargetKeys = getKeys(TrestniOznameniGroupTarget);

	const restOfCheckboxes = (
		except: keyof typeof TrestniOznameniGroupTarget
	) => {
		return groupTargetKeys.filter((r) => r !== except);
	};
	const checkboxRequiredMessage = tsn(
		'formular.validace.popis-skupiny.povinny'
	);

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormData>>()
		.shape(
			{
				popisProblemu: yup
					.string()
					.max(10000, tsn('formular.validace.popis-problemu.max'))
					.required(tsn('formular.validace.popis-problemu.povinny')),

				skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky: yup
					.boolean()
					.optional()
					.when(
						restOfCheckboxes('skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky'),
						{
							is: noneIsTrue,
							then: yup
								.boolean()
								.oneOf([true], checkboxRequiredMessage)
								.required(checkboxRequiredMessage),
						}
					),
				skupinyKtereSeOdlisujiUrcitymiTypickymiZnakyPopis: yup
					.string()
					.optional()
					.when('skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),

				etnickeANarodnostiSkupiny: yup
					.boolean()
					.when(restOfCheckboxes('etnickeANarodnostiSkupiny'), {
						is: noneIsTrue,
						then: yup
							.boolean()
							.oneOf([true], checkboxRequiredMessage)
							.required(checkboxRequiredMessage),
					}),
				etnickeANarodnostiSkupinyPopis: yup
					.string()
					.optional()
					.when('etnickeANarodnostiSkupiny', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),

				skupinyDefinovaneVyznanim: yup
					.boolean()
					.optional()
					.when(restOfCheckboxes('skupinyDefinovaneVyznanim'), {
						is: noneIsTrue,
						then: yup
							.boolean()
							.oneOf([true], checkboxRequiredMessage)
							.required(checkboxRequiredMessage),
					}),
				skupinyDefinovaneVyznanimPopis: yup
					.string()
					.optional()
					.when('skupinyDefinovaneVyznanim', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),
				sexualniMensiny: yup
					.boolean()
					.optional()
					.when(restOfCheckboxes('sexualniMensiny'), {
						is: noneIsTrue,
						then: yup
							.boolean()
							.oneOf([true], checkboxRequiredMessage)
							.required(checkboxRequiredMessage),
					}),
				sexualniMensinyPopis: yup
					.string()
					.optional()
					.when('sexualniMensiny', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),

				skupinyDefinovanePolitickymPresvedcenim: yup
					.boolean()
					.optional()
					.when(restOfCheckboxes('skupinyDefinovanePolitickymPresvedcenim'), {
						is: noneIsTrue,
						then: yup
							.boolean()
							.oneOf([true], checkboxRequiredMessage)
							.required(checkboxRequiredMessage),
					}),
				skupinyDefinovanePolitickymPresvedcenimPopis: yup
					.string()
					.optional()
					.when('skupinyDefinovanePolitickymPresvedcenim', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),

				jine: yup
					.boolean()
					.optional()
					.when(restOfCheckboxes('jine'), {
						is: noneIsTrue,
						then: yup
							.boolean()
							.oneOf([true], checkboxRequiredMessage)
							.required(checkboxRequiredMessage),
					}),
				jinePopis: yup
					.string()
					.optional()
					.when('jine', {
						is: true,
						then: yup
							.string()
							.max(250, tsn('formular.validace.skupina.max'))
							.required(tsn('formular.validace.skupina-popis.povinny')),
					}),
			},
			pairs(groupTargetKeys)
		)
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep1>({
		formSchema,
		defaultValues: {
			popisProblemu: undefined,

			skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky: false,
			skupinyKtereSeOdlisujiUrcitymiTypickymiZnakyPopis: undefined,

			etnickeANarodnostiSkupiny: false,
			etnickeANarodnostiSkupinyPopis: undefined,

			skupinyDefinovaneVyznanim: false,
			skupinyDefinovaneVyznanimPopis: undefined,

			sexualniMensiny: false,
			sexualniMensinyPopis: undefined,

			skupinyDefinovanePolitickymPresvedcenim: false,
			skupinyDefinovanePolitickymPresvedcenimPopis: undefined,

			jine: false,
			jinePopis: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep2({
	code,
}: FormInstanceProps): FormDefinition<TrestniOznameniFormDataStep2> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormDataStep2>>({
			datumZjisteni: getDatePickerShape().max(
				today(),
				tsn('formular.validace.datum-zjisteni.max')
			),

			verejnePristupny: yup.string().oneOf(getKeys(RadioBooleanOption)),
			verejnePristupnyKomu: yup
				.string()
				.optional()
				.when('verejnePristupny', {
					is: RadioBooleanOption.yes,
					then: yup
						.string()
						.max(250, tsn('formular.validace.verejne-pristupny.max')),
				}),
			printscreen: yup.object().nullable().optional(),
			priloha: yup.object().nullable().optional(),
		})
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep2>({
		formSchema,
		defaultValues: {
			datumZjisteni: undefined,
			verejnePristupny: undefined,
			verejnePristupnyKomu: undefined,
			printscreen: undefined,
			priloha: undefined,
		},
	});
	const publicAvailable = formMethods.watch('verejnePristupny');

	useEffect(() => {
		if (publicAvailable === RadioBooleanOption.no) {
			formMethods.setValue('verejnePristupnyKomu', '');
			formMethods.resetField('verejnePristupnyKomu');
		}
	}, [publicAvailable]);

	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep3({
	code,
}: FormInstanceProps): FormDefinition<TrestniOznameniFormDataStep3> {
	const { getZipCodeShape } = useZipCodeShape();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormDataStep3>>({
			jePachatelZnam: yup
				.string()
				.oneOf(getKeys(RadioBooleanOption))
				.required(tsn('formular.validace.je-pachatel-znam.povinny')),

			pachatelJmenoPrijmeni: yup
				.string()
				.max(250, tsn('formular.validace.pachatel-info.max'))
				.optional(),
			pachatelUlice: yup
				.string()
				.max(250, tsn('formular.validace.pachatel-info.max'))
				.optional(),
			pachatelObec: yup
				.string()
				.max(250, tsn('formular.validace.pachatel-info.max'))
				.optional(),
			pachatelPsc: getZipCodeShape().optional(),
			pachatelStatniPrislusnost: yup.string().optional(),
			pachatelPohlavi: yup
				.string()
				.oneOf(getValues(TrestniOznameniGender))
				.optional(),
			pachatelVek: yup
				.number()
				.min(0, tsn('formular.validace.vek.min'))
				.optional(),
			pachatelZjisten: yup
				.string()
				.max(10000, tsn('formular.validace.pachatel-zjisten.max'))
				.optional(),
			pachatelLink: yup
				.string()
				.max(250, tsn('formular.validace.pachatel-info.max'))
				.optional(),
			pachatelJine: yup
				.string()
				.max(10000, tsn('formular.validace.pachatel-jine.max'))
				.optional(),
		})
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep3>({
		formSchema,
		defaultValues: {
			jePachatelZnam: undefined,
			pachatelJmenoPrijmeni: undefined,
			pachatelUlice: undefined,
			pachatelObec: undefined,
			pachatelPsc: undefined,
			pachatelStatniPrislusnost: undefined,
			pachatelPohlavi: undefined,
			pachatelVek: undefined,
			pachatelZjisten: undefined,
			pachatelLink: undefined,
			pachatelJine: undefined,
		},
	});

	const isPerpetratorKnown = formMethods.watch('jePachatelZnam');

	useEffect(() => {
		if (isPerpetratorKnown === RadioBooleanOption.no) {
			formMethods.reset({
				jePachatelZnam: RadioBooleanOption.no,
			});
		}
	}, [isPerpetratorKnown]);

	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep4({
	code,
}: FormInstanceProps): FormDefinition<TrestniOznameniFormDataStep4> {
	const { getZipCodeShape } = useZipCodeShape();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormDataStep4>>({
			jeObetZnama: yup
				.string()
				.oneOf(getKeys(RadioBooleanOption))
				.required(tsn('formular.validace.je-obet-znama.povinny')),
			obetJmenoPrijmeni: yup
				.string()
				.max(250, tsn('formular.validace.obet-info.max'))
				.optional(),
			obetUlice: yup
				.string()
				.max(250, tsn('formular.validace.obet-info.max'))
				.optional(),
			obetObec: yup
				.string()
				.max(250, tsn('formular.validace.obet-info.max'))
				.optional(),
			obetPsc: getZipCodeShape().optional(),
			obetPohlavi: yup
				.string()
				.oneOf(getValues(TrestniOznameniGender))
				.optional(),
			obetVek: yup.number().min(0, tsn('formular.validace.vek.min')).optional(),
			obetJine: yup
				.string()
				.max(10000, tsn('formular.validace.obet-jine.max'))
				.optional(),
			obetLink: yup
				.string()
				.max(250, tsn('formular.validace.obet-info.max'))
				.optional(),
		})
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep4>({
		formSchema,
		defaultValues: {
			jeObetZnama: undefined,
			obetJmenoPrijmeni: undefined,
			obetUlice: undefined,
			obetObec: undefined,
			obetPsc: undefined,
			obetPohlavi: undefined,
			obetVek: undefined,
			obetJine: undefined,
			obetLink: undefined,
		},
	});

	const isVictimKnow = formMethods.watch('jeObetZnama');

	useEffect(() => {
		if (isVictimKnow === RadioBooleanOption.no) {
			formMethods.reset({
				jeObetZnama: RadioBooleanOption.no,
			});
		}
	}, [isVictimKnow]);

	return getFormDefinition({ formMethods, formSchema });
}

interface FormInstanceStep5Props extends FormInstanceProps {
	personCommunicationData: PersonCommunicationData;
}

export function FormInstanceStep5({
	personCommunicationData,
}: FormInstanceStep5Props): FormDefinition<TrestniOznameniFormDataStep5> {
	const { getLocalize } = useTranslationWithNamespace();
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormDataStep5>>({
			zadostOVyrozumeni: yup.string().oneOf(getKeys(RadioBooleanOption)),
			_useKontaktTel: yup.boolean().optional(),
			kontaktTel: getPhoneShape(),
			_useKontaktEmail: yup.boolean().optional(),
			kontaktEmail: yup
				.string()
				.email(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.email-neni-ve-spravnem-tvaru'
					)
				)
				.optional(),
		})
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep5>({
		formSchema,
		defaultValues: {
			_useKontaktEmail: !!personCommunicationData.emailVerified,
			_useKontaktTel: !!personCommunicationData.phoneVerified,
			zadostOVyrozumeni: RadioBooleanOption.yes,
			kontaktTel: personCommunicationData.phoneVerified,
			kontaktEmail: personCommunicationData.emailVerified,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep6(): FormDefinition<TrestniOznameniFormDataStep6> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<TrestniOznameniFormDataStep6>>({
			...getDataBoxShape({ isRequired: true }),
		})
		.required();

	const formMethods = usePoForm<TrestniOznameniFormDataStep6>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

const prepareCheckboxes = (
	formData: TrestniOznameniFormData
): { name: TrestniOznameniGroupTarget; description: string }[] =>
	getEntries(TrestniOznameniGroupTarget)
		.map(([name, value]) =>
			formData[name]
				? { name: value, description: formData[`${name}Popis`] }
				: undefined
		)
		.filter(is);

export const prepareSubmitData = (
	formData: TrestniOznameniFormData
): DataRequest => {
	const checkboxes = prepareCheckboxes(formData);

	const { datovaSchrankaId, ...params } = dataBoxParams(formData);

	return {
		params: {
			odesilatelDSId: datovaSchrankaId,
			...params,
		},
		body: {
			kodPodani: 'HATECRIME2',

			popisProblemu: formData.popisProblemu,
			vedenoProtiSkupine: checkboxes.map(prop('name')),
			vedenoProtiSkupinePopis: checkboxes.map(prop('description')),

			datumZjisteni: formData.datumZjisteni
				? toStringDate(formData.datumZjisteni)
				: undefined,
			verejnePristupny: formData.verejnePristupny === RadioBooleanOption.yes,
			verejnePristupnyKomu: formData.verejnePristupnyKomu,
			priloha: getAttachmentsIds(formData.priloha),
			printscreen: getAttachmentsIds(formData.printscreen),

			pachatelJmenoPrijmeni: formData.pachatelJmenoPrijmeni,
			pachatelUlice: formData.pachatelUlice,
			pachatelObec: formData.pachatelObec,
			pachatelPsc: formData.pachatelPsc,
			pachatelStatniPrislusnost: formData.pachatelStatniPrislusnost,
			pachatelPohlavi: formData.pachatelPohlavi,
			pachatelVek: formData.pachatelVek,
			pachatelZjisten: formData.pachatelZjisten,
			pachatelLink: formData.pachatelLink,
			pachatelJine: formData.pachatelJine,

			obetJmenoPrijmeni: formData.obetJmenoPrijmeni,
			obetUlice: formData.obetUlice,
			obetObec: formData.obetObec,
			obetPsc: formData.obetPsc,
			obetPohlavi: formData.obetPohlavi,
			obetVek: formData.obetVek,
			obetJine: formData.obetJine,
			obetLink: formData.obetLink,

			zadostOVyrozumeni: formData.zadostOVyrozumeni === RadioBooleanOption.yes,
			kontaktTel: formData._useKontaktTel ? formData.kontaktTel : undefined,
			kontaktEmail: formData._useKontaktEmail
				? formData.kontaktEmail
				: undefined,
		},
	};
};
