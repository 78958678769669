import cx from 'classnames';
import React from 'react';
import { LayoutGap, LayoutGapProps } from './LayoutGap';

export interface FormSideOffsetLayoutProps extends LayoutGapProps {
	pl?: number;
}

export const FormSideOffsetLayout = ({
	children,
	gap,
	pl = 10,
	className,
}: FormSideOffsetLayoutProps) => {
	return (
		<LayoutGap
			gap={gap}
			className={cx(pl && `pl-${pl}`, className && className)}>
			{children}
		</LayoutGap>
	);
};
