import { GovWizard } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeNovaContext } from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner } from '@gov-nx/ui/web';
import { WizardStepWeb } from '../../../Wizard/WizardStepWeb';
import { EpetitionInfoBar } from '../form/EPetitionFormInfoBar';
import { EPetitionFormSkeleton } from '../form/EPetitionFormSkeleton';
import { WizardStep1 } from '../form/WizardStep1';

interface EPetitionNewViewProps {
	code: PageCode;
}

export const EPetitionNewView = ({ code }: EPetitionNewViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const {
		wizard,
		applicant,
		isLoading,
		isDisabled,
		formDefinition,
		controls,
		loginInfo,
	} = useEPeticeNovaContext();

	if (controls.initialLoading) {
		return <EPetitionFormSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (loginInfo.isDataBoxLoginType) {
		return <EpetitionInfoBar content={tsn('pole.varovani.datova-schranka')} />;
	}

	if (loginInfo.isLowLoa) {
		return <EpetitionInfoBar content={tsn('pole.varovani.low-loa')} />;
	}

	return (
		<ContentLayout customClasses={'bg-transparent px-0 [ md:px-0 ]'}>
			<GovWizard>
				<WizardStepWeb
					index={0}
					label={tsn('formular.zprava.zakladni-informace')}
					wizard={wizard}>
					<WizardStep1
						code={code}
						applicant={applicant}
						isLoading={isLoading}
						isDisabled={isDisabled}
						formDefinition={formDefinition}
					/>
				</WizardStepWeb>
				<WizardStepWeb
					index={1}
					label={tsn('formular.zprava.informace-o-petici')}
					wizard={wizard}>
					{null}
				</WizardStepWeb>
				<WizardStepWeb
					index={2}
					label={tsn('formular.zprava.peticni-vybor')}
					wizard={wizard}>
					{null}
				</WizardStepWeb>
				<WizardStepWeb
					index={3}
					label={tsn('formular.zprava.rekapitulace')}
					wizard={wizard}>
					{null}
				</WizardStepWeb>
			</GovWizard>
		</ContentLayout>
	);
};
