import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { FilterParam } from '@gov-nx/core/types';
import { Button } from '../Button/Button';

export const FilterParameterItem = ({
	label,
	value,
	onRemove,
}: FilterParam) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<li>
			<dl className={'flex items-center gap-1 bg-primary-200 rounded'}>
				<dt className={'pl-4'}>{label}</dt>
				<dd className={'font-bold'}>{value}</dd>
				<Button
					onClick={onRemove}
					variant="primary"
					type="base"
					size="s"
					wcagLabel={t('akce.wcag.zrusit-tento-filtr') + ` ${label} ${value}`}>
					<GovIcon
						slot="right-icon"
						name="x-lg"
					/>
				</Button>
			</dl>
		</li>
	);
};
