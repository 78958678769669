import * as yup from 'yup';

export type AutocompleteFormData<Selected> = {
	selected?: Selected;
	input?: string;
	value?: string;
};

export const getAutocompleteDefaultValues = () => ({
	value: undefined,
	input: undefined,
	selected: undefined,
});

export const getAutocompleteShape = (props?: { requiredMessage: string }) => {
	if (props?.requiredMessage) {
		return yup
			.object({
				value: yup.string(),
				input: yup.string(),
				selected: yup.object().nullable().required(props.requiredMessage),
			})
			.required(props.requiredMessage);
	}

	return yup.object({
		value: yup.string(),
		input: yup.string(),
		selected: yup.object(),
	});
};
