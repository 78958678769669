import {
	isSameWeek as isSameWeekFns,
	isSameMonth as isSameMonthFns,
	startOfWeek,
} from 'date-fns';
import { today } from '@gov-nx/utils/common';
import { NotificationDto } from './context.types';

enum TimePeriodKey {
	Dnes = 'dnes',
	TentoTyden = 'tento-tyden',
	MinulyTyden = 'minuly-tyden',
	TentoMesic = 'tento-mesic',
	MinulyMesic = 'minuly-mesic',
	TentoRok = 'tento-rok',
	MinulyRok = 'minuly-rok',
	Starsi = 'starsi',
}

export interface TimePeriod {
	key: TimePeriodKey;
	keyWeight: number;
}

export const getTimePeriod = (datumACasVytvoreni: string): TimePeriod => {
	const now = today();
	const creationDate = new Date(datumACasVytvoreni);

	const timeDifference = now.getTime() - creationDate.getTime();
	const daysDifference = timeDifference / (24 * 60 * 60 * 1000);

	if (daysDifference <= 0) {
		return { key: TimePeriodKey.Dnes, keyWeight: 1 };
	} else if (isSameWeek(now, creationDate)) {
		return { key: TimePeriodKey.TentoTyden, keyWeight: 2 };
	} else if (isLastWeek(now, creationDate)) {
		return { key: TimePeriodKey.MinulyTyden, keyWeight: 3 };
	} else if (isSameMonth(now, creationDate)) {
		return { key: TimePeriodKey.TentoMesic, keyWeight: 4 };
	} else if (isLastMonth(now, creationDate)) {
		return { key: TimePeriodKey.MinulyMesic, keyWeight: 5 };
	} else if (isSameYear(now, creationDate)) {
		return { key: TimePeriodKey.TentoRok, keyWeight: 6 };
	} else if (isLastYear(now, creationDate)) {
		return { key: TimePeriodKey.MinulyRok, keyWeight: 7 };
	} else {
		return { key: TimePeriodKey.Starsi, keyWeight: 8 };
	}
};

export interface NotificationPeriodization {
	key: TimePeriodKey;
	keyWeight: number;
	notifications: NotificationDto[];
	label: string;
}

export const categorizeNotifications = (
	notifications: NotificationDto[],
	ts: (key: string) => string
): NotificationPeriodization[] => {
	return notifications.reduce((all, current) => {
		const period = current.datumACasVytvoreni
			? getTimePeriod(current.datumACasVytvoreni)
			: undefined;
		if (!period) {
			return all;
		}

		const index = all.findIndex((x) => x.key === period.key);

		if (index !== -1) {
			all[index] = {
				...all[index],
				notifications: [...all[index].notifications, current],
			};
		} else {
			all.push({
				key: period.key,
				keyWeight: period.keyWeight,
				label: ts(`kategorie.casove-deleni.${period.key}`),
				notifications: [current],
			});
		}

		all.sort((a, b) => a.keyWeight - b.keyWeight);

		return all;
	}, [] as NotificationPeriodization[]);
};

export const getLink = (data: NotificationDto[], notificationId: bigint) => {
	const selectedNotification = data?.find((item) => item.id === notificationId);
	return {
		url: selectedNotification?.rozsirujiciInformace?.odkazUrl ?? undefined,
		urlText: selectedNotification?.rozsirujiciInformace?.odkazText ?? undefined,
	};
};

const isSameWeek = (date1: Date, date2: Date) => {
	const startDate1 = startOfWeek(date1, { weekStartsOn: 1 });
	const startDate2 = startOfWeek(date2, { weekStartsOn: 1 });
	return isSameWeekFns(startDate1, startDate2);
};

const isLastWeek = (date1: Date, date2: Date) => {
	const oneWeekAgo = new Date(date1.getTime() - 7 * 24 * 60 * 60 * 1000);
	const startDate1 = startOfWeek(oneWeekAgo, { weekStartsOn: 1 });
	const startDate2 = startOfWeek(date2, { weekStartsOn: 1 });
	return isSameWeekFns(startDate1, startDate2);
};

const isSameMonth = (date1: Date, date2: Date) => {
	return isSameMonthFns(date1, date2);
};

const isLastMonth = (date1: Date, date2: Date) => {
	const oneMonthAgo = new Date(date1);
	oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
	return isSameMonthFns(oneMonthAgo, date2);
};

const isSameYear = (date1: Date, date2: Date) => {
	return date1.getFullYear() === date2.getFullYear();
};

const isLastYear = (date1: Date, date2: Date) => {
	const oneYearAgo = new Date(date1);
	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
	return isSameYear(oneYearAgo, date2);
};
