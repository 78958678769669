import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { compare, hasProperty } from '@gov-nx/core/types';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import { DataBoxFormData, FormSelectOption } from '@gov-nx/ui/types';

export const dataBoxParams = <T extends DataBoxFormData>(
	formData: T
): DataBoxFormData => {
	return {
		datovaSchrankaId: formData.datovaSchrankaId,
		naseCisloJednaci: formData.naseCisloJednaci,
		naseSpisovaZnacka: formData.naseSpisovaZnacka,
	};
};

interface DataBoxShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	fileNumberField?: {
		maxLengthValidationCopy?: string;
	};
	referenceNumberField?: {
		maxLengthValidationCopy?: string;
	};
}

export const useDataBoxShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getDataBoxDefaultValues: () => ({
			datovaSchrankaId: undefined,
			naseCisloJednaci: undefined,
			naseSpisovaZnacka: undefined,
		}),
		getDataBoxShape: ({
			isRequired,
			isRequiredMessage,
			fileNumberField,
			referenceNumberField,
		}: DataBoxShape) => {
			let datovaSchrankaId = yup.string();
			if (isRequired) {
				datovaSchrankaId = datovaSchrankaId.required(
					isRequiredMessage ??
						t('data-box.validations.vyberte-datovou-schranku') ??
						undefined
				);
			}

			return {
				datovaSchrankaId,
				naseCisloJednaci: yup
					.string()
					.max(
						50,
						fileNumberField?.maxLengthValidationCopy ??
							t('data-box.messages.cislo-jednaci') ??
							undefined
					),
				naseSpisovaZnacka: yup
					.string()
					.max(
						50,
						referenceNumberField?.maxLengthValidationCopy ??
							t('data-box.messages.spisova-znacka') ??
							undefined
					),
			};
		},
	};
};

export const prepareDataBoxOptions = (
	dataBoxes: DatovkaApiType[]
): FormSelectOption[] => {
	return dataBoxes
		.filter(hasProperty('datovaSchrankaId'))
		.map((box) => {
			return {
				type: box.typSchranky,
				value: box.datovaSchrankaId,
				label: dataBoxLabel(box),
			};
		})
		.sort(compare(['type', 'label']));
};
