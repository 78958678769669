import { KosPolozkaKosDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { FormCheckbox, Space } from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';

interface DocumentTableRowBinnedProps {
	document: KosPolozkaKosDto;
	index: number;
}

export const TableRowBinned = ({
	document,
	index,
}: DocumentTableRowBinnedProps) => {
	const { controls, code } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const daysToDelete = document.dnuDoOdstraneni ?? 0;

	return (
		<tr
			key={document.id}
			className={
				(index % 2 === 0 ? 'bg-secondary-100 ' : '') +
				'relative py-6 border-b border-secondary-500 [ last-of-type:border-b-0 ]'
			}>
			<td className={'!w-60 py-6 pl-4 align-top [ lg:!w-[400px] ]'}>
				{/* // @TODO: v /api/kompozitni/v1/polozkakos nechodi nazevSouboru. Rozsirit API? Nebo radeji volat /api/v1/dokumenty?smazano=true */}
				<p
					className={
						'w-60 truncate text-s [ sm:w-80 ] [ md:w-full ] [ lg:text-m lg:w-96 ]'
					}>
					<strong>{document.nazev}</strong>
					<span className={'hidden [ lg:!inline ]'}>
						{' '}
						/ {tsn(`data.typ.${document.typ}`)}
					</span>
				</p>
			</td>
			<td className={'py-6 pl-4 align-top text-xs [ lg:!hidden lg:pl-1 ]'}>
				{tsn(`data.typ.${document.typ}`)}
			</td>
			<td
				className={
					'py-6 pl-4 whitespace-nowrap align-top text-xs [ lg:text-m lg:pl-1 ]'
				}>
				{document.datumSmazani && toDateTimeReadable(document.datumSmazani)}
			</td>
			<td className={'py-6 pl-4 align-top text-xs [ lg:text-m lg:pl-1 ]'}>
				<span className={'[ lg:hidden ]'}>{tsn('data.odstraneni-za')}</span>
				<Space />
				<span className={daysToDelete < 10 ? 'text-error-400' : ''}>
					{daysToDelete}
					<Space />
					{tsn('data.odstraneni-za-dny', { count: daysToDelete })}
				</span>
			</td>
			<td
				className={
					'py-6 absolute top-6 right-0 [ lg:relative lg:top-auto lg:right-auto ]'
				}>
				<div className={'flex justify-end items-start'}>
					<FormCheckbox
						field={{
							name: `selection.${document.id}`,
							disabled: controls.processLoading,
							noLabel: true,
						}}
					/>
				</div>
			</td>
		</tr>
	);
};
