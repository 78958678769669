import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	getDataBoxIcon,
	getDataBoxTypeLabel,
} from '@gov-nx/module/data-box';
import { PageCode, useDashboardContextInstance } from '@gov-nx/module/page';
import { ContentLayout, NotificationBadge } from '@gov-nx/ui/web';
import { limitNumber } from '@gov-nx/utils/common';
import { UnreadMessagesSkeleton } from './UnreadMessagesSkeleton';

export const DashboardUnreadMessagesWidget = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		unreadMessages,
		showUnreadMessagesWidget,
		isLoading,
		unreadMessagesCount,
		hasUnreadMessages,
		dataBoxDateUtils: { getMessageDateLabel },
	} = useDashboardContextInstance();

	if (!showUnreadMessagesWidget) return null;

	return (
		<ContentLayout customClasses={'!p-0 !mx-0 mb-10'}>
			<Link
				to={routeResolver(PageCode['datove-schranky'])}
				className={
					'flex justify-start items-center gap-2 p-4 bg-primary-600 !text-neutral-white text-xl font-medium [ hover:bg-primary-700 ] [ md:-mx-0 md:-mt-0 md:rounded-t ]'
				}>
				<GovIcon
					name="datovka"
					type="basic"
					slot="icon"
					className={'w-6 h-6 !text-neutral-white'}></GovIcon>
				{tn('box-neprectene.neprectene-zpravy')}
				{hasUnreadMessages && (
					<NotificationBadge customClasses={'text-primary-700'}>
						{limitNumber(unreadMessagesCount)}
					</NotificationBadge>
				)}
				<GovIcon
					name="chevron-right"
					type="basic"
					slot="icon"
					className={'!ml-auto w-3 h-3 !text-neutral-white'}></GovIcon>
			</Link>

			{isLoading ? (
				<UnreadMessagesSkeleton />
			) : !unreadMessages.length ? (
				<div className={'flex flex-col items-center py-6 text-center'}>
					<GovIcon
						name="check-lg"
						type="basic"
						slot="icon"
						className={'w-6 h-6 text-primary-600'}></GovIcon>
					<span className={'text-xl'}>{tn('box-neprectene.nic-neuniklo')}</span>
					<span>{tn('box-neprectene.vsechny-prectene')}</span>
				</div>
			) : (
				<ul className={'gov-list--plain'}>
					{unreadMessages.map((message) => {
						return (
							<li
								className={
									'border-b border-secondary-500 [ last-of-type:border-b-0 ]'
								}
								key={message.datovaZpravaId}>
								<Link
									to={routeResolver(PageCode['datove-schranky-zprava'], {
										dataBoxId: message.prijemceId,
										folderType: DataBoxFolderType.Received,
										messageId: message.datovaZpravaId,
									})}
									className={
										'relative block p-4 text-primary-600 [ hover:bg-primary-200 ]'
									}>
									<span
										className={'absolute right-4 text-xs !text-secondary-700'}>
										{getMessageDateLabel(message.datumACasDodani as string)}
									</span>
									<div className={'text-lg mr-12 font-bold'}>
										{message.odesilatelNazev}
									</div>
									<div className={'mr-8 text-sm !text-secondary-700'}>
										{message.vec}
									</div>
									<div
										className={'flex items-center text-xs !text-secondary-700'}>
										<GovIcon
											name={getDataBoxIcon(message.typSchranky)}
											type="basic"
											slot="icon"
											className={'w-3 h-3 !mr-2'}></GovIcon>
										{message.prijemceNazev} -{' '}
										{getDataBoxTypeLabel(message.typSchranky, tn)}
									</div>
									<GovIcon
										name="chevron-right"
										type="basic"
										slot="icon"
										className={
											'absolute top-0 bottom-0 right-4 w-3 text-primary-600'
										}></GovIcon>
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</ContentLayout>
	);
};
