import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const UnreadMessagesSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	const wcagLabel = tg('loading.wcag.processing', {
		namespace: LocalizeNameSpaceTypes.Global,
	});

	return (
		<>
			{[0, 1].map((i) => {
				return (
					<div
						className={
							'p-4 pb-2 border-b border-secondary-300 [ last-of-type:border-0 ]'
						}
						key={i}>
						<div className={'flex gap-4 justify-between'}>
							<div style={{ width: generateRandomNumber(40, 80) + '%' }}>
								<GovSkeleton
									width={'100%'}
									height={'22px'}
									wcagLabel={wcagLabel}
								/>
							</div>
							<GovSkeleton
								width={'34px'}
								height={'18px'}
								wcagLabel={wcagLabel}
							/>
						</div>
						<div className={'flex gap-4 justify-between'}>
							<GovSkeleton
								width={'160px'}
								height={'22px'}
								wcagLabel={wcagLabel}
							/>
							<GovSkeleton
								width={'18px'}
								height={'18px'}
								shape={'circle'}
								wcagLabel={wcagLabel}
							/>
						</div>
						<GovSkeleton
							width={'200px'}
							height={'18px'}
							wcagLabel={wcagLabel}
						/>
					</div>
				);
			})}
		</>
	);
};
