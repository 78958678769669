import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	isValidDate,
	today,
	toStringDate,
	useDateShape,
} from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { DataRequest, EPetitionFilterForm } from '../seznam/context.types';

export interface FormInstanceProps {
	code: PageCode;
	defaultValues: EPetitionFilterForm;
}

export function FormInstance({
	code,
	defaultValues,
}: FormInstanceProps): FormDefinition<EPetitionFilterForm> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);
	const formSchema = yup
		.object({
			text: yup.string().optional(),
			platnostOd: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.zadejte-datum-od'),
			}).max(today(), tsn('formular.validace.maximum-datum-od')),
			platnostDo: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.zadejte-datum-do'),
			})
				.max(today(), tsn('formular.validace.maximum-datum-do'))
				.when('platnostOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(
							from,
							tsn('formular.validace.starsi-nez-datum-od')
						);
					}
					return schema;
				}),
		})
		.required();

	const formMethods = usePoForm<EPetitionFilterForm>({
		formSchema,
		defaultValues,
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	formData: EPetitionFilterForm
): DataRequest => {
	return {
		params: {
			text: formData.text ?? undefined,
			platnostOd: formData.platnostOd
				? toStringDate(formData.platnostOd)
				: undefined,
			platnostDo: formData.platnostDo
				? toStringDate(formData.platnostDo)
				: undefined,
		},
	};
};
