import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React from 'react';
import { Nullable } from '@gov-nx/core/types';

export interface InfoBannerProps {
	children: React.ReactNode;
	icon: Nullable<JSX.GovIcon>;
	type?: 'primary' | 'secondary' | undefined;
	className?: string;
}

export const InfoBanner = ({
	children,
	icon,
	type,
	className,
}: InfoBannerProps) => {
	return (
		<div
			role="status"
			className={cx(
				'p-5 text-center [ md:px-8 md:py-10 md:rounded md:text-left ]',
				type === 'secondary'
					? 'bg-neutral-white'
					: 'bg-gradient-to-t to-primary-700 from-primary-600 text-neutral-white',
				className
			)}>
			<div
				className={
					'flex flex-col gap-6 items-center [ md:gap-10 md:flex-row md:items-start ]'
				}>
				{icon ? (
					<GovIcon
						className={'w-24 h-24'}
						type={icon.type}
						name={icon.name}></GovIcon>
				) : null}
				<div className={'flex flex-col gap-3 flex-1'}>{children}</div>
			</div>
		</div>
	);
};
