import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { prepareDataBoxOptions } from '@gov-nx/utils/common';

interface DataBoxSelectProps<F extends { name: string }> {
	field: F;
	types: DatovkaApiType['typSchranky'][];
}

export const useDataBoxSelect = <T extends { name: string }>({
	types,
	field,
}: DataBoxSelectProps<T>) => {
	const { setValue } = useFormContext();
	const { getConnectedDataboxesListByTypes } = useDataBoxStore();
	const dataBoxes = getConnectedDataboxesListByTypes(types);

	const dataBoxesOptions = prepareDataBoxOptions(dataBoxes);

	useEffect(() => {
		if (dataBoxesOptions.length > 0) {
			setValue(field.name, dataBoxesOptions[0].value);
		}
	}, [dataBoxesOptions.map((option) => option.value).join('-')]);

	return { dataBoxesOptions, dataBoxes };
};
