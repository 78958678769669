import {
	GovChip,
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxLabel,
	getDataBoxIcon,
	getDataBoxInitials,
	getDataBoxTypeLabel,
	useDataBoxCredit,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, CopyButton } from '@gov-nx/ui/web';
import { formatPrice, limitNumber } from '@gov-nx/utils/common';
import { DataBoxItemNavigation } from './DataBoxItemNavigation';
import { DataBoxStorageUsage } from './DataBoxStorageUsage';

interface TruncateNameProps {
	name: string;
}

const TruncateName = ({ name }: TruncateNameProps) => {
	const textRef = useRef<HTMLHeadingElement>(null);
	const [isTruncated, setIsTruncated] = useState(false);

	useEffect(() => {
		const dataBoxName = textRef.current;
		if (dataBoxName) {
			if (dataBoxName.scrollHeight > dataBoxName.clientHeight) {
				setIsTruncated(true);
			}
		}
	}, []);

	return (
		<div>
			{isTruncated ? (
				<GovTooltip
					position="bottom"
					size="s"
					icon>
					<h2
						ref={textRef}
						className={'line-clamp-2'}>
						{name}
					</h2>
					<GovTooltipContent>{name}</GovTooltipContent>
				</GovTooltip>
			) : (
				<h2
					ref={textRef}
					className={'line-clamp-2'}>
					{name}
				</h2>
			)}
		</div>
	);
};

interface DataBoxesListProps {
	dataBox: DatovkaApiType;
	toggleable: boolean;
	defaultlyOpen?: boolean;
}

export const DataBoxesItem = ({
	dataBox,
	toggleable,
	defaultlyOpen = false,
}: DataBoxesListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		handleCreditModalOpen,
		mobileMenuDataBox,
		setMobileMenuDataBox,
		getUnreadMessagesCounts,
	} = useDataBoxesContext();

	const { credit } = useDataBoxCredit(dataBox.datovaSchrankaId);

	const unreadMessagesCount = dataBox.datovaSchrankaId
		? getUnreadMessagesCounts(dataBox.datovaSchrankaId)
		: null;

	const [isMenuItemOpen, setIsMenuItemOpen] = useState(defaultlyOpen);

	const name = dataBoxLabel(dataBox, true);

	const toggleContent = (
		<>
			{dataBox.typSchranky && (
				<div className={'text-left text-s text-secondary-700'}>
					{getDataBoxTypeLabel(dataBox.typSchranky, tn)}
				</div>
			)}
			<div className={'text-left'}>
				{!dataBox.odpojeno && !isMenuItemOpen && toggleable ? (
					!unreadMessagesCount ? (
						<GovChip
							variant="success"
							size="s"
							inverse
							className={'inline-block mt-2'}>
							<span>{tn('zpravy.vsechny-prectene')}</span>
						</GovChip>
					) : (
						<GovChip
							variant="warning"
							size="s"
							className={'inline-block mt-2'}>
							<span>
								{tn('zpravy.neprectene-pocet', { count: unreadMessagesCount })}
							</span>
						</GovChip>
					)
				) : null}
				{dataBox.odpojeno && (
					<GovTooltip
						position="right"
						size="s"
						message={tn('schranky.odpojena-slozky')}
						className={'text-left border-b-0'}>
						<GovChip
							variant="error"
							size="s"
							inverse
							className={'inline-block mt-2'}>
							<span>{tn('schranky.odpojena')}</span>
						</GovChip>
					</GovTooltip>
				)}
			</div>
		</>
	);

	return (
		<>
			<Button
				variant="primary"
				type="link"
				className={'block relative ds-no-underline [ xl:hidden ]'}
				wcagLabel={tn('wcag.nabidka-schranky') + ' ' + name}
				wcagExpanded={true}
				wcagControls={'ds-menu-' + dataBox.datovaSchrankaId}
				onClick={() =>
					setMobileMenuDataBox(dataBox.datovaSchrankaId as string)
				}>
				<div
					className={cx(
						'flex flex-col justify-center items-center w-10 h-10 rounded-full bg-neutral-white text-secondary-800',
						dataBox.datovaSchrankaId === mobileMenuDataBox && 'ds-active'
					)}>
					<GovIcon
						name={
							getDataBoxIcon(
								dataBox?.typSchranky as DatovkaApiType['typSchranky']
							) as string
						}
						className={'!w-3 !h-3 grow-0 shrink-0'}
					/>
					<h2
						className={'text-s font-bold'}
						aria-label={name}>
						{getDataBoxInitials(dataBox)}
					</h2>
					{!dataBox.odpojeno && !!unreadMessagesCount && (
						<span
							className={
								'absolute top-0 right-0 flex items-center px-1 h-4 text-xs font-bold bg-warning-400 text-primary-600 rounded-full'
							}
							aria-label={tn('zpravy.neprectene-zpravy')}>
							{limitNumber(unreadMessagesCount)}
						</span>
					)}
				</div>
			</Button>

			{toggleable ? (
				<Button
					variant="primary"
					type="base"
					className={
						'hidden anim-bg-hover ds-toggle-name [ hover:bg-primary-100 hover:rounded-lg ] [ xl:block ]'
					}
					onClick={() => setIsMenuItemOpen((isMenuItemOpen) => !isMenuItemOpen)}
					wcagLabel={tn('wcag.nabidka-schranky') + ' ' + name}>
					<div className="!block w-full">
						<div
							className={
								'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'
							}>
							<TruncateName name={name} />
							<GovIcon
								name={isMenuItemOpen ? 'chevron-up' : 'chevron-down'}
								className={'w-6 grow-0 shrink-0'}
							/>
						</div>
						{toggleContent}
					</div>
				</Button>
			) : (
				<div className={'hidden p-2 [ xl:block ]'}>
					<div
						className={
							'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'
						}>
						<TruncateName name={name} />
					</div>
					{toggleContent}
				</div>
			)}

			<div
				id={'ds-menu-' + dataBox.datovaSchrankaId}
				className={cx(
					'absolute top-0 left-[72px] w-[244px] h-[calc(100vh-61px)] p-2 bg-neutral-white [ xl:relative xl:left-0 xl:w-auto xl:h-auto xl:p-0 xl:bg-transparent xl:block ]',
					mobileMenuDataBox !== dataBox.datovaSchrankaId && 'hidden',
					toggleable && !isMenuItemOpen && '[ xl:hidden ]'
				)}>
				<div className={'p-2 [ xl:hidden ]'}>
					<div
						className={
							'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'
						}>
						<span>{name}</span>
						<Button
							variant="primary"
							type="base"
							size="s"
							wcagLabel={tn('wcag.zavrit-nabidku')}
							onClick={() => setMobileMenuDataBox(null)}>
							<GovIcon
								name="x-lg"
								slot="left-icon"
							/>
						</Button>
					</div>
					{dataBox.typSchranky && (
						<div className={'text-s text-secondary-700'}>
							{getDataBoxTypeLabel(dataBox.typSchranky, tn)}
						</div>
					)}
				</div>
				<dl className={'px-2 mb-3 text-xs text-secondary-800'}>
					<div className={'flex items-center'}>
						<dt className={'mr-1'}>{tn('schranky.id-schranky')}</dt>
						<dd>
							<strong>{dataBox.datovaSchrankaId}</strong>
						</dd>
						<CopyButton
							label={tn('schranky.zkopirovat-id-schranky')}
							value={dataBox.datovaSchrankaId as string}></CopyButton>
					</div>
					{!dataBox.odpojeno && credit !== null && (
						<div className={'flex items-center'}>
							<dt className={'mr-1'}>{tn('kredit.kredit')}</dt>
							<dd>
								<strong>{formatPrice(credit)}</strong>
							</dd>
							<Button
								variant="primary"
								type="base"
								size="s"
								onClick={() =>
									handleCreditModalOpen(dataBox.datovaSchrankaId as string)
								}
								wcagLabel={tn('kredit.wcag.dobit')}>
								{tn('kredit.dobit')}
							</Button>
						</div>
					)}
				</dl>

				<DataBoxItemNavigation
					dataBox={dataBox}
					unreadMessagesCount={unreadMessagesCount}
				/>
				<DataBoxStorageUsage classes="p-2 mt-1 [ xl:hidden ]" />
			</div>
		</>
	);
};
