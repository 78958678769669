import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { dataBoxParams, useDataBoxShape } from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			data: yup.object().shape({}).required(),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			data: {},
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData): DataRequest {
	return {
		params: dataBoxParams(formData),
		body: {
			data: formData.data,
			typVypisu: 'NEVEREJNE_UDAJE_ROS',
		},
	};
}
