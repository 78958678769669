import React, { ReactNode } from 'react';
import { CopyButton } from '@gov-nx/ui/web';

export const Row = ({
	label,
	children,
	testId,
}: {
	label: string;
	testId?: string;
	children: ReactNode;
}) => {
	return (
		<tr>
			<td>{label}</td>
			<td>
				<strong data-testid={testId}>{children}</strong>
			</td>
		</tr>
	);
};

export const CopyOrEmpty = ({
	value,
	label,
}: {
	value?: string | number;
	label: string;
}) => {
	if (!value) {
		return '-';
	}
	return (
		<>
			{value}
			<CopyButton
				value={value}
				label={label}
			/>
		</>
	);
};
