export interface RequestData<Params, Body> {
	params?: Params;
	body?: Body;
}

export enum RecycleBinType {
	DOKLAD = 'DOKLAD',
	DOKUMENT = 'DOKUMENT',
	UDALOST = 'UDALOST',
	DATOVA_ZPRAVA = 'DATOVA_ZPRAVA',
	PODANI = 'PODANI',
}

export interface DocumentDeleteParams {
	id: number;
	permanent: boolean;
}

export interface DocumentRestoreParams {
	id: number;
}

export interface EmptyRecycleBinParams {
	typ?: RecycleBinType;
}

export interface DocumentUploadParams {
	zdroj: 'UZIVATELEM_NAHRANY_DOKUMENT';
	soubor: string;
	nazev: string;
	kategorie?: string;
	cisloDokumentu?: string;
	platnostOd?: string;
	platnostDo?: string;
	komentar?: string;
}
