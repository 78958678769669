import type { UrlChecker } from '@gov-nx/utils/web';
import { PoMarkdownRenderWeb } from './PoMarkdownRenderWeb';

export interface MarkdownRenderProps {
	content: string;
	openLinksInNewWindow?: UrlChecker;
}

/**
 * Legacy Markdown renderer which does not allow for much configuration.
 *
 * @deprecated Use PoMarkdownRenderWeb directly.
 */
export const MarkdownRender = ({
	content,
	openLinksInNewWindow,
}: MarkdownRenderProps) => (
	<PoMarkdownRenderWeb
		checkIfUrlShouldOpenNewWindowWith={openLinksInNewWindow}
		disableWrapper>
		{content}
	</PoMarkdownRenderWeb>
);
