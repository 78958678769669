import {
	isResponseStatus,
	rosBusinessPersonQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

interface BusinessPersonLoaderProps {
	onError?: (error: GovError) => void;
}

export const useBusinessPersonLoader = (props?: BusinessPersonLoaderProps) => {
	const { setBusinessPersonData, business: businessPerson } = usePersonStore();

	const query = usePoQuery(
		{
			queryKey: ['osoby', 'podnikatele'],
			queryFn: rosBusinessPersonQuery,
			onSuccess: (data) => setBusinessPersonData(data),
			onError: props?.onError,
			refetchOnWindowFocus: false,
			retry: 0,
			enabled: !businessPerson,
		},
		{ errorIgnoreFilter: isResponseStatus(500) }
	);
	return { businessPerson, error: query.error };
};
