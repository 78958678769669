/**
 * Matches phone numbers like this:
 * A phone number must be in international format and have exactly 12 digits.
 * The digits can be separated by spaces only (no dashes, minus signs, etc.).
 * We don't assume size of digit groups except for the first one (country code).
 * The country code can be enclosed in brackets, i.e.: (+420) 123 456 789 or +420 123 456 789.
 * The number can be on beginning of line or preceded by a whitespace/s.
 * The number can be at the end of line or followed by full stop, coma or whitespace.
 */
const mdPhoneNumberRegexp =
	/(^|\s)((?:\(\+\d{3}\)|\+\d{3})\s?(?:\s?\d){9})(\s|\.|,|$)/gi;

/**
 * Finds all phone numbers in a given string and converts them to Markdown link format.
 * @param input - A Markdown or plain text string.
 */
export const findAndActivatePhoneNumbersInMarkdown = (input: string): string =>
	input
		.split('\n')
		.map((line) =>
			line.replace(mdPhoneNumberRegexp, (match, prefix, tel, suffix) =>
				match.replace(
					match,
					`${prefix ?? ''}[${tel}](tel:${tel.replace(/[^+\d]/g, '')})${
						suffix ?? ''
					}`
				)
			)
		)
		.join('\n');
