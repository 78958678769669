import { useState } from 'react';
import {
	AgendyAisAgendaSeznamDto,
	searchAgendasQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';

export const useAgendaSearchAutocomplete = (order: 'nazev', start = 0) => {
	const [text, setText] = useState<Optional<string>>(undefined);

	const { isLoading, isFetching, data } = usePoQuery<AgendyAisAgendaSeznamDto>({
		queryKey: ['agenda-autocomplete', order, start, text],
		queryFn: () => searchAgendasQuery(text as string, order, start),
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		isLoading,
		isFetching,
		agendas: data?.seznam || [],
		setText,
	};
};
