import { GovChip } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useRegistrRidicuContext } from '@gov-nx/module/page';
import { BadgeNav } from '@gov-nx/ui/web';

export interface DriversRegistryNavigationProps {
	code: PageCode;
}

export const DriversRegistryNavigation = ({
	code,
}: DriversRegistryNavigationProps) => {
	const { selectedPage, selectPage } = useRegistrRidicuContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<BadgeNav>
			<li>
				<GovChip
					variant={'primary'}
					size="m"
					tag="button"
					inverse={selectedPage !== 'competency'}
					wcagLabel={`${ts('filtr.wcag.filtrovat')} ${ts(
						'filtr.wcag.opravneni'
					)}`}
					onGov-click={() => selectPage('competency')}>
					{ts('filtr.opravneni')}
				</GovChip>
			</li>
			<li>
				<GovChip
					variant={'primary'}
					size="m"
					tag="button"
					inverse={selectedPage !== 'offences'}
					wcagLabel={`${ts('filtr.wcag.filtrovat')} ${ts(
						'filtr.wcag.prestupky'
					)}`}
					onGov-click={() => selectPage('offences')}>
					{ts('filtr.prestupky')}
				</GovChip>
			</li>
			<li>
				<GovChip
					variant={'primary'}
					size="m"
					tag="button"
					inverse={selectedPage !== 'prohibitions'}
					wcagLabel={`${ts('filtr.wcag.filtrovat')} ${ts('filtr.wcag.zakazy')}`}
					onGov-click={() => selectPage('prohibitions')}>
					{ts('filtr.zakazy')}
				</GovChip>
			</li>
		</BadgeNav>
	);
};

DriversRegistryNavigation.displayName = 'DriversRegistryNavigation';
