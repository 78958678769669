import { PageCode } from '@gov-nx/module/page';
import { useFilter } from '@gov-nx/utils/common';

export const useContactDetailsTabsFilter = () => {
	const filter = useFilter<{ activeTab: 'FO' | 'PO' }>({
		key: PageCode['nastaveni'],
		initialState: { activeTab: 'FO' },
	});

	return {
		activeTab: filter.filter.activeTab,
		setActiveTab: (activeTab: 'FO' | 'PO') =>
			filter.setPartialFilter({ activeTab }),
	};
};
