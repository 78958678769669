import {
	ePetitionAttachmentDownloadQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { OnEpetitionAttachmentsDownloadParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface ePetititionAttachmentDownloadProps {
	onError?: (
		variables: { petitionId: number; attachmentId: number },
		error: Error
	) => void;
	onSuccess?: (data: OnEpetitionAttachmentsDownloadParams) => void;
}

export const usePetitionAttachmentDownload = (
	props?: ePetititionAttachmentDownloadProps
) => {
	const mutation = usePoMutation({
		mutationFn: ePetitionAttachmentDownloadQuery,
		onError: (error, variables) => {
			props?.onError && props.onError(variables, error as GovError);
		},
		onSuccess: async (response, variables) => {
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(variables);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props?.onSuccess &&
						props.onSuccess({ antivirus, fileName, blob, ...variables });
				} catch (e) {
					props?.onError && props.onError(variables, e as Error);
				}
			}
		},
	});
	return mutation;
};
