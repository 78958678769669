import cx from 'classnames';
import { UiBlockingGroupContextProvider } from '@gov-nx/component/common';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import {
	DalsiNastaveniDatovkyContextProvider,
	DatovkaNastaveniUpozorneniContextProvider,
	PageCode,
	useDataboxSettingsView,
} from '@gov-nx/module/page';
import { ContentLayout, DataBoxConnectBanner, LayoutGap } from '@gov-nx/ui/web';
import { DataboxAdditionalSettings } from './DataboxAdditionalSettings';
import { DataboxManagementButtons } from './DataboxManagementButtons';
import { DataboxNotificationSettings } from './DataboxNotificationSettings';
import { DataboxSettingsHeading } from './DataboxSettingsHeading';

export const DataboxesNotificationSettings = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	const { possibleToConnectOrCreate, areAnyConnected } = useDataBoxAccess();
	const { ref, connectedDataboxes, handleShowMoreLess } =
		useDataboxSettingsView();

	if (!areAnyConnected && !possibleToConnectOrCreate) {
		return null;
	}

	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<h2
					className={'text-2xl'}
					ref={ref}>
					{tds('nastaveni-upozorneni.pripojene-datovky')}
				</h2>
				{connectedDataboxes.length ? (
					<>
						{connectedDataboxes.map((databoxId, index) => (
							<div
								key={databoxId}
								className={cx(
									'py-10',
									connectedDataboxes.length > 1 &&
										index > 0 &&
										'border-t border-secondary-300'
								)}>
								<DataboxSettingsHeading databoxId={databoxId} />
								<UiBlockingGroupContextProvider>
									<DatovkaNastaveniUpozorneniContextProvider
										databoxId={databoxId}>
										<DataboxNotificationSettings />
									</DatovkaNastaveniUpozorneniContextProvider>
									<DalsiNastaveniDatovkyContextProvider databoxId={databoxId}>
										<DataboxAdditionalSettings />
									</DalsiNastaveniDatovkyContextProvider>
								</UiBlockingGroupContextProvider>
							</div>
						))}
						<DataboxManagementButtons
							handleShowMoreLess={handleShowMoreLess}
							connectedDataboxes={connectedDataboxes}
						/>
					</>
				) : (
					<div className="pt-6 md:pt-8 md:pb-2">
						<DataBoxConnectBanner
							requiredTypes={[]}
							translationPrefix="banner"
						/>
					</div>
				)}
			</ContentLayout>
		</LayoutGap>
	);
};
