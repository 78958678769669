import React from 'react';
import { useDashboardContextInstance } from '@gov-nx/module/page';
import { DataBoxConnectBanner } from '@gov-nx/ui/web';

export const DashboardDataBoxConnectBanner = () => {
	const { showDataBoxBanner } = useDashboardContextInstance();

	if (!showDataBoxBanner) return null;

	return (
		<div className={'gov-layout pt-14 -mx-4 w-screen [ md:mx-0 md:w-auto ]'}>
			<section
				className={'gov-layout__section gov-layout__section-aside-right'}>
				<div
					className={
						'gov-layout-column px-4 overflow-hidden rounded bg-neutral-white [ md:px-0 ]'
					}>
					<DataBoxConnectBanner
						requiredTypes={[]}
						translationPrefix="banner-homepage-v1"
					/>
				</div>
				<div className={'gov-layout-column'} />
			</section>
		</div>
	);
};
