import * as yup from 'yup';
import {
	OsobyFyzickaOsobaDto,
	OsobyKomunikacniUdajeDto,
} from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	LocalizeNameSpaceTypes,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	cleanPhoneNumber,
	formatPhoneNumber,
	getAttachmentsIds,
	usePhoneShape,
} from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import {
	DataPodporaRequest,
	FormData,
	FormPersonData,
	FormProblemData,
} from './context.types';

interface FormPersonInstanceProps {
	code: PageCode;
	communication?: OsobyKomunikacniUdajeDto;
	individualPerson?: OsobyFyzickaOsobaDto;
}

export function FormPersonInstance({
	code,
	communication,
	individualPerson,
}: FormPersonInstanceProps): FormDefinition<FormPersonData> {
	const { getLocalize, getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<FormPersonData>>({
			jmeno: yup.string().required(tsn('formular.validace.zadejte-jmeno')),
			prijmeni: yup
				.string()
				.required(tsn('formular.validace.zadejte-prijmeni')),
			telefon: getPhoneShape(),
			email: yup
				.string()
				.required(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.zadejte-kontaktni-email'
					)
				)
				.email(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.email-neni-ve-spravnem-tvaru'
					)
				),
		})
		.required();
	const formMethods = usePoForm<FormPersonData>({
		formSchema,
		defaultValues: {
			jmeno: individualPerson?.jmeno,
			prijmeni: individualPerson?.prijmeni,
			telefon: communication?.telefonniCislo
				? formatPhoneNumber(communication.telefonniCislo)
				: undefined,
			email: communication?.email,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

interface FormProblemInstanceProps {
	code: PageCode;
	isLogged: boolean;
}

export function FormProblemInstance({
	code,
	isLogged,
}: FormProblemInstanceProps): FormDefinition<FormProblemData> {
	const { getLocalizeCurried, getLocalize } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<FormProblemData>>({
			isLogged: yup.boolean().optional(),
			souhrn: yup.string().required(tsn('formular.validace.nazev-problemu')),
			podrobnyPopis: yup
				.string()
				.required(tsn('formular.validace.popis-problemu')),
			vyskytChyby: yup.string().required(tsn('formular.validace.vyskyt-chyby')),
			drivejsiStav: yup
				.string()
				.required(tsn('formular.validace.drivejsi-stav')),
			priloha: yup.object().nullable().optional(),
			zpusobPrihlaseni: yup.string().when('isLogged', {
				is: false,
				then: yup.string().required(tsn('formular.validace.zpusob-prihlaseni')),
				otherwise: yup.string().optional(),
			}),
			prihlaseniUserName: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: yup.string().required(tsn('formular.validace.prihlaseni-jmeno')),
				otherwise: yup.string().optional(),
			}),
			prihlaseniEmail: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: yup
					.string()
					.required(
						getLocalize(
							LocalizeNameSpaceTypes.Form,
							'validations.zadejte-kontaktni-email'
						)
					)
					.email(
						getLocalize(
							LocalizeNameSpaceTypes.Form,
							'validations.email-neni-ve-spravnem-tvaru'
						)
					),
				otherwise: yup.string().optional(),
			}),
			prihlaseniTelefon: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: getPhoneShape({ isRequired: true }),
				otherwise: yup.string().optional(),
			}),
			prihlaseniIdDS: yup.string().when('zpusobPrihlaseni', {
				is: 'DS',
				then: yup.string().required(tsn('formular.validace.prihlaseni-ds')),
				otherwise: yup.string().optional(),
			}),
		})
		.required();

	const formMethods = usePoForm<FormProblemData>({
		formSchema,
		defaultValues: {
			isLogged: isLogged,
			souhrn: undefined,
			podrobnyPopis: undefined,
			vyskytChyby: undefined,
			drivejsiStav: undefined,
			priloha: undefined,
			zpusobPrihlaseni: undefined,
			prihlaseniUserName: undefined,
			prihlaseniEmail: undefined,
			prihlaseniTelefon: undefined,
			prihlaseniIdDS: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataPodporaRequest => {
	return {
		params: {},
		body: {
			kodPodani: 'SERVICE_DESK',
			jmeno: data.jmeno,
			prijmeni: data.prijmeni,
			email: data.email,
			telefon: data.telefon ? cleanPhoneNumber(data.telefon) : undefined,
			souhrn: data.souhrn,
			podrobnyPopis: data.podrobnyPopis,
			vyskytChyby: data.vyskytChyby,
			drivejsiStav: data.drivejsiStav,
			priloha: getAttachmentsIds(data.priloha),
			zpusobPrihlaseni: data.zpusobPrihlaseni,
			prihlaseniUserName: data.prihlaseniUserName,
			prihlaseniEmail: data.prihlaseniEmail,
			prihlaseniTelefon: data.prihlaseniTelefon
				? cleanPhoneNumber(data.prihlaseniTelefon)
				: undefined,
			prihlaseniIdDS: data.prihlaseniIdDS,
			technickeInformace: JSON.stringify(data.technickeInformace),
			verze: data.verze,
			build: data.build,
			captcha: data.captcha,
		},
	};
};
