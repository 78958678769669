import React from 'react';
import { is } from '@gov-nx/core/types';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableMotorProps {
	vehicle: Vehicle;
	tsn: (code: string) => string;
}

export const TableMotor = ({ tsn, vehicle }: TableMotorProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.motor.vyrobce'),
					vehicle.motor.motorZakladni?.motorVyrobce?.xVal,
				],
				[tsn('tabulka.motor.typ'), vehicle.motor.motorZakladni?.motorTyp?.xVal],
				[
					tsn('tabulka.motor.max-vykon'),
					[
						vehicle.motor.motorDodatek?.motorMaxVykon?.xVal,
						vehicle.motor.motorDodatek?.motorMinOtacky?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.motor.palivo'),
					vehicle.vozidloUdaje.udajeDodatek?.vozidloPalivo?.xVal,
				],
				[
					tsn('tabulka.motor.zdvihovy-objem'),
					vehicle.motor.motorDodatek?.motorZdvihObjem?.xVal,
				],
				[
					tsn('tabulka.motor.emisni-limit'),
					[
						vehicle.emise.emiseNorma?.emiseEHKOSN?.xVal,
						vehicle.emise.emiseNorma?.emiseEHSES?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.motor.korigovany-soucinitel-absorpce'),
					vehicle.emise.emiseHodnoty?.emiseKSA?.xVal,
				],
				[
					tsn('tabulka.motor.emise-co2'),
					[
						vehicle.emise.emiseHodnoty?.emiseCO2?.xVal,
						vehicle.emise.emiseHodnoty?.emiseCO2Mesto?.xVal,
						vehicle.emise.emiseHodnoty?.emiseCO2Mimo?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.motor.spotreba-paliva'),
					[
						vehicle.vozidloUdaje.vozidloSpotreba?.spotreba?.xVal,
						vehicle.vozidloUdaje.vozidloSpotreba?.spotrebaPriRychlosti?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.motor.spotreba-na100km'),
					[
						vehicle.vozidloUdaje.vozidloSpotreba?.spotrebaNa100kmMesto?.xVal,
						vehicle.vozidloUdaje.vozidloSpotreba?.spotrebaNa100km90?.xVal,
						vehicle.vozidloUdaje.vozidloSpotreba?.spotrebaNa100km120?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
			]}
		/>
	);
};
