import { AxiosInstance } from 'axios';
import { createInstance, TFunction } from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { PageCode } from '@gov-nx/module/page';
import { LocalizeNameSpaceTypes } from './name-space.types';

const instance = createInstance();

export async function initTranslations(
	initialLanguage: LocalizationLanguage,
	axios: AxiosInstance,
	cmsKeyCache: string
): Promise<TFunction> {
	return instance
		.use(initReactI18next)
		.use(HttpBackend)
		.init<HttpBackendOptions>({
			compatibilityJSON: 'v3',
			backend: {
				loadPath: '/translations/{{ns}}-{{lng}}.json?v=' + cmsKeyCache,
				request: async (options, url, payload, callback) => {
					try {
						const data = await axios.get(url);
						return callback(null, {
							status: 200,
							data: data.data.data,
						});
					} catch (error) {
						callback(error, {
							status: 500,
							data: {},
						});
					}
				},
			},
			react: {
				useSuspense: true,
			},
			lng: initialLanguage,
			fallbackLng: LocalizationLanguage.Czech,
			ns: [
				LocalizeNameSpaceTypes.Global,
				LocalizeNameSpaceTypes.Auth,
				LocalizeNameSpaceTypes.Page,
				LocalizeNameSpaceTypes.Service,
				LocalizeNameSpaceTypes.Component,
				LocalizeNameSpaceTypes.Form,
				PageCode['drozd'],
				PageCode['profil-ROB'],
				PageCode['datove-schranky'],
				PageCode['nastaveni-upozorneni'],
			],
			defaultNS: LocalizeNameSpaceTypes.Global,
			debug: false,
			interpolation: {
				escapeValue: false,
			},
		});
}

export function getI18n() {
	return instance;
}

export function i18nLoadNamespaces(namespaces: string[]): Promise<void[]> {
	return new Promise((resolve) => {
		const promises = Promise.all(
			namespaces
				.filter((namespace) => !getI18n().hasLoadedNamespace(namespace))
				.map((namespace) => getI18n().loadNamespaces(namespace))
		);

		return resolve(promises);
	});
}
