import React from 'react';
import {
	PageCode,
	useNastaveniKontaktnichUdajuROSItemContext,
} from '@gov-nx/module/page';
import { ContactEmailView } from '../ContactEmailView';
import { ContactPhoneView } from '../ContactPhoneView';
import { UsePersonalContactsButton } from './UsePersonalContactsButton';

interface ROSContactViewProps {
	code: PageCode;
}

export const ROSContactView = ({ code }: ROSContactViewProps) => {
	const {
		contact,
		canUsePersonalContacts,
		isUsingPersonalContactsLoading,
		phone,
		email,
		handleUsePersonalContacts,
	} = useNastaveniKontaktnichUdajuROSItemContext();

	return (
		<>
			<h3 className={'text-xl font-normal'}>{contact.nazevSpolecnosti}</h3>

			<ContactEmailView
				code={code}
				{...email}
			/>

			<ContactPhoneView
				code={code}
				{...phone}
			/>

			<UsePersonalContactsButton
				canUsePersonalContacts={canUsePersonalContacts}
				isLoading={isUsingPersonalContactsLoading}
				code={code}
				handleUsePersonalContacts={handleUsePersonalContacts}
			/>
		</>
	);
};
