import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxFolderType, useMessageDownload } from '@gov-nx/module/data-box';
import { DownloadCallback } from '../Download/types';

export const DownloadMessageListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const [messageData, setMessageData] = useState<null | {
		dataBoxId: string;
		message: DatoveSchrankyDatovaZpravaDto;
		folderType: DataBoxFolderType;
	}>(null);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();
	const [callbacks, setCallbacks] = useState<{
		[key: number]: Nullable<DownloadCallback['callback']>;
	}>({});

	useDownloadEvents({
		onDataBoxMessageDownload: (_, { messageData, callback }) => {
			setMessageData(messageData);
			if (callback) {
				setCallbacks((cbs) => ({
					...cbs,
					[messageData.message.datovaZpravaId as number]: callback,
				}));
			}
		},
	});

	const onDownload = useCallback(
		(id: number, success: Nullable<boolean>, error: Nullable<Error>) => {
			if (typeof id !== 'number') {
				return;
			}
			const callbackFunction = callbacks[id];
			if (callbackFunction) {
				callbackFunction(id, success, error);
				setCallbacks({ ...callbacks, [id]: null });
			}
			setMessageData(null);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
		[callbacks, messageData]
	);

	const { mutate } = useMessageDownload({
		onSuccess: (data) => {
			download({
				...data,
				id: data.id.toString(),
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.id, true, null);
					}
					if (error) {
						errorMessage();
						onDownload(data.id, null, error);
					}
				},
			});
		},
		onError: (id, error) => {
			errorMessage();
			onDownload(id, null, error);
		},
	});

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('soubor.stazeni-souboru-uspech', {
				namespace: LocalizeNameSpaceTypes.Service,
				fileName,
			}),
			options: {
				variant: 'success',
				icon: {
					name: 'download',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('soubor.stazeni-souboru-chyba', {
				namespace: LocalizeNameSpaceTypes.Service,
			}),
			options: {
				variant: 'error',
				icon: {
					name: 'exclamation-triangle-fill',
					type: 'basic',
				},
			},
		});
	};

	useEffect(() => {
		if (messageData) {
			mutate(messageData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [messageData]);

	return null;
};
