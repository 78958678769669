import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { icValidator } from './Validator/ic';

interface IcNumberShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	isInvalidMessage?: string;
}

export const useIcNumberShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getIcNumberShape: ({
			isInvalidMessage,
			isRequiredMessage,
			isRequired,
		}: IcNumberShape): yup.StringSchema => {
			const schema = yup
				.string()
				.test(
					'length',
					isInvalidMessage ?? t('ico.validace.ic-neni-ve-spravnem-tvaru') ?? {},
					icValidator
				);

			if (isRequired) {
				return schema.required(
					isRequiredMessage ?? t('ico.validace.zadejte-ic') ?? undefined
				);
			}

			return schema;
		},
	};
};
