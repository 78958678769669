import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	FilterParameterList,
	FormDatePicker,
	FormInput,
	FormWidthElementLayout,
	HeaderLayout,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

export interface EPetitionFilterProps {
	code: PageCode;
}

export const EPetitionFilter = ({ code }: EPetitionFilterProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const {
		controls,
		filterParams,
		resetFilter,
		setControls,
		formDefinition,
		onSubmit,
		filter,
	} = useEPeticeSeznamContext();

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<>
					<HeaderLayout
						filterParams={
							<FilterParameterList
								items={filterParams}
								onReset={() => resetFilter()}
							/>
						}>
						<FormWidthElementLayout
							type="l"
							className={'relative'}>
							<FormInput
								field={{
									name: 'text',
									placeholder: ts('filtr.placeholder.vyhledat-epetici'),
									wcagLabelledBy: 'vyhledat-epetici',
								}}
								label={{
									children: '',
								}}
								group={{ gap: 's' }}
							/>
							<label
								id="vyhledat-epetici"
								className={'hidden'}>
								{ts('filtr.pole.wcag.vyhledat-epetici')}
							</label>
							<div className={'absolute top-1 right-1'}>
								<Button
									nativeType={'submit'}
									variant="primary"
									type="solid"
									size="s"
									wcagLabel={ts('akce.wcag.vyhledat')}>
									<GovIcon
										name={'search'}
										type={'basic'}
										slot="left-icon"></GovIcon>
								</Button>
							</div>
						</FormWidthElementLayout>

						<Button
							onClick={() => setControls({ displayFilter: true })}
							variant="primary"
							type="outlined"
							className={'ml-auto'}
							wcagLabel={ts('akce.wcag.zobrazit-filtr')}>
							<GovIcon
								name={'funnel-fill'}
								type={'basic'}></GovIcon>
						</Button>
					</HeaderLayout>

					<GovModal
						open={controls.displayFilter}
						label={ts('filtr.nazev')}
						onGov-close={() => setControls({ displayFilter: false })}
						wcagCloseLabel={ts('akce.wcag.zavrit-filtr')}>
						<div className={'flex flex-col gap-2 [ md:flex-row md:gap-10 ]'}>
							<FormWidthElementLayout>
								<FormDatePicker
									field={{
										name: 'platnostOd',
									}}
									label={{
										children: ts('filtr.pole.platnost-od'),
									}}
									group={{ gap: 's' }}
								/>
							</FormWidthElementLayout>
							<FormWidthElementLayout>
								<FormDatePicker
									field={{
										name: 'platnostDo',
									}}
									label={{
										children: ts('filtr.pole.platnost-do'),
									}}
									group={{ gap: 's' }}
								/>
							</FormWidthElementLayout>
						</div>
						<div
							slot="footer"
							className={'flex gap-4'}>
							<Button
								variant={'primary'}
								type={'solid'}
								nativeType={'submit'}
								wcagLabel={ts('akce.wcag.filtrovat')}>
								{ts('akce.filtrovat')}
							</Button>
							{filter.platnostOd || filter.platnostDo || filter.text ? (
								<Button
									onClick={() => resetFilter()}
									variant="error"
									type="outlined"
									wcagLabel={ts('akce.wcag.zrusit-filtr')}>
									{ts('akce.zrusit-filtr')}
								</Button>
							) : null}
						</div>
					</GovModal>
				</>
			</PoFormWeb>
		</FormProvider>
	);
};
