import { useState } from 'react';
import { DokumentyDokumentDto } from '@gov-nx/api/portal-obcana';
import { useDownloadEvents } from '@gov-nx/core/events';
import { is } from '@gov-nx/core/types';

const useNumberArray = () => {
	const [state, setState] = useState<number[]>([]);

	return {
		set: setState,
		clear: () => setState([]),
		isInArray: (item: number) => state.includes(item),
		isEmpty: state.length === 0,
	};
};

interface MyFilesDownloadProps {
	onSelectedDocumentsSuccess?: () => void;
	onDocumentSuccess?: () => void;
}

export const useMyFilesDownload = (props?: MyFilesDownloadProps) => {
	const downloadEvents = useDownloadEvents();
	const downloadingDocumentIds = useNumberArray();

	const downloadSelectedDocuments = async (documentIds: number[]) => {
		downloadingDocumentIds.set(documentIds);
		await Promise.all(
			documentIds.map(
				(documentId) =>
					new Promise((resolve) =>
						downloadEvents.downloadDocument({
							documentId,
							callback: resolve,
						})
					)
			)
		);
		props?.onSelectedDocumentsSuccess && props.onSelectedDocumentsSuccess();
		downloadingDocumentIds.clear();
	};

	const downloadDocument = (documentId: number) => {
		downloadingDocumentIds.set([documentId]);
		downloadEvents.downloadDocument({
			documentId,
			callback: () => {
				downloadingDocumentIds.clear();
				props?.onDocumentSuccess && props.onDocumentSuccess();
			},
		});
	};

	const hasFileToDownload = (document: DokumentyDokumentDto): boolean => {
		const { pripona, velikost } = document;
		return (is(velikost) && velikost > 0) || is(pripona);
	};

	return {
		downloadDocument,
		downloadSelectedDocuments,
		hasFileToDownload,
		isDownloading: downloadingDocumentIds.isInArray,
		isAnyDocumentDownloading: !downloadingDocumentIds.isEmpty,
	};
};
