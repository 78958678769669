import { GovSkeleton } from '@gov-design-system-ce/react';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { DefaultUploadParameters } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode, useVlastniDokladyContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	FormDatePicker,
	FormFileUpload,
	FormInput,
	FormSwitch,
	FormWidthElementLayout,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';
import { OwnDocumentFormSkeleton } from './OwnDocumentFormSkeleton';

interface EditOwnDocumentFormProps {
	code: PageCode;
}

export const OwnDocumentForm = ({ code }: EditOwnDocumentFormProps) => {
	const {
		documentId,
		controls,
		isEditAction,
		formDefinition,
		replaceFile,
		onSubmit,
		hasNotFinishedUploads,
		onFilesChanged,
		hasFile,
		documentDownload,
		imageBlob,
	} = useVlastniDokladyContext();
	const { getLocalizeCurried, t } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const urlCreator = window.URL || window.webkitURL;

	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

	if (controls.initialLoading) {
		return <OwnDocumentFormSkeleton />;
	}

	return (
		<ContentLayout>
			<FormProvider {...formDefinition.formMethods}>
				<PoFormWeb
					formDefinition={formDefinition}
					onSubmit={onSubmit}>
					<LayoutGap gap={6}>
						<FormWidthElementLayout type="l">
							<FormInput
								field={{
									name: 'nazev',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholder.nazev'),
								}}
								label={{
									children: tsn('formular.pole.nazev'),
								}}
							/>
						</FormWidthElementLayout>

						{isEditAction && imageBlob && (
							<FormSwitch
								field={{
									name: '_replaceFile',
									label: { children: tsn('formular.pole.vymenit-soubor') },
								}}
							/>
						)}

						{(replaceFile || !isEditAction || !hasFile) && (
							<FormFileUpload
								field={{ name: 'soubor', expanded: true }}
								// label={{ children: tsn('formular.pole.soubor') }}
								options={{
									...DefaultUploadParameters,
									extensions: ['.jpg', '.jpeg', '.png', '.pdf'],
									onFilesChanged: onFilesChanged,
								}}
							/>
						)}

						<FormWidthElementLayout type="l">
							<LayoutGap gap={6}>
								<FormInput
									field={{
										name: 'cisloDokumentu',
										disabled: controls.processLoading,
										placeholder: tsn('formular.placeholder.cislo-dokumentu'),
									}}
									label={{
										children: tsn('formular.pole.cislo-dokumentu'),
									}}
								/>

								<FormDatePicker
									field={{
										name: 'platnostOd',
										disabled: controls.processLoading,
									}}
									label={{
										children: tsn('formular.pole.platnost-od'),
									}}
								/>
								<FormDatePicker
									field={{
										name: 'platnostDo',
										disabled: controls.processLoading,
									}}
									label={{
										children: (
											<PoMarkdownRenderWeb>
												{tsn('formular.pole.platnost-do')}
											</PoMarkdownRenderWeb>
										),
									}}
								/>
							</LayoutGap>
						</FormWidthElementLayout>
						<FormInput
							field={{
								name: 'komentar',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholder.komentar'),
								multiline: true,
								rows: 4,
							}}
							label={{
								children: tsn('formular.pole.komentar'),
							}}
						/>

						<Modal
							open={isPreviewModalOpen}
							label={tsn('vlastni-doklady.nahled')}
							onGov-close={() => setIsPreviewModalOpen(false)}>
							{isEditAction &&
								formDefinition.formMethods.getValues('nazevSouboru') && (
									<>
										{documentDownload.isLoading ? (
											<div>
												<GovSkeleton
													height={'22px'}
													width={'150px'}
													wcagLabel={
														t('loading.wcag.processing', {
															namespace: LocalizeNameSpaceTypes.Global,
														}) as string
													}
												/>
											</div>
										) : (
											<div>
												{documentId && documentDownload.isError
													? tsn('vlastni-doklady.nahled-neni')
													: null}
												{imageBlob && (
													<div>
														<img src={urlCreator.createObjectURL(imageBlob)} />
													</div>
												)}
											</div>
										)}
									</>
								)}
						</Modal>

						<ButtonGroupLayout>
							<Button
								nativeType="submit"
								wcagLabel={
									isEditAction
										? tsn('formular.akce.ulozit')
										: tsn('formular.akce.pridat')
								}
								disabled={controls.processLoading || hasNotFinishedUploads}
								variant="primary"
								type="solid">
								{isEditAction
									? tsn('formular.akce.ulozit')
									: tsn('formular.akce.pridat')}
							</Button>
							{hasFile && (
								<Button
									loading={documentDownload.isLoading}
									nativeType="button"
									wcagLabel={tsn('formular.akce.zobrazit-soubor')}
									disabled={
										controls.processLoading || documentDownload.isLoading
									}
									variant="primary"
									type="solid"
									onClick={() => setIsPreviewModalOpen(true)}>
									{tsn('formular.akce.zobrazit-soubor')}
								</Button>
							)}
						</ButtonGroupLayout>
					</LayoutGap>
				</PoFormWeb>
			</FormProvider>
		</ContentLayout>
	);
};
