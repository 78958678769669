import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfilROSContextInstance,
	useProfilROSReklamaceContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormCheckbox,
	FormDatePicker,
	FormInput,
	FormLabel,
	FormRadio,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../Form/PoForm';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';
import { ProfilROSSkeleton } from '../profil-ROS/ProfilROSSkeleton';
import { AddressSearchForm } from './AddressSearchForm';

export interface ComplaintProps {
	code: PageCode;
}

export const ProfileROSComplaintView = ({ code }: ComplaintProps) => {
	const {
		controls,
		setControls,
		formDefinition,
		onSubmit,
		selectedLegalForm,
		filteredOptions,
		resetAddressSearchFields,
	} = useProfilROSReklamaceContextInstance();
	const { robCommunication } = useProfilROSContextInstance();
	const isProcessing = controls.processLoading;

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <ProfilROSSkeleton />;
	}

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<LayoutGap gap={6}>
					<PoMarkdownRenderWeb>{ts('formular.popis')}</PoMarkdownRenderWeb>
					<FormWidthElementLayout
						type={'l'}
						className={'flex flex-col gap-6'}>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'nazevSpolecnosti',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.nazev-spolecnosti'),
								}}
								label={{ children: ts('formular.pole.nazev-spolecnosti') }}
								control={{ size: 'm' }}
							/>
							<FormDatePicker
								field={{
									name: 'datumOd',
									disabled: isProcessing,
								}}
								label={{
									children: ts('formular.pole.datum-vzniku'),
								}}
							/>
							<FormDatePicker
								field={{
									name: 'datumDo',
									disabled: isProcessing,
								}}
								label={{
									children: ts('formular.pole.datum-zaniku'),
								}}
							/>
							<FormCheckbox
								field={{
									name: 'pozastaveniPreruseni',
									disabled: isProcessing,
									label: {
										children: ts('formular.pole.pozastaveni-preruseni'),
									},
								}}
							/>
							<FormSelect
								field={{
									name: 'pravniForma',
									disabled: isProcessing,
									options: [
										{
											value: '',
											label: selectedLegalForm ?? '',
											isDisabled: true,
										},
										...filteredOptions,
									],
								}}
								label={{ children: ts('formular.pole.pravni-forma') }}
							/>
							<FormInput
								field={{
									name: 'adresaSidla',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.adresa-sidla'),
								}}
								label={{ children: ts('formular.pole.adresa-sidla') }}
								control={{ size: 'm' }}
							/>

							{!controls.displaySearchAddress && (
								<Button
									onClick={() => setControls({ displaySearchAddress: true })}
									disabled={isProcessing}
									variant="primary"
									size="m"
									type="outlined">
									{ts('akce.zmenit-adresu')}
								</Button>
							)}
							{controls.displaySearchAddress && (
								<>
									<Button
										onClick={() => {
											setControls({ displaySearchAddress: false });
											resetAddressSearchFields();
										}}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{ts('akce.zrusit-zmeny')}
									</Button>
									<AddressSearchForm
										code={code}
										isProcessing={isProcessing}
									/>
								</>
							)}
						</LayoutGap>
					</FormWidthElementLayout>

					<div>
						<FormLabel legend={true}>
							{ts('formular.pole.kontaktni-udaje')}
						</FormLabel>
						<p>{ts('formular.pole.kontaktni-udaje-popis')}</p>
					</div>
					<FormWidthElementLayout
						type={'xl'}
						className={'flex flex-col gap-4'}>
						<FormCheckbox
							field={{
								name: 'kontaktTelefon',
								disabled: isProcessing,
								label: {
									children: ts('formular.pole.poskytnuti-telefonu'),
								},
							}}
						/>
						{formDefinition.formMethods.watch('kontaktTelefon') ? (
							<LayoutGap className={'ml-10'}>
								{robCommunication?.telefonniCislo ? (
									<FormRadio
										field={{
											name: '_telefon',
											disabled: isProcessing,
											options: [
												{
													value: robCommunication.telefonniCislo,
													label: {
														children: robCommunication.telefonniCislo,
													},
												},
												{
													value: 'another',
													label: {
														children: ts('formular.pole.jiny-telefon'),
													},
												},
											],
										}}
										label={{
											children: ts('formular.pole.vyberte-ze-seznamu'),
										}}
										control={{ size: 'm' }}
										group={{ gap: 's' }}
									/>
								) : (
									<FormInput
										field={{
											name: 'newPhone',
											disabled: isProcessing,
											placeholder: ts('formular.placeholders.novy-telefon'),
										}}
										label={{ children: ts('formular.pole.novy-telefon') }}
										control={{ size: 'm' }}
									/>
								)}
							</LayoutGap>
						) : null}
						{formDefinition.formMethods.watch('_telefon') === 'another' &&
						formDefinition.formMethods.watch('kontaktTelefon') ? (
							<FormInput
								field={{
									name: 'newPhone',
									disabled: isProcessing,
									placeholder: ts('formular.placeholders.novy-telefon'),
								}}
								label={{ children: ts('formular.pole.novy-telefon') }}
								control={{ size: 'm' }}
							/>
						) : null}

						<FormCheckbox
							field={{
								name: 'kontaktEmail',
								disabled: isProcessing,
								label: {
									children: ts('formular.pole.poskytnuti-emailu'),
								},
							}}
						/>
						{formDefinition.formMethods.watch('kontaktEmail') ? (
							<LayoutGap className={'ml-10'}>
								{robCommunication?.email ? (
									<FormRadio
										field={{
											name: '_email',
											disabled: isProcessing,
											options: [
												{
													value: robCommunication.email,
													label: { children: robCommunication.email },
												},
												{
													value: 'another',
													label: {
														children: ts('formular.pole.jiny-email'),
													},
												},
											],
										}}
										label={{
											children: ts('formular.pole.vyberte-ze-seznamu'),
										}}
										control={{ size: 'm' }}
										group={{ gap: 's' }}
									/>
								) : (
									<FormInput
										field={{
											name: 'newEmail',
											disabled: isProcessing,
											placeholder: ts('formular.placeholders.novy-email'),
										}}
										label={{ children: ts('formular.pole.novy-email') }}
										control={{ size: 'm' }}
									/>
								)}
							</LayoutGap>
						) : null}
						{formDefinition.formMethods.watch('_email') === 'another' &&
						formDefinition.formMethods.watch('kontaktEmail') ? (
							<LayoutGap className={'ml-10'}>
								<FormInput
									field={{
										name: 'newEmail',
										disabled: isProcessing,
										placeholder: ts('formular.placeholders.novy-email'),
									}}
									label={{ children: ts('formular.pole.novy-email') }}
									control={{ size: 'm' }}
								/>
							</LayoutGap>
						) : null}
					</FormWidthElementLayout>
					<FormWidthElementLayout
						type={'l'}
						className={'flex flex-col gap-6'}>
						<FormInput
							field={{
								name: 'popisReklamace',
								multiline: true,
								disabled: isProcessing,
								placeholder: ts('formular.placeholders.popis-reklamace'),
							}}
							label={{ children: ts('formular.pole.popis-reklamace') }}
							control={{ size: 'm' }}
						/>

						<ButtonGroupLayout>
							<Button
								loading={isProcessing}
								disabled={isProcessing}
								nativeType="submit"
								variant="primary"
								size="m"
								type="solid">
								{ts('akce.odeslat-reklamaci')}
							</Button>
						</ButtonGroupLayout>
					</FormWidthElementLayout>
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
