import { sanitizeUrl } from '@braintree/sanitize-url';

const resolveUrl = (representation: string, base?: string) => {
	const input = sanitizeUrl(representation);

	try {
		return encodeURI(new URL(input, base).toString());
	} catch {
		return null;
	}
};

export const constructValidUrl = (representation: string) => {
	const hasProtocol =
		representation.startsWith('http:') || representation.startsWith('https:');
	if (hasProtocol) return resolveUrl(representation);

	const isRelative =
		representation.startsWith('/') || representation.startsWith('.');
	if (isRelative) return resolveUrl(representation, window?.location?.href);

	return resolveUrl(`http://${representation}`);
};

export const getIFrameUrlFromString = (s?: string) =>
	s?.match('iframe') && s?.match('(?<=src=").*?(?=[?"])')?.[0];
