import { UseFormReturn } from 'react-hook-form';
import { PeticePeticeDto } from '@gov-nx/api/portal-obcana';
import { ContactDetailsFormData } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { EPeticeStep2Common } from '@gov-nx/module/page';
import { petitionAddresseeTemplateResolver } from '@gov-nx/ui/types';
import { getDefaultPhoneValue } from '@gov-nx/utils/common';

export const setPetitionDetailsValues = <T extends EPeticeStep2Common>(
	form: FormDefinition<T>,
	{ perex, text, adresat }: PeticePeticeDto
): void => {
	const formMethods =
		form.formMethods as unknown as UseFormReturn<EPeticeStep2Common>;
	if (perex && perex.trim()) {
		formMethods.setValue('perex', perex.trim());
	}
	if (text && text.trim()) {
		formMethods.setValue('text', text.trim());
	}
	adresat &&
		formMethods.setValue('adresatOvm', {
			value: petitionAddresseeTemplateResolver(adresat),
			selected: adresat,
		});
};

export const setContactDetailsValues = <T extends ContactDetailsFormData>(
	form: FormDefinition<T>,
	{ email, _useEmail, _usePhone, phone }: ContactDetailsFormData
): void => {
	const { formMethods } =
		form as unknown as FormDefinition<ContactDetailsFormData>;

	formMethods.setValue('_useEmail', _useEmail);
	formMethods.setValue('email', email ?? '');

	formMethods.setValue('_usePhone', _usePhone);
	formMethods.setValue('phone', phone ?? getDefaultPhoneValue());
};
