import { getI18n } from 'react-i18next';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { useUserSettingsLoader } from '../User/userSettingsLoader';
import { useAppLanguageSelector } from './useAppLanguageSelector';

export const useAppLanguage = () => {
	const { language } = useAppLanguageSelector();
	const { persistLanguage } = useUserSettingsLoader();

	const switchTo = async (newLang: LocalizationLanguage) => {
		await getI18n().changeLanguage(newLang);
		await persistLanguage(newLang);
	};

	const toggle = async () => {
		const to =
			language === LocalizationLanguage.Czech
				? LocalizationLanguage.English
				: LocalizationLanguage.Czech;

		return switchTo(to);
	};

	return { language, toggle };
};
