import { Country } from '@gov-nx/api/common';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { useListsStore } from '@gov-nx/store/portal-obcana';
import { AlphabeticalComparator } from '@gov-nx/utils/common';
import { getI18n } from '../translations/i18n';

interface useCountryProps {
	filterOut?: string[];
}

export const useCountry = (props?: useCountryProps) => {
	const i18n = getI18n();
	const selectedLocale =
		i18n.language === LocalizationLanguage.Czech
			? LocalizationLanguage.Czech
			: LocalizationLanguage.English;
	const { countries, getEuropeCountries } = useListsStore();
	const allCountries = countries || [];
	const europeCountries = getEuropeCountries();

	const selectStructure = (country: Country) => ({
		value: country.iso2.toLowerCase(),
		label: country.name[selectedLocale],
	});

	const allCountriesForSelect = allCountries
		.map(selectStructure)
		.sort(AlphabeticalComparator);
	const europeCountriesForSelect = europeCountries
		.map(selectStructure)
		.sort(AlphabeticalComparator);

	const filterCondition = props?.filterOut && props.filterOut;

	const filteredEuCountriesToSelect = europeCountriesForSelect.filter(
		(lang) => !filterCondition?.includes(lang.value)
	);
	const filteredAllCountriesToSelect = allCountriesForSelect.filter(
		(lang) => !filterCondition?.includes(lang.value)
	);

	return {
		allCountries,
		europeCountries,
		allCountriesForSelect,
		europeCountriesForSelect,
		filteredEuCountriesToSelect,
		filteredAllCountriesToSelect,
	};
};
