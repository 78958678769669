import { useEffect } from 'react';
import {
	DataBoxAuthSubsription,
	DataBoxConnectSubscription,
	DataBoxCreateSubscription,
	DataBoxCreditsRefetchSubscription,
	DataBoxHideMessageListSubscription,
	DataBoxListStateChangeSubscription,
	DataBoxMessageListUpdateSubscription,
	DataBoxMessageReadSubscription,
	DataBoxMessagesArchiveSubscription,
	DataBoxMessagesUnselectAllSubscription,
	DataBoxRefreshListSubscription,
	DataBoxResetActionInfoSubscription,
	DataBoxStorageRefetchSubscription,
	DataBoxUnreadMessagesCountsRefetchSubscription,
	ShowDataBoxSettingModalSubscription,
} from './dataBox.types';
import {
	dataBoxConnectionVerifiedSubsription,
	dataBoxConnectSubscription,
	dataBoxCreateSubscription,
	dataBoxCreditsRefetchSubscription,
	dataBoxEvents,
	dataBoxHideMessageListSubscription,
	dataBoxListStateChangeSubscription,
	dataBoxMessageListUpdateSubscription,
	dataBoxMessageReadSubscription,
	dataBoxMessagesArchiveSubscription,
	dataBoxMessagesUnselectAllSubscription,
	dataBoxRefreshListSubscription,
	dataBoxResetActionInfoSubscription,
	dataBoxStorageRefetchSubscription,
	DataBoxSubscriptionTypes,
	dataBoxUnreadMessagesCountsRefetchSubscription,
	showDataBoxSettingModalSubscription,
} from './dataBoxEvents';

interface DataBoxEventsProps {
	onListStateChange?: (
		action: DataBoxListStateChangeSubscription['type'],
		payload: DataBoxListStateChangeSubscription['payload']
	) => void;
	onMessageRead?: (
		action: DataBoxMessageReadSubscription['type'],
		payload: DataBoxMessageReadSubscription['payload']
	) => void;
	onMessagesArchive?: (
		action: DataBoxMessagesArchiveSubscription['type'],
		payload: DataBoxMessagesArchiveSubscription['payload']
	) => void;
	onMessagesUnselectAll?: (
		action: DataBoxMessagesUnselectAllSubscription['type']
	) => void;
	onMessageListUpdate?: (
		action: DataBoxMessageListUpdateSubscription['type']
	) => void;
	onConnect?: (
		action: DataBoxConnectSubscription['type'],
		payload: DataBoxConnectSubscription['payload']
	) => void;
	onCreate?: (action: DataBoxCreateSubscription['type']) => void;
	onCreditsRefetch?: (
		action: DataBoxCreditsRefetchSubscription['type']
	) => void;
	onStorageRefetch?: (
		action: DataBoxStorageRefetchSubscription['type']
	) => void;
	onUnreadMessagesCountsRefetch?: (
		action: DataBoxUnreadMessagesCountsRefetchSubscription['type'],
		payload: DataBoxUnreadMessagesCountsRefetchSubscription['payload']
	) => void;
	onHideMessageList?: (
		action: DataBoxHideMessageListSubscription['type'],
		payload: DataBoxHideMessageListSubscription['payload']
	) => void;
	onResetActionInfo?: (
		action: DataBoxResetActionInfoSubscription['type']
	) => void;
	onRefreshList?: (
		action: DataBoxRefreshListSubscription['type'],
		payload: DataBoxRefreshListSubscription['payload']
	) => void;
	onShowDataBoxSettingModal?: (
		action: ShowDataBoxSettingModalSubscription['type'],
		payload: ShowDataBoxSettingModalSubscription['payload']
	) => void;
	onDataBoxConnectionVerified?: (
		action: DataBoxAuthSubsription['type'],
		payload: DataBoxAuthSubsription['payload']
	) => void;
}

export const useDataBoxEvents = ({
	onListStateChange,
	onMessageRead,
	onMessagesArchive,
	onMessagesUnselectAll,
	onMessageListUpdate,
	onConnect,
	onCreate,
	onCreditsRefetch,
	onStorageRefetch,
	onUnreadMessagesCountsRefetch,
	onHideMessageList,
	onResetActionInfo,
	onRefreshList,
	onShowDataBoxSettingModal,
	onDataBoxConnectionVerified,
}: DataBoxEventsProps = {}) => {
	useEffect(() => {
		const listStateChangeSub =
			onListStateChange &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.listStateChange,
				onListStateChange
			);

		const messageReadSub =
			onMessageRead &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.messageRead,
				onMessageRead
			);

		const messagesArchiveSub =
			onMessagesArchive &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.messagesArchive,
				onMessagesArchive
			);

		const messagesUnselectAllSub =
			onMessagesUnselectAll &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.messagesUnselectAll,
				onMessagesUnselectAll
			);

		const messageListUpdateSub =
			onMessageListUpdate &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.messageListUpdate,
				onMessageListUpdate
			);

		const connectSub =
			onConnect &&
			dataBoxEvents.subscribe(DataBoxSubscriptionTypes.connect, onConnect);
		const createSub =
			onCreate &&
			dataBoxEvents.subscribe(DataBoxSubscriptionTypes.create, onCreate);

		const creditsRefetchSub =
			onCreditsRefetch &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.creditsRefetch,
				onCreditsRefetch
			);

		const storageRefetchSub =
			onStorageRefetch &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.storageRefetch,
				onStorageRefetch
			);

		const unreadMessagesCountsRefetchSub =
			onUnreadMessagesCountsRefetch &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.unreadMessagesCountsRefetch,
				onUnreadMessagesCountsRefetch
			);

		const hideMessageListSub =
			onHideMessageList &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.hideMessageList,
				onHideMessageList
			);

		const resetActionInfoSub =
			onResetActionInfo &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.resetActionInfo,
				onResetActionInfo
			);

		const refreshListSub =
			onRefreshList &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.refreshList,
				onRefreshList
			);

		const showDataBoxSettingModal =
			onShowDataBoxSettingModal &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.showDataBoxSettingModal,
				onShowDataBoxSettingModal
			);

		const databoxAuthSub =
			onDataBoxConnectionVerified &&
			dataBoxEvents.subscribe(
				DataBoxSubscriptionTypes.databoxAuth,
				onDataBoxConnectionVerified
			);

		return () => {
			listStateChangeSub?.unSubscribe();
			messageReadSub?.unSubscribe();
			messagesArchiveSub?.unSubscribe();
			messagesUnselectAllSub?.unSubscribe();
			messageListUpdateSub?.unSubscribe();
			connectSub?.unSubscribe();
			createSub?.unSubscribe();
			creditsRefetchSub?.unSubscribe();
			storageRefetchSub?.unSubscribe();
			unreadMessagesCountsRefetchSub?.unSubscribe();
			hideMessageListSub?.unSubscribe();
			resetActionInfoSub?.unSubscribe();
			refreshListSub?.unSubscribe();
			showDataBoxSettingModal?.unSubscribe();
			databoxAuthSub?.unSubscribe();
		};
	}, [
		onConnect,
		onCreate,
		onCreditsRefetch,
		onHideMessageList,
		onListStateChange,
		onMessageListUpdate,
		onMessageRead,
		onMessagesArchive,
		onMessagesUnselectAll,
		onStorageRefetch,
		onUnreadMessagesCountsRefetch,
		onResetActionInfo,
		onRefreshList,
		onShowDataBoxSettingModal,
		onDataBoxConnectionVerified,
	]);

	const listStateChange = (
		payload: DataBoxListStateChangeSubscription['payload']
	) => {
		dataBoxEvents.publish(dataBoxListStateChangeSubscription(payload));
	};

	const messageRead = (payload: DataBoxMessageReadSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxMessageReadSubscription(payload));
	};

	const messagesArchive = (
		payload: DataBoxMessagesArchiveSubscription['payload']
	) => {
		dataBoxEvents.publish(dataBoxMessagesArchiveSubscription(payload));
	};

	const messagesUnselectAll = () => {
		dataBoxEvents.publish(dataBoxMessagesUnselectAllSubscription());
	};

	const messageListUpdate = () => {
		dataBoxEvents.publish(dataBoxMessageListUpdateSubscription());
	};

	const connect = (payload: DataBoxConnectSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxConnectSubscription(payload));
	};

	const create = () => {
		dataBoxEvents.publish(dataBoxCreateSubscription());
	};

	const creditsRefetch = () => {
		dataBoxEvents.publish(dataBoxCreditsRefetchSubscription());
	};

	const storageRefetch = () => {
		dataBoxEvents.publish(dataBoxStorageRefetchSubscription());
	};

	const unreadMessagesCountsRefetch = (
		payload: DataBoxUnreadMessagesCountsRefetchSubscription['payload']
	) => {
		dataBoxEvents.publish(
			dataBoxUnreadMessagesCountsRefetchSubscription(payload)
		);
	};

	const hideMessageList = (
		payload: DataBoxHideMessageListSubscription['payload']
	) => {
		dataBoxEvents.publish(dataBoxHideMessageListSubscription(payload));
	};

	const resetActionInfo = () => {
		dataBoxEvents.publish(dataBoxResetActionInfoSubscription());
	};

	const refreshList = (payload: DataBoxRefreshListSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxRefreshListSubscription(payload));
	};

	const showDataBoxSettingModal = (
		payload: ShowDataBoxSettingModalSubscription['payload']
	) => {
		dataBoxEvents.publish(showDataBoxSettingModalSubscription(payload));
	};

	const dataBoxConnectionVerified = (
		payload: DataBoxAuthSubsription['payload']
	) => {
		dataBoxEvents.publish(dataBoxConnectionVerifiedSubsription(payload));
	};

	return {
		listStateChange,
		messageRead,
		messagesArchive,
		messagesUnselectAll,
		messageListUpdate,
		connect,
		create,
		creditsRefetch,
		storageRefetch,
		unreadMessagesCountsRefetch,
		hideMessageList,
		resetActionInfo,
		refreshList,
		showDataBoxSettingModal,
		dataBoxConnectionVerified,
	};
};
