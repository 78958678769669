import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	fetchSettingsQuery,
	saveSettingsQuery,
	usePoMutation,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	usePersonCommunicationData,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { is } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import {
	getFormKeys,
	getNotificationSettingKeys,
	NotificationFormInstance,
	notificationSettingQueryKey,
	prepareSubmitData,
	useSetFormValues,
} from './FormDefinitions';
import { FormFieldType, NotificationsSettingsContext } from './context.types';
import {
	FormNotificationFields,
	NotificationSavePayload,
	NotificationSettingsPayload,
} from './form.types';

const NotificationsSettingContext =
	createContext<NotificationsSettingsContext | null>(null);

interface NastaveniUpozorneniContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	hasPushNotifications?: boolean;
	pushNotificationsEnabled?: boolean;
}

export function NastaveniUpozorneniContextProvider({
	children,
	code,
	hasPushNotifications = false,
	pushNotificationsEnabled = false,
}: NastaveniUpozorneniContextProviderProps) {
	const { controls, setControls } = useProcessControl();
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const queryClient = useQueryClient();
	const { emailVerified, phoneVerified } = usePersonCommunicationData();

	const keySettingQueries = usePoQueries({
		queries: getNotificationSettingKeys().map((key) => {
			return {
				queryKey: notificationSettingQueryKey(key),
				queryFn: () => fetchSettingsQuery<NotificationSettingsPayload>(key),
				refetchOnWindowFocus: false,
			};
		}),
	});

	const keySettingQueriesResponses = keySettingQueries
		.map((result) => (!result.isLoading ? result.data : undefined))
		.filter(is);

	const isQueryFetched =
		keySettingQueriesResponses.length === keySettingQueries.length;
	const formMethods = NotificationFormInstance();

	useEffect(() => {
		if (isQueryFetched && controls.initialLoading) {
			setControls({ initialLoading: false });
		}
	}, [isQueryFetched, controls.initialLoading, setControls]);

	useSetFormValues(pushNotificationsEnabled, formMethods);

	const mutation = usePoMutation<
		AxiosResponse<void>,
		NotificationSettingsPayload,
		{ previousValues?: NotificationSettingsPayload }
	>({
		mutationFn: saveSettingsQuery,
		onMutate: async (
			payload
		): Promise<{ previousValues?: NotificationSettingsPayload }> => {
			const queryKey = notificationSettingQueryKey(payload.klic);

			await queryClient.cancelQueries({ queryKey });
			const previousValues =
				queryClient.getQueryData<NotificationSettingsPayload>(queryKey);

			queryClient.setQueryData(queryKey, () => payload);

			return { previousValues };
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			toastMessage({
				content: t('messages.ulozeno'),
				options: {
					variant: 'success',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
			});
		},
		onError: async (error, payload, context) => {
			// roll back the previous values
			queryClient.setQueryData(
				notificationSettingQueryKey(payload.klic),
				context?.previousValues
			);

			setControls({ processError: error, processLoading: false });
			formMethods.reset(formMethods.control._defaultValues);
		},
		onSettled: async (response, error, payload) => {
			await queryClient.invalidateQueries(
				notificationSettingQueryKey(payload.klic)
			);
		},
	});

	const handleChange = useCallback(
		(
			name: keyof FormNotificationFields,
			type: NotificationSavePayload['type']
		) => {
			setControls({ processError: null, processLoading: true });

			const values = formMethods.getValues();
			const prepared = prepareSubmitData(`${name}.${type}`, values);
			if (prepared) {
				return mutation.mutate(prepared);
			}
		},
		[formMethods, mutation, setControls]
	);

	const allTypes = useMemo((): FormFieldType[] => {
		return [
			{
				type: 'email',
				isDisabled: !emailVerified,
			},
			{
				type: 'sms',
				isDisabled: !phoneVerified,
			},
			{
				type: 'push',
				isDisabled: !pushNotificationsEnabled,
			},
		]
			.filter(({ type }): boolean => type !== 'push' || hasPushNotifications)
			.map((t) => t as FormFieldType);
	}, [
		hasPushNotifications,
		emailVerified,
		phoneVerified,
		pushNotificationsEnabled,
	]);

	return (
		<NotificationsSettingContext.Provider
			value={{
				controls,
				formMethods,
				allKeys: getFormKeys(),
				allTypes,
				handleChange,
				noContactDetails: !emailVerified || !phoneVerified,
			}}>
			{children}
		</NotificationsSettingContext.Provider>
	);
}

export const useNastaveniUpozorneniContextInstance =
	(): NotificationsSettingsContext =>
		useContext(NotificationsSettingContext) as NotificationsSettingsContext;
