import { useState } from 'react';
import {
	DokumentyDokumentDto,
	KosPolozkaKosDto,
} from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

export const useMyFilesModals = () => {
	const [fileForActionModal, setFileForActionModal] =
		useState<Nullable<DokumentyDokumentDto>>(null);

	const [binnedFileForActionModal, setBinnedFileForActionModal] =
		useState<Nullable<KosPolozkaKosDto>>(null);

	const [noFileModal, setNoFileModal] =
		useState<Nullable<DokumentyDokumentDto>>(null);

	const closeActiveModal = () =>
		fileForActionModal
			? setFileForActionModal(null)
			: setBinnedFileForActionModal(null);

	return {
		fileForActionModal,
		setFileForActionModal,
		binnedFileForActionModal,
		setBinnedFileForActionModal,
		noFileModal,
		setNoFileModal,
		closeActiveModal,
	};
};
