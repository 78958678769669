import * as yup from 'yup';
import { useContactDetailsFormDefinition } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { WebFileProps } from '@gov-nx/ui/types';
import {
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	phoneShapeToString,
} from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import {
	EPeticeFormDataStep1,
	EPeticeDataRequest,
	EPeticeFormDataPOStep2,
	EPeticeFormDataFOStep2,
	EPeticeStep2Common,
} from './wizzard.types';

export interface FormInstanceProps {
	code: PageCode;
}

export function FormInstanceStep1({
	code,
}: FormInstanceProps): FormDefinition<EPeticeFormDataStep1> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<EPeticeFormDataStep1>>({
			nazev: yup
				.string()
				.max(200, tsn('formular.validace.nazev.max'))
				.required(tsn('formular.validace.nazev.povinny')),
			zakladatelTyp: yup.string().oneOf(['PO', 'FO']),
		})
		.required();

	const formMethods = usePoForm<EPeticeFormDataStep1>({
		formSchema,
		defaultValues: {
			nazev: undefined,
			zakladatelTyp: undefined,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

interface Step2CommonFormDefinition {
	schema: FormSchemaShape<EPeticeStep2Common>;
	defaultValues: EPeticeStep2Common;
}

const useCommonFormDefinition = (code: PageCode): Step2CommonFormDefinition => {
	const { getLocalizeCurried } = useTranslationWithNamespace();

	const tsn = getLocalizeCurried(code);

	return {
		schema: {
			perex: yup
				.string()
				.max(290, tsn('formular.validace.perex.max'))
				.required(tsn('formular.validace.perex.povinny')),
			text: yup
				.string()
				.max(3500, tsn('formular.validace.text.max'))
				.required(tsn('formular.validace.text.povinny')),
			adresatOvm: getAutocompleteShape({
				requiredMessage: tsn('formular.validace.adresat.povinny'),
			}),
			priloha: yup.array(),
		},
		defaultValues: {
			perex: '',
			text: '',
			adresatOvm: getAutocompleteDefaultValues(),
			priloha: undefined,
		},
	};
};

interface Step2Props extends FormInstanceProps {
	petitionAttachmentsOptions: WebFileProps['options'];
}

export interface FormStep2PO {
	fields: {
		attachments: WebFileProps;
	};
	formDefinition: FormDefinition<EPeticeFormDataPOStep2>;
}

export function FormInstancePOStep2({
	code,
	petitionAttachmentsOptions,
}: Step2Props): FormStep2PO {
	const { schema, defaultValues } = useCommonFormDefinition(code);
	const contactDetailsFormDefinition = useContactDetailsFormDefinition();
	const formSchema = yup
		.object<FormSchemaShape<EPeticeFormDataPOStep2>>({
			...schema,
			...contactDetailsFormDefinition.shape,
			zakladatelPoIco: yup.number(),
		})
		.required();

	const formMethods = usePoForm<EPeticeFormDataPOStep2>({
		formSchema,
		defaultValues: {
			...defaultValues,
			...contactDetailsFormDefinition.defaultValues,
			zakladatelPoIco: undefined,
		},
	});
	return {
		fields: {
			attachments: {
				field: { name: 'priloha', expanded: true },
				// label: { children: t('formular.pole.priloha') },
				options: petitionAttachmentsOptions,
			},
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
}

export interface FormStep2FO {
	fields: {
		attachments: WebFileProps;
	};
	formDefinition: FormDefinition<EPeticeFormDataFOStep2>;
}

export function FormInstanceFOStep2({
	code,
	petitionAttachmentsOptions,
}: Step2Props): FormStep2FO {
	const { schema, defaultValues } = useCommonFormDefinition(code);
	const contactDetailsFormDefinition = useContactDetailsFormDefinition();

	const formSchema = yup
		.object<FormSchemaShape<EPeticeFormDataFOStep2>>({
			...schema,
			...contactDetailsFormDefinition.shape,
			_useAddress: yup.boolean(),
			adresa: yup.object(),
			clenovePeticnihoVyboru: yup.array().of(
				yup.object({
					jmeno: yup.string(),
					prijmeni: yup.string(),
					nazevObce: yup.string(),
					nazevUlice: yup.string(),
					cislo: yup.string(),
				})
			),
		})
		.required();

	const formMethods = usePoForm<EPeticeFormDataFOStep2>({
		formSchema,
		defaultValues: {
			...defaultValues,
			...contactDetailsFormDefinition.defaultValues,
			_useAddress: false,
			adresa: undefined,
			clenovePeticnihoVyboru: [],
		},
	});
	return {
		fields: {
			attachments: {
				field: { name: 'priloha', expanded: true },
				options: petitionAttachmentsOptions,
			},
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
}

export const prepareSubmitData = (
	step1FormData: EPeticeFormDataStep1,
	step2FormData?: EPeticeFormDataPOStep2 | EPeticeFormDataFOStep2
): EPeticeDataRequest => {
	if (step1FormData.zakladatelTyp === 'PO') {
		const data = step2FormData as EPeticeFormDataPOStep2;
		return {
			zakladatelTyp: 'PO',
			nazev: step1FormData.nazev,
			perex: data.perex,
			text: data.text,
			adresatOvmId: data.adresatOvm.selected?.id ?? 0,
			zakladatelPoIco: data.zakladatelPoIco,
			kontaktniUdaje: {
				adresa: {
					kod: '',
					adresaText: '',
				},
				email: data._useEmail ? data.email : undefined,
				telefon:
					data._usePhone && data.phone
						? phoneShapeToString(data.phone)
						: undefined,
			},
		};
	}

	const dataFO = step2FormData as EPeticeFormDataFOStep2;

	return {
		zakladatelTyp: 'FO',
		nazev: step1FormData.nazev,
		perex: dataFO.perex,
		text: dataFO.text,
		adresatOvmId: dataFO.adresatOvm.selected?.id ?? 0,
		clenovePeticnihoVyboru: dataFO.clenovePeticnihoVyboru ?? [],
		kontaktniUdaje: {
			adresa: {
				kod: dataFO._useAddress && dataFO.adresa?.kod ? dataFO.adresa.kod : '',
				adresaText:
					dataFO._useAddress && dataFO.adresa?.adresaText
						? dataFO.adresa.adresaText
						: '',
			},
			email: dataFO._useEmail ? dataFO.email : '',
			telefon:
				dataFO._usePhone && dataFO.phone
					? phoneShapeToString(dataFO.phone)
					: '',
		},
	};
};
