import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { isValidDate, toStringDate, useDateShape } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { OwnDocumentFormData, OwnDokumentDataRequest } from './context.types';

interface FormDefinitionProps {
	code: PageCode;
}

export function FormInstance({
	code,
}: FormDefinitionProps): FormDefinition<OwnDocumentFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object({
			_replaceFile: yup.boolean(),
			nazev: yup
				.string()
				.max(255, tsn('formular.validace.nazev.max'))
				.required(tsn('formular.validace.nazev.povinny')),
			cisloDokumentu: yup
				.string()
				.max(255, tsn('formular.validace.cislo-dokumentu.max')),
			komentar: yup.string().max(255, tsn('formular.validace.komentar.max')),
			platnostOd: getDatePickerShape(),
			platnostDo: getDatePickerShape().when('platnostOd', (from, schema) => {
				if (isValidDate(from)) {
					return schema.min(
						from,
						tsn('formular.validace.platnost-do.starsi-nez-platnost-od')
					);
				}
				return schema;
			}),
			nazevSouboru: yup.string(),
			soubor: yup.object().nullable().optional(),
		})
		.required();

	const formMethods = usePoForm<OwnDocumentFormData>({
		formSchema,
		defaultValues: {
			_replaceFile: false,
			nazev: undefined,
			nazevSouboru: undefined,
			platnostDo: undefined,
			platnostOd: undefined,
			cisloDokumentu: undefined,
			komentar: undefined,
			soubor: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

class FormDataTyped<T> {
	formData = new FormData();

	append<K extends keyof T>(key: K, value: T[K]) {
		this.formData.append(key as string, value as string);
	}
}

export const prepareSubmitData = (
	formData: OwnDocumentFormData,
	isEditAction: boolean
) => {
	const requestBody = new FormDataTyped<OwnDokumentDataRequest>();
	requestBody.append('nazev', formData.nazev);
	requestBody.append('kategorie', '#DOKLAD#');
	requestBody.append('zdroj', 'UZIVATELEM_NAHRANY_DOKUMENT');
	if (formData.komentar) {
		requestBody.append('komentar', formData.komentar);
	}
	if (formData.platnostOd) {
		requestBody.append('platnostOd', toStringDate(formData.platnostOd));
	}
	if (formData.platnostDo) {
		requestBody.append('platnostDo', toStringDate(formData.platnostDo));
	}
	if (formData.cisloDokumentu) {
		requestBody.append('cisloDokumentu', formData.cisloDokumentu);
	}

	const fileId = formData.soubor?.[0]?.fileId;
	if (isEditAction && fileId) {
		requestBody.append('soubor', fileId);
	}
	if (isEditAction && !formData._replaceFile && formData.nazevSouboru) {
		requestBody.append('nazevSouboru', formData.nazevSouboru);
	}

	if (!isEditAction && fileId) {
		requestBody.append('soubor', fileId);
		if (formData.soubor?.[0]?.name) {
			requestBody.append('nazevSouboru', formData.soubor?.[0]?.name);
		}
	}

	return requestBody.formData;
};
