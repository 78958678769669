import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import {
	RegistrVozidelResponse,
	Vehicle,
	getTotalVehicleCount,
	getVehicleList,
} from '@gov-nx/module/page';
import { axiosInstance } from '../axios';

export const fetchDataPreviewsQuery = async <
	T extends { zdrojUdaju: string },
	Response
>(
	params: T
): Promise<AxiosResponse<Response>> => {
	govApiLog(`/api/v1/nahledyudaju?zdrojUdaju=${params.zdrojUdaju}`);
	return await axiosInstance.get<Response>(`/api/v1/nahledyudaju`, { params });
};

export const vehicleRegistryPreviewsQuery = async <
	T extends { zdrojUdaju: string; start: number; pocet: number }
>(
	params: T,
	vehicleData: Vehicle[] = []
): Promise<Vehicle[]> => {
	const response = await axiosInstance.get<RegistrVozidelResponse>(
		`/api/v1/nahledyudaju`,
		{ params }
	);
	const data = getVehicleList(response);
	const vehicleCount = getTotalVehicleCount(response);

	const newVehicleData = [...vehicleData, ...data];

	if (vehicleCount && newVehicleData.length < vehicleCount) {
		return vehicleRegistryPreviewsQuery(
			{ ...params, start: params.start + 1 },
			newVehicleData
		);
	}

	return newVehicleData;
};
