import * as yup from 'yup';
import { PeticeAdresaDto } from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { has } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import { AddressLookupFormData, useAddressLookup } from './useAddressLookup';

interface PetitionMemberAddressLookupFormData extends AddressLookupFormData {
	jmeno: string;
	prijmeni: string;
}

export interface PetitionMemberAddressLookupProps {
	code: PageCode;
	onSubmit: (data: {
		formData: PetitionMemberAddressLookupFormData;
		address?: PeticeAdresaDto;
	}) => void;
}

export const usePetitionMemberAddressLookup = ({
	code,
	onSubmit,
}: PetitionMemberAddressLookupProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<PetitionMemberAddressLookupFormData>>({
			jmeno: yup.string().required(tsn('formular.validace.jmeno.povinny')),
			prijmeni: yup
				.string()
				.required(tsn('formular.validace.prijmeni.povinny')),
			cislo: yup
				.string()
				.required(tsn('formular.validace.cislo.povinny'))
				.matches(/^[0-9]+$/, tsn('formular.validace.cislo.pouze-cislice'))
				.max(4, tsn('formular.validace.cislo.max-9999')),
			nazevObce: yup
				.string()
				.required(tsn('formular.validace.nazev-obce.povinny')),
			nazevUlice: yup.string(),
			_address: yup.string(),
		})
		.required();

	const formMethods = usePoForm<PetitionMemberAddressLookupFormData>({
		formSchema,
		defaultValues: {
			_address: undefined,
			jmeno: undefined,
			prijmeni: undefined,
			cislo: undefined,
			nazevObce: undefined,
			nazevUlice: undefined,
		},
	});
	const formDefinition = getFormDefinition({ formMethods, formSchema });

	const addressLookup = useAddressLookup({
		code,
		onLookUpSuccess: (data) =>
			data[0].kod &&
			formDefinition.formMethods.setValue('_address', data[0].kod),
	});

	return {
		...addressLookup,
		options: (addressLookup.results ?? []).filter(has('kod')).map((option) => ({
			value: option.kod,
			label: option.adresaText ?? '',
		})),
		onLookup: formMethods.handleSubmit(() =>
			addressLookup.lookupMutation.mutate(
				formDefinition.formMethods.getValues()
			)
		),
		onSubmit: formMethods.handleSubmit((formData) => {
			const address = addressLookup.results?.find(
				(address) => address.kod === formData._address
			);

			formDefinition.formReset();
			addressLookup.formDefinition.formReset();
			addressLookup.lookupMutation.reset();

			return onSubmit({ formData, address });
		}),

		formDefinition,
		disableSubmitButton: !formDefinition.formMethods.watch('_address'),
	};
};
