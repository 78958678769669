import { useForm, UseFormReturn } from 'react-hook-form';
import { NotificationCheckboxesValues } from './utils';

export enum DataBoxNotificationToggleInputName {
	Sms = 'enabledSms',
	Email = 'enabledEmail',
	EmailExtended = 'enabledEmailAttachment',
}

type NotificationSettingsForm = UseFormReturn<{
	[databoxId: string]: NotificationCheckboxesValues;
}>;

export const FormInstance = (databoxId: string): NotificationSettingsForm => {
	const methods = useForm({
		defaultValues: {
			[databoxId]: {
				[DataBoxNotificationToggleInputName.Sms]: false,
				[DataBoxNotificationToggleInputName.Email]: false,
				[DataBoxNotificationToggleInputName.EmailExtended]: false,
			},
		},
	});

	return methods;
};

export const setNotificationCheckboxesValues = (
	form: NotificationSettingsForm,
	databoxId: string,
	complextValue: NotificationCheckboxesValues
): void => {
	form.setValue(
		`${databoxId}.${DataBoxNotificationToggleInputName.Sms}`,
		complextValue.enabledSms
	);
	form.setValue(
		`${databoxId}.${DataBoxNotificationToggleInputName.Email}`,
		complextValue.enabledEmail
	);
	form.setValue(
		`${databoxId}.${DataBoxNotificationToggleInputName.EmailExtended}`,
		complextValue.enabledEmailAttachment
	);
};

export const setExtendedNotification = (
	form: NotificationSettingsForm,
	databoxId: string,
	value: boolean
): void => {
	if (value) {
		form.setValue(
			`${databoxId}.${DataBoxNotificationToggleInputName.Email}`,
			true
		);
	}
	form.setValue(
		`${databoxId}.${DataBoxNotificationToggleInputName.EmailExtended}`,
		value
	);
};
