import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, Modal } from '@gov-nx/ui/web';

type DataBoxSupportModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const DataBoxSupportModal = ({
	isOpen,
	onClose,
}: DataBoxSupportModalProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { napovedaDatoveSchrankyUrl, portalObcanaEmail } = useConfiguration();

	return (
		<Modal
			open={isOpen}
			onGov-close={onClose}
			label={tn('schranky.napoveda')}>
			<div className="flex flex-col space-y-8">
				{napovedaDatoveSchrankyUrl && (
					<div className="flex flex-col space-y-2">
						<div>{tn('napoveda.vice-o-datovych-schrankach')}</div>
						<Button
							href={napovedaDatoveSchrankyUrl}
							enforceLink={true}
							target="_blank"
							variant="primary"
							type="outlined"
							wcagLabel={tn('napoveda.prejit-na-gov')}>
							<GovIcon
								name="box-arrow-up-right"
								slot="right-icon"
							/>
							{tn('napoveda.prejit-na-gov')}
						</Button>
					</div>
				)}
				<div className="flex flex-col space-y-2">
					<div>{tn('napoveda.napiste-na-podporu')}</div>
					<div className="flex space-x-4 items-center">
						<Button
							href={routeResolver(PageCode['podpora'])}
							enforceLink={true}
							target="_blank"
							variant="primary"
							type="outlined"
							wcagLabel={tn('napoveda.formular-podpory')}>
							{tn('napoveda.formular-podpory')}
							<GovIcon
								name="question-circle"
								slot="right-icon"
							/>
						</Button>
						<a
							href={'mailto:' + portalObcanaEmail}
							className={
								'gov-link text-s text-primary-600 font-semibold max-w-fit'
							}>
							{portalObcanaEmail}
						</a>
					</div>
				</div>
			</div>
			<GovIcon
				type="basic"
				name="question-circle"
				slot="icon"
			/>
		</Modal>
	);
};
