import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
	DatoveSchrankyDatovaSchrankaStavDto,
	fetchDataBoxesUnreadMessagesCountsQuery,
	isResponseStatus,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useAppContext } from '@gov-nx/core/app';
import { getKeys, getValues } from '@gov-nx/core/types';

const databoxUnreadCounts = (
	databoxes: DatoveSchrankyDatovaSchrankaStavDto[]
) =>
	databoxes.reduce<Record<string, number>>((acc, databox) => {
		if (databox.datovaSchrankaId && databox.pocetNeprecteno !== undefined) {
			return {
				...acc,
				...{ [databox.datovaSchrankaId]: databox.pocetNeprecteno },
			};
		}

		return acc;
	}, {});

const queryKey = ['data-boxes-unread-messages-counts'];

export const useDataBoxUnreadMessages = () => {
	const { setAppError } = useAppContext();
	const queryClient = useQueryClient();
	const query = usePoQuery(
		{
			queryKey,
			queryFn: fetchDataBoxesUnreadMessagesCountsQuery,
			onError: setAppError,
			enabled: false,
			retry: 1,
		},
		{
			errorIgnoreFilter: isResponseStatus(404),
		}
	);

	const dataBoxesUnreadMessagesCounts = databoxUnreadCounts(
		query.data?.data.seznam ?? []
	);

	const unreadMessagesCount = getValues(dataBoxesUnreadMessagesCounts).reduce(
		(acc, item) => acc + item,
		0
	);

	const initDataBoxUnreadMessages = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetchDataBoxUnreadMessages = useCallback(async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey });

		await query.refetch();
	}, [query, queryClient]);

	return {
		initDataBoxUnreadMessages,
		refetchDataBoxUnreadMessages,
		isReFetching: query.isFetching,
		isLoading: query.isLoading,
		unreadMessagesCount,
		getUnreadMessagesCounts: (dataBoxId: string) =>
			dataBoxesUnreadMessagesCounts[dataBoxId] ?? null,
		hasUnreadMessages: unreadMessagesCount > 0,
		unreadMessagesDataboxIds: getKeys(dataBoxesUnreadMessagesCounts),
	};
};
