import { ConsentRequest, ConsentDataRequest } from './context.types';

export const prepareSubmitData = (
	postData: ConsentRequest
): ConsentDataRequest => {
	return {
		body: {
			kodPodani: 'ZR10_ODVOLANI',
			souhlasId: postData.souhlasId,
			nazevOsoby: postData.nazevOsoby,
			datovaSchrankaId: postData.datovaSchrankaId,
			typCisloID: postData.typCisloID,
		},
	};
};
