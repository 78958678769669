import {
	DatovkaNastaveniArchivaceValue,
	useArchivingSettingsQuery,
	useNotificationSettingsQuery,
} from '@gov-nx/api/portal-obcana';
import { useNotificationSettingsValues } from '@gov-nx/module/page';

export interface DataBoxSettingsProps {
	dataBoxId: string;
}

export const useDataBoxSettings = ({ dataBoxId }: DataBoxSettingsProps) => {
	const settingsValues = useNotificationSettingsValues();
	const archivingQuery = useArchivingSettingsQuery(dataBoxId);
	const notificationSettingQuery = useNotificationSettingsQuery(dataBoxId);

	const isNotificationsEnabled = () => {
		if (notificationSettingQuery.isLoading || !notificationSettingQuery.data) {
			return false;
		}

		const { enabledSms, enabledEmail, enabledEmailAttachment } =
			settingsValues.decompose(notificationSettingQuery.data.hodnota);

		return enabledEmail || enabledEmailAttachment || enabledSms;
	};

	const isAutomaticArchiveEnabled = () => {
		if (archivingQuery.isLoading || !archivingQuery.data) {
			return false;
		}

		const { klic, hodnota } = archivingQuery.data || {};
		return !!(
			klic && hodnota === DatovkaNastaveniArchivaceValue.LargeDataboxMessage
		);
	};

	return {
		automaticArchiveEnabled: isAutomaticArchiveEnabled(),
		notificationsEnabled: isNotificationsEnabled(),
	};
};
