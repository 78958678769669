import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { PeticePeticeDto } from '@gov-nx/api/portal-obcana';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { CopyButton } from '@gov-nx/ui/web';
import { slugify } from '@gov-nx/utils/common';

export interface EPetitionShareMessageProps {
	petition: Nullable<PeticePeticeDto>;
	code: PageCode;
}

export const EPetitionShareMessage = ({
	petition,
	code,
}: EPetitionShareMessageProps) => {
	const { ePeticeGovDetailUrl } = useConfiguration();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	const link = `${ePeticeGovDetailUrl}${petition?.id}-${slugify(
		petition?.nazev
	)}`;

	return (
		<GovMessage variant={'primary'}>
			<GovIcon
				type={'basic'}
				name={'lightbulb-fill'}
				slot={'icon'}></GovIcon>
			<div
				className={
					'flex-col flex justify-between items-start gap-4 [ md:flex-row ]'
				}>
				<div className={'flex-col flex gap-2 flex-1'}>
					<p>{ts('zprava.pole.sdilet')}</p>
					<a
						href={link}
						target="_blank"
						rel="noopener noreferrer">
						{link}
					</a>
				</div>
				<CopyButton
					type={'outlined'}
					label={ts('akce.zkopirovat-odkaz')}
					value={link}>
					{ts('akce.zkopirovat-odkaz')}
				</CopyButton>
			</div>
		</GovMessage>
	);
};
