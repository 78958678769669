import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	isValidDate,
	dataBoxParams,
	today,
	toStringDate,
	useDataBoxShape,
	yearsAgo,
	resetHours,
	useDateShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { FormData, RequestData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const fromYearsAgoMax = 1;
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);

	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			datumOd: getDatePickerShape({
				isInvalidMessage: tsn('form.validations.zadejte-datum-od'),
			})
				.required(tsn('form.validations.zadejte-datum-od'))
				.min(
					resetHours(yearsAgo(fromYearsAgoMax)),
					tsn('form.validations.minimum-datum-od')
				)
				.max(today(), tsn('form.validations.maximum-datum-od')),

			datumDo: getDatePickerShape({
				isInvalidMessage: tsn('form.validations.zadejte-datum-do'),
			})
				.required(tsn('form.validations.zadejte-datum-do'))
				.max(today(), tsn('form.validations.maximum-datum-do'))
				.when('datumOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(
							from,
							tsn('form.validations.starsi-nez-datum-od')
						);
					}
					return schema;
				}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			datumOd: yearsAgo(fromYearsAgoMax),
			datumDo: today(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (formData: FormData): RequestData => {
	return {
		params: dataBoxParams(formData),
		body: {
			typVypisu: 'UDAJE_ROS_VYUZITI',
			data: {
				datumOd: toStringDate(formData.datumOd),
				datumDo: toStringDate(formData.datumDo),
			},
		},
	};
};
