import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useNavigate } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxConfirmModalType,
	useDataBoxMessageDetailContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

const Icon = () => {
	return (
		<GovIcon
			name="exclamation-triangle-fill"
			slot="icon"
			className={'text-error-400'}
		/>
	);
};

const CancelButton = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { setConfirmModal } = useDataBoxMessageDetailContext();
	return (
		<Button
			nativeType="button"
			variant="primary"
			type="base"
			slot="actions"
			onClick={() => setConfirmModal(null)}
			wcagLabel={tn('zpravy.zrusit')}>
			{tn('zpravy.zrusit')}
		</Button>
	);
};

export const DataBoxMessageConfirmModal = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		dataBoxId,
		folderType,
		messageOperations,
		confirmModal,
		setConfirmModal,
		loadingItems,
	} = useDataBoxMessageDetailContext();

	const navigate = useNavigate();

	const redirect = () => {
		navigate(
			routeResolver(PageCode['datove-schranky-seznam'], {
				dataBoxId,
				folderType,
			})
		);
	};

	return (
		<>
			{confirmModal === DataBoxConfirmModalType.removeMessage && (
				<Prompt
					label={tn('zpravy.smazani-kopie-zpravy')}
					open={
						!!confirmModal &&
						DataBoxConfirmModalType.removeMessage.includes(confirmModal)
					}
					onGov-close={() => setConfirmModal(null)}>
					<Icon />
					<p className={'mb-3 text-l text-secondary-800'}>
						{tn('zpravy.opravdu-chcete-smazat')}
					</p>
					<p className={'mb-6'}>
						<strong>{tn('zpravy.smazanim-do-kose')}</strong>{' '}
						{tn('zpravy.zpravu-najdete-slozky')}
					</p>
					<p>{tn('zpravy.kopii-zpravy-muzete-smazat')}</p>
					<CancelButton />
					<Button
						nativeType="button"
						variant="error"
						type="outlined"
						slot="actions"
						loading={loadingItems.removePermanently}
						onClick={() =>
							messageOperations.handleDeleteMessage(true, redirect)
						}
						disabled={!!loadingItems.removePermanently}
						wcagLabel={tn('zpravy.trvale-smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{tn('zpravy.trvale-smazat')}
						</span>
					</Button>
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="actions"
						loading={loadingItems.remove}
						onClick={() =>
							messageOperations.handleDeleteMessage(false, redirect)
						}
						disabled={!!loadingItems.remove}
						wcagLabel={tn('zpravy.smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{tn('zpravy.smazat')}
						</span>
					</Button>
				</Prompt>
			)}

			{confirmModal === DataBoxConfirmModalType.removeMessagesPermanently && (
				<Prompt
					label={tn('zpravy.trvale-smazani-zpravy')}
					open={
						!!confirmModal &&
						DataBoxConfirmModalType.removeMessagesPermanently.includes(
							confirmModal
						)
					}
					onGov-close={() => setConfirmModal(null)}>
					<Icon />
					<p className={'mb-3 text-l text-secondary-800'}>
						{tn('zpravy.opravdu-trvale-smazat')}
					</p>
					<p>{tn('zpravy.trvale-smazane-neobnovite')}</p>
					<CancelButton />
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="actions"
						loading={loadingItems.removePermanently}
						onClick={() =>
							messageOperations.handleDeleteMessage(true, redirect)
						}
						disabled={!!loadingItems.removePermanently}
						wcagLabel={tn('zpravy.trvale-smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							{tn('zpravy.trvale-smazat')}
						</span>
					</Button>
				</Prompt>
			)}
		</>
	);
};
