import { CmsMessage, useCmsMessagesQuery } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';

export interface CmsMessageLoaderProps {
	onSuccess?: (messages: CmsMessage[]) => void;
	onError?: (e: Error) => void;
}

export const useCmsMessageLoader = (props?: CmsMessageLoaderProps) => {
	const query = useCmsMessagesQuery({
		onError: (message: string) => {
			props?.onError && props?.onError(new GovError(message));
		},
		onSuccess: (data) => props?.onSuccess && props?.onSuccess(data),
	});

	return { messageQuery: query, messages: query.data ?? [] };
};
