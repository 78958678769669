import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';

export const OwnDocumentFormSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout>
			<LayoutGap gap={6}>
				{[0, 1, 2, 3].map((i) => {
					return (
						<div
							key={i}
							className={'flex flex-col'}>
							<GovSkeleton
								width={'115px'}
								height={'22px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<div className={'[ md:w-[330px] ]'}>
								<GovSkeleton
									width={'100%'}
									height={'40px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
						</div>
					);
				})}
				<div className={'flex flex-col'}>
					<GovSkeleton
						width={'115px'}
						height={'22px'}
						wcagLabel={
							tg('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						width={'100%'}
						height={'115px'}
						wcagLabel={
							tg('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<div className={'flex flex-col gap-4 [ md:flex-row ]'}>
					<div className={'[ md:w-[61px] ]'}>
						<GovSkeleton
							width={'100%'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<div className={'[ md:w-[64px] ]'}>
						<GovSkeleton
							width={'100%'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
				</div>
			</LayoutGap>
		</ContentLayout>
	);
};
