import { GovIcon } from '@gov-design-system-ce/react';
import { FC } from 'react';
import { useConfiguration } from '@gov-nx/core/service';
import {
	DataBoxConnectDialogVariant,
	useDataBoxConnectDialog,
} from '@gov-nx/module/data-box';
import { Button, ButtonGroupLayout, LayoutGap, Modal } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';

const ContinueButton: FC<{ url: string; mainKey: string }> = ({
	url,
	mainKey,
}) => {
	const { ts } = useDataBoxConnectDialog();

	return (
		<Button
			enforceLink={true}
			href={url}
			variant={'primary'}
			wcagLabel={ts('datova-schranka.modal.' + mainKey + '.wcag.pokracovat')}
			type={'solid'}>
			<GovIcon
				name={'datovka'}
				slot={'left-icon'}
				type={'basic'}
			/>
			{ts('datova-schranka.modal.' + mainKey + '.pokracovat')}
		</Button>
	);
};

export const DataBoxConnectListener = () => {
	const {
		zalozeniDatoveSchrankyUrl: creationUrl,
		pripojeniDatoveSchrankyUrl: connectionUrl,
	} = useConfiguration();
	const { mainKey, ts, modalVariant, closeModal } = useDataBoxConnectDialog();

	const isOpen = modalVariant !== DataBoxConnectDialogVariant.Closed;

	return (
		<Modal
			open={isOpen}
			onGov-close={() => closeModal()}
			label={ts('datova-schranka.modal.' + mainKey + '.nadpis')}>
			<LayoutGap>
				<PoMarkdownRenderWeb>
					{ts('datova-schranka.modal.' + mainKey + '.popis')}
				</PoMarkdownRenderWeb>
				<ButtonGroupLayout>
					{modalVariant === DataBoxConnectDialogVariant.Connect && (
						<ContinueButton
							url={connectionUrl}
							mainKey="pripojeni"
						/>
					)}
					{modalVariant === DataBoxConnectDialogVariant.Create && (
						<ContinueButton
							url={creationUrl}
							mainKey="zalozeni"
						/>
					)}
					<Button
						variant={'primary'}
						wcagLabel={ts('datova-schranka.modal.' + mainKey + '.wcag.zrusit')}
						type={'base'}
						onClick={() => closeModal()}>
						{ts('datova-schranka.modal.' + mainKey + '.zrusit')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
		</Modal>
	);
};
