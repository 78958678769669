import {
	EPetitionAttachmentProvider,
	PageCode,
	useEPeticeDetailContext,
} from '@gov-nx/module/page';
import { DocumentDownloadContainer } from '@gov-nx/ui/web';
import { EPetitionAttachmentItem } from './EPetitionAttachmentItem';

export interface EPetitionAttachmentsProps {
	code: PageCode;
}

export const EPetitionAttachments = ({ code }: EPetitionAttachmentsProps) => {
	const { attachments, petition } = useEPeticeDetailContext();
	return (
		<DocumentDownloadContainer>
			{attachments?.map((attachment) => {
				return (
					<EPetitionAttachmentProvider
						code={code}
						key={attachment.id}>
						<EPetitionAttachmentItem
							code={code}
							attachment={attachment}
							petitionId={petition?.id ?? 0}
						/>
					</EPetitionAttachmentProvider>
				);
			})}
		</DocumentDownloadContainer>
	);
};
