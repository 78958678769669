import { GovDropdown, GovIcon } from '@gov-design-system-ce/react';
import { DatoveSchrankyPrilohaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageAttachmentContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, DocumentDownloadItem } from '@gov-nx/ui/web';

interface DataBoxAttachmentItemProps {
	attachment: DatoveSchrankyPrilohaDto;
}

export const DataBoxAttachmentItem = ({
	attachment,
}: DataBoxAttachmentItemProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		isDropdownOpen,
		attachmentOperations: {
			handleSaveAttachmentToDocuments,
			handleDownloadAttachment,
		},
		loadingItems,
	} = useDataBoxMessageAttachmentContext();

	return (
		<GovDropdown
			open={isDropdownOpen.value}
			position="right">
			<DocumentDownloadItem
				name={attachment.nazev}
				size={attachment.velikost}
				disabled={loadingItems.download || loadingItems.saveToDocuments}
				loading={loadingItems.download || loadingItems.saveToDocuments}
			/>
			<ul
				slot="list"
				className={'!p-0'}>
				<li>
					<Button
						variant="secondary"
						type="base"
						disabled={!!loadingItems.download}
						onClick={() => handleDownloadAttachment(attachment)}
						wcagLabel={tn('prilohy.stahnout')}>
						<span className="inline-flex items-center justify-between gap-x-3 w-full">
							{tn('prilohy.stahnout')}
							<GovIcon
								name="download"
								type="basic"
							/>
						</span>
					</Button>
				</li>
				<li>
					<Button
						variant="secondary"
						type="base"
						disabled={!!loadingItems.saveToDocuments}
						onClick={() => handleSaveAttachmentToDocuments(attachment)}
						wcagLabel={tn('prilohy.ulozit')}>
						<span className="inline-flex items-center justify-between gap-x-3 w-full">
							{tn('prilohy.ulozit')}
							<GovIcon
								name="save"
								type="basic"
							/>
						</span>
					</Button>
				</li>
			</ul>
		</GovDropdown>
	);
};
