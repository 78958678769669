import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useApplicantLoader } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { EPeticeFormDataStep1, PageCode } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormInput,
	LayoutGap,
} from '@gov-nx/ui/web';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';
import { FounderSelect } from './FounderSelect';

interface WizardStep1Props {
	code: PageCode;
	formDefinition: FormDefinition<EPeticeFormDataStep1>;
	isLoading: boolean;
	isDisabled: boolean;
	applicant: ReturnType<typeof useApplicantLoader>;
}

export const WizardStep1 = ({
	code,
	formDefinition,
	isDisabled,
	isLoading,
	applicant,
}: WizardStep1Props) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<LayoutGap gap={10}>
			<LayoutGap gap={4}>
				<h2 className={'mt-4 text-2xl'}>
					{tsn('formular.nadpis.nazev-epetice')}
				</h2>
				<FormInput
					label={{ children: tsn('formular.pole.nazev') }}
					field={{
						name: 'nazev',
						disabled: isDisabled,
						placeholder: tsn('formular.placeholder.nazev'),
					}}
				/>
			</LayoutGap>

			<LayoutGap gap={4}>
				<h2 className={'text-2xl'}>
					{tsn('formular.nadpis.udaje-zakladateli')}
				</h2>

				<MarkdownRender
					content={tsn('formular.popis.zakladni-udaje')}></MarkdownRender>
				<FounderSelect
					code={code}
					isDisabled={isDisabled}
					applicant={applicant}
					formDefinition={formDefinition}
				/>
			</LayoutGap>

			<LayoutGap gap={4}>
				<GovMessage variant="primary">
					<GovIcon
						type={'basic'}
						name={'lightbulb-fill'}
						slot={'icon'}></GovIcon>
					<div>
						<MarkdownRender
							content={tsn('formular.zprava.info-zakladatel-typ')}
						/>
					</div>
				</GovMessage>
			</LayoutGap>

			<ButtonGroupLayout>
				<Button
					loading={isLoading}
					wcagLabel={tsn('akce.wcag.pokracovat')}
					disabled={isDisabled}
					nativeType={'submit'}
					variant={'primary'}
					size={'l'}
					type={'solid'}>
					{tsn('formular.tlacitko.pokracovat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
