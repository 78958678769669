import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export interface SideBoxHeaderProps {
	children: React.ReactNode;
	link: string;
	customClasses?: string;
}

export const SideBoxHeader = ({
	children,
	link,
	customClasses,
}: SideBoxHeaderProps) => {
	return (
		<Link
			to={link}
			className={cx(
				'flex justify-start items-center gap-2 p-4 rounded-t bg-primary-600 !text-neutral-white text-xl font-medium [ hover:bg-primary-700 ] [ md:-mx-0 md:-mt-0 ]',
				customClasses
			)}>
			{children}
		</Link>
	);
};
