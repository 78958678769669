import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const SupportSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<>
			<ContentLayout>
				<LayoutGap>
					<div className={'flex gap-4 items-center'}>
						<GovSkeleton
							width={'40px'}
							height={'40px'}
							className={'py-2'}
							shape={'circle'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							width={'150px'}
							height={'28px'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<LayoutGap className={'[ md:ml-14 ]'}>
						<div>
							<GovSkeleton
								width={'100%'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={''}
							/>
							<GovSkeleton
								width={generateRandomNumber(20, 80) + '%'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<div className={'flex flex-col [ md:flex-row ]'}>
							<div className={'w-2/5'}>
								<GovSkeleton
									width={'60px'}
									height={'22px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'[ md:ml-3 ]'}
								/>
							</div>
							<GovSkeleton
								width={generateRandomNumber(50, 250) + 'px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<div className={'flex flex-col [ md:flex-row ]'}>
							<div className={'w-2/5'}>
								<GovSkeleton
									width={'75px'}
									height={'22px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'[ md:ml-3 ]'}
								/>
							</div>
							<GovSkeleton
								width={generateRandomNumber(50, 250) + 'px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<div className={'flex flex-col'}>
							<GovSkeleton
								width={'50px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<GovSkeleton
								width={'275px'}
								height={'40px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<div className={'flex flex-col'}>
							<GovSkeleton
								width={'60px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<GovSkeleton
								width={'275px'}
								height={'40px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<GovSkeleton
							width={'170px'}
							height={'50px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</LayoutGap>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout customClasses={'!pt-4 !pb-3'}>
				<div className={'flex gap-4 items-center'}>
					<GovSkeleton
						width={'40px'}
						height={'40px'}
						className={'py-2'}
						shape={'circle'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						width={'120px'}
						height={'28px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</ContentLayout>
			<ContentLayout customClasses={'!pt-4 !pb-3'}>
				<div className={'flex gap-4 items-center'}>
					<GovSkeleton
						width={'40px'}
						height={'40px'}
						className={'py-2'}
						shape={'circle'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						width={'100px'}
						height={'28px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</ContentLayout>
		</>
	);
};
