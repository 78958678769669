import { GovIcon } from '@gov-design-system-ce/react';
import { Button } from '@gov-nx/ui/web';
import { IUseBoolean } from '@gov-nx/utils/common';

interface ActionButtonsProps {
	isEditing: IUseBoolean;
	isChanging: IUseBoolean;
	isNeedForVerification: boolean;
	isConfirmationOpen: IUseBoolean;
	value?: string;
	strings: {
		buttons: {
			edit: {
				label: string;
				wcagLabel: string;
			};
			cancel: {
				label: string;
				wcagLabel: string;
			};
			delete: {
				wcagLabel: string;
			};
		};
	};
}

export const ActionButtons = ({
	isNeedForVerification,
	isEditing,
	isChanging,
	isConfirmationOpen,
	value,
	strings,
}: ActionButtonsProps) => {
	if (isNeedForVerification) {
		return null;
	}

	if (isChanging.value) {
		return (
			<Button
				onClick={isChanging.setFalse}
				wcagLabel={strings.buttons.cancel.wcagLabel}
				variant="primary"
				type="base"
				size="s">
				{strings.buttons.cancel.label}
			</Button>
		);
	}
	if (isEditing.value) {
		return (
			<Button
				onClick={isEditing.setFalse}
				wcagLabel={strings.buttons.cancel.wcagLabel}
				variant="primary"
				type="base"
				size="s">
				{strings.buttons.cancel.label}
			</Button>
		);
	}
	return (
		<div>
			<Button
				onClick={isEditing.setTrue}
				wcagLabel={strings.buttons.edit.wcagLabel}
				variant="primary"
				type="base"
				size="s">
				{strings.buttons.edit.label}
			</Button>
			{value && (
				<Button
					onClick={isConfirmationOpen.setTrue}
					wcagLabel={strings.buttons.delete.wcagLabel}
					title={strings.buttons.delete.wcagLabel}
					variant="error"
					type="base"
					size="s">
					<GovIcon name={'trash'} />
				</Button>
			)}
		</div>
	);
};
