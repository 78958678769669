import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import React from 'react';
import { DokladyDokladDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { DokladyType, PageCode, useDokladyContext } from '@gov-nx/module/page';
import {
	CopyButton,
	ErrorStatusBanner,
	InfoBanner,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { getTextColor, toDateReadable } from '@gov-nx/utils/common';
import { DocumentSkeleton } from './IdentityDocumentListSkeleton';

interface DocumentTileProps {
	code: PageCode;
	document: DokladyDokladDto;
}

const DocumentTile = ({ document, code }: DocumentTileProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const links: Record<string, string> = {
		ID: routeResolver(PageCode['obcansky-prukaz']),
		P: routeResolver(PageCode['cestovni-pas']),
		RP: routeResolver(PageCode['ridicsky-prukaz']),
	};
	const { classname } = getTextColor(document.platnostDo);

	return (
		<Tile
			icon={{ name: 'chevron-right', type: 'basic' }}
			to={document.druhDokladu ? links[document.druhDokladu] : '#'}
			name={tsn('typ.' + document.druhDokladu)}
			headlineLink={true}>
			<div
				className={
					'flex flex-col justify-start gap-2 [ sm:flex-row sm:items-center ]'
				}>
				<div className={'flex items-center gap-1'}>
					<span className={'inline-block mr-1'}>{tsn('cislo')}:</span>
					<strong className={`gov-color--secondary-900`}>
						{document.cisloDokladu}
					</strong>
					<CopyButton
						label={
							tsn('akce.kopirovat-cislo-dokladu') + ' ' + document.cisloDokladu
						}
						value={document.cisloDokladu as string}
					/>
				</div>
				{document.platnostOd ? (
					<div className={'flex items-center'}>
						<span className={'inline-block mr-1'}>{tsn('platnost-od')}:</span>
						<strong>{toDateReadable(document.platnostOd)}</strong>
					</div>
				) : null}
				{document.platnostDo ? (
					<div className={'flex items-center'}>
						<span className={'inline-block mr-1'}>{tsn('platnost-do')}:</span>
						<strong
							className={cx(
								document.druhDokladu === DokladyType.RP && classname
							)}>
							{toDateReadable(document.platnostDo)}
						</strong>
					</div>
				) : null}
			</div>
		</Tile>
	);
};

export interface IdentityDocumentListProps {
	code: PageCode;
}

export const IdentityDocumentList = ({ code }: IdentityDocumentListProps) => {
	const {
		documents,
		driversLicense,
		hasLicense,
		documentsError,
		isDocumentsLoading,
		driversLicenseError,
		isDriversLicenseLoading,
	} = useDokladyContext();

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const allDocuments = [...documents, driversLicense];
	if (allDocuments.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary"
				className={'!px-0 !py-4'}>
				<p className={'text-2xl'}>{tsn('data.error.zadny-zaznam')}</p>
				<p>{tsn('data.error.popis')}</p>
			</InfoBanner>
		);
	}

	return (
		<Tiles role={'list'}>
			{documentsError ? (
				<ErrorStatusBanner code={code} />
			) : isDocumentsLoading ? (
				<DocumentSkeleton />
			) : (
				documents.map((document) => (
					<DocumentTile
						key={document.cisloDokladu}
						document={document}
						code={code}
					/>
				))
			)}
			{driversLicenseError ? (
				<ErrorStatusBanner code={code} />
			) : isDriversLicenseLoading ? (
				<DocumentSkeleton />
			) : hasLicense ? (
				driversLicense && (
					<DocumentTile
						document={driversLicense}
						code={code}
					/>
				)
			) : null}
		</Tiles>
	);
};
