import { FileUploadProps } from '@gov-nx/core/hooks';

export const MAX_SUM_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_ATTACHMENTS = 50;

export const MAX_NORMAL_FILE_SIZE = 20 * 1024 * 1024;

export const DataBoxFileUploadParams: FileUploadProps = {
	extensions: [
		'.asic',
		'.cer',
		'.crt',
		'.csv',
		'.der',
		'.doc',
		'.docx',
		'.dbf',
		'.dgn',
		'.dwg',
		'.edi',
		'.fo',
		'.gif',
		'.gfs',
		'.gml',
		'.heic',
		'.heif',
		'.htm',
		'.html',
		'.isdoc',
		'.isdocx',
		'.jfif',
		'.jpeg',
		'.jpg',
		'.json',
		'.mp2',
		'.mp3',
		'.mp4',
		'.m4a',
		'.m4v',
		'.m4p',
		'.mpg',
		'.mpeg',
		'.odp',
		'.ods',
		'.odt',
		'.p7b',
		'.p7c',
		'.p7f',
		'.p7m',
		'.p7s',
		'.pdf',
		'.pk7',
		'.png',
		'.ppt',
		'.pptx',
		'.qix',
		'.rtf',
		'.sbn',
		'.sbx',
		'.shp',
		'.shx',
		'.tif',
		'.tiff',
		'.tsr',
		'.tst',
		'.txt',
		'.wav',
		'.xls',
		'.xlsx',
		'.xml',
		'.zfo',
		'.zip',
	],
	maxFileSize: MAX_FILE_SIZE,
	maxSumFileSize: MAX_SUM_FILE_SIZE,
	maxAttachments: MAX_ATTACHMENTS,
	multiple: true,
};
