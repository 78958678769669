import {
	GovIcon,
	GovMessage,
	GovPagination,
} from '@gov-design-system-ce/react';
import cx from 'classnames';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import {
	ContentLayout,
	ErrorStatusBanner,
	FilterParameterList,
	HeaderLayout,
	InfoBanner,
	LayoutGap,
	Modal,
	Space,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';
import { MyFilesUploadForm } from '../nahrat/MyFilesUploadForm';
import { Badges } from './Badges';
import { ConfirmPrompts } from './ConfirmPrompts';
import { DocumentsActions } from './DocumentsActions';
import { DocumentsTable } from './DocumentsTable';
import { MyFilesListSkeleton } from './MyFilesSkeleton';
import { UploadSection } from './UploadSection';
import { EmptyBinButton } from './kos/EmptyBinButton';
import { FilterModal } from './soubory/FilterModal';
import { Filters } from './soubory/Filters';

export const MyFilesListView = () => {
	const {
		myFilesModals: { setNoFileModal, noFileModal },
		formDefinition,
		onSubmit,
		documents,
		documentsBinned,
		isSelectedRecycleBin,
		isSelectedWithoutFile,
		myFilesSelection,
		code,
		controls,
		myFilesFilters,
		isDataFetching,
	} = useMojeSouborySeznamContext();

	const { t } = useTranslation([LocalizeNameSpaceTypes.Page]);

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (controls.processError) {
		return <ErrorStatusBanner code={code} />;
	}

	const total =
		(isSelectedRecycleBin ? documentsBinned?.pocet : documents?.pocet) ?? 0;
	const filteredTotal =
		(isSelectedRecycleBin
			? documentsBinned?.pocet
			: documents?.seznam?.length) ?? 0;

	return (
		<div>
			<MyFilesUploadForm />
			<UploadSection />
			<ConfirmPrompts />
			<FormProvider {...formDefinition.formMethods}>
				<PoFormWeb
					formDefinition={formDefinition}
					onSubmit={onSubmit}>
					<FilterModal />

					<ContentLayout customClasses={cx(isSelectedRecycleBin && '!pt-4')}>
						{!isSelectedRecycleBin && (
							<HeaderLayout
								filterParams={
									<FilterParameterList
										items={myFilesFilters.filterParams}
										onReset={myFilesFilters.resetFilter}
									/>
								}>
								<Filters />
							</HeaderLayout>
						)}

						{myFilesSelection.selectedIds.length > 0 ? (
							<LayoutGap gap={4}>
								<div
									className={cx(
										'flex justify-end h-[74px] py-3 px-8 -mx-8 bg-primary-200 [ md:justify-between md:items-center md:px-4 md:-mr-10 ]',
										isSelectedRecycleBin && '-mt-4'
									)}>
									<span
										className={
											'hidden font-bold text-secondary-700 [ md:block ]'
										}>
										{tsn('vyber.vybrano')}:<Space />
										{myFilesSelection.selectedIds.length}
									</span>
									<div className="flex gap-2 items-center">
										<DocumentsActions />
									</div>
								</div>
								{myFilesSelection.isItemWithNoFileInSelection &&
									!isSelectedWithoutFile && (
										<GovMessage variant={'primary'}>
											{tsn('info.vybrany-soubor-bez-prilohy')}
											<GovIcon
												type="basic"
												name="info"
												slot="icon"
											/>
										</GovMessage>
									)}
							</LayoutGap>
						) : (
							<div
								className={cx(
									isSelectedRecycleBin ? 'min-h-[58px]' : 'min-h-[74px] pt-4'
								)}>
								<Badges />
							</div>
						)}

						{isDataFetching ? (
							<MyFilesListSkeleton />
						) : (
							<div>
								{total === 0 ? (
									<InfoBanner
										icon={{ name: 'cactus', type: 'colored' }}
										type="secondary">
										<p className={'text-2xl [ md:text-3xl ]'}>
											{tsn('stav.chyba.zatim-tu-nic-nemame')}
										</p>
										<p>{tsn('stav.chyba.zda-se-ze-jste-tu-poprve')}</p>
									</InfoBanner>
								) : (
									<div>
										<DocumentsTable />
										<Modal
											label={noFileModal?.nazev}
											open={!!noFileModal}
											onGov-close={() => setNoFileModal(null)}>
											<div>
												<p>
													{noFileModal?.komentar ??
														tsn('data.komentar-neuveden')}
												</p>
											</div>
										</Modal>

										<footer className={'flex flex-col gap-4 pt-4 text-right'}>
											{filteredTotal < myFilesFilters.filter.pageSize &&
											myFilesFilters.filter.start === 0 ? null : (
												<GovPagination
													wcag-label={t('strankovani', {
														namespace: LocalizeNameSpaceTypes.Global,
													})}
													total={total}
													page-size={myFilesFilters.filter.pageSize}
													current={myFilesFilters.pageNumber}
													onGov-page={(event) =>
														myFilesFilters.goToPage(
															event.detail.pagination.currentPage
														)
													}
												/>
											)}
											{isSelectedRecycleBin && <EmptyBinButton />}
										</footer>
									</div>
								)}
							</div>
						)}
					</ContentLayout>
				</PoFormWeb>
			</FormProvider>
		</div>
	);
};
