import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useEPeticeEditContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	DataTable,
	FormFileUpload,
	FormInput,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
	PetitionAddresseeAutocomplete,
} from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';
import { WizardStep2ContactDetails } from './WizardStep2ContactDetails';
import { ElementIds } from './utils';

export const WizardStep2 = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const context = useEPeticeEditContext();
	const {
		applicant,
		onRemoveMember,
		formStep2,
		code,
		isProcessing,
		petitionAttachments,
		isPetitionMemberAddressLookupModalOpen,
		zakladatelTyp,
	} = context;
	const tsn = getLocalizeCurried(code);

	return (
		<LayoutGap gap={10}>
			<LayoutGap gap={4}>
				<h2
					className={'mt-4 text-2xl'}
					id={ElementIds.popis}>
					{tsn('formular.nadpis.popis-epetice')}
				</h2>

				<LayoutGap gap={8}>
					<FormInput
						label={{ children: tsn('formular.pole.perex') }}
						field={{
							multiline: true,
							rows: 4,
							name: 'perex',
							disabled: isProcessing,
							placeholder: tsn('formular.placeholder.perex'),
						}}
					/>
					<FormInput
						label={{ children: tsn('formular.pole.text') }}
						field={{
							multiline: true,
							rows: 4,
							name: 'text',
							disabled: isProcessing,
							placeholder: tsn('formular.placeholder.text'),
						}}
					/>
				</LayoutGap>
			</LayoutGap>

			<LayoutGap gap={4}>
				<h2
					className={'text-2xl'}
					id={ElementIds.priloha}>
					{tsn('formular.nadpis.priloha')}
				</h2>

				<FormFileUpload {...formStep2.fields.attachments} />
			</LayoutGap>

			{zakladatelTyp === 'PO' && (
				<div>
					<FormWidthElementLayout type="l">
						<FormSelect
							toValue={(ico: string) => parseInt(ico, 10)}
							field={{
								disabled:
									isProcessing || context.selectIcNumberOptions.length <= 1,
								name: 'zakladatelPoIco',
								options: context.selectIcNumberOptions,
							}}
							label={{ children: tsn('formular.pole.ico') }}
						/>
					</FormWidthElementLayout>

					{context.selectedIcNumber && (
						<LayoutGap
							gap={4}
							className={'mt-4'}>
							<PoMarkdownRenderWeb>
								{tsn('formular.popis.zakladatel-po')}
							</PoMarkdownRenderWeb>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{tsn('formular.zakladatel.adresa')}</td>
										<td>
											<strong>
												{
													applicant.businessPerson?.seznam?.find(
														(business) =>
															business.ico === context.selectedIcNumber
													)?.sidloSpolecnosti?.adresaText
												}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{tsn('formular.zakladatel.datova-schranka')}</td>
										<td>
											<strong>
												{
													applicant.businessPerson?.seznam?.find(
														(business) =>
															business.ico === context.selectedIcNumber
													)?.datovaSchrankaId
												}
											</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
						</LayoutGap>
					)}
				</div>
			)}

			{formStep2 ? <WizardStep2ContactDetails /> : null}

			<LayoutGap gap={4}>
				<h2
					className={'text-2xl'}
					id={ElementIds.peticniVybor}>
					{tsn('formular.nadpis.peticni-vybor')}
				</h2>
				<p>{tsn('formular.popisek.peticni-vybor')}</p>
				{zakladatelTyp === 'FO' ? (
					<div>
						{!formStep2?.formDefinition.formMethods.watch(
							'clenovePeticnihoVyboru'
						)?.length ? (
							''
						) : (
							<ol className={'mb-4'}>
								{formStep2?.formDefinition.formMethods
									.watch('clenovePeticnihoVyboru')
									?.map((member, index) => {
										return (
											<li
												key={[
													member.id,
													member.jmeno,
													member.prijmeni,
													member.adresa?.adresaText,
												].join('-')}>
												{member.jmeno} {member.prijmeni},{' '}
												{member.adresa?.adresaText}
												<Button
													onClick={() => onRemoveMember(index)}
													size={'xs'}
													variant="primary"
													type="base"
													slot="footer"
													disabled={isProcessing}
													wcagLabel={tsn('akce.wcag.odebrat-clena')}
													className={'ml-1'}>
													<GovIcon
														name={'x-lg'}
														type={'basic'}
														slot="left-icon"></GovIcon>
												</Button>
											</li>
										);
									})}
							</ol>
						)}
						<Button
							onClick={isPetitionMemberAddressLookupModalOpen.setTrue}
							loading={isProcessing}
							wcagLabel={tsn('akce.wcag.pridat-clena')}
							disabled={isProcessing}
							variant={'primary'}
							type={'outlined'}
							size="s">
							<GovIcon
								name={'plus'}
								type={'basic'}
								slot="left-icon"></GovIcon>
							{tsn('formular.tlacitko.pridat-clena')}
						</Button>
					</div>
				) : (
					<GovMessage variant={'warning'}>
						<GovIcon
							type={'basic'}
							name={'info'}
							slot={'icon'}></GovIcon>
						<div>
							<PoMarkdownRenderWeb>
								{tsn('formular.zprava.peticni-vybor-varovani-po')}
							</PoMarkdownRenderWeb>
						</div>
					</GovMessage>
				)}
			</LayoutGap>

			<LayoutGap gap={4}>
				<h2
					className={'text-2xl'}
					id={ElementIds.adresat}>
					{tsn('formular.nadpis.adresat')}
				</h2>
				<PoMarkdownRenderWeb>
					{tsn('formular.poznamka.adresat')}
				</PoMarkdownRenderWeb>
				<FormWidthElementLayout type="l">
					<PetitionAddresseeAutocomplete
						field={{
							name: 'adresatOvm',
							disabled: isProcessing,
							placeholder: tsn('formular.placeholder.adresat'),
						}}
						label={{
							children: tsn('formular.pole.adresat'),
						}}
					/>
				</FormWidthElementLayout>
			</LayoutGap>

			<ButtonGroupLayout>
				<Button
					loading={isProcessing}
					wcagLabel={tsn('akce.wcag.pokracovat')}
					disabled={isProcessing || petitionAttachments?.isUploading}
					nativeType={'submit'}
					variant={'primary'}
					size={'l'}
					type={'solid'}>
					{tsn('formular.tlacitko.pokracovat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
