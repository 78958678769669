import { useState } from 'react';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents } from '@gov-nx/core/events';

export const useDownloadBlob = () => {
	const [state, setState] = useState<{
		isDownloading: boolean;
		error?: GovError;
	}>({
		isDownloading: false,
		error: undefined,
	});

	const { downloadBlob } = useDownloadEvents();

	return {
		handleDownloadBlob: <T>(getData: () => T) => {
			return {
				download: (
					onSuccess?: () => void,
					onError?: (error: GovError) => void
				) => {
					setState({ isDownloading: true, error: undefined });
					downloadBlob({
						data: getData(),
						onSuccess: () => {
							setState({ isDownloading: false, error: undefined });
							if (onSuccess) {
								onSuccess();
							}
						},
						onError: (error) => {
							setState({ isDownloading: false, error });
							if (onError) {
								onError(error);
							}
						},
					});
				},
				isDownloading: state.isDownloading,
				error: state.error,
			};
		},
	};
};
