import {
	DokladyDokladDto,
	DokumentyDokumentDto,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { useDocumentPreview } from './useDocumentPreview';

export enum DokladyType {
	/** Obcansky prukaz */
	ID = 'ID',
	/** Pas */
	P = 'P',
	/** Ridicsky prukaz */
	RP = 'RP',
}

export interface IdentityDocumentControls extends ProcessControlCore {
	displayComplaintModal: DokladyDokladDto;
}

export interface IdentityDocumentsContext {
	documents: DokladyDokladDto[];
	isDocumentsLoading: boolean;
	documentsError?: GovError;

	driversLicense?: DokladyDokladDto;
	canChangeLicence: boolean;
	zadostNovyRidicskyPrukazUrl: Nullable<string>;
	hasLicense: boolean;
	isDriversLicenseLoading: boolean;
	driversLicenseError?: GovError;

	ownDocuments: DokumentyDokumentDto[];
	ownDocumentsError?: GovError;

	controls: IdentityDocumentControls;
	setControls: (control: Partial<IdentityDocumentControls>) => void;
	getDocumentByType: (type: DokladyType) => DokladyDokladDto[];
	onOwnDocumentDelete: ({
		id,
		permanent,
	}: {
		id: number;
		permanent: boolean;
	}) => void;
	documentPreviewQuery: ReturnType<typeof useDocumentPreview>;
}
