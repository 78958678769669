import React from 'react';
import { MarkdownRender } from '@gov-nx/component/web';
import { useDataBoxSelect } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useLocale,
	usePoFormContext,
} from '@gov-nx/core/service';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import {
	DataBoxOnlyOptionProps,
	WebDataBoxDetailsProps,
	WebDataBoxSelectProps,
} from '@gov-nx/ui/types';
import { ToggleButton } from '../../Button/ToggleButton';
import { FormWidthElementLayout } from '../../Layout/FormWidthElementLayout';
import { LayoutGap } from '../../Layout/LayoutGap';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';

const DataBoxDetails = (props: WebDataBoxDetailsProps) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	return (
		<ToggleButton
			id={props.toggleButtonID}
			wcagHideLabel={t('data-box.wcag.skryt-doplnkove-udaje')}
			wcagShowLabel={t('data-box.wcag.zobrazit-doplnkove-udaje')}
			showLabel={t('data-box.labels.zobrazit-doplnkove-udaje')}
			hideLabel={t('data-box.labels.skryt-doplnkove-udaje')}>
			<LayoutGap gap={4}>
				<MarkdownRender
					content={
						props.titleCopy ?? t('data-box.messages.doplnujici-udaje-obsag')
					}
				/>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.fileNumberField?.name ?? 'naseCisloJednaci',
							placeholder:
								props.fileNumberField?.labelCopy ??
								t('data-box.placeholders.cislo-jednaci') ??
								undefined,
							disabled: props.fileNumberField?.disabled || props.disabled,
						}}
						label={{
							children:
								props.fileNumberField?.placeholderCopy ??
								t('data-box.fields.cislo-jednaci'),
						}}
					/>
				</FormWidthElementLayout>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.referenceNumberField?.name ?? 'naseSpisovaZnacka',
							placeholder:
								props.referenceNumberField?.placeholderCopy ??
								t('data-box.placeholders.spisova-znacka'),
							disabled: props.referenceNumberField?.disabled || props.disabled,
						}}
						label={{
							children:
								props.referenceNumberField?.labelCopy ??
								t('data-box.fields.spisova-znacka'),
						}}
					/>
				</FormWidthElementLayout>
			</LayoutGap>
		</ToggleButton>
	);
};

const DataBoxOnlyOption = ({
	field,
	label,
	dataBox,
}: DataBoxOnlyOptionProps) => {
	return (
		<div className={'flex flex-col gap-2 mb-1'}>
			<span className={'gov-color--secondary-700'}>
				{label.children}
				{field.isRequired ? (
					<span className="gov-color--error-500"> *</span>
				) : null}
			</span>
			<span className={'gov-color--secondary-800 font-semibold'}>
				{dataBoxLabel(dataBox)}
			</span>
		</div>
	);
};

export const DataBoxSelect = ({ types, ...props }: WebDataBoxSelectProps) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);
	const { required } = usePoFormContext().propsFromSchema(props.field.name);

	const { dataBoxesOptions, dataBoxes } = useDataBoxSelect({
		types,
		field: props.field,
	});

	return (
		<fieldset className={'flex flex-col gap-2'}>
			<legend className={'sr-only'}>
				{t('data-box.wcag.vyber-datove-schranky')}
			</legend>
			{dataBoxes.length === 1 ? (
				<DataBoxOnlyOption
					field={{ ...props.field, isRequired: required }}
					label={props.label}
					dataBox={dataBoxes[0]}
				/>
			) : (
				<FormWidthElementLayout type={'xl'}>
					<FormSelect
						{...props}
						field={{ ...props.field, options: dataBoxesOptions }}
					/>
				</FormWidthElementLayout>
			)}
			{!props.isDetailDisabled && (
				<DataBoxDetails
					{...props.details}
					disabled={props.field.disabled}
				/>
			)}
		</fieldset>
	);
};
