import { ProcessControlCore } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';

export enum CadastreOfRealEstateDataSource {
	KATASTR_NEMOVITOSTI_PRAVO = 'KATASTR_NEMOVITOSTI_PRAVO',
	KATASTR_NEMOVITOSTI_ODKAZ = 'KATASTR_NEMOVITOSTI_ODKAZ',
}

export interface CadastreOfEstateFilter {
	text: Optional<string>;
}

export interface CadastreOfRealEstateFilterForm {
	text: CadastreOfEstateFilter['text'];
}

export interface CadastreOfRealEstatePreviewsParams {
	zdrojUdaju: CadastreOfRealEstateDataSource;
	format: 'json' | 'xml';
	text: CadastreOfRealEstateFilterForm['text'];
}

export interface CadastreOfRealEstatePreviewsDetailsParams {
	zdrojUdaju: CadastreOfRealEstateDataSource;
	typ: 'lv';
	id: string;
}

export interface RealEstateLVRaw {
	telId?: string;
	katastr?: string;
	obec?: string;
	lv?: string;
	podil?: {
		citatel?: string;
		jmenovatel?: string;
	};
}

export interface RealEstateLV extends RealEstateLVRaw {
	link?: string;
}

export interface CadastreOfRealEstateContext {
	realEstates?: RealEstateLV[];
	controls: ProcessControlCore;
	formDefinition: FormDefinition<CadastreOfRealEstateFilterForm>;
	queryLoading: boolean;
	noRealEstates: boolean;
	onSubmit: () => void;
}

export type INode<T> = T | T[] | undefined | null | string | number;

export interface DataResponse {
	Data?: [
		{
			r: INode<{
				kNOdpoved: INode<{
					kontextData: INode<{
						generujSestavuResponse: INode<{
							reportList: INode<{
								report: INode<{
									souborSestavy: INode<{
										evidencePravProOsobu: INode<{
											uplnaShoda: INode<{
												vlastnictvi: INode<{
													opravSubjekt: INode<{
														charos: INode<{
															charOs: INode<{
																kod: INode<{
																	xVal: string;
																}>;
															}>;
														}>;
														pravVztahy: INode<{
															typrav: INode<{
																typravNazev: INode<{
																	xVal: string;
																}>;
																okresy: INode<{
																	okres: INode<{
																		obce: INode<{
																			obec: INode<{
																				obec: INode<{
																					nazev: INode<{
																						xVal: string;
																					}>;
																				}>;
																				katastry: INode<{
																					katastr: INode<{
																						katuze: INode<{
																							nazev: INode<{
																								xVal: string;
																							}>;
																						}>;
																						telesa: INode<{
																							teleso: INode<{
																								lv: {
																									xVal: string;
																								};
																								telId: {
																									xVal: string;
																								};
																								podil: INode<{
																									citatel: INode<{
																										xVal: string;
																									}>;
																									jmenovatel: INode<{
																										xVal: string;
																									}>;
																								}>;
																							}>;
																						}>;
																					}>;
																				}>;
																			}>;
																		}>;
																	}>;
																}>;
															}>;
														}>;
													}>;
												}>;
											}>;
										}>;
									}>;
								}>;
							}>;
						}>;
					}>;
				}>;
			}>;
		},
		{
			egsb: {
				xAttStatus: string;
				xAttGuuid: string;
			};
		}
	];
}

export interface DataDetail {
	Data: Array<{
		po: {
			nemovitost: {
				xAttId: string;
				xAttTyp: string;
				url: {
					xAttPlatnost: string;
					xVal: string;
				};
			};
		};
	}>;
}
