import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useLocale } from '@gov-nx/core/service';
import { arrayEquals, pipe } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxConnectBannerProps } from '@gov-nx/ui/types';

const isFO = (types: DatovkaApiType['typSchranky'][]) =>
	arrayEquals(types, ['FO']);

type DataBoxAccessHookProps = DataBoxConnectBannerProps;

export const useDataBoxConnectBanner = ({
	requiredTypes,
	translationPrefix,
}: DataBoxAccessHookProps) => {
	const { t } = useLocale(PageCode['datove-schranky']);
	const events = useDataBoxEvents();
	const {
		possibleToConnectOrCreate,
		possibleToConnect,
		listToCreateTypes,
		connectedTypes,
	} = useDataBoxAccess({ requiredTypes });
	const keyPostfix = isFO(requiredTypes) ? '-FO' : '';

	const locale = (...chunks: string[]): string =>
		pipe(
			translationPrefix || 'banner-service',
			(prefix) => [prefix, ...chunks],
			(bits) => bits.join('.'),
			t
		);

	const strings = {
		...(possibleToConnect
			? {
					title: locale('pripojeni', `nadpis${keyPostfix}`),
					content: locale('pripojeni', 'popis'),
			  }
			: {
					title: locale('zalozeni', `nadpis${keyPostfix}`),
					content: locale('zalozeni', 'popis'),
			  }),
		buttons: {
			connect: {
				content: locale('pripojeni', 'cta'),
				wcag: locale('pripojeni', 'wcag', 'cta'),
			},
			create: {
				content: locale('zalozeni', 'cta'),
				wcag: locale('zalozeni', 'wcag', 'cta'),
			},
			createFO: {
				content: locale('zalozeni', 'cta-fo'),
				wcag: locale('zalozeni', 'wcag', 'cta-fo'),
			},
		},
	};

	return {
		possibleToConnectOrCreate,
		showConnectButton: possibleToConnect,
		showCreateButton: !possibleToConnect,
		showCreateFOButton:
			listToCreateTypes.includes('FO') && connectedTypes.length > 0,
		strings,
		events,
	};
};
