import cx from 'classnames';
import React from 'react';

export interface ButtonGroupLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const ButtonGroupLayout = ({
	children,
	customClasses,
}: ButtonGroupLayoutProps) => {
	return (
		<div
			className={cx(
				'button-group flex flex-col gap-4 [ md:flex-row ]',
				customClasses
			)}>
			{children}
		</div>
	);
};
