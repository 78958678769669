import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdentity } from '@gov-nx/auth/common';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	usePersonCommunicationLoader,
	useSignposts,
	useUserSettingsLoader,
} from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Time } from '@gov-nx/core/types';
import {
	useDataBoxLoader,
	useDataBoxUnreadMessages,
} from '@gov-nx/module/data-box';
import { isDifferentTimeZone } from '@gov-nx/utils/common';

export interface SyncProviderProps {
	children: React.ReactNode;
}

export function SyncProvider({ children }: SyncProviderProps) {
	const [processing, setProcessing] = useState(true);

	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const { toastMessage } = useMessageEvents();

	const { isAuthenticated } = useIdentity();
	const { refetchSignposts } = useSignposts();

	const { initDataBoxes } = useDataBoxLoader();
	const { initDataBoxUnreadMessages, refetchDataBoxUnreadMessages } =
		useDataBoxUnreadMessages();
	const { initPersonCommunication } = usePersonCommunicationLoader();
	const { initUserSettings } = useUserSettingsLoader();

	const init = useCallback(
		async () =>
			await Promise.all([
				initDataBoxes(),
				initPersonCommunication(),
				initUserSettings(),
				refetchSignposts(),
			]),
		[initDataBoxes, initPersonCommunication, initUserSettings, refetchSignposts]
	);

	useEffect(() => {
		if (isAuthenticated) {
			init().finally(() => {
				setProcessing(false);
				initDataBoxUnreadMessages().catch();
				isDifferentTimeZone() &&
					toastMessage({
						content: t('zprava.informace-casova-zona'),
						options: {
							variant: 'primary',
							time: Time['1 minute'],
						},
					});
			});
		} else {
			setProcessing(false);
		}

		// Note: Updating deps causing infinite loop here !
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated) {
			const refetchInterval = setInterval(() => {
				refetchDataBoxUnreadMessages();
			}, Time['30 seconds']);

			return () => clearInterval(refetchInterval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	if (processing) {
		return null;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
