import * as yup from 'yup';
import {
	DokladyDokladDto,
	OsobyFyzickaOsobaDto,
} from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PersonalDocumentType } from '@gov-nx/core/types';
import {
	toDateReadable,
	nameFormatting,
	useAddressSearchShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../../definitions/codes';
import {
	FormData,
	DataRequest,
	ZadostOPoskytnutiUdajuComplaintProcessControl,
} from './context.types';
import { toPersonalDocumentType } from './utils';

export interface FormInstanceProps {
	code: ServiceCode;
	individualPerson?: OsobyFyzickaOsobaDto;
	mainDocument?: DokladyDokladDto;
	controls: ZadostOPoskytnutiUdajuComplaintProcessControl;
}

export function FormInstance({
	code,
	individualPerson,
	mainDocument,
	controls,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getAddressSearchDefaultValues, getAddressSearchShape } =
		useAddressSearchShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			jmeno: yup.string().optional(),
			prijmeni: yup.string().optional(),
			datumNarozeni: yup.string().optional(),
			druhDokladu: yup
				.string()
				.oneOf(
					[PersonalDocumentType.IDCard, PersonalDocumentType.passport],
					tsn('formular.validace.druh-dokladu')
				)
				.optional(),
			cisloDokladu: yup
				.string()
				.optional()
				.min(8, tsn('formular.validace.cislo-dokladu-min'))
				.max(9, tsn('formular.validace.cislo-dokladu-max')),
			adresaPobytu: yup.string().optional(),
			popisReklamace: yup
				.string()
				.required(tsn('formular.validace.popis-reklamace.povinny')),
			...getAddressSearchShape({
				controls: controls.displayAddressForm,
				nazevObce: {
					requiredMessage: tsn('formular.validace.popis-reklamace.povinny'),
				},
				cislo: {
					requiredMessage: tsn('formular.validace.popis-reklamace.povinny'),
					max9999Message: tsn('formular.validace.max-9999'),
					onlyNumbersMessage: tsn('formular.validace.pouze-cislice'),
				},
			}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			jmeno: nameFormatting(individualPerson?.jmeno),
			prijmeni: nameFormatting(individualPerson?.prijmeni),
			datumNarozeni:
				individualPerson && individualPerson.datumNarozeni
					? toDateReadable(individualPerson.datumNarozeni) || ''
					: '',
			druhDokladu: toPersonalDocumentType(mainDocument?.druhDokladu),
			cisloDokladu: mainDocument?.cisloDokladu || '',
			adresaPobytu: individualPerson?.adresaPobytu?.adresaText,
			...getAddressSearchDefaultValues(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const preparePersonSubmitData =
	(document?: DokladyDokladDto, newAddress?: string) =>
	(data: FormData): DataRequest => {
		const seznam = [];

		if (data.jmeno) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Jmeno',
				zpusobNaplneni: data.jmeno,
			});
		}

		if (data.prijmeni) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Prijmeni',
				zpusobNaplneni: data.prijmeni,
			});
		}
		if (data.druhDokladu || data.cisloDokladu) {
			seznam.push({
				kodUdaje: 'Doklad',
				puvodni: document?.cisloDokladu,
				puvodniDruh: toPersonalDocumentType(document?.druhDokladu),
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.cisloDokladu,
				zpusobNaplneniDruh: data.druhDokladu,
			});
		}

		if (newAddress) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'AdresaPobytu',
				zpusobNaplneni: newAddress,
			});
		}

		return {
			params: {},
			body: {
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
