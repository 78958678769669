import {
	axiosInstance,
	EdokladyInstanceAplikaceSeznamDto,
} from '@gov-nx/api/portal-obcana';

export const eDocumentsQuery = async <Params>(
	params?: Params
): Promise<EdokladyInstanceAplikaceSeznamDto> => {
	const { data } = await axiosInstance.get('/api/edoklady/v1/instance', {
		params,
	});
	return data;
};

export const deleteEDocumentQuery = async (
	instanceId: string
): Promise<void> => {
	const { data } = await axiosInstance.delete(
		`/api/edoklady/v1/instance/${instanceId}`
	);
	return data;
};
