import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useState,
} from 'react';
import { cmsPageQuery } from '@gov-nx/api/common';
import { usePoQuery } from '@gov-nx/api/portal-obcana';
import { govLog, useAppContext } from '@gov-nx/core/app';
import { useAppLanguage } from '@gov-nx/core/hooks';
import {
	getI18n,
	i18nLoadNamespaces,
	useCmsPage,
	useConfiguration,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { useCmsStore } from '@gov-nx/store/portal-obcana';

export interface CmsPageProviderCoreProps {
	children: React.ReactNode;
	code: PageCode;
	onError?: () => void;
	onNotFound?: () => void;
	skeleton: React.ReactNode;
}

export const CmsPageProviderCore = forwardRef(
	(
		{
			children,
			code,
			onError,
			onNotFound,
			skeleton = null,
		}: CmsPageProviderCoreProps,
		ref
	) => {
		const { saveCmsPage } = useCmsStore();
		const [translationLoaded, setTranslationLoaded] = useState(
			getI18n().hasLoadedNamespace(code)
		);
		const page = useCmsPage({ code });
		const { setCurrentCode } = useAppContext();
		const { cmsKeyCache } = useConfiguration();
		const { language } = useAppLanguage();

		const pageQuery = usePoQuery({
			queryKey: ['cms-page', code, cmsKeyCache, language],
			queryFn: () => cmsPageQuery({ cmsKeyCache, code, locale: language }),
			onSuccess: (data) => saveCmsPage(code, data),
			retry: 0,
			cacheTime: 0,
			refetchOnWindowFocus: false,
		});

		const init = useCallback(async () => {
			await Promise.all([i18nLoadNamespaces([code]), pageQuery.refetch()]);
			setTranslationLoaded(getI18n().hasLoadedNamespace(code));
		}, [code, pageQuery]);

		useImperativeHandle(ref, () => ({
			init() {
				setCurrentCode(code, null);
				init().finally(() => {
					govLog('Page loaded [' + code + ']');
				});
			},
		}));

		if (page && translationLoaded) {
			return <>{children}</>;
		}
		return <>{skeleton}</>;
	}
);
