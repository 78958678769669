import { GovIcon } from '@gov-design-system-ce/react';
import { useContext } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { dataBoxLabel, getDataBoxTypeLabel } from '@gov-nx/module/data-box';
import { DalsiNastaveniDatovkyContext, PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

export const DataboxDisconnectModal = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	const context = useContext(DalsiNastaveniDatovkyContext);
	if (!context) {
		return null;
	}

	const {
		databoxId,
		databox,
		isDisconnectConfirmationOpen,
		closeDisconnectConfirmation,
		doDisconnect,
	} = context;

	const databoxLabel = databox
		? `${dataBoxLabel(databox, true)} – ${getDataBoxTypeLabel(
				databox.typSchranky,
				tds
		  )} (${databoxId})`
		: '';

	const promptQuestionTranslation = tds(
		'odpojeni.opravdu-chcete-datovou-schranku-x-odpojit-z-portalu'
	);
	const promptQuestionParts = promptQuestionTranslation.split('{{label}}');
	const promptQuestion =
		promptQuestionParts.length === 2 ? (
			<>
				<span>{promptQuestionParts[0]}</span> <strong>{databoxLabel}</strong>{' '}
				<span>{promptQuestionParts[1]}</span>
			</>
		) : (
			promptQuestionTranslation
		);

	return (
		<Prompt
			label={tds('odpojeni.odpojeni-datovky-z-portalu-obcana')}
			open={isDisconnectConfirmationOpen}
			onGov-close={closeDisconnectConfirmation}>
			<GovIcon
				name="exclamation-triangle-fill"
				slot="icon"
				className={'text-error-400'}
			/>
			<p className={'mb-6 text-l'}>{promptQuestion}</p>
			<p className={'mb-6 text-secondary-700'}>
				<strong>
					{tds(
						'odpojeni.kopie-zprav-v-archivu-budete-mit-stale-v-portalu-obcana-dostupne'
					)}
				</strong>
			</p>
			<p className={'text-secondary-700'}>
				{tds(
					'odpojeni.datova-schranka-bude-nadale-pristupna-v-portalu-datovych-schranek'
				)}
			</p>
			<Button
				nativeType="button"
				variant="primary"
				type="base"
				slot="actions"
				onClick={closeDisconnectConfirmation}
				wcagLabel={tds('odpojeni.neodpojovat')}>
				{tds('odpojeni.neodpojovat')}
			</Button>
			<Button
				nativeType="button"
				variant="error"
				type="solid"
				slot="actions"
				onClick={doDisconnect}
				wcagLabel={tds('odpojeni.odpojit-datovku')}>
				{tds('odpojeni.odpojit-datovku')}
			</Button>
		</Prompt>
	);
};
