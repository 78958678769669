import { GovTooltip } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

interface UseButtonProps {
	isDisabled: boolean;
	isLoading: boolean;
	strings: {
		label: string;
		wcagLabel: string;
	};
	onClick: () => void;
}

const UseButton = ({
	isDisabled,
	isLoading,
	strings,
	onClick,
}: UseButtonProps) => {
	return (
		<Button
			onClick={onClick}
			disabled={isDisabled}
			loading={isLoading}
			variant="primary"
			type="outlined"
			size="s"
			wcagLabel={strings.wcagLabel}>
			{strings.label}
		</Button>
	);
};

interface UsePersonalContactsButtonProps {
	code: PageCode;
	canUsePersonalContacts: boolean;
	isLoading: boolean;
	handleUsePersonalContacts: () => void;
}

export const UsePersonalContactsButton = ({
	canUsePersonalContacts,
	code,
	isLoading,
	handleUsePersonalContacts,
}: UsePersonalContactsButtonProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	const strings = {
		label: ts('kontakt.action.pouzit-udaje-fyzicke-osoby'),
		wcagLabel: ts('kontakt.wcag.pouzit-udaje-fyzicke-osoby'),
	};

	if (canUsePersonalContacts) {
		return (
			<UseButton
				isDisabled={isLoading}
				isLoading={isLoading}
				strings={strings}
				onClick={handleUsePersonalContacts}
			/>
		);
	}

	return (
		<GovTooltip
			message={ts('kontakt.nejprve-overte')}
			className={'border-b-0'}>
			<UseButton
				isDisabled={!canUsePersonalContacts}
				isLoading={false}
				strings={strings}
				onClick={handleUsePersonalContacts}
			/>
		</GovTooltip>
	);
};
