import { GovIcon } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout } from '@gov-nx/ui/web';

export const EmptyBinButton = () => {
	const { code, emptyRecycleBin } = useMojeSouborySeznamContext();
	const { t } = useLocale(code);

	return (
		<ButtonGroupLayout customClasses={'self-end'}>
			<Button
				wcagLabel={t('akce.vysypat-kos')}
				variant="error"
				type="solid"
				onClick={emptyRecycleBin.emptyBinModal.setTrue}>
				<GovIcon
					name="trash"
					slot="left-icon"
				/>
				{t('akce.vysypat-kos')}
			</Button>
		</ButtonGroupLayout>
	);
};
