import { useQuery } from '@tanstack/react-query';
import { useAppLanguageSelector } from '@gov-nx/core/hooks';
import { useConfiguration } from '@gov-nx/core/service';
import { axiosCmsInstance } from '../axios/instance';
import { CmsMessage, CmsMessageResponse } from './types';

export const useCmsMessagesQuery = ({
	onError,
	onSuccess,
}: {
	onError?: (errorMessage: string) => void;
	onSuccess?: (data: CmsMessage[]) => void;
}) => {
	const { cmsKeyCache } = useConfiguration();
	const { language } = useAppLanguageSelector();
	return useQuery({
		onError,
		onSuccess,
		queryFn: async () => {
			const response = await axiosCmsInstance.get<CmsMessageResponse>(
				`/messages/${language}.json?v=${cmsKeyCache}`
			);
			return response.data.data;
		},
		retry: 0,
		refetchOnWindowFocus: false,
		queryKey: ['cms-messages', language, cmsKeyCache],
	});
};
