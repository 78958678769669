import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

type Props = {
	open: boolean;
	onCancel?: () => void;
	onConfirm?: () => void;
};

export const CancelPropmpt = ({ open, onCancel, onConfirm }: Props) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	return (
		<Prompt
			label={tn('nova-zprava.opravdu-chcete-prestat-psat')}
			onGov-close={onCancel}
			open={open}>
			<GovIcon
				name="exclamation-triangle-fill"
				slot="icon"
				className="text-error-400"
			/>
			<p>{tn('nova-zprava.zprava-se-zahodi')}</p>
			<Button
				variant={'primary'}
				nativeType={'button'}
				type={'base'}
				onClick={onCancel}
				slot={'actions'}
				wcagLabel={tn('nova-zprava.pokracovat')}>
				{tn('nova-zprava.pokracovat')}
			</Button>

			<Button
				variant={'error'}
				nativeType={'button'}
				onClick={onConfirm}
				type={'solid'}
				slot={'actions'}
				wcagLabel={tn('nova-zprava.prestat-psat')}>
				{tn('nova-zprava.prestat-psat')}
			</Button>
		</Prompt>
	);
};
