import React from 'react';
import { usePetitionAddresseeAutocomplete } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	WebFormAutocompleteProps,
	petitionAddresseeTemplateResolver,
} from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

export const PetitionAddresseeAutocomplete = (
	props: WebFormAutocompleteProps
) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { isFetching, result, setText } = usePetitionAddresseeAutocomplete();

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			onSelect={() => setText(undefined)}
			field={{
				...props.field,
				options: result?.seznam,
				processing: isFetching,
				messageEmpty:
					props.field.messageEmpty ?? tn('naseptavac.pole.zadny-vysledek'),
				messageLoading:
					props.field.messageLoading ?? tn('naseptavac.pole.nacitam'),
				rightIcon: {
					name: 'search',
					type: 'basic',
				},
				templateResolver: petitionAddresseeTemplateResolver,
				nameKey: 'datovaSchrankaId',
			}}
		/>
	);
};
