import { useQueryClient } from '@tanstack/react-query';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	DatovkyApiSeznam,
	searchDataBoxesQuery,
	sendSubmissionQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	WizardFormStep,
	useIdentityDocumentLoad,
	usePoIndividualDataLoad,
	useProcessControl,
	useTreeFormCheckboxes,
	useWizard,
} from '@gov-nx/core/hooks';
import { getRequiredDataBoxes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import {
	FormInstanceStep2,
	FormInstanceStep3,
	FormInstanceStep4,
	prepareSubmitData,
	scopeWithLabels,
	useFormTree,
} from './FormDefinitions';
import {
	FormData,
	FormDataStep2,
	FormDataStep3,
	ServiceContextTypes,
	ServiceControls,
} from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const { setControls, controls } = useProcessControl<ServiceControls>({
		displayComplaintModal: false,
		isDownloadLoading: false,
		downloadError: null,
	});
	const { downloadBlob } = useDownloadEvents();
	const { individualPerson } = usePoIndividualDataLoad({
		onError: (initialError) => setControls({ initialError }),
	});
	const { getMainDocument, documents } = useIdentityDocumentLoad({
		onError: (initialError) => setControls({ initialError }),
	});

	const mainDocument = getMainDocument();
	useEffect(() => {
		if (individualPerson && documents && controls.initialLoading) {
			setControls({ initialLoading: false });
		}
	}, [individualPerson, documents]);

	const queryClient = useQueryClient();

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(
				mainDocument,
				lookUpQuery.data?.seznam?.[0]
			)(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			wizard.resetForms();
			treeCheckboxes.reset();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			await queryClient.invalidateQueries({ queryKey: ['souhlasy'] });
			wizard.resetForms();
			treeCheckboxes.reset();
		},
	});

	const onSubmit = useCallback(
		async (values: FormData) => {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[setControls, submitMutation]
	);

	const wizard = useWizard({
		steps: [
			{},
			FormInstanceStep2({ code }),
			FormInstanceStep3({ code }),
			FormInstanceStep4({ code }),
		],
		onSubmit,
	});

	const step2 = wizard.step(1) as unknown as WizardFormStep<FormDataStep2>;
	const personType = step2.formDefinition.formMethods.watch('typOsoby');
	useEffect(() => {
		if (personType === 'FO') {
			step2.formDefinition.formMethods.reset({
				...step2.formDefinition.formMethods.control._defaultValues,
				typOsoby: 'FO',
			});
		}
		if (personType === 'PO') {
			step2.formDefinition.formMethods.reset({
				...step2.formDefinition.formMethods.control._defaultValues,
				typOsoby: 'PO',
			});
		}
	}, [personType]);

	const typeSearch = 'GENERAL';
	const ds = step2.formDefinition.formMethods.watch('foDs');
	const lookUpQuery = usePoQuery<DatovkyApiSeznam>({
		queryKey: [
			'data-box-autocomplete',
			typeSearch,
			requiredDataBoxes[0],
			ds?.selected?.datovaSchrankaId,
		],
		queryFn: () =>
			searchDataBoxesQuery(
				ds?.selected?.datovaSchrankaId as string,
				typeSearch,
				requiredDataBoxes[0]
			),
		enabled: !!ds.selected,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const treeCheckboxes = useTreeFormCheckboxes({
		tree: useFormTree(),
		formMethods: (wizard.step(2) as unknown as WizardFormStep<FormDataStep3>)
			.formDefinition.formMethods,
	});

	const handleDownload = async () => {
		setControls({
			downloadError: null,
			isDownloadLoading: true,
		});
		return downloadBlob({
			data: prepareSubmitData(
				mainDocument,
				lookUpQuery.data?.seznam?.[0]
			)(wizard.formData),
			onSuccess: () => {
				setControls({ isDownloadLoading: false });
				wizard.resetForms();
			},
			onError: (error) => {
				setControls({ downloadError: error, isDownloadLoading: false });
				wizard.resetForms();
			},
		});
	};

	return (
		<ServiceContext.Provider
			value={{
				code,
				requiredDataBoxes,
				controls,
				setControls,
				individualPerson,
				mainDocument,
				foDs: lookUpQuery.data?.seznam?.[0],
				wizard,
				treeCheckboxes,
				scope: scopeWithLabels(treeCheckboxes.getTree()),
				handleDownload,
			}}>
			{children}
		</ServiceContext.Provider>
	);
}

export const ZadostOPoskytnutiUdajuContextInstance = () =>
	useContext(ServiceContext) as ServiceContextTypes;
