import React from 'react';
import { useBoolean } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';

interface CollapsibleProps {
	children?: string;
	max: number;
	showCollapsedLabel: string;
	showExpandedLabel: string;
}

export const Collapsible = ({
	children,
	max,
	showExpandedLabel,
	showCollapsedLabel,
}: CollapsibleProps) => {
	const isExpanded = useBoolean(false);

	if (children && children.length > max) {
		if (isExpanded.value) {
			return (
				<span>
					<span>{children}</span>
					<br />
					<Button
						onClick={isExpanded.setFalse}
						variant="primary"
						type="base"
						size="s"
						wcagLabel={showCollapsedLabel}>
						{showCollapsedLabel}
					</Button>
				</span>
			);
		}

		return (
			<span>
				<span>{children.slice(0, max)}</span>
				<div>
					<Button
						onClick={isExpanded.setTrue}
						variant="primary"
						type="base"
						size="s"
						wcagLabel={showExpandedLabel}>
						{showExpandedLabel}
					</Button>
				</div>
			</span>
		);
	}

	return <span>{children}</span>;
};
