import { FormMessage } from 'libs/ui/web/src/Form/FormMessage';
import { useWatch } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import {
	Button,
	FormCheckbox,
	FormInput,
	FormWidthElementLayout,
	Modal,
} from '@gov-nx/ui/web';

type Props = {
	isEdit: boolean;
};

export const AdditionalDataModal = ({ isEdit }: Props) => {
	const {
		showAdditionalDataModal,
		onAdditionalDataModalClose,
		onAdditionalDataModalConfirm,
		form,
	} = useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const localData = useWatch({
		control: form.control,
	});
	const hasAdditionalInfo = Boolean(
		localData.temporary?.additionalData?.intoTheirOwnHands ||
			localData.temporary?.additionalData?.senderIdentification ||
			localData.temporary?.additionalData?.toTheHandsOf ||
			localData.temporary?.additionalData?.naseSpisovaZnacka ||
			localData.temporary?.additionalData?.naseCisloJednaci ||
			localData.temporary?.additionalData?.vaseSpisovaZnacka ||
			localData.temporary?.additionalData?.vaseCisloJednaci
	);
	const dataChanged = Boolean(
		localData.temporary?.additionalData?.intoTheirOwnHands !==
			localData.intoTheirOwnHands ||
			localData.temporary?.additionalData?.senderIdentification !==
				localData.senderIdentification ||
			localData.temporary?.additionalData?.toTheHandsOf !==
				localData.toTheHandsOf ||
			localData.temporary?.additionalData?.naseSpisovaZnacka !==
				localData.naseSpisovaZnacka ||
			localData.temporary?.additionalData?.naseCisloJednaci !==
				localData.naseCisloJednaci ||
			localData.temporary?.additionalData?.vaseSpisovaZnacka !==
				localData.vaseSpisovaZnacka ||
			localData.temporary?.additionalData?.vaseCisloJednaci !==
				localData.vaseCisloJednaci
	);
	const buttonDisabled = isEdit ? !dataChanged : !hasAdditionalInfo;

	return (
		<Modal
			label={tn('nova-zprava.doplnkove-udaje.nadpis')}
			open={showAdditionalDataModal}
			onGov-close={onAdditionalDataModalClose}>
			<div className={'flex flex-col gap-10'}>
				<section className={'flex flex-col gap-6'}>
					<h3 className={'text-l font-bold text-secondary-800'}>
						{tn('nova-zprava.doplnkove-udaje.odesilatel-a-prijemce')}
					</h3>
					<FormWidthElementLayout>
						<FormInput
							field={{
								name: 'temporary.additionalData.toTheHandsOf',
							}}
							label={{
								children: tn('nova-zprava.doplnkove-udaje.k-rukam-osoby'),
							}}
							control={{
								size: 'm',
							}}
						/>
						<FormMessage
							messages={[
								{
									variant: 'secondary',
									children: tn(
										'nova-zprava.doplnkove-udaje.k-rukam-osoby-vysvetleni'
									),
								},
							]}
						/>
					</FormWidthElementLayout>
					<div className={'flex flex-col gap-2'}>
						<FormCheckbox
							field={{
								name: 'temporary.additionalData.intoTheirOwnHands',
								label: {
									children: tn(
										'nova-zprava.doplnkove-udaje.predat-do-vlastnich-rukou'
									),
								},
							}}
						/>
						<div className={'ml-10'}>
							<FormMessage
								messages={[
									{
										variant: 'secondary',
										children: tn(
											'nova-zprava.doplnkove-udaje.do-vlastnich-rukou-vysvetleni'
										),
									},
								]}
							/>
						</div>
					</div>
					<div className={'flex flex-col gap-2'}>
						<FormCheckbox
							field={{
								name: 'temporary.additionalData.senderIdentification',
								label: {
									children: tn(
										'nova-zprava.doplnkove-udaje.pridat-identifikaci'
									),
								},
							}}
						/>
						<div className={'ml-10'}>
							<FormMessage
								messages={[
									{
										variant: 'secondary',
										children: tn(
											'nova-zprava.doplnkove-udaje.pridat-identifikaci-vysvetleni'
										),
									},
								]}
							/>
						</div>
					</div>
				</section>
				<section className={'flex flex-col gap-6'}>
					<div>
						<h3 className={'text-l font-bold text-secondary-800'}>
							{tn('nova-zprava.doplnkove-udaje.cisla-jednaci-a-spisove-znacky')}
						</h3>
						<p className={'text-s'}>
							{tn('nova-zprava.doplnkove-udaje.pokud-znate-vyplnte')}
						</p>
					</div>
					<div className={'flex flex-col gap-3'}>
						<h4 className={'text-m font-bold'}>
							{tn('nova-zprava.doplnkove-udaje.nase')}
						</h4>
						<div className={'flex flex-col gap-6 [ md:flex-row ]'}>
							<FormInput
								field={{
									name: 'temporary.additionalData.naseCisloJednaci',
								}}
								label={{
									children: tn(
										'nova-zprava.doplnkove-udaje.nase-cislo-jednaci'
									),
								}}
								control={{
									size: 'm',
								}}
							/>
							<FormInput
								field={{
									name: 'temporary.additionalData.naseSpisovaZnacka',
								}}
								label={{
									children: tn(
										'nova-zprava.doplnkove-udaje.nase-spisova-znacka'
									),
								}}
								control={{
									size: 'm',
								}}
							/>
						</div>
					</div>
					<div className={'flex flex-col gap-3'}>
						<h4 className={'text-m font-bold'}>
							{tn('nova-zprava.doplnkove-udaje.vase')}
						</h4>
						<div className={'flex flex-col gap-6 [ md:flex-row ]'}>
							<FormInput
								field={{
									name: 'temporary.additionalData.vaseCisloJednaci',
								}}
								label={{
									children: tn(
										'nova-zprava.doplnkove-udaje.vase-cislo-jednaci'
									),
								}}
								control={{
									size: 'm',
								}}
							/>
							<FormInput
								field={{
									name: 'temporary.additionalData.vaseSpisovaZnacka',
								}}
								label={{
									children: tn(
										'nova-zprava.doplnkove-udaje.vase-spisova-znacka'
									),
								}}
								control={{
									size: 'm',
								}}
							/>
						</div>
					</div>
				</section>
				<footer className={'flex gap-4'}>
					<Button
						nativeType="button"
						variant="primary"
						type="solid"
						size="l"
						disabled={buttonDisabled}
						onClick={onAdditionalDataModalConfirm}
						wcagLabel={
							isEdit
								? tn('nova-zprava.doplnkove-udaje.upravit-udaje')
								: tn('nova-zprava.doplnkove-udaje.pridat-udaje')
						}>
						<span>
							{isEdit
								? tn('nova-zprava.doplnkove-udaje.upravit-udaje')
								: tn('nova-zprava.doplnkove-udaje.pridat-udaje')}
						</span>
					</Button>
					<Button
						type="outlined"
						nativeType="button"
						variant="primary"
						size="l"
						onClick={onAdditionalDataModalClose}
						wcagLabel={
							isEdit
								? tn('nova-zprava.doplnkove-udaje.zrusit-zmeny')
								: tn('nova-zprava.doplnkove-udaje.zrusit')
						}>
						<span>
							{isEdit
								? tn('nova-zprava.doplnkove-udaje.zrusit-zmeny')
								: tn('nova-zprava.doplnkove-udaje.zrusit')}
						</span>
					</Button>
				</footer>
			</div>
		</Modal>
	);
};
