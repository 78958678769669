import { pipe } from '@gov-nx/core/types';

export const toPercentageReadable = (input: number): string => {
	return pipe(
		input,
		(number) => {
			return number === 0 || number === 100 ? number : number.toFixed(1);
		},
		(value) => [value, '%'].join(' ')
	);
};
