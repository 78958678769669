import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { axiosInstance } from '../axios';
import {
	DatoveSchrankyDatovaSchrankaStavSeznamDto,
	DatoveSchrankyDatovaZpravaDto,
	DatoveSchrankyDatovaZpravaSeznamDto,
	DatoveSchrankyKreditInfoDto,
	DokumentyVyuzitiUlozisteDto,
} from '../generated';
import {
	ArchiveAllDataBoxesMessagesQueryType,
	ArchiveDataBoxesMessageQueryType,
	ArchiveDataBoxesMessagesQueryType,
	ConnectDataBoxQueryType,
	DeleteDataBoxesMessageQueryType,
	DeleteDataBoxesMessagesQueryType,
	DownloadDataBoxAttachmentQueryType,
	DownloadDataBoxMessageQueryType,
	FetchDataBoxesMessageQueryType,
	FetchDataBoxesMessagesQueryType,
	FetchDataBoxesReceivedMessagesQueryType,
	RestoreDataBoxesMessageQueryType,
	RestoreDataBoxesMessagesQueryType,
	SaveDataBoxAllAttachmentsToDocumentsQueryType,
	SaveDataBoxAttachmentToDocumentsQueryType,
} from '../types/dataBoxQuery';
import { DatovkaApiType, DatovkyApiSeznam } from '../types/databoxTypes';

export const loadDataBoxMessagesQuery = async (
	messageIds: number[]
): Promise<DatoveSchrankyDatovaZpravaDto[]> => {
	const params = {
		datovaZpravaId: messageIds.join(','),
		smazano: false,
		razeni: '-datumACasDoruceni',
		start: 0,
		pocet: 50,
	};
	const { data } = await axiosInstance.get('/api/v1/datovezpravypo', {
		params,
	});
	return data.seznam;
};

export const loadDataBoxMessagePoQuery = async (
	messageId: number
): Promise<DatoveSchrankyDatovaZpravaDto> => {
	govApiLog('/api/v1/datovezpravypo/' + messageId);
	const { data } = await axiosInstance.get(
		'/api/v1/datovezpravypo/' + messageId
	);
	return data;
};

export const fetchDataBoxesQuery = async (): Promise<DatovkyApiSeznam> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky/moje', {
		params: {
			vcetneOdpojenych: true,
			overStav: true,
		},
	});
	return data;
};

export const fetchDataBoxCreditQuery = async (
	dataBoxId: string
): Promise<DatoveSchrankyKreditInfoDto> => {
	const { data } = await axiosInstance.get<DatoveSchrankyKreditInfoDto>(
		`/api/v1/datoveschranky/kredit`,
		{
			params: {
				datovaSchrankaId: dataBoxId,
			},
		}
	);
	return data;
};

export const fetchStorageUsageQuery =
	async (): Promise<DokumentyVyuzitiUlozisteDto> => {
		const { data } = await axiosInstance.get('/api/v1/uloziste/vyuziti');
		return data;
	};

export const fetchDataBoxesConversationsQuery = async (
	dataBoxId: string
): Promise<DatoveSchrankyDatovaZpravaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/datovezpravy/konverzace', {
		params: {
			datovaSchrankaId: dataBoxId,
			start: 0,
			pocet: 50,
		},
	});
	return data;
};

export const searchDataBoxesQuery = async (
	text: string,
	typHledani: 'GENERAL',
	typSchranky?: DatovkaApiType['typSchranky']
): Promise<DatovkyApiSeznam> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky', {
		params: {
			text,
			typHledani,
			typSchranky,
		},
	});
	return data;
};

export const fetchDataBoxesMessagesQuery = async ({
	dataBoxId,
	folderType,
	messageCount = 50,
	startPosition = 1,
	query,
}: FetchDataBoxesMessagesQueryType): Promise<
	Nullable<DatoveSchrankyDatovaZpravaSeznamDto>
> => {
	const commonProps = {
		prijemceDatovaSchrankaId: dataBoxId,
		pocet: messageCount,
		start: startPosition,
		text: query,
	};

	if (typeof query === 'string' && query.length < 3) {
		return null;
	}

	if (
		[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType)
	) {
		const { data } = await axiosInstance.get('/api/v1/datovezpravy', {
			params: {
				...commonProps,
				razeni: '-datumACasDodani',
				smer:
					folderType === DataBoxFolderType.Received ? 'PRIJATA' : 'ODESLANA',
			},
		});
		return data;
	} else if (folderType === DataBoxFolderType.Archived) {
		const { data } = await axiosInstance.get('/api/v1/datovezpravypo', {
			params: {
				...commonProps,
				// With parameter '-datumACasDoruceni', API returns data sorted by 'datumACasDodani'
				razeni: '-datumACasDoruceni',
				smazano: false,
			},
		});
		return data;
	} else if (folderType === DataBoxFolderType.Trash) {
		const { data } = await axiosInstance.get('/api/kompozitni/v1/polozkakos', {
			params: {
				typ: 'DATOVA_ZPRAVA',
				razeni: '-datumSmazani',
				start: startPosition,
				pocet: messageCount,
				datovaSchrankaId: dataBoxId,
			},
		});
		return data;
	}

	return null;
};

export const fetchDataBoxesReceivedMessagesQuery = async ({
	dataBoxesIds,
	messageCount = 50,
}: FetchDataBoxesReceivedMessagesQueryType): Promise<
	PromiseSettledResult<
		AxiosResponse<DatoveSchrankyDatovaZpravaSeznamDto, any>
	>[]
> => {
	return Promise.allSettled(
		dataBoxesIds.map((dataBoxId) =>
			axiosInstance.get<DatoveSchrankyDatovaZpravaSeznamDto>(
				'/api/v1/datovezpravy',
				{
					params: {
						prijemceDatovaSchrankaId: dataBoxId,
						pocet: messageCount,
						start: 1,
						razeni: '-datumACasDodani',
						smer: 'PRIJATA',
					},
				}
			)
		)
	);
};

export const fetchDataBoxesMessageQuery = async ({
	dataBoxId,
	folderType,
	messageId,
}: FetchDataBoxesMessageQueryType): Promise<DatoveSchrankyDatovaZpravaDto> => {
	if (
		folderType === DataBoxFolderType.Archived ||
		folderType === DataBoxFolderType.Trash
	) {
		const { data } = await axiosInstance.get(
			`/api/v1/datovezpravypo/${messageId}`
		);
		return data;
	}

	const { data } = await axiosInstance.get(
		`/api/v1/datovezpravy/${messageId}`,
		{
			params: {
				datovaSchrankaId: dataBoxId,
			},
		}
	);
	return data;
};

export const fetchDataBoxesRecipientQuery = async (
	text: string
): Promise<DatovkaApiType[]> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky', {
		params: {
			text,
			typHledani: 'GENERAL',
		},
	});
	return data?.seznam || [];
};

export const newDataBoxesMessageQuery = async (
	body: FormData
): Promise<AxiosResponse> => {
	const response = await axiosInstance.post('/api/v1/datovezpravy', body, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response;
};

export const archiveDataBoxesMessageQuery = async ({
	dataBoxId,
	messageId,
}: ArchiveDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.post(
		`/api/v1/datovezpravy/${messageId}/archivace`,
		{ datovaSchrankaId: dataBoxId },
		{
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return response;
};

export const archiveAllDataBoxesMessagesQuery = async ({
	dataBoxId,
	folderType,
}: ArchiveAllDataBoxesMessagesQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.post(
		`/api/v1/datovezpravy/archivace`,
		{
			datovaSchrankaId: dataBoxId,
			smer: folderType === DataBoxFolderType.Received ? 'PRIJATA' : 'ODESLANA',
		},
		{
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return response;
};

export const archiveDataBoxesMessagesQuery = async ({
	dataBoxId,
	messagesIds,
}: ArchiveDataBoxesMessagesQueryType): Promise<
	PromiseSettledResult<AxiosResponse>[]
> => {
	return Promise.allSettled(
		messagesIds.map((messageId) =>
			axiosInstance.post(
				`/api/v1/datovezpravy/${messageId}/archivace`,
				{ datovaSchrankaId: dataBoxId },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
		)
	);
};

export const deleteDataBoxesMessageQuery = async ({
	messageId,
	permanently = false,
}: DeleteDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.delete(
		`/api/v1/datovezpravypo/${messageId}${permanently ? '?tvrde=true' : ''}`
	);
	return response;
};

export const deleteDataBoxesMessagesQuery = async ({
	messagesIds,
	permanently = false,
}: DeleteDataBoxesMessagesQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.delete(
		`/api/v1/datovezpravypo?datovaZpravaId=${messagesIds.join(',')}${
			permanently ? '&tvrde=true' : ''
		}`
	);
	return response;
};

export const restoreDataBoxesMessageQuery = async ({
	messageId,
}: RestoreDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.put(
		`/api/v1/datovezpravypo/${messageId}/obnovit`
	);
	return response;
};

export const restoreDataBoxesMessagesQuery = async ({
	messagesIds,
}: RestoreDataBoxesMessagesQueryType): Promise<
	PromiseSettledResult<AxiosResponse>[]
> => {
	return Promise.allSettled(
		messagesIds.map((messageId) =>
			axiosInstance.put(`/api/v1/datovezpravypo/${messageId}/obnovit`)
		)
	);
};

export const downloadDataBoxMessageQuery = async ({
	dataBoxId,
	message,
	folderType,
}: DownloadDataBoxMessageQueryType): Promise<AxiosResponse> => {
	if (folderType === DataBoxFolderType.Archived) {
		return axiosInstance.get(
			`/api/v1/datovezpravypo/${message.datovaZpravaId}/stahnout`,
			{
				responseType: 'blob',
			}
		);
	} else {
		let direction = '';
		if (message.odesilatelId === dataBoxId) direction = 'ODESLANA';
		else if (message.prijemceId === dataBoxId) direction = 'PRIJATA';

		return axiosInstance.get(
			`/api/v1/datovezpravy/${message.datovaZpravaId}/stahnout`,
			{
				responseType: 'blob',
				params: {
					datovaSchrankaId: dataBoxId,
					smer: direction,
				},
			}
		);
	}
};

export const downloadDataBoxAttachmentQuery = async ({
	dataBoxId,
	messageId,
	folderType,
	attachment,
}: DownloadDataBoxAttachmentQueryType): Promise<AxiosResponse> => {
	if (folderType === DataBoxFolderType.Archived) {
		return axiosInstance.get(
			`/api/v1/datovezpravypo/${messageId}/prilohy/${attachment.prilohaId}/stahnout`,
			{
				params: { datovaSchrankaId: dataBoxId },
				responseType: 'blob',
			}
		);
	}

	return axiosInstance.get(
		`/api/v1/datovezpravy/${messageId}/prilohy/${attachment.hashPrilohy}/stahnout`,
		{
			params: { datovaSchrankaId: dataBoxId },
			responseType: 'blob',
		}
	);
};

export const saveDataBoxAttachmentToDocumentsQuery = async ({
	dataBoxId,
	messageId,
	folderType,
	attachment,
}: SaveDataBoxAttachmentToDocumentsQueryType): Promise<AxiosResponse> => {
	if (folderType === DataBoxFolderType.Archived) {
		return await axiosInstance.put(
			`/api/v1/datovezpravypo/${messageId}/prilohy/${attachment.prilohaId}/ulozitDoDokumentu`,
			{
				datovaSchrankaId: dataBoxId,
			}
		);
	}

	return await axiosInstance.put(
		`/api/v1/datovezpravy/${messageId}/prilohy/${attachment.hashPrilohy}/ulozitDoDokumentu`,
		{
			datovaSchrankaId: dataBoxId,
		}
	);
};

export const saveDataBoxAllAttachmentsToDocumentsQuery = async ({
	dataBoxId,
	messageId,
	attachments,
	folderType,
}: SaveDataBoxAllAttachmentsToDocumentsQueryType): Promise<
	PromiseSettledResult<AxiosResponse>[]
> => {
	if (folderType === DataBoxFolderType.Archived) {
		return Promise.allSettled(
			attachments.map((attachment) =>
				axiosInstance.put(
					`/api/v1/datovezpravypo/${messageId}/prilohy/${attachment.prilohaId}/ulozitDoDokumentu`
				)
			)
		);
	}

	return Promise.allSettled(
		attachments.map((attachment) =>
			axiosInstance.put(
				`/api/v1/datovezpravy/${messageId}/prilohy/${attachment.hashPrilohy}/ulozitDoDokumentu`,
				{
					datovaSchrankaId: dataBoxId,
				}
			)
		)
	);
};

export const connectDataBoxQuery = async ({
	dataBoxId,
	body,
}: ConnectDataBoxQueryType): Promise<
	AxiosResponse<{ datovaSchrankaId: string }>
> => {
	return await axiosInstance.post(
		`/api/v1/datoveschranky/${dataBoxId}/pridat`,
		body
	);
};

export const disconnectDataBoxQuery = async (
	databoxId: string
): Promise<AxiosResponse> => {
	return axiosInstance.delete(`/api/v1/datoveschranky/${databoxId}/odebrat`);
};

export const fetchDataBoxesUnreadMessagesCountsQuery = async (): Promise<
	AxiosResponse<DatoveSchrankyDatovaSchrankaStavSeznamDto>
> => {
	return axiosInstance.get('/api/v1/datoveschranky/neprecteno');
};
