export interface formatedDocuments {
	sourceIdentifier: string;
	sourceName: string;
	sourceIco: string;
	effectiveTime: string;
	ehealthDocumentId: string;
	ehealthDocumentOid: string;
	traceToken: string;
}

export enum ZdravDokumentaceOperace {
	STAHNOUT = 'stahnout',
	ULOZIT_DO_DOKUMENTU = 'ulozitdodokumentu',
}

export enum ZdravDokumentaceZdrojUdaju {
	NCP_POSKYTOVATELE_ZDR_SLUZEB = 'NCP_POSKYTOVATELE_ZDR_SLUZEB',
	NCP_ZDRAVOTNICKA_DOC_PACIENTA = 'NCP_ZDRAVOTNICKA_DOC_PACIENTA',
}

export interface ZdravDokumentaceDataPreviewsParams {
	zdrojUdaju: ZdravDokumentaceZdrojUdaju.NCP_POSKYTOVATELE_ZDR_SLUZEB;
	format: string;
}

export interface SubmitObject {
	zdrojUdaju: ZdravDokumentaceZdrojUdaju.NCP_ZDRAVOTNICKA_DOC_PACIENTA;
	format: 'signed';
	sourceIco: string;
	sourceIdentifier: string;
	ehealthDocumentId: string;
	ehealthDocumentOid: string;
	traceToken: string;
}

export interface DataRequestBody {
	zdrojUdaju: ZdravDokumentaceZdrojUdaju.NCP_ZDRAVOTNICKA_DOC_PACIENTA;
	format: 'signed';
	operace:
		| ZdravDokumentaceOperace.STAHNOUT
		| ZdravDokumentaceOperace.ULOZIT_DO_DOKUMENTU;
	sourceIco: string;
	sourceIdentifier: string;
	ehealthDocumentId: string;
	ehealthDocumentOid: string;
	traceToken: string;
}

export interface DataZdravDokumentaceRequest {
	body: DataRequestBody;
}
