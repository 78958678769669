import { CiselnikyCiselnikPolozkaDto } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

export function getSortedOptions(
	country: Nullable<CiselnikyCiselnikPolozkaDto[]>
) {
	const sortedOptions = country
		? country
				.filter((data) => data.kod !== undefined && data.nazevCs !== undefined)
				.sort((a, b) => (a.nazevCs || '').localeCompare(b.nazevCs || ''))
				.map((data) => ({
					value: data.kod !== undefined ? data.kod.toString() : '',
					label: data.nazevCs || '',
				}))
		: [];

	return sortedOptions;
}
