import { useCallback } from 'react';
import {
	CommonSettingKey,
	fetchUserSettingsQuery,
	isUnauthorizedResponse,
	saveUserSettingQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { PoIdentityType, useIdentity } from '@gov-nx/auth/common';
import { LocalizationLanguage, useAppContext } from '@gov-nx/core/app';
import { is, Nullable } from '@gov-nx/core/types';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { mapToUserSetting } from '@gov-nx/utils/common';

const queryKey = ['user-settings-app-portal-obcana'];

interface UserSettingsLoader {
	persistLanguage: (language: LocalizationLanguage) => Promise<void>;
	initUserSettings: () => Promise<void>;
}

export const useUserSettingsLoader = (): UserSettingsLoader => {
	const { setAppError } = useAppContext();
	const { storeFetchedUserSettings, setUserSettingLanguage } = useUserStore();
	const poIdentityContext = useIdentity() as Nullable<PoIdentityType>;

	const saveUserSettingMutation = usePoMutation({
		mutationFn: saveUserSettingQuery,
	});

	const persistLanguage = async (language: LocalizationLanguage) => {
		setUserSettingLanguage(language);

		if (poIdentityContext?.isAuthenticated) {
			await saveUserSettingMutation.mutateAsync(
				{
					key: CommonSettingKey.Language,
					value: language,
				},
				{
					onSuccess: () => {
						fetchUserSettings();
					},
				}
			);
		}
	};

	const initQuery = usePoQuery({
		queryFn: fetchUserSettingsQuery,
		onSuccess: (data) => {
			if (!data?.seznam) {
				setAppError(new Error(`Invalid user settings`));
				return;
			}

			storeFetchedUserSettings(data.seznam.map(mapToUserSetting).filter(is));
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				setAppError(e as Error);
			}
		},
		queryKey,
		enabled: false,
		retry: 1,
	});

	const fetchUserSettings = useCallback(async (): Promise<void> => {
		await initQuery.refetch();
	}, [initQuery]);

	return { initUserSettings: fetchUserSettings, persistLanguage };
};
