import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const AgendasSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={8}>
			{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
				return (
					<div key={i}>
						<GovSkeleton
							width={generateRandomNumber(20, 70) + '%'}
							height={'28px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'mb-2 skeleton-brighter'}
						/>
						<div
							className={
								'flex justify-between items-center h-20 px-2 py-3 bg-neutral-white [ md:h-12 ]'
							}>
							<div className={'w-full'}>
								<GovSkeleton
									width={generateRandomNumber(70, 90) + '%'}
									height={'24px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'[ md:hidden ]'}
								/>
								<GovSkeleton
									width={generateRandomNumber(20, 80) + '%'}
									height={'24px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
							<GovSkeleton
								height={'24px'}
								width={'24px'}
								shape={'circle'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
					</div>
				);
			})}
		</LayoutGap>
	);
};
