import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { Trans, useTranslation } from 'react-i18next';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	DataBoxListStatesType,
	isDataBoxTypePO,
	useDataBoxActionInfoContext,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

export const DataBoxActionInfoPage = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);

	const { listState, folderType, emptyFolder, dataBox } =
		useDataBoxActionInfoContext();

	const { automaticArchiveEnabled, notificationsEnabled } =
		useDataBoxesContext();

	if (listState === DataBoxListStatesType.List && emptyFolder) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<img
						src="\assets\images\postcard-placeholder.svg"
						width="160"
						height="100"
						alt=""
					/>
				</div>
			</div>
		);
	}

	if (
		listState === DataBoxListStatesType.List &&
		[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\postcard.svg"
							width="160"
							height="100"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>
								{tn('info-stranka.nevybrali-jste-zpravu')}
							</h4>
							<p className={'text-secondary-700'}>
								{tn('info-stranka.vyberte-zpravu')}
							</p>
						</div>
					</div>

					{!isDataBoxTypePO(dataBox) &&
					!automaticArchiveEnabled &&
					(notificationsEnabled ||
						(!notificationsEnabled && Math.random() > 0.5)) ? (
						<>
							<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
							<div
								className={
									'flex flex-col gap-6 h-full justify-center items-center text-center'
								}>
								<img
									src="\assets\images\archiv.svg"
									width="102"
									height="64"
									alt=""
								/>
								<div className={'flex flex-col gap-2'}>
									<h4 className={'text-l font-bold'}>
										{tn('info-stranka.automaticke-mazani')}
									</h4>
									<p className={'text-secondary-700'}>
										{tn('info-stranka.automaticka-archivace')}
									</p>
								</div>
								<div>
									<Button
										key="zapnout-archivaci-button"
										href={routeResolver(PageCode['nastaveni'])}
										variant="primary"
										type="solid">
										{tn('info-stranka.zapnout-archivaci')}
									</Button>
								</div>
							</div>
						</>
					) : !notificationsEnabled ? (
						<>
							<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
							<div
								className={
									'flex flex-col gap-6 h-full justify-center items-center text-center'
								}>
								<img
									src="\assets\images\notification.svg"
									width="102"
									height="64"
									alt=""
								/>
								<div className={'flex flex-col gap-2'}>
									<h4 className={'text-l font-bold'}>
										{tn('info-stranka.dulezita-zprava')}
									</h4>
									<p className={'text-secondary-700'}>
										{tn('info-stranka.zapnout-notifikace')}
									</p>
								</div>
								<div>
									<Button
										key="nastavit-notifikace-button"
										href={routeResolver(PageCode['nastaveni'])}
										variant="primary"
										type="solid"
										wcagLabel={tn('info-stranka.wcag.nastavit-notifikace')}>
										{tn('info-stranka.nastavit-notifikace')}
									</Button>
								</div>
							</div>
						</>
					) : null}
				</div>
			</div>
		);
	}

	if (
		listState === DataBoxListStatesType.List &&
		DataBoxFolderType.Archived === folderType
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\postcard.svg"
							width="160"
							height="100"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>
								{tn('info-stranka.nevybrali-jste-zpravu')}
							</h4>
							<p className={'text-secondary-700'}>
								{tn('info-stranka.vyberte-zpravu')}
							</p>
						</div>
					</div>
					{!isDataBoxTypePO(dataBox) && !automaticArchiveEnabled && (
						<>
							<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
							<div
								className={
									'flex flex-col gap-6 h-full justify-center items-center text-center'
								}>
								<img
									src="\assets\images\archiv.svg"
									width="102"
									height="64"
									alt=""
								/>
								<div className={'flex flex-col gap-2'}>
									<h4 className={'text-l font-bold'}>
										{tn('info-stranka.automaticke-mazani')}
									</h4>
									<p className={'text-secondary-700'}>
										{tn('info-stranka.automaticka-archivace')}
									</p>
								</div>
								<div>
									<Button
										href={routeResolver(PageCode['nastaveni'])}
										variant="primary"
										type="solid">
										{tn('info-stranka.zapnout-archivaci')}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}

	if (
		listState === DataBoxListStatesType.List &&
		DataBoxFolderType.Trash === folderType
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\postcard.svg"
							width="160"
							height="100"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>
								{tn('info-stranka.nevybrali-jste-zpravu')}
							</h4>
							<p className={'text-secondary-700'}>
								{tn('info-stranka.vyberte-zpravu')}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (listState === DataBoxListStatesType.Search) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex h-full justify-center items-center'}>
					<div className={'w-[400px] flex flex-col gap-6'}>
						<img
							src="\assets\images\search.svg"
							width="102"
							height="64"
							alt=""
						/>
						<h4 className={'text-l font-bold'}>
							{tn('info-stranka.vyhledavani.vyhledavani')}
						</h4>
						<div className={'flex flex-col gap-2'}>
							<p className={'flex gap-2 items-center font-bold'}>
								<GovIcon
									name="lightbulb-fill"
									className={'inline-flex grow-0 shrink-0 w-4'}
								/>
								{tn('info-stranka.vyhledavani.podle')}
							</p>
							<ul className={'ml-6 text-secondary-700'}>
								<li className="before:!bg-secondary-700">
									{tn('info-stranka.vyhledavani.predmet')}
								</li>
								<li className="before:!bg-secondary-700">
									{tn('info-stranka.vyhledavani.odesilatel')}
								</li>
								<li className="before:!bg-secondary-700">
									{tn('info-stranka.vyhledavani.schranka')}
								</li>
								<li className="before:!bg-secondary-700">
									{tn('info-stranka.vyhledavani.zprava')}
								</li>
							</ul>
							<p>
								<Trans
									t={t}
									i18nKey="info-stranka.vyhledavani-slozka"
									components={{ strong: <strong /> }}
								/>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (
		[
			DataBoxListStatesType.MassArchive,
			DataBoxListStatesType.SearchMassArchive,
		].includes(listState)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\archive-copy.svg"
						width="102"
						height="64"
						alt=""
					/>
					<div className={'flex flex-col gap-2'}>
						<h4 className={'text-l font-bold'}>
							{tn('info-stranka.kopirovani-do-archivu')}
						</h4>
						<p className={'text-secondary-700'}>
							{tn('info-stranka.najdete-datovka')}
						</p>
					</div>
				</div>
			</div>
		);
	}

	if (
		[
			DataBoxListStatesType.MassRemove,
			DataBoxListStatesType.SearchMassRemove,
		].includes(listState)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\bin.svg"
						width="102"
						height="64"
						alt=""
					/>
					<div className={'flex flex-col gap-2'}>
						<h4 className={'text-l font-bold'}>
							{tn('info-stranka.vybrane-smazani')}
						</h4>
						<p className={'text-secondary-700'}>
							{tn('info-stranka.automaticke-odstraneni')}
						</p>
					</div>
				</div>
			</div>
		);
	}

	if (
		[
			DataBoxListStatesType.MassPermanentRemove,
			DataBoxListStatesType.SearchMassPermanentRemove,
		].includes(listState)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\bin-empty.svg"
						width="102"
						height="64"
						alt=""
					/>
					<h4 className={'text-l font-bold'}>
						{tn('info-stranka.kos-odstranit')}
					</h4>
				</div>
			</div>
		);
	}

	if (
		[
			DataBoxListStatesType.MassRestore,
			DataBoxListStatesType.SearchMassRestore,
		].includes(listState)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\bin-archive.svg"
						width="102"
						height="64"
						alt=""
					/>
					<h4 className={'text-l font-bold'}>
						{tn('info-stranka.kos-obnovit')}
					</h4>
				</div>
			</div>
		);
	}

	return null;
};
