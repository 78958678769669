import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	RecycleBinOrder,
	useMojeSouborySeznamContext,
} from '@gov-nx/module/page';
import { SortArrows } from '@gov-nx/ui/web';
import { SelectAll } from '../SelectAll';

export const TableHeadBinned = () => {
	const { code, myFilesFilters } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<thead>
			<tr className={'h-[56px] align-bottom'}>
				<th
					className={
						'w-full pl-4 text-left font-normal text-primary-600 whitespace-nowrap [ lg:w-[400px] ]'
					}>
					{tsn('data.nazev-souboru')}
					<SortArrows
						descWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.nazev-souboru'
						)} - ${tsn('razeni.sestupne')}`}
						descOnClick={() =>
							myFilesFilters.sortFilesBy(RecycleBinOrder.NAZEV_SOUBORU_DESC)
						}
						ascWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.nazev-souboru'
						)} - ${tsn('razeni.vzestupne')}`}
						ascOnClick={() =>
							myFilesFilters.sortFilesBy(RecycleBinOrder.NAZEV_SOUBORU)
						}
					/>
				</th>
				<th
					className={
						'pl-1 text-left font-normal text-primary-600 whitespace-nowrap hidden [ lg:table-cell ]'
					}>
					{tsn('data.v-kosi-od')}
					<SortArrows
						descWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.datum-smazani'
						)} - ${tsn('razeni.sestupne')}`}
						descOnClick={() =>
							myFilesFilters.sortFilesBy(RecycleBinOrder.DATUM_SMAZANI_DESC)
						}
						ascWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.datum-smazani'
						)} - ${tsn('razeni.vzestupne')}`}
						ascOnClick={() =>
							myFilesFilters.sortFilesBy(RecycleBinOrder.DATUM_SMAZANI)
						}
					/>
				</th>
				<th
					className={
						'pl-1 text-left font-normal text-primary-600 whitespace-nowrap hidden [ lg:table-cell ]'
					}>
					{tsn('data.odstraneni-za')}
				</th>
				<SelectAll />
			</tr>
		</thead>
	);
};
