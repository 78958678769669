import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const DataboxAdditionalSettingsSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<>
			<div className="p-4 pt-8">
				<div className="w-full">
					<GovSkeleton
						height={'24px'}
						width={'40px'}
						className="mr-4"
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'24px'}
						width={generateRandomNumber(10, 15) + 'em'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</div>
				<GovSkeleton
					height={'14px'}
					width={'90%'}
					wcagLabel={t('loading.wcag.processing')}
				/>
				<GovSkeleton
					height={'14px'}
					width={'70%'}
					wcagLabel={t('loading.wcag.processing')}
				/>
			</div>

			<div className="p-4">
				<GovSkeleton
					height={'38px'}
					width={generateRandomNumber(200, 250) + 'px'}
					className={'py-2'}
					wcagLabel={t('loading.wcag.processing')}
				/>
			</div>
		</>
	);
};
