import { useRef, useState } from 'react';
import { useDataBoxAccess } from '@gov-nx/module/data-box';

export const useDataboxSettingsView = () => {
	const { connectedIds } = useDataBoxAccess();
	const ref = useRef<HTMLHeadingElement | null>(null);
	const scrollToDataboxTop = () => {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};
	const [dataBoxesToDisplay, setDataBoxesToDisplay] = useState<number>(2);
	const connectedDataboxes = connectedIds.slice(0, dataBoxesToDisplay);

	const handleShowMoreLess = () => {
		if (connectedDataboxes.length === 2) {
			setDataBoxesToDisplay(connectedIds.length);
		} else {
			setDataBoxesToDisplay(2);
			scrollToDataboxTop();
		}
	};

	return {
		ref,
		connectedDataboxes,
		handleShowMoreLess,
	};
};
