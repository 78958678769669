import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import {
	emptyRecycleBinQuery,
	usePoMutation,
	RecycleBinType,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { useBoolean } from '@gov-nx/utils/common';

export interface EmptyRecycleBinProps {
	onError: (error: Error) => void;
	onSuccess: (response: AxiosResponse) => void;
}

export const useEmptyRecycleBin = (props?: EmptyRecycleBinProps) => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Service]);
	const { toastMessageSuccess } = useMessageEvents();
	const queryClient = useQueryClient();
	const emptyBinModal = useBoolean();

	const emptyRecycleBin = usePoMutation({
		mutationFn: emptyRecycleBinQuery,
		onError: (error) => {
			props?.onError &&
				props.onError(
					new GovError(
						`Error: An unexpected error occurred while recycle bin emptying. ${error}`
					)
				);
		},
		onSuccess: async (response) => {
			props?.onSuccess && props.onSuccess(response);
			toastMessageSuccess(
				t('kos.vysypani.uspesne', {
					namespace: LocalizeNameSpaceTypes.Service,
				})
			);
			emptyBinModal.setFalse();
			await queryClient.invalidateQueries(['documents-binned']);
		},
	});

	const emptyDocumentRecycleBin = () => {
		emptyRecycleBin.mutate({ typ: RecycleBinType.DOKUMENT });
	};

	return {
		emptyDocumentRecycleBin,
		isEmptying: emptyRecycleBin.isLoading,
		emptyBinModal,
	};
};
