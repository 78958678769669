import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { MySubmissionVoterCardAplication } from '@gov-nx/api/portal-obcana';
import { PoMarkdownRenderWeb } from '@gov-nx/component/web';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useVoterCardApplicationMessages } from '@gov-nx/module/page';
import { Attachment, DocumentDownloadContainer } from '@gov-nx/ui/web';

interface VoterRegistrationMessageProps {
	submission: MySubmissionVoterCardAplication;
	onDownload: (onSuccess: () => void) => void;
}

export const VoterRegistrationMessage = ({
	submission,
	onDownload,
}: VoterRegistrationMessageProps) => {
	const { t } = useLocale(PageCode['moje-podani']);

	const { specificToType, specificToPickupOption } =
		useVoterCardApplicationMessages(submission);

	return (
		<div>
			<GovMessage variant={'primary'}>
				<GovIcon
					slot={'icon'}
					type={'basic'}
					name="lightbulb-fill"
				/>
				{specificToType.map((message) => (
					<PoMarkdownRenderWeb key={message}>{message}</PoMarkdownRenderWeb>
				))}
			</GovMessage>

			<GovMessage variant={'primary'}>
				<GovIcon
					slot={'icon'}
					type={'basic'}
					name="lightbulb-fill"
				/>
				<PoMarkdownRenderWeb>
					{t('volicsky-prukaz.pouceni')}
				</PoMarkdownRenderWeb>

				{specificToPickupOption.map((message) => {
					return (
						<PoMarkdownRenderWeb key={message}>{message}</PoMarkdownRenderWeb>
					);
				})}

				<PoMarkdownRenderWeb>
					{t('volicsky-prukaz.pouceni-v-pripade-dotazu')}
				</PoMarkdownRenderWeb>
			</GovMessage>
			{submission.parametry.zpusobPrevzeti === 'URAD' ? (
				<div>
					<br />
					<DocumentDownloadContainer>
						<Attachment
							downloadLabel={t('volicsky-prukaz.stahnout.stitek')}
							name={t('volicsky-prukaz.stahnout.nazev-souboru')}
							onDownload={onDownload}
							size={17.062 * 1024}
						/>
					</DocumentDownloadContainer>
				</div>
			) : null}
		</div>
	);
};
