import {
	createInstance,
	MatomoProvider,
	useMatomo,
} from '@mitresthen/matomo-tracker-react';
import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfiguration } from '@gov-nx/core/service';

export const MatomoInstanceProvider = ({ children }: PropsWithChildren) => {
	const { matomoBaseUrl, matomoSiteId } = useConfiguration();

	if (matomoBaseUrl && matomoSiteId) {
		const instance = createInstance({
			urlBase: matomoBaseUrl as string,
			siteId: matomoSiteId as number,
			disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
			configurations: {
				disableCookies: true,
				setSecureCookie: true,
			},
		});

		return (
			<MatomoProvider value={instance}>
				<MatomoChildren>{children}</MatomoChildren>
			</MatomoProvider>
		);
	}

	return <>{children}</>;
};

export const MatomoChildren = ({ children }: PropsWithChildren) => {
	const location = useLocation();
	const { trackPageView } = useMatomo();

	useEffect(() => {
		trackPageView();
	}, [location]);

	return <>{children}</>;
};
