import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { usePersonCommunicationData } from '@gov-nx/core/hooks';
import {
	compose,
	DeepPartial,
	getKeys,
	Optional,
	whenDefined,
} from '@gov-nx/core/types';
import {
	FormNotificationField,
	FormNotificationFields,
	NotificationChannel,
	NotificationSavePayload,
	NotificationSettingsPayload,
	TypeAPayload,
	TypeAValues,
	TypeDPayload,
} from './form.types';

export const getNotificationSettingKeys =
	(): NotificationSettingsPayload['klic'][] => [
		NotificationChannel.Oznameni,
		NotificationChannel.MarketingovaSdeleni,
		NotificationChannel.ZmenyVRegistrech,
		NotificationChannel.KonecPlatnostiDokladu,
		NotificationChannel.KonecPlatnostiDokladuSms,
		NotificationChannel.KonecPlatnostiDokladuPush,
	];

export const notificationSettingQueryKey = (
	key: NotificationChannel
): ['setting-notifications', NotificationChannel] => [
	'setting-notifications',
	key,
];

const useNotificationSettingsCache = () => {
	const queryClient = useQueryClient();

	const getValue = (payload: NotificationSettingsPayload): number => {
		if (payload.hodnota?.startsWith('RP') ?? false) {
			return payload.hodnota === 'RP0' ? 0 : 1;
		}

		return parseInt(payload.hodnota);
	};

	const getTypeDPayload = (
		name:
			| NotificationChannel.KonecPlatnostiDokladu
			| NotificationChannel.KonecPlatnostiDokladuSms
			| NotificationChannel.KonecPlatnostiDokladuPush
	) =>
		queryClient.getQueryData<TypeDPayload>(notificationSettingQueryKey(name));

	const getTypeAPayload = (
		name:
			| NotificationChannel.Oznameni
			| NotificationChannel.MarketingovaSdeleni
			| NotificationChannel.ZmenyVRegistrech
	) =>
		queryClient.getQueryData<TypeAPayload>(notificationSettingQueryKey(name));

	return {
		getTypeAPayload: compose(whenDefined(getValue), getTypeAPayload),
		getTypeDPayload: compose(whenDefined(getValue), getTypeDPayload),
	};
};

export const getFormKeys = (): Array<keyof FormNotificationFields> => {
	return [
		NotificationChannel.Oznameni,
		NotificationChannel.MarketingovaSdeleni,
		NotificationChannel.ZmenyVRegistrech,
		NotificationChannel.KonecPlatnostiDokladu,
	];
};

export function NotificationFormInstance(): UseFormReturn<FormNotificationFields> {
	return useForm<FormNotificationFields>({
		defaultValues: getFormKeys().reduce(
			(all, key) => ({
				...all,
				[key]: {
					sms: false,
					email: false,
					push: false,
				},
			}),
			{}
		),
	});
}

const fieldNameSplit = (
	name:
		| `${NotificationSavePayload['key']}.${NotificationSavePayload['type']}`
		| NotificationSavePayload['key']
) => {
	const [key, type] = name.split('.');
	return {
		key: key as keyof FormNotificationFields,
		type: type as NotificationSavePayload['type'],
	};
};

export const prepareSubmitData = (
	name:
		| `${keyof FormNotificationFields}.${NotificationSavePayload['type']}`
		| NotificationSavePayload['key'],
	values: DeepPartial<FormNotificationFields>
): Optional<NotificationSettingsPayload> => {
	const { type, key } = fieldNameSplit(name);

	const row: DeepPartial<FormNotificationField> =
		values[key] ?? ({} as FormNotificationField);
	const availableNotificationTypes = getKeys(row);

	let valueForBE: number | string = 0;

	switch (key) {
		case NotificationChannel.KonecPlatnostiDokladu:
			return availableNotificationTypes
				.filter((k) => k === type) // filter to only one that is requested
				.map((k) => {
					switch (k) {
						case 'sms':
							return {
								klic: NotificationChannel.KonecPlatnostiDokladuSms,
								hodnota: row[k] ? 'RP7' : 'RP0',
							};
						case 'push':
							return {
								klic: NotificationChannel.KonecPlatnostiDokladuPush,
								hodnota: row[k] ? 'RP1' : 'RP0',
							};
						default:
							return {
								klic: NotificationChannel.KonecPlatnostiDokladu,
								hodnota: row[k] ? 'RP8' : 'RP0',
							};
					}
				})[0];
		default:
			valueForBE = availableNotificationTypes
				.filter((k) => row[k]) // filter only active toggles
				.map((k) => TypeAValues[k]) // map active toggles to ENUM values
				.reduce((sum, enumValue, i) => enumValue + sum, 0); // combine values to one
	}

	return { klic: key, hodnota: `${valueForBE}` };
};

const useSetTypeAFormValues = (
	pushNotificationsEnabled: boolean,
	formMethods: UseFormReturn<FormNotificationFields>,
	name:
		| NotificationChannel.Oznameni
		| NotificationChannel.MarketingovaSdeleni
		| NotificationChannel.ZmenyVRegistrech
): void => {
	const { emailVerified, phoneVerified } = usePersonCommunicationData();

	const value = useNotificationSettingsCache().getTypeAPayload(name);

	useEffect(() => {
		if (value === undefined) return;

		formMethods.setValue(name, {
			email: emailVerified ? (value & TypeAValues.email) !== 0 : false,
			sms: phoneVerified ? (value & TypeAValues.sms) !== 0 : false,
			push: pushNotificationsEnabled ? (value & TypeAValues.push) !== 0 : false,
		});
	}, [value, pushNotificationsEnabled, emailVerified, phoneVerified]);
};

const useSetDocumentExpiryFormValues = (
	pushNotificationsEnabled: boolean,
	formMethods: UseFormReturn<FormNotificationFields>
): void => {
	const { emailVerified, phoneVerified } = usePersonCommunicationData();

	const { getTypeDPayload } = useNotificationSettingsCache();
	const email = getTypeDPayload(NotificationChannel.KonecPlatnostiDokladu);
	const sms = getTypeDPayload(NotificationChannel.KonecPlatnostiDokladuSms);
	const push = getTypeDPayload(NotificationChannel.KonecPlatnostiDokladuPush);

	useEffect(() => {
		if (email === undefined || sms === undefined || push === undefined) return;

		formMethods.setValue(NotificationChannel.KonecPlatnostiDokladu, {
			email: emailVerified ? email === 1 : false,
			sms: phoneVerified ? sms === 1 : false,
			push: pushNotificationsEnabled ? push === 1 : false,
		});
	}, [
		email,
		sms,
		push,
		pushNotificationsEnabled,
		emailVerified,
		phoneVerified,
	]);
};

export const useSetFormValues = (
	pushNotificationsEnabled: boolean,
	formMethods: UseFormReturn<FormNotificationFields>
): void => {
	useSetTypeAFormValues(
		pushNotificationsEnabled,
		formMethods,
		NotificationChannel.Oznameni
	);
	useSetTypeAFormValues(
		pushNotificationsEnabled,
		formMethods,
		NotificationChannel.MarketingovaSdeleni
	);
	useSetTypeAFormValues(
		pushNotificationsEnabled,
		formMethods,
		NotificationChannel.ZmenyVRegistrech
	);

	useSetDocumentExpiryFormValues(pushNotificationsEnabled, formMethods);
};
