import { createContext, ReactNode, useContext } from 'react';
import { electionsQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { VotersCardContext } from './context.types';

const ServiceContext = createContext<Nullable<VotersCardContext>>(null);

interface VolicskyPrukazContextProviderProps {
	children: ReactNode;
	code: ServiceCode;
}

export const ServiceContextProvider = ({
	children,
}: VolicskyPrukazContextProviderProps) => {
	const elections = usePoQuery({
		queryKey: ['announced-elections'],
		queryFn: electionsQuery,
	});

	return (
		<ServiceContext.Provider
			value={{
				initialState: {
					isLoading: elections.isLoading,
					error: elections.error ?? undefined,
				},
				elections: elections.data?.seznam ?? [],
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const VolicskyPrukazSeznamContextInstance = (): VotersCardContext =>
	useContext(ServiceContext) as VotersCardContext;
