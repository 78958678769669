import { GovCard, GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, LayoutGap } from '@gov-nx/ui/web';

export interface DrozdInfoViewProps {
	code: PageCode;
}

export const DrozdInfoView = ({ code }: DrozdInfoViewProps) => {
	const { appStoreMobileAppLink, googlePlayMobileAppLink } = useConfiguration();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<LayoutGap gap={8}>
			<GovCard promotion={true}>
				<GovIcon
					slot="icon"
					type="complex"
					name="mobile-phone"
				/>
				<div>
					<h2 className={'mb-2 text-2xl'}>{ts('drozd-info.nadpis')}</h2>
					<p>{ts('drozd-info.popis')}</p>
					<div className={'flex flex-row flex-wrap gap-4 mt-4'}>
						{appStoreMobileAppLink ? (
							<Button
								enforceLink={true}
								target={'_blank'}
								href={appStoreMobileAppLink}
								variant="primary"
								type="solid"
								size="m"
								wcagLabel={ts('drozd-info.tlacitko.wcag.app-store')}>
								{ts('drozd-info.tlacitko.app-store')}
							</Button>
						) : null}
						{googlePlayMobileAppLink ? (
							<Button
								enforceLink={true}
								target={'_blank'}
								href={googlePlayMobileAppLink}
								variant="primary"
								type="solid"
								size="m"
								wcagLabel={ts('drozd-info.tlacitko.wcag.google-play')}>
								{ts('drozd-info.tlacitko.google-play')}
							</Button>
						) : null}
					</div>
				</div>
			</GovCard>
		</LayoutGap>
	);
};
