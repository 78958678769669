import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const IdentityDocumentSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout>
			<DataTable
				noStripped
				responsive>
				<tbody>
					{Array.from({ length: 3 }).map((_, index) => (
						<tr>
							<td className="w-1/2">
								<GovSkeleton
									height={'18px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									width={generateRandomNumber(20, 60) + '%'}
									className={'flex-1 mb-0 pb-0'}
								/>
							</td>
							<td className="w-1/2">
								<GovSkeleton
									height={'18px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									width={generateRandomNumber(20, 60) + '%'}
									className={'flex-1 mb-0 pb-0'}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</DataTable>
		</ContentLayout>
	);
};
