import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { WebFilterParameterListProps } from '@gov-nx/ui/types';
import { Button } from '../Button/Button';
import { FilterParameterItem } from './FilterParameterItem';

export const FilterParameterList = ({
	items,
	onReset,
	className,
}: WebFilterParameterListProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const activeFilters = items.filter((item) => item.isActive);

	if (activeFilters.length === 0) {
		return null;
	}

	return (
		<ul
			className={classnames([
				'gov-list--plain flex flex-wrap gap-2 pt-4',
				className,
			])}>
			{activeFilters?.map((item) => {
				return (
					<FilterParameterItem
						key={`${item.label}-${item.value}`}
						{...item}
					/>
				);
			})}
			{activeFilters?.length >= 3 && (
				<li>
					<Button
						onClick={onReset}
						variant="error"
						type="outlined"
						size="s"
						wcagLabel={t('akce.wcag.zrusit-filtr')}>
						{t('akce.zrusit-filtr')}
					</Button>
				</li>
			)}
		</ul>
	);
};
