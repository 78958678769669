import {
	QueriesOptions,
	QueriesResults,
	useQueries,
	useQueryClient,
	UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { propEq } from '@gov-nx/core/types';
import { ResponseError } from '../errors/errors.types';
import { usePoResponseError } from '../errors/usePoResponseError';
import { PoQueryConfig } from './types';

export const usePoQueries = <T extends any[]>({
	queries,
	context,
	configs,
	onAllSuccess,
}: {
	queries: QueriesOptions<T>;
	context?: UseQueryOptions['context'];
	onAllSuccess?: () => void;
	configs?: PoQueryConfig[];
}): QueriesResults<T> => {
	const { handleError } = usePoResponseError();
	const queryClient = useQueryClient();

	const requests = useQueries<T>({
		context,
		queries: queries.map((query, index) => {
			const config = configs?.[index];
			return {
				...query,
				onError: (error: AxiosError<ResponseError>) => {
					if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
						return;
					}

					handleError(error);

					query.onError && query.onError(error);
				},
			};
		}) as any,
	});

	const allFinished = requests.every(propEq('isSuccess', true));

	useEffect(() => {
		if (allFinished) {
			queries.forEach((query) => {
				const cached =
					query.queryKey && queryClient.getQueryData(query.queryKey);
				if (query.onSuccess && cached) {
					query.onSuccess(cached);
				}
			});
			onAllSuccess && onAllSuccess();
		}
	}, [allFinished]);

	return requests;
};
