import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { ActionsBinned } from './kos/ActionsBinned';
import { Actions, ActionsNoFile } from './soubory/Actions';

export const DocumentsActions = () => {
	const { isSelectedRecycleBin, isSelectedWithoutFile } =
		useMojeSouborySeznamContext();

	if (isSelectedRecycleBin) return <ActionsBinned />;

	if (isSelectedWithoutFile) return <ActionsNoFile />;

	return <Actions />;
};
