import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFileUploadParams,
	useDataBoxMessageFormContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	DataBoxRecipientDetail,
	FormFileUpload,
	FormInput,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';
import { AdditionalDataList } from './AdditionalDataList';
import { AdditionalDataModal } from './AdditionalDataModal';
import { AttachmentsDropdown } from './AttachmentsDropdown';
import { CancelPropmpt } from './CancelPropmpt';
import { DataBoxMessageFormSkeleton } from './DataBoxMessageFormSkeleton';
import { DocumentsModal } from './DocumentsModal';
import { FormListItem } from './FormListItem';
import { LowCreditPrompt } from './LowCreditPrompt';
import { RecipientAutocomplete } from './RecipientAutocomplete';
import { SenderDropdown } from './SenderDropdown';

export const DataBoxMessageForm = () => {
	const {
		localData,
		canSendForm,
		showCancelConfirmModal,
		documents,
		dataBoxes,
		recipient,
		showUploadModal,
		hasAdditionalInfo,
		uploadRef,
		blockUploadModalClose,
		loading,
		controls,
		onCancelClick,
		onSubmit,
		openAdditionalDataModal,
		removeDocument,
		onUploadModalClose,
		onFilesConfirm,
		onMessageCancelled,
		removeFile,
	} = useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	if (!dataBoxes || dataBoxes.length === 0 || loading)
		return <DataBoxMessageFormSkeleton />;

	return (
		<form
			onSubmit={onSubmit}
			className={'flex flex-col w-full h-full overflow-hidden'}>
			<div
				className={
					'flex flex-col gap-4 justify-between w-full h-full [ ds:overflow-hidden ]'
				}>
				<div className={'flex flex-col gap-2'}>
					<SenderDropdown />
					{recipient ? (
						<DataBoxRecipientDetail dataBox={recipient} />
					) : (
						<RecipientAutocomplete />
					)}
					<div className={'ds-new-message ds-subject pr-0.5'}>
						<FormInput
							field={{
								name: 'subject',
							}}
							label={{
								children: `${tn('nova-zprava.predmet')}:`,
								required: true,
							}}
							control={{
								size: 'm',
							}}
						/>
					</div>
				</div>
				<div className={'overflow-y-auto'}>
					<div className={'flex flex-col gap-4 p-0.5'}>
						{hasAdditionalInfo ||
						localData.documents?.length ||
						localData.files?.length ? (
							<>
								<ButtonGroupLayout
									customClasses={
										localData.documents?.length || localData.files?.length
											? 'p-3 rounded bg-primary-100 [ md:flex-col ]'
											: ''
									}>
									{localData.documents?.length || localData.files?.length ? (
										<ul className={'flex flex-wrap gap-2 gov-list--plain'}>
											{documents
												?.filter(
													(item) =>
														item.dokumentId &&
														localData.documents?.includes(item.dokumentId)
												)
												.map((document) => (
													<FormListItem
														key={document.dokumentId}
														isFile
														fileSize={document.velikost}
														extension={document.pripona}
														name={document.nazev}
														onRemoveClick={() =>
															removeDocument(document.dokumentId as number)
														}>
														{document.nazev}
													</FormListItem>
												))}
											{localData.files?.map((file) => (
												<FormListItem
													key={file.id}
													isFile
													fileSize={file.size}
													name={file.name}
													onRemoveClick={() => removeFile(file.fileId ?? '')}>
													{file.name}
												</FormListItem>
											))}
										</ul>
									) : null}
									<AttachmentsDropdown />
								</ButtonGroupLayout>
								<ButtonGroupLayout
									customClasses={
										hasAdditionalInfo
											? 'p-3 rounded bg-primary-100 [ md:flex-col ]'
											: ''
									}>
									<AdditionalDataList />
									<Button
										type="outlined"
										nativeType="button"
										variant="primary"
										size="s"
										onClick={openAdditionalDataModal}
										wcagLabel={
											hasAdditionalInfo
												? tn('nova-zprava.upravit-doplnkove-udaje')
												: tn('nova-zprava.pridat-doplnkove-udaje')
										}>
										<span>
											{hasAdditionalInfo
												? tn('nova-zprava.upravit-doplnkove-udaje')
												: tn('nova-zprava.pridat-doplnkove-udaje')}
										</span>
									</Button>
								</ButtonGroupLayout>
							</>
						) : (
							<ButtonGroupLayout customClasses={'flex-col [ xl:flex-row ]'}>
								<AttachmentsDropdown />
								<Button
									variant="primary"
									type="outlined"
									size="s"
									nativeType="button"
									onClick={openAdditionalDataModal}
									wcagLabel={tn('nova-zprava.pridat-doplnkove-udaje')}>
									{tn('nova-zprava.pridat-doplnkove-udaje')}
								</Button>
							</ButtonGroupLayout>
						)}
						<div className={'pt-2'}>
							<FormInput
								field={{
									name: 'message',
									multiline: true,
									rows: 9,
									placeholder: tn('nova-zprava.text-zpravy'),
								}}
								label={{
									children: '',
								}}
							/>
						</div>
					</div>
				</div>
				<ButtonGroupLayout customClasses={'mt-auto p-0.5'}>
					{canSendForm ? (
						<Button
							nativeType="submit"
							disabled={controls.processLoading}
							loading={controls.processLoading}
							variant="primary"
							type="solid"
							wcagLabel={tn('nova-zprava.odeslat')}>
							<GovIcon name="send-fill" />
							{tn('nova-zprava.odeslat')}
						</Button>
					) : (
						<GovTooltip
							position="top"
							className={'border-0'}>
							<Button
								nativeType="button"
								disabled
								variant="primary"
								type="solid"
								wcagLabel={tn('nova-zprava.odeslat')}>
								<GovIcon name="send-fill" />
								{tn('nova-zprava.odeslat')}
							</Button>
							<GovTooltipContent>
								<p>{tn('nova-zprava.zprava-musi-obsahovat')}</p>
							</GovTooltipContent>
						</GovTooltip>
					)}

					<Button
						className="hidden [ ds:inline-block ]"
						wcagLabel={tn('nova-zprava.zahodit')}
						type="outlined"
						nativeType="button"
						variant="primary"
						disabled={controls.processLoading}
						onClick={onCancelClick}>
						<GovIcon name="trash" />
						{tn('nova-zprava.zahodit')}
					</Button>
				</ButtonGroupLayout>
			</div>
			<Modal
				label={tn('nova-zprava.nahrat-prilohu-ze-zarizeni')}
				open={showUploadModal}
				onGov-close={onUploadModalClose}
				blockClose={blockUploadModalClose}>
				<div>
					<LayoutGap gap={2}>
						<FormFileUpload
							ref={uploadRef}
							field={{ name: 'temporary.files', expanded: true }}
							isSubmited={!showUploadModal}
							options={DataBoxFileUploadParams}
						/>
						<p>
							{tn('informace.omezeni-souboru.titul')}
							<GovTooltip
								position="bottom"
								size="s"
								message={tn('informace.omezeni-souboru-detail')
									.split('\n')
									.join('<br/>')}
								icon>
								<GovIcon
									name={'info-circle'}
									type={'basic'}
									className={'ml-2 align-middle w-4'}
								/>
							</GovTooltip>
						</p>
					</LayoutGap>

					<Button
						variant="primary"
						type="solid"
						nativeType="button"
						disabled={!uploadRef.current?.filesUploaded()}
						onClick={onFilesConfirm}
						className={'mt-4'}
						wcagLabel={tn('nova-zprava.dokumenty.prilozit-vybrane')}>
						{tn('nova-zprava.dokumenty.prilozit-vybrane')}
					</Button>
				</div>
			</Modal>
			<DocumentsModal />
			<AdditionalDataModal isEdit={hasAdditionalInfo} />
			<CancelPropmpt
				open={showCancelConfirmModal.value}
				onCancel={() => showCancelConfirmModal.setFalse()}
				onConfirm={onMessageCancelled}
			/>
			<LowCreditPrompt />
		</form>
	);
};
