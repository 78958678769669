import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { DataRequest } from './service.types';

const filterTypes =
	(types: DatovkaApiType['typSchranky'][]) =>
	(type: DatovkaApiType['typSchranky']) =>
		types.filter((item) => (item ?? '').split('_')[0] === type);

export const filterDataBoxTypes = (
	all: DatovkaApiType['typSchranky'][],
	selected: DataRequest['body']['typOsoby']
): DatovkaApiType['typSchranky'][] => {
	const filter = filterTypes(all);
	switch (selected) {
		case 'PFO':
		case 'PO':
			return filter(selected);
		case 'FO':
		default:
			return [selected];
	}
};
