import * as yup from 'yup';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import {
	CommonCheckboxProps,
	CommonInputProps,
	PhoneWithCountryCodeShape,
} from '@gov-nx/ui/types';
import { usePhoneShapeWithCountryCodes } from '@gov-nx/utils/common';

export interface ContactDetailsFormData {
	_useEmail: boolean;
	_usePhone: boolean;
	email?: string;
	phone?: PhoneWithCountryCodeShape;
}

interface IFields {
	_useEmail: CommonCheckboxProps;
	email: CommonInputProps;
	_usePhone: CommonCheckboxProps;
	phone: CommonInputProps;
}

export const useContactDetailsFormDefinition = () => {
	const { getPhoneShape, getDefaultValue } = usePhoneShapeWithCountryCodes();
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	return {
		shape: {
			_useEmail: yup.boolean(),
			_usePhone: yup.boolean(),
			email: yup
				.string()
				.email(t('validations.email-neni-ve-spravnem-tvaru'))
				.when('_useEmail', {
					is: true,
					then: (schema) =>
						schema.required(t('validations.zadejte-kontaktni-email')),
					otherwise: (schema) => schema.optional(),
				}),
			phone: getPhoneShape().when('_usePhone', {
				is: true,
				then: getPhoneShape({ isRequired: true }),
				otherwise: (schema) => schema.optional(),
			}),
		},
		defaultValues: {
			_useEmail: false,
			email: undefined,
			_usePhone: false,
			phone: getDefaultValue(),
		},
		fields: (
			isProcessing: boolean,
			strings: {
				email: { label: string; placeholder: string };
				phone: { label: string; placeholder: string };
			}
		) => {
			const fields: IFields = {
				_useEmail: {
					name: '_useEmail',
					disabled: isProcessing,
					isDisabled: isProcessing,
					label: {
						children: strings.email.label,
					},
				},
				email: {
					name: 'email',
					disabled: isProcessing,
					isDisabled: isProcessing,
					placeholder: strings.email.placeholder,
				},
				_usePhone: {
					name: '_usePhone',
					disabled: isProcessing,
					isDisabled: isProcessing,
					label: {
						children: strings.phone.label,
					},
				},
				phone: {
					name: 'phone',
					disabled: isProcessing,
					isDisabled: isProcessing,
					placeholder: strings.phone.placeholder,
				},
			};

			return fields;
		},
	};
};
