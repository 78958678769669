import * as yup from 'yup';
import { DokladyDokladDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { toDate, toStringDate, useDateShape } from '@gov-nx/utils/common';
import { DataRequest, FormData } from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	document: DokladyDokladDto;
}

export function FormInstance({
	code,
	document,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			cisloDokladu: yup
				.string()
				.optional()
				.min(8, getLocalize(code, 'formular.validace.cislo-dokladu-min'))
				.max(9, getLocalize(code, 'formular.validace.cislo-dokladu-max')),
			platnostDo: getDatePickerShape({ isRequired: true }),
			popisReklamace: yup
				.string()
				.required(
					getLocalize(code, 'formular.validace.popis-reklamace.povinny')
				),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			cisloDokladu: document?.cisloDokladu,
			platnostDo: document?.platnostDo
				? toDate(document.platnostDo)
				: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(document: DokladyDokladDto) =>
	(data: FormData): DataRequest => {
		const seznam: DataRequest['body']['navrhovaneHodnoty']['seznam'] = [];

		if (data.cisloDokladu) {
			seznam.push({
				kodUdaje: 'Doklad',
				puvodni: document.cisloDokladu,
				puvodniDruh: document.druhDokladu,
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.cisloDokladu,
				zpusobNaplneniPlatnost: data.platnostDo
					? toStringDate(data.platnostDo)
					: undefined,
			});
		}

		return {
			params: {},
			body: {
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
