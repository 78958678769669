import { axiosInstance } from '..';
import { AgendyAisAdresaDto } from '../generated';

export interface AgendyAisAdresaSeznamDto {
	seznam?: AgendyAisAdresaDto[];
}
export const addressQuery = async (
	nazevObce?: string,
	cislo?: string,
	nazevUlice?: string
): Promise<AgendyAisAdresaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/adresy', {
		params: {
			nazevObce,
			cislo,
			nazevUlice,
		},
	});
	return data;
};
