import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { PageCode } from '../definitions/codes';
import { CadastreOfRealEstateFilterForm } from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	defaultValues: CadastreOfRealEstateFilterForm;
}

export function FormInstance({
	code,
	defaultValues,
}: FormInstanceProps): FormDefinition<CadastreOfRealEstateFilterForm> {
	const formSchema = yup
		.object({
			text: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<CadastreOfRealEstateFilterForm>({
		formSchema,
		defaultValues,
	});
	return getFormDefinition({ formMethods, formSchema });
}
