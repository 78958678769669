import { GovFormSearch, GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useRozcestnikContext } from '@gov-nx/module/page';
import { Button, FormInput } from '@gov-nx/ui/web';
import { PoFormWeb } from '../../Form/PoForm';

export interface SignpostSearchProps {
	code: PageCode;
}

export const SignpostSearch = ({ code }: SignpostSearchProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { formDefinition, onSubmit } = useRozcestnikContext();

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<>
					<label
						id="vyhledat-rozcestnik"
						className={'hidden'}>
						{ts('vyhledat.placeholder.label')}
					</label>
					<GovFormSearch className={'border [ sm:w-[330px] ]'}>
						<FormInput
							field={{
								name: 'search',
								placeholder: ts('vyhledat.placeholder.co-hledate'),
								wcagLabelledBy: 'vyhledat-rozcestnik',
								size: 's',
							}}
							label={{}}
							slot="input"
						/>
						<Button
							slot="button"
							variant={'primary'}
							type={'solid'}
							nativeType={'submit'}
							size={'s'}
							wcagLabel={ts('akce.vyhledat-na-po')}>
							<GovIcon
								slot="left-icon"
								name="search"></GovIcon>
						</Button>
					</GovFormSearch>
				</>
			</PoFormWeb>
		</FormProvider>
	);
};
