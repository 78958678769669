import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NonUndefined, UseFormReturn } from 'react-hook-form';
import {
	addressQuery,
	AgendyAisAdresaSeznamDto,
	ResponseError,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';

interface AddressData {
	nazevObce?: string;
	cislo?: string;
	nazevUlice?: string;
	nazevObceDorucovaciAdresa?: string;
	cisloDorucovaciAdresa?: string;
	nazevUliceDorucovaciAdresa?: string;
}

export const useAddressSearch: (props: {
	formMethods: UseFormReturn<AddressData>;
	onSuccess?: () => void;
	onError?: () => void;
}) => AddressData & {
	mainQuery: UseQueryResult<
		AgendyAisAdresaSeznamDto,
		AxiosError<ResponseError>
	>;
	deliveryAddressQuery: UseQueryResult<
		AgendyAisAdresaSeznamDto,
		AxiosError<ResponseError>
	>;
	address: NonUndefined<AgendyAisAdresaSeznamDto['seznam']>;
	deliveryAddress: NonUndefined<AgendyAisAdresaSeznamDto['seznam']>;
} & {
	isNotNazevOrCislo: boolean;
	disableSearchButton: boolean;
	disableDeliveryAddressSearchButton: boolean;
} = (props: {
	formMethods: UseFormReturn<AddressData>;
	onSuccess?: () => void;
	onError?: () => void;
}) => {
	const nazevObce = props.formMethods.watch('nazevObce');
	const cislo = props.formMethods.watch('cislo');
	const nazevUlice = props.formMethods.watch('nazevUlice');

	const nazevObceDorucovaciAdresa = props.formMethods.watch(
		'nazevObceDorucovaciAdresa'
	);
	const cisloDorucovaciAdresa = props.formMethods.watch(
		'cisloDorucovaciAdresa'
	);
	const nazevUliceDorucovaciAdresa = props.formMethods.watch(
		'nazevUliceDorucovaciAdresa'
	);

	const isNotNazevOrCislo = !nazevObce || !cislo;
	const isNotNazevOrCisloDelivery =
		!nazevObceDorucovaciAdresa || !cisloDorucovaciAdresa;

	const shouldSearchButtonBeDisabled = (
		cislo: string,
		isNotNazevOrCislo: boolean
	) => {
		const containsOnlyNumbers = /^\d+$/.test(cislo);
		const cisloLength = cislo.length;
		return (
			(!containsOnlyNumbers && cisloLength > 0) ||
			cisloLength > 4 ||
			isNotNazevOrCislo
		);
	};

	const disableSearchButton = shouldSearchButtonBeDisabled(
		cislo ?? '',
		isNotNazevOrCislo
	);
	const disableDeliveryAddressSearchButton = shouldSearchButtonBeDisabled(
		cisloDorucovaciAdresa ?? '',
		isNotNazevOrCisloDelivery
	);

	const combinedQueries = usePoQueries<
		[
			UseQueryResult<AgendyAisAdresaSeznamDto, AxiosError<ResponseError>>,
			UseQueryResult<AgendyAisAdresaSeznamDto, AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['address', nazevObce, cislo, nazevUlice],
				queryFn: async () => {
					const response = await addressQuery(nazevObce, cislo, nazevUlice);
					return response;
				},
				onError: props?.onError,
				onSuccess: props?.onSuccess,
				enabled: false,
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: [
					'delivery-address',
					nazevObceDorucovaciAdresa,
					cisloDorucovaciAdresa,
					nazevUliceDorucovaciAdresa,
				],
				queryFn: async () => {
					const response = await addressQuery(
						nazevObceDorucovaciAdresa,
						cisloDorucovaciAdresa,
						nazevUliceDorucovaciAdresa
					);
					return response;
				},
				onError: props?.onError,
				onSuccess: props?.onSuccess,
				enabled: false,
				retry: 0,
				refetchOnWindowFocus: false,
			},
		],
	});

	return {
		mainQuery: combinedQueries[0] as UseQueryResult<
			AgendyAisAdresaSeznamDto,
			AxiosError<ResponseError>
		>,
		deliveryAddressQuery: combinedQueries[1] as UseQueryResult<
			AgendyAisAdresaSeznamDto,
			AxiosError<ResponseError>
		>,
		address: combinedQueries[0].data?.seznam ?? [],
		deliveryAddress: combinedQueries[1].data?.seznam ?? [],
		nazevUlice,
		nazevObce,
		cislo,
		nazevObceDorucovaciAdresa,
		cisloDorucovaciAdresa,
		nazevUliceDorucovaciAdresa,
		isNotNazevOrCislo,
		disableSearchButton,
		disableDeliveryAddressSearchButton,
	};
};
