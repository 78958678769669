import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { axiosInstance } from '../axios';
import {
	DokumentyDokumentDto,
	DokumentyDokumentSeznamDto,
	DokumentyStrankovaniDto,
	PodaniPodaniInstanceDto,
} from '../generated';
import {
	DocumentDeleteParams,
	DocumentRestoreParams,
	DocumentUploadParams,
} from '../types/queryTypes';

export const documentsQuery = async <T extends DokumentyStrankovaniDto>({
	...params
}: T): Promise<DokumentyDokumentSeznamDto> => {
	govApiLog('/api/v1/dokumenty');
	const { data } = await axiosInstance.get('/api/v1/dokumenty', { params });
	return data;
};

export const documentCategoriesQuery = async (): Promise<
	Nullable<string>[]
> => {
	govApiLog('/api/v1/dokumenty/kategorie');
	const { data } = await axiosInstance.get('/api/v1/dokumenty/kategorie');
	return data;
};

export const downloadDocumentQuery = async (
	documentId: number
): Promise<AxiosResponse<Blob>> => {
	return axiosInstance.get(`/api/v1/dokumenty/${documentId}/stahnout`, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'blob',
	});
};

export const lastDocumentsQuery = async <Params>(
	params: Params
): Promise<AxiosResponse<PodaniPodaniInstanceDto>> => {
	return axiosInstance.post('/api/v1/podaniinstance/posledni', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

interface documentsListQueryParams {
	jeSoubor?: true;
	kategorie?: string;
	smazano?: boolean;
	razeni?: string;
	start?: number;
	pocet?: number;
}

export const documentsListQuery = async <
	Params extends documentsListQueryParams
>(
	params?: Params
): Promise<DokumentyDokumentSeznamDto['seznam']> => {
	const { data } = await axiosInstance.get('/api/v1/dokumenty', { params });

	return data?.seznam || [];
};
export const documentQuery = async (
	documentId: number
): Promise<DokumentyDokumentDto> => {
	const { data } = await axiosInstance.get(`/api/v1/dokumenty/${documentId}`);

	return data;
};

export const documentsPreviewQuery = async (
	documentId: number
): Promise<Blob> => {
	const { data } = await axiosInstance.get(
		`/api/v1/dokumenty/${documentId}/nahled`,
		{
			responseType: 'blob',
		}
	);
	return data;
};

export const updateDocumentQuery = async <Body>(
	dokumentId: number,
	body: Body
): Promise<void> => {
	const { data } = await axiosInstance.post(
		`/api/v1/dokumenty/${dokumentId}`,
		body,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
	return data;
};

export const createDocumentQuery = async <Body>(body: Body): Promise<void> => {
	const { data } = await axiosInstance.post(`/api/v1/dokumenty/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return data;
};

export const documentDeleteQuery = async ({
	id,
	permanent = false,
}: DocumentDeleteParams): Promise<AxiosResponse> => {
	return axiosInstance.delete(`/api/v1/dokumenty/${id}`, {
		params: {
			tvrde: permanent,
		},
	});
};

export const documentRestoreQuery = async ({
	id,
}: DocumentRestoreParams): Promise<AxiosResponse> => {
	return axiosInstance.put(`/api/v1/dokumenty/${id}/obnovit`);
};

export const documentUploadQuery = async (
	params: DocumentUploadParams
): Promise<AxiosResponse> => {
	govApiLog('/api/v1/dokumenty');
	return axiosInstance.post('/api/v1/dokumenty', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
