import { createContext, PropsWithChildren, useContext } from 'react';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { NastaveniKontaktniUdajeROSContextValue } from './context.types';
import { useROSContact } from './useROSContact';

export const NastaveniKontaktniUdajeROSContext =
	createContext<Nullable<NastaveniKontaktniUdajeROSContextValue>>(null);

type NastaveniKontaktniUdajeROSContextProviderProps = PropsWithChildren<{
	code: PageCode;
}>;

export const NastaveniKontaktniUdajeROSContextProvider = ({
	children,
}: NastaveniKontaktniUdajeROSContextProviderProps) => {
	const { isLoading, error, rosContacts } = useROSContact();

	return (
		<NastaveniKontaktniUdajeROSContext.Provider
			value={{
				initialState: {
					isLoading,
					error,
				},
				rosContacts,
			}}>
			{children}
		</NastaveniKontaktniUdajeROSContext.Provider>
	);
};

export const useNastaveniKontaktnichUdajuROSContext =
	(): NastaveniKontaktniUdajeROSContextValue =>
		useContext(
			NastaveniKontaktniUdajeROSContext
		) as NastaveniKontaktniUdajeROSContextValue;
