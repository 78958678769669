import { useState } from 'react';
import { Optional } from '@gov-nx/core/types';

export const useMap = <Key, Value>() => {
	const [state, setState] = useState({ map: new Map<Key, Value>() });

	return {
		get: (id: Key): Optional<Value> => state.map.get(id),
		set: (key: Key, value: Value) =>
			setState(({ map }) => ({ map: map.set(key, value) })),
	};
};
