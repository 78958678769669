import {
	GovFormControl,
	GovFormFile,
	GovFormGroup,
	GovIcon,
} from '@gov-design-system-ce/react';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileState, useFileUpload } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	usePoFormContext,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { propEq } from '@gov-nx/core/types';
import { WebFileProps } from '@gov-nx/ui/types';
import { formatBytes } from '@gov-nx/utils/common';
import { Button } from '../../Button/Button';
import { LayoutGap } from '../../Layout/LayoutGap';
import { FormLabel } from '../FormLabel';
import { FormErrorMessage, FormMessage } from '../FormMessage';
import { FormUploadAttachments } from './FormUploadAttachments';

export const FormFileUpload = forwardRef<unknown, WebFileProps>(
	({ options, field, label, message, group, isSubmited, control }, ref) => {
		const { getLocalizeCurried } = useTranslationWithNamespace();
		const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
		const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
		const formContext = useFormContext();
		const value = formContext.getValues(field.name);
		const extraProps = usePoFormContext().propsFromSchema(field.name);
		const { files, addFiles, removeFile, setFiles } = useFileUpload(options);

		useEffect(() => {
			const filesToValue = files.filter(propEq('state', FileState.success));
			formContext.setValue(
				field.name,
				filesToValue,
				filesToValue.length ? { shouldValidate: true } : {}
			);
		}, [files]);

		useEffect(() => {
			if (isSubmited) {
				setFiles([]);
			}
		}, [isSubmited]);

		useEffect(() => {
			if (Array.isArray(value)) {
				setFiles(value);
			}
		}, []);

		useImperativeHandle(
			ref,
			() => {
				return {
					removeFile,
					filesUploading: () =>
						files.some((file) => file.state === FileState.uploading),
					filesValidating: () =>
						files.some((file) => file.state === FileState.validating),
					filesUploaded: () =>
						files.every((file) => file.state === FileState.success) &&
						files.length > 0,
				};
			},
			[removeFile]
		);

		return (
			<Controller
				name={field.name}
				control={formContext.control}
				render={({ field: renderField, fieldState }) => {
					const canAdd = files.length < options.maxAttachments;

					const isDisabled = !canAdd || field.disabled;
					return (
						<div>
							<GovFormControl {...control}>
								{label ? (
									<FormLabel
										slot="top"
										required={extraProps.required}
										{...label}>
										{label.children}
									</FormLabel>
								) : null}
								<GovFormGroup {...group}>
									<GovFormFile
										ref={renderField.ref}
										invalid={fieldState.invalid}
										name={field.name}
										required={extraProps.required}
										expanded={field.expanded}
										disabled={isDisabled}
										accept={options.extensions.join(',')}
										maxFileSize={options.maxFileSize}
										multiple={options.multiple}
										onGov-files={({ detail }) => addFiles(detail.files ?? [])}
										resetAfterPick={true}
										displayAttachments={false}>
										<LayoutGap gap={2}>
											<p className={'text-m'}>
												{ts('nahrani-souboru.pretahnete-soubor-nebo')}
											</p>
											<p aria-hidden="true">
												<Button
													disabled={isDisabled}
													wcagLabel={ts(
														'nahrani-souboru.nahrat-soubor-ze-zarizeni'
													)}
													variant={'primary'}
													type={'outlined'}>
													<GovIcon
														slot="left-icon"
														name="upload"></GovIcon>
													{ts('nahrani-souboru.nahrat-soubor-ze-zarizeni')}
												</Button>
											</p>
											{options.extensions.length ? (
												<p className="gov-text--s">
													{t('nahrani-souboru.pocet-velikost-souboru', {
														namespace: LocalizeNameSpaceTypes.Form,
														count: options.maxAttachments,
														sumSize: formatBytes(options.maxSumFileSize),
													})}
													<br />
													{t('nahrani-souboru.podporovane-formaty', {
														namespace: LocalizeNameSpaceTypes.Form,
														extensions: options.extensions
															.map((ext) => ext.replace('.', '').toUpperCase())
															.join(', '),
													})}
												</p>
											) : null}
										</LayoutGap>
									</GovFormFile>
								</GovFormGroup>
								<div
									slot="bottom"
									className={'!mt-0'}>
									{fieldState.error && (
										<FormErrorMessage error={fieldState.error} />
									)}
									{message && <FormMessage messages={message.messages} />}
								</div>
							</GovFormControl>

							<FormUploadAttachments
								onRemove={removeFile}
								files={files}
							/>
						</div>
					);
				}}
			/>
		);
	}
);
