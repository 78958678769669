import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
	DokladyDokladDto,
	DokladyDokladSeznamDto,
	OsobyFyzickaOsobaDto,
	OsobySouhlasDto,
	OsobySouhlasSeznamDto,
	consentPreviewsQuery,
	identityDocumentQuery,
	robPersonQuery,
	sendSubmissionQuery,
	usePoMutation,
	usePoQueries,
	ResponseError,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable, is, compose } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import {
	ConsentManagementBadge,
	ConsentManagementDetailContextTypes,
	ConsentManagementDetailProcessControl,
	ConsentManagementView,
	ConsentRequest,
} from './context.types';
import { prepareSubmitData } from './utils';

const SpravaSouhlasuDetailContext =
	createContext<Nullable<ConsentManagementDetailContextTypes>>(null);

interface SpravaSouhlasuDetailContextProviderProps {
	consentId: number;
	children: React.ReactNode;
	code: PageCode;
}

export function ConsentManagementDetailContextProvider({
	children,
	code,
	consentId,
}: SpravaSouhlasuDetailContextProviderProps) {
	const { controls, setControls } =
		useProcessControl<ConsentManagementDetailProcessControl>({
			initialLoading: true,
			displayModal: false,
			modalProcessLoading: false,
			downloadError: null,
			downloadLoading: false,
		});
	const { downloadBlob } = useDownloadEvents();
	const { toastMessage } = useMessageEvents();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const navigate = useNavigate();

	const tsn = getLocalizeCurried(code);
	const [view, setView] = useState<ConsentManagementView>({
		prijemce: true,
		zadatel: false,
		dalsiInformace: false,
		referencniUdaje: true,
		nereferencniUdaje: false,
	});
	const setNewView = (newView: Partial<ConsentManagementView>) =>
		setView({ ...view, ...newView });
	const [consentDetail, setConsentDetail] = useState<OsobySouhlasDto[]>([]);
	const [person, setPerson] = useState<Nullable<OsobyFyzickaOsobaDto>>(null);
	const [idDocuments, setIdDocuments] = useState<DokladyDokladDto[]>([]);

	const [filteredDetail] = consentDetail.filter(
		(item) => item.id === consentId
	);
	const [consentRequestObject, setConsentRequestObject] = useState<
		ConsentRequest | undefined
	>();

	const individualBadges: ConsentManagementBadge[] = [
		{
			name: tsn('odznak.prijemce-udaju'),
			isActive: view.prijemce === true,
			viewFunction: () =>
				setNewView({ prijemce: true, zadatel: false, dalsiInformace: false }),
		},
		{
			name: tsn('odznak.zadatel'),
			isActive: view.zadatel === true,
			viewFunction: () =>
				setNewView({ zadatel: true, dalsiInformace: false, prijemce: false }),
		},
		{
			name: tsn('odznak.dalsi-informace'),
			isActive: view.dalsiInformace === true,
			viewFunction: () =>
				setNewView({ dalsiInformace: true, zadatel: false, prijemce: false }),
		},
	];

	const refInfoBadges: ConsentManagementBadge[] = [
		{
			name: tsn('odznak.referencni-udaje'),
			isActive: view.referencniUdaje === true,
			viewFunction: () =>
				setNewView({ referencniUdaje: true, nereferencniUdaje: false }),
		},
		{
			name: tsn('odznak.nereferencni-udaje'),
			isActive: view.nereferencniUdaje === true,
			viewFunction: () =>
				setNewView({ nereferencniUdaje: true, referencniUdaje: false }),
		},
	];

	const mainQueries = usePoQueries<
		[
			UseQueryResult<OsobySouhlasSeznamDto, AxiosError<ResponseError>>,
			UseQueryResult<OsobyFyzickaOsobaDto, AxiosError<ResponseError>>,
			UseQueryResult<DokladyDokladSeznamDto, AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['souhlasy'],
				queryFn: consentPreviewsQuery,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
			},
			{
				queryKey: ['fyzicke-osoby'],
				queryFn: robPersonQuery,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
				refetchOnWindowFocus: false,
				retry: 0,
			},
			{
				queryKey: ['doklady'],
				queryFn: identityDocumentQuery,
				onError: (error: Error) =>
					setControls({ initialError: new GovError(error.message) }),
				refetchOnWindowFocus: false,
				retry: 0,
			},
		],
	});

	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);

	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const consents = queriesResponses[0]
				? (queriesResponses[0] as OsobySouhlasSeznamDto)
				: null;
			const robPerson = queriesResponses[1]
				? (queriesResponses[1] as OsobyFyzickaOsobaDto)
				: null;
			const identityDocuments = queriesResponses[2]
				? (queriesResponses[2] as AxiosResponse<DokladyDokladSeznamDto>)
				: null;

			setConsentDetail(consents?.seznam || []);
			setPerson(robPerson);
			setIdDocuments(identityDocuments?.data.seznam || []);
			setControls({ initialLoading: false });
		}
	}, [isQueriesFetched]);

	useEffect(() => {
		if (filteredDetail && idDocuments[0]) {
			setConsentRequestObject({
				kodPodani: 'ZR10_ODVOLANI',
				souhlasId: filteredDetail.id,
				nazevOsoby: filteredDetail.nazevOsoby,
				datovaSchrankaId: filteredDetail.datovaSchrankaId,
				typCisloID: idDocuments[0].cisloDokladu,
			});
		}
	}, [filteredDetail, idDocuments]);

	const handleDownload = useCallback(async () => {
		setControls({
			downloadError: null,
			downloadLoading: true,
			processLoading: true,
		});
		return downloadBlob({
			data: consentRequestObject && prepareSubmitData(consentRequestObject),
			onSuccess: () => {
				setControls({ downloadLoading: false, processLoading: false });
			},
			onError: (error) => {
				setControls({
					downloadError: error,
					downloadLoading: false,
					processLoading: false,
				});
			},
		});
	}, [setControls, downloadBlob, consentRequestObject]);

	const submitMutation = usePoMutation<
		AxiosResponse<number, void>,
		ConsentRequest
	>({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			toastMessage({
				options: {
					variant: 'error',
					type: 'solid',
					icon: {
						name: 'exclamation-triangle-fill',
						type: 'basic',
					},
				},
				content: tsn('formular.zprava.chyba'),
			});
			setControls({ processError: error, modalProcessLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: tsn('formular.zprava.souhlas-odvolan'),
			});
			setControls({ modalProcessLoading: false, displayModal: false });
			navigate(-1);
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, modalProcessLoading: true });
		consentRequestObject && submitMutation.mutate(consentRequestObject);
	}, [setControls, consentRequestObject, submitMutation]);

	return (
		<SpravaSouhlasuDetailContext.Provider
			value={{
				controls,
				setControls,
				handleDownload,
				handleSubmit,
				individualBadges,
				refInfoBadges,
				filteredDetail,
				view,
				person,
				idDocuments,
			}}>
			{children}
		</SpravaSouhlasuDetailContext.Provider>
	);
}

export const useSpravaSouhlasuDetailContext =
	(): ConsentManagementDetailContextTypes =>
		useContext(
			SpravaSouhlasuDetailContext
		) as ConsentManagementDetailContextTypes;
