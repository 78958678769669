import { createContext, useContext, useState } from 'react';
import { agendasQuery, aisQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { Nullable, pipe } from '@gov-nx/core/types';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import {
	AisItem,
	KdeOMneStatVedeUdajeContextProviderProps,
	KdeOMneStatVedeUdajeContextValue,
	KdeOMneStatVedeUdajeData,
} from './context.types';
import { prepareAgendasResponse } from './utils';

const KdeOMneStatVedeUdajeContext =
	createContext<Nullable<KdeOMneStatVedeUdajeContextValue>>(null);

export const KdeOMneStatVedeUdajeContextProvider = ({
	children,
}: KdeOMneStatVedeUdajeContextProviderProps) => {
	const [expandedAis, setExpandedAis] = useState<AisItem['kodAis']>();
	const { environment: env } = useConfigurationStore();

	const sliceDataOnLclAndAce = (data: KdeOMneStatVedeUdajeData) => {
		if (!data) return null;
		if (['lcl', 'ace'].includes(`${env}`)) {
			return data?.slice(0, 3);
		}
		return data;
	};

	const query = usePoQuery({
		queryKey: ['agendy-ais'],
		queryFn: agendasQuery,
		refetchOnWindowFocus: false,
	});

	const aisDataQuery = usePoQuery({
		queryKey: ['agendy-ais', expandedAis],
		queryFn: async () => aisQuery(expandedAis ?? null),
		refetchOnWindowFocus: false,
		enabled: !!expandedAis,
	});

	const toggleAis = (kodAis?: string) => {
		if (!kodAis || kodAis === expandedAis) {
			setExpandedAis(undefined);
		} else {
			setExpandedAis(kodAis);
		}
	};

	return (
		<KdeOMneStatVedeUdajeContext.Provider
			value={{
				data: pipe(
					query.data?.seznam ?? [],
					prepareAgendasResponse,
					sliceDataOnLclAndAce
				),
				controls: {
					initialLoading: query.isLoading,
					initialError: query.error ? new GovError(query.error.message) : null,
					processLoading: false,
					processError: null,
				},
				expandedAis,
				toggleAis,
				aisData: aisDataQuery.data,
			}}>
			{children}
		</KdeOMneStatVedeUdajeContext.Provider>
	);
};

export const useKdeOMneStatVedeUdajeContext = () =>
	useContext(KdeOMneStatVedeUdajeContext) as KdeOMneStatVedeUdajeContextValue;
