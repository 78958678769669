import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useApplicantLoader, useDownloadBlob } from '@gov-nx/core/hooks';
import { getRequiredDataBoxes } from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextTypes } from './service.types';
import { filterDataBoxTypes } from './utils';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();
	const { handleDownloadBlob } = useDownloadBlob();
	const applicantLoader = useApplicantLoader();
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const formDefinition = FormInstance({ code, requiredDataBoxes });

	const submitMutation = usePoMutation({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: () => {
			formDefinition.formReset();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('form.messages.odeslano', { namespace: code }),
			});

			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, submitMutation]);

	const downloadBlob = handleDownloadBlob(() =>
		prepareSubmitData(formDefinition.formMethods.getValues())
	);

	const handleDownload = () => {
		downloadBlob.download(
			() => formDefinition.formReset(),
			() => formDefinition.formReset()
		);
	};

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);
	const onDownload = formDefinition.formMethods.handleSubmit(handleDownload);

	const typOsoby = formDefinition.formMethods.watch('typOsoby');

	return (
		<ServiceContext.Provider
			value={{
				formDefinition,
				controls: {
					initialError: applicantLoader.error
						? new GovError(applicantLoader.error.message)
						: null,
					initialLoading: applicantLoader.isLoading ?? false,

					processError: submitMutation.error,
					processLoading: submitMutation.isLoading,

					isDownloading: downloadBlob.isDownloading,
					downloadError: downloadBlob.error ?? null,
				},
				onSubmit,
				onDownload,
				requiredDataBoxes,
				dataBoxTypes: filterDataBoxTypes(requiredDataBoxes, typOsoby),
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const ZadostOOsvedceniDIGUkonuContextInstance = () =>
	useContext(ServiceContext) as ServiceContextTypes;
