import { GovIcon } from '@gov-design-system-ce/react';
import { Trans, useTranslation } from 'react-i18next';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxConfirmModalType,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

const Icon = () => {
	return (
		<GovIcon
			name="exclamation-triangle-fill"
			slot="icon"
			className={'text-error-400'}
		/>
	);
};

const CancelButton = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tnGlobal = getLocalizeCurried('global');

	const { setConfirmModal } = useDataBoxMessageListContext();
	return (
		<Button
			nativeType="button"
			variant="primary"
			type="base"
			slot="actions"
			onClick={() => setConfirmModal(null)}
			wcagLabel={tnGlobal('akce.zrusit')}>
			{tnGlobal('akce.zrusit')}
		</Button>
	);
};

export const DataBoxMessageListConfirmModal = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);

	const {
		messagesSelect: { selectedMessageList },
		messageListCount,
		massOperations,
		confirmModal,
		setConfirmModal,
		loadingItems,
	} = useDataBoxMessageListContext();

	return (
		<>
			{confirmModal === DataBoxConfirmModalType.emptyTrash && (
				<Prompt
					label={tn('zpravy.vypis.trvale-smazani-pocet', {
						pocet: messageListCount,
					})}
					open={
						!!confirmModal &&
						[DataBoxConfirmModalType.emptyTrash].includes(confirmModal)
					}
					onGov-close={() => setConfirmModal(null)}>
					<Icon />
					<p className={'mb-3 text-l text-secondary-800'}>
						{tn('zpravy.vypis.opravdu-trvale-smazat-zobrazene-kopie-pocet', {
							pocet: messageListCount,
						})}
					</p>
					<p>{tn('zpravy.vypis.trvale-odstranite-zobrazene-kopie')}</p>
					<CancelButton />
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="actions"
						loading={loadingItems.removePermanently}
						onClick={() => massOperations.handleEmptyTrash()}
						disabled={!!loadingItems.removePermanently}
						wcagLabel={tn('zpravy.trvale-smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							{tn('zpravy.trvale-smazat')}
						</span>
					</Button>
				</Prompt>
			)}

			{confirmModal === DataBoxConfirmModalType.removeMessages && (
				<Prompt
					label={tn('zpravy.vypis.smazani-kopii-pocet', {
						pocet: selectedMessageList.length,
					})}
					open={
						!!confirmModal &&
						[DataBoxConfirmModalType.removeMessages].includes(confirmModal)
					}
					onGov-close={() => setConfirmModal(null)}>
					<Icon />
					<p className={'mb-3 text-l text-secondary-800'}>
						{tn('zpravy.vypis.opravdu-smazat-kopie-pocet', {
							pocet: selectedMessageList.length,
						})}
					</p>
					<p className={'mb-6'}>
						<Trans
							t={t}
							i18nKey="zpravy.vypis.smazanim-do-kose"
							components={{ strong: <strong /> }}
						/>
					</p>
					<p>{tn('zpravy.vypis.kopie-muzete-trvale-smazat')}</p>
					<CancelButton />
					<Button
						nativeType="button"
						variant="error"
						type="outlined"
						slot="actions"
						loading={loadingItems.removePermanently}
						onClick={() => massOperations.handleDeleteMessages(true)}
						disabled={!!loadingItems.removePermanently}
						wcagLabel={tn('zpravy.trvale-smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{tn('zpravy.trvale-smazat')}
						</span>
					</Button>
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="actions"
						loading={loadingItems.remove}
						onClick={() => massOperations.handleDeleteMessages(false)}
						disabled={!!loadingItems.remove}
						wcagLabel={tn('zpravy.smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{tn('zpravy.smazat')}
						</span>
					</Button>
				</Prompt>
			)}

			{confirmModal === DataBoxConfirmModalType.removeMessagesPermanently && (
				<Prompt
					label={tn('zpravy.vypis.trvale-smazani-pocet', {
						pocet: selectedMessageList.length,
					})}
					open={
						!!confirmModal &&
						[DataBoxConfirmModalType.removeMessagesPermanently].includes(
							confirmModal
						)
					}
					onGov-close={() => setConfirmModal(null)}>
					<Icon />
					<p className={'mb-3 text-l text-secondary-800'}>
						{tn('zpravy.vypis.opravdu-trvale-smazat-kopie-pocet', {
							pocet: selectedMessageList.length,
						})}
					</p>
					<p>{tn('zpravy.vypis.trvale-odstranite-vybrane.kopie')}</p>
					<CancelButton />
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="actions"
						loading={loadingItems.removePermanently}
						onClick={() => massOperations.handleDeleteMessages(true)}
						disabled={!!loadingItems.removePermanently}
						wcagLabel={tn('zpravy.trvale-smazat')}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							{tn('zpravy.trvale-smazat')}
						</span>
					</Button>
				</Prompt>
			)}
		</>
	);
};
