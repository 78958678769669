import { VypisyudajuSouboryUlozkaDto } from '@gov-nx/api/portal-obcana';
import { isArray, isObject, Optional } from '@gov-nx/core/types';
import { toDateReadable } from '@gov-nx/utils/common';
import { DataResponse, DocumentsDetails, Location } from './context.types';
import {
	DataRequestBody,
	DataZdravDokumentaceRequest,
	formatedDocuments,
	SubmitObject,
	ZdravDokumentaceZdrojUdaju,
} from './utils.types';

export const getFormatedDocuments = (
	responseData: DataResponse
): formatedDocuments[] => {
	const location =
		responseData.Data[0]?.r?.patientSummaryOdpoved?.kontextData?.location;
	const token =
		responseData.Data[0]?.r?.patientSummaryOdpoved?.kontextData?.traceToken
			.xVal;

	if (isArray(location)) {
		return prepareDocuments(location, token);
	} else if (location && isObject(location)) {
		return [prepareDocumentsFromObj(location, token)];
	} else {
		return [];
	}
};

const prepareDocumentsFromObj = (
	obj: Location,
	traceToken?: string
): formatedDocuments => {
	return {
		sourceIdentifier: obj.sourceIdentifier.xVal,
		sourceName: obj.sourceName.xVal,
		sourceIco: obj.sourceIco.xVal,
		effectiveTime: toDateReadable(obj.effectiveTime.xVal),
		ehealthDocumentId: obj.ehealthDocumentId.xVal,
		ehealthDocumentOid: obj.ehealthDocumentOid.xVal,
		traceToken: traceToken ?? '',
	};
};

export const prepareDocuments = (
	documentsPath: Location[],
	documentsTraceToken?: string
): formatedDocuments[] =>
	documentsPath.map((location: Location) =>
		prepareDocumentsFromObj(location, documentsTraceToken)
	);

export const prepareSubmitData = (
	postData: DataRequestBody
): DataZdravDokumentaceRequest => {
	return {
		body: {
			zdrojUdaju: postData.zdrojUdaju,
			format: postData.format,
			operace: postData.operace,
			sourceIco: postData.sourceIco,
			sourceIdentifier: postData.sourceIdentifier,
			ehealthDocumentId: postData.ehealthDocumentId,
			ehealthDocumentOid: postData.ehealthDocumentOid,
			traceToken: postData.traceToken,
		},
	};
};

export const getSubmitObject = (data: DocumentsDetails): SubmitObject => {
	return {
		zdrojUdaju: ZdravDokumentaceZdrojUdaju.NCP_ZDRAVOTNICKA_DOC_PACIENTA,
		format: 'signed',
		sourceIco: data.sourceIco,
		sourceIdentifier: data.sourceIdentifier,
		ehealthDocumentId: data.ehealthDocumentId,
		ehealthDocumentOid: data.ehealthDocumentOid,
		traceToken: data.traceToken,
	};
};

export function getFileIdFromResponse(
	response: VypisyudajuSouboryUlozkaDto
): Optional<string> {
	return response?.soubory?.[0]?.id;
}
