import { DokumentyDokumentDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Button, FormCheckbox } from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';

interface DocumentTableNoFileRowProps {
	document: DokumentyDokumentDto;
}

export const TableRowNoFile = ({ document }: DocumentTableNoFileRowProps) => {
	const {
		controls,
		code,
		myFilesModals: { setNoFileModal },
	} = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	if (!document.dokumentId) {
		return null;
	}

	const createdAt =
		document.datumVytvoreni && toDateTimeReadable(document.datumVytvoreni);

	return (
		<tr
			key={document.dokumentId}
			className={'border-b border-secondary-500 [ last-of-type:border-b-0 ]'}>
			<td className={'py-6 px-0 align-top [ md:pl-4 ]'}>
				<div className={'break-words'}>
					<Button
						wcagLabel={tsn('akce.stahnout')}
						variant="primary"
						type="link"
						onClick={() => setNoFileModal(document)}
						className={'text-left'}>
						{document.nazev}
					</Button>
				</div>
				<div className={'mt-2 text-xs [ md:hidden md:mt-0 ]'}>{createdAt}</div>
			</td>
			<td
				className={
					'py-6 px-0 whitespace-nowrap align-top text-sm hidden [ md:table-cell md:pl-1 ]'
				}>
				{createdAt}
			</td>
			<td className={'py-6 px-0 align-top [ md:pl-1 ]'}>
				<div className={'flex justify-end items-start'}>
					<FormCheckbox
						field={{
							name: `selection.${document.dokumentId}`,
							disabled: controls.processLoading,
							noLabel: true,
						}}
					/>
				</div>
			</td>
		</tr>
	);
};
