import { axiosInstance } from '../axios';
import { CiselnikyCiselnikPolozkaSeznamDto } from '../generated';

const phoneCountryCodesQuery =
	async (): Promise<CiselnikyCiselnikPolozkaSeznamDto> => {
		const { data } = await axiosInstance.get(
			'/api/v1/ciselniky/PREDVOLBA_EU/polozky'
		);
		return data;
	};

export const getPhoneCountryCodesQuery = () => {
	return {
		queryKey: ['phone-country-codes'],
		queryFn: phoneCountryCodesQuery,
	};
};
