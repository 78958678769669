import { pubSubActionBridge } from '@gov-nx/core/app';
import { CmsActions, CmsExternalServiceModalSubscription } from './cms.types';

export enum CmsSubscriptionTypes {
	externalServiceModal = 'CMS_EXTERNAL_SERVICE_SUBSCRIPTION',
}

export const cmsExternalServiceModalSubscription = (
	payload: CmsExternalServiceModalSubscription['payload']
): CmsExternalServiceModalSubscription => ({
	type: CmsSubscriptionTypes.externalServiceModal,
	payload,
});

export const cmsEvents = pubSubActionBridge<CmsActions>();
