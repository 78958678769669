import React from 'react';
import { isVypisEgsbSubmission } from '@gov-nx/api/portal-obcana';
import { DownloadAttachmentListener } from '@gov-nx/component/common';
import { useLocale } from '@gov-nx/core/service';
import {
	PageCode,
	useMojePodaniDetailContextInstance,
} from '@gov-nx/module/page';
import { Button, InfoBanner } from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { EGSBSubmission } from './EGSBSubmission';
import { MySubmissionMessageList } from './MySubmissionMessageList';
import { MySubmissionsDetailSkeleton } from './MySubmissionsDetailSkeleton';

const Item = ({ label, date }: { label: string; date?: string }) => {
	return (
		<div className={'flex items-center'}>
			<dt className={'mr-1'}>{label}</dt>
			<dd>
				<strong>{date ? toDateTimeReadable(date) : undefined}</strong>
			</dd>
		</div>
	);
};

export interface MySubmissionDetailProps {
	code: PageCode;
}

export const MySubmissionDetail = ({ code }: MySubmissionDetailProps) => {
	const { submission, controls, messages, documents, strings } =
		useMojePodaniDetailContextInstance();
	const { t: ts } = useLocale(code);

	return (
		<CmsPageRender
			code={code}
			showDescription={false}
			override={{
				name: strings.submission.name,
			}}>
			{controls.initialLoading ? (
				<MySubmissionsDetailSkeleton />
			) : (
				<>
					<DownloadAttachmentListener />
					<dl>
						<Item
							label={ts('formular.pole.datum-odesilani')}
							date={submission?.datumOdeslani}
						/>
						<Item
							label={ts('formular.pole.datum-vyrizeni')}
							date={submission?.lhutaVyrizeni}
						/>
						<dt>
							{submission?.automatickeZpracovani
								? `${ts('moje-podani.automaticke-zpracovani')}`
								: null}
						</dt>
					</dl>

					{documents.map((document) => {
						return (
							<Button
								key={document.documentId}
								wcagLabel={ts('akce.wcag.stahnout-vypis')}
								type={'solid'}
								variant={'primary'}
								size={'m'}
								loading={document.isDownloading}
								disabled={document.isDownloading}
								onClick={document.onDownload}>
								{ts('akce.stahnout-vypis')}
							</Button>
						);
					})}

					{submission && isVypisEgsbSubmission(submission) && (
						<EGSBSubmission submission={submission} />
					)}

					<h2 className={'text-xl'}>
						{ts('moje-podani.komunikace-v-ramci-podani')}
					</h2>
					{messages.length && submission ? (
						<MySubmissionMessageList code={code} />
					) : (
						<InfoBanner
							icon={{ name: 'empty-file', type: 'colored' }}
							type="secondary"
							className={'!px-0 !py-4'}>
							<p className={'text-2xl [ md:text-3xl ]'}>
								{ts('stav.chyba.zadne-datove-zpravy')}
							</p>
							<p>{ts('stav.chyba.nenasli-jsme-zadne-datove-zpravy')}</p>
						</InfoBanner>
					)}
				</>
			)}
		</CmsPageRender>
	);
};
