import {
	CiselnikyCiselnikPolozkaDto,
	CiselnikyVolbyDto,
	CiselnikyVolbyKoloDto,
} from '@gov-nx/api/portal-obcana';
import { getEntries, is, Optional } from '@gov-nx/core/types';
import { dataBoxParams, phoneShapeToString } from '@gov-nx/utils/common';
import { hasDatesProperties } from './FormStep1';
import {
	FormDataStep1,
	VotersCardDataRequest,
	VotersCardDataRequestZpusobPrevzeti,
	VotersCardFormData,
	ZpusobPrevzeti,
	ZpusobPrevzetiPosta,
	ZpusobPrevzetiUrad,
} from './context.types';
import { isTwoRoundedElection } from './utils';

export function convertToRoman(input: number): Optional<'I' | 'II'>;
export function convertToRoman(input: string): Optional<'I' | 'II'>;
export function convertToRoman(input: string | number): Optional<'I' | 'II'> {
	const num = typeof input === 'string' ? parseInt(input, 10) : input;
	switch (num) {
		case 1:
			return 'I';
		case 2:
			return 'II';
		default:
			return;
	}
}

const transformElectionRounds = (
	electionRound: FormDataStep1['electionRound']
): 'I' | 'II' | 'ALL' => {
	const rounds = getEntries(electionRound ?? {})
		.filter(([_, isChecked]) => isChecked)
		.map(([key]) => {
			return convertToRoman(key);
		})
		.filter(is);

	return rounds.length === 1 ? rounds[0] : 'ALL';
};

const getZpusobPrevzeti = (
	formData: VotersCardFormData
): VotersCardDataRequestZpusobPrevzeti => {
	if (formData.zpusobPrevzeti === ZpusobPrevzeti.URAD) {
		switch (formData.zpusobPrevzetiUrad) {
			case ZpusobPrevzetiUrad.URAD_ZAHRANICNI:
				return {
					zpusobPrevzeti: 'URAD_ZAHRANICNI',
					volitNaOkrskuVZahranici: formData.volitNaOkrskuVZahraniciId,
				};
			default:
				return { zpusobPrevzeti: 'URAD' };
		}
	}
	switch (formData.zpusobPrevzetiPosta) {
		case ZpusobPrevzetiPosta.JINA_NEZ_TRVALA_ADRESA:
			return {
				zpusobPrevzeti: 'POSTA_JINA_ADRESA',
				obec: formData.obec ?? '',
				psc: formData.cislo ?? '',
				ulice: formData.ulice ?? '',
				stat: formData.stat ?? '',
			};
		case ZpusobPrevzetiPosta.DORUCOVACI_ADRESA:
			return { zpusobPrevzeti: 'POSTA_DORUCOVACI_ADRESA' };
		default:
			return { zpusobPrevzeti: 'POSTA' };
	}
};

export const electionRoundDefaultValues = (
	rounds: CiselnikyVolbyKoloDto[]
): { [key: string]: boolean } => {
	return rounds
		.filter(hasDatesProperties)
		.map((round, index) => ({ ...round, kolo: round.kolo ?? index + 1 }))
		.reduce((all, round) => {
			return { ...all, [round.kolo]: round.lzePodatZadost ?? false };
		}, {} as { [key: string]: boolean });
};

export const prepareCheckData = (
	electionRound: VotersCardFormData['electionRound'],
	electionId: number,
	election?: CiselnikyVolbyDto
) => {
	return {
		params: { kontrola: true },
		body: {
			kodPodani: 'ZADOST_VOLEBNI_PRUKAZ',
			volbyId: electionId,
			kola: isTwoRoundedElection(election?.druh)
				? transformElectionRounds(electionRound)
				: undefined,
		},
	};
};

export const prepareSubmitData = (
	formData: VotersCardFormData,
	electionId: number,
	election?: CiselnikyVolbyDto,
	embassy?: CiselnikyCiselnikPolozkaDto
): VotersCardDataRequest => {
	return {
		params: { kontrola: false, ...dataBoxParams(formData) },
		body: {
			kodPodani: 'ZADOST_VOLEBNI_PRUKAZ',
			volbyId: electionId,
			kola: isTwoRoundedElection(election?.druh)
				? transformElectionRounds(formData.electionRound)
				: undefined,
			zapsanNaOkrskuVZahranici: formData.onSpecialList
				? embassy?.kod
				: undefined,
			email: formData._useEmail ? formData.email : undefined,
			telefon:
				formData._usePhone && formData.phone
					? phoneShapeToString(formData.phone)
					: undefined,
			...getZpusobPrevzeti(formData),
		},
	};
};

interface DownloadPowerOfAttorney {
	body: {
		kodPodani: 'ZADOST_VOLEBNI_PRUKAZ_PLNA_MOC';
		volbyId: number;
		kola: 'I' | 'II' | 'ALL';
		zpusobPrevzeti: string;
	};
}

export const prepareToDownloadPowerOfAttorney = (
	electionId: number,
	formData: VotersCardFormData
): DownloadPowerOfAttorney => {
	return {
		body: {
			kodPodani: 'ZADOST_VOLEBNI_PRUKAZ_PLNA_MOC',
			volbyId: electionId,
			kola: transformElectionRounds(formData.electionRound),
			zpusobPrevzeti: ZpusobPrevzeti.URAD,
		},
	};
};
