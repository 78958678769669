import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import {
	dataBoxParams,
	useDataBoxShape,
	useIcNumberShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { FormData, RequestData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getIcNumberShape } = useIcNumberShape();
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			ico: getIcNumberShape({ isRequired: true }),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			ico: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData): RequestData {
	return {
		params: dataBoxParams(formData),
		body: {
			typVypisu: 'INSOLVENCNI_REJSTRIK',
			data: {
				ico: formData.ico,
			},
		},
	};
}
