import { PeticePeticeDto } from '@gov-nx/api/portal-obcana';
import { dateDiffInDays } from '@gov-nx/utils/common';
import { EPetitionStatus } from '../enums';

export const isMonthBeforeClosureDate = (closureDate: string): boolean => {
	const closureDay = new Date(closureDate);
	const today = new Date();
	const difference = dateDiffInDays(closureDay, today);

	return difference < 30;
};

export const isPetitionEditable = (petition: PeticePeticeDto) => {
	return (
		petition.stav === EPetitionStatus.vytvorena ||
		petition.stav === EPetitionStatus.rozpracovana
	);
};
