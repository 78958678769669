import { UseQueryResult } from '@tanstack/react-query';
import { PodaniPodaniInstanceSeznamDto } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { FilterParam, FilterSortDirection } from '@gov-nx/core/types';
import { TimePeriod } from '@gov-nx/module/page';
import { SubmissionStatus } from './utils';

export interface MySubmissionsListControl extends ProcessControlCore {
	displayFilter: boolean;
}

interface MySubmission {
	id: number;
	name: string;
	status: SubmissionStatus;
	period?: TimePeriod;
}

export interface MySubmissionsListContext {
	controls: MySubmissionsListControl;
	submissions: MySubmission[];
	setControls: (control: Partial<MySubmissionsListControl>) => void;
	query: UseQueryResult<PodaniPodaniInstanceSeznamDto>;
	numberOfSubmissions: number;
	onSubmit: () => void;
	resetFilter: () => void;
	filter: MySubmissionsListFilter;
	setPartialFilter: (filter: Partial<MySubmissionsListFilter>) => void;
	currentPage: number;
	formDefinition: FormDefinition<MySubmissionListFilterForm>;
	goToPage: (page: number) => void;
	filterParams: FilterParam[];
}

export interface MySubmissionListFilterForm {
	datumVyrizeni?: Date;
	lhutaVyrizeni?: Date;
	vyrizeno?: Date;
	razeni?: MySubmissionListOrder;
	razeniSmer?: FilterSortDirection;
	hledat?: string;
}

export enum MySubmissionListOrder {
	datumOdeslani = 'datumOdeslani',
	datumVyrizeni = 'datumVyrizeni',
	lhutaVyrizeni = 'lhutaVyrizeni',
	'-datumOdeslani' = '-datumOdeslani',
	'-datumVyrizeni' = '-datumVyrizeni',
	'-lhutaVyrizeni' = '-lhutaVyrizeni',
}

export interface MySubmissionsListFilter extends MySubmissionListFilterForm {
	smazano: boolean;
	start: number;
	pocet: number;
}

export interface DataRequest {
	params: {
		datumVyrizeni?: string;
		lhutaVyrizeni?: string;
		vyrizeno?: string;
		razeni?: MySubmissionListOrder;
		start?: number;
		pocet?: number;
	};
}
