import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Optional } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { FiltersState } from './store.types';

const update = <T>(property: keyof T, value: object, object: T): T => {
	return {
		...object,
		[property]: { ...object[property], ...value },
	};
};

const updateByPath = <T extends object>(
	path: string[],
	value: object,
	obj: T
): T => {
	const [first, ...rest] = path;
	const key = first as keyof T;
	const toUpdate =
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		rest.length > 0 ? updateByPath(rest, value, obj ? obj[key] : {}) : value;

	return update(key, toUpdate, obj);
};

const initialState = {
	filters: {},
};

export const useFiltersStore = create<FiltersState>()(
	persist(
		(set, get) => ({
			...initialState,

			updateFiltersAction: (key: string, data: object) => {
				set((state: FiltersState) => ({
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					filters: update(key, data, state.filters),
				}));
			},

			updateFiltersWithPathAction: (key: string, data: object, path: string) =>
				set((state: FiltersState) => ({
					...updateByPath([key, path], data, state.filters),
				})),

			filtersResetAction: () => set(initialState),

			getFilters: <T extends object>(key: string) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				return get().filters?.[key] as Optional<T>;
			},
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.filters,
		}
	)
);
