import { LocalizationLanguage } from '@gov-nx/core/app';
import { Nullable, Optional } from '@gov-nx/core/types';
import { replaceVariablesInUrl } from '@gov-nx/utils/common';
import { PageCode } from './codes';
import PageList from './pages';

interface IndexStructureOfPage {
	[key: string]: PageCode;
}

let indexStructureOfPages: IndexStructureOfPage = {};

export interface PageListMapper {
	code: Nullable<PageCode>;
	locales: Record<
		string,
		{
			slug: string;
		}
	>;
	children: Record<string, PageListMapper>;
}

function convertToIndexStructure(
	data: PageListMapper,
	currentPath: string[] = []
) {
	let result: Record<string, PageCode> = {};
	for (const key in data.children) {
		if (Object.hasOwnProperty.call(data.children, key)) {
			const item = data.children[key];
			const newPath = [...currentPath, key];
			if (item && item.code !== null) {
				result[newPath.join('.')] = item.code;
			}
			if (item && item.children && Object.keys(item.children).length > 0) {
				const childrenResult = convertToIndexStructure(item, newPath);
				result = { ...result, ...childrenResult };
			}
		}
	}
	return result;
}

export function preparePageStructure() {
	indexStructureOfPages = convertToIndexStructure(PageList);
}

export const getPageUrlByCode = (
	code: PageCode,
	params: Nullable<Record<string, unknown>> = null,
	locale: LocalizationLanguage = LocalizationLanguage.Czech
): Nullable<string> => {
	const structure = getPageStructure(code);
	if (structure) {
		const link =
			'/' + structure.map((page) => page.locales[locale].slug).join('/');

		return replaceVariablesInUrl(link, params);
	}
	return null;
};

export const getPagePartSlug = (
	key: string,
	locale: LocalizationLanguage = LocalizationLanguage.Czech
): Optional<string> => {
	const structure = key
		.split('.')
		.reduce<Nullable<PageListMapper>>((page, prop) => {
			return page && page.children ? page.children[prop] : null;
		}, PageList);
	if (structure) {
		return structure.locales[locale].slug;
	}
	return undefined;
};

export const getPageStructure = (
	code: PageCode
): Nullable<PageListMapper[]> => {
	const key = getKeyOfPagesByCode(code);
	if (key) {
		let currentObjects: PageListMapper[] = [PageList];
		const objectArray: PageListMapper[] = [];
		key.split('.').forEach((prop) => {
			const nextObjects: PageListMapper[] = [];
			currentObjects.forEach((obj) => {
				if (obj.children && obj.children[prop]) {
					nextObjects.push(obj.children[prop]);
					objectArray.push(obj.children[prop]);
				}
			});
			currentObjects = nextObjects;
		});
		return objectArray;
	}
	return null;
};

const getKeyOfPagesByCode = (code: PageCode): Nullable<string> => {
	if (Object.keys(indexStructureOfPages).length) {
		return (
			Object.keys(indexStructureOfPages).find(
				(key) => indexStructureOfPages[key] === code
			) || null
		);
	}
	return null;
};
