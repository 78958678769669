import { useState } from 'react';
import { Optional } from '@gov-nx/core/types';
import { DataBoxFolderType } from '../providers/DataBoxes.types';
import { DataBoxListStatesType } from '../providers/MessageListContext.types';

interface MessagesFilterProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId?: number;
	listState: DataBoxListStatesType;
}

export function useMessagesFilter({
	dataBoxId,
	folderType,
	messageId,
	listState,
}: MessagesFilterProps) {
	const [query, setQuery] = useState<Optional<string>>(undefined);

	const hasSearch = [
		DataBoxListStatesType.Search,
		DataBoxListStatesType.SearchMassArchive,
		DataBoxListStatesType.SearchMassPermanentRemove,
		DataBoxListStatesType.SearchMassRemove,
		DataBoxListStatesType.SearchMassRestore,
	].includes(listState);

	return {
		dataBoxId,
		folderType,
		messageId,
		query,
		setQuery,
		hasSearch,
	};
}
