import { GovFormControl, GovFormGroup } from '@gov-design-system-ce/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppLanguage, useCountryCodes } from '@gov-nx/core/hooks';
import { usePoFormContext } from '@gov-nx/core/service';
import { WebPhoneNumberInputProps } from '@gov-nx/ui/types';
import { Input } from '../FormInput';
import { FormLabel } from '../FormLabel';
import { FormErrorMessage, FormMessage } from '../FormMessage';
import { Select } from '../FormSelect';

export const PhoneNumberInput = ({
	label,
	countryCodeLabel,
	message,
	control,
	slot,
	...props
}: WebPhoneNumberInputProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);
	const { language } = useAppLanguage();
	const { getOptions } = useCountryCodes();

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl
						{...control}
						slot={slot}>
						<div className={'flex flex-row gap-2'}>
							<div>
								{countryCodeLabel.children ? (
									<FormLabel
										slot="top"
										{...countryCodeLabel}>
										{countryCodeLabel.children}
									</FormLabel>
								) : null}
								<GovFormGroup {...props.group}>
									<Select
										name={`${field.name}.countryCode`}
										options={getOptions(language)}
										disabled={props.field.disabled}
										value={field.value.countryCode}
										onGov-blur={field.onBlur}
										onGov-change={(event) => {
											field.onChange({
												...field.value,
												countryCode: event.detail.value,
											});
										}}
									/>
								</GovFormGroup>
							</div>
							<div>
								{label.children ? (
									<FormLabel
										slot="top"
										required={extraProps.required}
										{...label}>
										{label.children}
									</FormLabel>
								) : null}
								<GovFormGroup {...props.group}>
									<Input
										{...extraProps}
										{...props.field}
										placeholder={props.field.placeholder}
										name={`${field.name}.number`}
										inputType="tel"
										ref={field.ref}
										invalid={fieldState.invalid}
										onGov-input={(event) => {
											field.onChange({
												...field.value,
												number: event.detail.value,
											});
										}}
										onGov-blur={field.onBlur}
										value={field.value.number}
									/>
								</GovFormGroup>
							</div>
						</div>
						<div
							slot="bottom"
							className={'!mt-0'}>
							{fieldState.error && (
								<FormErrorMessage error={fieldState.error} />
							)}
							{message && <FormMessage messages={message.messages} />}
						</div>
					</GovFormControl>
				);
			}}
		/>
	);
};
