import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';

export const DataBoxMessageFormSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap className={'mt-4 rounded-2xl'}>
			<div className="flex gap-4">
				<div className={'w-6 [ md:w-20 ]'}>
					<GovSkeleton
						width={'24px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<div className={'w-full'}>
					<GovSkeleton
						width={'100%'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</div>
			<div className="flex gap-4">
				<div className={'w-12 [ md:w-20 ]'}>
					<GovSkeleton
						width={'48px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<div className={'w-full'}>
					<GovSkeleton
						width={'100%'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</div>
			<div className="flex gap-4">
				<div className={'w-16 [ md:w-20 ]'}>
					<GovSkeleton
						width={'64px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<div className={'w-full'}>
					<GovSkeleton
						width={'100%'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-4 [ md:flex-row ]">
				<div className={'w-full [ md:w-60 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<div className={'w-full [ md:w-48 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</div>
			<GovSkeleton
				width={'100%'}
				height={'240px'}
				variant={'secondary'}
				wcagLabel={
					t('loading.wcag.processing', {
						namespace: LocalizeNameSpaceTypes.Global,
					}) as string
				}
			/>
			<div className="flex gap-4">
				<div className={'w-full [ md:w-28 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
				<GovSkeleton
					width={'110px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
					className={'hidden [ md:block ]'}
				/>
			</div>
		</LayoutGap>
	);
};
