import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import {
	dataBoxParams,
	useDataBoxShape,
	useIcNumberShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getIcNumberShape } = useIcNumberShape();
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			ico: getIcNumberShape({
				isRequired: true,
			}),
			druhSubjektu: yup
				.string()
				.oneOf(['Tuzemský subjekt', 'Zahraniční subjekt'])
				.required('Vyberte subjekt'),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			ico: undefined,
			druhSubjektu: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData): DataRequest {
	return {
		params: dataBoxParams(formData),
		body: {
			typVypisu: 'KVALIFIKOVANI_DODAVATELE',
			data:
				formData.druhSubjektu === 'Zahraniční subjekt'
					? {
							dodavatelZahranicniIco: formData.ico,
					  }
					: {
							dodavatelTuzemskyIco: formData.ico,
					  },
		},
	};
}
