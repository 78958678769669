import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ProfilCertificatesSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={3}>
			<div className={'hidden justify-between items-center pb-2 [ sm:flex ]'}>
				<div className={'w-1/2 mr-auto'}>
					<GovSkeleton
						width={'130px'}
						height={'26px'}
						variant={'secondary'}
						wcagLabel={tg('loading.wcag.processing')}
					/>
				</div>
				<div className={'mr-4 w-1/5 [ md:w-40 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'26px'}
						variant={'secondary'}
						wcagLabel={tg('loading.wcag.processing')}
					/>
				</div>
				<div className={'mr-4 w-1/5 [ md:w-40 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'26px'}
						variant={'secondary'}
						wcagLabel={tg('loading.wcag.processing')}
					/>
				</div>
				<div className={'w-14'}></div>
			</div>
			{[0, 1, 2].map((i) => {
				return (
					<div
						className={
							'border-b border-secondary-300 [ last-of-type:border-0 ]'
						}
						key={i}>
						<div
							className={
								'flex flex-col pb-2 [ sm:flex-row sm:justify-between sm:items-center ]'
							}>
							<div className={'w-full [ sm:hidden ]'}>
								<GovSkeleton
									width={'75px'}
									height={'19px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>
							<div className={'w-full mb-2 [ sm:w-1/2 sm:mr-auto sm:mb-0 ]'}>
								<GovSkeleton
									width={generateRandomNumber(50, 90) + '%'}
									height={'21px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>

							<div className={'w-full [ sm:hidden ]'}>
								<GovSkeleton
									width={'95px'}
									height={'19px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>
							<div
								className={
									'w-full mb-2 [ sm:w-1/5 sm:mr-4 sm:mb-0 ] [ md:w-40 ]'
								}>
								<GovSkeleton
									width={'70px'}
									height={'21px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>

							<div className={'w-full [ sm:hidden ]'}>
								<GovSkeleton
									width={'85px'}
									height={'19px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>
							<div
								className={
									'w-full mb-2 [ sm:w-1/5 sm:mr-4 sm:mb-0 ] [ md:w-40 ]'
								}>
								<GovSkeleton
									width={'85px'}
									height={'21px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>

							<div className={'w-full [ sm:w-14 sm:text-right ]'}>
								<GovSkeleton
									width={'24px'}
									height={'24px'}
									variant={'secondary'}
									wcagLabel={tg('loading.wcag.processing')}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</LayoutGap>
	);
};
