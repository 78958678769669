import { hasLargeFile } from 'libs/core/hooks/src/File/fileUploadUtils';
import { FileUploadItem } from '@gov-nx/core/hooks';
import { useConfiguration } from '@gov-nx/core/service';

export const useMessagePrice = (attachments?: FileUploadItem[]) => {
	const { datovaZpravaCena, velkokapacitniZpravaCena } = useConfiguration();

	const price = hasLargeFile(attachments)
		? velkokapacitniZpravaCena
		: datovaZpravaCena;

	return price;
};
