import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	CiselnikyCiselnikPolozkaDto,
	countriesQuery,
	OsobyFyzickaOsobaDto,
	sendComplaintQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useAddressSearch, useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ProfileROBComplaintContext,
	ProfileRobComplaintProcessControl,
} from './context.types';

const ProfilROBReklamaceContext =
	createContext<Nullable<ProfileROBComplaintContext>>(null);

interface ProfilROBComplaintContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	data: Nullable<OsobyFyzickaOsobaDto>;
	onComplaintSubmit?: () => void;
}

export function ProfilROBReklamaceContextProvider({
	children,
	code,
	data,
	onComplaintSubmit,
}: ProfilROBComplaintContextProviderProps) {
	const { setControls, controls } =
		useProcessControl<ProfileRobComplaintProcessControl>({
			initialDataError: null,
			initialCountryError: null,
			displaySearchAddressLoading: false,
			displaySearchAddress: false,
			displaySearchDeliveryAddressLoading: false,
			displaySearchDeliveryAddress: false,
			displayBirthPlace: false,
		});

	const [country, setCountry] = useState<
		Nullable<CiselnikyCiselnikPolozkaDto[]>
	>([]);
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	usePoQuery({
		queryKey: ['profile-rob-countries'],
		queryFn: countriesQuery,
		onSuccess: (robCountry) => {
			setCountry(robCountry.seznam ?? []);
			setControls({ initialLoading: false });
		},
		onError: async (error: Error) => {
			setControls({ initialCountryError: new GovError(error.message) });
		},
	});

	const formDefinition = FormInstance({ code, data, controls });

	const { address, deliveryAddress, mainQuery, deliveryAddressQuery } =
		useAddressSearch({
			formMethods: formDefinition.formMethods,
			onSuccess: () =>
				setControls({
					displaySearchAddressLoading: false,
					displaySearchDeliveryAddressLoading: false,
				}),
			onError: () =>
				setControls({
					displaySearchAddressLoading: false,
					displaySearchDeliveryAddressLoading: false,
				}),
		});

	const newAddress = formDefinition.formMethods.watch('newAddress');
	const newDeliveryAddress =
		formDefinition.formMethods.watch('newDeliveryAddress');

	useEffect(() => {
		const selectedOption = address.find((option) => option.kod === newAddress);
		if (selectedOption && address.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaPobytu',
				selectedOption.adresaText || ''
			);
		}
	}, [newAddress, address, formDefinition.formMethods]);

	useEffect(() => {
		const selectedOption = deliveryAddress.find(
			(option) => option.kod === newDeliveryAddress
		);
		if (selectedOption && deliveryAddress.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaDorucovaci',
				selectedOption.adresaText || ''
			);
		}
		if (newDeliveryAddress === tsn('formular.adresa.neni-evidovana')) {
			formDefinition.formMethods.setValue(
				'adresaDorucovaci',
				tsn('formular.adresa.neni-evidovana') || ''
			);
		}
	}, [newDeliveryAddress, deliveryAddress, formDefinition.formMethods, tsn]);

	const newPlaceOfBirth = formDefinition.formMethods.watch('newPlaceOfBirth');

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(
				newAddress,
				newDeliveryAddress,
				newPlaceOfBirth
			)(data);
			return sendComplaintQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			formDefinition.formMethods.reset();
			onComplaintSubmit && onComplaintSubmit();
		},
	});

	const [initialFormValues, setInitialFormValues] = useState<FormData | null>(
		null
	);

	useEffect(() => {
		setInitialFormValues(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods]);

	const resetBirthPlaceSearchFields = () => {
		formDefinition.formMethods.resetField('stat');
		formDefinition.formMethods.resetField('newPlaceOfBirth');
	};

	const resetAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObce');
		formDefinition.formMethods.resetField('nazevUlice');
		formDefinition.formMethods.resetField('cislo');
	};

	const resetDeliveryAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObceDorucovaciAdresa');
		formDefinition.formMethods.resetField('nazevUliceDorucovaciAdresa');
		formDefinition.formMethods.resetField('cisloDorucovaciAdresa');
	};

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		const changedValues: FormData = {};
		for (const fieldName in initialFormValues) {
			const initialValue = initialFormValues[fieldName as keyof FormData];
			const currentValue =
				formDefinition.formMethods.getValues()[fieldName as keyof FormData];
			if (initialValue !== currentValue) {
				changedValues[fieldName as keyof FormData] = currentValue;
			}
		}
		if (Object.keys(changedValues).length > 0) {
			submitMutation.mutate(changedValues);
		} else {
			setControls({ processLoading: false });
		}
	}, [
		submitMutation,
		formDefinition.formMethods,
		setControls,
		initialFormValues,
	]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ProfilROBReklamaceContext.Provider
			value={{
				controls,
				setControls,
				formDefinition,
				data,
				country,
				address,
				mainQuery,
				deliveryAddress,
				deliveryAddressQuery,
				resetAddressSearchFields,
				resetDeliveryAddressSearchFields,
				resetBirthPlaceSearchFields,
				onSubmit,
				disableSubmitButton: submitMutation.isSuccess,
			}}>
			{children}
		</ProfilROBReklamaceContext.Provider>
	);
}

export const useProfilROBReklamaceContextInstance =
	(): ProfileROBComplaintContext =>
		useContext(ProfilROBReklamaceContext) as ProfileROBComplaintContext;
