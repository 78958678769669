import { useEffect, useState } from 'react';
import {
	inforAboutStudyingQuery,
	School,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Optional, SchoolCategoryCode, TypeOfSchool } from '@gov-nx/core/types';

export interface SchoolSearchAutocompleteProps {
	type: TypeOfSchool;
}

export const useSchoolSearchAutocomplete = ({
	type,
}: SchoolSearchAutocompleteProps) => {
	const [text, setText] = useState<Optional<string>>(undefined);

	// Get rid of stored text on type change
	useEffect(() => setText(undefined), [type]);

	const mainQuery = usePoQuery<{ seznam: School[] }>({
		queryKey: ['school-autocomplete', text, type],
		queryFn: async () => {
			const response = await inforAboutStudyingQuery({
				kodKategorie: SchoolCategoryCode[type] ?? undefined,
				text: text,
			});
			return response.data;
		},
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		isLoading: mainQuery.isLoading,
		isFetching: mainQuery.isFetching,
		schools: mainQuery.data?.seznam || [],
		setText,
	};
};
