import { routeResolver, useScroll } from '@gov-nx/web';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isFileUploadItemWeb } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { filter, pipe } from '@gov-nx/core/types';
import { PageCode, useEPeticeEditContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	Collapsible,
	CommaSpace,
	LayoutGap,
	Space,
} from '@gov-nx/ui/web';
import { phoneShapeToString } from '@gov-nx/utils/common';
import { ElementIds } from './utils';

interface TitleProps {
	tsn: (key: string) => string;
	title: string;
	openStep: () => void;
}

const Title = ({ title, tsn, openStep }: TitleProps) => {
	return (
		<div className={'flex gap-2'}>
			<h3 className={'text-xl text-secondary-700'}>{title}</h3>
			<Button
				onClick={openStep}
				wcagLabel={`${tsn('akce.wcag.upravit')}: ${title}`}
				variant="primary"
				type="base"
				size="s">
				{tsn('akce.upravit')}
			</Button>
		</div>
	);
};

export const WizardStep4 = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const {
		code,
		petitionId,
		wizard,
		zakladatelTyp,
		formStep1,
		formStep2,
		isSubmitConfirmModalOpen,
		isDeleteConfirmModalOpen,
		isProcessing,
		controls,
		onSave,
	} = useEPeticeEditContext();
	const tsn = getLocalizeCurried(code);
	const navigate = useNavigate();
	const { scrollToElement } = useScroll();

	const focusToElement = (stepIndex: number, elementId: string) => {
		const step = wizard.step(stepIndex);
		const element = document.getElementById(elementId);

		if (step.isExpanded) {
			scrollToElement(element);
		} else {
			wizard.openStep(stepIndex);
			// wait for the gov-wizard to expand the step and scroll to it
			setTimeout(() => {
				scrollToElement(element);
			}, 100);
		}
	};

	const step2Data = formStep2?.formDefinition.formMethods.getValues();

	return (
		<LayoutGap
			gap={8}
			className={'pt-4'}>
			<LayoutGap gap={2}>
				<Title
					title={tsn('formular.nadpis.popis-epetice')}
					tsn={tsn}
					openStep={() => focusToElement(1, ElementIds.popis)}
				/>
				<dl>
					<div>
						<dt className={'inline mr-1'}>{tsn('formular.popis.nazev')}</dt>
						<dd className={'inline'}>
							{formStep1.formMethods.getValues('nazev')}
						</dd>
					</div>
					<div>
						<dt className={'inline mr-1'}>{tsn('formular.popis.perex')}</dt>
						<dd className={'inline'}>
							<Collapsible
								max={20}
								showCollapsedLabel={tsn('formular.popis.zobrazit-zkraceny')}
								showExpandedLabel={tsn('formular.popis.zobrazit-cely')}>
								{step2Data?.perex}
							</Collapsible>
						</dd>
					</div>
					<div>
						<dt className={'inline mr-1'}>{tsn('formular.popis.text')}</dt>
						<dd className={'inline'}>
							<Collapsible
								max={20}
								showCollapsedLabel={tsn('formular.popis.zobrazit-zkraceny')}
								showExpandedLabel={tsn('formular.popis.zobrazit-cely')}>
								{step2Data?.text}
							</Collapsible>
						</dd>
					</div>
				</dl>
			</LayoutGap>

			<LayoutGap gap={2}>
				<Title
					title={tsn('formular.nadpis.priloha')}
					tsn={tsn}
					openStep={() => focusToElement(1, ElementIds.priloha)}
				/>
				<dl>
					<dt className={'inline mr-1'}>{tsn('formular.popis.priloha')}</dt>
					<dd className={'inline mr-1'}>
						<ol className={'count-inline'}>
							{pipe(
								wizard.formData.priloha,
								filter(isFileUploadItemWeb),
								(attachments) => {
									if (Array.isArray(attachments) && attachments.length > 0) {
										return attachments.map((attachment) => (
											<li key={attachment.id}>
												<Button
													variant={'primary'}
													type={'link'}
													onClick={() =>
														window.open(URL.createObjectURL(attachment.file))
													}
													wcagLabel={'formular.popis.otevrit-prilohu'}>
													{attachment.name}
												</Button>
											</li>
										));
									}

									return tsn('formular.popis.bez-prilohy');
								}
							)}
						</ol>
					</dd>
				</dl>
			</LayoutGap>

			<LayoutGap gap={2}>
				<Title
					title={tsn('formular.nadpis.adresat')}
					tsn={tsn}
					openStep={() => focusToElement(1, ElementIds.adresat)}
				/>
				<dl>
					<dt className={'inline mr-1'}>{tsn('formular.popis.adresat')}</dt>
					<dd className={'inline'}>
						{wizard.formData.adresatOvm?.selected
							? wizard.formData.adresatOvm.selected.nazev
							: tsn('formular.zprava.adresat-nevybran')}
					</dd>
				</dl>
			</LayoutGap>

			<LayoutGap gap={2}>
				<Title
					title={tsn('formular.nadpis.kontakt-zakladatel')}
					tsn={tsn}
					openStep={() => focusToElement(1, ElementIds.zakladatel)}
				/>
				{step2Data?._useEmail && (
					<dl>
						<dt className={'inline mr-1'}>{tsn('formular.popis.email')}:</dt>
						<dd className={'inline'}>{step2Data.email}</dd>
					</dl>
				)}
				{step2Data?._usePhone && (
					<dl>
						<dt className={'inline mr-1'}>{tsn('formular.popis.telefon')}:</dt>
						<dd className={'inline'}>
							{pipe(step2Data.phone, (shape) =>
								shape ? phoneShapeToString(shape) : null
							)}
						</dd>
					</dl>
				)}
				{zakladatelTyp === 'FO' &&
				formStep2?.formDefinition.formMethods.getValues('_useAddress') ? (
					<dl>
						<dt className={'inline mr-1'}>{tsn('formular.popis.adresa')}:</dt>
						<dd className={'inline'}>
							{formStep2?.formDefinition.formMethods.getValues(
								'adresa.adresaText'
							)}
						</dd>
					</dl>
				) : null}
			</LayoutGap>

			{zakladatelTyp === 'FO' ? (
				<LayoutGap gap={2}>
					<Title
						title={tsn('formular.nadpis.peticni-vybor')}
						tsn={tsn}
						openStep={() => focusToElement(1, ElementIds.peticniVybor)}
					/>
					<dl>
						<dt className={'inline mr-1'}>
							{tsn('formular.popis.peticni-vybor')}
						</dt>
						<dd className={'inline mr-1'}>
							<ol className={'count-inline'}>
								{pipe(
									formStep2?.formDefinition.formMethods.getValues(
										'clenovePeticnihoVyboru'
									),
									(members) => {
										if (Array.isArray(members) && members.length > 0) {
											return members.map((member) => {
												return (
													<li
														className={'inline'}
														key={[
															member.id,
															member.jmeno,
															member.prijmeni,
															member.adresa?.adresaText,
														].join('-')}>
														{member.jmeno}
														<Space />
														{member.prijmeni}
														<CommaSpace />
														{member.adresa?.adresaText}
													</li>
												);
											});
										}
										return tsn('formular.popis.peticni-vybor-neuvedeno');
									}
								)}
							</ol>
						</dd>
					</dl>
				</LayoutGap>
			) : null}

			<ButtonGroupLayout>
				<Button
					onClick={isSubmitConfirmModalOpen.setTrue}
					wcagLabel={tsn('akce.wcag.odeslat')}
					disabled={isProcessing}
					variant={'primary'}
					size={'l'}
					type={'solid'}>
					{tsn('formular.tlacitko.odeslat')}
				</Button>
				<Button
					onClick={() =>
						onSave(() => {
							navigate(
								routeResolver(PageCode['epetice-detail'], { petitionId })
							);
						})
					}
					loading={controls.saveLoading}
					wcagLabel={tsn('akce.wcag.ulozit')}
					disabled={isProcessing}
					variant={'primary'}
					size={'l'}
					type={'outlined'}>
					{tsn('formular.tlacitko.ulozit')}
				</Button>
				<Button
					onClick={isDeleteConfirmModalOpen.setTrue}
					wcagLabel={tsn('akce.wcag.smazat')}
					disabled={isProcessing}
					variant={'error'}
					size={'l'}
					type={'outlined'}>
					{tsn('formular.tlacitko.smazat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
