export const useSessionStorage = (key: string) => {
	return {
		value: sessionStorage.getItem(key),
		remove: () => sessionStorage.removeItem(key),
		set: (value: string) => sessionStorage.setItem(key, value),
		equalsTo: (value: string) => {
			return sessionStorage.getItem(key) === value;
		},
	};
};
