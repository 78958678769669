import React from 'react';
import { CookbookContainerCoreProps } from '@gov-nx/ui/types';

export const CookbookContainer = ({
	children,
	label,
}: CookbookContainerCoreProps) => {
	return (
		<ul
			className={'gov-list--plain flex flex-col gap-2 items-start'}
			aria-label={label}>
			{children}
		</ul>
	);
};
