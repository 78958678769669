import cx from 'classnames';
import { FormProvider } from 'react-hook-form';
import { CommunicationType, EmailFormData } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import {
	Button,
	FormInput,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

interface ContactDataEmailFormProps {
	code: PageCode;
	formDefinition: FormDefinition<EmailFormData>;
	onSubmit: () => void;
	onChange: () => void;
	isNeedForVerification: boolean;
	isUpdating: boolean;
	resendVerification?: () => void;
	isVerificationResending: boolean;
}

export const ContactEmailForm = ({
	code,
	formDefinition,
	onSubmit,
	onChange,
	isNeedForVerification,
	resendVerification,
	isUpdating,
	isVerificationResending,
}: ContactDataEmailFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const type = CommunicationType.EMAIL;

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				onSubmit={onSubmit}
				formDefinition={formDefinition}>
				<LayoutGap
					gap={4}
					className={cx('pb-6')}>
					<FormWidthElementLayout>
						<FormInput
							label={{ children: tsn('kontakt.pole.email') }}
							field={{
								name: 'email',
								disabled: isNeedForVerification || isUpdating,
							}}
							control={{ size: 'm' }}
							group={{ gap: 's' }}
						/>
						<p className={'text-xs text-secondary.700'}>
							{tsn('kontakt.pole.note.email')}
						</p>
					</FormWidthElementLayout>

					{isNeedForVerification ? (
						<FormWidthElementLayout>
							<FormInput
								label={{
									children: tsn('kontakt.pole.kod.' + type),
								}}
								field={{
									name: 'code',
									disabled: isUpdating,
								}}
								control={{ size: 'm' }}
								group={{ gap: 's' }}
							/>
							<p className={'text-xs text-secondary.700'}>
								{tsn('kontakt.pole.note.kod.' + type)}
							</p>
							{resendVerification && (
								<Button
									variant={'primary'}
									type={'link'}
									disabled={isVerificationResending}
									loading={isVerificationResending}
									onClick={resendVerification}>
									{tsn('kontakt.akce.znovu-odeslat-email-kod')}
								</Button>
							)}
						</FormWidthElementLayout>
					) : null}
					<div className={'flex flex-col gap-4 mt-2 [ md:flex-row ]'}>
						<Button
							wcagLabel={tsn(
								'kontakt.akce.wcag.' +
									type +
									(isNeedForVerification ? '.odeslat' : '.overit')
							)}
							nativeType={'submit'}
							variant={'primary'}
							disabled={isUpdating}
							loading={isUpdating}
							size={'m'}
							type={'solid'}>
							<span>
								{tsn(
									'kontakt.akce.' +
										type +
										(isNeedForVerification ? '.odeslat' : '.overit')
								)}
							</span>
						</Button>
					</div>
					{isNeedForVerification && (
						<Button
							onClick={onChange}
							wcagLabel={tsn('kontakt.akce.wcag.' + type + '.zadat-jiny')}
							variant={'primary'}
							type={'link'}
							size={'m'}
							className={'mt-4'}>
							{tsn('kontakt.akce.' + type + '.zadat-jiny')}
						</Button>
					)}
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
