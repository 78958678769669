// @TODO Add more key-value pairs
const HREF_KEY_LINKS: { [key: string]: string } = {
	'setting-notification-tab': '/nastaveni',
};

export const extractPoLinkAttributes = (
	text?: string,
	currentLanguageCode?: string
) => {
	const poLinkRegex = /<po-link[^>]*\shref-key=['"](.*?)['"].*?>/g;
	const poLinkMatches = text?.match(poLinkRegex);
	const extractedValues = poLinkMatches?.map((match) => {
		const hrefKeyMatch = match.match(/href-key=['"](.*?)['"]/);
		const labelCsMatch = match.match(/label-cs=['"](.*?)['"]/);
		const labelEnMatch = match.match(/label-en=['"](.*?)['"]/);

		if (hrefKeyMatch && labelCsMatch && labelEnMatch) {
			const hrefKey = hrefKeyMatch[1];
			const label =
				currentLanguageCode === 'cs' ? labelCsMatch[1] : labelEnMatch[1];
			const link = HREF_KEY_LINKS[hrefKey] || '';
			return {
				'href-key': hrefKey,
				label,
				link,
			};
		}
		return null;
	});
	return extractedValues;
};

export const extractLink = (text?: string) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const urlMatches = text?.match(urlRegex);

	const links =
		urlMatches?.map((url, index) => ({
			href: url,
			key: index,
		})) || [];

	return { links };
};

export const sanitizedText = (
	links: { href: string; key: number }[],
	originalText?: string
) => {
	if (!originalText) {
		return '';
	}

	return links.reduce((acc, link) => {
		return acc.replace(new RegExp(link.href, 'g'), '');
	}, originalText);
};
