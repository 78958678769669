import { createContext, ReactNode, useContext } from 'react';
import { useDownloadEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ePetitionAttachmentContextType } from './context.types';

export const ePetitionAttachmentContext =
	createContext<Nullable<ePetitionAttachmentContextType>>(null);

interface ePetitionAttachmentProviderProps {
	code: PageCode;
	children: ReactNode;
}

export function EPetitionAttachmentProvider({
	children,
}: ePetitionAttachmentProviderProps) {
	const { downloadEPetitionAttachment } = useDownloadEvents();

	return (
		<ePetitionAttachmentContext.Provider
			value={{
				downloadAttachment: (attachmentData, onSuccess) => {
					return downloadEPetitionAttachment({
						attachmentData,
						callback: onSuccess,
					});
				},
			}}>
			{children}
		</ePetitionAttachmentContext.Provider>
	);
}

export const usePetitionAttachmentContext =
	(): ePetitionAttachmentContextType =>
		useContext(ePetitionAttachmentContext) as ePetitionAttachmentContextType;
