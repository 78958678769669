import { AxiosResponse } from 'axios';
import { FileUploadItem } from '@gov-nx/core/hooks';
import { axiosInstance } from '../axios';
import { OsobyCertifikatSeznamDto } from '../generated';
import { fileToFormData } from '../utils/utils';

export const certificatesQuery = async (): Promise<
	AxiosResponse<OsobyCertifikatSeznamDto>
> => {
	return axiosInstance.get('/api/v1/fyzickeosoby/certifikaty');
};

export const sendNewCertificatesQuery = async (
	file: FileUploadItem
): Promise<AxiosResponse<OsobyCertifikatSeznamDto>> => {
	const data = new FormData();
	data.append('nazevSouboru', fileToFormData(file));

	return axiosInstance.post('/api/v1/fyzickeosoby/certifikaty', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
