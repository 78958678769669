import { GovIcon } from '@gov-design-system-ce/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUuid4 } from '@gov-nx/utils/common';
import { Button } from './Button';

interface ToggleButtonProps {
	id?: string;
	children?: ReactNode;
	showLabel?: string;
	hideLabel?: string;
	wcagShowLabel?: string;
	wcagHideLabel?: string;
	onClick?: () => void;
}

export const ToggleButton = ({
	id,
	children,
	wcagHideLabel,
	wcagShowLabel,
	showLabel,
	hideLabel,
	onClick,
}: ToggleButtonProps) => {
	const { t } = useTranslation();
	const elementId = id ?? createUuid4('toggle');
	const [display, setDisplay] = useState<boolean>(false);
	return (
		<>
			<Button
				wcagHasPopup={'true'}
				wcagExpanded={display}
				wcagControls={elementId}
				variant={'primary'}
				wcagLabel={
					display
						? wcagHideLabel || t('vice-informaci.wcag.label')
						: wcagShowLabel || t('vice-informaci.wcag.label')
				}
				onClick={() => {
					setDisplay(!display);
					onClick?.();
				}}
				type={'link'}
				className={'button-plain'}>
				{display
					? hideLabel || t('vice-informaci.skryt')
					: showLabel || t('vice-informaci.zobrazit')}
				<GovIcon
					name={display ? 'chevron-up' : 'chevron-down'}
					type={'basic'}
					slot={'right-icon'}
					className={'!mt-0'}></GovIcon>
			</Button>
			<div
				id={elementId}
				hidden={!display}
				aria-hidden={!display}>
				{display ? <div className={'pb-4 w-full'}>{children}</div> : null}
			</div>
		</>
	);
};
