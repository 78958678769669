import { ReactNode } from 'react';
import { useAppLanguageSelector } from '@gov-nx/core/hooks';
import { useTranslationInit } from '@gov-nx/core/service';
import { useAppContext } from '../context/AppContext';

interface TranslationInitProviderProps {
	children: ReactNode;
}

export const TranslationInitProvider = ({
	children,
}: TranslationInitProviderProps) => {
	const { setAppError } = useAppContext();
	const { language } = useAppLanguageSelector();

	const { translationInitialized } = useTranslationInit({
		initialLanguage: language,
		onError: (error) => setAppError(new Error(error)),
	});

	return translationInitialized ? children : null;
};
