import { LocalizationLanguage } from '@gov-nx/core/app';
import { PageCode } from '@gov-nx/module/page';
import { axiosCmsInstance } from '../axios/instance';
import { CmsPageResponse } from './types';

export interface CmsPageQueryParams {
	code: PageCode;
	locale: LocalizationLanguage;
	cmsKeyCache: string;
}

export const cmsPageQuery = async ({
	code,
	locale,
	cmsKeyCache,
}: CmsPageQueryParams) => {
	const response = await axiosCmsInstance.get<CmsPageResponse>(
		`/pages/${code}-${locale}.json?v=${cmsKeyCache}`
	);
	return response.data.data;
};
