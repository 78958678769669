import cx from 'classnames';
import React from 'react';
import { LayoutGap } from './LayoutGap';

export interface FormLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const FormLayout = ({ children, customClasses }: FormLayoutProps) => {
	return (
		<section
			className={cx(
				'py-8 px-4 -mx-4 rounded-sm bg-neutral-white [ md:ml-0 md:mr-0 md:pt-8 md:pr-10 md:pb-10 md:pl-8 ]',
				customClasses
			)}>
			<LayoutGap gap={6}>{children}</LayoutGap>
		</section>
	);
};
