import { useCallback } from 'react';
import {
	useAuthStore,
	useCmsStore,
	useFiltersStore,
} from '@gov-nx/store/portal-obcana';
import { useDataBoxStore, usePersonStore } from '@gov-nx/store/portal-obcana';

export const useAppLogout = () => {
	const { dataBoxReset } = useDataBoxStore();
	const { personReset } = usePersonStore();
	const { resetCms } = useCmsStore();
	const { filtersResetAction } = useFiltersStore();
	const { logout: logoutAction } = useAuthStore();

	const logout = useCallback(() => {
		logoutAction();

		dataBoxReset();
		personReset();
		resetCms();
		filtersResetAction();
	}, [logoutAction, dataBoxReset, personReset, resetCms, filtersResetAction]);

	return { logout };
};
