import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const NotificationsSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			<ContentLayout>
				{[0, 1, 2, 3, 4].map((i) => {
					return (
						<div
							className={
								'flex flex-col py-5 border-b border-secondary-300 [ last-of-type:border-0 ] [ md:justify-between md:items-center md:flex-row ]'
							}
							key={i}>
							<div className={'w-full'}>
								<GovSkeleton
									width={generateRandomNumber(50, 90) + '%'}
									height={'22px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'ml-5'}
								/>
								<GovSkeleton
									width={generateRandomNumber(50, 90) + '%'}
									height={'22px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'ml-5 mb-2 [ md:hidden ]'}
								/>
							</div>
							<GovSkeleton
								width={'120px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={'ml-5'}
							/>
						</div>
					);
				})}
			</ContentLayout>
		</LayoutGap>
	);
};
