import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { DocumentDownloadItemCoreProps } from '@gov-nx/ui/types';
import { formatBytes } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';

export const DocumentDownloadItem = ({
	name,
	size,
	disabled,
	loading,
}: DocumentDownloadItemCoreProps) => {
	const suffix =
		name && name.indexOf('.') !== -1 ? name.split('.').pop() : undefined;
	return (
		<li className={'w-full [ sm:w-auto ]'}>
			<Button
				className={
					'document-download inline-flex w-full border border-secondary-500 rounded [ sm:w-auto ] [ hover:bg-primary-200 hover:text-primary-600 ]'
				}
				variant={'primary'}
				type={'base'}
				disabled={!!disabled}
				wcagLabel={name}>
				{loading ? (
					<GovIcon
						name="loader"
						className={
							'!w-6 !h-6 grow-0 shrink-0 !text-secondary-700 gov-spin-animation'
						}
					/>
				) : (
					<GovIcon
						slot="left-icon"
						name="file-earmark-text"
						className={'!w-6 !h-6 grow-0 shrink-0 !text-secondary-700'}
					/>
				)}
				<div className={'flex flex-col text-left'}>
					<GovTooltip
						variant={'primary'}
						position={'top'}
						size={'s'}
						icon
						className={
							'inline-block overflow-hidden w-[230px] whitespace-nowrap text-ellipsis text-secondary-800 [ sm:w-[184px] ]'
						}>
						{name}
						<GovTooltipContent>{name}</GovTooltipContent>
					</GovTooltip>
					<span className={'text-s text-secondary-600'}>
						{size && formatBytes(size, 2)}
						{suffix && <span className={'uppercase'}>, {suffix}</span>}
					</span>
				</div>
				<GovIcon
					slot="right-icon"
					name="chevron-down"
					className={'w-4 ml-auto mr-0 !text-primary-600'}
				/>
			</Button>
		</li>
	);
};
