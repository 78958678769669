import { ValueOf } from '@gov-nx/core/types';

// Corresponds to the API request parameters.
export enum NotificationChannel {
	Oznameni = 'N_OZN',
	MarketingovaSdeleni = 'N_OZN_MARKETING',
	ZmenyVRegistrech = 'N_ZR',
	KonecPlatnostiDokladu = 'N_RP',
	KonecPlatnostiDokladuSms = 'N_RP_SMS',
	KonecPlatnostiDokladuPush = 'N_RP_PUSH',
}

export type FormNotificationField = { [key in TypeAValueKey]?: boolean };

export interface FormNotificationFields {
	[NotificationChannel.Oznameni]: FormNotificationField;
	[NotificationChannel.MarketingovaSdeleni]: FormNotificationField;
	[NotificationChannel.ZmenyVRegistrech]: FormNotificationField;
	[NotificationChannel.KonecPlatnostiDokladu]: FormNotificationField;
}

export interface NotificationSavePayload {
	row: ValueOf<FormNotificationFields>;
	key: keyof FormNotificationFields;
	type: keyof FormNotificationField;
	value: boolean;
}

export interface FormData extends FormNotificationFields {
	datovaSchrankaId: string;
	typVypisu: string;
}

export enum TypeAValues {
	sms = 1,
	email = 2,
	push = 4,
}

export type TypeAValueKey = keyof typeof TypeAValues;

export interface TypeA {
	klic:
		| NotificationChannel.Oznameni
		| NotificationChannel.MarketingovaSdeleni
		| NotificationChannel.ZmenyVRegistrech
		| NotificationChannel.KonecPlatnostiDokladuPush;
	hodnota: TypeAValues;
}

export interface TypeAPayload {
	klic: TypeA['klic'];
	hodnota: string;
}

export enum TypeBValues {
	off = 0,
	on = 1,
}

export interface TypeB {
	klic: NotificationChannel.KonecPlatnostiDokladu;
	hodnota: TypeBValues; // or RP8
}

export interface TypeBPayload {
	klic: TypeB['klic'];
	hodnota: string;
}

export enum TypeCValues {
	off = 0,
	on = 1,
}

export interface TypeC {
	klic: NotificationChannel.KonecPlatnostiDokladuSms;
	hodnota: TypeCValues;
}

export interface TypeCPayload {
	klic: TypeC['klic'];
	hodnota: string;
}

export enum TypeDValues {
	off = 0,
	on = 1,
}

export interface TypeD {
	klic: NotificationChannel.KonecPlatnostiDokladuPush;
	hodnota: TypeDValues;
}

export interface TypeDPayload {
	klic: TypeD['klic'];
	hodnota: string;
}

export type NotificationSettingsTypes = TypeA | TypeB | TypeC | TypeD;
export type NotificationSettingsPayload =
	| TypeAPayload
	| TypeBPayload
	| TypeCPayload
	| TypeDPayload;
