import React, { createContext, useContext, useEffect, useMemo } from 'react';
import {
	getError,
	isResultsLimitExceededError,
	usePoQuery,
	vehicleRegistryPreviewsQuery,
} from '@gov-nx/api/portal-obcana';
import { useBusinessPersonLoader } from '@gov-nx/core/hooks';
import { Nullable, propEq } from '@gov-nx/core/types';
import { toStringDate, useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { getVehicleGetters } from '../vehicleUtils';
import { FormDefinitionInstance, getDefaultValues } from './FormDefinitions';
import { RegisterSVListContext, VehicleRegistryParams } from './context.types';
import { filterVehicles, sortVehiclesSubjektOd } from './filterUtils';
import { useFilters } from './useFilters';

const RegistrSVContext = createContext<Nullable<RegisterSVListContext>>(null);

interface RegistrZPContextProviderProps {
	code: PageCode;
	children: React.ReactNode;
}

export function RegistrSVSeznamContextProvider({
	code,
	children,
}: RegistrZPContextProviderProps) {
	const filterWindowOpened = useBoolean(false);
	const defaultValues = getDefaultValues();
	const formDefinition = FormDefinitionInstance({ code, defaultValues });
	const { filterParams, filter, setPartialFilter } = useFilters({
		code,
		formDefinition,
		defaultValues,
	});

	const ico = filter.typOsoby === 'PFO' ? filter.ico : undefined;
	const datumOd = filter.datumOd ? toStringDate(filter.datumOd) : undefined;
	const datumDo = filter.datumDo ? toStringDate(filter.datumDo) : undefined;

	const query = usePoQuery({
		queryKey: [
			'nahledyudaju',
			'zdrojUdaju',
			'REGISTR_VOZIDEL',
			ico,
			datumOd,
			datumDo,
		],
		refetchOnWindowFocus: false,
		queryFn: () =>
			vehicleRegistryPreviewsQuery<VehicleRegistryParams>({
				zdrojUdaju: 'REGISTR_VOZIDEL_V2',
				ico,
				start: 0,
				pocet: 100,
				datumOd: datumOd,
				datumDo: datumDo,
			}),
		retry: false,
	});

	const limitExceededError = query.error
		? isResultsLimitExceededError(query.error)
		: undefined;

	const { businessPerson, error: businessPersonError } =
		useBusinessPersonLoader();

	const dataBoxesPFO =
		businessPerson?.seznam?.filter(propEq('typ', 'PFO')) ?? [];
	const hasPFODataBox = dataBoxesPFO.length > 0;
	const typOsoby = formDefinition.formMethods.watch('typOsoby');

	useEffect(() => {
		if (dataBoxesPFO.length > 0 && typOsoby === 'PFO') {
			const { ico } = dataBoxesPFO[0];
			ico && formDefinition.formMethods.setValue('ico', `${ico}`);
		}
	}, [typOsoby, dataBoxesPFO]);

	useEffect(() => {
		formDefinition.formMethods.setValue('typOsoby', filter.typOsoby);
	}, [filter.typOsoby]);

	const handleSubmit = () => {
		setPartialFilter(formDefinition.formMethods.getValues());
		filterWindowOpened.setFalse();
	};

	const onReset = () => {
		setPartialFilter(defaultValues);
		formDefinition.formReset();
		filterWindowOpened.setFalse();
	};

	useEffect(() => {
		filter.datumOd &&
			formDefinition.formMethods.setValue('datumOd', filter.datumOd);
		filter.datumDo &&
			formDefinition.formMethods.setValue('datumDo', filter.datumDo);
		filter.filterText &&
			formDefinition.formMethods.setValue('filterText', filter.filterText);
		filter.filterStatus &&
			formDefinition.formMethods.setValue('filterStatus', filter.filterStatus);
		filter.filterVztah &&
			formDefinition.formMethods.setValue('filterVztah', filter.filterVztah);
		filter.filterVehicleStatus &&
			formDefinition.formMethods.setValue(
				'filterVehicleStatus',
				filter.filterVehicleStatus
			);
	}, []);

	useEffect(() => {
		const subscription = formDefinition.formMethods.watch((value, { name }) => {
			if (name === 'filterVehicleStatus') {
				setPartialFilter({
					filterVehicleStatus: formDefinition.formMethods.getValues(
						'filterVehicleStatus'
					),
					page: defaultValues.page,
				});
			}
			if (name === 'filterStatus.vse' && value['filterStatus']?.vse === true) {
				formDefinition.formMethods.setValue('filterStatus.provozovane', true);
				formDefinition.formMethods.setValue('filterStatus.zanik', true);
			}
			if (
				name === 'filterStatus.provozovane' &&
				value['filterStatus']?.provozovane === false
			) {
				formDefinition.formMethods.setValue('filterStatus.vse', false);
			}
			if (
				name === 'filterStatus.zanik' &&
				value['filterStatus']?.zanik === false
			) {
				formDefinition.formMethods.setValue('filterStatus.vse', false);
			}
			if (name === 'filterVztah.vse' && value['filterVztah']?.vse === true) {
				formDefinition.formMethods.setValue('filterVztah.provozovatel', true);
				formDefinition.formMethods.setValue('filterVztah.vlastnik', true);
			}

			if (
				name === 'filterVztah.provozovatel' &&
				value['filterVztah']?.provozovatel === false
			) {
				formDefinition.formMethods.setValue('filterVztah.vse', false);
			}
			if (
				name === 'filterVztah.vlastnik' &&
				value['filterVztah']?.vlastnik === false
			) {
				formDefinition.formMethods.setValue('filterVztah.vse', false);
			}
		});

		return () => subscription.unsubscribe();
	}, [formDefinition.formMethods, filterWindowOpened.value]);

	const vehicles = useMemo(
		() =>
			query.data?.filter(filterVehicles(filter)).sort(sortVehiclesSubjektOd),
		[query.data, filter]
	);

	const totalFilteredVehicles = vehicles?.length ?? 0;
	const vehiclesPaginated =
		vehicles?.slice(
			(filter.page - 1) * filter.pageSize,
			filter.page * filter.pageSize
		) ?? [];

	const showNoVehiclesBanner = vehicles?.length === 0 && !limitExceededError;

	return (
		<RegistrSVContext.Provider
			value={{
				code,
				controls: {
					resultsLimitExceeded: limitExceededError?.extPopis,
					initialError:
						getError(query.error, { ignoreStatus: 400 }) || businessPersonError,
					initialLoading: query.isLoading,
					processLoading: query.isRefetching,
				},
				totalFilteredVehicles,
				vehiclesPaginated,
				showNoVehiclesBanner,
				formDefinition,
				vehicleGetters: getVehicleGetters,
				openFilterModal: filterWindowOpened.setTrue,
				isFilterWindowOpen: filterWindowOpened.value,
				closeFilterModal: filterWindowOpened.setFalse,
				onSubmit: formDefinition.formMethods.handleSubmit(handleSubmit),
				onReset,
				filterParams,
				hasPFODataBox,
				typOsoby,
				ico,
				dataBoxesPFO,
				filter,
				setPartialFilter,
			}}>
			{children}
		</RegistrSVContext.Provider>
	);
}

export const useRegistrSVSeznamContext = (): RegisterSVListContext =>
	useContext(RegistrSVContext) as RegisterSVListContext;
