import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DownloadEPetitionAttachmentSubscription,
	useDownloadEvents,
	useMessageEvents,
} from '@gov-nx/core/events';
import { usePetitionAttachmentDownload } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DownloadCallback } from '../Download/types';

export const DownloadEPetitionAttachmentListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();

	const [callbacks, setCallbacks] = useState<{
		[key: number]: Nullable<DownloadCallback<number>['callback']>;
	}>({});

	const { mutate } = usePetitionAttachmentDownload({
		onSuccess: (data) => {
			download({
				blob: data.blob,
				id: data.petitionId.toString(),
				antivirus: data.antivirus,
				fileName: data.fileName,
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.attachmentId);
					}
					if (error) {
						errorMessage();
						onDownload(data.attachmentId, error);
					}
				},
			});
		},
		onError: () => {
			errorMessage();
		},
	});

	const onEPetitionAttachmentDownload = useCallback(
		(
			_: DownloadEPetitionAttachmentSubscription['type'],
			{
				attachmentData,
				callback,
			}: DownloadEPetitionAttachmentSubscription['payload']
		) => {
			if (callback) {
				setCallbacks((state) => ({
					...state,
					[attachmentData.attachmentId]: callback,
				}));
			}

			setTimeout(() => {
				mutate(attachmentData);
			}, 0);
		},
		[mutate]
	);

	useDownloadEvents({
		onEPetitionAttachmentDownload,
	});

	const onDownload = useCallback(
		(attachmentId: number, error?: Error) => {
			const callbackFunction = callbacks[attachmentId];
			if (callbackFunction) {
				callbackFunction(attachmentId, !error, error ?? null);
				setCallbacks((state) => ({ ...state, [attachmentId]: null }));
			}
		},
		[callbacks]
	);

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-uspech', {
				namespace: LocalizeNameSpaceTypes.Service,
				fileName,
			}),
			options: {
				variant: 'success',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-chyba', {
				namespace: LocalizeNameSpaceTypes.Service,
			}),
			options: {
				variant: 'error',
				icon: {
					name: 'exclamation-triangle-fill',
					type: 'basic',
				},
			},
		});
	};

	return null;
};
