/**
 * Filter Monad
 */
type Filter<T> = {
	value: T;
	previous: boolean;
};
export const filterMonad =
	<T>(fn: (input: T) => boolean) =>
	(input: Filter<T>): Filter<T> => {
		if (!input.previous) {
			return input;
		}

		return {
			...input,
			previous: fn(input.value),
		};
	};

export const prepareFilterMonad = <T>(value: T): Filter<T> => ({
	previous: true,
	value,
});
