import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	getSortedOptions,
	useProfilROBReklamaceContextInstance,
} from '@gov-nx/module/page';
import { FormInput, FormSelect, LayoutGap } from '@gov-nx/ui/web';

export interface BiirthPlaseFormProps {
	code: PageCode;
	isProcessing: boolean;
}
export const BirthPlaceChangeForm = ({
	code,
	isProcessing,
}: BiirthPlaseFormProps) => {
	const { country } = useProfilROBReklamaceContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	const sortedOptions = getSortedOptions(country);
	if (sortedOptions.length === 0) {
		return null;
	}
	return (
		<LayoutGap gap={4}>
			<FormSelect
				field={{
					name: 'stat',
					disabled: isProcessing,
					options: [
						{
							value: '',
							label: ts('formular.pole.neuvedeno'),
							isDisabled: true,
						},
						...sortedOptions,
					],
				}}
				label={{ children: ts('formular.pole.stat') }}
			/>
			<FormInput
				field={{
					name: 'newPlaceOfBirth',
					disabled: isProcessing,
					placeholder: ts('formular.placeholders.nove-misto-narozeni'),
				}}
				label={{ children: ts('formular.pole.nove-misto-narozeni') }}
				control={{ size: 'm' }}
			/>
		</LayoutGap>
	);
};
