import {
	DatoveSchrankyTypSchranky,
	DatovkaApiType,
} from '@gov-nx/api/portal-obcana';

export const sortDataboxesBeforeStoringToState = (list: DatovkaApiType[]) =>
	!list.length
		? []
		: [...list]
				// sort by state
				.sort((a, b) => (a.odpojeno === b.odpojeno ? 0 : a.odpojeno ? 1 : -1))
				// sort by name
				.sort((a, b) =>
					`${a.firma} ${a.prijmeni} ${a.jmeno}`.localeCompare(
						`${b.firma} ${b.prijmeni} ${b.jmeno}`
					)
				)
				// sort by type
				.sort((a, b) =>
					!a.typSchranky || !b.typSchranky
						? 0
						: DatoveSchrankyTypSchranky[a.typSchranky] -
						  DatoveSchrankyTypSchranky[b.typSchranky]
				);
