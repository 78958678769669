import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	MojePodaniDatovaZpravaContextProvider,
	PageCode,
	useMojePodaniDetailContextInstance,
} from '@gov-nx/module/page';
import { ContentLayout } from '@gov-nx/ui/web';
import { MySubmissionMessage } from './MySubmissionMessage';

export interface MySubmissionMessagesProps {
	code: PageCode;
}

export const MySubmissionMessageList = ({
	code,
}: MySubmissionMessagesProps) => {
	const { messages } = useMojePodaniDetailContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<div>
			<ul className={'gov-list--plain flex flex-col gap-8'}>
				{messages.map((message, index) => {
					if (message.isDeleted) {
						return (
							<ContentLayout>
								<GovMessage
									variant={'warning'}
									key={message.message?.datovaZpravaId}>
									{ts('status.zprava-byla-smazana')}
								</GovMessage>
							</ContentLayout>
						);
					}

					return (
						<MojePodaniDatovaZpravaContextProvider
							key={message.message.datovaZpravaId}
							message={message}
							index={index}
							openFirstOnInit>
							<MySubmissionMessage />
						</MojePodaniDatovaZpravaContextProvider>
					);
				})}
			</ul>
		</div>
	);
};
