import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
	documentsPreviewQuery,
	filterErrorStatus,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { equals } from '@gov-nx/core/types';
import { ownDocumentKeys } from './queryKeys';

interface DocumentPreviewHookProps {
	documentId?: number;
}

interface ProcessingData {
	id: number;
	onSuccess?: (data: Blob) => void;
}

export const useDocumentPreview = (props?: DocumentPreviewHookProps) => {
	const [processing, setProcessing] = useState<ProcessingData | undefined>(
		undefined
	);
	const queryClient = useQueryClient();
	const [errorMessage, setErrorMessage] = useState<number[]>([]);

	const documentPreviewQuery = usePoQuery(
		{
			queryKey: ownDocumentKeys().preview(processing?.id),
			queryFn: async () =>
				processing?.id ? documentsPreviewQuery(processing.id) : undefined,
			enabled: !!processing?.id,
			refetchOnWindowFocus: false,
			retry: true,
			cacheTime: 0,
		},
		{
			errorIgnoreFilter: filterErrorStatus(404, (response) => {
				setErrorMessage((state) =>
					processing?.id ? [...state, processing.id] : state
				);
			}),
		}
	);

	useEffect(() => {
		if (props?.documentId) {
			setProcessing({ id: props.documentId });
		}
	}, []);

	useEffect(() => {
		if (documentPreviewQuery.isFetched) {
			if (processing?.onSuccess && documentPreviewQuery.data) {
				processing?.onSuccess(documentPreviewQuery.data);
			}
		}
	}, [documentPreviewQuery.isFetched]);

	return {
		documentPreviewQuery,
		downloadingId: processing?.id,
		fetchFile: (documentId: number, onSuccess: (file: Blob) => void) => {
			const cached = queryClient.getQueryData<Blob>(
				ownDocumentKeys().preview(documentId)
			);
			if (cached) {
				onSuccess(cached);
			}

			setProcessing({ id: documentId, onSuccess });
		},
		isPreviewNotAvailable: (id: number): boolean =>
			!!errorMessage.find(equals(id)),
	};
};
