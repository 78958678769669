import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export const ActionWrapper = ({
	children,
	showCheckboxes,
	link,
}: PropsWithChildren<{ showCheckboxes: boolean; link: string }>) => {
	if (showCheckboxes) {
		return <label className={'flex-1 py-5 no-underline'}>{children}</label>;
	}

	return (
		<Link
			to={link}
			className={'flex-1 py-5 no-underline'}>
			{children}
		</Link>
	);
};
