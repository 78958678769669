import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext } from 'react';
import {
	VypisyudajuSouboryUlozkaDto,
	extractOfDataGetFileIdQuery,
	fetchDataPreviewsQuery,
	usePoMutation,
	usePoQuery,
	getError,
	isResponseStatus,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents } from '@gov-nx/core/events';
import { useProcessControl, useSaveToDocuments } from '@gov-nx/core/hooks';
import { compose, Nullable } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import {
	DataResponse,
	DocumentsDetails,
	HealthDocumentationContextControls,
	HealthDocumentationContextTypes,
} from './context.types';
import {
	getFileIdFromResponse,
	getFormatedDocuments,
	getSubmitObject,
	prepareSubmitData,
} from './utils';
import {
	DataRequestBody,
	ZdravDokumentaceDataPreviewsParams,
	ZdravDokumentaceOperace,
	ZdravDokumentaceZdrojUdaju,
} from './utils.types';

const ZdravDokumentaceContext =
	createContext<Nullable<HealthDocumentationContextTypes>>(null);

interface ZdravDokumentaceContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function ZdravDokumentaceContextProvider({
	children,
	code,
}: ZdravDokumentaceContextProviderProps) {
	const { downloadFile } = useDownloadEvents();
	const { saveToDocuments } = useSaveToDocuments({
		onSuccess: () => {
			setControls({ processLoading: false, submitDocumentId: null });
		},
		onError: (error) =>
			setControls({
				processError: error,
				processLoading: false,
				submitDocumentId: null,
			}),
	});
	const { setControls, controls } =
		useProcessControl<HealthDocumentationContextControls>({
			downloadLoading: false,
			downloadError: null,
			downloadDocumentId: null,
			submitDocumentId: null,
		});
	const sourceKey = ZdravDokumentaceZdrojUdaju.NCP_POSKYTOVATELE_ZDR_SLUZEB;

	const params: ZdravDokumentaceDataPreviewsParams = {
		zdrojUdaju: sourceKey,
		format: 'json',
	};

	const query = usePoQuery<AxiosResponse<DataResponse, void>>(
		{
			queryKey: [sourceKey],
			queryFn: async () => fetchDataPreviewsQuery(params),
			retry: 0,
		},
		{ errorIgnoreFilter: isResponseStatus(400) }
	);

	const documents = query.data ? getFormatedDocuments(query.data.data) : [];

	const saveToFilesMutation = usePoMutation<
		VypisyudajuSouboryUlozkaDto,
		DataRequestBody
	>({
		mutationFn: compose(extractOfDataGetFileIdQuery, prepareSubmitData),
		onError: (error) =>
			setControls({
				processError: error,
				processLoading: false,
				submitDocumentId: null,
			}),
		onSuccess: async (response) => {
			const fileId = getFileIdFromResponse(response);
			if (fileId) {
				saveToDocuments.mutate(fileId);
			} else {
				setControls({
					processError: new GovError('Error: no document id in response.'),
					processLoading: false,
				});
			}
		},
	});

	const downloadMutation = usePoMutation<
		VypisyudajuSouboryUlozkaDto,
		DataRequestBody
	>({
		mutationFn: compose(extractOfDataGetFileIdQuery, prepareSubmitData),
		onError: (error) =>
			setControls({
				downloadError: error,
				downloadLoading: false,
				processLoading: false,
				downloadDocumentId: null,
			}),
		onSuccess: async (response) => {
			const fileId = getFileIdFromResponse(response);
			if (fileId) {
				downloadFile({
					id: fileId,
					callback: () => {
						setControls({
							downloadLoading: false,
							processLoading: false,
							downloadDocumentId: null,
						});
					},
				});
			} else {
				setControls({
					processError: new GovError('Error: no document id in response.'),
					processLoading: false,
				});
			}
		},
	});

	const handleDownload = useCallback(
		async (data: DocumentsDetails) => {
			setControls({
				downloadError: null,
				downloadLoading: true,
				processLoading: true,
				downloadDocumentId: data.sourceIdentifier,
			});
			const submitObject = getSubmitObject(data);
			downloadMutation.mutate({
				operace: ZdravDokumentaceOperace.STAHNOUT,
				...submitObject,
			});
		},
		[downloadMutation, setControls]
	);

	const handleSaveToFiles = useCallback(
		async (data: DocumentsDetails) => {
			setControls({
				processError: null,
				processLoading: true,
				submitDocumentId: data.sourceIdentifier,
			});
			const submitObject = getSubmitObject(data);
			saveToFilesMutation.mutate({
				operace: ZdravDokumentaceOperace.ULOZIT_DO_DOKUMENTU,
				...submitObject,
			});
		},
		[saveToFilesMutation, setControls]
	);

	return (
		<ZdravDokumentaceContext.Provider
			value={{
				code,
				controls: {
					...controls,
					initialLoading: query.isLoading || query.isFetching,
					initialError: getError(query.error, { ignoreStatus: 400 }),
				},
				documents,
				setControls,
				handleDownload,
				handleSaveToFiles,
			}}>
			{children}
		</ZdravDokumentaceContext.Provider>
	);
}

export const useZdravDokumentaceContext = (): HealthDocumentationContextTypes =>
	useContext(ZdravDokumentaceContext) as HealthDocumentationContextTypes;
