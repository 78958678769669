import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';
import {
	filterErrorStatus,
	NotificationsFilter,
	NotificationsOrder,
	notificationsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useNotificationDetail } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import { NotificationsContexts } from './context.types';
import { categorizeNotifications, getLink } from './utils';

const UpozorneniContext = createContext<NotificationsContexts | null>(null);

interface NotificationsContextProviderProps {
	children: React.ReactNode;
}

export function UpozorneniContextProvider({
	children,
}: NotificationsContextProviderProps) {
	const { t } = useLocale(PageCode['upozorneni']);
	const queryClient = useQueryClient();

	const { filter, setPartialFilter } = useFilter<NotificationsFilter>({
		key: 'upozorneni',
		initialState: {
			razeni: NotificationsOrder['-datumACasVytvoreni'],
			start: 0,
			pocet: 10,
		},
	});

	const goToPage = (page: number) => {
		const newStart = (page - 1) * filter.pocet;
		setPartialFilter({ start: newStart });
	};

	const currentPage = Math.floor(filter.start / filter.pocet) + 1;

	const query = usePoQuery(
		{
			queryKey: ['notifications', filter],
			queryFn: async () => notificationsQuery(filter),
		},
		{
			errorIgnoreFilter: filterErrorStatus(500),
		}
	);

	const data = query.data?.seznam ?? [];
	const numberOfNotifications = query.data?.pocet ?? 0;

	const selectedNotification = useNotificationDetail();
	const categorizedData = categorizeNotifications(data, t);

	return (
		<UpozorneniContext.Provider
			value={{
				data,
				filter,
				currentPage,
				goToPage,
				numberOfNotifications,
				controls: {
					initialError: query.isError,
					initialLoading: query.isLoading,
				},
				link: selectedNotification.id
					? getLink(data, selectedNotification.id)
					: undefined,
				categorizedData,
				reloadNotifications: async () =>
					queryClient.invalidateQueries({ queryKey: ['notifications'] }),
				selectedNotification,
			}}>
			{children}
		</UpozorneniContext.Provider>
	);
}

export const useUpozorneniContextInstance = (): NotificationsContexts =>
	useContext(UpozorneniContext) as NotificationsContexts;
