export type UrlChecker = (url: string) => boolean;
export type ExtendableUrlChecker = (
	url: string,
	extraChecker?: UrlChecker | null
) => boolean;

const isUrlExternalBase: UrlChecker = (url: string) => {
	if (!url.match(/^https?:\/\//i)) return false;

	const currentUrl = window && window?.location?.href;
	const linkDomain = url.replace(/^https?:\/\//i, '').split('/')[0];
	const windowDomain = currentUrl.replace(/^https?:\/\//i, '').split('/')[0];

	return windowDomain !== linkDomain;
};

export const isUrlExternal: ExtendableUrlChecker = (url, extraChecker) =>
	typeof extraChecker === 'function'
		? extraChecker(url) || isUrlExternalBase(url)
		: isUrlExternalBase(url);
