import { GovIcon } from '@gov-design-system-ce/react';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxMessageAttachmentProvider,
	useDataBoxMessageDetailContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, DocumentDownloadContainer } from '@gov-nx/ui/web';
import { useWindowDimensions } from '@gov-nx/utils/web';
import { DataBoxAttachmentItem } from './DataBoxAttachmentItem';

const MOBILE_WIDTH_MAX = 768;

interface DataBoxAttachmentListProps {
	message: DatoveSchrankyDatovaZpravaDto;
}

export const DataBoxAttachmentList = ({
	message,
}: DataBoxAttachmentListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		folderType,
		showAllAttachments,
		setShowAllAttachments,
		dataBoxId,
		messageId,
		messageOperations: { handleSaveAllAttachmentsToDocuments },
		loadingItems,
	} = useDataBoxMessageDetailContext();

	const { width } = useWindowDimensions();
	const maxItemsCount = width < MOBILE_WIDTH_MAX ? 2 : 4;

	const items = message.prilohy;
	const limitedItems = showAllAttachments
		? items
		: items?.slice(0, maxItemsCount);

	return (
		<section
			className={
				'pt-4 pb-6 px-4 border-t border-secondary-300 text-center bg-neutral-white [ md:p-6 ] [ xl:text-left ]'
			}>
			<div>
				<DocumentDownloadContainer label={tn('prilohy.prilohy')}>
					{limitedItems?.map((attachment, index) => {
						return (
							<DataBoxMessageAttachmentProvider
								key={(attachment.hashPrilohy as string) + index}
								folderType={folderType}
								dataBoxId={dataBoxId}
								messageId={messageId}>
								<DataBoxAttachmentItem attachment={attachment} />
							</DataBoxMessageAttachmentProvider>
						);
					})}
				</DocumentDownloadContainer>

				{(items ?? [])?.length > maxItemsCount && !showAllAttachments && (
					<Button
						variant="primary"
						type="base"
						size="s"
						className={'block mt-6'}
						onClick={() => setShowAllAttachments(true)}
						wcagLabel={tn('prilohy.zobrazit-dalsi', {
							pocet: (items ?? [])?.length - maxItemsCount,
						})}>
						<GovIcon
							slot="left-icon"
							name="chevron-down"
						/>
						<span>
							{tn('prilohy.zobrazit-dalsi', {
								pocet: (items ?? [])?.length - maxItemsCount,
							})}
						</span>
					</Button>
				)}

				<Button
					variant="primary"
					type="base"
					size="s"
					className={'block mt-6'}
					loading={loadingItems.saveAttachmentsToDocuments}
					onClick={() => handleSaveAllAttachmentsToDocuments(message)}
					disabled={!!loadingItems.saveAttachmentsToDocuments}
					wcagLabel={tn('prilohy.ulozit-prilohy')}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.saveAttachmentsToDocuments && (
							<GovIcon
								slot="left-icon"
								name="save"
							/>
						)}
						{tn('prilohy.ulozit-prilohy')}
					</span>
				</Button>
			</div>
		</section>
	);
};
