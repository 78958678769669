import { useCallback } from 'react';
import {
	authMeQuery,
	isUnauthorizedResponse,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import {
	authEvents,
	authLogoutSubscription,
	userInactiveSubscription,
} from '@gov-nx/core/events';
import {
	ApiCallback,
	useApiCallback,
	useStorageListener,
	useUserInactivity,
} from '@gov-nx/core/hooks';
import { Time } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	useAuthStore,
} from '@gov-nx/store/portal-obcana';

const PERSIST_AUTH_KEY = [PERSIST_ZUSTAND_STORE_KEY, StoreKeys.auth].join(':');

export const useAuthMeQuery = () => {
	const { setCallback, onError, onSuccess } = useApiCallback();
	const { setUser, logout } = useAuthStore();
	const userInactivity = useUserInactivity(
		{
			inactiveTimeout: Time['19 minutes'],
			autoLogoutTimeout: Time['20 minutes'],
			onInactive: () => authEvents.publish(userInactiveSubscription()),
			onLogout: () =>
				authEvents.publish(authLogoutSubscription({ enforce: true })),
		},
		window
	);

	useStorageListener(PERSIST_AUTH_KEY, logout, window);

	const meQuery = usePoQuery({
		queryKey: ['po-auth-me'],
		queryFn: authMeQuery,
		onSuccess: (data) => {
			setUser(data);

			// maybe check for window.document ? or somehow disable this in native app
			if (window.document) {
				userInactivity.start();
			}

			onSuccess();
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				onError(e as Error);
			}
		},
		enabled: false,
		retry: 1,
	});

	const fetch = useCallback(
		async ({ ...args }: ApiCallback<undefined, Error>): Promise<void> => {
			setCallback({ ...args });
			await meQuery.refetch();
		},
		[meQuery, setCallback]
	);

	return { fetch };
};
