import {
	ePetitionAttachmentsUpdateQuery,
	ePetitionDeleteAttachmentQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	DefaultUploadParameters,
	FileState,
	FileUploadItem,
	hasFileId,
} from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import { usePetitionAttachmentsQuery } from './hooks/usePetitionAttachmentsQuery';

export const usePetitionAttachments = (petitionId: number) => {
	const { t } = useLocale(PageCode['epetice-nova']);
	const attachmentsLoading = useBoolean();

	const { toastMessageSuccess } = useMessageEvents();
	const petitionAttachments = usePetitionAttachmentsQuery(petitionId);

	const uploadAttachment = usePoMutation({
		mutationFn: ePetitionAttachmentsUpdateQuery,
	});

	const deleteAttachmentMutation = usePoMutation({
		mutationFn: ePetitionDeleteAttachmentQuery,
		onSuccess: async () => {
			await petitionAttachments.invalidate();
			toastMessageSuccess(t('formular.zprava.priloha-smazana'));
		},
	});

	return {
		isUploading: uploadAttachment.isLoading || attachmentsLoading.value,
		invalidate: petitionAttachments.invalidate,
		options: {
			...DefaultUploadParameters,
			extensions: [
				'.xml',
				'.pdf',
				'.doc',
				'.docx',
				'.odt',
				'.jpg',
				'.jpeg',
				'.png',
			],
			maxSumFileSize: 15 * 1024 * 1024,
			maxAttachments: 10,
			multiple: true,
			initialFiles: petitionAttachments.files,
			onAllFilesUploaded: async (
				files: FileUploadItem[]
			): Promise<FileUploadItem[]> => {
				await Promise.all(
					files.filter(hasFileId).map(({ fileId }) => {
						return uploadAttachment.mutateAsync({
							petitionId,
							files: [fileId],
						});
					})
				);
				const attachments = await petitionAttachments.refetch();

				attachmentsLoading.setFalse();

				return attachments;
			},
			onFilesChanged: (
				files: FileUploadItem[],
				isAllFilesUploaded: boolean
			) => {
				attachmentsLoading.setValue(!isAllFilesUploaded);
			},
			onFileRemoved: (file: FileUploadItem) => {
				// Do not delete file that was not successfully uploaded at first place
				if (file.state !== FileState.success) {
					return;
				}

				// Do not delete file that was uploaded but not saved as petition attachment
				if (file.id.toLowerCase().startsWith('GovFormFile'.toLowerCase())) {
					return;
				}

				deleteAttachmentMutation.mutate({
					petitionId,
					attachmentId: parseInt(file.id, 10),
				});
			},
		},
	};
};
