import cx from 'classnames';
import React from 'react';

export interface ContentLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const ContentLayout = ({
	children,
	customClasses,
}: ContentLayoutProps) => {
	return (
		<section
			className={cx(
				'p-4 -mx-4 rounded bg-neutral-white [ md:pt-8 md:pr-10 md:pb-10 md:pl-8 md:mx-0 ]',
				customClasses
			)}>
			{children}
		</section>
	);
};
