import { GovIcon } from '@gov-design-system-ce/react';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode, useRegistrRidicuContext } from '@gov-nx/module/page';
import {
	Button,
	CommaSpace,
	DataTable,
	InfoBanner,
	Space,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { getTextColor, toDateReadable } from '@gov-nx/utils/common';

export interface DriversRegistryCompetencyViewProps {
	code: PageCode;
}

export const DriversRegistryCompetencyView = ({
	code,
}: DriversRegistryCompetencyViewProps) => {
	const { zadostNovyRidicskyPrukazUrl } = useConfiguration();
	const { data } = useRegistrRidicuContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<section className={'flex flex-col gap-6'}>
			<Tiles>
				<Tile
					name={ts('ridicsky-prukaz')}
					icon={{ name: 'chevron-right', type: 'basic' }}
					customClasses={'[ last-of-type:border-b ]'}>
					<p className={'text-secondary-700 mb-2'}>
						{ts('cislo-prukazu')}: <strong>{data?.license?.id}</strong>
						{data?.license?.validFrom && (
							<>
								<CommaSpace />
								{ts('platnost-od')}
								<Space />
								<strong>{toDateReadable(data.license.validFrom)}</strong>
							</>
						)}
						{data?.license?.validTo && (
							<>
								<CommaSpace />
								{ts('platnost-do')}
								<Space />
								<strong
									className={getTextColor(data.license.validTo).classname}>
									{toDateReadable(data.license.validTo)}
								</strong>
							</>
						)}
					</p>
					{data?.license?.canChange && !!zadostNovyRidicskyPrukazUrl && (
						<Button
							enforceLink={true}
							href={zadostNovyRidicskyPrukazUrl}
							target="_blank"
							type="outlined"
							variant="primary"
							wcagLabel={ts('akce.zazadat-o-novy')}>
							{ts('akce.zazadat-o-novy')}
							<GovIcon name="box-arrow-up-right" />
						</Button>
					)}
				</Tile>
			</Tiles>

			<section className={'py-2'}>
				<h2 className={'mb-4 text-xl'}>{ts('ridicska-opravneni')}</h2>
				{data?.hasCompetencies ? (
					<DataTable responsive>
						<tbody>
							{data?.competencies?.map((competency) => (
								<tr key={competency.id}>
									<td>
										{ts('data.skupina')} {competency.group}
									</td>
									<td>
										{competency.validFrom && (
											<strong>
												{ts('data.od')} {toDateReadable(competency.validFrom)}
											</strong>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</DataTable>
				) : (
					<InfoBanner
						icon={{ name: 'empty-file', type: 'colored' }}
						type="secondary"
						className={'!px-0 !py-4'}>
						<p className={'text-2xl [ md:text-3xl ]'}>
							{ts('stav.chyba.zadne-ridicske-opravneni')}
						</p>
						<p>{ts('stav.chyba.zadne-ridicske-opravneni-popis')}</p>
					</InfoBanner>
				)}
			</section>

			<section className={'py-2'}>
				<h2 className={'mb-4 text-xl'}>{ts('profesni-zpusobilost')}</h2>
				{data?.hasQualification ? (
					<DataTable responsive>
						<tbody>
							{data?.qualification?.groups.map((group) => (
								<tr key={data.qualification?.id}>
									<td>
										{ts('data.skupina')} {group}
									</td>
									<td>
										{data?.qualification?.validTo && (
											<strong>
												{ts('data.do')}
												<Space />
												{toDateReadable(data.qualification.validTo)}
											</strong>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</DataTable>
				) : (
					<InfoBanner
						icon={{ name: 'empty-file', type: 'colored' }}
						type="secondary"
						className={'!px-0 !py-4'}>
						<p className={'text-2xl [ md:text-3xl ]'}>
							{ts('stav.chyba.zadna-profesni-zpusobilost')}
						</p>
						<p>{ts('stav.chyba.zadna-profesni-zpusobilost-popis')}</p>
					</InfoBanner>
				)}
			</section>
		</section>
	);
};
