import {
	memo,
	PropsWithChildren,
	ReactNode,
	ElementType,
	ComponentType,
} from 'react';
import { Nullable } from '@gov-nx/core/types';
import { normalizeStr } from './normalizeStr';

type Props = {
	text: string;
	search: string;
	HighlightComp?: ComponentType<PropsWithChildren> | ElementType;
	TextComp?: ComponentType<PropsWithChildren> | ElementType;
	truncate?: boolean;
	isNative?: boolean;
};

export const HighlightText = ({
	text,
	search,
	TextComp = 'span',
	HighlightComp = 'span',
	truncate = true,
	isNative = false,
}: Props) => {
	const parts: ReactNode[] = [];
	const highlightColor = '#faecc8';

	if (search.length < 2) {
		parts.push(
			<TextComp
				key={0}
				truncate={truncate.toString()}>
				{text}
			</TextComp>
		);
	} else {
		const normalizedSearch = normalizeStr(search);
		const normalizedText = normalizeStr(text);
		const searchLength = normalizedSearch.length;
		let position: Nullable<number> = null;
		let prevPosition: Nullable<number> = null;

		while (position !== -1) {
			position = normalizedText.indexOf(
				normalizedSearch,
				position === null ? 0 : position + normalizedSearch.length
			);
			if (position !== -1) {
				if (prevPosition === null) {
					parts.push(
						<TextComp
							key={`first-${position}`}
							truncate={truncate.toString()}>
							{text.slice(0, position)}
						</TextComp>
					);
				} else if (prevPosition + searchLength < position) {
					parts.push(
						<TextComp
							key={`middle-${position}`}
							truncate={truncate.toString()}>
							{text.slice(prevPosition + searchLength, position)}
						</TextComp>
					);
				}
				parts.push(
					<HighlightComp
						key={position}
						style={{ backgroundColor: highlightColor }}>
						{text.slice(position, position + searchLength)}
					</HighlightComp>
				);
				prevPosition = position;
			} else if (prevPosition === null) {
				parts.push(
					<TextComp
						key={position}
						truncate={truncate.toString()}>
						{text}
					</TextComp>
				);
			} else {
				parts.push(
					<TextComp
						key={position}
						truncate={truncate.toString()}>
						{text.slice(prevPosition + searchLength)}
					</TextComp>
				);
			}
		}
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{parts}</>;
};

export const HighlightTextMemo = memo(HighlightText);
