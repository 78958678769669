import { axiosInstance } from '../axios';
import { PeticeAdresaDto } from '../generated';

export const addressLookupQuery = async <Params>(
	params: Params
): Promise<PeticeAdresaDto[]> => {
	const { data } = await axiosInstance.get('/api/v1/adresy', { params });

	return data.seznam;
};
