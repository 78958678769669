import { GovIcon } from '@gov-design-system-ce/react';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useDataBoxesContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

export const DataBoxAddCreditModal = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { creditModalDataBoxId, closeCreditModal } = useDataBoxesContext();
	const { datovaSchrankaDobitiKredituUrl } = useConfiguration();

	return (
		<Prompt
			label={tn('kredit.presmerovani')}
			open={!!creditModalDataBoxId}
			onGov-close={closeCreditModal}>
			<p>{tn('kredit.dobijete')}</p>

			<Button
				variant={'primary'}
				nativeType={'button'}
				type={'base'}
				onClick={closeCreditModal}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.zrusit')}>
				{tn('kredit.zrusit')}
			</Button>

			<Button
				href={datovaSchrankaDobitiKredituUrl.replace(
					'{{DS_ID}}',
					creditModalDataBoxId as string
				)}
				enforceLink={true}
				target={'_blank'}
				variant={'primary'}
				type={'solid'}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.prejit')}>
				<GovIcon
					slot="right-icon"
					name="box-arrow-up-right"
				/>
				{tn('kredit.prejit')}
			</Button>
		</Prompt>
	);
};
