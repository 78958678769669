import React from 'react';
import { DokumentyDokumentDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';

interface DeletePromptProps {
	code: PageCode;
	documentToDelete?: DokumentyDokumentDto;
	onClose: () => void;
	onDelete: () => void;
}

export const DeletePrompt = ({
	code,
	documentToDelete,
	onClose,
	onDelete,
}: DeletePromptProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<Prompt
			label={tsn('vlastni-doklady.mazani.titulek')}
			open={!!documentToDelete}
			onGov-close={() => onClose()}>
			<PoMarkdownRenderWeb>
				{tsn('vlastni-doklady.mazani.popis', {
					nazevDokladu: documentToDelete?.nazev ?? '',
				})}
			</PoMarkdownRenderWeb>
			<Button
				type={'base'}
				variant={'primary'}
				onClick={() => onClose()}
				slot={'actions'}
				wcagLabel={tsn('vlastni-doklady.akce.mazani-zrusit')}>
				{tsn('vlastni-doklady.akce.mazani-zrusit')}
			</Button>
			<Button
				type={'solid'}
				variant={'error'}
				onClick={() => onDelete()}
				slot={'actions'}
				wcagLabel={tsn('vlastni-doklady.akce.mazani-potvrdit')}>
				{tsn('vlastni-doklady.akce.mazani-potvrdit')}
			</Button>
		</Prompt>
	);
};
