import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useEPeticeEditContext } from '@gov-nx/module/page';
import { LayoutGap } from '@gov-nx/ui/web';
import { UseCommunicationDataAddress } from './UseCommunicationDataAddress';
import { UseCommunicationDataEmail } from './UseCommunicationDataEmail';
import { UseCommunicationDataPhone } from './UseCommunicationDataPhone';
import { ElementIds } from './utils';

export const WizardStep2ContactDetails = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const context = useEPeticeEditContext();
	const {
		formStep2,
		code,
		isProcessing,
		personCommunicationData,
		zakladatelTyp,
		email,
		phone,
		resetEmailField,
		resetPhoneField,
	} = context;
	const tsn = getLocalizeCurried(code);

	if (!formStep2) return null;

	return (
		<LayoutGap gap={4}>
			<h2
				className={'text-2xl'}
				id={ElementIds.zakladatel}>
				{tsn('formular.nadpis.kontakt-zakladatel')}
			</h2>

			<UseCommunicationDataEmail
				email={email}
				personCommunicationData={personCommunicationData}
				fields={{
					_useEmail: {
						name: '_useEmail',
						disabled: isProcessing,
						label: {
							children: `${tsn('formular.pole.kontakt-email')}:`,
						},
					},
					email: {
						name: 'email',
						placeholder: tsn('formular.placeholder.email'),
					},
				}}
				onReset={resetEmailField}
				wcagLabels={{
					edit: tsn('formular.akce.wcag.upravit-email'),
					cancel: tsn('formular.tlacitko.zrusit'),
				}}
			/>

			<UseCommunicationDataPhone
				personCommunicationData={personCommunicationData}
				fields={{
					_usePhone: {
						name: '_usePhone',
						disabled: isProcessing,
						label: {
							children: `${tsn('formular.pole.kontakt-telefon')}:`,
						},
					},
					phone: {
						name: 'phone',
						placeholder: tsn('formular.placeholder.telefon'),
					},
				}}
				phone={phone}
				wcagLabels={{
					edit: tsn('formular.akce.wcag.upravit-tel'),
					cancel: tsn('formular.tlacitko.zrusit'),
				}}
				onReset={resetPhoneField}
			/>

			{zakladatelTyp === 'FO' ? (
				<UseCommunicationDataAddress
					personCommunicationData={personCommunicationData}
					fields={{
						_useAddress: {
							name: '_useAddress',
							disabled: isProcessing,
							label: {
								children: `${tsn('formular.pole.kontakt-adresa')}:`,
							},
						},
						address: {
							name: 'adresa',
							placeholder: tsn('formular.placeholder.adresa'),
						},
					}}
					onReset={personCommunicationData.control.isEditingAddress.setFalse}
					address={formStep2.formDefinition.formMethods.getValues(
						'adresa.adresaText'
					)}
					wcagLabels={{
						cancel: tsn('formular.tlacitko.zrusit'),
						edit: tsn('formular.akce.wcag.upravit-tel'),
					}}
				/>
			) : null}
		</LayoutGap>
	);
};
