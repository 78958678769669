import React, {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
} from 'react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxConnect } from '../../hooks/connect/useDataBoxConnect';
import {
	DataBoxConnectContextType,
	DataBoxConnectProcessControl,
} from './ConnectContext.types';

export const DataBoxConnectContext =
	createContext<Nullable<DataBoxConnectContextType>>(null);

interface DataBoxConnectProviderProps extends PropsWithChildren {
	sessionId: string;
	code: PageCode;
}

export function DataBoxConnectProvider({
	sessionId,
	children,
}: DataBoxConnectProviderProps) {
	const { refreshList, unreadMessagesCountsRefetch } = useDataBoxEvents();
	const { controls, setControls } =
		useProcessControl<DataBoxConnectProcessControl>({
			connectingProcess: false,
			connectingError: null,
			displaySetting: false,
		});

	const { connect } = useDataBoxConnect();

	const connectDataBox = useCallback(async () => {
		setControls({ connectingProcess: true });
		const databoxId = await connect(sessionId);

		if (typeof databoxId !== 'string') {
			throw new Error('Datovka nepripojena');
		} else {
			await new Promise((resolve, reject) => {
				refreshList({
					callback: () => {
						unreadMessagesCountsRefetch({
							callback: () => resolve(databoxId),
						});
					},
				});
			});
			return databoxId;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [connect, sessionId, setControls]);

	return (
		<DataBoxConnectContext.Provider
			value={{ controls, setControls, connectDataBox }}>
			{children}
		</DataBoxConnectContext.Provider>
	);
}

export const useDataBoxConnectContext = (): DataBoxConnectContextType =>
	useContext(DataBoxConnectContext) as DataBoxConnectContextType;
