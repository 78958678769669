import {
	DatoveSchrankyDatovaZpravaDto,
	loadDataBoxMessagePoQuery,
	loadDataBoxMessagesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';

export interface DataBoxMessagesLoaderProps {
	onError: (error: GovError) => void;
	messageIds: number[];
}

export const useDataBoxMessagesLoader = ({
	messageIds,
	onError,
}: DataBoxMessagesLoaderProps) =>
	usePoQuery<DatoveSchrankyDatovaZpravaDto[]>({
		queryKey: ['data-box-messages', messageIds],
		queryFn: () => loadDataBoxMessagesQuery(messageIds),
		onError,
		enabled: !!messageIds.length,
		retry: 0,
		refetchOnWindowFocus: false,
	});

export interface DataBoxMessagePoLoaderProps {
	onError: (error: GovError) => void;
	messageId: Nullable<number>;
}

export const useDataBoxMessagePoLoader = ({
	messageId,
	onError,
}: DataBoxMessagePoLoaderProps) => {
	const query = usePoQuery<DatoveSchrankyDatovaZpravaDto>({
		queryKey: ['data-box-message', messageId],
		queryFn: () => loadDataBoxMessagePoQuery(messageId as number),
		onError,
		enabled: !!messageId,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return { query };
};
