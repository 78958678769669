import { GovWizard } from '@gov-design-system-ce/react';
import React from 'react';
import { WizardFormStep } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import {
	FormPersonData,
	FormProblemData,
	PageCode,
	usePodporaContext,
} from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner } from '@gov-nx/ui/web';
import { WizardStepWeb } from '../../Wizard/WizardStepWeb';
import { SupportPersonForm } from './SupportPersonForm';
import { SupportProblemForm } from './SupportProblemForm';
import { SupportRecapitulation } from './SupportRecapitulation';
import { SupportSkeleton } from './SupportSkeleton';

export interface FormProps {
	code: PageCode;
}

export const SupportView = ({ code }: FormProps) => {
	const { wizard, controls } = usePodporaContext();
	const { t } = useLocale(code);

	if (controls.initialLoading) {
		return <SupportSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<ContentLayout customClasses={'bg-transparent px-0 [ md:!p-0 ]'}>
			<GovWizard wcagLabel={t('formular.zprava.podpora')}>
				<WizardStepWeb
					index={0}
					label={t('formular.zprava.udaje-odesilatele')}
					wizard={wizard}>
					<SupportPersonForm
						formMethods={
							(wizard.step(0) as unknown as WizardFormStep<FormPersonData>)
								.formDefinition
						}
					/>
				</WizardStepWeb>

				<WizardStepWeb
					index={1}
					label={t('formular.zprava.popis-problemu')}
					wizard={wizard}>
					<SupportProblemForm
						formMethods={
							(wizard.step(1) as unknown as WizardFormStep<FormProblemData>)
								.formDefinition
						}
					/>
				</WizardStepWeb>

				<WizardStepWeb
					index={2}
					label={t('formular.zprava.rekapitulace')}
					wizard={wizard}>
					<SupportRecapitulation
						code={code}
						formMethods={
							(wizard.step(1) as unknown as WizardFormStep<FormProblemData>)
								.formDefinition
						}
						isProcessing={controls.processLoading}
					/>
				</WizardStepWeb>
			</GovWizard>
		</ContentLayout>
	);
};
