import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ProfilROSSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<LayoutGap gap={10}>
					<div className={'flex gap-4'}>
						<GovSkeleton
							width={'120px'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							width={'85px'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							width={'130px'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							width={'110px'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							width={'90px'}
							height={'40px'}
							wcagLabel={
								tg('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<LayoutGap gap={8}>
						<LayoutGap gap={4}>
							<GovSkeleton
								width={'220px'}
								height={'24px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<div>
								<GovSkeleton
									width={'100%'}
									height={'21px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'60%'}
									height={'21px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
							<DataTable responsive>
								<tbody>
									<tr>
										<td className={'md:!w-1/2'}>
											<GovSkeleton
												width={'120px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={'80px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<GovSkeleton
												width={'85px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={generateRandomNumber(40, 80) + '%'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<GovSkeleton
												width={'130px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={'60px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<GovSkeleton
								width={'170px'}
								height={'40px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</LayoutGap>
						<LayoutGap gap={4}>
							<GovSkeleton
								width={'130px'}
								height={'24px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<div>
								<GovSkeleton
									width={'100%'}
									height={'21px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'50%'}
									height={'21px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
							<DataTable responsive>
								<tbody>
									<tr>
										<td className={'md:!w-1/2'}>
											<GovSkeleton
												width={'40px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={generateRandomNumber(30, 50) + '%'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<GovSkeleton
												width={'50px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={'110px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
								</tbody>
							</DataTable>
						</LayoutGap>
						<LayoutGap gap={4}>
							<GovSkeleton
								width={'260px'}
								height={'24px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<DataTable responsive>
								<tbody>
									<tr>
										<td className={'md:!w-1/2'}>
											<GovSkeleton
												width={'35px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={generateRandomNumber(60, 90) + '%'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<GovSkeleton
												width={'120px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={'75px'}
												height={'19px'}
												wcagLabel={
													tg('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<GovSkeleton
								width={'170px'}
								height={'40px'}
								wcagLabel={
									tg('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</LayoutGap>
					</LayoutGap>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
