import { useEffect } from 'react';
import * as yup from 'yup';
import { CiselnikyVolbyKoloDto } from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	getFormDefinition,
	useLocale,
	usePoForm,
} from '@gov-nx/core/service';
import { equals, Optional, pairs, prop } from '@gov-nx/core/types';
import { getSelectedRoundKeys, ServiceCode } from '@gov-nx/module/service';
import { toDateReadable } from '@gov-nx/utils/common';
import { FormDataStep1 } from './context.types';
import { CheckboxProps, IFormStep1 } from './form.types';
import { convertToRoman, electionRoundDefaultValues } from './formDefinitions';

interface FormStep1Props {
	code: ServiceCode;
	rounds: CiselnikyVolbyKoloDto[];
	isProcessing: boolean;
	isDisabled: boolean;
}

export const hasDatesProperties = (
	object: CiselnikyVolbyKoloDto
): object is Required<Pick<CiselnikyVolbyKoloDto, 'terminOd' | 'terminDo'>> &
	CiselnikyVolbyKoloDto => {
	return !!(object.terminOd && object.terminDo);
};

const noneIsTrue = (...fields: Optional<boolean>[]) =>
	!fields.some(equals(true));

export const FormStep1 = ({
	code,
	rounds,
	isProcessing,
	isDisabled,
}: FormStep1Props): IFormStep1 => {
	const { t } = useLocale(code);

	const checkboxRequiredMessage = t('formular.validace.kolo.povinny');

	const electionRoundFields: CheckboxProps[] = rounds
		.filter(hasDatesProperties)
		.map((round, index) => {
			const key = `${index + 1}`;
			return {
				key,
				name: `electionRound.${key}`,
				disabled:
					!round.lzePodatZadost ||
					round.zadostPodana ||
					isProcessing ||
					isDisabled,
				label: {
					children: t('formular.pole.kolo', {
						kolo: convertToRoman(key) ?? '',
						od: toDateReadable(round.terminOd),
						do: toDateReadable(round.terminDo),
					}),
				},
			};
		});

	const keys = electionRoundFields.map(prop('key'));

	const restOfCheckboxes = (exceptKey: string): string[] =>
		keys.filter((key) => key !== exceptKey);

	const electionRoundSchema = keys.reduce((all, key) => {
		return {
			...all,
			[key]: yup
				.boolean()
				.optional()
				.when(restOfCheckboxes(key), {
					is: noneIsTrue,
					then: yup
						.boolean()
						.oneOf([true], checkboxRequiredMessage)
						.required(checkboxRequiredMessage),
				}),
		};
	}, {});

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep1>>({
			electionRound: yup
				.object()
				.shape(electionRoundSchema, pairs(keys))
				.required(checkboxRequiredMessage),
		})
		.required(checkboxRequiredMessage);

	const electionRoundDefaults = electionRoundDefaultValues(rounds);

	const formMethods = usePoForm<FormDataStep1>({
		formSchema,
		defaultValues: {
			electionRound: electionRoundDefaults,
		},
	});

	useEffect(() => {
		formMethods.reset({ electionRound: electionRoundDefaults });
	}, [keys.length]);

	const electionRound = formMethods.watch('electionRound');
	const isNoRoundSelected = getSelectedRoundKeys(electionRound).length === 0;

	return {
		fields: {
			electionRound: electionRoundFields,
			submit: {
				isLoading: isProcessing,
				isDisabled: isProcessing || isDisabled || isNoRoundSelected,
				label: t('formular.tlacitko.pokracovat'),
				wcagLabel: t('formular.tlacitko.pokracovat'),
			},
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
};
