import {
	downloadDataBoxMessageQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { OnMessageSaveParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface MessageDownloadProps {
	onError: (messageId: number, e: Error) => void;
	onSuccess: (data: OnMessageSaveParams) => void;
}

export const useMessageDownload = (props: MessageDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: downloadDataBoxMessageQuery,
		onError: (error, messageData) => {
			props.onError &&
				props.onError(
					messageData.message.datovaZpravaId as number,
					error as GovError
				);
		},
		onSuccess: async (response, messageData) => {
			if (response.status === 202) {
				await delay(2000);
				await mutation.mutate(messageData);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props.onSuccess({
						antivirus,
						fileName,
						blob,
						id: messageData.message.datovaZpravaId as number,
					});
				} catch (e) {
					props.onError(
						messageData.message.datovaZpravaId as number,
						e as Error
					);
				}
			}
		},
	});
	return mutation;
};
