export enum ServiceCode {
	'vypis-z-rejstriku-trestu-FO' = 'vypis-z-rejstriku-trestu-FO',
	'vypis-z-rejstriku-trestu-PO' = 'vypis-z-rejstriku-trestu-PO',
	'verejny-vypis-z-ROS' = 'verejny-vypis-z-ROS',
	'neverejny-vypis-z-ROS' = 'neverejny-vypis-z-ROS',
	'vypis-z-zivnostenskeho-rejstriku' = 'vypis-z-zivnostenskeho-rejstriku',
	'neverejny-vypis-z-ZR' = 'neverejny-vypis-z-ZR',
	'vypis-vyuziti-udaju-z-ROB' = 'vypis-vyuziti-udaju-z-ROB',
	'vypis-vyuziti-udaju-z-ROS' = 'vypis-vyuziti-udaju-z-ROS',
	'vypis-ze-seznamu-KD' = 'vypis-ze-seznamu-KD',
	'vypis-z-IR' = 'vypis-z-IR',
	'zadost-o-osvedceni-DIG-ukonu' = 'zadost-o-osvedceni-DIG-ukonu',
	'vypis-bodoveho-hodnoceni-ridice' = 'vypis-bodoveho-hodnoceni-ridice',
	'vypis-z-verejneho-rejstriku' = 'vypis-z-verejneho-rejstriku',
	'podani-zadosti-o-informace' = 'podani-zadosti-o-informace',
	'potvrzeni-o-studiu' = 'potvrzeni-o-studiu',
	'vypis-udaju-z-ROB' = 'vypis-udaju-z-ROB',
	'zadost-o-poskytovani-udaju' = 'zadost-o-poskytovani-udaju',
	'trestni-oznameni' = 'trestni-oznameni',
	'volicsky-prukaz' = 'volicsky-prukaz',
}
