import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { PageCode } from '../definitions/codes';
import { CertificateFormData } from './context.types';

interface FormDefinitionProps {
	code: PageCode;
}

export function FormInstance({
	code,
}: FormDefinitionProps): FormDefinition<CertificateFormData> {
	const formSchema = yup
		.object({
			nazevSouboru: yup.object().nullable().optional(),
		})
		.required();

	const formMethods = usePoForm<CertificateFormData>({
		formSchema,
		defaultValues: {
			nazevSouboru: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}
