import {
	endOfDay,
	endOfMonth,
	endOfWeek,
	endOfYear,
	isWithinInterval,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subMonths,
	subWeeks,
	subYears,
} from 'date-fns';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { today } from '@gov-nx/utils/common';
import {
	DataBoxTimeDividedMessageList,
	DataBoxTimeDivisionType,
} from '../providers/DataBoxes.types';

export const divideMessagesByTime = (
	originalMessageList: DatoveSchrankyDatovaZpravaDto[],
	sortedBy: 'datumACasDodani' | 'datumSmazani' = 'datumACasDodani'
) => {
	const messageList = [...originalMessageList];
	const list: DataBoxTimeDividedMessageList = [];

	const todayDate = today();

	const timePeriodList = [
		{
			label: DataBoxTimeDivisionType.Today,
			start: startOfDay(todayDate),
			end: endOfDay(todayDate),
		},
		{
			label: DataBoxTimeDivisionType.ThisWeek,
			start: startOfWeek(todayDate, { weekStartsOn: 1 }),
			end: endOfWeek(todayDate, { weekStartsOn: 1 }),
		},
		{
			label: DataBoxTimeDivisionType.LastWeek,
			start: startOfWeek(subWeeks(todayDate, 1), { weekStartsOn: 1 }),
			end: endOfWeek(subWeeks(todayDate, 1), { weekStartsOn: 1 }),
		},
		{
			label: DataBoxTimeDivisionType.ThisMonth,
			start: startOfMonth(todayDate),
			end: endOfMonth(todayDate),
		},
		{
			label: DataBoxTimeDivisionType.LastMonth,
			start: startOfMonth(subMonths(todayDate, 1)),
			end: endOfMonth(subMonths(todayDate, 1)),
		},
		{
			label: DataBoxTimeDivisionType.ThisYear,
			start: startOfYear(todayDate),
			end: endOfYear(todayDate),
		},
		{
			label: DataBoxTimeDivisionType.LastYear,
			start: startOfYear(subYears(todayDate, 1)),
			end: endOfYear(subYears(todayDate, 1)),
		},
		{
			label: DataBoxTimeDivisionType.Older,
		},
	];

	let startPeriod = 0;

	messageList.sort((a, b) =>
		(a[sortedBy] as string) > (b[sortedBy] as string) ? -1 : 1
	);

	messageList.forEach((message) => {
		const date = today(message[sortedBy] as string);
		let dateLabel = DataBoxTimeDivisionType.Older;

		for (let i = startPeriod; i < timePeriodList.length; i++) {
			const timePeriodItem = timePeriodList[i];

			if (
				i === timePeriodList.length - 1 ||
				!timePeriodItem.start ||
				!timePeriodItem.end
			) {
				break;
			} else if (
				isWithinInterval(date, {
					start: timePeriodItem.start as Date,
					end: timePeriodItem.end as Date,
				})
			) {
				dateLabel = timePeriodItem.label;
				startPeriod = i;
				break;
			}
		}

		const item = list.find((item) => item.label === dateLabel);
		if (item) {
			item.messages.push(message);
		} else {
			list.push({
				label: dateLabel,
				messages: [message],
			});
		}
	});

	return list;
};
