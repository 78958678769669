export const delay = (time: number): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
};

export function throttle(f: (entries: any) => void, delay: number) {
	let timer: any = 0;
	return function (...args: any[]) {
		clearTimeout(timer);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		timer = setTimeout(() => f.apply(this, args), delay);
	};
}

export function debounce(callback: () => void, wait: number) {
	let timerId: any = 0;
	return (...args: any[]) => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			callback(...args);
		}, wait);
	};
}
