import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CommonSettingKey,
	FavoriteCode,
	saveUserSettingQuery,
	usePoMutation,
	UserSetting,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { PageCode } from '@gov-nx/module/page';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { isPageCode } from '../../../../module/page/src/types/pageTypeHelpers';

const favoriteCodeToI18nKeypart = (code: FavoriteCode) =>
	isPageCode(code) ? 'stranka' : 'sluzba';

const blacklistedCodes: FavoriteCode[] = [
	PageCode['podpora'],
	PageCode['uzivatelska-prirucka'],
];

export interface FavoriteToggleCommonProps {
	code: FavoriteCode;
	name?: string;
	size?: JSX.GovButton['size'];
	withLabel?: boolean;
}

export const useFavoriteToggle = ({
	code,
	name,
	withLabel = false,
}: FavoriteToggleCommonProps) => {
	const { poUserFavorites, setUserSetting } = useUserStore();
	const favorites = poUserFavorites();
	const isFavorite = favorites.includes(code);

	const { t } = useTranslation();
	const { toastMessageSuccess } = useMessageEvents();

	const i18nStaticParts = ['uzivatel', 'oblibene'];
	const i18nLabelVariableParts = [
		favoriteCodeToI18nKeypart(code),
		isFavorite ? 'odebrat' : 'pridat',
	];
	const i18nToastParts = [
		...i18nStaticParts,
		favoriteCodeToI18nKeypart(code),
		isFavorite ? 'odebrano' : 'pridano',
	];

	const label = t([...i18nStaticParts, ...i18nLabelVariableParts].join('.'));
	const ariaLabel = t(
		[...i18nStaticParts, 'wcag', ...i18nLabelVariableParts].join('.'),
		{ name }
	);

	const newFavoritesSettingOnToggle = useMemo(
		(): UserSetting => ({
			key: CommonSettingKey.Favorites,
			value: isFavorite
				? favorites.filter((item) => item !== code)
				: [...favorites, code],
		}),
		[favorites, code, isFavorite]
	);

	const { mutate, isLoading } = usePoMutation({
		mutationFn: saveUserSettingQuery,
		onSuccess: async () => {
			setUserSetting(newFavoritesSettingOnToggle);
			toastMessageSuccess(t(i18nToastParts.join('.')));
		},
	});

	return {
		isEnabled: !blacklistedCodes.includes(code),
		label,
		ariaLabel,
		isFavorite,
		toggleFavorite: () => mutate(newFavoritesSettingOnToggle),
		isSaving: isLoading,
	};
};
