import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxConfirmModalType,
	DataBoxListStatesType,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { limitNumber } from '@gov-nx/utils/common';

export const DataBoxMessageListFooter = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const {
		listState,
		messagesSelect: { selectedMessageList },
		massOperations,
		setConfirmModal,
		loadingItems,
	} = useDataBoxMessageListContext();

	return (
		<footer className={'px-4 py-6 bg-primary-200 text-center'}>
			{[
				DataBoxListStatesType.MassArchive,
				DataBoxListStatesType.SearchMassArchive,
			].includes(listState) && (
				<Button
					variant="primary"
					type="outlined"
					size="s"
					loading={loadingItems.archive}
					onClick={massOperations.handleArchiveMessages}
					disabled={!selectedMessageList.length || !!loadingItems.archive}
					wcagLabel={
						tn('zpravy.zkopirovat-do-archivu') +
						' ' +
						(!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						))
					}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.archive && (
							<GovIcon
								slot="left-icon"
								name="archive-outline"
							/>
						)}
						{tn('zpravy.zkopirovat-do-archivu')}{' '}
						{!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						)}
					</span>
				</Button>
			)}
			{[
				DataBoxListStatesType.MassRemove,
				DataBoxListStatesType.SearchMassRemove,
			].includes(listState) && (
				<Button
					variant="error"
					type="outlined"
					size="s"
					onClick={() =>
						setConfirmModal(DataBoxConfirmModalType.removeMessages)
					}
					disabled={!selectedMessageList.length}
					wcagLabel={
						tn('zpravy.vypis.presunout-do-kose') +
						' ' +
						(!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						))
					}>
					<span className="inline-flex items-center gap-x-3">
						<GovIcon
							slot="left-icon"
							name="trash"
						/>
						{tn('zpravy.vypis.presunout-do-kose')}{' '}
						{!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						)}
					</span>
				</Button>
			)}
			{[
				DataBoxListStatesType.MassPermanentRemove,
				DataBoxListStatesType.SearchMassPermanentRemove,
			].includes(listState) && (
				<Button
					variant="error"
					type="outlined"
					size="s"
					onClick={() =>
						setConfirmModal(DataBoxConfirmModalType.removeMessagesPermanently)
					}
					disabled={!selectedMessageList.length}
					wcagLabel={
						tn('zpravy.trvale-smazat') +
						' ' +
						(!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						))
					}>
					<span className="inline-flex items-center gap-x-3">
						<GovIcon
							slot="left-icon"
							name="trash"
						/>
						{tn('zpravy.trvale-smazat')}{' '}
						{!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						)}
					</span>
				</Button>
			)}
			{[
				DataBoxListStatesType.MassRestore,
				DataBoxListStatesType.SearchMassRestore,
			].includes(listState) && (
				<Button
					variant="primary"
					type="outlined"
					size="s"
					loading={loadingItems.restore}
					onClick={massOperations.handleRestoreMessages}
					disabled={!selectedMessageList.length || !!loadingItems.restore}
					wcagLabel={
						tn('zpravy.obnovit') +
						(!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						))
					}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.restore && (
							<GovIcon
								slot="left-icon"
								name="arrow-counterclockwise"
							/>
						)}
						{tn('zpravy.obnovit')}{' '}
						{!!selectedMessageList.length && (
							<>({limitNumber(selectedMessageList.length)})</>
						)}
					</span>
				</Button>
			)}
		</footer>
	);
};
