import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { cs, enUS } from 'date-fns/locale';
import { normalizeDate } from 'libs/ui/native/src/Calendar/utilities/normalizeDate';
import { LocalizationLanguage } from '@gov-nx/core/app';
import {
	is,
	isNumber,
	isString,
	Nullable,
	TimeZones,
} from '@gov-nx/core/types';

export const utcToPragueZonedTime = (date: Date) => {
	return utcToZonedTime(date, TimeZones['Europe/Prague']);
};

export const today = (date?: string | number): Date => {
	const pragueTime = (dateObject: Date = new Date()) =>
		utcToPragueZonedTime(dateObject);

	if (isString(date)) {
		return pragueTime(new Date(date));
	}
	if (isNumber(date)) {
		return pragueTime(new Date(date));
	}
	return pragueTime();
};

export const isDifferentTimeZone = (): boolean => {
	const currectTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return currectTimeZone !== TimeZones['Europe/Prague'];
};

const pad = (digits: number) => (n: number) => String(n).padStart(digits, '0');
const pad2: (n: number) => string = pad(2);
const pad4: (n: number) => string = pad(4);

export function toStringDate(date: Date): string;
export function toStringDate(date: string): string;
export function toStringDate(input: Date | string): string {
	const date = typeof input === 'string' ? new Date() : input;
	return [
		pad4(date.getFullYear()),
		pad2(date.getMonth() + 1),
		pad2(date.getDate()),
	].join('-');
}

export const yearsAgo = (years: number) => {
	const date = today();
	date.setFullYear(date.getFullYear() - years);
	return date;
};

export const monthsAgo = (months: number) => {
	const date = today();
	date.setMonth(date.getMonth() - months);
	return date;
};

export function toDateReadable(date: Date): string;
export function toDateReadable(date: string): string;
export function toDateReadable(input: Date | string) {
	const date = typeof input === 'string' ? today(input) : input;
	return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
		.map(pad2)
		.join('.');
}

export function toPragueZonedDateReadable(input: Date) {
	return toDateReadable(utcToPragueZonedTime(input));
}

export const toDateRangeReadable = (from: string, to: string) =>
	[from, to].map(toDateReadable).join(' - ');

export const toDateShortReadable = (date: Date) => {
	return [date.getDate(), date.getMonth() + 1].map(pad2).join('.') + '.';
};

export const toTimeReadable = (date: Date) => {
	return [date.getHours(), date.getMinutes()].map(pad2).join(':');
};

export const toDateTimeReadable = (date: Date | string) => {
	const toDateAndTime = (date: Date) =>
		[toDateReadable(date), toTimeReadable(date)].join(' ');

	if (isString(date)) return toDateAndTime(today(date));

	return toDateAndTime(date);
};

export const toWeekDay = (date: Date, language: LocalizationLanguage) => {
	const languages = {
		[LocalizationLanguage.Czech]: cs,
		[LocalizationLanguage.English]: enUS,
	};

	return format(date, 'EEEE', { locale: languages[language] });
};

export const resetHours = (date: Date) => {
	date.setHours(0, 0, 0, 0);
	return date;
};

export const addDay =
	(days = 1) =>
	(input: Date) => {
		const date = new Date(input.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};

export const toDate = (input: string) => {
	const timestamp = Date.parse(input);
	if (isNaN(timestamp) === false) {
		return new Date(timestamp);
	}
	return;
};

export const isValidDate = (date: Date): boolean => {
	return date instanceof Date && isFinite(date.getTime());
};

export const isDate = (input: unknown): input is Date =>
	is(input) && typeof input === 'object' && isValidDate(input as Date);

export const dateToLocaleFormat = (date: string | Date): Nullable<string> => {
	if (typeof date === 'string') {
		date = today(date);
	}
	if (isValidDate(date)) {
		return date.toLocaleDateString('cs-CZ').replace(/\s/g, '');
	}
	return null;
};

export const xValToValidDate = (input?: string): Nullable<Date> => {
	if (!input) return null;
	const date = new Date(input);
	return isValidDate(date) ? date : null;
};

export const dateDiffInDays = (a: Date, b: Date): number => {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;

	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

export function toDefaultDateStringNormalized(input: Date) {
	return normalizeDate(toStringDate(utcToPragueZonedTime(input))) ?? '';
}
