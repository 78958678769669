import { GovFormControl, GovFormGroup } from '@gov-design-system-ce/react';
import { Controller, GlobalError, get, useFormContext } from 'react-hook-form';
import { is, prop } from '@gov-nx/core/types';
import { WebCheckboxListProps } from '@gov-nx/ui/types';
import { Checkbox } from './FormCheckbox';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

export const FormCheckboxList = ({
	fields,
	control,
	message,
	label,
	isRequired,
	group,
}: WebCheckboxListProps) => {
	const formContext = useFormContext();

	const errors: GlobalError[] = fields
		.map((field) => get(formContext.formState.errors, field.name))
		.filter(is);

	return (
		<GovFormControl {...control}>
			{label && (
				<FormLabel
					slot="top"
					required={isRequired}
					{...label}>
					{label.children}
				</FormLabel>
			)}
			<GovFormGroup {...group}>
				{fields.map((checkbox, index) => {
					return (
						<Controller
							key={checkbox.name}
							name={checkbox.name}
							control={formContext.control}
							render={({ field, fieldState, formState }) => {
								return (
									<div>
										<Checkbox
											name={field.name}
											ref={field.ref}
											invalid={fieldState.invalid}
											disabled={checkbox.disabled}
											checked={field.value}
											onChange={async (event) => {
												field.onChange(event.detail.originalEvent);

												await formContext.trigger(
													fields
														.filter(({ name }) => name !== field.name)
														.map(prop('name'))
												);

												checkbox['onChange'] && checkbox['onChange'](event);
											}}
											onBlur={(event) => {
												field.onBlur();

												checkbox['onBlur'] && checkbox['onBlur'](event);
											}}
											label={checkbox.label}
										/>
										<div>{checkbox.children}</div>
									</div>
								);
							}}
						/>
					);
				})}
			</GovFormGroup>
			<div
				slot="bottom"
				className={'!mt-0'}>
				{errors.length > 0 && <FormErrorMessage error={errors[0]} />}
				{message && <FormMessage messages={message.messages} />}
			</div>
		</GovFormControl>
	);
};
