import { useQueryClient } from '@tanstack/react-query';
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	createDocumentQuery,
	documentDeleteQuery,
	documentQuery,
	updateDocumentQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FileUploadItem,
	hasNoSuccessFile,
	useDocumentDownload,
} from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { today, useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { ownDocumentKeys } from '../queryKeys';
import { FormInstance, prepareSubmitData } from './FormDefinition';
import { MyOwnDocumentsContext, OwnDocumentFormData } from './context.types';

const VlastniDokladContext =
	createContext<Nullable<MyOwnDocumentsContext>>(null);

interface VlastniDokladyContextProviderProps {
	children: ReactNode;
	code: PageCode;
	documentId?: number;
	onDocumentUpdated: () => void;
}

export const VlastniDokladyContextProvider = ({
	code,
	children,
	documentId,
	onDocumentUpdated,
}: VlastniDokladyContextProviderProps) => {
	const { toastMessageSuccess } = useMessageEvents();
	const { t } = useLocale(code);
	const queryClient = useQueryClient();
	const formDefinition = FormInstance({ code });
	const isDeletePromptOpen = useBoolean(false);
	const hasNotFinishedUploads = useBoolean(false);
	const hasFile = useBoolean(false);
	const documentExist = !!documentId;
	const [imageBlob, setImageBlob] = useState<Blob | null>(null);

	const query = usePoQuery({
		queryKey: ownDocumentKeys().one(documentId),
		queryFn: async () => {
			return documentId ? documentQuery(documentId) : undefined;
		},
		enabled: documentExist,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		const response = query.data;
		if (!response) return;

		hasFile.setValue(!!response.velikost);

		formDefinition.formMethods.reset({
			nazev: response.nazev,
			cisloDokumentu: response.cisloDokumentu,
			komentar: response.komentar,
			platnostOd: response.platnostOd ? today(response.platnostOd) : undefined,
			platnostDo: response.platnostDo ? today(response.platnostDo) : undefined,
			_replaceFile: false,
			nazevSouboru: response.nazevSouboru,
			soubor: undefined,
		});
	}, [query.data]);

	const documentDownloadMutation = useDocumentDownload({
		onSuccess: (data) => {
			setImageBlob(data.blob);
		},
	});

	useEffect(() => {
		if (documentId && hasFile.value)
			documentDownloadMutation.mutate(documentId);
	}, [documentId, hasFile.value]);

	const documentEditMutation = usePoMutation({
		mutationFn: async (formData: OwnDocumentFormData) => {
			const prepared = prepareSubmitData(formData, true);
			return documentId ? updateDocumentQuery(documentId, prepared) : undefined;
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.ulozeno'));
			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({ queryKey: ownDocumentKeys().all });
			onDocumentUpdated();
			await queryClient.invalidateQueries({
				queryKey: ownDocumentKeys().preview(documentId),
			});
		},
	});

	const documentCreateMutation = usePoMutation({
		mutationFn: async (formData: OwnDocumentFormData) => {
			const prepared = prepareSubmitData(formData, false);
			return createDocumentQuery(prepared);
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.ulozeno'));
			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({ queryKey: ownDocumentKeys().all });
			await queryClient.invalidateQueries({
				queryKey: ownDocumentKeys().preview(documentId),
			});

			onDocumentUpdated();
		},
	});

	const ownDocumentDeleteMutation = usePoMutation({
		mutationFn: documentDeleteQuery,
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.smazano'));
			await queryClient.invalidateQueries({ queryKey: ['documents'] });
			await queryClient.invalidateQueries({ queryKey: ['documents-binned'] });
			await queryClient.invalidateQueries({ queryKey: ownDocumentKeys().all });
			onDocumentUpdated();
		},
	});

	const handleEditSubmit = formDefinition.formMethods.handleSubmit(() =>
		documentEditMutation.mutate(formDefinition.formMethods.getValues())
	);

	const handleCreateSubmit = formDefinition.formMethods.handleSubmit(() =>
		documentCreateMutation.mutate(formDefinition.formMethods.getValues())
	);

	const onFilesChanged = (files: FileUploadItem[]) =>
		hasNotFinishedUploads.setValue(hasNoSuccessFile(files));

	return (
		<VlastniDokladContext.Provider
			value={{
				controls: {
					initialLoading: query.isInitialLoading,
					initialError: query.error,
					processError: documentExist
						? documentEditMutation.error
						: documentCreateMutation.error,
					processLoading: documentExist
						? documentEditMutation.isLoading
						: documentCreateMutation.isLoading,
				},
				documentId,
				isEditAction: documentExist,
				documentDownload: documentDownloadMutation,
				formDefinition,
				onOwnDocumentDelete: ownDocumentDeleteMutation.mutate,
				isDeletePromptOpen,
				replaceFile: formDefinition.formMethods.watch('_replaceFile'),
				onSubmit: documentExist ? handleEditSubmit : handleCreateSubmit,
				onFilesChanged,
				hasNotFinishedUploads: hasNotFinishedUploads.value,
				hasFile: hasFile.value,
				imageBlob,
			}}>
			{children}
		</VlastniDokladContext.Provider>
	);
};

export const useVlastniDokladyContext = (): MyOwnDocumentsContext =>
	useContext(VlastniDokladContext) as MyOwnDocumentsContext;
