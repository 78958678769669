import { GovIcon } from '@gov-design-system-ce/react';
import { PropsWithChildren } from 'react';
import { AisItem, useKdeOMneStatVedeUdajeContext } from '@gov-nx/module/page';

type AgendaItemProps = PropsWithChildren<{
	ais?: AisItem;
}>;

export const AgendaItem = ({ ais, children }: AgendaItemProps) => {
	const { toggleAis, expandedAis } = useKdeOMneStatVedeUdajeContext();

	const className = 'w-full py-3 px-2 flex justify-between items-center gap-4';

	const content = (
		<>
			<span className={'text-m text-primary-600 text-left'}>{children}</span>
			{!!ais?.nazevAis &&
				(expandedAis === ais?.kodAis ? (
					<GovIcon
						name="chevron-up"
						className={'grow-0 shrink-0 w-4 h-4 text-primary-600'}
					/>
				) : (
					<GovIcon
						name="chevron-down"
						className={'grow-0 shrink-0 w-4 h-4 text-primary-600'}
					/>
				))}
		</>
	);

	if (ais?.nazevAis)
		return (
			<button
				className={className}
				type="button"
				onClick={() => toggleAis(ais.kodAis)}>
				{content}
			</button>
		);

	return <span className={className}>{content}</span>;
};
