import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { FilterParam } from '@gov-nx/core/types';
import { toDate, toDateReadable, useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { RegistrSVFormData } from './context.types';

interface FilterParamsProps {
	code: PageCode;
	formDefinition: FormDefinition<RegistrSVFormData>;
	defaultValues: RegistrSVFormData;
}

export const useFilters = ({
	code,
	formDefinition,
	defaultValues,
}: FilterParamsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { filter, setPartialFilter, setPartialFilterWithPath } =
		useFilter<RegistrSVFormData>({
			key: code,
			initialState: defaultValues,
			rehydrate: (data) => {
				return {
					...data,
					datumOd:
						typeof data.datumOd === 'string'
							? toDate(data.datumOd) ?? null
							: data.datumOd,
					datumDo:
						typeof data.datumDo === 'string'
							? toDate(data.datumDo) ?? null
							: data.datumDo,
				};
			},
		});

	const filterParams: FilterParam[] = [
		{
			label: tsn('formular.label.text-filtr'),
			value: filter.filterText,
			onRemove: () => {
				const { filterText } = defaultValues;
				setPartialFilter({ filterText: filterText });
				formDefinition.formMethods.setValue('filterText', filterText);
			},
			isActive: !!filter.filterText,
		},
		{
			label: tsn('formular.label.status'),
			value: tsn('formular.pole.filter-status-provozovane'),
			onRemove: () => {
				const { filterStatus } = defaultValues;
				setPartialFilterWithPath(
					{ provozovane: filterStatus.provozovane },
					'filterStatus'
				);
				formDefinition.formMethods.setValue(
					'filterStatus.provozovane',
					filterStatus.provozovane
				);
			},
			isActive: filter.filterStatus.provozovane,
		},
		{
			label: tsn('formular.label.status'),
			value: tsn('formular.pole.filter-status-zanik'),
			onRemove: () => {
				const { filterStatus } = defaultValues;
				setPartialFilterWithPath({ zanik: filterStatus.zanik }, 'filterStatus');
				formDefinition.formMethods.setValue(
					'filterStatus.zanik',
					filterStatus.zanik
				);
			},
			isActive: filter.filterStatus.zanik,
		},
		{
			label: tsn('formular.label.vztah-k-vozidlu'),
			value: tsn('formular.pole.filtr-vztah-vlastnik'),
			onRemove: () => {
				const { filterVztah } = defaultValues;
				setPartialFilterWithPath(
					{ vlastnik: filterVztah.vlastnik },
					'filterVztah'
				);
				formDefinition.formMethods.setValue(
					'filterVztah.vlastnik',
					filterVztah.vlastnik
				);
			},
			isActive: filter.filterVztah.vlastnik,
		},
		{
			label: tsn('formular.label.vztah-k-vozidlu'),
			value: tsn('formular.pole.filtr-vztah-provozovatel'),
			onRemove: () => {
				const { filterVztah } = defaultValues;
				setPartialFilterWithPath(
					{ provozovatel: filterVztah.provozovatel },
					'filterVztah'
				);
				formDefinition.formMethods.setValue(
					'filterVztah.provozovatel',
					filterVztah.provozovatel
				);
			},
			isActive: filter.filterVztah.provozovatel,
		},
		{
			label: tsn('formular.label.obdobi-od'),
			value: filter.datumOd ? toDateReadable(filter.datumOd) : undefined,
			onRemove: () => {
				setPartialFilter({ datumOd: undefined });
				formDefinition.formMethods.setValue('datumOd', defaultValues.datumOd);
			},
			isActive: !!filter.datumOd,
		},
		{
			label: tsn('formular.label.obdobi-do'),
			value: filter.datumDo ? toDateReadable(filter.datumDo) : undefined,
			onRemove: () => {
				setPartialFilter({ datumDo: undefined });
				formDefinition.formMethods.setValue('datumDo', defaultValues.datumOd);
			},
			isActive: !!filter.datumDo,
		},
	];

	return { filterParams, filter, setPartialFilter };
};
