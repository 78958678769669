import { WizardVariantsType } from '@gov-design-system-ce/components/dist/types/components/gov-wizard/constants';
import { GovWizardItem } from '@gov-design-system-ce/react';
import { useScroll } from '@gov-nx/web';
import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormWizardHook } from '@gov-nx/core/hooks';
import { PoFormWeb } from '../Form/PoForm';

interface StepProps<T extends object> {
	label: string;
	index: number;
	children: React.ReactNode;
	wizard: FormWizardHook<T>;
}

export const WizardStepWeb = <T extends object>({
	index,
	wizard,
	label,
	children,
}: StepProps<T>) => {
	const step = wizard.step(index);
	const stepRef = useRef<HTMLGovWizardItemElement>(null);
	const { scrollToElement } = useScroll();

	useEffect(() => {
		if (step.isFocused) {
			// wait for the gov-wizard to expand the step
			setTimeout(() => {
				scrollToElement(stepRef.current);
			}, 100);
		}
	}, [step.isFocused]);

	const getVariant = (): WizardVariantsType => {
		if (step.isCollapsible) {
			return 'success';
		}
		if (step.isExpanded) {
			return 'primary';
		}
		return 'secondary';
	};

	return (
		<GovWizardItem
			ref={stepRef}
			labelTag={'h2'}
			label={label}
			isExpanded={step.isExpanded}
			variant={getVariant()}
			onGov-change={(event) => {
				if (
					event.type === 'gov-change' &&
					event.detail.component === 'gov-wizard-item'
				) {
					if (event.detail.expanded) {
						wizard.openStep(index);
					} else {
						wizard.closeStep(index);
					}
				}
			}}
			collapsible={step.isCollapsible}>
			<span slot="prefix">{index + 1}</span>
			{step.type === 'form' ? (
				<FormProvider {...step.formDefinition.formMethods}>
					<PoFormWeb
						formDefinition={step.formDefinition}
						onSubmit={step.onSubmit()}>
						{children}
					</PoFormWeb>
				</FormProvider>
			) : (
				children
			)}
		</GovWizardItem>
	);
};
