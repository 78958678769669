import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import { parseIcon } from '@gov-nx/component/common';
import { useCmsExternalServiceModalLoader } from '@gov-nx/core/service';
import { Button, DataTable } from '@gov-nx/ui/web';
import { FavoriteToggle } from '../Favorite/FavoriteToggle';
import { PoMarkdownRenderWeb } from '../Markdown/PoMarkdownRenderWeb';

export const CmsExternalServiceListener = () => {
	const { id, externalService, setId, urlCmsPath } =
		useCmsExternalServiceModalLoader();

	if (id && externalService) {
		const parsedIcon = externalService.icon?.icon
			? parseIcon(externalService.icon.icon)
			: null;
		return (
			<GovModal
				open={!!id}
				label={externalService.name}
				onGov-close={() => setId(null)}
				wcagCloseLabel={'Zavřít okno s externím službou'}>
				<div className={'w-full relative -top-4 -left-3'}>
					<FavoriteToggle
						withLabel={true}
						code={externalService.code}
						name={externalService.name}
						size={'s'}
					/>
				</div>
				<PoMarkdownRenderWeb>{externalService.description}</PoMarkdownRenderWeb>
				<DataTable customClasses={'my-6 text-secondary-800'}>
					<tbody>
						{externalService.parameters.map((parameter) => {
							return (
								<tr key={parameter.id}>
									<td>{parameter.key}:</td>
									<td>
										<PoMarkdownRenderWeb
											activatePhoneNumbers
											applyClasses={{ paragraph: 'font-bold' }}
											disableWrapper>
											{parameter.value}
										</PoMarkdownRenderWeb>
									</td>
								</tr>
							);
						})}
					</tbody>
				</DataTable>
				{externalService.links.map((link) => {
					return (
						<Button
							enforceLink={true}
							target={'_blank'}
							href={link.link}
							key={link.id}
							type={link.properties.type}
							variant={link.properties.variant}
							inverse={link.properties.inverse}
							wcagLabel={link.label}>
							{link.label}
							<GovIcon
								type={'basic'}
								name={'box-arrow-up-right'}
								slot={'right-icon'}></GovIcon>
						</Button>
					);
				})}
				{externalService.icon?.file ? (
					<img
						slot={'icon'}
						src={urlCmsPath + externalService.icon?.file}
						alt=""
					/>
				) : (
					<GovIcon
						name={parsedIcon?.name}
						type={parsedIcon?.type}
						slot="icon"></GovIcon>
				)}
			</GovModal>
		);
	}

	return null;
};
