import { AxiosResponse } from 'axios';
import { NahledyUdajuResponse } from '@gov-nx/module/service';
import { axiosInstance } from '../axios';

export const driversRegistryResults = async (
	source: string
): Promise<AxiosResponse<NahledyUdajuResponse>> =>
	axiosInstance.get<NahledyUdajuResponse>(
		`/api/v1/nahledyudaju?zdrojUdaju=${source}`
	);
