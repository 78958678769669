import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const MyFilesListSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			<div className={'flex justify-between items-center'}>
				<GovSkeleton
					width={'170px'}
					height={'23px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing')}
					className={'mr-auto'}
				/>
				<div className={'w-[120px]'}>
					<GovSkeleton
						width={'60px'}
						height={'23px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						className={'hidden [ md:inline ]'}
					/>
				</div>
				<GovSkeleton
					width={'80px'}
					height={'23px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing')}
					className={'ml-2'}
				/>
			</div>
			{[0, 1, 2, 3, 4].map((i) => {
				return (
					<div
						className={
							'pb-4 border-b border-secondary-300 [ last-of-type:border-0 ]'
						}
						key={i}>
						<div className={'flex justify-between items-center'}>
							<div className={'w-3/4 mr-auto'}>
								<GovSkeleton
									width={generateRandomNumber(50, 90) + '%'}
									height={'21px'}
									variant={'secondary'}
									wcagLabel={t('loading.wcag.processing')}
								/>
							</div>
							<GovSkeleton
								width={'130px'}
								height={'21px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								className={'hidden [ md:inline ]'}
							/>
							<GovSkeleton
								width={'24px'}
								height={'24px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								className={'ml-11 mr-3'}
							/>
						</div>
						<div>
							<GovSkeleton
								width={generateRandomNumber(30, 70) + '%'}
								height={'14px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
							/>
						</div>
						<div>
							<GovSkeleton
								width={generateRandomNumber(30, 70) + '%'}
								height={'14px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								className={'[ md:hidden ]'}
							/>
						</div>
						<div>
							<GovSkeleton
								width={'60px'}
								height={'14px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								className={'[ md:hidden ]'}
							/>
						</div>
					</div>
				);
			})}
			<span className={'flex gap-1.5'}>
				{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
					return (
						<GovSkeleton
							key={i}
							width={'40px'}
							height={'40px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					);
				})}
			</span>
		</LayoutGap>
	);
};
