import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { ApplicantDetails, MarkdownRender } from '@gov-nx/component/web';
import { useApplicantFormHelper } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ApplicantDetailsSelectProps } from '@gov-nx/ui/types';
import { FormSideOffsetLayout } from '../../Layout/FormSideOffsetLayout';
import { FormRadio } from '../FormRadio';
import { FormSelect } from '../FormSelect';

export const ApplicantDetailsSelect = ({
	field,
	code,
	formMethods,
}: ApplicantDetailsSelectProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const {
		selectedApplicantType,
		values,
		individualPerson,
		dataBoxes,
		dataBoxFO,
		dataBoxPFO,
		dataBoxesPO,
		hasDisconnectedDS,
		selectPOOptions,
	} = useApplicantFormHelper(formMethods);

	const dataBoxSelect = () => {
		return (
			<FormSelect
				field={{
					name: '_businessPersonDataBox',
					disabled: field.disabled,
					options: selectPOOptions,
				}}
				label={{
					children: tsn('form.fields.zadatel-udaje.nazav-po'),
				}}
			/>
		);
	};

	if (dataBoxes.length === 1) {
		return (
			<ApplicantDetails
				dataBoxSelect={
					selectedApplicantType === 'PO' && dataBoxesPO?.length
						? dataBoxSelect()
						: undefined
				}
				individualPerson={individualPerson}
				title={tsn('form.fields.zadatel-udaje.label')}
			/>
		);
	}

	return (
		<FormRadio
			group={{ gap: 's' }}
			field={{
				...field,
				options: [
					{
						value: 'FO',
						label: { children: tsn('form.fields.zadatel-udaje.fo') },
						disabled: !dataBoxFO,
						children: (
							<>
								{!dataBoxFO && hasDisconnectedDS('FO') && (
									<FormSideOffsetLayout className={'mt-2 mb-2'}>
										<GovMessage variant="primary">
											<MarkdownRender
												content={tsn(
													'formular.zprava.odpojena-datova-schranka'
												)}
											/>
										</GovMessage>
									</FormSideOffsetLayout>
								)}
								{selectedApplicantType === 'FO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails individualPerson={individualPerson} />
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
					{
						value: 'PFO',
						label: { children: tsn('form.fields.zadatel-udaje.pfo') },
						disabled: !dataBoxPFO,
						children: (
							<>
								{!dataBoxPFO && hasDisconnectedDS('PFO') && (
									<FormSideOffsetLayout className={'mt-2 mb-2'}>
										<GovMessage variant="primary">
											<MarkdownRender
												content={tsn(
													'formular.zprava.odpojena-datova-schranka'
												)}
											/>
										</GovMessage>
									</FormSideOffsetLayout>
								)}
								{selectedApplicantType === 'PFO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails
											individualPerson={individualPerson}
											values={values}
										/>
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
					{
						value: 'PO',
						label: { children: tsn('form.fields.zadatel-udaje.po') },
						disabled: dataBoxesPO.length === 0,
						children: (
							<>
								{dataBoxesPO.length === 0 && hasDisconnectedDS('PO') && (
									<FormSideOffsetLayout className={'mt-2 mb-2'}>
										<GovMessage variant="primary">
											<MarkdownRender
												content={tsn(
													'formular.zprava.odpojena-datova-schranka'
												)}
											/>
										</GovMessage>
									</FormSideOffsetLayout>
								)}
								{selectedApplicantType === 'PO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails
											dataBoxSelect={
												dataBoxesPO?.length ? dataBoxSelect() : undefined
											}
											individualPerson={individualPerson}
											values={values}
										/>
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
				],
			}}
			label={{
				children: tsn('form.fields.zadatel-udaje.label'),
				legend: true,
			}}
		/>
	);
};
