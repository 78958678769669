import { MySubmissionVoterCardAplication } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { is } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import {
	getElectionSubmissionDates,
	isBothRoundsRequested,
	pickupDates,
} from '@gov-nx/module/service';
import { toDateReadable } from '@gov-nx/utils/common';

export const useVoterCardApplicationMessages = (
	submission: MySubmissionVoterCardAplication
) => {
	const { t } = useLocale(PageCode['moje-podani']);

	const messageSpecificToType = () => {
		switch (submission.parametry.druh) {
			case 'POSLANECKA_SNEMOVNA':
				return [t('volicsky-prukaz.POSLANECKA_SNEMOVNA.pouceni')];
			case 'SENAT': {
				const requestedBothRounds = isBothRoundsRequested(submission.parametry);

				return [
					t('volicsky-prukaz.SENAT.pouceni', {
						obvod: submission.parametry.volebniObvod ?? '&nbsp;',
					}),
					!requestedBothRounds
						? t('volicsky-prukaz.SENAT.pouceni-2')
						: undefined,
				];
			}
			case 'PREZIDENT':
				return [t('volicsky-prukaz.PREZIDENT.pouceni')];

			case 'KRAJ':
				return [
					t('volicsky-prukaz.KRAJ.pouceni', {
						kraj: submission.parametry.kraj ?? '&nbsp;',
					}),
				];
			case 'EVROPSKY_PARLAMENT':
				return [
					t('volicsky-prukaz.EVROPSKY_PARLAMENT.pouceni'),
					submission.parametry.obcanEu
						? t('volicsky-prukaz.EVROPSKY_PARLAMENT.pouceni-obcanEu')
						: undefined,
				];
			default:
				return [];
		}
	};

	const messagesSpecificToPickupOption = (): string[] => {
		const uradOdesleNejdrive = submission.parametry.uradOdesleNejdrive
			? toDateReadable(submission.parametry.uradOdesleNejdrive)
			: undefined;

		switch (submission.parametry.zpusobPrevzeti) {
			case 'POSTA':
				return [
					t('volicsky-prukaz.zpusob-prevzeti.POSTA', {
						uradOdesleNejdrive: uradOdesleNejdrive ?? '&nbsp;',
						uradOdesleNaAdresu:
							submission.parametry.uradOdesleNaAdresu ?? '&nbsp;',
					}),
				];
			case 'POSTA_JINA_ADRESA':
				return [
					t('volicsky-prukaz.zpusob-prevzeti.POSTA_JINA_ADRESA', {
						uradOdesleNejdrive: uradOdesleNejdrive ?? '&nbsp;',
						uradOdesleNaAdresu:
							submission.parametry.uradOdesleNaAdresu ?? '&nbsp;',
					}),
				];
			case 'URAD': {
				const vyzvednutiNaUraduTermin = pickupDates(submission);
				return [
					submission.parametry.vyzvednutiNaUradu
						? t('volicsky-prukaz.zpusob-prevzeti.URAD-osobne', {
								termin: vyzvednutiNaUraduTermin,
								vyzvednutiNaUradu: submission.parametry.vyzvednutiNaUradu,
						  })
						: t(
								'volicsky-prukaz.zpusob-prevzeti.URAD-osobne-bez-adresy-uradu',
								{
									termin: vyzvednutiNaUraduTermin,
								}
						  ),
					t('volicsky-prukaz.zpusob-prevzeti.URAD-popis'),
				];
			}
			case 'URAD_ZAHRANICNI':
				return [
					submission.parametry.uradOdesleNejdrive
						? t(
								'volicsky-prukaz.zpusob-prevzeti.URAD_ZAHRANICNI-odeslano-nejdrive',
								{
									uradOdesleNejdrive: uradOdesleNejdrive ?? '&nbsp;',
								}
						  )
						: t(
								'volicsky-prukaz.zpusob-prevzeti.URAD_ZAHRANICNI-odeslano-co-nejdrive'
						  ),
					t('volicsky-prukaz.zpusob-prevzeti.URAD_ZAHRANICNI-popis', {
						dnyVolebDleVybranychKol:
							getElectionSubmissionDates(submission) ?? '&nbsp;',
						zastupitelskyUradNazev:
							submission.parametry.zastupitelskyUradNazev ?? '&nbsp;',
						zastupitelskyUradAdresa:
							submission.parametry.zastupitelskyUradAdresa ?? '&nbsp;',
					}),
				];
			default:
				return [];
		}
	};

	return {
		specificToType: messageSpecificToType().filter(is),
		specificToPickupOption: messagesSpecificToPickupOption(),
	};
};
