import React from 'react';
import { OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { DataTable } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

interface ApplicantFOProps {
	code: PageCode | ServiceCode;
	person: OsobyFyzickaOsobaDto;
	showDateOfBirth?: boolean;
}

export const ApplicantFO = ({
	person,
	code,
	showDateOfBirth,
}: ApplicantFOProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<DataTable responsive>
			<tbody>
				<tr>
					<td>{tsn('formular.zakladatel.prijmeni')}</td>
					<td>
						<strong>{person.prijmeni}</strong>
					</td>
				</tr>
				<tr>
					<td>{tsn('formular.zakladatel.jmeno')}</td>
					<td>
						<strong>{person.jmeno}</strong>
					</td>
				</tr>
				{showDateOfBirth && person.datumNarozeni && (
					<tr>
						<td>{tsn('formular.zakladatel.datum-narozeni')}</td>
						<td>
							<strong>{toDateReadable(person.datumNarozeni)}</strong>
						</td>
					</tr>
				)}
				<tr>
					<td>{tsn('formular.zakladatel.adresa')}</td>
					<td>
						<strong>{person.adresaPobytu?.adresaText}</strong>
					</td>
				</tr>
			</tbody>
		</DataTable>
	);
};
