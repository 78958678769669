import { filterMonad, pipe, prepareFilterMonad } from '@gov-nx/core/types';
import { Vehicle } from '../vehicle.types';
import { getVehicleGetters, getVztahOd } from '../vehicleUtils';
import { RegistrSVFormData } from './context.types';

export const sortDescending = <T>(a: T, b: T) => {
	if (!a || !b) {
		return 0;
	}
	if (a > b) {
		return -1;
	}

	if (a < b) {
		return 1;
	}

	return 0;
};

export const sortVehiclesSubjektOd = (a: Vehicle, b: Vehicle) =>
	sortDescending(getVztahOd(a), getVztahOd(b));

const byVehicleStatus =
	(filter: RegistrSVFormData['filterVehicleStatus']) =>
	(vehicle: Vehicle): boolean => {
		if (filter.actual && filter.historical) {
			return true;
		}

		const isActive = getVehicleGetters(vehicle).isActive();
		if (filter.actual && !isActive) {
			return false;
		}

		if (filter.historical && isActive) {
			return false;
		}

		return true;
	};

const byRelation =
	(filter: RegistrSVFormData['filterVztah']) =>
	(vehicle: Vehicle): boolean => {
		const relations = getVehicleGetters(vehicle).relations();
		if (!filter.provozovatel && !filter.vlastnik) {
			return true;
		}

		if (filter.vse) {
			return true;
		}
		if (
			filter.vlastnik &&
			relations.find((relation) => relation === 'VLASTNÍK')
		) {
			return true;
		}
		if (
			filter.provozovatel &&
			relations.find((relation) => relation === 'PROVOZOVATEL')
		) {
			return true;
		}
		return false;
	};

const byStatus =
	(filterStatus: RegistrSVFormData['filterStatus']) =>
	(vehicle: Vehicle): boolean => {
		const status = getVehicleGetters(vehicle).statusName();
		if (!filterStatus.provozovane && !filterStatus.zanik) {
			return true;
		}

		if (filterStatus.provozovane && status === 'PROVOZOVANÉ') {
			return true;
		}
		if (filterStatus.zanik && status === 'ZÁNIK') {
			return true;
		}

		return false;
	};

const byText =
	(filterText: RegistrSVFormData['filterText']) =>
	(vehicle: Vehicle): boolean => {
		if (!filterText) return true;

		const rz = getVehicleGetters(vehicle).rz();
		const name = getVehicleGetters(vehicle).name();

		return !!(
			rz &&
			name &&
			(rz.toUpperCase().includes(filterText.toUpperCase()) ||
				name.toUpperCase().includes(filterText.toUpperCase()))
		);
	};

export const filterVehicles =
	({
		filterText,
		filterStatus,
		filterVehicleStatus,
		filterVztah,
	}: RegistrSVFormData) =>
	(vehicle: Vehicle): boolean => {
		return pipe(
			prepareFilterMonad(vehicle),
			filterMonad(byText(filterText)),
			filterMonad(byVehicleStatus(filterVehicleStatus)),
			filterMonad(byRelation(filterVztah)),
			filterMonad(byStatus(filterStatus))
		).previous;
	};
