import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useKdeOMneStatVedeUdajeContext } from '@gov-nx/module/page';
import { InfoBanner } from '@gov-nx/ui/web';
import { AgendaItem } from './AgendaItem';
import { AgendasSkeleton } from './AgendasSkeleton';
import { AisSkeleton } from './AisSkeleton';
import { AisView } from './AisView';

type AgendasViewProps = {
	code: PageCode;
};

export const AgendasView = ({ code }: AgendasViewProps) => {
	const { data, controls, expandedAis, aisData } =
		useKdeOMneStatVedeUdajeContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <AgendasSkeleton />;
	}

	if (controls.initialError || !data || data?.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl [ md:text-3xl ]'}>
					{ts('stav.chyba.zatim-tu-nic-neni')}
				</p>
				<p>{ts('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
			</InfoBanner>
		);
	}

	return (
		<section className={'flex flex-col gap-8'}>
			{data.map((agenda) => (
				// TODO: This list can be quite large (~ couple of MB), virtual list might be needed here
				<section key={agenda.kodAgendy}>
					<h2 className={'mb-2 text-l text-secondary-800'}>
						{agenda.nazevAgendy}{' '}
						<span className={'text-s text-secondary-700'}>
							{ts('kod-agendy')} {agenda.kodAgendy}
						</span>
					</h2>
					<ul className={'gov-list--plain'}>
						{agenda.children.map((ais) => (
							<li
								key={ais.kodAis}
								className={
									'border-b border-secondary-500 bg-secondary-100 [ last-of-type:border-0 '
								}>
								<AgendaItem ais={ais}>
									{ais?.nazevAis}{' '}
									<span className={'text-s text-secondary-700'}>
										{ts('kod-agendoveho-i-s')} {ais?.kodAis}
									</span>
								</AgendaItem>
								{expandedAis === ais.kodAis &&
									(aisData ? (
										<AisView
											code={code}
											aisData={aisData}
										/>
									) : (
										<AisSkeleton />
									))}
							</li>
						))}
					</ul>
				</section>
			))}
		</section>
	);
};

AgendasView.displayName = 'AgendasView';
