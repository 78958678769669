import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { DataTable } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const AisSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<DataTable responsive>
			<tbody>
				{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
					return (
						<tr key={i}>
							<td className={'md:!w-1/2'}>
								<GovSkeleton
									width={generateRandomNumber(40, 70) + '%'}
									height={'19px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td>
								<GovSkeleton
									width={generateRandomNumber(40, 70) + '%'}
									height={'19px'}
									wcagLabel={
										tg('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</DataTable>
	);
};
