import React from 'react';
import {
	DatoveSchrankyDatovaZpravaDto,
	isVoterRegistrationSubmission,
	MySubmission,
} from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { DataTable } from '@gov-nx/ui/web';
import { VoterRegistration } from './VoterRegistration';
import { CopyOrEmpty, Row } from './components';

interface MySubmissionPreviewProps {
	code: PageCode;
	dataMessage: DatoveSchrankyDatovaZpravaDto;
	submission?: MySubmission;
}

export const MySubmissionPreview = ({
	code,
	dataMessage,
	submission,
}: MySubmissionPreviewProps) => {
	const { t } = useLocale(code);

	return (
		<DataTable
			responsive
			customClasses={'mb-3'}>
			{submission && isVoterRegistrationSubmission(submission) ? (
				<VoterRegistration
					submission={submission}
					code={code}
				/>
			) : (
				<tbody>
					<Row label={t('data.adresat')}>{dataMessage.prijemceNazev}</Row>
					<Row label={t('data.datova-schranka')}>
						<CopyOrEmpty
							label={t('akce.zkopirovat-id-datovky')}
							value={dataMessage?.prijemceId}
						/>
					</Row>
				</tbody>
			)}
		</DataTable>
	);
};
