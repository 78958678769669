import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useLocale, useTranslationWithNamespace } from '@gov-nx/core/service';
import { Optional, is } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';

export const useDataBoxLabel = () => {
	const { t } = useLocale(PageCode['datove-schranky']);

	return {
		label: (type: NonNullable<DatovkaApiType['typSchranky']>): string =>
			({
				FO: t('typ-schranky-podrobny.fo'),
				OVM: t('typ-schranky-podrobny.ovm'),
				OVM_EXEK: t('typ-schranky-podrobny.ovm'),
				OVM_FO: t('typ-schranky-podrobny.ovm-fo'),
				OVM_NOTAR: t('typ-schranky-podrobny.ovm'),
				OVM_PFO: t('typ-schranky-podrobny.ovm-pfo'),
				OVM_PO: t('typ-schranky-podrobny.ovm-po'),
				OVM_REQ: t('typ-schranky-podrobny.ovm-req'),
				PFO: t('typ-schranky-podrobny.pfo'),
				PFO_ADVOK: t('typ-schranky-podrobny.pfo-advok'),
				PFO_AUDITOR: t('typ-schranky-podrobny.pfo-auditor'),
				PFO_DANPOR: t('typ-schranky-podrobny.pfo-danpor'),
				PFO_INSSPR: t('typ-schranky-podrobny.pfo-insspr'),
				PFO_ZNALEC: t('typ-schranky-podrobny.pfo-znalec'),
				PFO_TLUMOCNIK: t('typ-schranky-podrobny.pfo-tlumocnik'),
				PFO_REQ: t('typ-schranky-podrobny.pfo-req'),
				PO: t('typ-schranky-podrobny.po'),
				PO_REQ: t('typ-schranky-podrobny.po-req'),
				PO_ZAK: t('typ-schranky-podrobny.po-zak'),
				PFO_ARCH: t('typ-schranky-podrobny.pfo-architekt'),
				PFO_AIAT: t('typ-schranky-podrobny.pfo-inzenyr-technik'),
				PFO_AZI: t('typ-schranky-podrobny.pfo-zememericky-inzenyr'),
			}[type]),
	};
};

export const dataBoxLabel = (dataBox: DatovkaApiType, shortLabel = false) => {
	const fullName = [dataBox.jmeno || undefined, dataBox.prijmeni || undefined]
		.filter(is)
		.join(' ');
	const name = dataBox.firma
		? [dataBox.firma, fullName || undefined].filter(is).join(' - ')
		: fullName;

	if (shortLabel) return name;

	return `${name} (${dataBox.datovaSchrankaId} | ${dataBox.typSchranky})`;
};

export const getDataBoxTypeLabel = (
	dataBoxType: DatovkaApiType['typSchranky'],
	tn: ReturnType<
		ReturnType<typeof useTranslationWithNamespace>['getLocalizeCurried']
	>
) => {
	if (dataBoxType) {
		if (dataBoxType.match(/^FO/)) {
			return tn('typ-schranky.fo');
		}
		if (dataBoxType.match(/^PFO/)) {
			return tn('typ-schranky.pfo');
		}
		if (dataBoxType.match(/^PO/)) {
			return tn('typ-schranky.po');
		}
	}

	return dataBoxType ?? '';
};

export const getDataBoxTypeShortcut = (
	dataBoxType: DatovkaApiType['typSchranky'],
	tn: ReturnType<
		ReturnType<typeof useTranslationWithNamespace>['getLocalizeCurried']
	>
) => {
	if (dataBoxType) {
		if (dataBoxType.match(/^FO/)) {
			return tn('typ-schranky.fo-short');
		}
		if (dataBoxType.match(/^PFO/)) {
			return tn('typ-schranky.pfo-short');
		}
		if (dataBoxType.match(/^PO/)) {
			return tn('typ-schranky.po-short');
		}
	}

	return dataBoxType ?? '';
};

export const getDataBoxIcon = (dataBoxType: DatovkaApiType['typSchranky']) => {
	if (dataBoxType) {
		if (dataBoxType.match(/^FO/)) {
			return 'person-fill';
		}
		if (dataBoxType.match(/^PFO/)) {
			return 'person-fill-bussines';
		}
		if (dataBoxType.match(/^PO/)) {
			return 'buildings';
		}

		return 'ovm';
	}
};

export const isDataBoxTypePO = (dataBox: Optional<DatovkaApiType>) => {
	return dataBox?.typSchranky?.match(/^PO/);
};

// Examples:
// Karolína Nováková - KN
// Karolína Marie Nováková - KN
// Karolína Marie Kloudová Nováková - KN
// Karolína Marie Kloudová-Nováková - KK
// Mgr. et Bc. Dana Bartošíková, CSc. - DB

const TITLE_REGEX =
	/^(Bc\.?|BcA\.?|Ing\.?|arch\.?|MDDr\.?|MUDr\.?|MVDr\.?|MgA\.?|Mgr\.?|JUDr\.?|PhDr\.?|RNDr\.?|PharmDr\.?|ThLic\.?|ThDr\.?|Ph\.?D\.?|Th\.?D\.?|prof\.?|doc\.?|CSc\.?|DrSc\.?|DSc\.?|PaedDr\.?|Dr\.?|PhMr\.?|DiS\.?|et)$/;

const getInitialsPerson = (name: string) => {
	const names = name
		.split(/[\s,]+/)
		.filter(Boolean)
		.filter((name) => !TITLE_REGEX.test(name))
		.filter((name) => {
			return /^[A-ZÁÉĚÍÓÚŮŽŠČŘĎŤŇÝ][A-ZÁÉĚÍÓÚŮŽŠČŘĎŤŇÝa-záéěíóúůžščřďťňý-]+$/.test(
				name
			);
		});

	if (names.length > 1) {
		return `${names[0][0]}${names[names.length - 1][0]}`;
	} else if (names.length === 1) {
		return names[0][0];
	} else {
		return name[0];
	}
};

// Examples:
// Vodafone Czech Republic a.s. - VC
// Vodafone a.s. - V

const getInitialsCompany = (name: string) => {
	const names = name
		.split(/[\s,-]+/)
		.filter(Boolean)
		.filter((name) => {
			return /^[A-ZÁÉĚÍÓÚŮŽŠČŘĎŤŇÝ][A-ZÁÉĚÍÓÚŮŽŠČŘĎŤŇÝa-záéěíóúůžščřďťňý]+$/.test(
				name
			);
		});

	if (names.length > 1) {
		return `${names[0][0]}${names[1][0]}`;
	} else if (names.length === 1) {
		return names[0][0];
	} else {
		return name[0];
	}
};

export const getDataBoxInitials = (dataBox: DatovkaApiType) => {
	const name = dataBoxLabel(dataBox, true);

	if (!name || typeof name !== 'string') {
		return '';
	}

	if (dataBox.typSchranky?.match(/^PO/)) {
		return getInitialsCompany(name);
	}

	return getInitialsPerson(name);
};

export const DATABOX_FOLDER_LABELS: Record<string, string> = {
	dorucene: 'slozky.dorucene',
	odeslane: 'slozky.odeslane',
	archiv: 'slozky.archiv',
	kos: 'slozky.kos',
};

export const DATABOX_TIME_DIVISION_LABELS: Record<string, string> = {
	today: 'zpravy.casove-deleni.dnes',
	'this-week': 'zpravy.casove-deleni.tento-tyden',
	'last-week': 'zpravy.casove-deleni.minuly-tyden',
	'this-month': 'zpravy.casove-deleni.tento-mesic',
	'last-month': 'zpravy.casove-deleni.minuly-mesic',
	'this-year': 'zpravy.casove-deleni.tento-rok',
	'last-year': 'zpravy.casove-deleni.minuly-rok',
	older: 'zpravy.casove-deleni.starsi',
};
