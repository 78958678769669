import { pubSubActionBridge } from '@gov-nx/core/app';
import {
	DataBoxActions,
	DataBoxAuthSubsription,
	DataBoxConnectSubscription,
	DataBoxCreateSubscription,
	DataBoxCreditsRefetchSubscription,
	DataBoxHideMessageListSubscription,
	DataBoxListStateChangeSubscription,
	DataBoxMessageListUpdateSubscription,
	DataBoxMessageReadSubscription,
	DataBoxMessagesArchiveSubscription,
	DataBoxMessagesUnselectAllSubscription,
	DataBoxRefreshListSubscription,
	DataBoxResetActionInfoSubscription,
	DataBoxStorageRefetchSubscription,
	DataBoxUnreadMessagesCountsRefetchSubscription,
	ShowDataBoxSettingModalSubscription,
} from './dataBox.types';

export enum DataBoxSubscriptionTypes {
	listStateChange = 'DATABOX_LIST_STATE_CHANGE_SUBSCRIPTION',
	messageRead = 'DATABOX_MESSAGE_READ_SUBSCRIPTION',
	messagesArchive = 'DATABOX_MESSAGES_ARCHIVE_SUBSCRIPTION',
	messagesUnselectAll = 'DATABOX_MESSAGES_UNSELECT_ALL_SUBSCRIPTION',
	messageListUpdate = 'DATABOX_MESSAGE_LIST_UPDATE_SUBSCRIPTION',
	creditsRefetch = 'DATABOX_CREDITS_REFETCH_SUBSCRIPTION',
	storageRefetch = 'DATABOX_STORAGE_REFETCH_SUBSCRIPTION',
	unreadMessagesCountsRefetch = 'DATABOX_UNREAD_MESSAGES_COUNTS_REFETCH_SUBSCRIPTION',
	connect = 'DATABOX_CONNECT_SUBSCRIPTION',
	create = 'DATABOX_CREATE_SUBSCRIPTION',
	hideMessageList = 'DATABOX_HIDE_MESSAGE_LIST_SUBSCRIPTION',
	resetActionInfo = 'DATABOX_RESET_ACTION_INFO_SUBSCRIPTION',
	refreshList = 'DATABOX_REFRESH_SUBSCRIPTION',
	showDataBoxSettingModal = 'DATABOX_SHOW_SETTINGS_MODAL_SUBSCRIPTION',
	databoxAuth = 'DATABOX_AUTH_SUBSRIPTION',
}

export const dataBoxListStateChangeSubscription = (
	payload: DataBoxListStateChangeSubscription['payload']
): DataBoxListStateChangeSubscription => ({
	type: DataBoxSubscriptionTypes.listStateChange,
	payload,
});

export const dataBoxMessageReadSubscription = (
	payload: DataBoxMessageReadSubscription['payload']
): DataBoxMessageReadSubscription => ({
	type: DataBoxSubscriptionTypes.messageRead,
	payload,
});

export const dataBoxMessagesArchiveSubscription = (
	payload: DataBoxMessagesArchiveSubscription['payload']
): DataBoxMessagesArchiveSubscription => ({
	type: DataBoxSubscriptionTypes.messagesArchive,
	payload,
});

export const dataBoxMessagesUnselectAllSubscription =
	(): DataBoxMessagesUnselectAllSubscription => ({
		type: DataBoxSubscriptionTypes.messagesUnselectAll,
	});

export const dataBoxMessageListUpdateSubscription =
	(): DataBoxMessageListUpdateSubscription => ({
		type: DataBoxSubscriptionTypes.messageListUpdate,
	});

export const dataBoxConnectSubscription = (
	payload: DataBoxConnectSubscription['payload']
): DataBoxConnectSubscription => ({
	type: DataBoxSubscriptionTypes.connect,
	payload,
});

export const dataBoxCreateSubscription = (): DataBoxCreateSubscription => ({
	type: DataBoxSubscriptionTypes.create,
});

export const dataBoxCreditsRefetchSubscription =
	(): DataBoxCreditsRefetchSubscription => ({
		type: DataBoxSubscriptionTypes.creditsRefetch,
	});

export const dataBoxStorageRefetchSubscription =
	(): DataBoxStorageRefetchSubscription => ({
		type: DataBoxSubscriptionTypes.storageRefetch,
	});

export const dataBoxUnreadMessagesCountsRefetchSubscription = (
	payload: DataBoxUnreadMessagesCountsRefetchSubscription['payload']
): DataBoxUnreadMessagesCountsRefetchSubscription => ({
	type: DataBoxSubscriptionTypes.unreadMessagesCountsRefetch,
	payload,
});

export const dataBoxHideMessageListSubscription = (
	payload: DataBoxHideMessageListSubscription['payload']
): DataBoxHideMessageListSubscription => ({
	type: DataBoxSubscriptionTypes.hideMessageList,
	payload,
});

export const dataBoxResetActionInfoSubscription =
	(): DataBoxResetActionInfoSubscription => ({
		type: DataBoxSubscriptionTypes.resetActionInfo,
	});

export const dataBoxRefreshListSubscription = (
	payload: DataBoxRefreshListSubscription['payload']
): DataBoxRefreshListSubscription => ({
	type: DataBoxSubscriptionTypes.refreshList,
	payload,
});

export const showDataBoxSettingModalSubscription = (
	payload: ShowDataBoxSettingModalSubscription['payload']
): ShowDataBoxSettingModalSubscription => ({
	type: DataBoxSubscriptionTypes.showDataBoxSettingModal,
	payload,
});

export const dataBoxConnectionVerifiedSubsription = (
	payload: DataBoxAuthSubsription['payload']
): DataBoxAuthSubsription => ({
	type: DataBoxSubscriptionTypes.databoxAuth,
	payload,
});

export const dataBoxEvents = pubSubActionBridge<DataBoxActions>();
