import { PropsWithChildren, createContext, useContext, useState } from 'react';
import {
	DriversOffenceItem,
	driversRegistryResponseTransform,
	filterErrorStatus,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { driversRegistryQuery } from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import { DriversRegistryContext, Page } from './context.types';

const RegistrRidicuContext =
	createContext<Nullable<DriversRegistryContext>>(null);

export type DriversRegistryProviderProps = PropsWithChildren & {
	code: PageCode;
};

export const RegistrRidicuContextProvider = ({
	children,
	code,
}: DriversRegistryProviderProps) => {
	const { controls, setControls } = useProcessControl();

	const query = usePoQuery(
		{
			queryKey: [code, 'registr-ridicu'],
			queryFn: async () => {
				const response = await driversRegistryQuery();
				return driversRegistryResponseTransform(response);
			},
			refetchOnWindowFocus: false,
			retry: 0,
		},
		{
			errorIgnoreFilter: filterErrorStatus(400, (initialError) =>
				setControls({ initialError })
			),
		}
	);

	const availablePages: Page[] = ['competency', 'offences', 'prohibitions'];
	const [selectedPage, setSelectedPage] = useState<Page>('competency');
	const [selectedOffence, setSelectedOffence] =
		useState<Nullable<DriversOffenceItem>>(null);

	const selectPage = (page: Page) => {
		setSelectedPage(page);
	};

	const selectOffence = (id?: number) => {
		if (id === undefined) {
			setSelectedOffence(null);
			return;
		}

		const offence = query?.data?.offences ? query.data.offences[id] : null;
		setSelectedOffence(offence);
	};

	return (
		<RegistrRidicuContext.Provider
			value={{
				data: query.data,
				availablePages,
				selectedPage,
				selectPage,
				selectedOffence,
				selectOffence,
				controls: {
					...controls,
					initialLoading: query.isLoading || query.isFetching,
				},
			}}>
			{children}
		</RegistrRidicuContext.Provider>
	);
};

export const useRegistrRidicuContext = () =>
	useContext(RegistrRidicuContext) as DriversRegistryContext;
