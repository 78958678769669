import { GovAccordion, GovAccordionItem } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { CommaSpace, Space } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export interface EPetitionInfoProps {
	code: PageCode;
}

export const EPetitionInfo = ({ code }: EPetitionInfoProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { petition, members } = useEPeticeDetailContext();

	return (
		<GovAccordion
			size={'xs'}
			noBorder={true}
			wcagLabelledBy={'informace-k-epetici'}>
			<GovAccordionItem>
				<h3
					slot={'label'}
					id="informace-k-epetici">
					{ts('data.petice-informace')}
				</h3>
				<div className={'flex flex-col gap-4'}>
					<h3 className={'text-xl text-secondary-800'}>
						{ts('data.zakladni-udaje')}
					</h3>
					<dl>
						<div>
							<dt className={'inline mr-1 font-bold text-secondary-800'}>
								{ts('data.datum-zverejneni')}
							</dt>
							<dd className={'inline'}>
								{petition?.datumZverejneni &&
									toDateReadable(petition?.datumZverejneni)}
							</dd>
						</div>
						<div>
							<dt className={'inline mr-1 font-bold text-secondary-800'}>
								{ts('data.ukonceni-petice')}
							</dt>
							<dd className={'inline'}>
								{petition?.datumUkonceni &&
									toDateReadable(petition?.datumUkonceni)}
								<Space />
								<i>{ts('data.ukonceni-petice-poznamka')}</i>
							</dd>
						</div>
						<div>
							<dt className={'inline mr-1 font-bold text-secondary-800'}>
								{ts('data.id-petice')}
							</dt>
							<dd className={'inline'}>{petition?.id}</dd>
						</div>
					</dl>
					{petition?.zakladatel ? (
						<>
							<h3 className={'mt-4 text-xl text-secondary-800'}>
								{ts('data.zakladatel')}
							</h3>
							{petition?.zakladatel?.pravnickaOsoba ? (
								<div>
									<p>
										<strong>
											{petition?.zakladatel?.pravnickaOsoba?.nazev}
										</strong>
										<br />
										{petition?.zakladatel?.pravnickaOsoba?.adresa?.adresaText}
									</p>
									<dl>
										<div>
											<dt className={'inline mr-1'}>{ts('data.ico')}</dt>
											<dd className={'inline'}>
												{petition?.zakladatel?.pravnickaOsoba?.ico}
											</dd>
										</div>
										<div>
											<dt className={'inline mr-1'}>
												{ts('data.datova-schranka')}
											</dt>
											<dd className={'inline'}>
												{petition?.zakladatel?.pravnickaOsoba?.datovaSchrankaId}
											</dd>
										</div>
									</dl>
								</div>
							) : petition?.zakladatel?.fyzickaOsoba ? (
								<p>
									<strong>
										{petition?.zakladatel?.fyzickaOsoba?.jmeno}{' '}
										{petition?.zakladatel?.fyzickaOsoba?.prijmeni}
									</strong>
									<br />
									{petition?.zakladatel?.fyzickaOsoba?.adresa?.adresaText}
								</p>
							) : null}
							{members.length > 0 ? (
								<p>
									<strong>{ts('data.peticni-vybor')}</strong>
									{members.map((member) => (
										<dl>
											<dd className={'inline'}>
												{member.jmeno}
												<Space />
												{member.prijmeni}
												<CommaSpace />
												{member.adresa?.adresaText}
											</dd>
										</dl>
									))}
								</p>
							) : null}
							{petition?.adresat ? (
								<>
									<h3 className={'mt-4 text-xl text-secondary-800'}>
										{ts('data.adresat')}
									</h3>
									<p>
										<strong>{petition?.adresat?.nazev}</strong>
										<br />
										<dl>
											<dt className={'inline mr-1'}>{ts('data.adresa')}</dt>
											<dd className={'inline'}>
												{petition?.adresat?.adresa?.adresaText}
											</dd>
										</dl>
										<dl>
											<dt className={'inline mr-1'}>
												{ts('data.datova-schranka')}
											</dt>
											<dd className={'inline'}>
												{petition?.adresat?.datovaSchrankaId}
											</dd>
										</dl>
									</p>
								</>
							) : null}
						</>
					) : null}
				</div>
			</GovAccordionItem>
		</GovAccordion>
	);
};
