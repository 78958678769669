import * as yup from 'yup';
import { DocumentUploadParams } from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	usePoForm,
	FormDefinition,
	getFormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '../../definitions/codes';
import { MyFilesSource } from '../seznam/context.types';
import { MyFilesUploadFormData } from './context.types';

export interface FormInstanceProps {
	code: PageCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<MyFilesUploadFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const formSchema = yup
		.object<FormSchemaShape<MyFilesUploadFormData>>({
			files: yup
				.array()
				.of(
					yup.object().shape({
						id: yup.string(),
						name: yup.string(),
					})
				)
				.compact((value) => !value.id)
				.min(1, tsn('soubor.validace.povinne')),
			name: yup.string().required(tsn('nazev-dokumentu.validace.povinne')),
		})
		.required();

	const formMethods = usePoForm<MyFilesUploadFormData>({
		formSchema,
		defaultValues: {
			files: [],
			name: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	data: MyFilesUploadFormData
): DocumentUploadParams => {
	return {
		zdroj: MyFilesSource.UZIVATELEM_NAHRANY_DOKUMENT,
		soubor: data.files?.[0]?.fileId as string,
		nazev: data.name,
	};
};
