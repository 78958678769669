import * as yup from 'yup';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';

export interface DataboxAdditionalSettingsFormData {
	[databoxId: string]: {
		shouldArchiveAutomatically: boolean;
		shouldArchiveLargeMessagesAutomatically: boolean;
	};
}

interface FormInstanceProps {
	databoxId: string;
}

export const FormInstance = ({ databoxId }: FormInstanceProps) => {
	const formSchema = yup
		.object<FormSchemaShape<DataboxAdditionalSettingsFormData>>({
			[databoxId]: yup.object({
				shouldArchiveAutomatically: yup.boolean(),
				shouldArchiveLargeMessagesAutomatically: yup.boolean(),
			}),
		})
		.required();

	const formMethods = usePoForm<DataboxAdditionalSettingsFormData>({
		formSchema,
		defaultValues: {
			[databoxId]: {
				shouldArchiveAutomatically: false,
				shouldArchiveLargeMessagesAutomatically: false,
			},
		},
	});

	return getFormDefinition({ formMethods, formSchema });
};
