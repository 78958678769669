import React, {
	createContext,
	useContext,
	useCallback,
	useEffect,
} from 'react';
import { FormProvider } from 'react-hook-form';
import {
	usePoQuery,
	documentsQuery,
	recycleBinQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useDocumentDelete,
	useDocumentRestore,
	useEmptyRecycleBin,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { first, Nullable } from '@gov-nx/core/types';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	FormInstance,
	prepareQueryBinnedData,
	prepareQueryData,
} from './FormDefinitions';
import { MyFilesContextType, MyFilesOrder } from './context.types';
import { useMyFilesBadges } from './hooks/useMyFilesBadges';
import { useMyFilesDownload } from './hooks/useMyFilesDownload';
import { useMyFilesFilters } from './hooks/useMyFilesFilters';
import { useMyFilesModals } from './hooks/useMyFilesModals';
import { useMyFilesSelection } from './hooks/useMyFilesSelection';
import { useMyFilesStorage } from './hooks/useMyFilesStorage';

const MojeSouborySeznamContext =
	createContext<Nullable<MyFilesContextType>>(null);

interface MojeSouborySeznamContextProviderProps {
	code: PageCode;
	children: React.ReactNode;
}

export function MojeSouborySeznamContextProvider({
	code,
	children,
}: MojeSouborySeznamContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const { toastMessageSuccess } = useMessageEvents();
	const { controls, setControls } = useProcessControl();

	const { getConnectedAndDisconnectedDataBoxesList } = useDataBoxStore();
	const dataBoxes = getConnectedAndDisconnectedDataBoxesList();

	const myFilesModals = useMyFilesModals();

	const deletePermanentConfirm = useBoolean(false);
	const deleteConfirm = useBoolean(false);

	const documentDelete = useDocumentDelete({
		onDeleteSuccess: ({ permanent }) => {
			toastMessageSuccess(
				permanent
					? tsn('zprava.dokumenty-byly-trvale-odstraneny')
					: tsn('zprava.dokumenty-byly-smazany')
			);
			permanent ? deletePermanentConfirm.setFalse() : deleteConfirm.setFalse();
			myFilesModals.closeActiveModal();
		},
	});

	const documentRestore = useDocumentRestore({
		onRestoreSuccess: () => {
			toastMessageSuccess(tsn('zprava.dokumenty-byly-obnoveny'));
			myFilesModals.closeActiveModal();
		},
	});

	const myFilesDownload = useMyFilesDownload({
		onDocumentSuccess: () => myFilesModals.closeActiveModal(),
		onSelectedDocumentsSuccess: () => {
			myFilesSelection.resetSelectedIds();
		},
	});
	const storage = useMyFilesStorage();

	const defaultValues = {
		text: undefined,
		selection: {},
		datumVytvoreniOd: undefined,
		datumVytvoreniDo: undefined,
	};
	const formDefinition = FormInstance({
		code,
		defaultValues,
	});
	const myFilesFilters = useMyFilesFilters({
		code,
		formMethods: formDefinition.formMethods,
		initialState: {
			...defaultValues,
			razeni: MyFilesOrder.DATUM_VYTVORENI_DESC,
			selectedBadgeIndex: 0,
			start: 0,
			pageSize: 10,
		},
	});
	const {
		badges,
		isSelectedRecycleBin,
		isSelectedWithoutFile,
		source,
		isFile,
	} = useMyFilesBadges(myFilesFilters.filter.selectedBadgeIndex);

	const emptyRecycleBin = useEmptyRecycleBin();

	const documentsMainQuery = usePoQuery({
		queryKey: ['documents', code, myFilesFilters.filter],
		queryFn: async () => {
			setControls({ initialLoading: true });

			const requestData = prepareQueryData(
				myFilesFilters.filter,
				source,
				isFile
			);
			return documentsQuery(requestData);
		},
		onSuccess: () => {
			myFilesSelection.resetSelectedIds();
			setControls({ initialLoading: false });
		},
		retry: 0,
		enabled: !isSelectedRecycleBin,
	});

	const documentsBinnedQuery = usePoQuery({
		queryKey: ['documents-binned', code, myFilesFilters.filter],
		queryFn: async () => {
			setControls({ initialLoading: true });

			const requestData = prepareQueryBinnedData(myFilesFilters.filter);
			return recycleBinQuery(requestData);
		},
		onSuccess: async () => {
			myFilesSelection.resetSelectedIds();
			setControls({ initialLoading: false });
		},
		retry: 0,
		enabled: isSelectedRecycleBin,
	});

	const myFilesSelection = useMyFilesSelection({
		formMethods: formDefinition.formMethods,
		isSelectedRecycleBin,
		documents: documentsMainQuery.data,
		documentsBinned: documentsBinnedQuery.data,
	});

	const handleSubmit = useCallback(async () => {
		myFilesFilters.submitFilters(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, myFilesFilters]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const { isEmpty, isNotFirstPage, goToPage, filter, pageNumber } =
		myFilesFilters;

	useEffect(() => {
		if (isEmpty(documentsMainQuery.data?.seznam) && isNotFirstPage(filter)) {
			goToPage(pageNumber - 1);
		}
	}, [documentsMainQuery.data, pageNumber]);

	useEffect(() => {
		if (isEmpty(documentsBinnedQuery.data?.seznam) && isNotFirstPage(filter)) {
			goToPage(pageNumber - 1);
		}
	}, [documentsBinnedQuery.data, pageNumber]);

	return (
		<MojeSouborySeznamContext.Provider
			value={{
				firstDataBox: first(dataBoxes),
				documents: documentsMainQuery.data,
				documentsBinned: documentsBinnedQuery.data,
				isDataFetching:
					documentsMainQuery.isFetching || documentsBinnedQuery.isFetching,
				badges,
				isSelectedRecycleBin,
				isSelectedWithoutFile,
				onSubmit,
				formDefinition,
				code,
				controls,
				setControls,

				deleteConfirm,
				deletePermanentConfirm,

				myFilesFilters,
				myFilesSelection,
				documentRestore,
				documentDelete,
				emptyRecycleBin,
				myFilesDownload,
				myFilesModals,

				storage,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</MojeSouborySeznamContext.Provider>
	);
}

export const useMojeSouborySeznamContext = (): MyFilesContextType =>
	useContext(MojeSouborySeznamContext) as MyFilesContextType;
