import { WebInputProps } from '@gov-nx/ui/types';
import { toStringDate, today } from '@gov-nx/utils/common';
import { FormInput } from './FormInput';

export const FormDatePicker = (props: WebInputProps) => {
	return (
		<FormInput
			{...props}
			field={{ ...props.field, inputType: 'date' }}
			toValue={(input) => {
				return input ? today(input) : input;
			}}
			fromValue={(date) => {
				return date ? toStringDate(date) : '';
			}}
		/>
	);
};
