import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { LayoutGap } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';

interface ApplicantPOProps {
	code: PageCode;
}

export const ApplicantPO = ({ code }: ApplicantPOProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<LayoutGap
			gap={4}
			className={'mt-4 [ md:ml-8 ]'}>
			<GovMessage variant={'warning'}>
				<GovIcon
					type={'basic'}
					name={'info'}
					slot={'icon'}></GovIcon>
				<div>
					<PoMarkdownRenderWeb>
						{tsn('formular.zprava.varovani-po')}
					</PoMarkdownRenderWeb>
				</div>
			</GovMessage>
		</LayoutGap>
	);
};
