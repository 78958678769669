import { useQueryClient } from '@tanstack/react-query';
import {
	DokumentyVyuzitiUlozisteDto,
	fetchStorageUsageQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { useAppContext } from '@gov-nx/core/app';
import { formatBytes, toPercentageReadable } from '@gov-nx/utils/common';

export interface Storage {
	usedInPercent: number;
	isStorageFull: boolean;
	hasEnoughFreeSpace: boolean;
	hasFreeSpace: boolean;
	freeInPercent: number;
	maximumFormatted: string;
	maximumFormattedShort: string;
	usedFormatted: string;
	usedFormattedShort: string;
	usedInPercentFormatted: string;
	usedInPercentInt: number;
	freeFormatted: string;
	freeInPercentFormatted: string;
	fullStorageInPercentFormatted: string;
	isBeingUsed: boolean;
}

const prepareStorageUsage = (data: DokumentyVyuzitiUlozisteDto): Storage => {
	const maximum = data.maximalniKapacita || 1;
	const used = data.aktualniVyuziti || 0;
	const usedInPercent = (used / maximum) * 100;
	const free = maximum - used;
	const freeInPercent = (free / maximum) * 100;
	const isStorageFull = usedInPercent >= 100;
	const hasEnoughFreeSpace = usedInPercent < 80;
	const hasFreeSpace = freeInPercent < 100;
	const isBeingUsed = used / maximum > 0.5;

	return {
		usedInPercent,
		isStorageFull,
		hasEnoughFreeSpace,
		hasFreeSpace,
		freeInPercent,
		maximumFormatted: formatBytes(maximum),
		maximumFormattedShort: formatBytes(maximum, 0),
		usedFormatted: formatBytes(used),
		usedFormattedShort: formatBytes(used, 0),
		usedInPercentFormatted: toPercentageReadable(usedInPercent),
		usedInPercentInt: Math.round(usedInPercent),
		freeFormatted: formatBytes(free),
		freeInPercentFormatted: toPercentageReadable(freeInPercent),
		fullStorageInPercentFormatted: toPercentageReadable(100),
		isBeingUsed,
	};
};

export const useMyFilesStorage = () => {
	const { setAppError } = useAppContext();
	const { isAuthenticated } = useIdentity();
	const queryClient = useQueryClient();
	const queryKey = ['uloziste-vyuziti'];

	const query = usePoQuery({
		queryKey,
		queryFn: async () => {
			const response = await fetchStorageUsageQuery();
			return prepareStorageUsage(response);
		},
		onError: setAppError,
		retry: 0,
		enabled: isAuthenticated,
		refetchOnWindowFocus: false,
	});

	return {
		...query,
		storageUsage: query.data,
		refetch: async () => {
			await queryClient.invalidateQueries({ queryKey });
		},
	};
};
