import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { isValidDate, today, useDateShape } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { RegistrSVFormData } from './context.types';

export const getDefaultValues = (): RegistrSVFormData => ({
	typOsoby: 'FO',
	ico: undefined,
	filterText: undefined,
	datumDo: null,
	datumOd: null,
	filterVehicleStatus: {
		actual: true,
		historical: false,
	},
	filterVztah: {
		vlastnik: false,
		provozovatel: false,
		vse: false,
	},
	filterStatus: {
		provozovane: false,
		zanik: false,
		vse: false,
	},
	pageSize: 20,
	page: 1,
});

interface FormDefinitionInstanceProps {
	code: PageCode;
	defaultValues: RegistrSVFormData;
}

export function FormDefinitionInstance({
	code,
	defaultValues,
}: FormDefinitionInstanceProps): FormDefinition<RegistrSVFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<RegistrSVFormData>>({
			typOsoby: yup.string().oneOf(['FO', 'PFO']),
			ico: yup.string(),
			filterText: yup.string(),
			datumOd: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.datum-od.min'),
			})
				.nullable()
				.min(today('1900-01-01'), tsn('formular.validace.datum-od.min'))
				.max(today(), tsn('formular.validace.datum-od.max')),
			datumDo: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.datum-do.min'),
			})
				.nullable()
				.min(today('1900-01-01'), tsn('formular.validace.datum-do.min'))
				.max(today(), tsn('formular.validace.datum-do.max'))
				.when('datumOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(
							from,
							tsn('formular.validace.datum-do.starsi-nez-datum-od')
						);
					}
					return schema;
				}),
			filterVehicleStatus: yup.object({
				actual: yup.boolean(),
				historical: yup.boolean(),
			}),
			filterVztah: yup.object({
				vlastnik: yup.boolean(),
				provozovatel: yup.boolean(),
				vse: yup.boolean(),
			}),
			filterStatus: yup.object({
				provozovane: yup.boolean(),
				zanik: yup.boolean(),
			}),
			page: yup.number(),
			pageSize: yup.number(),
		})
		.required();

	const formMethods = usePoForm<RegistrSVFormData>({
		formSchema,
		defaultValues,
	});

	return getFormDefinition({ formMethods, formSchema });
}
