import React from 'react';
import { is } from '@gov-nx/core/types';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableNoiseProps {
	vozidloUdaje: Vehicle['vozidloUdaje'];
	tsn: (code: string) => string;
}

export const TableNoise = ({ tsn, vozidloUdaje }: TableNoiseProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.hluk.vnejsi-hluk-vozidla-stojiciho-pri-otackach'),
					[
						vozidloUdaje.vozidloHluk?.hlukStojici?.xVal,
						vozidloUdaje.vozidloHluk?.hlukOtacky?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.hluk.za-jizdy'),
					vozidloUdaje.vozidloHluk?.hlukJizda?.xVal,
				],
			]}
		/>
	);
};
