import { useContext } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DatovkaNastaveniUpozorneniContext,
	PageCode,
} from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';

export const DataboxConfirmExtendedMailNotificationModal = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	const context = useContext(DatovkaNastaveniUpozorneniContext);
	if (!context) {
		return null;
	}

	const {
		isExtendedMailConfirmationDialogOpen: isDialogOpen,
		closeExtendedMailConfirmationDialog: closeDialog,
		confirmExtendedMailNotification,
	} = context;

	return (
		<Prompt
			label={tds('nastaveni-upozorneni.souhlas-se-vstupovanim-do-schranky')}
			open={isDialogOpen}
			onGov-close={closeDialog}>
			<div className="databox-confirm-extended-mail-markdown-area">
				<MarkdownRender
					content={tds(
						'nastaveni-upozorneni.souhlas-se-vstupovanim-do-schranky-popis-dusledku'
					)}
				/>
			</div>

			<Button
				nativeType="button"
				variant="primary"
				type="base"
				slot="actions"
				onClick={closeDialog}
				wcagLabel={tds('nastaveni-upozorneni.souhlas-odmitnout')}>
				{tds('nastaveni-upozorneni.souhlas-odmitnout')}
			</Button>
			<Button
				nativeType="button"
				variant="error"
				type="solid"
				slot="actions"
				onClick={confirmExtendedMailNotification}
				wcagLabel={tds('nastaveni-upozorneni.souhlas-povolit')}>
				{tds('nastaveni-upozorneni.souhlas-povolit')}
			</Button>
		</Prompt>
	);
};
