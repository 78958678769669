import { GovIcon } from '@gov-design-system-ce/react';
import {
	FavoriteToggleCommonProps,
	useFavoriteToggle,
} from '@gov-nx/core/hooks';
import { Button } from '@gov-nx/ui/web';

export const FavoriteToggle = ({
	size = 'l',
	...props
}: FavoriteToggleCommonProps) => {
	const { isEnabled, isFavorite, toggleFavorite, label, ariaLabel, isSaving } =
		useFavoriteToggle(props);

	if (!isEnabled) {
		return null;
	}

	return (
		<Button
			variant={'primary'}
			type={'base'}
			size={size}
			title={label}
			disabled={isSaving}
			onClick={toggleFavorite}
			wcagLabel={ariaLabel}
			className={'print:hidden'}>
			<GovIcon
				type={'basic'}
				name={isFavorite ? 'star-fill' : 'star'}
				slot={'left-icon'}
			/>
			{props.withLabel ? label : null}
		</Button>
	);
};
