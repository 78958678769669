import { useEffect } from 'react';
import { Nullable, Optional } from '@gov-nx/core/types';
import { AuthState } from '@gov-nx/store/portal-obcana';

interface IEventRegister {
	addEventListener: (
		key: 'storage',
		callback: (event: StorageEvent) => void
	) => void;
	removeEventListener: (
		key: 'storage',
		callback: (event: StorageEvent) => void
	) => void;
}

const parse = (value: Nullable<string>): Optional<AuthState> => {
	if (!value) {
		return;
	}
	try {
		return JSON.parse(value)?.state;
	} catch {
		return;
	}
};

const isLogoutEvent = (key: string, event: StorageEvent): boolean => {
	if (event.key !== key) {
		return false;
	}

	const oldToken = parse(event.oldValue)?.token;
	const newToken = parse(event.newValue)?.token;

	return !!(oldToken && !newToken);
};

export const useStorageListener = (
	key: string,
	onLogout: () => void,
	eventRegister?: IEventRegister
) => {
	const emitter = (event: StorageEvent) => {
		if (event.key !== key) {
			return false;
		}
		if (isLogoutEvent(key, event)) {
			onLogout();
		}
	};

	useEffect(() => {
		eventRegister?.addEventListener?.('storage', emitter);
		return () => {
			eventRegister?.removeEventListener?.('storage', emitter);
		};
	}, []);
};
