import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { Button, FormInput, FormWidthElementLayout } from '@gov-nx/ui/web';

export const Filters = () => {
	const { code, controls, myFilesFilters } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<>
			<FormWidthElementLayout
				type="l"
				className={'relative'}>
				<FormInput
					field={{
						name: 'text',
						disabled: controls.processLoading,
						placeholder: tsn('formular.placeholder.vyhledat-soubor'),
						wcagLabelledBy: 'vyhledat-soubor',
					}}
					label={{
						children: '',
					}}
					group={{ gap: 's' }}
				/>
				<label
					id="vyhledat-soubor"
					className={'hidden'}>
					{tsn('formular.pole.wcag.vyhledat-soubor')}
				</label>
				<div className={'absolute top-1 right-1'}>
					<Button
						nativeType={'submit'}
						variant="primary"
						type="solid"
						size="s"
						wcagLabel={tsn('akce.vyhledat')}>
						<GovIcon
							name={'search'}
							type={'basic'}
							slot="left-icon"></GovIcon>
					</Button>
				</div>
			</FormWidthElementLayout>
			<Button
				onClick={myFilesFilters.filterWindowOpened.setTrue}
				variant="primary"
				type="outlined"
				className={'ml-auto'}
				wcagLabel={tsn('akce.wcag.zobrazit-filtr')}>
				<GovIcon
					name={'funnel-fill'}
					type={'basic'}></GovIcon>
			</Button>
		</>
	);
};
