import React from 'react';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableOthersProps {
	vozidloUdaje: Vehicle['vozidloUdaje'];
	tsn: (code: string) => string;
}

export const TableOthers = ({ vozidloUdaje, tsn }: TableOthersProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.ostatni.nejvyssi-rychlost'),
					vozidloUdaje.udajeDodatek?.vozidloNejvyssiRychlost?.xVal,
				],
				[
					tsn('tabulka.ostatni.pomer-vykon-hmotnost'),
					vozidloUdaje.udajeDodatek?.vozidloPomerVykonHmotnost?.xVal,
				],
				[
					tsn('tabulka.ostatni.predpis'),
					vozidloUdaje.vozidloSpotreba?.spotrebaMetodika?.xVal,
				],
			]}
		/>
	);
};
