import { useQueryClient } from '@tanstack/react-query';
import { rosBusinessPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';

export const useRosBusinessPersonQuery = () => {
	const queryClient = useQueryClient();
	const queryKey = ['profile-ros'];
	const query = usePoQuery({
		queryKey,
		queryFn: rosBusinessPersonQuery,
		retry: 1,
		refetchOnWindowFocus: false,
	});

	const rosContacts = query.data?.seznam?.filter(is) ?? [];

	return {
		...query,
		rosContacts,
		invalidate: async () => queryClient.invalidateQueries({ queryKey }),
	};
};
