import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useProfilROSContextInstance } from '@gov-nx/module/page';
import {
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
	LayoutGap,
} from '@gov-nx/ui/web';
import { ProfilROSSkeleton } from './ProfilROSSkeleton';
import { ProfileROSBusinessPersonView } from './ProfileROSBusinessPersonView';
import { ProfileROSDataView } from './ProfileROSDataView';

export interface RegistrOsobFormProps {
	navigation: React.ReactNode;
	code: PageCode;
}

export const ProfileROSView = ({ navigation, code }: RegistrOsobFormProps) => {
	const { data, controls } = useProfilROSContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <ProfilROSSkeleton />;
	}

	if (controls.initialDataError) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<ContentLayout customClasses={'!pt-4'}>
			{navigation}
			{Array.isArray(data) && data.length > 0 ? (
				<LayoutGap gap={8}>
					<LayoutGap gap={4}>
						<h3 className={'text-xl text-secondary-700'}>
							{ts('zaznam.udaje-rob')}
						</h3>
						<h4>{ts('zaznam.udaje-rob-text')}</h4>
						<ProfileROSDataView
							code={code}
							data={data}
						/>
					</LayoutGap>
					<section>
						<h3 className={'text-s text-secondary-700'}>
							{ts('zaznam.agenda')}
						</h3>
						<LayoutGap gap={8}>
							<ProfileROSBusinessPersonView code={code} />
						</LayoutGap>
					</section>
				</LayoutGap>
			) : (
				<InfoBanner
					icon={{ name: 'empty-file', type: 'colored' }}
					type="secondary"
					className={'!px-0 !py-4'}>
					<p className={'text-2xl [ md:text-3xl ]'}>
						{ts('stav.chyba.slozka-je-prazdna')}
					</p>
					<p>{ts('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
				</InfoBanner>
			)}
		</ContentLayout>
	);
};
