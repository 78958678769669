import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React from 'react';
import { Button } from '../Button/Button';

export interface SortProps {
	className?: string;
	descWcagLabel: string;
	descDisabled?: boolean;
	descOnClick: () => void;
	ascWcagLabel: string;
	ascDisabled?: boolean;
	ascOnClick: () => void;
}

export const SortArrows = ({
	className,
	descWcagLabel,
	descDisabled,
	descOnClick,
	ascWcagLabel,
	ascDisabled,
	ascOnClick,
}: SortProps) => {
	return (
		<span className={cx('sort-arrows', className && className)}>
			<Button
				wcagLabel={descWcagLabel}
				variant="primary"
				type="base"
				size="xs"
				disabled={descDisabled ?? false}
				onClick={descOnClick}>
				<GovIcon
					slot="left-icon"
					name="chevron-down"></GovIcon>
			</Button>
			<Button
				wcagLabel={ascWcagLabel}
				variant="primary"
				type="base"
				size="xs"
				disabled={ascDisabled ?? false}
				onClick={ascOnClick}>
				<GovIcon
					slot="left-icon"
					name="chevron-up"></GovIcon>
			</Button>
		</span>
	);
};
