import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { TableBinned } from './kos/TableBinned';
import { Table } from './soubory/Table';
import { TableNoFile } from './soubory/TableNoFile';

export const DocumentsTable = () => {
	const { isSelectedRecycleBin, isSelectedWithoutFile } =
		useMojeSouborySeznamContext();

	if (isSelectedRecycleBin) return <TableBinned />;

	if (isSelectedWithoutFile) return <TableNoFile />;

	return <Table />;
};
