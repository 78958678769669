import { useROSCommunicationQuery } from './useROSCommunicationQuery';
import { useRosBusinessPersonQuery } from './useRosBusinessPersonQuery';

export const useROSContact = () => {
	const rosCreditQuery = useROSCommunicationQuery();
	const rosProfileQuery = useRosBusinessPersonQuery();

	const reloadRosContact = async () => {
		await rosProfileQuery.invalidate();
		await rosCreditQuery.invalidate();
	};

	return {
		isLoading: rosCreditQuery.isLoading || rosProfileQuery.isLoading,
		isReloading: rosCreditQuery.isFetching || rosProfileQuery.isFetching,
		error: (rosCreditQuery.error || rosProfileQuery.error) ?? undefined,
		rosContacts: rosProfileQuery.rosContacts,
		getRosContact: rosCreditQuery.getRosContact,
		reloadRosContact,
	};
};
