import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import {
	fetchDataPreviewsQuery,
	isResponseStatus,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import {
	RegistrZPDataPreviewsParams,
	RegistrZPSubject,
	RegistrZPZdrojUdaju,
} from './types';
import { getSubjectsFromRegistrZPResponse } from './utils';
import { RegistrZpDataPreviewsResponseData } from './utils.types';

export interface RegistrZPLoaderProps {
	onSuccess: (data: RegistrZPSubject[]) => void;
	onError: (error: Error) => void;
	onDataError: (error: GovError) => void;
}

export const useRegistrZPLoader = (props: RegistrZPLoaderProps) => {
	const sourceKey = RegistrZPZdrojUdaju.RZP_PREHLED_O_UDAJICH;

	const params: RegistrZPDataPreviewsParams = {
		zdrojUdaju: sourceKey,
		format: 'json',
	};

	const query = usePoQuery<
		AxiosResponse<RegistrZpDataPreviewsResponseData, void>
	>(
		{
			queryKey: [sourceKey],
			queryFn: () => fetchDataPreviewsQuery(params),
			onSettled: (response, error) => {
				if (error) {
					return props.onError(error);
				}
				if (response) {
					const subjects = getSubjectsFromRegistrZPResponse(response.data);
					if (Array.isArray(subjects)) {
						props.onSuccess(subjects);
					} else {
						props.onDataError(
							new GovError(`[Page]: Wrong data structure (${sourceKey})`)
						);
					}
				}
			},
			retry: 0,
			enabled: false,
		},
		{ errorIgnoreFilter: isResponseStatus(400) }
	);

	const load = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	return { load };
};
