import { AxiosError } from 'axios';
import { useEffect } from 'react';
import {
	OsobyFyzickaOsobaDto,
	OsobyPodnikatelSeznamDto,
	ResponseError,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useBusinessPersonLoader } from '../Person/useBusinessPersonLoader';
import { usePoIndividualDataLoad } from '../Person/usePoIndividualDataLoad';

export interface ApplicantLoader {
	businessPerson: OsobyPodnikatelSeznamDto;
	individualPerson: OsobyFyzickaOsobaDto;
	isLoading?: boolean;
	error?: AxiosError<ResponseError>;
}

interface ApplicantLoaderProps {
	onLoaded?: (applicant: ApplicantLoader) => void;
	onError?: (error: GovError) => void;
}

export const useApplicantLoader = ({
	onLoaded,
	onError,
}: ApplicantLoaderProps = {}): {
	[key in keyof ApplicantLoader]?: ApplicantLoader[key];
} => {
	const { individualPerson, error: individualPersonError } =
		usePoIndividualDataLoad({ onError });
	const { businessPerson, error: businessPersonError } =
		useBusinessPersonLoader({ onError });

	useEffect(() => {
		if (onLoaded && individualPerson && businessPerson) {
			onLoaded({ businessPerson, individualPerson });
		}
	}, [businessPerson, individualPerson]);

	return {
		businessPerson,
		individualPerson,
		isLoading: !(individualPerson && businessPerson),
		error: individualPersonError ?? businessPersonError ?? undefined,
	};
};
