import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useEPeticeEditContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, LayoutGap } from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';

export const WizardStep3 = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();

	const { code, isProcessing, wizard } = useEPeticeEditContext();
	const tsn = getLocalizeCurried(code);
	return (
		<LayoutGap gap={4}>
			<LayoutGap gap={3}>
				<PoMarkdownRenderWeb>
					{tsn('formular.popis.informace-pro-zakladatele-podpis')}
				</PoMarkdownRenderWeb>
				<PoMarkdownRenderWeb>
					{tsn('formular.popis.informace-pro-zakladatele')}
				</PoMarkdownRenderWeb>
				<PoMarkdownRenderWeb>
					{tsn('formular.popis.informace-pro-zakladatele-zpracovani-udaju')}
				</PoMarkdownRenderWeb>
			</LayoutGap>
			<ButtonGroupLayout>
				<Button
					onClick={() => wizard.openNextStep(3)}
					loading={isProcessing}
					wcagLabel={tsn('akce.wcag.pokracovat')}
					disabled={isProcessing}
					variant={'primary'}
					size={'l'}
					type={'solid'}>
					{tsn('formular.tlacitko.pokracovat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
