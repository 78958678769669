import { ServiceCode } from '@gov-nx/module/service';
import { useCmsStore } from '@gov-nx/store/portal-obcana';

export interface CmsServiceProps {
	code: ServiceCode;
}

export const useCmsService = (props: CmsServiceProps) => {
	const { getCmsService } = useCmsStore();
	return props.code ? getCmsService(props.code) : null;
};
