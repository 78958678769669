import {
	DatovkaApiType,
	DokladyDokladDto,
	OsobyFyzickaOsobaDto,
	OsobyPodnikatelDto,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { ProcessControlCore, TreeFormCheckboxes } from '@gov-nx/core/hooks';
import { Nullable, PersonalDocumentType } from '@gov-nx/core/types';
import { DataBoxFormData } from '@gov-nx/ui/types';
import { AutocompleteFormData } from '@gov-nx/utils/common';
import { ServiceWizardContextCore } from '../types/serviceContext.types';

export interface ServiceControls extends ProcessControlCore {
	displayComplaintModal: boolean;
	isDownloadLoading: boolean;
	downloadError: Nullable<GovError>;
}

export type ServiceContextTypes = {
	individualPerson?: OsobyFyzickaOsobaDto;
	mainDocument?: DokladyDokladDto;
	foDs?: DatovkaApiType;
	treeCheckboxes: TreeFormCheckboxes;
	requiredDataBoxes: DatovkaApiType['typSchranky'][];
	scope: (formData: FormData) => (
		name: keyof typeof ScopeHelperField
	) => Array<{
		name:
			| keyof typeof ScopeReferential
			| keyof typeof ScopeNonReferential
			| keyof typeof ScopeDocuments;
		label?: string;
	}>;
	handleDownload: () => void;
} & ServiceWizardContextCore<ServiceControls, FormData>;

export interface DataRequest {
	params: DataBoxFormData;
	body: {
		kodPodani: 'ZR10_POSKYTNUTI';

		vsechnyUdaje: boolean;
		opakovanePoskytnuti: boolean;

		udaj: Array<
			keyof (typeof ScopeReferential &
				typeof ScopeNonReferential &
				typeof ScopeDocuments)
		>;
		typCisloID: string;
	} & (
		| {
				typOsoby: 'PO';
				poIco?: number;
				poNazev?: string;
				poDs?: string;
				poNazevMesta?: string;
				poPsc?: number;
				poNazevCastiObce?: string;
				poCisloPopisne?: number;
				poNazevUlice?: string;
				poCisloOrientacni?: number;
		  }
		| {
				typOsoby: 'FO';
				foTypDokladu: PersonalDocumentType;
				foCisloDokladu: number;
				foDatumNarozeni: string;
				foJmeno?: string;
				foPrijmeni?: string;
				foAdresa?: string;
				foDs?: string;
		  }
	);
}

interface FormDataStep2FO {
	typOsoby: 'FO';
	foDs: AutocompleteFormData<DatovkaApiType>;
	foTypDokladu: PersonalDocumentType;
	foCisloDokladu: number;
	foDatumNarozeni: Date;
}

interface FormDataStep2PO {
	typOsoby: 'PO';
	poIco: AutocompleteFormData<OsobyPodnikatelDto>;
}

export type FormDataStep2 = FormDataStep2FO | FormDataStep2PO;

export type FormDataStep3 = {
	opakovanePoskytnuti: boolean;
	udaj: { [key in keyof Scope]: boolean };
};

export type FormDataStep4 = DataBoxFormData;

export type FormData = FormDataStep2 & FormDataStep3 & FormDataStep4;

export enum ScopeReferential {
	Jmeno = 'Jmeno',
	Prijmeni = 'Prijmeni',
	Pohlavi = 'Pohlavi',
	RodinnyStavPartnerstvi = 'RodinnyStavPartnerstvi',
	AdresaPobytu = 'AdresaPobytu',
	DorucovaciAdresa = 'DorucovaciAdresa',
	DatumNarozeni = 'DatumNarozeni',
	MistoNarozeni = 'MistoNarozeni',
	OmezeniSvepravnosti = 'OmezeniSvepravnosti',
	DatumUmrti = 'DatumUmrti',
	MistoUmrti = 'MistoUmrti',
	Obcanstvi = 'Obcanstvi',
	DatovaSchranka = 'DatovaSchranka',
}

export enum ScopeNonReferential {
	Telefon = 'Telefon',
	Email = 'Email',
	Certifikat = 'Certifikat',
}

export enum ScopeDocuments {
	DokladOP = 'DokladOP',
	DokladPAS = 'DokladPAS',
	DokladVS = 'DokladVS',
	DokladPS = 'DokladPS',
	DokladPPP = 'DokladPPP',
}

export enum ScopeMainField {
	vsechnyUdaje = 'vsechnyUdaje',
}

export enum ScopeHelperField {
	_referential = '_referential',
	_nonReferential = '_nonReferential',
	_documents = '_documents',
}

export type Scope = typeof ScopeMainField &
	typeof ScopeReferential &
	typeof ScopeNonReferential &
	typeof ScopeDocuments &
	typeof ScopeHelperField;
