import { useEffect } from 'react';
import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	isEmail: boolean;
	email?: string;
}

export function FormInstance({
	code,
	isEmail,
	email,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			_statementFromAbroad: yup.boolean().optional(),
			_statementInForeignLanguage: yup.boolean().optional(),

			vypisZeZahranici: yup
				.string()
				.optional()
				.when('_statementFromAbroad', {
					is: true,
					then: (schema) =>
						schema.required(
							getLocalize(code, 'formular.validace.vyberte-zemi')
						),
				}),
			vypisVDalsimJazyce: yup
				.string()
				.optional()
				.when('_statementInForeignLanguage', {
					is: true,
					then: (schema) =>
						schema.required(
							getLocalize(code, 'formular.validace.vyberte-jazyk')
						),
				}),
			proKontaktSDetmi: yup.boolean().optional(),
			email: yup
				.string()
				.optional()
				.email(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.email-neni-ve-spravnem-tvaru'
					)
				)
				.when(
					[
						'_statementFromAbroad',
						'_statementInForeignLanguage',
						'proKontaktSDetmi',
					],
					{
						is: (
							_statementFromAbroad: boolean,
							_statementInForeignLanguage: boolean,
							proKontaktSDetmi: boolean
						) =>
							_statementFromAbroad ||
							_statementInForeignLanguage ||
							proKontaktSDetmi,
						then: (schema) =>
							schema.required(
								getLocalize(
									LocalizeNameSpaceTypes.Form,
									'validations.zadejte-kontakni-email'
								)
							),
					}
				),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			_statementFromAbroad: false,
			_statementInForeignLanguage: false,

			vypisZeZahranici: undefined,
			vypisVDalsimJazyce: undefined,
			proKontaktSDetmi: false,
			email: isEmail ? email : undefined,
		},
	});

	const watchStatementFromAbroad = formMethods.watch('_statementFromAbroad');
	const watchStatementInForeignLanguage = formMethods.watch(
		'_statementInForeignLanguage'
	);
	const watchproKontaktSDetmi = formMethods.watch('proKontaktSDetmi');

	useEffect(() => {
		if (watchStatementFromAbroad === false) {
			formMethods.setValue('vypisZeZahranici', '');
			formMethods.resetField('vypisZeZahranici');
		}
		if (watchStatementInForeignLanguage === false) {
			formMethods.setValue('vypisVDalsimJazyce', '');
			formMethods.resetField('vypisVDalsimJazyce');
		}
		if (
			!watchStatementFromAbroad ||
			!watchStatementInForeignLanguage ||
			!watchproKontaktSDetmi
		) {
			formMethods.clearErrors('email');
		}
	}, [
		watchStatementFromAbroad,
		watchStatementInForeignLanguage,
		watchproKontaktSDetmi,
	]);

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(data: FormData): DataRequest {
	return {
		body: {
			email: data.email,
			kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
			typOsoby: 'FO',
			format: 'signed',
			proKontaktSDetmi: data.proKontaktSDetmi
				? data.proKontaktSDetmi
				: undefined,
			vypisVDalsimJazyce: data._statementInForeignLanguage
				? data.vypisVDalsimJazyce
				: undefined,
			vypisZeZahranici: data._statementFromAbroad
				? data.vypisZeZahranici
				: undefined,
		},
	};
}
