import {
	DatovkaApiType,
	DatoveSchrankyDatovaZpravaDto,
} from '@gov-nx/api/portal-obcana';
import { Nullable, Optional } from '@gov-nx/core/types';
import { DataBoxConfirmModalType, DataBoxFolderType } from './DataBoxes.types';

export type DataBoxMessageDetailContextType = {
	dataBoxId: string;
	dataBox: Optional<DatovkaApiType>;
	recipientDataBox?: DatovkaApiType;
	senderDataBox?: DatovkaApiType;
	folderType: DataBoxFolderType;
	messageId: number;
	message: Optional<DatoveSchrankyDatovaZpravaDto>;
	isLoading: boolean;
	loadingItems: Partial<Record<DataBoxMessageDetailLoadingItemType, boolean>>;
	messageOperations: {
		isLoading: boolean;
		isArchivingEnabled: boolean;
		handleArchiveMessage: () => void;
		handleRestoreMessage: (redirect: () => void) => void;
		handleDeleteMessage: (permanently: boolean, redirect: () => void) => void;
		handleDownloadMessage: (message: DatoveSchrankyDatovaZpravaDto) => void;
		handleSaveAllAttachmentsToDocuments: (
			message: DatoveSchrankyDatovaZpravaDto
		) => void;
	};
	confirmModal: Nullable<DataBoxConfirmModalType>;
	setConfirmModal: (modal: Nullable<DataBoxConfirmModalType>) => void;
	showAllAttachments: boolean;
	setShowAllAttachments: (showAll: boolean) => void;
	disableNewMessageButton: boolean;
};

export enum DataBoxMessageDetailLoadingItemType {
	Archive = 'archive',
	Restore = 'restore',
	Download = 'download',
	Remove = 'remove',
	RemovePermanently = 'removePermanently',
	SaveAttachmentsToDocuments = 'saveAttachmentsToDocuments',
}
