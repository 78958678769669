import { axiosInstance } from '../axios';
import { OsobyEditorSeznamDto } from '../generated';

export const editorsOvmQuery = async (
	ico?: number
): Promise<OsobyEditorSeznamDto> => {
	const { data } = await axiosInstance.get(
		`api/v1/editori?ico=${ico}&kodUdaje=PravniForma`
	);
	return data;
};
