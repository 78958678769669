import React, { createContext, useContext } from 'react';
import { fetchDataPreviewsQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import {
	RegistrVozidelResponse,
	TechnicalInspectionResponse,
} from '../vehicle.types';
import { getVehicleDetailData, getVehicleGetters } from '../vehicleUtils';
import { FormDefinitionInstance } from './FormDefinitions';
import {
	RegisterSVDetailContext,
	RegisterSVDetailControl,
} from './context.types';

const RegistrSVContext = createContext<Nullable<RegisterSVDetailContext>>(null);

interface RegistrSVDetailContextProviderProps {
	code: PageCode;
	vehicleId: number;
	ico?: number;
	children: React.ReactNode;
}

export function RegistrSVDetailContextProvider({
	code,
	children,
	vehicleId,
	ico,
}: RegistrSVDetailContextProviderProps) {
	const { controls, setControls } = useProcessControl<RegisterSVDetailControl>({
		isTechnicalInspectionInfoLoading: true,
	});
	const formDefinition = FormDefinitionInstance();

	const query = usePoQuery({
		queryKey: ['nahledyudaju', 'zdrojUdaju', 'REGISTR_VOZIDEL', vehicleId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return fetchDataPreviewsQuery<
				{ zdrojUdaju: string; vozidloID: number; ico?: number },
				RegistrVozidelResponse
			>({
				zdrojUdaju: 'REGISTR_VOZIDEL_V2',
				vozidloID: vehicleId,
				ico,
			});
		},
		onError: (initialError) => {
			setControls({ initialLoading: false, initialError });
		},
	});

	const vehicle = getVehicleDetailData(query.data);
	const vinNumber = vehicle?.vozidloUdaje.udajeZakladni?.vozidloVin?.xVal;

	const technicalInspectionInfoQuery = usePoQuery({
		queryKey: ['technical-inspection', vinNumber],
		queryFn: async () => {
			setControls({ isTechnicalInspectionInfoLoading: true });
			return fetchDataPreviewsQuery<
				{ zdrojUdaju: string; vin?: string },
				TechnicalInspectionResponse
			>({
				zdrojUdaju: 'TECHNICKE_PROHLIDKY_ODKAZ',
				vin: vinNumber,
			});
		},
		onSuccess: () => {
			setControls({ isTechnicalInspectionInfoLoading: false });
		},
		onError: (initialError) => {
			setControls({ isTechnicalInspectionInfoLoading: false, initialError });
		},
		enabled: !!vinNumber,
		refetchOnWindowFocus: false,
	});

	return (
		<RegistrSVContext.Provider
			value={{
				isLoading: query.isLoading,
				code,
				controls,
				setControls,
				formDefinition,
				vehicle,
				technicalInspectionURL:
					technicalInspectionInfoQuery.data?.data.Data?.[0].odkaz,
				vehicleGetters: getVehicleGetters,
			}}>
			{children}
		</RegistrSVContext.Provider>
	);
}

export const useRegistrSVDetailContext = (): RegisterSVDetailContext =>
	useContext(RegistrSVContext) as RegisterSVDetailContext;
