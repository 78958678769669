import React, { createContext, useContext, useState } from 'react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { DataBoxActionInfoContextType } from './ActionInfoContext.types';
import { DataBoxFolderType } from './DataBoxes.types';
import { DataBoxListStatesType } from './MessageListContext.types';

export const DataBoxActionInfoContext =
	createContext<Nullable<DataBoxActionInfoContextType>>(null);

interface DataBoxActionInfoProviderProps {
	folderType: DataBoxFolderType;
	dataBoxId: string;
	children: React.ReactNode;
}

export function DataBoxActionInfoProvider({
	folderType,
	dataBoxId,
	children,
}: DataBoxActionInfoProviderProps) {
	const { list: dataBoxes } = useDataBoxStore();

	const [listState, setListState] = useState(DataBoxListStatesType.List);
	const [emptyFolder, setEmptyFolder] = useState(true);

	useDataBoxEvents({
		onListStateChange: (_, { variant, isEmptyFolder }) => {
			setListState(variant);
			setEmptyFolder(isEmptyFolder);
		},
	});

	return (
		<DataBoxActionInfoContext.Provider
			value={{
				listState,
				folderType,
				emptyFolder,
				dataBox: dataBoxes.find(
					(dataBox) => dataBox.datovaSchrankaId === dataBoxId
				),
			}}>
			{children}
		</DataBoxActionInfoContext.Provider>
	);
}

export const useDataBoxActionInfoContext = (): DataBoxActionInfoContextType =>
	useContext(DataBoxActionInfoContext) as DataBoxActionInfoContextType;
