import { useState } from 'react';
import {
	DatovkaApiType,
	fetchDataBoxesRecipientQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';

export const useRecipientSearchAutocomplete = () => {
	const [text, setText] = useState<string>('');

	const mainQuery = usePoQuery<DatovkaApiType[]>({
		queryKey: ['recipient-autocomplete', text],
		queryFn: async () => fetchDataBoxesRecipientQuery(text),
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		isLoading: mainQuery.isLoading,
		isFetching: mainQuery.isFetching,
		result: mainQuery.data,
		text,
		setText,
	};
};
