import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const SettingsNotificationFormSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<GovSkeleton
					height={'28px'}
					width={generateRandomNumber(28, 40) + '%'}
					className={'py-2'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
				<DataTable customClasses={'gov-skeleton-no-mb mt-4'}>
					<thead>
						<tr>
							<th className={'w-4/6 [ md:w-3/6 ]'}>&nbsp;</th>
							<th className={'md:w-1/6'}>
								<GovSkeleton
									height={'19px'}
									width={'60px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</th>
							<th className={'md:w-1/6'}>
								<GovSkeleton
									height={'19px'}
									width={'60px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</th>
							<th className={'hidden md:table-cell'}>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={'!pb-0'}>
								<GovSkeleton
									height={'19px'}
									width={generateRandomNumber(28, 40) + '%'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
						<tr>
							<td className={'!pb-0'}>
								<GovSkeleton
									height={'19px'}
									width={generateRandomNumber(28, 40) + '%'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
						<tr>
							<td className={'!pb-0'}>
								<GovSkeleton
									height={'19px'}
									width={generateRandomNumber(28, 40) + '%'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
						<tr>
							<td className={'!pb-0'}>
								<GovSkeleton
									height={'19px'}
									width={generateRandomNumber(28, 40) + '%'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'text-center'}>
								<GovSkeleton
									height={'24px'}
									width={'24px'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
					</tbody>
				</DataTable>
			</ContentLayout>
		</LayoutGap>
	);
};
