import { differenceInCalendarDays, isBefore } from 'date-fns';
import { PodaniPodaniInstanceDto } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { toDateReadable, today } from '@gov-nx/utils/common';

export interface SubmissionStatus {
	message: string;
	date: Nullable<string | number>;
}

export function prepareStatus(
	submission: PodaniPodaniInstanceDto
): SubmissionStatus {
	const date = today();

	if (submission.datumVyrizeni) {
		return {
			message: 'vyrizeno',
			date: toDateReadable(submission.datumVyrizeni),
		};
	}

	if (submission.automatickeZpracovani) {
		return { message: 'vyrizeno', date: null };
	}

	if (
		submission.lhutaVyrizeni &&
		isBefore(date, today(submission.lhutaVyrizeni))
	) {
		return {
			message: 'lhuta-do',
			date: toDateReadable(submission.lhutaVyrizeni),
		};
	}

	const diff = differenceInCalendarDays(date, today(submission.lhutaVyrizeni));
	return { message: 'v-prodleni', date: diff };
}
