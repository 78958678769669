import React, { createContext, useCallback, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	useLastDocument,
	usePersonCommunicationData,
	useProcessControl,
} from '@gov-nx/core/hooks';
import {
	useCountry,
	useLanguage,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	WarningRequestHeader,
	getApiWarningRequestHeader,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ServiceContextControls,
	ServiceContextTypes,
} from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { filteredEuCountriesToSelect } = useCountry({
		filterOut: ['cz'],
	});
	const { filteredLanguagesToSelect } = useLanguage({
		filterOut: ['CS'],
	});
	const { toastMessage } = useMessageEvents();
	const [changeEmail, setChangeEmail] = useState<boolean>(false);
	const { emailVerified } = usePersonCommunicationData();
	const { downloadDocument } = useDownloadEvents();
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		mustWaitForProcessing: null,
	});
	const formDefinition = FormInstance({
		code,
		isEmail: !!emailVerified,
		email: emailVerified,
	});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const lastDocument = useLastDocument(code, true, { typOsoby: 'FO' });

	const submitMutation = usePoMutation({
		mutationFn: (data: FormData) => {
			const prepared = prepareSubmitData(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async (response) => {
			const headerWarning = getApiWarningRequestHeader(response);
			if (headerWarning) {
				lastDocument.invalidateCache();
				formDefinition.formReset();
				setControls({
					mustWaitForProcessing: headerWarning,
					processLoading: false,
				});
			} else if (response.data > 0) {
				downloadDocument({
					documentId: response.data,
					callback: async () => {
						lastDocument.invalidateCache();
						formDefinition.formReset();
						setControls({ processLoading: false });
					},
				});
			} else if (response.data === 0) {
				lastDocument.invalidateCache();
				formDefinition.formReset();
				setControls({
					mustWaitForProcessing: WarningRequestHeader.UNKNOW,
					processLoading: false,
				});
			} else {
				toastMessage({
					content: tsn('formular.zprava.chyba-pri-odesilani'),
					options: {
						variant: 'error',
						time: 0,
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
				});
				lastDocument.invalidateCache();
				setControls({
					processLoading: false,
					processError: new GovError(
						`[Service]: An unexpected error has occurred in (${code})`
					),
				});
			}
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({
			mustWaitForProcessing: null,
			processError: null,
			processLoading: true,
		});
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				controls,
				setControls,
				isEmail: !!emailVerified,
				email: emailVerified,
				changeEmail,
				setChangeEmail,
				lastDocument,
				filteredEuCountriesToSelect,
				filteredLanguagesToSelect,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisRejstrikuTrestuFOContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
