import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, Storage } from '@gov-nx/module/page';
import { Space } from '@gov-nx/ui/web';

interface StorageUsageProps {
	storage: Storage;
}

export const StorageUsage = ({ storage }: StorageUsageProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	if (storage.isStorageFull) {
		return (
			<>
				<div>
					<strong>{storage.usedFormatted}</strong>
					<Space />
					{tn('uloziste.z')}
					<Space />
					{storage.maximumFormatted}
				</div>
				<div>
					<strong>{storage.fullStorageInPercentFormatted}</strong>
				</div>
			</>
		);
	}
	return (
		<>
			<div>
				<strong>{storage.usedFormatted}</strong>
				<Space />
				{tn('uloziste.z')}
				<Space />
				{storage.maximumFormatted}
			</div>
			<div>
				{tn('uloziste.zbyva')}
				<Space />
				<strong>{storage.freeInPercentFormatted}</strong>
			</div>
		</>
	);
};
