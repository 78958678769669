import { useState } from 'react';

export const usePetitionToggleText = (maxSymbol: number) => {
	const [showFullText, setShowFullText] = useState(false);

	const toggleFullText = () => {
		setShowFullText(!showFullText);
	};

	const truncateText = (text: string) => {
		if (showFullText) {
			return text;
		} else if (text.length <= maxSymbol) {
			return text;
		} else {
			return text.slice(0, maxSymbol) + '...';
		}
	};

	return { truncateText, toggleFullText };
};
