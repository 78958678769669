import { GovIcon } from '@gov-design-system-ce/react';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { WebCopyButtonProps } from '@gov-nx/ui/types';
import { Button } from './Button';

export const CopyButton = ({
	label,
	value,
	onCopy,
	size = 's',
	variant = 'primary',
	type = 'base',
	inverse = false,
	children,
}: WebCopyButtonProps) => {
	const { toastMessageSuccess } = useMessageEvents();
	const { t } = useLocale(LocalizeNameSpaceTypes.Global);

	const copyValue = useCallback(() => {
		const valueToCopy = value.toString();
		const result = copy(valueToCopy);
		onCopy && onCopy(valueToCopy, result);
		toastMessageSuccess(t('akce.zkopirovano'));
	}, [onCopy, t, toastMessageSuccess, value]);

	return (
		<Button
			variant={variant}
			wcagLabel={label}
			size={size}
			onClick={() => copyValue()}
			type={type}
			inverse={inverse}>
			{children ? (
				children
			) : (
				<GovIcon
					name={'copy'}
					type={'basic'}
					slot={'right-icon'}></GovIcon>
			)}
		</Button>
	);
};
