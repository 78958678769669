import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePoMutation, documentUploadQuery } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	FormInstance,
	prepareSubmitData,
} from './MyFilesUploadFormDefinitions';
import { MojeSouboryNahratContextType } from './context.types';

export const MojeSouboryNahratContext =
	createContext<Nullable<MojeSouboryNahratContextType>>(null);

type MojeSouboryNahratContextProviderProps = {
	children: React.ReactNode;
	code: PageCode;
};

export function MojeSouboryNahratContextProvider({
	children,
	code,
}: MojeSouboryNahratContextProviderProps) {
	const { t } = useTranslation([code]);
	const { controls, setControls } = useProcessControl();
	const { toastMessageSuccess } = useMessageEvents();
	const queryClient = useQueryClient();
	const isSubmitted = useBoolean();
	const hasNotFinishedUploads = useBoolean(false);

	const isUploadModalOpened = useBoolean(false);
	const formDefinition = FormInstance({ code });

	useEffect(() => {
		if (!isUploadModalOpened.value) {
			formDefinition.formReset();
		}
	}, [isUploadModalOpened.value]);

	const uploadMutation = usePoMutation({
		mutationFn: documentUploadQuery,
		onSuccess: async (response) => {
			toastMessageSuccess(t('zpravy.dokument-byl-uspesne-ulozen'));
			await queryClient.invalidateQueries({ queryKey: ['uloziste-vyuziti'] });
			await queryClient.invalidateQueries(['documents', code]);
			formDefinition.formReset();
			isSubmitted.setTrue();
			isUploadModalOpened.setFalse();
		},
	});

	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		isSubmitted.setFalse();
		return uploadMutation.mutate(
			prepareSubmitData(formDefinition.formMethods.getValues())
		);
	});

	return (
		<MojeSouboryNahratContext.Provider
			value={{
				code,
				formDefinition,
				controls,
				setControls,
				isUploadModalOpened,
				onSubmit,
				isUploading: uploadMutation.isLoading,
				formShouldBeReset: isSubmitted.value || !isUploadModalOpened.value,
				hasNotFinishedUploads,
			}}>
			{children}
		</MojeSouboryNahratContext.Provider>
	);
}

export const useMojeSouboryNahratContext = (): MojeSouboryNahratContextType =>
	useContext(MojeSouboryNahratContext) as MojeSouboryNahratContextType;
