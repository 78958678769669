import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { Optional, is } from '@gov-nx/core/types';
import { useDataBoxCredit } from '@gov-nx/module/data-box';

interface LowCreditPromptProps {
	senderId: Optional<string>;
	recipient: Optional<DatovkaApiType>;
	messagePrice: number;
	onPromptClose: () => void;
}

export const useLowCreditPrompt = ({
	senderId,
	recipient,
	messagePrice,
	onPromptClose,
}: LowCreditPromptProps) => {
	const { credit } = useDataBoxCredit(senderId);
	const isLowCredit = is(credit) && credit < messagePrice;

	const isOpen =
		is(recipient) && !recipient?.typSchranky?.startsWith('OVM') && isLowCredit;

	return {
		isOpen,
		recipient,
		senderId,
		onPromptClose,
	};
};
