import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import {
	Button,
	FormDatePicker,
	FormWidthElementLayout,
	Modal,
} from '@gov-nx/ui/web';

export const FilterModal = () => {
	const { code, myFilesFilters } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<Modal
			label={tsn('formular.akce.filtrovat')}
			open={myFilesFilters.filterWindowOpened.value}
			onGov-close={myFilesFilters.onFilterWindowClose}>
			<div className={'flex flex-col gap-2 [ md:flex-row md:gap-10 ]'}>
				<FormWidthElementLayout>
					<FormDatePicker
						field={{
							name: 'datumVytvoreniOd',
						}}
						label={{
							children: tsn('formular.pole.obdobi-od'),
						}}
						group={{ gap: 's' }}
					/>
				</FormWidthElementLayout>
				<FormWidthElementLayout>
					<FormDatePicker
						field={{
							name: 'datumVytvoreniDo',
						}}
						label={{
							children: tsn('formular.pole.obdobi-do'),
						}}
						group={{ gap: 's' }}
					/>
				</FormWidthElementLayout>
			</div>
			<div
				slot="footer"
				className={'flex gap-4'}>
				<Button
					wcagLabel={tsn('formular.akce.filtrovat')}
					name="submit"
					nativeType="submit"
					variant="primary"
					size="l"
					type="solid">
					{tsn('formular.akce.filtrovat')}
				</Button>
				{(myFilesFilters.filter.datumVytvoreniOd ||
					myFilesFilters.filter.datumVytvoreniDo) && (
					<Button
						onClick={myFilesFilters.resetFilter}
						variant="error"
						type="outlined"
						wcagLabel={tsn('formular.akce.zrusit')}
						size="l">
						{tsn('formular.akce.zrusit')}
					</Button>
				)}
			</div>
		</Modal>
	);
};
