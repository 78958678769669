import { useEffect } from 'react';
import * as yup from 'yup';
import { RecycleBinType } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useFiltersStore } from '@gov-nx/store/portal-obcana';
import {
	isValidDate,
	toStringDate,
	today,
	useDateShape,
} from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	MyFilesDataRequest,
	MyFilesFilterType,
	MyFilesFormData,
	MyFilesSource,
} from './context.types';

interface FormInstanceProps {
	code: PageCode;
	defaultValues: MyFilesFormData;
}

export function FormInstance({
	code,
	defaultValues,
}: FormInstanceProps): FormDefinition<MyFilesFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { getDatePickerShape } = useDateShape();
	const tsn = getLocalizeCurried(code);
	const { getFilters } = useFiltersStore();
	const filters = getFilters<MyFilesFilterType>(code);

	const formSchema = yup
		.object<FormSchemaShape<MyFilesFormData>>({
			text: yup.string().optional(),
			selection: yup.object().optional(),

			datumVytvoreniOd: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.datum-vytvoreni-od.type'),
			}).max(today(), tsn('formular.validace.datum-vytvoreni-od.max')),
			datumVytvoreniDo: getDatePickerShape({
				isInvalidMessage: tsn('formular.validace.datum-vytvoreni-do.type'),
			})
				.max(today(), tsn('formular.validace.datum-vytvoreni-do.max'))
				.when('datumVytvoreniOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(
							from,
							tsn(
								'formular.validace.datum-vytvoreni-do.starsi-nez-datum-vytvoreni-od'
							)
						);
					}
					return schema;
				}),
		})
		.required();

	const formMethods = usePoForm<MyFilesFormData>({
		formSchema,
		defaultValues,
	});

	useEffect(() => {
		filters?.text && formMethods.setValue('text', filters.text);
		filters?.datumVytvoreniOd &&
			formMethods.setValue('datumVytvoreniOd', filters.datumVytvoreniOd);
		filters?.datumVytvoreniDo &&
			formMethods.setValue('datumVytvoreniDo', filters.datumVytvoreniDo);
		filters?.selection && formMethods.setValue('selection', filters.selection);
	}, []);

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareQueryData(
	filter: MyFilesFilterType,
	source?: MyFilesSource,
	isFile?: boolean
): MyFilesDataRequest {
	return {
		text: filter.text ?? undefined,
		zdroje: source ?? undefined,
		smazano: 'false',
		razeni: [filter.razeni],
		start: filter.start ?? undefined,
		pocet: filter.pageSize,
		datumVytvoreniOd: filter.datumVytvoreniOd
			? toStringDate(filter.datumVytvoreniOd)
			: undefined,
		datumVytvoreniDo: filter.datumVytvoreniDo
			? toStringDate(filter.datumVytvoreniDo)
			: undefined,
		jeSoubor: isFile ?? undefined,
	};
}

export function prepareQueryBinnedData(
	filter: MyFilesFilterType
): MyFilesDataRequest {
	return {
		typ: RecycleBinType.DOKUMENT,
		razeni: [filter.razeni],
		start: filter.start ?? undefined,
		pocet: filter.pageSize,
	};
}
