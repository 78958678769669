import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, ContentLayout, LayoutGap } from '@gov-nx/ui/web';

interface EPetitionClosedStatusBannerProps {
	code: PageCode;
}

export const EPetitionClosedStatusBanner = ({
	code,
}: EPetitionClosedStatusBannerProps) => {
	const { t } = useTranslation(code);
	const {
		petition,
		controls,
		ePetitionSubmitClose,
		handleEPetitionDownload,
		handleEPetitionSubmit,
	} = useEPeticeDetailContext();

	return (
		<ContentLayout>
			<LayoutGap gap={6}>
				<h2 className={'text-xl'}>{t('pole.zpusob.predani-vyberte')}</h2>

				<GovMessage variant={'primary'}>
					{ePetitionSubmitClose.isBeforeSubmit ? (
						<GovIcon
							type={'basic'}
							name={'lightbulb-fill'}
							slot={'icon'}></GovIcon>
					) : (
						<GovIcon
							type={'basic'}
							name={'exclamation-triangle-fill'}
							slot={'icon'}></GovIcon>
					)}
					<div
						className={
							'flex-col flex justify-between items-start gap-4 [ md:flex-row ]'
						}>
						<div className={'flex-col flex gap-2 flex-1'}>
							{ePetitionSubmitClose.isBeforeSubmit
								? t('zprava.podani.zbytek-dni', {
										namespace: code,
										days: ePetitionSubmitClose.daysLeft,
								  })
								: t('zprava.podani.lhuta-ubehla')}
						</div>
					</div>
				</GovMessage>

				<div className="flex flex-col gap-4 mt-2 [ md:flex-row ]">
					<GovIcon
						name="datovka"
						className={'w-10 h-10 grow-0 shrink-0 text-warning-400'}
					/>
					<div className="flex flex-col">
						<h3 className={'mb-1 text-l font-medium'}>
							{t('pole.zpusob-podani-datovka')}
						</h3>
						<p className={'text-secondary-700'}>
							{t('pole.podani-adresat')}:{' '}
							<strong>{petition?.adresat?.nazev}</strong> (
							{petition?.adresat?.datovaSchrankaId})
						</p>
						<p className={'text-secondary-700'}>{t('pole.datovka-popis')}</p>
					</div>
					<Button
						onClick={() => handleEPetitionSubmit()}
						disabled={
							controls.isSubmitProcessing ||
							!ePetitionSubmitClose.isBeforeSubmit
						}
						loading={controls.isSubmitProcessing}
						variant="primary"
						type="solid"
						size="s"
						className={'[ md:ml-auto md:mt-2 ]'}
						wcagLabel={t('akce.poslat')}>
						<GovIcon
							name="send"
							slot="left-icon"
						/>
						{t('akce.poslat')}
					</Button>
				</div>
				<div className="flex flex-col gap-4 [ md:flex-row ]">
					<GovIcon
						name="journal-text"
						className={'w-10 h-10 grow-0 shrink-0 text-primary-600 [ md:mt-2 ]'}
					/>
					<div className="flex flex-col">
						<h3 className={'mb-1 text-l font-medium'}>
							{t('pole.zpusob-podani-osobne')}
						</h3>
						<p className={'text-secondary-700'}>{t('pole.osobne-popis')}</p>
					</div>
					<Button
						onClick={() => handleEPetitionDownload()}
						loading={controls.isDownloadProcessing}
						disabled={
							controls.isDownloadProcessing ||
							!ePetitionSubmitClose.isBeforeSubmit
						}
						variant="primary"
						nativeType="button"
						type="solid"
						size="s"
						className={'[ md:ml-auto md:mt-2 ]'}
						wcagLabel={t('akce.stahnout')}>
						<GovIcon
							name="download"
							slot="left-icon"
						/>
						{t('akce.stahnout')}
					</Button>
				</div>
			</LayoutGap>
		</ContentLayout>
	);
};
