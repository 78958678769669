import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	DataBoxListStatesType,
	DataBoxSearchFormProvider,
	useDataBoxesContext,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageListConfirmModal } from './DataBoxMessageListConfirmModal';
import { DataBoxMessageListFooter } from './DataBoxMessageListFooter';
import { DataBoxMessageListHeader } from './DataBoxMessageListHeader';
import { DataBoxMessageListMain } from './DataBoxMessageListMain';

export const DataBoxMessageListPage = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { dataBoxId, disableNewMessageButton } = useDataBoxesContext();

	const {
		listState,
		messagesFilter: { folderType, setQuery },
		messagesSelect: { showCheckboxes },
		isMessageListHidden,
	} = useDataBoxMessageListContext();

	if (
		!Object.values(DataBoxFolderType).includes(folderType as DataBoxFolderType)
	) {
		return <div>{tn('zpravy.chyba')}</div>;
	}

	return (
		<section
			className={cx(
				'flex-col h-full bg-neutral-white [ ds:flex ds:grow-0 ds:shrink-0 ds:w-[400px] ds:rounded-2xl ds:overflow-hidden ]',
				isMessageListHidden ? 'is-hidden' : 'is-visible'
			)}>
			<DataBoxSearchFormProvider setQuery={setQuery}>
				<DataBoxMessageListHeader />
				<DataBoxMessageListMain />
			</DataBoxSearchFormProvider>
			{showCheckboxes && <DataBoxMessageListFooter />}
			<DataBoxMessageListConfirmModal />

			{listState === DataBoxListStatesType.List && !isMessageListHidden && (
				<Button
					href={routeResolver(PageCode['datove-schranky-nova'], {
						dataBoxId,
						folderType,
					})}
					variant={'primary'}
					type={'solid'}
					className={'fixed bottom-6 right-5 visible z-50 [ xl:hidden ]'}
					disabled={disableNewMessageButton}
					wcagLabel={tn('zpravy.wcag.nova-zprava')}>
					<GovIcon
						slot={'left-icon'}
						name={'plus-circle'}
					/>
					{tn('zpravy.nova-zprava')}
				</Button>
			)}
		</section>
	);
};
