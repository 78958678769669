import { Optional } from '@gov-nx/core/types';
import { WebInputProps } from '@gov-nx/ui/types';
import { FormInput } from './FormInput';

export const FormNumberInput = (props: WebInputProps) => {
	return (
		<FormInput
			{...props}
			field={{ ...props.field, inputType: 'number' }}
			toValue={(input): Optional<number> => {
				return input ? parseInt(input, 10) : undefined;
			}}
			fromValue={(int) => {
				if (typeof int === 'number') {
					return `${int}`;
				}
				return int;
			}}
		/>
	);
};
