import { axiosInstance } from '../axios';
import { CiselnikyCiselnikPolozkaSeznamDto } from '../generated';

export const legalFormQuery =
	async (): Promise<CiselnikyCiselnikPolozkaSeznamDto> => {
		const { data } = await axiosInstance.get(
			'/api/v1/ciselniky/ROS_PRAVNI_FORMA/polozky'
		);
		return data;
	};
