import React from 'react';
import { MySubmissionVoterCardAplication } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { propEq } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { transformElectionRounds } from '@gov-nx/module/service';
import { Row } from './components';

interface VoterRegistrationProps {
	code: PageCode;
	submission: MySubmissionVoterCardAplication;
}

export const VoterRegistration = ({
	code,
	submission,
}: VoterRegistrationProps) => {
	const { t } = useLocale(code);

	const rounds = transformElectionRounds(submission)
		.filter(propEq('isRequested', true))
		.map((round) =>
			t(`volicsky-prukaz.kolo`, {
				od: round.from,
				do: round.to,
				kolo: round.round ?? '',
			})
		);

	return (
		<tbody>
			<Row
				label={t('volicsky-prukaz.volby')}
				testId="volicsky-prukaz.volby">
				{t(`volicsky-prukaz.druh.${submission?.parametry.druh}`)}
			</Row>
			{rounds.length > 0 ? (
				<Row label={t('volicsky-prukaz.pro-kola')}>{rounds.join(' + ')}</Row>
			) : null}
			<Row
				label={t('volicsky-prukaz.zpusob-prevzeti.klic')}
				testId="volicsky-prukaz.zpusob-prevzeti.klic">
				{t(
					`volicsky-prukaz.zpusob-prevzeti.${submission.parametry.zpusobPrevzeti}`
				)}
				{submission.parametry.uradOdesleNaAdresu
					? ` (${submission.parametry.uradOdesleNaAdresu})`
					: null}
			</Row>
			<Row
				label={t('volicsky-prukaz.kontaktni-telefon')}
				testId="volicsky-prukaz.kontaktni-telefon">
				{submission.parametry.kontaktniTelefon}
			</Row>
			<Row label={t('volicsky-prukaz.kontaktni-email')}>
				{submission.parametry.kontaktniEmail}
			</Row>
		</tbody>
	);
};
