import { DatoveSchrankyPrilohaDto } from '@gov-nx/api/portal-obcana';
import { IUseBoolean } from '@gov-nx/utils/common';

export type DataBoxMessageAttachmentContextType = {
	isDropdownOpen: IUseBoolean;
	attachmentOperations: {
		handleSaveAttachmentToDocuments: (
			attachment: DatoveSchrankyPrilohaDto
		) => void;
		handleDownloadAttachment: (attachment: DatoveSchrankyPrilohaDto) => void;
	};
	loadingItems: Partial<
		Record<DataBoxMessageAttachmentLoadingItemsType, boolean>
	>;
};

export enum DataBoxMessageAttachmentLoadingItemsType {
	SaveToDocuments = 'saveToDocuments',
	Download = 'download',
}
