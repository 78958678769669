import { CiselnikyVolbyDto } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { is, Optional } from '@gov-nx/core/types';
import {
	getElectionDates,
	IElectionControl,
	ServiceCode,
} from '@gov-nx/module/service';
import { toDateReadable } from '@gov-nx/utils/common';

export const useVoterRegistrationCardStrings = (
	checkMutation?: IElectionControl,
	election?: CiselnikyVolbyDto
) => {
	const { t } = useLocale(ServiceCode['volicsky-prukaz']);
	const params = checkMutation?.params;
	if (!params) return;

	const vyzvednutiNaUraduDatumOd = params.vyzvednutiNaUraduDatumOd
		? toDateReadable(params.vyzvednutiNaUraduDatumOd)
		: undefined;
	const vyzvednutiNaUraduDatumDo = params.vyzvednutiNaUraduDatumDo
		? toDateReadable(params.vyzvednutiNaUraduDatumDo)
		: undefined;
	const uradOdesleNejdrive = params.uradOdesleNejdrive
		? toDateReadable(params.uradOdesleNejdrive)
		: undefined;

	const officePickupDescription = (): Optional<string> => {
		if (params.vyzvednutiNaUradu) {
			if (vyzvednutiNaUraduDatumOd && vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.zastoupeni',
					{
						terminOd: vyzvednutiNaUraduDatumOd,
						terminDo: vyzvednutiNaUraduDatumDo,
					}
				);
			}
			if (vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.bezTerminOd',
					{
						terminDo: vyzvednutiNaUraduDatumDo,
					}
				);
			}
			if (!vyzvednutiNaUraduDatumOd && !vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.bezTerminOdDo'
				);
			}
		} else {
			if (vyzvednutiNaUraduDatumOd && vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.bezAdresyUradu',
					{
						terminOd: vyzvednutiNaUraduDatumOd,
						terminDo: vyzvednutiNaUraduDatumDo,
					}
				);
			}
			if (vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.bezTerminOd-bezAdresyUradu',
					{ terminDo: vyzvednutiNaUraduDatumDo }
				);
			}
			if (!vyzvednutiNaUraduDatumOd && !vyzvednutiNaUraduDatumDo) {
				return t(
					'volby.info.zpusobPrevzeti.URAD.osobneNeboPlnaMoc.bezTerminOdDo-bezAdresyUradu'
				);
			}
		}
	};

	const postOfficeDescription = () => {
		return {
			title: uradOdesleNejdrive
				? t('volby.zpusobPrevzeti.POSTA.odeslanoNejdrive.b', {
						nejdrive: uradOdesleNejdrive,
				  })
				: t('volby.zpusobPrevzeti.POSTA.odeslanoCoNejdrive'),
			description: t('volby.zpusobPrevzeti.POSTA.info.doVlastnichRukou'),
		};
	};

	const officeAbroadDescription = () => {
		return {
			title: uradOdesleNejdrive
				? t('volby.zpusobPrevzeti.URAD_ZAHRANICNI.odeslanoNejdrive.b', {
						nejdrive: uradOdesleNejdrive,
				  })
				: t('volby.zpusobPrevzeti.URAD_ZAHRANICNI.odeslanoCoNejdrive'),
			description: t('volby.zpusobPrevzeti.URAD_ZAHRANICNI.infoPosta', {
				dny: getElectionDates(election) ?? '',
			}),
		};
	};

	return {
		officeAddress: checkMutation.params?.vyzvednutiNaUradu,
		officePickupDates: [vyzvednutiNaUraduDatumOd, vyzvednutiNaUraduDatumDo]
			.filter(is)
			.join(' - '),
		postOfficeDescription: postOfficeDescription(),
		officePickupDescription: officePickupDescription(),
		officeAbroadDescription: officeAbroadDescription(),
	};
};
