import { useQueryClient } from '@tanstack/react-query';
import { DatoveSchrankyPrilohaDto } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useDownloadEvents } from '@gov-nx/core/events';
import { DataBoxFolderType } from '../providers/DataBoxes.types';
import { DataBoxMessageAttachmentLoadingItemsType } from '../providers/MessageAttachmentContext.types';
import { useSaveAttachmentToDocuments } from './useSaveAttachmentToDocuments';

interface AttachmentOperationsProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	updateLoadingItems: (
		loadingItem: DataBoxMessageAttachmentLoadingItemsType,
		add: boolean
	) => void;
	closeDropdown: () => void;
}

export const useAttachmentOperations = ({
	dataBoxId,
	folderType,
	messageId,
	updateLoadingItems,
	closeDropdown,
}: AttachmentOperationsProps) => {
	const { storageRefetch } = useDataBoxEvents();
	const queryClient = useQueryClient();

	const saveAttachmentToDocuments = useSaveAttachmentToDocuments({
		onError: () => {
			updateLoadingItems(
				DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments,
				false
			);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['documents'] });

			storageRefetch();
			updateLoadingItems(
				DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments,
				false
			);
		},
	});

	const handleSaveAttachmentToDocuments = (
		attachment: DatoveSchrankyPrilohaDto
	) => {
		updateLoadingItems(
			DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments,
			true
		);
		closeDropdown();

		saveAttachmentToDocuments.mutate({
			attachment,
			dataBoxId,
			messageId,
			folderType,
		});
	};

	const { downloadDataBoxAttachment } = useDownloadEvents();

	const handleDownloadAttachment = (attachment: DatoveSchrankyPrilohaDto) => {
		updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.Download, true);
		closeDropdown();

		downloadDataBoxAttachment({
			attachmentData: { dataBoxId, messageId, attachment, folderType },
			callback: () => {
				updateLoadingItems(
					DataBoxMessageAttachmentLoadingItemsType.Download,
					false
				);
			},
		});
	};

	return {
		handleSaveAttachmentToDocuments,
		handleDownloadAttachment,
	};
};
