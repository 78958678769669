import React from 'react';
import { is } from '@gov-nx/core/types';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableDimensionsProps {
	vehicle: Vehicle;
	tsn: (code: string) => string;
}

export const TableDimensions = ({ vehicle, tsn }: TableDimensionsProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.karoserie.vyrobce'),
					vehicle.karoserie.karoserieZakladni?.karoserieVyrobce?.xVal,
				],
				[
					tsn('tabulka.karoserie.druh-typ'),
					vehicle.karoserie.karoserieZakladni?.karoserieDruh?.xVal,
				],
				[
					tsn('tabulka.karoserie.vyrobni-cislo-dalsi-stupen'),
					vehicle.karoserie.karoserieZakladni?.karoserieVyrobniCislo?.xVal,
				],
				[
					tsn('tabulka.karoserie.barva'),
					[
						vehicle.karoserie.karoserieZakladni?.karoserieBarva?.xVal,
						vehicle.karoserie.karoserieZakladni?.karoserieBarvaDoplnkova?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.karoserie.mist-celkem-k-sezeni-k-stani'),
					[
						vehicle.karoserie.karoserieDodatek?.karoserieMistCelkem?.xVal,
						vehicle.karoserie.karoserieDodatek?.karoserieMistSezeni?.xVal,
						vehicle.karoserie.karoserieDodatek?.karoserieMistStani?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.karoserie.celkova-delka-sirka-vyska'),
					[
						vehicle.rozmery.rozmeryZakladni?.rozmeryDelka?.xVal,
						vehicle.rozmery.rozmeryZakladni?.rozmerySirka?.xVal,
						vehicle.rozmery.rozmeryZakladni?.rozmeryVyska?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn('tabulka.karoserie.rozvor'),
					vehicle.rozmery.rozmeryDodatek?.rozmeryRozvor?.xVal,
				],
				[
					tsn('tabulka.karoserie.rozchod'),
					[
						vehicle.napravy.napravyRozchod?.napravyRozchod1?.xVal,
						vehicle.napravy.napravyRozchod?.napravyRozchod2?.xVal,
						vehicle.napravy.napravyRozchod?.napravyRozchod3?.xVal,
						vehicle.napravy.napravyRozchod?.napravyRozchod4?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
			]}
		/>
	);
};
