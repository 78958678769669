import React from 'react';
import { is } from '@gov-nx/core/types';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableWeightProps {
	vehicle: Vehicle;
	tsn: (code: string) => string;
}

export const TableWeight = ({ vehicle, tsn }: TableWeightProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.hmotnost.provozni'),
					vehicle.hmotnosti.hmotnostiZakladni?.hmotnostiProvozni?.xVal,
				],
				[
					tsn('tabulka.hmotnost.nejvyssi-technicky-pristupna-povolena'),
					[
						vehicle.hmotnosti.hmotnostiZakladni?.hmotnostiPrip?.xVal,
						vehicle.hmotnosti.hmotnostiZakladni?.hmotnostiPov?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn(
						'tabulka.hmotnost.nejvyssi-technicky-pristupna-povolena-na-napravu'
					),
					[
						[
							vehicle.napravy.napravyHmotnosti?.hmotnostiPripN1?.xVal,
							vehicle.napravy.napravyHmotnosti?.hmotnostiPovN1?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyHmotnosti?.hmotnostiPripN2?.xVal,
							vehicle.napravy.napravyHmotnosti?.hmotnostiPovN2?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyHmotnosti?.hmotnostiPripN3?.xVal,
							vehicle.napravy.napravyHmotnosti?.hmotnostiPovN3?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyHmotnosti?.hmotnostiPripN4?.xVal,
							vehicle.napravy.napravyHmotnosti?.hmotnostiPovN4?.xVal,
						]
							.filter(is)
							.join(' / '),
					]
						.filter((item) => item !== '')
						.join('; '),
				],
				[
					tsn(
						'tabulka.hmotnost.nejvyssi-technicky-pristupna-povolena-pripojneho-vozidla-brzdeneho'
					),
					[
						vehicle.hmotnosti.vozidloHmotnostiPrives?.hmotnostiPripBrzdenePV
							?.xVal,
						vehicle.hmotnosti.vozidloHmotnostiPrives?.hmotnostiPovBrzdenePV
							?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn(
						'tabulka.hmotnost.nejvyssi-technicky-pristupna-povolena-pripojneho-vozidla-nebrzdeneho'
					),
					[
						vehicle.hmotnosti.vozidloHmotnostiPrives?.hmotnostiPripNebrzdenePV
							?.xVal,
						vehicle.hmotnosti.vozidloHmotnostiPrives?.hmotnostiPovNebrzdenePV
							?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
				[
					tsn(
						'tabulka.hmotnost.nejvyssi-technicky-pristupna-povolena-jizdni-soupravy'
					),
					[
						vehicle.hmotnosti.hmotnostiZakladni?.hmotnostiPripustnaJS?.xVal,
						vehicle.hmotnosti.hmotnostiZakladni?.hmotnostiPovolenaJS?.xVal,
					]
						.filter(is)
						.join(' / '),
				],
			]}
		/>
	);
};
