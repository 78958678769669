import { useWatch } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { FormListItem } from './FormListItem';

export const AdditionalDataList = () => {
	const { form, hasAdditionalInfo, removeAdditionalData } =
		useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const localData = useWatch({
		control: form.control,
	});

	if (!hasAdditionalInfo) {
		return null;
	}

	return (
		<ul className={'gov-list--plain flex gap-2 flex-wrap'}>
			{localData.intoTheirOwnHands ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('intoTheirOwnHands')}>
					{tn('nova-zprava.doplnkove-udaje.do-vlastnich-rukou')}
				</FormListItem>
			) : null}
			{localData.senderIdentification ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('senderIdentification')}>
					{tn('nova-zprava.doplnkove-udaje.pridat-identifikaci')}
				</FormListItem>
			) : null}
			{localData.toTheHandsOf ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('toTheHandsOf')}>
					{tn('nova-zprava.doplnkove-udaje.k-rukam-osoby')}:{' '}
					<strong className={'break-all'}>{localData.toTheHandsOf}</strong>
				</FormListItem>
			) : null}
			{localData.naseCisloJednaci ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('naseCisloJednaci')}>
					{tn('nova-zprava.doplnkove-udaje.nase-cislo-jednaci')}:{' '}
					<strong className={'break-all'}>{localData.naseCisloJednaci}</strong>
				</FormListItem>
			) : null}
			{localData.naseSpisovaZnacka ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('naseSpisovaZnacka')}>
					{tn('nova-zprava.doplnkove-udaje.nase-spisova-znacka')}:{' '}
					<strong className={'break-all'}>{localData.naseSpisovaZnacka}</strong>
				</FormListItem>
			) : null}
			{localData.vaseCisloJednaci ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('vaseCisloJednaci')}>
					{tn('nova-zprava.doplnkove-udaje.vase-cislo-jednaci')}:{' '}
					<strong className={'break-all'}>{localData.vaseCisloJednaci}</strong>
				</FormListItem>
			) : null}
			{localData.vaseSpisovaZnacka ? (
				<FormListItem
					onRemoveClick={() => removeAdditionalData('vaseSpisovaZnacka')}>
					{tn('nova-zprava.doplnkove-udaje.vase-spisova-znacka')}:{' '}
					<strong className={'break-all'}>{localData.vaseSpisovaZnacka}</strong>
				</FormListItem>
			) : null}
		</ul>
	);
};
