import * as yup from 'yup';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	LocalizeNameSpaceTypes,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { getKeys } from '@gov-nx/core/types';
import { Tree } from '@gov-nx/ui/types';
import { dataBoxParams, useDataBoxShape } from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData, FormDataBody } from './service.types';

const defaultValues = (): { [key in keyof FormDataBody]: boolean } => {
	return {
		vsechnyUdaje: false,
		_referential: false,
		_nonReferential: false,
		_operational: false,
		zaznamPoskytnutiUdaju: false,
		datumPosledniZmenyUdaju: false,
		certifikat: false,
		email: false,
		telefon: false,
		zaznamDs: false,
		dorucovaciAdresa: false,
		statniObcanstvi: false,
		omezeniSvepravnosti: false,
		mistoNarozeni: false,
		prijmeni: false,
		rodnePrijmeni: false,
		jmeno: false,
		pohlavi: false,
		rodinnyStavPartnerstvi: false,
		adresa: false,
		datumNarozeni: false,
		cislaEId: false,
	};
};

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({
	code,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const fieldsSchema = getKeys(defaultValues()).reduce(
		(all, key) => ({ ...all, [key]: yup.boolean().optional() }),
		{} as { [key in keyof FormDataBody]: yup.BooleanSchema }
	);
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
			...fieldsSchema,
			_referential: yup.boolean().optional(),
			_nonReferential: yup.boolean().optional(),
			_operational: yup.boolean().optional(),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			...defaultValues(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (formData: FormData): DataRequest => {
	const isAll = formData.vsechnyUdaje;

	return {
		params: dataBoxParams(formData),
		body: {
			typVypisu: 'UDAJE_ROB',
			data: {
				...(isAll
					? { vsechnyUdaje: true }
					: {
							...getKeys(defaultValues()).reduce((all, key) => {
								return formData[key] ? { ...all, [key]: formData[key] } : all;
							}, {} as FormDataBody),
					  }),
			},
		},
	};
};

export const useTree = (): Tree => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	return {
		name: 'vsechnyUdaje',
		label: tsn('vyber-udaju.stitky.vsechny-udaje'),
		children: [
			{
				name: '_referential',
				label: tsn('vyber-udaju.stitky.referencni-udaje'),
				children: [
					{ name: 'prijmeni', label: tsn('vyber-udaju.stitky.prijmeni') },
					{
						name: 'rodnePrijmeni',
						label: tsn('vyber-udaju.stitky.rodne-prijmeni'),
					},
					{ name: 'jmeno', label: tsn('vyber-udaju.stitky.jmeno') },
					{ name: 'pohlavi', label: tsn('vyber-udaju.stitky.pohlavi') },
					{
						name: 'rodinnyStavPartnerstvi',
						label: tsn('vyber-udaju.stitky.rodinny-stav-partnerstvi'),
					},
					{ name: 'adresa', label: tsn('vyber-udaju.stitky.adresa') },
					{
						name: 'datumNarozeni',
						label: tsn('vyber-udaju.stitky.datum-narozeni'),
					},
					{
						name: 'mistoNarozeni',
						label: tsn('vyber-udaju.stitky.misto-narozeni'),
					},
					{
						name: 'omezeniSvepravnosti',
						label: tsn('vyber-udaju.stitky.omezeni-svepravnosti'),
					},
					{
						name: 'statniObcanstvi',
						label: tsn('vyber-udaju.stitky.statni-obcanstvi'),
					},
					{ name: 'cislaEId', label: tsn('vyber-udaju.stitky.cisla-eid') },
					{ name: 'zaznamDs', label: tsn('vyber-udaju.stitky.zaznam-ds') },
				],
			},
			{
				name: '_nonReferential',
				label: tsn('vyber-udaju.stitky.nereferencni-udaje'),
				children: [
					{ name: 'telefon', label: tsn('vyber-udaju.stitky.telefon') },
					{ name: 'email', label: tsn('vyber-udaju.stitky.email') },
					{ name: 'certifikat', label: tsn('vyber-udaju.stitky.certifikat') },
				],
			},
			{
				name: '_operational',
				label: tsn('vyber-udaju.stitky.provozni-udaje'),
				children: [
					{
						name: 'zaznamPoskytnutiUdaju',
						label: tsn('vyber-udaju.stitky.zaznam-poskytnuti-udaju'),
					},
					{
						name: 'datumPosledniZmenyUdaju',
						label: tsn('vyber-udaju.stitky.datum-posledni-zmeny-udaju'),
					},
				],
			},
		],
	};
};
