import { pubSubActionBridge } from '@gov-nx/core/app';
import {
	AppActions,
	DataBoxConnectedSubsription,
	DataBoxConnectionFailedSubsription,
	DataBoxVerificationSubsription,
	NotImplementedFeatureSubsription,
} from './appEvents.types';

export enum AppSubscriptionTypes {
	dataBoxVerification = 'DATABOX_VERIFICATION_SUBSCRIPTION',
	dataBoxConnected = 'DATABOX_CONNECTED_SUBSCRIPTION',
	dataBoxConnectionFailed = 'DATABOX_CONNECTION_FAILED_SUBSRIPTION',
	notImplementedFeature = 'NOT_IMPLEMENTED_FEATURE',
}

export const dataBoxVerificationSubsription = (
	payload: DataBoxVerificationSubsription['payload']
): DataBoxVerificationSubsription => ({
	type: AppSubscriptionTypes.dataBoxVerification,
	payload,
});
export const dataBoxConnectedSubsription = (
	payload: DataBoxConnectedSubsription['payload']
): DataBoxConnectedSubsription => ({
	type: AppSubscriptionTypes.dataBoxConnected,
	payload: payload,
});

export const dataBoxConnectionFailedSubsription =
	(): DataBoxConnectionFailedSubsription => ({
		type: AppSubscriptionTypes.dataBoxConnectionFailed,
	});

export const notImplementedFeatureSubsription = (
	payload: NotImplementedFeatureSubsription['payload']
): NotImplementedFeatureSubsription => ({
	type: AppSubscriptionTypes.notImplementedFeature,
	payload: payload,
});

export const appEvents = pubSubActionBridge<AppActions>();
