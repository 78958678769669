import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	OsobyKomunikacniUdajeDto,
	OsobyPodnikatelDto,
	robCommunicationQuery,
	usePoQueries,
	OsobyPodnikatelSeznamDto,
	rosBusinessPersonQuery,
	OsobyKomunikacniUdajeRosSeznamDto,
	rosCommunicationQuery,
	isResponseStatus,
	ResponseError,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable, is } from '@gov-nx/core/types';
import { ProfileRosProcessControl, ROSContext } from './context.types';
import { mergeDataByIco } from './utils';

const ProfilROSContext = createContext<ROSContext | null>(null);

interface SettingsROSContextProviderProps {
	children: React.ReactNode;
}

export function RegistrOsobContextProvider({
	children,
}: SettingsROSContextProviderProps) {
	const { setControls, controls } = useProcessControl<ProfileRosProcessControl>(
		{
			initialDataError: null,
			initialPhotoError: null,
			complaintModal: null,
		}
	);
	const [robCommunication, setRobCommunication] =
		useState<Nullable<OsobyKomunikacniUdajeDto>>(null);
	const [rosCommunication, setRosCommunication] =
		useState<Nullable<OsobyKomunikacniUdajeRosSeznamDto>>(null);
	const [data, setData] = useState<OsobyPodnikatelDto[]>([]);

	const mainQueries = usePoQueries<
		[
			UseQueryResult<OsobyPodnikatelSeznamDto, AxiosError<ResponseError>>,
			UseQueryResult<OsobyKomunikacniUdajeDto, AxiosError<ResponseError>>,
			UseQueryResult<OsobyKomunikacniUdajeDto, AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['profile-ros'],
				queryFn: rosBusinessPersonQuery,
				onError: async (error: Error) => {
					setControls({ initialDataError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['profile-ros-query'],
				queryFn: robCommunicationQuery,
				onError: async (error: Error) => {
					setControls({ initialPhotoError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['profile-ros-communication'],
				queryFn: rosCommunicationQuery,
				onError: async (error: Error) => {
					setControls({ initialPhotoError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
		],
		configs: [
			{},
			{
				errorIgnoreFilter: isResponseStatus(404),
			},
			{
				errorIgnoreFilter: isResponseStatus(404),
			},
		],
	});

	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);
	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		setControls({ initialLoading: true });
	}, []);

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const rosData = queriesResponses[0]
				? (queriesResponses[0] as OsobyPodnikatelSeznamDto)
				: { seznam: [] };
			const robCommunication = queriesResponses[1]
				? (queriesResponses[1] as OsobyKomunikacniUdajeDto)
				: null;
			const rosCommunication = queriesResponses[2]
				? (queriesResponses[2] as OsobyKomunikacniUdajeRosSeznamDto)
				: { seznam: [] };

			setData(rosData.seznam ?? []);
			setRobCommunication(robCommunication);
			setRosCommunication(rosCommunication);
			setControls({ initialLoading: false });
		}
	}, [
		controls.initialLoading,
		isQueriesFetched,
		queriesResponses,
		setControls,
	]);

	return (
		<ProfilROSContext.Provider
			value={{
				controls,
				setControls,
				data,
				robCommunication,
				rosCommunication,
				mergedData: mergeDataByIco(data, rosCommunication),
			}}>
			{children}
		</ProfilROSContext.Provider>
	);
}

export const useProfilROSContextInstance = (): ROSContext =>
	useContext(ProfilROSContext) as ROSContext;
