import {
	DokumentyDokumentDto,
	DokumentyDokumentSeznamDto,
} from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { FilterParam, Optional, pipe } from '@gov-nx/core/types';
import {
	addDay,
	resetHours,
	toDate,
	toDateReadable,
	useBoolean,
	useFilter,
} from '@gov-nx/utils/common';
import { PageCode } from '../../../definitions/codes';
import {
	MyFilesContextType,
	MyFilesFilterType,
	MyFilesFormData,
	MyFilesOrder,
	RecycleBinOrder,
} from '../context.types';

const filterDateRange =
	(from?: Date, to?: Date) => (document: DokumentyDokumentDto) => {
		const date = document.datumVytvoreni
			? toDate(document.datumVytvoreni)
			: undefined;
		if (!date) return true;

		if (from && date < resetHours(from)) {
			return false;
		}

		if (to && date > pipe(to, addDay(), resetHours)) {
			return false;
		}

		return true;
	};

interface MyFilesFiltersProps {
	code: PageCode;
	formMethods: MyFilesContextType['formDefinition']['formMethods'];
	initialState: MyFilesFilterType;
}

export const useMyFilesFilters = ({
	formMethods,
	code,
	initialState,
}: MyFilesFiltersProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const filterWindowOpened = useBoolean(false);

	const { filter, setPartialFilter } = useFilter<MyFilesFilterType>({
		key: code,
		initialState,
		rehydrate: (data) => {
			return {
				...data,
				datumVytvoreniOd:
					typeof data.datumVytvoreniOd === 'string'
						? toDate(data.datumVytvoreniOd)
						: data.datumVytvoreniOd,
				datumVytvoreniDo:
					typeof data.datumVytvoreniDo === 'string'
						? toDate(data.datumVytvoreniDo)
						: data.datumVytvoreniDo,
			};
		},
	});

	const goToPage = (page: number) => {
		setPartialFilter({ start: (page - 1) * filter.pageSize });
	};

	const selectBadge = (selectedBadgeIndex: number) => {
		setPartialFilter({ selectedBadgeIndex, start: 0 });
	};

	const submitFilters = (values: MyFilesFormData) => {
		setPartialFilter({ ...values, start: 0 });
		filterWindowOpened.setFalse();
	};

	const filterDocuments = (
		documents?: DokumentyDokumentSeznamDto
	): Optional<DokumentyDokumentSeznamDto> => {
		if (!documents) return undefined;
		return {
			seznam: documents.seznam?.filter(
				filterDateRange(filter.datumVytvoreniOd, filter.datumVytvoreniDo)
			),
			pocet: documents.pocet,
		};
	};

	const sortFilesBy = (sortParam: MyFilesOrder | RecycleBinOrder) =>
		setPartialFilter({ razeni: sortParam, start: 0 });

	const resetFilter = () => {
		formMethods.setValue('datumVytvoreniOd', undefined);
		formMethods.setValue('datumVytvoreniDo', undefined);
		formMethods.setValue('text', undefined);
		setPartialFilter({
			datumVytvoreniDo: undefined,
			datumVytvoreniOd: undefined,
			text: undefined,
		});
		filterWindowOpened.setFalse();
	};

	const filterParams: FilterParam[] = [
		{
			label: tsn('formular.pole.obdobi-od'),
			value: filter.datumVytvoreniOd
				? toDateReadable(filter.datumVytvoreniOd)
				: undefined,
			isActive: !!filter.datumVytvoreniOd,
			onRemove: () => {
				setPartialFilter({ datumVytvoreniOd: undefined });
				formMethods.resetField('datumVytvoreniOd');
			},
		},
		{
			label: tsn('formular.pole.obdobi-do'),
			value: filter.datumVytvoreniDo
				? toDateReadable(filter.datumVytvoreniDo)
				: undefined,
			isActive: !!filter.datumVytvoreniDo,
			onRemove: () => {
				setPartialFilter({ datumVytvoreniDo: undefined });
				formMethods.resetField('datumVytvoreniDo');
			},
		},
	];

	const onFilterWindowClose = () => {
		formMethods.setValue('datumVytvoreniOd', filter.datumVytvoreniOd);
		formMethods.setValue('datumVytvoreniDo', filter.datumVytvoreniDo);
		filterWindowOpened.setFalse();
	};

	const isNotFirstPage = (filter: MyFilesFilterType) => filter.start !== 0;

	const isEmpty = <T>(list?: T[]) => list?.length === 0;

	return {
		setPartialFilter,
		submitFilters,
		goToPage,
		selectBadge,
		filter,
		filterWindowOpened,
		onFilterWindowClose,
		filterDocuments,
		resetFilter,
		filterParams,
		sortFilesBy,
		isNotFirstPage,
		isEmpty,
		pageNumber: filter.start / filter.pageSize + 1,
	};
};
