import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	DokladyDokladDto,
	OsobyFyzickaOsobaDto,
	sendComplaintQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useAddressSearch, useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../../definitions/codes';
import { getZadostOPoskytnutiUdajuContextInstance } from '../../index';
import { FormInstance, preparePersonSubmitData } from './FormDefinitions';
import {
	FormData,
	ZadostOPoskytnutiUdajuComplaintContextService,
	ZadostOPoskytnutiUdajuComplaintProcessControl,
} from './context.types';

const ZadostOPoskytnutiUdajuReklamaceContext =
	createContext<Nullable<ZadostOPoskytnutiUdajuComplaintContextService>>(null);

interface ZadostOPoskytnutiUdajuComplaintContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
	mainDocument?: DokladyDokladDto;
	individualPerson?: OsobyFyzickaOsobaDto;
}

export function ZadostOPoskytnutiUdajuReklamaceContextProvider({
	children,
	code,
	mainDocument,
	individualPerson,
}: ZadostOPoskytnutiUdajuComplaintContextProviderProps) {
	const { setControls, controls } =
		useProcessControl<ZadostOPoskytnutiUdajuComplaintProcessControl>({
			initialDataError: null,
			initialCountryError: null,
			displayAddressForm: false,
			searchAddressLoading: false,
		});

	const { setControls: complaintSetControls } =
		getZadostOPoskytnutiUdajuContextInstance();
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);

	const formDefinition = FormInstance({
		code,
		individualPerson,
		mainDocument,
		controls,
	});

	const { address, mainQuery } = useAddressSearch({
		formMethods: formDefinition.formMethods,
		onSuccess: () => setControls({ searchAddressLoading: false }),
		onError: () => setControls({ searchAddressLoading: false }),
	});

	const newAddress = formDefinition.formMethods.watch('newAddress');

	useEffect(() => {
		const selectedOption = address.find((option) => option.kod === newAddress);
		if (selectedOption && address.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaPobytu',
				selectedOption.adresaText || ''
			);
		}
	}, [newAddress, address, formDefinition.formMethods]);

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = preparePersonSubmitData(mainDocument, newAddress)(data);
			return sendComplaintQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('formular.zprava.reklamace.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			formDefinition.formReset();
			complaintSetControls({ displayComplaintModal: false });
		},
	});

	const [initialFormValues, setInitialFormValues] = useState<FormData | null>(
		null
	);

	useEffect(() => {
		setInitialFormValues(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods]);

	const resetAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObce');
		formDefinition.formMethods.resetField('nazevUlice');
		formDefinition.formMethods.resetField('cislo');
	};

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		const changedValues: FormData = {};
		for (const fieldName in initialFormValues) {
			const initialValue = initialFormValues[fieldName as keyof FormData];
			const currentValue =
				formDefinition.formMethods.getValues()[fieldName as keyof FormData];
			if (initialValue !== currentValue) {
				changedValues[fieldName as keyof FormData] = currentValue;
			}
		}
		if (Object.keys(changedValues).length > 0) {
			submitMutation.mutate(changedValues);
		} else {
			setControls({ processLoading: false });
		}
	}, [
		submitMutation,
		formDefinition.formMethods,
		setControls,
		initialFormValues,
	]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ZadostOPoskytnutiUdajuReklamaceContext.Provider
			value={{
				controls,
				setControls,
				formDefinition,
				mainDocument,
				individualPerson,
				address,
				mainQuery,
				resetAddressSearchFields,
				onSubmit,
			}}>
			{children}
		</ZadostOPoskytnutiUdajuReklamaceContext.Provider>
	);
}

export const ZadostOPoskytnutiUdajuReklamaceContextInstance =
	(): ZadostOPoskytnutiUdajuComplaintContextService =>
		useContext(
			ZadostOPoskytnutiUdajuReklamaceContext
		) as ZadostOPoskytnutiUdajuComplaintContextService;
