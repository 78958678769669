import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LocalizationLanguage, useAppContext } from '@gov-nx/core/app';
import { useAppLanguage } from '@gov-nx/core/hooks';

export const LanguageSwitchLink = () => {
	const { t } = useTranslation();
	const { setLoading } = useAppContext();
	const { language, toggle } = useAppLanguage();

	const langSwitchTranslationKeySuffix =
		language === LocalizationLanguage.Czech ? 'english' : 'cesky';

	return (
		<Link
			to="#"
			onClick={(event) => {
				event.preventDefault();
				setLoading(true);
				return toggle();
			}}
			aria-label={t(
				`portal-obcana.navigace.wcag.${langSwitchTranslationKeySuffix}`
			)}
			title={t(`portal-obcana.navigace.wcag.${langSwitchTranslationKeySuffix}`)}
			className={'underline text-primary-600 [ visited:text-primary-600 ]'}>
			{t(`portal-obcana.navigace.${langSwitchTranslationKeySuffix}`)}
		</Link>
	);
};
