import { GovIcon, GovMessage, GovModal } from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DokladyType,
	DokumentyReklamaceContextProvider,
	PageCode,
	useDokladyContext,
} from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	CopyButton,
	DataTable,
	LayoutGap,
} from '@gov-nx/ui/web';
import { getTextColor, toDateReadable } from '@gov-nx/utils/common';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';
import { DocumentComplaintView } from './DocumentComplaintView';
import { IdentityDocumentSkeleton } from './IdentityDocumentSkeleton';

export interface IdentifyDocumentViewProps {
	type: DokladyType;
	code: PageCode;
}

export const IdentityDocumentView = ({
	type,
	code,
}: IdentifyDocumentViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const {
		getDocumentByType,
		controls,
		setControls,
		canChangeLicence,
		zadostNovyRidicskyPrukazUrl,
	} = useDokladyContext();

	if (controls.initialLoading) {
		return <IdentityDocumentSkeleton />;
	}

	return (
		<LayoutGap gap={4}>
			{getDocumentByType(type).map((document) => {
				const { classname } = getTextColor(document.platnostDo);
				return (
					<ContentLayout key={document.cisloDokladu}>
						<LayoutGap gap={4}>
							<DataTable responsive>
								<tbody>
									{document.druhDokladu === DokladyType.P ? (
										<tr>
											<td>{tsn('type')}</td>
											<td>{document.druhDokladu}</td>
										</tr>
									) : null}
									<tr>
										<td>{tsn('number')}</td>
										<td>
											<strong>{document.cisloDokladu}</strong>
											<CopyButton
												label={
													tsn('akce.kopirovat-cislo-dokladu') +
													' ' +
													document.cisloDokladu
												}
												value={document.cisloDokladu as string}
											/>
										</td>
									</tr>
									{document.platnostOd ? (
										<tr>
											<td>{tsn('platnostOd')}</td>
											<td>
												<strong>{toDateReadable(document.platnostOd)}</strong>
											</td>
										</tr>
									) : null}
									{document.platnostDo ? (
										<tr>
											<td>{tsn('platnostDo')}</td>
											<td>
												<strong
													className={cx(type === DokladyType.RP && classname)}>
													{toDateReadable(document.platnostDo)}
												</strong>
											</td>
										</tr>
									) : null}
								</tbody>
							</DataTable>
							{canChangeLicence &&
							type === DokladyType.RP &&
							zadostNovyRidicskyPrukazUrl ? (
								<GovMessage variant={'warning'}>
									<GovIcon
										type="basic"
										name="exclamation-triangle-fill"
										slot="icon"
									/>
									<PoMarkdownRenderWeb>
										{tsn('ridicsky-prukaz.akce.prodlouzeni', {
											url: zadostNovyRidicskyPrukazUrl,
										})}
									</PoMarkdownRenderWeb>
								</GovMessage>
							) : null}
						</LayoutGap>
						{type !== DokladyType.RP && (
							<>
								<Button
									onClick={() =>
										setControls({ displayComplaintModal: document })
									}
									className={'mt-6'}
									variant={'primary'}
									type={'outlined'}
									wcagLabel={tsn('akce.podnet-k-oprave-udaju')}>
									{tsn('akce.podnet-k-oprave-udaju')}
								</Button>
								<GovModal
									open={!!controls.displayComplaintModal}
									label={tsn('formular.podnet-k-oprave-udaju')}
									onGov-close={() =>
										setControls({ displayComplaintModal: undefined })
									}
									wcagCloseLabel={tsn('akce.wcag.zavrit-formular')}>
									<div>
										{controls.displayComplaintModal ? (
											<DokumentyReklamaceContextProvider
												code={code}
												document={controls.displayComplaintModal}
												onComplaintSubmit={() =>
													setControls({ displayComplaintModal: undefined })
												}>
												<DocumentComplaintView code={code} />
											</DokumentyReklamaceContextProvider>
										) : null}
									</div>
								</GovModal>
							</>
						)}
					</ContentLayout>
				);
			})}
		</LayoutGap>
	);
};
