import { useEffect, useState } from 'react';
import { get, UseFormReturn } from 'react-hook-form';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { DataBoxMessageFormInputs } from '@gov-nx/module/data-box';
import { useBoolean } from '@gov-nx/utils/common';

export const useSender = (
	dataBoxes: DatovkaApiType[],
	form: UseFormReturn<DataBoxMessageFormInputs>
) => {
	const senderId = form.getValues('senderId');
	const isDropDownOpen = useBoolean(false);

	const [databox, setSelectedDataBox] = useState<DatovkaApiType>(
		dataBoxes.find((item) => item.datovaSchrankaId === senderId) ?? dataBoxes[0]
	);

	const onChange = (senderId: string) => {
		isDropDownOpen.setFalse();
		setSelectedDataBox(
			dataBoxes.find((item) => item.datovaSchrankaId === senderId) ??
				dataBoxes[0] ??
				null
		);
	};

	useEffect(() => {
		if (databox.datovaSchrankaId) {
			form.setValue('senderId', databox.datovaSchrankaId);
		}
	}, [databox.datovaSchrankaId]);

	const availableDataBoxes = dataBoxes.filter(
		(item) => item.stav === 'EXISTUJE' && !item.odpojeno
	);

	return {
		databox,
		isDropDownOpen,
		onChange,
		error: get(form.formState.errors, 'senderId'),
		availableDataBoxes,
	};
};
