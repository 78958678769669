import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const MySubmissionsSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout>
			<LayoutGap className={'flex justify-between'}>
				<GovSkeleton
					width={'40px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
					className={'ml-auto'}
				/>
				<MySubmissionsListSkeleton />
			</LayoutGap>
		</ContentLayout>
	);
};

export const MySubmissionsListSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<ContentLayout>
			<LayoutGap className={'flex justify-between !gap-8'}>
				{Array.from(Array(10))
					.fill(null)
					.map((_, index) => {
						const randomNumber = generateRandomNumber(20, 70);
						return (
							<div
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								className={
									'flex flex-col w-full pb-6 border-b border-secondary-300 [ md:flex-row md:justify-between md:items-center ]'
								}>
								<div className={'flex justify-between items-center'}>
									<div className={'w-full'}>
										<GovSkeleton
											width={'100%'}
											height={'24px'}
											variant={'secondary'}
											wcagLabel={
												t('loading.wcag.processing', {
													namespace: LocalizeNameSpaceTypes.Global,
												}) as string
											}
											className={''}
										/>
									</div>
									<GovSkeleton
										width={'20px'}
										height={'20px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
										className={'ml-3 [ md:hidden ]'}
									/>
								</div>
								<div style={{ width: randomNumber + '%' }}>
									<GovSkeleton
										width={'100%'}
										height={'24px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</div>
								<GovSkeleton
									width={'100px'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'mt-4 [ md:ml-auto md:mt-0 ]'}
								/>
								<GovSkeleton
									width={'20px'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'hidden [ md:block md:ml-3 ]'}
								/>
							</div>
						);
					})}
			</LayoutGap>
		</ContentLayout>
	);
};
