import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const CadastreOfRealEstateItemsSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<div className={'py-8 px-4'}>
			<GovSkeleton
				height={'30px'}
				variant={'secondary'}
				wcagLabel={
					t('loading.wcag.processing', {
						namespace: LocalizeNameSpaceTypes.Global,
					}) as string
				}
				width={generateRandomNumber(20, 60) + '%'}
				className={'flex-1 mb-0 pb-0'}
			/>
			<div
				className={
					'flex flex-col items-start gap-4 pt-4 [ sm:flex-row sm:items-center ]'
				}>
				<div className={'flex'}>
					<GovSkeleton
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						width={'41px'}
						className={'flex-1 mb-0 pb-0 mr-1'}
					/>
					<GovSkeleton
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						width={'80px'}
						className={'flex mb-0 pb-0'}
					/>
				</div>
				<div className={'flex'}>
					<GovSkeleton
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						width={'150px'}
						className={'flex-1 mb-0 pb-0  mr-1'}
					/>
					<GovSkeleton
						height={'20px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						width={'80px'}
						className={'flex mb-0 pb-0'}
					/>
				</div>
			</div>
		</div>
	);
};

export const CadastreOfRealEstateSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout customClasses={'!p-0'}>
			<div className={'py-4 px-8 border-b border-b-secondary-300'}>
				<GovSkeleton
					width={'320px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
			<div className={'px-8 pb-10'}>
				<CadastreOfRealEstateItemsSkeleton />
				<GovSkeleton
					width={'190px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
				/>
			</div>
		</ContentLayout>
	);
};
