import { Nullable, ObjectOrArrayType, Optional } from '@gov-nx/core/types';

type xVal = Optional<{
	xVal?: string;
}>;

export enum StatusName {
	zanik = 'ZÁNIK',
	provozovane = 'PROVOZOVANÉ',
	vyvoz = 'VÝVOZ',
	vyrazeno = 'VYŘAZENO Z PROVOZU',
}

export interface Subject {
	subjektTyp: {
		xVal?: '1' | '2';
	};
	subjektOd: xVal;
	subjektDo: xVal;
	osobaTyp: xVal;
}

export interface VehicleDocuments {
	rz: ObjectOrArrayType<{
		aktualni: xVal;
		cisloRz: xVal;
		cisloRzDo: xVal;
		cisloRzUkoncenoDuvod: Nullable<xVal>;
	}>;
	tp: ObjectOrArrayType<{
		cisloTp: xVal;
		cisloTpDo: xVal;
		cisloTpUkoncenoDuvod: xVal;
	}>;
	orv: ObjectOrArrayType<{
		cisloOrv: xVal;
		cisloOrvOd: xVal;
		cisloOrvUkoncenoDuvod: Nullable<xVal>;
	}>;
}

export interface VehicleState {
	status?: ObjectOrArrayType<{
		statusOd: xVal;
		statusDo?: xVal;
		statusNazev: {
			xVal:
				| StatusName.provozovane
				| StatusName.vyrazeno
				| StatusName.vyvoz
				| StatusName.zanik;
		};
		statusDuvod: xVal;
	}>;
	vyrazeni: xVal;
	vyvoz: xVal;
	zanik: xVal;
}

export interface Vehicle {
	doklady: VehicleDocuments;
	dokladyHistorie: VehicleDocuments;
	emise: {
		emiseHodnoty?: {
			emiseCO2: xVal;
			emiseCO2Mesto: xVal;
			emiseCO2Mimo: xVal;
			emiseKSA: xVal;
		};
		emiseNorma?: { emiseEHKOSN: xVal; emiseEHSES: xVal; emiseStupen: xVal };
	};
	hmotnosti: {
		hmotnostiZakladni?: {
			hmotnostiPov: xVal;
			hmotnostiPovolenaJS: xVal;
			hmotnostiPrip: xVal;
			hmotnostiPripustnaJS: xVal;
			hmotnostiProvozni: xVal;
		};
		vozidloHmotnostiPrives?: {
			hmotnostiPovBrzdenePV: xVal;
			hmotnostiPovNebrzdenePV: xVal;
			hmotnostiPripBrzdenePV: xVal;
			hmotnostiPripNebrzdenePV: xVal;
		};
	};
	karoserie: {
		karoserieZakladni?: {
			karoserieBarva: xVal;
			karoserieBarvaDoplnkova: xVal;
			karoserieDruh: xVal;
			karoserieVyrobce: xVal;
			karoserieVyrobniCislo: xVal;
		};
		karoserieDodatek?: {
			karoserieBarvaDoplnkova: xVal;
			karoserieDvere: xVal;
			karoserieMistCelkem: xVal;
			karoserieMistSezeni: xVal;
			karoserieMistStani: xVal;
		};
	};
	motor: {
		motorZakladni?: { motorTyp: xVal; motorVyrobce: xVal; motorCislo: xVal };
		motorDodatek?: {
			motorMaxVykon: xVal;
			motorMinOtacky: xVal;
			motorZdvihObjem: xVal;
		};
	};
	napravy: {
		napravyZakladni?: { vozidloNapravyPocet: xVal; vozidloNapravyDruh: xVal };
		napravyHmotnosti?: {
			hmotnostiPripN1: xVal;
			hmotnostiPripN2: xVal;
			hmotnostiPripN3: xVal;
			hmotnostiPripN4: xVal;
			hmotnostiPovN1: xVal;
			hmotnostiPovN2: xVal;
			hmotnostiPovN3: xVal;
			hmotnostiPovN4: xVal;
		};
		napravyRozchod?: {
			napravyRozchod1: xVal;
			napravyRozchod2: xVal;
			napravyRozchod3: xVal;
			napravyRozchod4: xVal;
		};
		napravyPneu?: {
			napravyPneu1: xVal;
			napravyPneu2: xVal;
			napravyPneu3: xVal;
			napravyPneu4: xVal;
		};
		napravyRafky?: {
			napravyRafky1: xVal;
			napravyRafky2: xVal;
			napravyRafky3: xVal;
			napravyRafky4: xVal;
		};
	};
	rozmery: {
		rozmeryZakladni?: {
			rozmeryDelka: xVal;
			rozmerySirka: xVal;
			rozmeryVyska: xVal;
		};
		rozmeryDodatek?: { rozmeryRozvor: xVal };
	};
	subjekty: {
		subjekt: ObjectOrArrayType<Subject>;
	};
	vozidloStav: VehicleState;
	vozidloUdaje: {
		registrace?: { prvniRegistrace: xVal; prvniRegistraceCr: xVal };
		udajeZakladni?: {
			vozidloDruh: xVal;
			vozidloDruh2: xVal;
			vozidloID: xVal;
			vozidloKategorie: xVal;
			vozidloObchodniOznaceni: xVal;
			vozidloTovarniZnacka: xVal;
			vozidloTypoveSchvaleni: Nullable<xVal>;
			vozidloVarianta: xVal;
			vozidloVerze: Nullable<xVal>;
			vozidloVin: xVal;
			vozidloHluk: xVal;
			vozidloPuvod: xVal;
			vozidloSpotreba: xVal;
		};
		udajeDodatek?: {
			cisloZtp: xVal;
			cisloEs: xVal;
			vozidloTypKod: xVal;
			vozidloVyrobce: xVal;
			vozidloPalivo: xVal;
			vozidloNejvyssiRychlost: xVal;
			vozidloPomerVykonHmotnost: xVal;
		};
		vozidloSpotreba?: {
			spotrebaMetodika: xVal;
			spotrebaNa100kmMesto: xVal;
			spotrebaNa100km90: xVal;
			spotrebaNa100km120: xVal;
			spotreba?: xVal;
			spotrebaPriRychlosti?: xVal;
		};
		vozidloHluk?: {
			hlukStojici: xVal;
			hlukOtacky: xVal;
			hlukJizda: xVal;
		};
	};
	vybaveni: {
		brzdy?: {
			brzdyProvozni: xVal;
			brzdyNouzova: xVal;
			brzdyParkovaci: xVal;
			brzdyOdlehcovaci: xVal;
		};
		spojZarizeni?: { vozidloSpojZariz: xVal; vozidloZatizeniSZ: xVal };
		vybaveniZakladni?: { vozidloNapravyPocet: xVal; vozidloNapravyDruh: xVal };
	};
}

export interface KontextyResponseData {
	paisRsvCtiAifoResponse: {
		paisRsvCtiVozidloResponse: ObjectOrArrayType<Vehicle>;
		pocetNalezenychVozidel: xVal;
	};
}

export interface KontextyResponseIcoData {
	paisRsvCtiIcoResponse: {
		paisRsvCtiVozidloResponse: ObjectOrArrayType<Vehicle>;
		pocetNalezenychVozidel: xVal;
	};
}

export interface KontextyResponseDetailData {
	paisRsvCtiVozidloIdResponse: {
		paisRsvCtiVozidloResponse: Optional<Vehicle>;
	};
}

interface KontextyResponseStatus {
	xAttAis: string; //'1025',
	status: {
		xVal: 'OK';
	};
	detail: object[];
}

export interface KontextyResponse {
	r:
		| KontextyResponseData
		| KontextyResponseIcoData
		| KontextyResponseDetailData
		| KontextyResponseStatus;
}

export interface RegistrVozidelResponse {
	Data: Array<object | KontextyResponse>;
}

export interface TechnicalInspectionResponse {
	Data?: [{ odkaz?: string }];
}
