import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Button } from './Button';

export interface BackButtonProps {
	onClick: () => void;
}

export const BackButton = ({ onClick }: BackButtonProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<Button
			variant={'primary'}
			type={'link'}
			onClick={onClick}
			size={'s'}
			wcagLabel={t('navigation.back')}
			className={'button-plain'}>
			<GovIcon
				slot="left-icon"
				name="chevron-left"
				className={'h-4 !mt-[1px]'}></GovIcon>
			{t('navigation.back', { namespace: LocalizeNameSpaceTypes.Global })}
		</Button>
	);
};
