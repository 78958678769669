import { AxiosResponse } from 'axios';
import React, { createContext, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import {
	getRequiredDataBoxes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { ServiceContextProviderProps } from '../vypis-z-zivnostenskeho-rejstriku/Context';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { FormData, ServiceContextTypes } from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const formDefinition = FormInstance({ code });
	const { toastMessage } = useMessageEvents();
	const { controls, setControls } = useProcessControl();

	const submitMutation = usePoMutation<AxiosResponse<void>, FormData>({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onSuccess: () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: tsn('form.messages.odeslano'),
			});
			formDefinition.formReset();
		},
		onError: () => {
			toastMessage({
				content: tsn('form.messages.chyba-pri-odesilani'),
				options: {
					variant: 'error',
					time: 0,
					icon: {
						name: 'exclamation-triangle-fill',
						type: 'basic',
					},
				},
			});
		},
	});

	const requiredDataBoxes = getRequiredDataBoxes(code);

	const onSubmit = formDefinition.formMethods.handleSubmit(() =>
		submitMutation.mutate(formDefinition.formMethods.getValues())
	);

	return (
		<ServiceContext.Provider
			value={{
				code,
				controls,
				setControls,
				formDefinition,
				requiredDataBoxes,
				onSubmit,
				submitMutation,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisVyuzitiUdajuZROSContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
