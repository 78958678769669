import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';

export const DataBoxRequiredBanner = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(PageCode['datove-schranky']);
	return (
		<div
			role="status"
			className={
				'bg-gradient-to-t to-primary-700 from-primary-600 [ md:rounded ]'
			}>
			<div
				className={
					'p-5 text-neutral-white flex flex-col gap-6 items-start [ md:p-8 md:flex-row ]'
				}>
				<GovIcon
					className={'w-12 h-12 text-warning-400'}
					type={'basic'}
					name={'datovka'}></GovIcon>
				<div className={'flex flex-col gap-3 flex-1'}>
					<h3 className={'text-l font-bold'}>
						{t('banner-service.zakaz.nadpis')}
					</h3>
					<p>{t('banner-service.zakaz.popis')}</p>
				</div>
			</div>
		</div>
	);
};
