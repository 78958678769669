import { AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';
import { VypisyudajuSouboryUlozkaDto } from '../generated';
import { RequestData } from '../types/queryTypes';

export const extractOfDataQuery = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<void, void>> => {
	return axiosInstance.post('/api/v1/vypisyudaju/odeslatZadost', body, {
		params,
	});
};

export const extractOfDataGetFileIdQuery = async <Body, Params>({
	body,
	params,
}: RequestData<Params, Body>): Promise<VypisyudajuSouboryUlozkaDto> => {
	const { data } = await axiosInstance.post('/api/v1/vypisyudaju', body, {
		params,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return data;
};
