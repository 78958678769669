import React from 'react';
import { DatoveSchrankyPrilohaDto } from '@gov-nx/api/portal-obcana';
import { useDownloadEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DocumentDownloadContainer, Attachment } from '@gov-nx/ui/web';

interface MySubmissionAttachmentsProps {
	code: PageCode;
	messageId: number;
	dataBoxId: string;
	attachments: DatoveSchrankyPrilohaDto[];
}

export const MySubmissionAttachments = ({
	code,
	messageId,
	dataBoxId,
	attachments,
}: MySubmissionAttachmentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	const { downloadDataBoxAttachment } = useDownloadEvents();

	return (
		<DocumentDownloadContainer label={ts('prilohy.akce.stahnout')}>
			{attachments.map((attachment) => {
				return (
					<Attachment
						key={attachment.prilohaId}
						onDownload={(onSuccess) => {
							downloadDataBoxAttachment({
								attachmentData: {
									attachment,
									dataBoxId,
									messageId,
									folderType: DataBoxFolderType.Archived,
								},
								callback: onSuccess,
							});
						}}
						downloadLabel={ts('prilohy.akce.stahnout')}
						size={attachment.velikost}
						name={attachment.nazev}
					/>
				);
			})}
		</DocumentDownloadContainer>
	);
};
