import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { DataTable } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const DataboxNotificationSettingsSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<DataTable customClasses={'gov-form-group-center noLabel mt-4'}>
			<thead>
				<tr>
					<th className={'w-4/6 [ md:w-3/6 ]'}>&nbsp;</th>
					<th className={'md:w-1/6'}>
						<GovSkeleton
							height={'19px'}
							width={'60px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</th>
					<th className={'md:w-1/6'}>
						<GovSkeleton
							height={'19px'}
							width={'60px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</th>
					<th className={'hidden md:table-cell'}>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className={'!pb-0'}>
						<GovSkeleton
							height={'19px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</td>
					<td className={'text-center'}>
						<GovSkeleton
							height={'24px'}
							width={'24px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</td>
					<td className={'text-center'}>
						<GovSkeleton
							height={'24px'}
							width={'24px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</td>
					<td className={'hidden md:table-cell'}>&nbsp;</td>
				</tr>
				<tr>
					<td className={'!pb-0'}>
						<GovSkeleton
							height={'19px'}
							width={generateRandomNumber(38, 70) + '%'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</td>
					<td className={'text-center'}>
						<GovSkeleton
							height={'24px'}
							width={'24px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</td>
					<td className={'hidden md:table-cell'}>&nbsp;</td>
					<td className={'hidden md:table-cell'}>&nbsp;</td>
				</tr>
			</tbody>
		</DataTable>
	);
};
