import React, { ReactNode } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfileEDocumentsContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	DataTable,
	LayoutGap,
	PopConfirm,
} from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';
import { ProfileEDocumentsSkeleton } from './ProfileEDocumentsSkeleton';

export interface ProfileEDocumentsViewProps {
	navigation: ReactNode;
	code: PageCode;
}

export const ProfileEDocumentsView = ({
	navigation,
	code,
}: ProfileEDocumentsViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { documents, initialLoading, onDeleteEDocument, isDeleting } =
		useProfileEDocumentsContextInstance();

	if (initialLoading) {
		return <ProfileEDocumentsSkeleton />;
	}

	return (
		<ContentLayout customClasses={'!pt-4'}>
			{navigation}
			{Array.isArray(documents) && documents.length ? (
				<LayoutGap gap={0}>
					{documents.map((document) => {
						return (
							<div
								key={document.id}
								className={'py-4 [ md:p-4 ]'}>
								<div className={'flex items-center justify-between'}>
									<h2 className={'text-xl'}>{document.nazev}</h2>

									<PopConfirm
										onConfirm={(closePrompt) =>
											document.kod &&
											onDeleteEDocument(document.kod, closePrompt)
										}
										strings={{
											title: ts('odpojit.titulek'),
											body: ts(
												'odpojit.text',
												document.nazev ? { nazev: document.nazev } : {}
											),
											buttons: {
												cancel: {
													label: ts('odpojit.zrusit'),
													wcagLabel: ts('odpojit.zrusit'),
												},
												confirm: {
													label: ts('odpojit.potvrdit'),
													wcagLabel: ts('odpojit.potvrdit'),
												},
											},
										}}
										isDisabled={isDeleting}
										isLoading={isDeleting}>
										{({ open }) => {
											return (
												<Button
													onClick={open}
													variant={'primary'}
													type={'base'}
													wcagLabel={ts('akce.odpojit')}>
													{ts('akce.odpojit')}
												</Button>
											);
										}}
									</PopConfirm>
								</div>

								<DataTable responsive>
									<thead>
										<tr>
											<th className={'text-left font-normal text-primary-600'}>
												{ts('tabulka.typ')}
											</th>
											<th className={'text-left font-normal text-primary-600'}>
												{ts('tabulka.aktualizace')}
											</th>
										</tr>
									</thead>
									<tbody>
										{document.edoklady?.map((edoklad) => {
											return (
												<tr key={edoklad.id}>
													<td data-label={ts('tabulka.typ')}>
														<strong>
															{ts(`tabulka.klic.${edoklad.typKod}`)}
														</strong>
													</td>
													<td data-label={ts('tabulka.aktualizace')}>
														{edoklad.datumAktualizace &&
															toDateTimeReadable(edoklad.datumAktualizace)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</DataTable>
							</div>
						);
					})}
				</LayoutGap>
			) : (
				<LayoutGap
					gap={12}
					className={'max-w-xl mx-auto pt-12 pb-8 text-center'}>
					<svg
						width="86"
						height="86"
						xmlns="http://www.w3.org/2000/svg"
						className={'mx-auto'}>
						<image
							href={'/assets/logos/edoklady.svg'}
							height="86"
							width="86"
						/>
					</svg>
					<LayoutGap gap={6}>
						<h2 className={'text-2xl'}>{ts('banner.nova-era-prokazovani')}</h2>
						<p>{ts('banner.popis')}</p>
						<Button
							variant="primary"
							type="solid"
							enforceLink={true}
							target={'_blank'}
							href={ts('banner.zjistit-vice-url')}>
							{ts('banner.zjistit-vice')}
						</Button>
					</LayoutGap>
				</LayoutGap>
			)}
		</ContentLayout>
	);
};
