import React from 'react';
import { ObjectOrArray, pipe } from '@gov-nx/core/types';
import { Vehicle, getVehicleGetters } from '@gov-nx/module/page';
import { toDateReadable } from '@gov-nx/utils/common';
import { Table } from './Table';

interface TableVehicleProps {
	vozidloUdaje: Vehicle['vozidloUdaje'];
	getter: ReturnType<typeof getVehicleGetters>;
	tsn: (code: string) => string;
}

export const TableVehicle = ({
	tsn,
	vozidloUdaje,
	getter,
}: TableVehicleProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.vozidlo.registrace-prvni-registrace'),
					vozidloUdaje.registrace?.prvniRegistrace?.xVal &&
						toDateReadable(vozidloUdaje.registrace.prvniRegistrace.xVal),
				],
				[
					tsn('tabulka.vozidlo.registrace-prvni-registrace-cr'),
					vozidloUdaje.registrace?.prvniRegistraceCr?.xVal &&
						toDateReadable(vozidloUdaje.registrace?.prvniRegistraceCr.xVal),
				],
				[
					tsn('tabulka.vozidlo.cislo-rz'),
					pipe(
						getter.documents('rz'),
						ObjectOrArray.first,
						(rz) => rz?.cisloRz?.xVal
					),
					{ copyValueLabel: tsn('tabulka.akce.zkopirovat-cislo-rz') },
				],
				[tsn('tabulka.vozidlo.ztp'), vozidloUdaje.udajeDodatek?.cisloZtp?.xVal],
				[tsn('tabulka.vozidlo.es'), vozidloUdaje.udajeDodatek?.cisloEs?.xVal],
				[
					tsn('tabulka.vozidlo.druh-vozidla'),
					vozidloUdaje.udajeZakladni?.vozidloDruh?.xVal,
				],
				[
					tsn('tabulka.vozidlo.druh-vozidla-2'),
					vozidloUdaje.udajeZakladni?.vozidloDruh2?.xVal,
				],
				[
					tsn('tabulka.vozidlo.druh-kategorie'),
					vozidloUdaje.udajeZakladni?.vozidloKategorie?.xVal,
				],
				[
					tsn('tabulka.vozidlo.tovarni-znacka'),
					vozidloUdaje.udajeZakladni?.vozidloTovarniZnacka?.xVal,
				],
				[
					tsn('tabulka.vozidlo.typ'),
					vozidloUdaje.udajeDodatek?.vozidloTypKod?.xVal,
				],
				[
					tsn('tabulka.vozidlo.varianta'),
					vozidloUdaje.udajeZakladni?.vozidloVarianta?.xVal,
				],
				[
					tsn('tabulka.vozidlo.verze'),
					vozidloUdaje.udajeZakladni?.vozidloVerze?.xVal,
				],
				[
					tsn('tabulka.vozidlo.vin'),
					vozidloUdaje.udajeZakladni?.vozidloVin?.xVal,
					{ copyValueLabel: tsn('tabulka.akce.zkopirovat-vin') },
				],
				[
					tsn('tabulka.vozidlo.obchodni-oznaceni'),
					vozidloUdaje.udajeZakladni?.vozidloObchodniOznaceni?.xVal,
				],
				[
					tsn('tabulka.vozidlo.vyrobce-vozidla'),
					vozidloUdaje.udajeDodatek?.vozidloVyrobce?.xVal,
				],
			]}
		/>
	);
};
