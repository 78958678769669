import { AppLoaderControl } from '@gov-nx/core/types';

const appLoaderElement = document.getElementById('app-central-loader');
const appLoaderMessageElement = appLoaderElement
	? appLoaderElement.querySelector('span')
	: null;

export const appLoaderControl: AppLoaderControl = {
	show: (message?: string) => {
		if (appLoaderElement) {
			appLoaderElement.hidden = false;
			appLoaderElement.setAttribute('aria-hidden', 'false');
			appLoaderElement.style.display = 'block';
			if (message && appLoaderMessageElement) {
				appLoaderMessageElement.innerHTML = message;
			}
		}
	},
	hide: () => {
		if (appLoaderElement) {
			appLoaderElement.hidden = true;
			appLoaderElement.setAttribute('aria-hidden', 'true');
			appLoaderElement.style.display = 'none';
			if (appLoaderMessageElement) {
				appLoaderMessageElement.innerHTML = '';
			}
		}
	},
};
