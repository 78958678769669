import { useQueryClient } from '@tanstack/react-query';
import {
	isResponseStatus,
	lastDocumentsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { first, is } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { useDocumentsDownload } from './useDocumentDownload';

export const useLastDocument = (
	code: ServiceCode,
	enabled: boolean,
	payload:
		| {
				typOsoby: 'PO';
				poZahranicni: boolean;
		  }
		| { typOsoby: 'FO' }
) => {
	const { prepare } = useDocumentsDownload();
	const queryClient = useQueryClient();

	const query = usePoQuery(
		{
			queryKey: [code, payload],
			queryFn: async () => {
				return lastDocumentsQuery({
					...payload,
					kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
				});
			},
			enabled,
			retry: false,
			refetchOnWindowFocus: false,
		},
		{ errorIgnoreFilter: isResponseStatus(404) }
	);

	const lastDocumentId = first(
		query.data?.data.dokumenty
			?.map((document) => document.dokumentId)
			.filter(is) ?? []
	);

	return {
		lastDocument: lastDocumentId ? prepare(lastDocumentId) : undefined,
		data: query.data?.data,
		invalidateCache: async () => {
			await queryClient.invalidateQueries({ queryKey: [code, payload] });
		},
	};
};
