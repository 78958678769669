import { PageCode } from '@gov-nx/module/page';
import { useCmsStore } from '@gov-nx/store/portal-obcana';

export interface CmsPageProps {
	code: PageCode;
}

export const useCmsPage = (props: CmsPageProps) => {
	const { getCmsPage } = useCmsStore();
	return getCmsPage(props.code);
};
