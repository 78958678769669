import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { ReactNode } from 'react';
import { WebLabelProps } from './FormLabel';
import { WebMessagesProps } from './FormMessage';

export enum RadioBooleanOption {
	no = 'no',
	yes = 'yes',
}

export interface WebFormRadioProps extends JSX.GovFormRadio {
	label: WebLabelProps;
}

export interface WebRadioOption extends Omit<WebFormRadioProps, 'name'> {
	value: string;
	children?: ReactNode;
}

export interface WebRadioField {
	name: string;
	options: WebRadioOption[];
	disabled?: boolean;
}

export interface WebRadioProps {
	field: WebRadioField;
	label: WebLabelProps;
	control?: JSX.GovFormControl;
	group?: JSX.GovFormGroup;
	messages?: WebMessagesProps;
}
