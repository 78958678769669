import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useDataBoxMessagePoLoader } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { toDateReadable, useBoolean } from '@gov-nx/utils/common';
import { MySubmissionMessage } from '../context.types';
import { MySubmissionDatovaZpravaContext } from './context.types';

const MojePodaniDatovaZpravaContext =
	createContext<Nullable<MySubmissionDatovaZpravaContext>>(null);

interface MojePodaniDatovaZpravaContextProviderProps {
	children: ReactNode;
	message: MySubmissionMessage;
	index: number;
	openFirstOnInit?: boolean;
}

export function MojePodaniDatovaZpravaContextProvider({
	children,
	message,
	index,
	openFirstOnInit = false,
}: MojePodaniDatovaZpravaContextProviderProps) {
	const [messageId, setMessageId] = useState<Nullable<number>>(null);
	const display = useBoolean(false);

	const { query } = useDataBoxMessagePoLoader({
		messageId,
		onError: display.setFalse,
	});
	const dataMessage = query.data;

	const dataMessageDataboxId = message.dataMessage.datovaSchrankaId;

	const datumACasDodani = message.message?.datumACasDodani
		? toDateReadable(message.message.datumACasDodani)
		: null;

	const toggleAccordeon = () => {
		if (message.message.datovaZpravaId) {
			setMessageId(message.message.datovaZpravaId);
		}
		display.toggle();
	};

	useEffect(() => {
		if (index === 0 && openFirstOnInit) toggleAccordeon();
	}, [index, openFirstOnInit]);

	return (
		<MojePodaniDatovaZpravaContext.Provider
			value={{
				dataMessageDataboxId,
				query,
				dataMessage,
				display,
				message,
				datumACasDodani,
				toggleAccordeon,
			}}>
			{children}
		</MojePodaniDatovaZpravaContext.Provider>
	);
}

export const useMojePodaniDatovaZpravaInstance =
	(): MySubmissionDatovaZpravaContext =>
		useContext(
			MojePodaniDatovaZpravaContext
		) as MySubmissionDatovaZpravaContext;
