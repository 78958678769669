import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { Nullable } from '@gov-nx/core/types';

export type IconType = 'complex' | 'basic' | 'colored' | string;

export const parseIcon = (icon: string): { type: IconType; name: string } => {
	const [type, name] = icon.split('/');
	return {
		name,
		type: type as IconType,
	};
};

export const renderIcon = (
	icon: string | JSX.GovIcon
): Nullable<{ type: IconType; name: string }> => {
	if (typeof icon === 'string' && icon.length) {
		const parsedIcon = parseIcon(icon);
		return {
			name: parsedIcon.name,
			type: parsedIcon.type,
		};
	} else if (typeof icon === 'object' && icon.name !== undefined && icon.type) {
		return {
			name: icon.name,
			type: icon.type,
		};
	}
	return null;
};
