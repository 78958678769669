import React from 'react';
import { List } from '../List/List';
import { Space } from '../Space/Space';

interface KeyValueProps {
	label: string;
	value?: string;
}

export const KeyValue = ({ label, value }: KeyValueProps) => {
	return (
		<React.Fragment key={`key-value-${label}-${value}`}>
			{label}
			<Space />
			<strong>{value}</strong>
		</React.Fragment>
	);
};
export const KeyValues = ({ options }: { options: KeyValueProps[] }) => {
	return <List data={options.map(KeyValue)} />;
};
