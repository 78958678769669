import { GovFormLabel } from '@gov-design-system-ce/react';
import { WebLabelProps } from '@gov-nx/ui/types';

export const FormLabel = (props: WebLabelProps) => {
	return (
		<GovFormLabel {...props}>
			{props.children && typeof props.children === 'string' ? (
				<span dangerouslySetInnerHTML={{ __html: props.children }}></span>
			) : null}
			{props.children && typeof props.children === 'object'
				? props.children
				: null}
		</GovFormLabel>
	);
};
