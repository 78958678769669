import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { PropsWithChildren } from 'react';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Checkbox } from '@gov-nx/ui/web';

const TriangleWarning = () => {
	const { t } = useLocale(PageCode['datove-schranky']);

	return (
		<GovTooltip
			slot="left-icon"
			icon>
			<GovIcon
				name="exclamation-triangle-fill"
				className={'text-warning-400'}
			/>
			<GovTooltipContent>{t('zprava.varovani.nutna-akce')}</GovTooltipContent>
		</GovTooltip>
	);
};

const Wrapper = ({ children }: PropsWithChildren) => (
	<div className={'ds-checkbox-item w-6 mr-2 grow-0 shrink-0'}>{children}</div>
);

interface LeftActionProps {
	name: string;
	isUnread: boolean;
	isLongUnread: boolean;
	showCheckboxes: boolean;

	isChecked: boolean;
	onCheck: () => void;
}

export const LeftAction = ({
	name,
	onCheck,
	isChecked,
	isLongUnread,
	isUnread,
	showCheckboxes,
}: LeftActionProps) => {
	if (isLongUnread) {
		return (
			<Wrapper>
				<TriangleWarning />
			</Wrapper>
		);
	}
	if (showCheckboxes) {
		if (isUnread) {
			return (
				<Wrapper>
					<TriangleWarning />
				</Wrapper>
			);
		}

		return (
			<Wrapper>
				<Checkbox
					name={name}
					checked={isChecked}
					onChange={onCheck}
					noLabel={true}
					size="s"
				/>
			</Wrapper>
		);
	}
};
