import { LocalizationLanguage } from '@gov-nx/core/app';
import { ServiceCode } from '@gov-nx/module/service';
import { axiosCmsInstance } from '../axios/instance';
import { CmsServiceResponse } from './types';

export interface CmsServiceQueryParams {
	code: ServiceCode;
	locale: LocalizationLanguage;
	cmsKeyCache: string;
}

export const cmsServiceQuery = async ({
	code,
	locale,
	cmsKeyCache,
}: CmsServiceQueryParams) => {
	const response = await axiosCmsInstance.get<CmsServiceResponse>(
		`/services/${code}-${locale}.json?v=${cmsKeyCache}`
	);
	return response.data.data;
};
