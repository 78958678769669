import {
	fetchSettingsHistoryQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { compareProperty, first, has, propEq } from '@gov-nx/core/types';
import { toDate } from '@gov-nx/utils/common';

export const useContactHistory = () => {
	const query = usePoQuery({
		queryKey: ['komunikacniudaje', 'historie'],
		queryFn: fetchSettingsHistoryQuery,
	});

	const data = query.data?.seznam
		?.filter(propEq('zdroj', 'ROB'))
		.filter(has('hodnota'))
		.filter(has('platnostOd'))
		.map((record) => {
			return {
				value: record.hodnota,
				type: record.typ,
				validFrom: toDate(record.platnostOd),
				validTo: record.platnostDo ? toDate(record.platnostDo) : undefined,
			};
		});

	const latest = (type: 'EMAIL' | 'TELEFON') =>
		first(
			data
				?.filter(propEq('type', type))
				.sort(compareProperty('validFrom', 'DESC')) ?? []
		)?.value;

	return {
		isLoading: query.isLoading,
		latestEmail: latest('EMAIL'),
		latestPhone: latest('TELEFON'),
	};
};
