import { Nullable } from '@gov-nx/core/types';

export const useScroll = () => {
	const headerHeight =
		document.getElementById('main-header')?.getBoundingClientRect().height ?? 0;

	const scrollToTop = () => window.scrollTo(0, 0);

	const scrollIntoView = (
		options: ScrollIntoViewOptions = { block: 'start', behavior: 'smooth' }
	) => {
		document.getElementById('root')?.scrollIntoView(options);
	};

	const scrollToElement = <T extends Element>(
		element: Nullable<T>,
		offset = headerHeight + 15
	) => {
		const position = window.scrollY;
		const top = element?.getBoundingClientRect().top ?? 0;
		const scrollTo = position + top - offset;

		window.scrollTo({ top: scrollTo, behavior: 'smooth' });
	};

	return {
		scrollToElement,
		scrollToTop,
		scrollIntoView,
	};
};
