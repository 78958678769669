import { AgendyAisAisDto } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { DataTable, InfoBanner } from '@gov-nx/ui/web';
import { constructValidUrl, toDateReadable } from '@gov-nx/utils/common';

interface AisViewProps {
	code: PageCode;
	aisData: AgendyAisAisDto;
}

export const AisView = ({ code, aisData }: AisViewProps) => {
	const { t: ts } = useLocale(code);

	if (!aisData || Object.keys(aisData).length <= 1)
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary"
				className={'!px-0 !py-4'}>
				<p className={'text-2xl [ md:text-3xl ]'}>
					{ts('stav.chyba.zatim-tu-nic-neni')}
				</p>
				<p>{ts('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
			</InfoBanner>
		);

	const url = aisData?.spravce?.web
		? constructValidUrl(aisData.spravce.web)
		: null;

	return (
		<div className={'px-2 pt-1 pb-4'}>
			<DataTable responsive>
				<tbody>
					{aisData?.datumVytvoreni && (
						<tr>
							<td>
								<strong>{ts('data.datum-vytvoreni')}</strong>
							</td>
							<td>{toDateReadable(aisData.datumVytvoreni)}</td>
						</tr>
					)}
					{aisData?.kategorie?.nazev && (
						<tr>
							<td>
								<strong>{ts('data.kategorie')}</strong>
							</td>
							<td>{aisData.kategorie.nazev}</td>
						</tr>
					)}
					{aisData?.verejny?.nazev && (
						<tr>
							<td>
								<strong>{ts('data.verejny')}</strong>
							</td>
							<td>{aisData.verejny.nazev}</td>
						</tr>
					)}
					{aisData?.charakteristika && (
						<tr>
							<td>
								<strong>{ts('data.charakteristika')}</strong>
							</td>
							<td>{aisData.charakteristika}</td>
						</tr>
					)}
					{aisData?.spravce?.nazev && (
						<tr>
							<td>
								<strong>{ts('data.spravce')}</strong>
							</td>
							<td>{aisData.spravce.nazev}</td>
						</tr>
					)}
					{aisData?.spravce?.ic && (
						<tr>
							<td>
								<strong>{ts('data.ico')}</strong>
							</td>
							<td>{aisData.spravce.ic}</td>
						</tr>
					)}
					{url && (
						<tr>
							<td>
								<strong>{ts('data.web')}</strong>
							</td>
							<td>
								<a
									href={url}
									target="_blank"
									rel="noreferrer noopener">
									{url}
								</a>
							</td>
						</tr>
					)}
					{aisData?.spravce?.email && (
						<tr>
							<td>
								<strong>{ts('data.email')}</strong>
							</td>
							<td>
								<a href={`mailto:${aisData.spravce.email}`}>
									{aisData.spravce.email}
								</a>
							</td>
						</tr>
					)}
				</tbody>
			</DataTable>
		</div>
	);
};

AisView.displayName = 'AisView';
