import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ContactDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const wcagLabel = t('loading.wcag.processing', {
		namespace: LocalizeNameSpaceTypes.Global,
	});

	return (
		<div className={'flex flex-col py-8 gap-8'}>
			<div className={'flex flex-row justify-between'}>
				<div>
					<GovSkeleton
						width={generateRandomNumber(200, 260) + 'px'}
						height={32 + 'px'}
						wcagLabel={wcagLabel}
					/>
				</div>
				<GovSkeleton
					width={50 + 'px'}
					height={24 + 'px'}
					wcagLabel={wcagLabel}
				/>
			</div>
			<GovSkeleton
				width={generateRandomNumber(240, 300) + 'px'}
				height={24 + 'px'}
				wcagLabel={wcagLabel}
			/>
		</div>
	);
};

export const ContactListSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const wcagLabel = t('loading.wcag.processing', {
		namespace: LocalizeNameSpaceTypes.Global,
	});

	return (
		<div className={'flex flex-col py-8 gap-8'}>
			{Array.from({ length: 20 }).map((_, index) => (
				<div key={index}>
					<div className={'flex flex-row justify-between'}>
						<div>
							<GovSkeleton
								width={generateRandomNumber(200, 260) + 'px'}
								height={32 + 'px'}
								wcagLabel={wcagLabel}
							/>
						</div>
						<GovSkeleton
							width={50 + 'px'}
							height={24 + 'px'}
							wcagLabel={wcagLabel}
						/>
					</div>
					<GovSkeleton
						width={generateRandomNumber(240, 300) + 'px'}
						height={24 + 'px'}
						wcagLabel={wcagLabel}
					/>
				</div>
			))}
		</div>
	);
};
