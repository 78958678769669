import {
	saveDataBoxAllAttachmentsToDocumentsQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { isFulfilled, isRejected } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';

export interface SaveAttachmentToDocumentsProps {
	onError: () => void;
	onSuccess: () => void;
}

export const useSaveAllAttachmentsToDocuments = ({
	onSuccess,
	onError,
}: SaveAttachmentToDocumentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { toastMessage } = useMessageEvents();

	const saveAllAttachmentsToDocuments = usePoMutation({
		mutationFn: saveDataBoxAllAttachmentsToDocumentsQuery,
		onSuccess: (data) => {
			const successCount = data.filter(isFulfilled).length;
			const errorResponses = data.filter(isRejected);

			if (errorResponses.length) {
				onError && onError();

				let errorMessage = '';

				const errorExtDesc =
					errorResponses[0].reason?.response?.data?.chyby?.[0]?.extPopis;
				const errorDesc =
					errorResponses[0].reason?.response?.data?.chyby?.[0]?.popis;

				if (errorExtDesc) {
					errorMessage = errorExtDesc;
				} else if (errorDesc) {
					errorMessage = errorDesc;
				} else {
					errorMessage = tn('akce.prilohy-ulozeny', { count: successCount });
				}

				toastMessage({
					options: {
						variant: 'error',
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
					content: errorMessage,
				});
			} else {
				onSuccess && onSuccess();

				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'save',
							type: 'basic',
						},
					},
					content:
						successCount === 1
							? tn('akce.priloha-ulozena')
							: tn('akce.prilohy-ulozeny', { count: successCount }),
				});
			}
		},
	});
	return saveAllAttachmentsToDocuments;
};
