import {
	NotificationsOrder,
	isResponseStatus,
	notificationsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';

export const useNotificationMessageLoader = () => {
	const allNotificationsQuery = usePoQuery(
		{
			queryKey: ['notifications'],
			queryFn: () =>
				notificationsQuery({
					razeni: NotificationsOrder['-datumACasVytvoreni'],
					start: 0,
					pocet: 50,
				}),
			retry: 1,
		},
		{
			errorIgnoreFilter: isResponseStatus(500),
		}
	);

	const unreadNotifications =
		allNotificationsQuery.data?.seznam?.filter(
			(item) => !item.datumACasPrecteni
		) ?? [];

	return {
		unreadNotificationsCount: unreadNotifications.length,
		unreadNotifications,
	};
};
