import React from 'react';
import { is } from '@gov-nx/core/types';
import { Vehicle } from '@gov-nx/module/page';
import { Table } from './Table';

interface TableChassisProps {
	vehicle: Vehicle;
	tsn: (code: string) => string;
}

export const TableChassis = ({ tsn, vehicle }: TableChassisProps) => {
	return (
		<Table
			data={[
				[
					tsn('tabulka.spojovaci-zarizeni.spojovaci-zarizeni'),
					vehicle.vybaveni.spojZarizeni?.vozidloSpojZariz?.xVal,
				],
				[
					tsn('tabulka.spojovaci-zarizeni.pocet-naprav-z-toho-pohanenych'),
					vehicle.napravy.napravyZakladni?.vozidloNapravyPocet?.xVal
						? `${vehicle.napravy.napravyZakladni?.vozidloNapravyPocet?.xVal} ${
								vehicle.napravy.napravyZakladni?.vozidloNapravyDruh?.xVal ?? '-'
						  }`
						: '-',
				],
				[
					tsn(
						'tabulka.spojovaci-zarizeni.kola-a-pneumatiky-na-naprave-rozmery-montaz'
					),
					[
						[
							vehicle.napravy.napravyRafky?.napravyRafky1?.xVal,
							vehicle.napravy.napravyPneu?.napravyPneu1?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyRafky?.napravyRafky2?.xVal,
							vehicle.napravy.napravyPneu?.napravyPneu2?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyRafky?.napravyRafky3?.xVal,
							vehicle.napravy.napravyPneu?.napravyPneu3?.xVal,
						]
							.filter(is)
							.join(' / '),
						[
							vehicle.napravy.napravyRafky?.napravyRafky4?.xVal,
							vehicle.napravy.napravyPneu?.napravyPneu4?.xVal,
						]
							.filter(is)
							.join(' / '),
					]
						.filter((item) => item !== '')
						.join('; '),
				],
			]}
		/>
	);
};
