import { createContext, ReactNode, useContext } from 'react';
import {
	EPetitionListFilter,
	ePetitionQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { FilterParam, Nullable } from '@gov-nx/core/types';
import { toDateReadable, useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	EPetitionBadge,
	EPetitionListContext,
	EPetitionProcessControl,
} from './context.types';

const EPeticeSeznamContext =
	createContext<Nullable<EPetitionListContext>>(null);

interface EPeticeContextProviderProps {
	children: ReactNode;
	code: PageCode;
}

export function EPeticeSeznamContextProvider({
	children,
	code,
}: EPeticeContextProviderProps) {
	const { setControls, controls } = useProcessControl<EPetitionProcessControl>({
		displayFilter: false,
		initialLoading: false,
	});
	const { filter, setPartialFilter } = useFilter<EPetitionListFilter>({
		key: code,
		initialState: {
			text: undefined,
			mnouPodepsane: false,
			mnouZalozene: false,
			start: 0,
			pocet: 10,
			platnostOd: undefined,
			platnostDo: undefined,
		},
	});

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const filterParams: FilterParam[] = [
		{
			label: tsn('filtr.pole.platnost-od'),
			value: filter.platnostOd ? toDateReadable(filter.platnostOd) : undefined,
			isActive: !!filter.platnostOd,
			onRemove: () => {
				setPartialFilter({ platnostOd: undefined, start: 0 });
				goToPage(1);
				formDefinition.formMethods.resetField('platnostOd');
			},
		},
		{
			label: tsn('filtr.pole.platnost-do'),
			value: filter.platnostDo ? toDateReadable(filter.platnostDo) : undefined,
			isActive: !!filter.platnostDo,
			onRemove: () => {
				setPartialFilter({ platnostDo: undefined, start: 0 });
				goToPage(1);
				formDefinition.formMethods.resetField('platnostDo');
			},
		},
	];

	const badges: EPetitionBadge[] = [
		{
			name: tsn('odznak.vse'),
			isActive: filter.mnouPodepsane === false && filter.mnouZalozene === false,
			filterFunction: () =>
				setPartialFilter({
					mnouPodepsane: false,
					mnouZalozene: false,
					start: 0,
				}),
		},
		{
			name: tsn('odznak.moje-petice'),
			isActive: filter.mnouZalozene === true,
			filterFunction: () =>
				setPartialFilter({
					mnouPodepsane: false,
					mnouZalozene: true,
					start: 0,
				}),
		},
		{
			name: tsn('odznak.podepsane'),
			isActive: filter.mnouPodepsane === true,
			filterFunction: () =>
				setPartialFilter({
					mnouPodepsane: true,
					mnouZalozene: false,
					start: 0,
				}),
		},
	];

	const query = usePoQuery({
		queryKey: ['epetice-seznam', filter],
		queryFn: () => ePetitionQuery(filter),
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const resetFilter = () => {
		setPartialFilter({
			platnostDo: undefined,
			platnostOd: undefined,
			text: undefined,
		});
		setControls({ displayFilter: false });
		formDefinition.formReset();
	};

	const goToPage = async (page: number) => {
		setPartialFilter({ start: (page - 1) * filter.pocet });
	};

	const currentPage = filter.start / filter.pocet + 1;

	const formDefinition = FormInstance({ code, defaultValues: filter });
	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		setPartialFilter(
			prepareSubmitData(formDefinition.formMethods.getValues()).params
		);
		setControls({ displayFilter: false });
	});

	return (
		<EPeticeSeznamContext.Provider
			value={{
				currentPage,
				goToPage,
				numberOfPetition: query.data?.pocet ?? 0,
				badges,
				filter,
				controls,
				data: query.data?.seznam ?? [],
				formDefinition,
				onSubmit,
				query,
				setControls,
				resetFilter,
				filterParams,
			}}>
			{children}
		</EPeticeSeznamContext.Provider>
	);
}

export const useEPeticeSeznamContext = (): EPetitionListContext =>
	useContext(EPeticeSeznamContext) as EPetitionListContext;
