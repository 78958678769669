import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageForm } from './DataBoxMessageForm';

export const DataBoxMessageAnswerPage = () => {
	const { dataBoxId, folderType, messageId } = useDataBoxMessageFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	return (
		<section className="flex flex-col mt-[-61px] relative z-200 h-screen bg-neutral-white [ ds:grow ds:rounded-2xl ds:overflow-hidden ds:mt-0 ds:h-full ds:z-0 ]">
			<div className={'flex justify-between px-6 pt-4 pb-2'}>
				<h1 className={'text-xl font-bold'}>{tn('nova-zprava.odpovedet')}</h1>
				<Button
					href={routeResolver(PageCode['datove-schranky-zprava'], {
						dataBoxId,
						folderType,
						messageId,
					})}
					className="[ ds:hidden ]"
					wcagLabel={tn('nova-zprava.zahodit')}
					type={'base'}
					size={'s'}
					nativeType={'button'}
					variant={'primary'}>
					<GovIcon name="x-lg" />
				</Button>
			</div>
			<div className={'flex h-[calc(100%-54px)] pb-4 px-4 [ ds:px-6 ]'}>
				<DataBoxMessageForm />
			</div>
		</section>
	);
};
