import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	ContactDataSection,
	PhoneFormData,
	PhoneFormInstance,
	UpdateContactHook,
	useContactDetails,
	useCountryCodes,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { getDefaultPhoneValue, IUseBoolean } from '@gov-nx/utils/common';

interface PhoneContactDetailsHookProps {
	updateContact: UpdateContactHook<PhoneFormData>;
	isReloading: boolean;
	refetch: () => Promise<void>;
	countryCodes: ReturnType<typeof useCountryCodes>;
	unverifiedPhone?: string;
	phoneVerified?: string;
	isEditing: IUseBoolean;
}

export const usePhoneContactDetails = ({
	updateContact,
	refetch,
	unverifiedPhone,
	countryCodes,
	phoneVerified,
	isReloading,
	isEditing,
}: PhoneContactDetailsHookProps): ContactDataSection<PhoneFormData> => {
	const code = PageCode['nastaveni'];
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(code);
	const queryClient = useQueryClient();
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();

	const {
		isChanging,
		isDeleteConfirmationOpen,
		isNeedForVerification,
		cleanNotificationSettingsCache,
	} = useContactDetails({ unverifiedValue: unverifiedPhone });

	const formDefinition = PhoneFormInstance({
		isNeedForVerification,
	});

	useEffect(() => {
		if (!countryCodes.isFetched) return;

		if (isChanging.value) {
			formDefinition.formMethods.setValue('telefon', getDefaultPhoneValue());
		}

		if (phoneVerified) {
			const shape = countryCodes.stringToPhoneShape(phoneVerified);
			shape && formDefinition.formMethods.setValue('telefon', shape);
		}

		if (!isChanging.value && unverifiedPhone) {
			formDefinition.formReset();
			const shape = countryCodes.stringToPhoneShape(unverifiedPhone);
			shape && formDefinition.formMethods.setValue('telefon', shape);
		}
	}, [
		phoneVerified,
		unverifiedPhone,
		formDefinition.formMethods,
		countryCodes.countryCodes,
		countryCodes.isFetched,
		isChanging.value,
	]);

	const onDelete = () =>
		updateContact.delete(undefined, {
			onSuccess: async () => {
				await refetch();
				isDeleteConfirmationOpen.setFalse();
				await cleanNotificationSettingsCache();
				toastMessageSuccess(t('kontakt.akce.smazat.potvrzeni.smazano'));
			},
		});

	const onResendVerification = () =>
		updateContact.resendVerification(undefined, {
			onSuccess: () => {
				toastMessageSuccess(t(`messages.overeni-SMS-odeslano`));
			},
			onError: () => {
				toastMessageError(t('messages.overeni-SMS-neodeslano'));
			},
		});

	const onPersonalContactsUse = (phone: string) => {
		const telefon = countryCodes.stringToPhoneShape(phone);
		if (!telefon) return;

		return updateContact.update(
			{ telefon },
			{
				onSuccess: async () => {
					await refetch();
					await queryClient.invalidateQueries({
						queryKey: ['setting-notifications', 'NU_TELEFON'],
					});
				},
			}
		);
	};

	const onVerify = () =>
		updateContact.verify(formDefinition.formMethods.getValues(), {
			onSuccess: async () => {
				isEditing.setFalse();
				await refetch();
				formDefinition.formReset();
				await queryClient.invalidateQueries({
					queryKey: ['setting-notifications', 'NU_TELEFON'],
				});
				toastMessageSuccess(t(`messages.overeni.sms.uspesne`));
			},
			onError: async () => {
				toastMessageError(t(`messages.overeni.sms.spatny-kod`));
				formDefinition.resetField('code');
			},
		});

	const onUpdate = () =>
		updateContact.update(formDefinition.formMethods.getValues(), {
			onSuccess: async () => {
				isEditing.setFalse();
				isChanging.setFalse();
				await refetch();
				formDefinition.formReset();
				await queryClient.invalidateQueries({
					queryKey: ['setting-notifications', 'NU_TELEFON'],
				});
			},
			onError: async (errorEmail) => {
				toastMessageError(t(`messages.nepovedlo-se`));
			},
		});

	const onSubmit = formDefinition.formMethods.handleSubmit(
		isNeedForVerification ? onVerify : onUpdate
	);
	return {
		isReloading,
		isDeleteConfirmationOpen,
		formDefinition,
		valueVerified: phoneVerified,
		onSubmit,
		onPersonalContactsUse,
		onChange: isChanging.setTrue,
		isChanging,
		isEditing,
		isDeleting: updateContact.isDeleting,
		isUpdating: updateContact.isUpdating || updateContact.isVerifying,
		isVerificationResending: updateContact.isVerificationResending,
		isNeedForVerification,
		onDelete,
		onResendVerification: unverifiedPhone ? onResendVerification : undefined,
		isFormOpen: isEditing.isTrue || isNeedForVerification || isChanging.isTrue,
	};
};
