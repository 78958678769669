import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';

export const EPetitionFormSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<>
			<ContentLayout>
				<LayoutGap>
					<div className={'flex gap-4 items-center'}>
						<GovSkeleton
							height={'40px'}
							width={'40px'}
							className={'py-2'}
							shape={'circle'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
						<GovSkeleton
							height={'28px'}
							width={'200px'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</div>
					<LayoutGap
						gap={8}
						className={'[ md:pl-16 ]'}>
						<LayoutGap gap={4}>
							<GovSkeleton
								width={'160px'}
								height={'36px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<div>
								<GovSkeleton
									width={'240px'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'100%'}
									height={'40px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
						</LayoutGap>
						<div>
							<GovSkeleton
								width={'300px'}
								height={'20px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<GovSkeleton
								width={'100%'}
								height={'115px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<div>
							<GovSkeleton
								width={'300px'}
								height={'20px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
							<GovSkeleton
								width={'100%'}
								height={'115px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
							/>
						</div>
						<LayoutGap gap={3}>
							<GovSkeleton
								width={'80px'}
								height={'36px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={'mt-4'}
							/>
							<div>
								<GovSkeleton
									width={'90%'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'20%'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
							<div
								className={
									'flex flex-col justify-center items-center py-8 border border-secondary-300'
								}>
								<GovSkeleton
									width={'170px'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'250px'}
									height={'40px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<div className={'w-[90%] [ md:w-[370px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'20px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</div>
								<div className={'w-[90%] [ md:w-[380px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'20px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</div>
							</div>
						</LayoutGap>
						<LayoutGap gap={3}>
							<GovSkeleton
								width={'180px'}
								height={'36px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={'mt-4'}
							/>
							<div>
								<GovSkeleton
									width={'85%'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'95%'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
								<GovSkeleton
									width={'30%'}
									height={'20px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
								/>
							</div>
							<div>
								<div>
									<GovSkeleton
										width={'190px'}
										height={'20px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</div>
								<div className={'[ md:w-[320px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'40px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
									/>
								</div>
							</div>
						</LayoutGap>
						<GovSkeleton
							width={'170px'}
							height={'40px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
						/>
					</LayoutGap>
				</LayoutGap>
			</ContentLayout>

			<ContentLayout customClasses={'!pt-4 !pb-3'}>
				<div className={'flex gap-4 items-center'}>
					<GovSkeleton
						height={'40px'}
						width={'40px'}
						className={'py-2'}
						shape={'circle'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'28px'}
						width={'160px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</ContentLayout>

			<ContentLayout customClasses={'!pt-4 !pb-3'}>
				<div className={'flex gap-4 items-center'}>
					<GovSkeleton
						height={'40px'}
						width={'40px'}
						className={'py-2'}
						shape={'circle'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'28px'}
						width={'100px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</ContentLayout>

			<ContentLayout customClasses={'!pt-4 !pb-3'}>
				<div className={'flex gap-4 items-center'}>
					<GovSkeleton
						height={'40px'}
						width={'40px'}
						className={'py-2'}
						shape={'circle'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
					<GovSkeleton
						height={'28px'}
						width={'95px'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
					/>
				</div>
			</ContentLayout>
		</>
	);
};
