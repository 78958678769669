import { AxiosError } from 'axios';
import {
	CiselnikyCiselnikPolozkaDtoEmbassyOffice,
	CiselnikyVolbyDto,
	DatovkaApiType,
	OsobyFyzickaOsobaDto,
} from '@gov-nx/api/portal-obcana';
import {
	ContactDetailsFormData,
	FormWizardHook,
	PersonCommunicationData,
} from '@gov-nx/core/hooks';
import { Optional } from '@gov-nx/core/types';
import {
	DataBoxFormData,
	PhoneWithCountryCodeShape,
	RadioBooleanOption,
} from '@gov-nx/ui/types';
import { ServiceCode } from '../../definitions/codes';
import {
	IFormStep1,
	IFormStep2,
	IFormStep2a,
	IFormStep2b,
	IFormStep3,
} from './form.types';
import { InitialCheckResult } from './useInitialCheck';
import { IElectionControl } from './utils';

export interface VotersCardContext {
	code: ServiceCode;
	initialState: {
		isLoading: boolean;
		error?: AxiosError;
	};
	checkMutation: {
		isLoading: boolean;
		data?: IElectionControl;
		error?: AxiosError;
	};
	submitMutation: {
		isSubmitted: boolean;
		isSubmitting: boolean;
	};
	election?: CiselnikyVolbyDto;
	requiredDataBoxes: DatovkaApiType['typSchranky'][];
	isDataBoxConnected: boolean;
	wizard: FormWizardHook<VotersCardFormData>;
	formStep1: IFormStep1;
	formStep2: IFormStep2;
	formStep2a: IFormStep2a;
	formStep2b: IFormStep2b;
	formStep3: IFormStep3;
	individualPerson: Optional<OsobyFyzickaOsobaDto>;
	personCommunicationData: PersonCommunicationData;
	livesAbroad: FormDataStep2['livesAbroad'];
	onSpecialList: FormDataStep2['onSpecialList'];
	zpusobPrevzeti: FormDataStep3['zpusobPrevzeti'];
	zpusobPrevzetiPosta: FormDataStep3['zpusobPrevzetiPosta'];
	zpusobPrevzetiUrad: FormDataStep3['zpusobPrevzetiUrad'];
	embassy?: CiselnikyCiselnikPolozkaDtoEmbassyOffice;
	volitNaOkrskuVZahraniciId: FormDataStep3['volitNaOkrskuVZahraniciId'];
	formData: VotersCardFormData;
	canVoteFromAbroad: boolean;
	countryOptions: { value: string; label: string }[];
	detailName: string;
	embassyOffices: CiselnikyCiselnikPolozkaDtoEmbassyOffice[];
	powerOfAttorney: {
		download: () => void;
		isDownloading: boolean;
	};
	selectedRounds: string;
	initialCheck: InitialCheckResult;
}

export interface FormDataStep1 {
	electionRound?: { [key: string]: boolean };
}

export interface FormDataStep2 extends ContactDetailsFormData {
	livesAbroad: RadioBooleanOption;
	onSpecialList?: RadioBooleanOption;
	embassyId: string;
}

// there might be a interface clash with the one from @gov-nx/ui/types
export type VotersCardFormDataStep2 = FormDataStep2;

export interface FormDataStep2a {
	livesAbroad: RadioBooleanOption;
	onSpecialList?: RadioBooleanOption;
	embassyId: string;
}

export interface FormDataStep2b {
	_useEmail: boolean;
	_usePhone: boolean;
	email: string;
	phone: PhoneWithCountryCodeShape;
}

export enum ZpusobPrevzeti {
	POSTA = 'POSTA',
	URAD = 'URAD',
}

export enum ZpusobPrevzetiPosta {
	TRVALA_ADRESA = 'TRVALA_ADRESA',
	DORUCOVACI_ADRESA = 'DORUCOVACI_ADRESA',
	JINA_NEZ_TRVALA_ADRESA = 'JINA_NEZ_TRVALA_ADRESA',
}

export enum ZpusobPrevzetiUrad {
	DLE_TRVALEHO_BYDLISTE = 'DLE_TRVALEHO_BYDLISTE',
	URAD_ZAHRANICNI = 'URAD_ZAHRANICNI',
}

export interface FormDataStep3 {
	zpusobPrevzeti: ZpusobPrevzeti;
	zpusobPrevzetiPosta: ZpusobPrevzetiPosta;
	zpusobPrevzetiUrad: ZpusobPrevzetiUrad;
	ulice?: string;
	obec?: string;
	cislo?: string;
	stat?: string;
	volitNaOkrskuVZahraniciId: string;
}

export type FormDataStep4 = DataBoxFormData;

export type VotersCardFormData = FormDataStep1 &
	FormDataStep2 &
	FormDataStep3 &
	FormDataStep4;

export type VotersCardDataRequestZpusobPrevzeti =
	| {
			zpusobPrevzeti: 'POSTA_JINA_ADRESA';
			ulice: string;
			obec: string;
			psc: string;
			stat: string;
	  }
	| { zpusobPrevzeti: 'POSTA_DORUCOVACI_ADRESA' }
	| { zpusobPrevzeti: 'POSTA' }
	| { zpusobPrevzeti: 'URAD' }
	| { zpusobPrevzeti: 'URAD_ZAHRANICNI'; volitNaOkrskuVZahranici: string };

export interface VotersCardDataRequest {
	params: {
		kontrola: false;
	} & DataBoxFormData;
	body: {
		kodPodani: 'ZADOST_VOLEBNI_PRUKAZ';
		volbyId: number;
		kola?: 'I' | 'II' | 'ALL';
		email?: string;
		telefon?: string;
		zapsanNaOkrskuVZahranici?: string;
		volitNaOkrskuVZahranici?: string;
	} & VotersCardDataRequestZpusobPrevzeti;
}
