import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import { KosPolozkaKosSeznamDto, KosStrankovaniDto } from '../generated';
import { EmptyRecycleBinParams } from '../types/queryTypes';

export const recycleBinQuery = async <T extends KosStrankovaniDto>({
	...params
}: T): Promise<KosPolozkaKosSeznamDto> => {
	govApiLog(`/api/kompozitni/v1/polozkakos`);
	const { data } = await axiosInstance.get(`/api/kompozitni/v1/polozkakos`, {
		params,
	});
	return data;
};

export const emptyRecycleBinQuery = async ({
	...params
}: EmptyRecycleBinParams): Promise<AxiosResponse> => {
	govApiLog(`/api/kompozitni/v1/polozkakos`);
	return axiosInstance.delete(`/api/kompozitni/v1/polozkakos`, { params });
};
