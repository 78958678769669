import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { MyFilesOrder, useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { SortArrows } from '@gov-nx/ui/web';
import { SelectAll } from '../SelectAll';

export const TableHead = () => {
	const { code, myFilesFilters } = useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<thead>
			<tr className={'h-[56px] align-bottom'}>
				<th className={'text-left font-normal text-primary-600 [ md:pl-4 ]'}>
					{tsn('data.nazev-souboru')}
					<SortArrows
						descWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.nazev-souboru'
						)} - ${tsn('razeni.sestupne')}`}
						descOnClick={() =>
							myFilesFilters.sortFilesBy(MyFilesOrder.NAZEV_SOUBORU_DESC)
						}
						ascWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.nazev-souboru'
						)} - ${tsn('razeni.vzestupne')}`}
						ascOnClick={() =>
							myFilesFilters.sortFilesBy(MyFilesOrder.NAZEV_SOUBORU)
						}
					/>
				</th>
				<th
					className={
						'w-36 pl-1 text-left font-normal text-primary-600 whitespace-nowrap hidden [ md:table-cell ]'
					}>
					{tsn('data.pridano')}
					<SortArrows
						descWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.pridano'
						)} - ${tsn('razeni.sestupne')}`}
						descOnClick={() =>
							myFilesFilters.sortFilesBy(MyFilesOrder.DATUM_VYTVORENI_DESC)
						}
						ascWcagLabel={`${tsn('razeni.title')}: ${tsn(
							'zaznam.pridano'
						)} - ${tsn('razeni.vzestupne')}`}
						ascOnClick={() =>
							myFilesFilters.sortFilesBy(MyFilesOrder.DATUM_VYTVORENI)
						}
					/>
				</th>
				<SelectAll />
			</tr>
		</thead>
	);
};
