import { GovIcon, GovMessage, GovTooltip } from '@gov-design-system-ce/react';
import { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { UiBlockingGroupContext } from '@gov-nx/component/common';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxNotificationToggleInputName,
	DatovkaNastaveniUpozorneniContext,
	PageCode,
} from '@gov-nx/module/page';
import { DataTable, FormCheckbox } from '@gov-nx/ui/web';
import { DataboxConfirmExtendedMailNotificationModal } from './DataboxConfirmExtendedMailNotificationModal';
import { DataboxNotificationSettingsSkeleton } from './DataboxNotificationSettingsSkeleton';

export const DataboxNotificationSettings = () => {
	const context = useContext(DatovkaNastaveniUpozorneniContext);
	const uiBlocking = useContext(UiBlockingGroupContext);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['nastaveni']);
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	if (!context) {
		return null;
	}

	const {
		controls,
		storeNotificationSettings,
		handleExtendedMailNotificationChange,
		formInstance,
		databox,
		noContactDetails,
		disableEmailOptions,
		disablePhoneOptions,
		isEmailExtentedChecked,
	} = context;

	if (controls.initialLoading || !databox) {
		return <DataboxNotificationSettingsSkeleton />;
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const databoxId = databox.datovaSchrankaId!;

	return (
		<FormProvider {...formInstance}>
			{noContactDetails && (
				<GovMessage variant="warning">
					<GovIcon
						type={'basic'}
						name={'info'}
						slot={'icon'}
					/>
					{tn('upozorneni.datovka.zadne-kontaktni-udaje')}
				</GovMessage>
			)}
			<div className="mb-8">
				<DataTable customClasses={'gov-form-group-center noLabel'}>
					<thead className={'text-s [ md:text-m ]'}>
						<tr>
							<th className={'w-4/6 [ md:w-3/6 ]'}>
								<span className={'invisible'}>{tn('table.header.druh')}</span>
							</th>
							<th className={'font-normal md:w-1/6'}>
								{tn('table.header.email')}
							</th>
							<th className={'font-normal md:w-1/6'}>
								{tn('table.header.sms')}
							</th>
							<th className={'hidden md:table-cell'}>&nbsp;</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td
								className={'text-s font-medium [ md:text-m md:font-medium ] '}>
								<label
									id={`popisek-nastaveni-upozorneni-notifikace_${databoxId}`}>
									{tds('nastaveni-upozorneni.notifikace')}
								</label>

								<GovTooltip
									position="right"
									size="s"
									message={tds('nastaveni-upozorneni.notifikace-info')
										.split('\n')
										.join('<br />')}
									className={'text-left border-b-0'}>
									<GovIcon
										name={'info'}
										type={'basic'}
										className={'ml-2 align-middle w-4'}
									/>
								</GovTooltip>
							</td>
							<td className="cell-email">
								<FormCheckbox
									field={{
										onChange: storeNotificationSettings,
										name: `${databoxId}.${DataBoxNotificationToggleInputName.Email}`,
										disabled:
											controls.processLoading ||
											uiBlocking?.isBlockedEditation ||
											disableEmailOptions,
										noLabel: true,
										wcagLabelledBy: `popisek-nastaveni-upozorneni-notifikace_${databoxId}`,
									}}
									group={{ gap: 's' }}
								/>
							</td>
							<td className="cell-sms">
								<FormCheckbox
									field={{
										onChange: storeNotificationSettings,
										name: `${databoxId}.${DataBoxNotificationToggleInputName.Sms}`,
										disabled:
											controls.processLoading ||
											uiBlocking?.isBlockedEditation ||
											disablePhoneOptions,
										noLabel: true,
										wcagLabelledBy: 'popisek-nastaveni-upozorneni-notifikace',
									}}
									group={{ gap: 's' }}
								/>
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
						<tr>
							<td
								className={'text-s font-medium [ md:text-m md:font-medium ] '}>
								<label
									id={`popisek-nastaveni-upozorneni-preposlat-prilohy_${databoxId}`}>
									{tds('nastaveni-upozorneni.preposlat-prilohy')}
								</label>
								<GovTooltip
									position="right"
									size="s"
									message={tds('nastaveni-upozorneni.preposlat-prilohy-info')}
									className={'text-left border-b-0'}>
									<GovIcon
										name={'info'}
										type={'basic'}
										className={'ml-2 align-middle w-4'}
									/>
								</GovTooltip>
							</td>
							<td className="cell-email-attachment">
								<FormCheckbox
									field={{
										onChange: handleExtendedMailNotificationChange,
										name: `${databoxId}.${DataBoxNotificationToggleInputName.EmailExtended}`,
										disabled:
											controls.processLoading ||
											uiBlocking?.isBlockedEditation ||
											disableEmailOptions,
										noLabel: true,
										wcagLabelledBy: `popisek-nastaveni-upozorneni-preposlat-prilohy_${databoxId}`,
									}}
									group={{ gap: 's' }}
								/>
								<DataboxConfirmExtendedMailNotificationModal />
							</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
							<td className={'hidden md:table-cell'}>&nbsp;</td>
						</tr>
					</tbody>
				</DataTable>
				{isEmailExtentedChecked ? (
					<GovMessage variant="warning">
						<GovIcon
							slot={'icon'}
							type={'basic'}
							name={'info'}
						/>
						{tn('upozorneni.nastaveni.velkokapacitni-zpravy')}
					</GovMessage>
				) : null}
			</div>
		</FormProvider>
	);
};
