import React from 'react';
import { PageCode, useDokladyContext } from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner, LayoutGap } from '@gov-nx/ui/web';
import { IdentityDocumentList } from './IdentityDocumentList';
import { IdentityDocumentListSkeleton } from './IdentityDocumentListSkeleton';
import { OwnDocumentsList } from './vlastni-doklady/OwnDocumentsList';

interface DocumentsViewProps {
	navigation: React.ReactNode;
	code: PageCode;
}

export const DocumentsView = ({ navigation, code }: DocumentsViewProps) => {
	const { controls } = useDokladyContext();

	if (controls.initialLoading) {
		return <IdentityDocumentListSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<LayoutGap gap={4}>
			<ContentLayout customClasses={'!pt-4'}>
				{navigation}
				<LayoutGap gap={4}>
					<IdentityDocumentList code={code} />
					<OwnDocumentsList code={code} />
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
