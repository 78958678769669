import { GovIcon } from '@gov-design-system-ce/react';
import { TreeFormCheckboxes } from '@gov-nx/core/hooks';
import { Button } from '../Button/Button';
import { FormCheckbox } from '../Form/FormCheckbox';
import { FormSideOffsetLayout } from '../Layout/FormSideOffsetLayout';

interface ExpandableNodeProps {
	name: string;
	label: string;
	wcagLabel: string;
	isDisabled: boolean;
	isOpen: boolean;
	onToggle: (name: string) => void;
}

const ExpandableNode = ({
	onToggle,
	name,
	label,
	wcagLabel,
	isDisabled,
	isOpen,
}: ExpandableNodeProps) => {
	return (
		<div className={'flex flex-row gap-1 pt-2 pb-1 content-start items-center'}>
			<span>
				<FormCheckbox
					field={{
						name: name,
						disabled: isDisabled,
						label: {
							children: label,
						},
					}}
				/>
			</span>
			<Button
				variant={'primary'}
				type={'base'}
				disabled={isDisabled}
				size={'s'}
				wcagLabel={wcagLabel}
				onClick={() => onToggle(name)}
				className={'pb-1'}>
				<GovIcon
					type={'basic'}
					name={isOpen ? 'chevron-up' : 'chevron-down'}
				/>
			</Button>
		</div>
	);
};

interface Props {
	treeCheckboxes: TreeFormCheckboxes;
	isDisabled: boolean;
	wcagLabelOpened: string;
	wcagLabelClosed: string;
}

export const CheckboxTree = ({
	treeCheckboxes,
	isDisabled,
	wcagLabelOpened,
	wcagLabelClosed,
}: Props) => {
	const { operations, getTree } = treeCheckboxes;
	const { isNodeOpen, toggleNode } = operations;
	const tree = getTree();
	return (
		<ul className={'gov-list--plain'}>
			<li>
				<ExpandableNode
					onToggle={toggleNode}
					isOpen={isNodeOpen(tree.name)}
					wcagLabel={isNodeOpen(tree.name) ? wcagLabelOpened : wcagLabelClosed}
					isDisabled={isDisabled}
					name={tree.name}
					label={tree.label}
				/>

				{isNodeOpen(tree.name) ? (
					<FormSideOffsetLayout>
						<ul className={'gov-list--plain'}>
							{tree.children.map((group) => {
								return (
									<li key={group.name}>
										<ExpandableNode
											onToggle={toggleNode}
											isOpen={isNodeOpen(group.name)}
											wcagLabel={
												isNodeOpen(group.name)
													? wcagLabelOpened
													: wcagLabelClosed
											}
											isDisabled={isDisabled}
											name={group.name}
											label={group.label}
										/>

										{isNodeOpen(group.name) ? (
											<FormSideOffsetLayout>
												<ul className={'gov-list--plain pt-5 pb-4'}>
													{group.children.map((field) => {
														return (
															<li
																key={field.name}
																className={'pb-3'}>
																<FormCheckbox
																	field={{
																		name: field.name,
																		disabled: isDisabled,
																		label: {
																			children: field.label,
																		},
																	}}
																	control={{ size: 'm' }}
																/>
															</li>
														);
													})}
												</ul>
											</FormSideOffsetLayout>
										) : null}
									</li>
								);
							})}
						</ul>
					</FormSideOffsetLayout>
				) : null}
			</li>
		</ul>
	);
};
