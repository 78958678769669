import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useNastaveniUpozorneniContextInstance,
} from '@gov-nx/module/page';
import {
	ContentLayout,
	DataTable,
	FormCheckbox,
	LayoutGap,
} from '@gov-nx/ui/web';
import { SettingsNotificationFormSkeleton } from './SettingsNotificationFormSkeleton';

export interface SettingsNotificationFormProps {
	code: PageCode;
}

export const SettingsNotificationForm = ({
	code,
}: SettingsNotificationFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const {
		allKeys,
		allTypes,
		formMethods,
		handleChange,
		controls,
		noContactDetails,
	} = useNastaveniUpozorneniContextInstance();

	if (controls.initialLoading) {
		return <SettingsNotificationFormSkeleton />;
	}

	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<FormProvider {...formMethods}>
					<form autoComplete={'off'}>
						<h2 className={'text-2xl mb-2'}>{tsn('table.header.titulek')}</h2>
						<p>{tsn('table.header.subtitulek')}</p>
						{noContactDetails ? (
							<GovMessage variant="warning">
								<GovIcon
									type={'basic'}
									name={'info'}
									slot={'icon'}
								/>
								{tsn('upozorneni.zprava.zadne-kontaktni-udaje')}
							</GovMessage>
						) : null}
						<DataTable customClasses={'gov-form-group-center noLabel mt-4'}>
							<thead className={'text-s [ md:text-m ]'}>
								<tr>
									<th className={'w-4/6 [ md:w-3/6 ]'}>
										<span className={'invisible'}>
											{tsn('table.header.druh')}
										</span>
									</th>
									<th className={'font-normal md:w-1/6'}>
										{tsn('table.header.email')}
									</th>
									<th className={'font-normal md:w-1/6'}>
										{tsn('table.header.sms')}
									</th>
									<th className={'hidden md:table-cell'}>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{allKeys.map((name, row) => {
									return (
										<tr key={name}>
											<td
												className={
													'text-s font-medium [ md:text-m md:font-medium ] '
												}>
												{tsn(`table.body.${name}`)}
											</td>
											{allTypes.map(({ type, isDisabled }) => {
												return (
													<td key={`${name}.${type}`}>
														<FormCheckbox
															field={{
																onChange: () => handleChange(name, type),
																name: `${name}.${type}`,
																disabled: isDisabled || controls.processLoading,
																noLabel: true,
															}}
															group={{ gap: 's' }}
														/>
													</td>
												);
											})}
											<td className={'hidden md:table-cell'}>&nbsp;</td>
										</tr>
									);
								})}
							</tbody>
						</DataTable>
					</form>
				</FormProvider>
			</ContentLayout>
		</LayoutGap>
	);
};
