import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { ReactNode } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { formatBytes } from '@gov-nx/utils/common';

type Props = {
	children: ReactNode;
	isFile?: boolean;
	fileSize?: number;
	name?: string;
	extension?: string;
	onRemoveClick?: () => void;
};

export const FormListItem = ({
	onRemoveClick,
	isFile = false,
	fileSize = 0,
	extension = undefined,
	name,
	children,
}: Props) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const button = (
		<Button
			variant="primary"
			type="base"
			size="xs"
			onClick={onRemoveClick}
			className={'!w-[38px] ml-auto'}
			wcagLabel={tn('nova-zprava.wcag.odebrat')}>
			<GovIcon
				slot="left-icon"
				name="x-lg"
			/>
		</Button>
	);

	return (
		<li className={'w-full [ sm:w-auto ]'}>
			{isFile ? (
				<div
					className={
						'inline-flex items-center gap-2 w-full py-[6px] px-3 bg-neutral-white border border-secondary-500 rounded [ sm:w-auto ]'
					}>
					<GovIcon
						name="file-earmark-text"
						className={'!w-6 !h-6 grow-0 shrink-0 !text-secondary-700'}
					/>
					<div className={'flex flex-col text-left w-full'}>
						<GovTooltip
							variant={'primary'}
							position={'top'}
							size={'s'}
							icon
							className={
								'inline-block overflow-hidden w-[196px] whitespace-nowrap text-ellipsis text-secondary-800 [ sm:w-[163px] ]'
							}>
							<span className={'text-s font-bold'}>{name}</span>
							<GovTooltipContent>{children}</GovTooltipContent>
						</GovTooltip>
						<span className={'text-s text-secondary-600'}>
							{formatBytes(fileSize)}
							{extension ? (
								<span className={'uppercase'}>
									, {extension?.replace('.', ``)}
								</span>
							) : null}
						</span>
					</div>
					{button}
				</div>
			) : (
				<div
					className={
						'flex justify-between items-center gap-1 w-auto pl-2 rounded bg-neutral-white'
					}>
					<span className={'text-s font-medium'}>{children}</span>
					{button}
				</div>
			)}
		</li>
	);
};
