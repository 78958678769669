import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuDetailContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout } from '@gov-nx/ui/web';

interface ConsentManagementInfoProps {
	code: PageCode;
}

export const ConsentManagementInfo = ({ code }: ConsentManagementInfoProps) => {
	const { controls, filteredDetail, setControls, handleDownload } =
		useSpravaSouhlasuDetailContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<div
			className={
				'flex flex-col [ md:flex-row md:justify-between md:items-center ]'
			}>
			<strong className="pb-7 text-xl [ md:pb-0 ]">
				{tsn('pole.udaje.id-souhlasu')}: {filteredDetail?.id}
			</strong>
			<ButtonGroupLayout>
				<Button
					onClick={() => handleDownload()}
					variant="primary"
					type="outlined"
					nativeType="button"
					loading={controls.processLoading}
					disabled={controls.downloadLoading}
					size="l"
					inverse={false}
					wcagLabel={tsn('tlacitko.akce.stahnout')}>
					<GovIcon
						name="download"
						slot="left-icon"
					/>
					{tsn('tlacitko.akce.stahnout')}
				</Button>
				<Button
					onClick={() => setControls({ displayModal: true })}
					variant="error"
					type="outlined"
					nativeType="button"
					size="l"
					inverse={false}
					wcagLabel={tsn('tlacitko.akce.odvolat-souhlas')}>
					<GovIcon
						name="x-lg"
						slot="left-icon"
					/>
					{tsn('tlacitko.akce.odvolat-souhlas')}
				</Button>
			</ButtonGroupLayout>
		</div>
	);
};
