import { useQueryClient } from '@tanstack/react-query';
import {
	fetchDataBoxCreditQuery,
	usePoQueries,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';

export const useDataBoxCredit = (dataBoxId?: string) => {
	const queryClient = useQueryClient();

	const { getConnectedDataBoxesIds } = useDataBoxStore();
	const connectedDataBoxesIds = getConnectedDataBoxesIds();

	const query = usePoQuery({
		enabled: dataBoxId ? connectedDataBoxesIds.includes(dataBoxId) : false,
		retry: 1,
		queryKey: dataBoxId ? ['data-box-credit', dataBoxId] : ['data-box-credit'],
		queryFn: async () =>
			dataBoxId ? await fetchDataBoxCreditQuery(dataBoxId) : undefined,
	});

	const refetch = async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey: ['data-box-credit'] });
	};

	return { refetch, credit: query.data?.kredit ?? null };
};

export const useDataBoxCreditLoader = () => {
	const { getConnectedDataBoxesIds } = useDataBoxStore();
	const connectedDataBoxesIds = getConnectedDataBoxesIds();

	const queries = usePoQueries({
		queries: connectedDataBoxesIds.map((dataBoxId) => {
			return {
				queryKey: ['data-box-credit', dataBoxId],
				queryFn: async () => fetchDataBoxCreditQuery(dataBoxId),
			};
		}),
	});

	const fetchedQueries = queries.filter((query) => query.isFetched);

	return { isLoaded: fetchedQueries.length === connectedDataBoxesIds.length };
};
