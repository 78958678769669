import { useEffect } from 'react';
import { Optional, getKeys } from '@gov-nx/core/types';
import { useFiltersStore } from '@gov-nx/store/portal-obcana';

type Serialized<T> = {
	[P in keyof T]: undefined extends T[P]
		? Optional<any>
		: T[P] extends Date
		? string
		: T[P];
};

interface FilterProps<T> {
	key: string;
	initialState: T;
	rehydrate?: (data: Serialized<T>) => T;
}

export const useFilter = <T extends object>({
	key,
	initialState,
	rehydrate,
}: FilterProps<T>) => {
	const { updateFiltersAction, updateFiltersWithPathAction, getFilters } =
		useFiltersStore();
	const filter = getFilters<Serialized<T>>(key);

	const setPartialFilter = (newFilter: Partial<T>) => {
		updateFiltersAction(key, newFilter);
	};
	const setPartialFilterWithPath = <Path extends keyof T>(
		newFilter: Partial<T[Path]>,
		path: Path
	) => {
		updateFiltersWithPathAction(key, newFilter, path as string);
	};

	useEffect(() => {
		const isEmpty = filter ? getKeys(filter).length === 0 : true;
		if (isEmpty) {
			updateFiltersAction(key, initialState);
		}
	}, []);

	return {
		filter: rehydrate && filter ? rehydrate(filter) : filter ?? initialState,
		setPartialFilter,
		setPartialFilterWithPath,
	};
};
