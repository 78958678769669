import { GovIcon, GovInfobar } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionExtend = ({ code }: EPetitionDetailViewProps) => {
	const { t } = useTranslation(code);
	const { petition, controls, setControls, ePetitionExtend } =
		useEPeticeDetailContext();

	return (
		<>
			<GovInfobar variant="warning">
				<GovIcon
					type="basic"
					name="lightbulb-fill"
					slot="icon"
					className={'flex [ md:self-center md:h-full ]'}></GovIcon>
				<div
					className={
						'flex flex-col gap-4 [ md:flex-row md:justify-between md:items-center ]'
					}>
					<p>
						{t('datum-uzavreni.status.epetice.detail', {
							namespace: code,
							closureDate: petition?.datumUkonceni
								? toDateReadable(petition.datumUkonceni)
								: null,
						})}
					</p>
					<Button
						onClick={() => {
							setControls({ isExtendModal: true });
						}}
						variant={'secondary'}
						type={'outlined'}
						size="s"
						wcagLabel={t('akce.prodlouzit')}>
						{t('akce.prodlouzit')}
					</Button>
				</div>
			</GovInfobar>

			<Prompt
				open={controls.isExtendModal}
				label={t('modal.pole.prodlouzit')}
				onGov-close={() => setControls({ isExtendModal: false })}>
				{t('modal.pole.chcete-prodlouzit')}
				<div
					slot={'actions'}
					className={'flex justify-end gap-4'}>
					<Button
						onClick={() => {
							petition?.id && ePetitionExtend.extendQuery.mutate(petition.id);
							setControls({ isExtendProcessing: true });
						}}
						loading={controls.isExtendProcessing}
						disabled={controls.isExtendProcessing}
						variant="primary"
						type="solid"
						nativeType="button"
						size="m"
						wcagLabel={t('tlacitko.akce.prodlouzit')}>
						{t('tlacitko.akce.prodlouzit')}
					</Button>
					<Button
						onClick={() => setControls({ isExtendModal: false })}
						variant="error"
						nativeType="button"
						size="m"
						type="outlined"
						wcagLabel={t('tlacitko.akce.neprodluzovat')}>
						{t('tlacitko.akce.neprodluzovat')}
					</Button>
				</div>
			</Prompt>
		</>
	);
};
