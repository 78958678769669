import {
	GovChip,
	GovIcon,
	GovMessage,
	GovPagination,
} from '@gov-design-system-ce/react';
import { routeResolver, useScroll } from '@gov-nx/web';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	EPetitionStatus,
	PageCode,
	useEPeticeSeznamContext,
} from '@gov-nx/module/page';
import {
	BadgeNav,
	ContentLayout,
	ErrorStatusBanner,
	Space,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { EPetitionFilter } from './EPetitionFilter';
import { EPetitionListSkeleton } from './EPetitionSkeleton';

export interface EPeticeFormProps {
	code: PageCode;
}

export const EPetitionView = ({ code }: EPeticeFormProps) => {
	const { scrollToTop } = useScroll();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { query, data, numberOfPetition, goToPage, filter, badges } =
		useEPeticeSeznamContext();

	if (query.error) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<ContentLayout>
			<EPetitionFilter code={code} />
			<BadgeNav className={'mt-4'}>
				{badges.map((badge) => (
					<li key={badge.name}>
						<GovChip
							variant={'primary'}
							size="m"
							tag="button"
							inverse={!badge.isActive}
							wcagLabel={`${ts('formular.wcag.filtrovat')} ${badge.name}`}
							onGov-click={() => badge.filterFunction()}>
							{badge.name}
						</GovChip>
					</li>
				))}
			</BadgeNav>

			{!query.isFetching && data.length === 0 && (
				<GovMessage variant={'primary'}>
					<GovIcon
						slot={'icon'}
						type={'basic'}
						name={'info'}
					/>
					{ts('stav.evidence.zadne-udaje')}
				</GovMessage>
			)}

			{query.isFetching ? (
				<EPetitionListSkeleton />
			) : (
				<Tiles>
					{data.map((petition) => {
						return (
							<Tile
								key={petition.id}
								name={`${petition.nazev}`}
								to={routeResolver(PageCode['epetice-detail'], {
									petitionId: petition.id,
								})}
								icon={{ name: 'chevron-right', type: 'basic' }}>
								<div className={'flex flex-col gap-2'}>
									<ul
										className={`gov-list--plain pt-2 pb-4 flex gap-1 overflow-x-auto whitespace-nowrap [ xl:overflow-x-visible ]`}>
										<GovChip
											variant={'secondary'}
											size="s">
											<span>
												{
													{
														[EPetitionStatus.rozpracovana]: ts(
															'odznak.rozpracovana'
														),
														[EPetitionStatus.zverejnena]:
															ts('odznak.zverejnena'),
														[EPetitionStatus.uzavrena]: ts('odznak.ukoncena'),
														[EPetitionStatus.podana]: ts('odznak.podana'),
														[EPetitionStatus.zamitnuta]: ts('odznak.zamitnuta'),
														[EPetitionStatus.vytvorena]: ts('odznak.vytvorena'),
													}[petition.stav as EPetitionStatus]
												}
											</span>
										</GovChip>
										{petition.mnouZalozene && (
											<li>
												<GovChip
													variant={'primary'}
													size="s">
													<span>{ts('odznak.moje-petice')}</span>
												</GovChip>
											</li>
										)}
										{petition.mnouPodepsane && (
											<li>
												<GovChip
													variant={'success'}
													size="s">
													<span>{ts('odznak.podepsana')}</span>
												</GovChip>
											</li>
										)}
									</ul>
									<p className={'!text-secondary-700'}>
										{ts('formular.petice.zakladatel')}
										<Space />
										<strong>{`${petition.zakladatel?.fyzickaOsoba?.prijmeni} ${petition.zakladatel?.fyzickaOsoba?.jmeno}`}</strong>
										{ts('formular.petice.adresat')}
										<Space />
										<strong>{petition.adresat?.nazev}</strong>
										<Space />
										{ts('formular.petice.zverejneno')}
										<Space />
										<strong>
											{petition.datumZverejneni &&
												toDateReadable(petition.datumZverejneni)}
										</strong>
										<Space />
									</p>
									<p className={'!text-secondary-700'}>{petition.perex}</p>
								</div>
							</Tile>
						);
					})}
				</Tiles>
			)}

			{numberOfPetition > filter.pocet ? (
				<GovPagination
					onGov-page={(event) => {
						scrollToTop();
						goToPage(event.detail.pagination.currentPage);
					}}
					current={filter.start / filter.pocet + 1}
					page-size={filter.pocet}
					total={numberOfPetition}
					wcag-label={ts('strankovani')}></GovPagination>
			) : null}
		</ContentLayout>
	);
};
