import { useState } from 'react';
import {
	ePetitionOvmDataBoxQuery,
	isResponseStatus,
	PeticeOvmSeznamDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';

export const usePetitionAddresseeAutocomplete = () => {
	const [text, setText] = useState<string>();

	const mainQuery = usePoQuery<PeticeOvmSeznamDto>(
		{
			queryKey: ['petition-addressee-autocomplete', text],
			queryFn: () => ePetitionOvmDataBoxQuery({ text: text ?? '', pocet: 15 }),
			enabled: text ? text.length > 2 : false,
			retry: 0,
			refetchOnWindowFocus: false,
		},
		{
			errorIgnoreFilter: isResponseStatus(400),
		}
	);

	return {
		isFetching: mainQuery.isFetching,
		result: mainQuery.data,
		setText,
	};
};
