export function icValidator(value?: string): boolean {
	if (typeof value === 'undefined') {
		return true;
	}
	if (typeof value !== 'string') {
		return false;
	}
	try {
		let a = 0;
		if (value.length === 0) return true;
		if (value.length !== 8) throw new Error('Ico nema spravnou delku');
		const b = value.split('');
		let c = 0;
		for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i);
		a = a % 11;
		c = 11 - a;
		if (a === 1) c = 0;
		if (a === 0) c = 1;
		if (a === 10) c = 1;
		if (parseInt(b[7]) !== c) throw new Error('Kontrolni delitelnost neprosla');
	} catch (e) {
		return false;
	}
	return true;
}
